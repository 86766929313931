import React from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import _ from 'lodash';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import './UserManagement.css';
import Select from 'react-select';
import ContextMenu from '../../general/ContextMenu';
import { MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TaimerComponent from "../../TaimerComponent";
import { withSnackbar } from 'notistack';
import DataList from '../../general/DataList'

const styles = theme => ({
    main: {
        paddingLeft: 0,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

const customOrder = {
    workhours_write: 1,
    workhours_read_superior: 2,
    workhours_read_full: 3,
    projects_write: 1,
    customers_write: 1,
};

const groupTypeOverride = {
    workhours_read_full: {
        group: 'read_full',
        type: 'read',
    },
    workhours_write_full: {
        group: 'read_full',
        type: 'readwrite',
    },
    workhours_read_superior: {
        group: 'read_superior',
        type: 'read',
    },
    persons_userlist_read: {
        group: 'userlist',
        type: 'read',
    },
}

class PermissionBlock extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/Permissions");

        this.state = {
            showExtras: false
        }

        this.hidingInProgress = false;
    }

    userDropChanged = (value) => {
        const { type, moduleName, permissionName, userAdded } = this.props;

        userAdded(value, type, moduleName, permissionName);
    }

    userDeleted = (value) => {
        const { type, moduleName, permissionName, userRemoved } = this.props;

        userRemoved(value, type, moduleName, permissionName);
    }

    openExtras = () => {
        if (this.hidingInProgress) {
            this.hidingInProgress = false;
            return;
        }
        this.setState({showExtras: true});
    }

    hideExtras = (event) => {
        if(event.path[0].className == "extra-count")
            this.hidingInProgress = true;
            
        this.setState({showExtras: false});
    }
    
    render() {
        const { permissions, usersAndGroups, moduleName, permissionName, filters, filteredReads, type, allowEdit } = this.props;
        let { showLimit } = this.props;
        const { showExtras } = this.state;
        const { tr } = this;

        const optionData = _.sortBy(
            usersAndGroups.filter(e => permissions.find(ee => ee.value === e.value) === undefined && (e.show_in === undefined || e.show_in.includes(moduleName) || e.show_in.includes(moduleName + "_" + permissionName))), 
            ['is_special', 'label'], ['desc', 'asc']);


        const filteredPermissions = filters.length > 0 ? permissions.filter(e => filters.find(ee => ee.value === e.value) !== undefined) : permissions;
        const filtered = filteredReads.length > 0 ? filteredPermissions.filter(e => filteredReads.find(ee => ee.value === e.value) === undefined) : filteredPermissions;

        const extraUsers = [];
        let extraUserCount = 0;
        
        return (
            <div className={"column " + type}>
                {filtered.length > 0 &&
                    filtered.map(r => {
                        showLimit--;
                        if (!r.value)
                            return;
                        if(showLimit >= 0) {
                            return <Chip id={r.value} key={r.value} label={r.label} className="square" onDelete={() => this.userDeleted(r)}/>
                        } else {
                            extraUserCount++;
                            extraUsers.push(r);
                        }
                    })
                }

                {extraUsers.length > 0 &&
                    <div className="extra-wrapper">
                        <div onClick={() => this.openExtras()} className="extra-count">+ {extraUserCount} more</div>
                        
                        {showExtras && (
                        
                        <ClickAwayListener onClickAway={(event) => this.hideExtras(event)}>
                        <div className="extra-users">
                            {extraUsers.map(r => (
                                <Chip id={r.value} key={r.value} label={r.label} className="square" onDelete={() => this.userDeleted(r)}/>
                                /*<MenuItem key={row.value} value={row.value}>
                                    <ListItemText>{row.label}</ListItemText>
                                    <div><DeleteIcon onClick={() => this.userDeleted(row)} /></div>
                                </MenuItem> */
                            ))}
                        </div>
                        </ClickAwayListener>)}
                    </div>
                }
                
                {allowEdit && 
                    <DataList
                        name={moduleName + "_" + permissionName}
                        placeholder={tr("Users and groups")}
                        onChange={this.userDropChanged}
                        value={null}
                        isSearchable={true}
                        isMulti={false}
                        options={optionData}
                        className="drop users-search"
                        shownCount={20}
                        classNamePrefix="users-search"
                    />
                }
            </div>
        )
    }
}

class PermissionGroup extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/Permissions");
    }

    render() {
        const { permissionData, groupHeader, usersAndGroups, filters, toggleGroupChecked, togglePermissionChecked, userAdded, userRemoved, groupName, showLimit } = this.props;
        const { tr } = this;
        
        return (
            <div id="permission-wrapper">
                <div className="header">
                    <div className="column permission"><Checkbox checked={permissionData.checked || false} onChange={() => toggleGroupChecked(groupName)} /> {groupHeader}</div>
                    <div className="column readwrite">{tr("Edit")}</div>
                    <div className="column read">{tr("View")}</div>
                </div>
                
                {_.map(permissionData, (r, i) => {
                    if (i == 'checked')
                        return;

                    const readWrites = (r.readwrite && r.readwrite['users']) ? r.readwrite['users'] : [];
                    const readsOnly = r.read ? r.read['users'] : [];
                    const filteredReads = readsOnly.filter(e => readWrites.find(ee => ee.value === e.value) !== undefined);
                    const filteredUsersAndGroups = usersAndGroups.filter(this.props?.usersAndGroupsFilters?.[i] ?? (() => true));
                    
                    return <div className="content">
                        <div className="column permission"><Checkbox checked={r.checked || false} onChange={() => togglePermissionChecked(groupName, i)}/> {r.transl}</div>
                        
                        <PermissionBlock
                            type="readwrite"
                            moduleName={groupName}
                            permissionName={i}
                            permissions={readWrites}
                            filteredReads={[]}
                            usersAndGroups={filteredUsersAndGroups}
                            filters={filters}
                            updateReadPermission={this.updateReadPermission}
                            userAdded={userAdded}
                            userRemoved={userRemoved}
                            showLimit={showLimit}
                            allowEdit={r.readwrite && r.readwrite.origName != ""}
                        />

                        <PermissionBlock
                            type="read"
                            moduleName={groupName}
                            permissionName={i}
                            permissions={readsOnly}
                            filteredReads={filteredReads}
                            usersAndGroups={filteredUsersAndGroups}
                            filters={filters}
                            updateReadPermission={this.updateReadPermission}
                            userAdded={userAdded}
                            userRemoved={userRemoved}
                            showLimit={showLimit}
                            allowEdit={r.read && r.read.origName != ""}
                        />
                    </div>
                })}
            </div>
        )
    }
}

class Permissions extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/Permissions");

        this.state = {
            loaded: false,
            filters: [],
        }
        const { tr } = this;
        this.permissionTransl = {
            'customers' : {
                'customers': tr("Accounts"),
                'customer_crm': tr("Activities"),
                'customer_pricelist': tr("Pricelist"),
                'attachments': tr("Account Attachments"),
                'profit_loss': tr("Profit & Loss"),
                'customer_memo': tr("Account Memo"),
                'hours': tr("Hours Insights"),
                'unit': tr("Unit"),
                'customer_budgets_target': tr("Goals Target"),
                'customer_budgets_forecast': tr("Goals Forecast"),
                'customer_budgets_commitment': tr("Goals Commitment"),
                'mediapro': tr("Mediapro"),
                'special_permissions': tr("Special Permissions"),
                'invoicing': tr("Invoicing Insight"),
                'set_quote_jobtypes': tr("Set Quote Jobtypes"),
                'corporate_structure': tr("Set Corporate Structure")
            },
            'dashboard' : {
                'dashboard': tr("Dashboard"),
                'profit_loss': tr("Profit & Loss"),
                'invoicing': tr("Invoicing"),
                'hours': tr("Hours Insights"),
                'activities': tr("Activities Insights"),
                'myday': tr("My Day"),
                'sales': tr("Sales Insight"),
                'goals': tr("Goals Insight")
            },
            'projects' : {
                'projects': tr("Projects"),
                'project_crm': tr("Read Activities"),
                'project_resourcing': tr("Gantt"),
                'own_resourcing': tr("Own Gantt"),
                'project_cost_estimate': tr("Project Quotes"),
                'project_billing_entries': tr("Project Invoicing"),
                'collaborate': tr("Collaborate"),
                'project_pricelist': tr("Pricelist"),
                'subcontracting': tr("Purchase Orders"),
                'attachments': tr("Project Attachments"),
                'profit_loss': tr("Profit & Loss"),
                'project_actual_costs': tr("Project Actual Costs"),
                'project_memo': tr("Project Memo"),
                'hours': tr("Hours Insights"),
                'special_permissions': tr("Special Permissions"),
                'revenue_recognition': tr("Revenue Recognition"),
                'hours_report': tr("Hours Report"),
                'purchase_order': tr("Purchase order"),
                'proposal': tr("Proposal"),
                'invoicing': tr("Invoicing Insight"),
            },
            'persons' : {
                'persons': tr("Persons"),
                'userlist': tr("Users"),
                'contact_owner': tr("Contact Owner")
            },
            'worktrips' : {
                'worktrips': tr("Create Expenses"),
                'approve': tr("Approve Expenses"),
                'approve_superior': tr("Superior approve Expenses"),
                'approve_projectmanager': tr("Project manager approve"),
                'modify_all': tr("Modify All Expenses"),
            },
            'receivedinvoices': {
                'pre_approve': tr("Pre-approve"),
                'approve': tr("Approve"),
            },
            'reports' : {
                'reports': tr("Hour Reports"), /* tuntiraportit */
                'read_sales_reports': tr("Sales Reports"), /* tuntiraportit */
                'read_own_subjects': tr('Hour Reports own subjects'),
                'read_own_projects': tr('Hour Reports own projects'),
                'read_not_special' : tr("Hour reports without HR-projects"),
                'worktrips': tr("Travel Expense Reports"),
                'worktrips_own_subjects': tr('Travel Expense Reports own subjects'),
                'invoicing': tr("Invoicing Reports"),
                'products': tr("Product Reports"),
                'recognition': tr("Revenue regocnition"),
                'profitability': tr("Profitability"),
                'forecast': tr("Forecast reports"),
                'subcontracting': tr("Subcontracting Reports"),
                'read_chief_reports': tr("Chief Reports"),
                'read_chief_subjects': tr("Chief Reports Subjects"),
            },
            'new_reports': {
                'invoicing': tr("Invoicing"),
                'hours': tr("Hours"),
                'hours_own_projects': tr("Hours (own projects)"),
                'hours_own_subordinates': tr("Hours (own subordinates)"),
                'hours_no_hr': tr("Hours (no HR projects)"),
                'costs': tr("Costs"),
                'projects': tr("Projects"),
                'share_templates': tr("Share reports with other users"),
                'scheduled_invoicing': tr("Scheduled invoicing"),
                'accounts': tr("Accounts"),
                'activities': tr("Activities"),
                'sales': tr("Sales"),
                'forecast': tr("Forecast"),
                'contacts': tr("Contacts"),
                'users': tr("Users"),
                'revenue_recognition': tr("Revenue recognition"),
                'resourcing': tr("Resourcing"),
            },
            'admin' : {
                'admin': tr("Admin Privileges"),
            },
            'newsfeed' : {
                'newsfeed': tr("Chat"),
            },
            'products' : {
                'products': tr("Products"),
            },
            'workhours' : {
                'workhours': tr("Allow Time Tracking"),
                'read_full': tr("Work Hours Access (All Users)"),
                'read_superior' : tr("Work Hours Access (Team Only)"),
                'approve_own_project': tr("Approve own projects"),
                'delete_full': tr("Delete workhours (All users)"),
            },
            'invoices' : {
                'write_simple': tr("Create Invoices For Own Projects"),
                'write_full': tr("Create Invoices"),
            },
            'purchaseorders': {
                'purchaseorders': tr("Purchase Orders"),
            },
        };

        this.hiddenPermissions = !context.addons.delete_user_workhours ? ["workhours_delete_full"] : [];

        if (!(this.context.addons && this.context.addons.resourcing)) {
            this.hiddenPermissions.push('project_resourcing');
            this.hiddenPermissions.push('own_resourcing');
        }

        if(!(this.context.addons && this.context.addons.new_user_report)) {
            this.hiddenPermissions.push('new_reports_users');
        }

        this.hiddenSections = context.taimerAccount.origin === "ingram" || !(this.context.addons && this.context.addons.bills) ? ["receivedinvoices"] : [];
        if (context.versionId != 4 && !(this.context.addons && this.context.addons.bills)) 
            this.hiddenSections.push("purchaseorders");
    }

    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    updateComponentData = async () => {
        const { company } = this.props;

        const data = await DataHandler.get({url: `settings/company/${company}/permissions`}).done(response => {
            if(!this.context.addons.mediapro) {
                delete response.permissions.customers.rights.customers_mediapro_read;
                delete response.permissions.customers.rights.customers_mediapro_write
            }
            if(!this.context.taimerAccount.hasExtranet) {
                delete response.permissions.projects.rights.projects_collaborate
            }

            return response;
        });

        const translatedLabels = {}

        for (const ug of data.usersAndGroups) {
            if (ug.is_special) {
                ug.label = this.tr(ug.label);
                translatedLabels[ug.value] = ug.label;
            }
            if (ug.users_company < 1)
                ug.label += ` (${this.tr("freelancer")})`;
        }

        
        const permissions = {}
        _.map(data.permissions, (v, i) => {
            if (this.hiddenSections.indexOf(i) !== -1)
                return;

            permissions[i] = this.buildPermissionData(v, translatedLabels);
        });



        this.setState({loaded: true, usersAndGroups: data.usersAndGroups, permissions: permissions});
    }
    
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    buildPermissionData = (group, translatedLabels) => {
        const groupName = group.name;
        const response = {};
        const permissionTransl = this.permissionTransl;

        const keys = _.keys(group.rights);

        keys.sort((a, b) => {
            const ordera = customOrder[a];
            const orderb = customOrder[b];

            if (ordera && !orderb) {
                return -1;
            } else if (!ordera && orderb) {
                return 1;
            } else if (ordera && orderb) {
                return ordera - orderb;
            } else {
                return a.localeCompare(b);
            }
        });

        _.map(keys, i => {
            if (this.hiddenPermissions.indexOf(i) !== -1)
                return;

            const v = group.rights[i];

            _.map(v, (lbl) => {
                if (translatedLabels[lbl.value])
                    lbl.label = translatedLabels[lbl.value];
            })

            const origName = i;
            let type = "readwrite";
            let trimmedName = i.replace(groupName + '_', '');
            
            if (groupTypeOverride[origName]) {
                trimmedName = groupTypeOverride[origName].group;
                type = groupTypeOverride[origName].type;
            } else if (trimmedName.endsWith('read')) {
                trimmedName = trimmedName.replace('_read', '');
                trimmedName = trimmedName.replace('read', '');
                type = groupName !== 'persons' ? "read" : 'readwrite';
            } else if (trimmedName.endsWith('write')) {
                trimmedName = trimmedName.replace('_write', '');
                trimmedName = trimmedName.replace('write', '');
            } else if (groupName == "reports") {
                type = "read";
            } else if (groupName == "new_reports") {
                type = "read";
            }
            
            if (trimmedName == "")
                trimmedName = groupName;

            if (!response[trimmedName])
                response[trimmedName] = {}
            
            response[trimmedName].transl = permissionTransl[groupName] ? permissionTransl[groupName][trimmedName] || trimmedName : trimmedName; 
            response[trimmedName][type] = {};
            response[trimmedName][type]['users'] = v;
            response[trimmedName][type]['origName'] = origName;
        })

        return response;
    }

    addFilter = value => {
        const { filters } = this.state;

        filters.push(value);

        this.setState({filters});
    }

    removeFilter = value => {
        const { filters } = this.state;

        filters.splice(filters.findIndex(e => e.value == value.value), 1);

        this.setState({filters});
    }

    toggleGroupChecked = (group) => {
        const { permissions } = this.state;
        let checkState = true;

        if(!permissions[group]['checked'])
            permissions[group]['checked'] = true;
        else {
            permissions[group]['checked'] = !permissions[group]['checked'];
            checkState = permissions[group]['checked']
        }

        _.map(permissions[group], (v, i) => {
            if (i != 'checked') {
                if(!permissions[group][i]['checked'])
                    permissions[group][i]['checked'] = checkState;
                else 
                    permissions[group][i]['checked'] = checkState;
            }
        })

        this.setState({permissions});
    }

    togglePermissionChecked = (group, name) => {
        const { permissions } = this.state;

        if(!permissions[group][name]['checked'])
            permissions[group][name]['checked'] = true;
        else 
            permissions[group][name]['checked'] = !permissions[group][name]['checked'];

        this.setState({permissions});
    }

    userAdded = (value, type, permissionName, permissionType, nosave = false) => {
        const { permissions } = this.state;

        if (Array.isArray(value)) {
            if (type == "readwrite" && !permissions[permissionName][permissionType][type])
                type = "read"

            _.map(value, (v, i) => {
                if (permissions[permissionName][permissionType][type]['users'].findIndex(e => e.value == v.value) < 0)
                    permissions[permissionName][permissionType][type]['users'].push(v);
            });
        } else {
            permissions[permissionName][permissionType][type]['users'].push(value);
        }

        this.setState({permissions}, () => {
            if (type === "readwrite" && permissions[permissionName][permissionType]["read"]) {
                this.userAdded(value, "read", permissionName, permissionType, true);
            }
        });

        if (!nosave)
            this.save(type, permissionName, permissionType);
    }

    userRemoved = (value, type, permissionName, permissionType) => {
        const { permissions } = this.state;

        if (Array.isArray(value)) {
            if (!permissions[permissionName][permissionType][type])
                type = "read"

            _.map(value, (v, i) => {
                if (permissions[permissionName][permissionType][type] && permissions[permissionName][permissionType][type]['users'].findIndex(e => e.value == v.value) > -1)
                    permissions[permissionName][permissionType][type]['users'].splice(permissions[permissionName][permissionType][type]['users'].findIndex(e => e.value == v.value), 1);
            });
        } else {
            const p = permissions[permissionName][permissionType][type];

            if(p) {
                p['users'] = p['users'].filter(e => e.value !== value.value);
            }

        }

        this.setState({permissions});

        this.save(type, permissionName, permissionType);

     
        // Delete read permission too if readwrite is removed
        if (type !== "read") {
            this.userRemoved(value, "read", permissionName, permissionType)
        }
    }

    save = async (type, permissionName, permissionType) => {
        const { permissions } = this.state;
        const { userObject } = this.context;
        const { company } = this.props;

        if (permissions[permissionName][permissionType][type]) {
            const parameters = {
                companyId: userObject.companies_id,
                permissions: permissions[permissionName][permissionType][type]['users'],
                module: permissionName,
                privilege: permissions[permissionName][permissionType][type]['origName']
            };
            DataHandler.post({url: "settings/company/" + company + "/permissions"}, parameters).done(response => {
                if (response.expenseInvalidUsers) {
                    this.props.enqueueSnackbar(this.tr("Some permissions could not be added: Expense creation permission can only be given for the user's own company!"), {
                        variant: 'warning'
                    });
                }
                setTimeout(() => {
                    try {
                        this.context.functions.whoami();
                        this.updateComponentData();
                    } catch (e) {}
                }, 1000) 
            }).fail(e => {
                const errorMsg = JSON.parse(e.responseText);
                this.props.enqueueSnackbar(this.tr(`${errorMsg.message}`), {
                    variant: 'error'
                });
                this.context.functions.whoami();
                this.updateComponentData();
            });
                
        }
    }

    addMultiplePermissions = () => {
        const { filters, permissions } = this.state;

        _.map(permissions, (v, i) => {
            _.map(permissions[i], (vv, ii) => {
                if (vv['checked'] && vv['checked'] == true) {
                    this.userAdded(filters, "readwrite", i, ii);
                }
            })
        });
    }

    deleteMultiplePermissions = () => {
        const { filters, permissions } = this.state;

        _.map(permissions, (v, i) => {
            _.map(permissions[i], (vv, ii) => {
                if (vv['checked'] && vv['checked'] == true) {
                    this.userRemoved(filters, "readwrite", i, ii);
                }
            })
        });
    }

    render() {
        const { loaded, usersAndGroups, permissions, filters } = this.state;
        const { tr } = this;
        const button = {
			className: 'list-menu',
			stickyIcon: true
        }

        const filteredUsersAndGroups = (usersAndGroups && filters) ? usersAndGroups.filter(e => filters.find(ee => ee.value === e.value) == undefined) : usersAndGroups;

        if (!loaded)
            return <div><Loading className='main-page-loading-indicator' /></div>
        
        //Order and headers in layout
        const permissionConfig = [
            { id: 'admin', name: tr("Admin rights") },
            { id: 'dashboard', name: tr("Dashboard") },
            { id: 'customers', name: tr("Accounts") },
            { id: "persons", name: tr("Contacts") },
            { id: "userlist", name: tr("User List") },
            { id: "projects", name: tr("Leads & deals") },
            { id: "products", name: tr("Products") },
            { id: "workhours", name: tr("Time tracker") },
            { id: "invoices", name: tr("Invoicing") },
            { 
                id: "worktrips", 
                name: tr("Expenses"),
                usersAndGroupsFilters: {
                    worktrips: (userOrGroup) => {
                        // If there happens to be a user or 
                        // group that doens't have a company
                        // (freelancers?), just show them.
                        return Number(userOrGroup?.companies_id ?? this.props.company) === Number(this.props.company);
                    }
                }
            },
            { id: "receivedinvoices", name: tr("Bills") },
            { id: "purchaseorders", name: tr("Purchase Orders") },
            { id: "reports", name: tr("Reports") },
            { id: "new_reports", name: tr("New Reports") },
            { id: "newsfeed", name: tr("Team chat") },
        ];

        return (
            <div id="permission-settings">
                <h3>{tr("Permissions")}</h3>
                <p className="help-text">{tr("Manage all user permissions here. Creating user permission groups first helps managing user right more efficiently. Give permission to user permission groups and simply add users to those groups.")}</p>

                <div className="filter-area">
                    <Select
                        placeholder={this.tr("Select users and groups")}
                        onChange={this.addFilter}
                        value={null}
                        isSearchable={true}
                        isMulti={false}
                        options={filteredUsersAndGroups}
                        className="drop"
                        classNamePrefix="filter-search"
                    />

                    {filters.length > 0 &&
                        filters.map(r => 
                            <Chip id={r.value} key={r.value} label={r.label} className="square" onDelete={() => this.removeFilter(r)}/>
                        )
                    }
                </div>

                <div className="option-container">
                    <ContextMenu buttonProps={button} variant="outlined" className="list-menu" label={tr("Options")} size="large" placement={"bottom-start"}>
                        <MenuItem onClick={() => this.addMultiplePermissions()}>{tr("Add permissions")}</MenuItem>
                        <MenuItem onClick={() => this.deleteMultiplePermissions()}>{tr("Remove permissions")}</MenuItem>
                    </ContextMenu>
                </div>

                {_.map(permissionConfig, (v) => {
                    const k = v.id;
                    
                    if (!permissions[k]) return;

                    const current = permissions[k];

                    const filteredUsersAndGroups = usersAndGroups.filter(v?.usersAndGroupsFilter ?? (() => true));
                    const usersAndGroupsFilters  = v?.usersAndGroupsFilters ?? {};

                    return <PermissionGroup 
                        usersAndGroups={!v.specialEntries ? usersAndGroups : [...v.specialEntries, ...filteredUsersAndGroups]}
                        usersAndGroupsFilters={usersAndGroupsFilters}
                        groupName={k}
                        groupHeader={v.name}
                        permissionData={current}
                        filters={filters}
                        origGroupName={k}
                        toggleGroupChecked={this.toggleGroupChecked}
                        togglePermissionChecked={this.togglePermissionChecked}
                        userAdded={this.userAdded}
                        userRemoved={this.userRemoved}
                        showLimit={5}
                    />
                })}
            </div>
        )
    }
}

export default withSnackbar(withStyles(styles)(Permissions));
