import getColumnType, { ColumnDefinition, isGroupedParent, sumAndGroup } from './ColumnTypes';
import getProjectColumns from './ProjectColumns';
import getAccountColumns from './AccountColumns';
import { CustomFields } from './CustomFields';
import { filterColumns } from "./ColumnVisibility";

// const columnOrder = [
//     'project',
//     'material_date_month',
//     'invoice_invoiced',
// ];

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
    startDate: Date | null;
    endDate: Date | null;
    dateFormat: string;
    settingsContext: any;
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
}

export default function getSalesColumns(params: Params) {

    const columns: ColumnDefinition[] = [    
        {
            field: 'company',
            headerName: 'Company',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'id',
            headerName: 'Sales quote ID',
            ...getColumnType('text'),
        },
        {
            field: 'name',
            headerName: 'Sales quote name',
            ...getColumnType('text'),
        },
        {
            field: 'row_description',
            headerName: 'Row Description',
            ...getColumnType('text'),
        },
        {
            field: 'status',
            headerName: 'Sales quote status	',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas: [
                    {id: '1', "name": "Draft", "color": "#6B7897"},
                    {id: '2', "name": "sent", "color": "#2D9FF7"},
                    {id: '3', "name": "Review", "color": "#F5A623"},
                    {id: '4', "name": "approved", "color": "#28A589"},
                    {id: '5', "name": "declined", "color": "#F7548F"},
                    {id: '-1', "name": "Archived", "color": "#716aca"},
                ],
                tr: params.tr
            }
        },
        {
            field: 'is_active',
            headerName: 'Active',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: '0', "name": "No"},
                    {id: '1', "name": "Yes"},
                ],
                tr: params.tr
            },
        },
        {
            field: 'creation_date',
            headerName: 'Creation date',
            ...getColumnType('date', null, 'creation_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'creation_date_month',
            headerName: 'Creation date Month',
            ...getColumnType('month'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'creation_date_quarter',
            headerName: 'Creation date Quarter',
            ...getColumnType('quarter'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'creation_date_year',
            headerName: 'Creation date Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group',
            chartDataType: 'category',
        },
        {
            field: 'project_closing_date',
            headerName: 'Project expected close date',
            ...getColumnType('date', null, 'project_closing_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_closing_date_month',
            headerName: 'Month (project expected close date)',
            ...getColumnType('month'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_closing_date_quarter',
            headerName: 'Quarter (project expected close date)',
            ...getColumnType('quarter'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_closing_date_year',
            headerName: 'Year (project expected close date)',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group',
            chartDataType: 'category',
        },
        {
            field: 'project_pipeline_date',
            headerName: 'Funnel last changed',
            ...getColumnType('date', null, 'project_pipeline_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_pipeline_date_month',
            headerName: 'Month (Funnel last changed)',
            ...getColumnType('month'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_pipeline_date_quarter',
            headerName: 'Quarter (Funnel last changed)',
            ...getColumnType('quarter'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_pipeline_date_year',
            headerName: 'Year (Funnel last changed)',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group',
            chartDataType: 'category',
        },
        {
            field: 'project_status_changed',
            headerName: 'Status last changed',
            ...getColumnType('date', null, 'project_status_changed'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_status_changed_month',
            headerName: 'Month (Status last changed)',
            ...getColumnType('month'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_status_changed_quarter',
            headerName: 'Quarter (Status last changed)',
            ...getColumnType('quarter'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_status_changed_year',
            headerName: 'Year (Status last changed)',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group',
            chartDataType: 'category',
        },
        {
            field: 'project_stage_date',
            headerName: 'Stage last changed',
            ...getColumnType('date', null, 'project_stage_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_stage_date_month',
            headerName: 'Month (Stage last changed)',
            ...getColumnType('month'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_stage_date_quarter',
            headerName: 'Quarter (Stage last changed)',
            ...getColumnType('quarter'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_stage_date_year',
            headerName: 'Year (Stage last changed)',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group',
            chartDataType: 'category',
        },
        {
            field: 'last_edited_date',
            headerName: 'Last edited date',
            ...getColumnType('date', null, 'last_edited_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'sent_date',
            headerName: 'Sent date',
            ...getColumnType('date', null, 'sent_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'valid_until_date',
            headerName: 'Valid until date',
            ...getColumnType('date', null, 'valid_until_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'delivery_date',
            headerName: 'Delivery date',
            ...getColumnType('date', null, 'delivery_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'currency',
            headerName: 'Currency',
            ...getColumnType('text'),
        },
        {
            field: 'currency_rate',
            headerName: 'Currency rate',
            ...getColumnType('number'),
            decimalAmount: 6,
            grandTotal: 'none',
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'recipient_address',
            headerName: 'Recipient address',
            ...getColumnType('text'),
        },
        {
            field: 'recipient_zip_code',
            headerName: 'Recipient zip code',
            ...getColumnType('text'),
        },
        {
            field: 'recipient_city',
            headerName: 'Recipient city',
            ...getColumnType('text'),
        },
        {
            field: 'recipient_country',
            headerName: 'Recipient country',
            ...getColumnType('text'),
        },
        {
            field: 'recipient_contact',
            headerName: 'Recipient contact',
            ...getColumnType('text'),
        },
        {
            field: 'recipient_phone',
            headerName: 'Recipient phone',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "tel:$(name)"
            },
        },
        {
            field: 'recipient_email',
            headerName: 'Recipient e-mail',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "mailto:$(name)"
            },
        },
        {
            field: 'sender_contact',
            headerName: 'Sender contact',
            ...getColumnType('text'),
        },
        {
            field: 'row_type',
            headerName: 'Row type',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: '1', "name": "Regular row"},
                    {id: '3', "name": "Product"},
                    {id: '4', "name": "CPQ"},
                ],
                tr: params.tr
            },
        },
        {
            field: 'unit',
            headerName: 'Unit',
            ...getColumnType('multi', params.tr),
            valueGetter: params => {
                if (isGroupedParent(params?.node)) {
                    return sumAndGroup(params);
                }

                if(!params || !params.data) {
                    return '';
                }
                const unit = params.data['unit'];

                if (unit == 1 || unit == 0 || unit?.trim() === "") {
                    return '[translate]qty';
                } else if (unit == 2) {
                    return '[translate]h';
                } else {
                    return unit;
                }
            },
            cellRendererParams: {
                tr: params.tr
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'work_type',
            headerName: 'Type of work',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: '1', "name": "Hours"},
                    {id: '2', "name": "Sub-contracting"},
                    {id: '3', "name": "Services"},
                    {id: '4', "name": "Products"},
                    {id: '5', "name": "Expenses"},
                ],
                tr: params.tr
            },
        },
        {
            field: 'unit_cost',
            headerName: 'Unit cost',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'unit_price',
            headerName: 'Unit price',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'quantity',
            headerName: 'Amount',
            ...getColumnType('number'),
            decimalAmount: 2,
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'discount_percentage',
            headerName: 'Discount %',
            ...getColumnType('percentage'),
        },
        {
            field: 'total_cost',
            headerName: 'Total cost',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'total_no_vat',
            headerName: 'Total (vat 0%)',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'vat_percentage',
            headerName: 'VAT %',
            ...getColumnType('percentage'),
        },
        {
            field: 'vat_amount',
            headerName: 'VAT',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'total',
            headerName: 'Total',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'product_code',
            headerName: 'Product code',
            ...getColumnType('text'),
        },
        {
            field: 'product',
            headerName: 'Product',
            ...getColumnType('text'),
        },
        {
            field: 'cpq',
            headerName: 'CPQ',
            ...getColumnType('text'),
        },
        {
            field: 'product_category',
            headerName: 'Product category',
            ...getColumnType('text'),
        },
        {
            field: 'product_parent_category',
            headerName: 'Product parent category',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'targeted',
            headerName: 'Actual cost',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'invoiced',
            headerName: 'Invoiced',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: '0', "name": "No"},
                    {id: '1', "name": "Yes"},
                ],
                tr: params.tr
            },
        },
        {
            field: 'invoice_numbers',
            headerName: 'Invoice number',
            ...getColumnType('links'),
            cellRendererParams: {
                url: "index.html?module=invoices&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'scheduled_invoice_created',
            headerName: 'Scheduled invoice created',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: '0', "name": "No"},
                    {id: '1', "name": "Yes"},
                ],
                tr: params.tr
            },
        },
        {
            field: "created_by",
            headerName: "Created by",
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('admin', 'admin', r.created_by_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'last_edited_by',
            headerName: 'Last edited by',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('admin', 'admin', r.last_edited_by_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'in_pipeline',
            headerName: 'Project in pipeline',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 0, "name": "No"},
                    {id: 1, "name": "Yes"},
                ],
                tr: params.tr,
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'margin',
            headerName: 'Margin',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            hide: true
        },
        {
            field: 'margin_percentage',
            headerName: 'Margin %',
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'margin', divisor: 'total_no_vat', decimalAmount: 2 },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum', // So shows formatted value correctly in grouped row. By default percentage has "grouped" and the the value is not formatted.
            hide: true
        },
        {
            field: 'project_last_activity_date',
            headerName: 'Last activity date',
            ...getColumnType('date', null, 'project_last_activity_date'),
            hide: true
        },
        ...getProjectColumns(params),
        ...getAccountColumns(params),
    ];

    // columns.sort(columnSorter(columnOrder));
    
    return filterColumns(
        columns, 
        "sales", 
        params.settingsContext
    );
}
