import React, { Component } from 'react';
import TaimerComponent from '../TaimerComponent';
import { withSnackbar } from 'notistack';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';
import { parse, format } from "date-fns";

import { DatePicker } from "./../general/react-date-range/src";
import SimpleTreeList from "../list/lists/SimpleTreeList";
import DialogBorder from './DialogBorder';
import DialogSection from './DialogSection';
//import SettingsGrid from './SettingsGrid';
import SettingsGrid from '../settings/pages/SettingsGrid'

import { SettingsContext } from '../SettingsContext';
import DataHandler from '../general/DataHandler';
import DataList from './../general/DataList';

import ProjectTreeDropdown from './../projects/ProjectTreeDropdown';
import { AddProject } from '../general/no-options/AddItemComponents';

//import './ResourceDialog.css';
import './CopyProjectDialog.css';
require("moment-timezone");


const TRANSL = {} // temp

export default withSnackbar(class CopyProjectDialog extends TaimerComponent {

    static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, "dialogs/CopyProjectDialog");
        const tr = this.tr;

        const data = props.data;

        this.copyFields = [
            {
                type: "check",
                name: "copy_all_fields",
                label: tr("Copy all fields"),
                className: "respSetting full"
            },
            {
                type: "check",
                name: "activities",
                label: tr("Activities"),
                className: "respSetting full",
                locked: true
            },
            {
                type: "check",
                name: "team",
                label: tr("Team"),
                className: "respSetting full",
                locked: true
            },
            {
                type: "check",
                name: "contacts",
                label: tr("Contacts"),
                className: "respSetting full",
                locked: true
            },
            {
                type: "check",
                name: "resourcing",
                label: tr("Resourcing"),
                className: "respSetting full",
                locked: true
            },
            {
                type: "check",
                name: "active_sales_quote",
                label: tr("Active Sales Quote"),
                className: "respSetting full",
                locked: true
            },
            {
                type: "check",
                name: "scheduled_invoicing",
                label: tr("Scheduled Invoicing"),
                className: "respSetting full",
                locked: true
            },
            {
                type: "check",
                name: "automatic_invoicing",
                label: tr("Automatic Invoicing"),
                className: "respSetting full",
                locked: true
            },
            {
                type: "check",
                name: "price_list",
                label: tr("Price List"),
                className: "respSetting full",
                locked: true
            },
            {
                type: "check",
                name: "attachments",
                label: tr("Attachments"),
                className: "respSetting full",
                locked: true
            }
        ];

        const { userObject } = this.context;
        const today = format(new Date(), "YYYY-MM-DD");
        this.state = {
            copyFields: {},
            account: {},
            pipeline: {},
            stage: {},
            projectManager: {},
            salesAgent: {},
            company: {},

            invalidAccount: false,
            invalidPipeline: false,
            invalidStage: false,
            invalidProjectManager: false,
            invalidSalesAgent: false,

            noProjectsSelected: false,

            accounts: [],
            pipelines: [],
            stages: [],
            employees: [],
            salesUsers: [],
            companies: [],
            saving: false,
            selectedMainProject: {},
            newStartDate: {
                startDate: today,
                endDate: today,
                input: today
            }
        };
        this.copyFields.forEach(el => {
            this.state.copyFields[el.name] = 1;
        });

        this.simpleTreeList = React.createRef();

        const projectTree = this.createProjectTree(data.children, []);
        projectTree.unshift({id: data.id, title: data.customer, title2: data.name + " (" + data.project_id + ")",
            input : data.name + " (copy)"});
        this.state.projectTree = projectTree;
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateCompanyData();
    }

    createProjectTree = (childRefs, currentArray) => {
        if(!childRefs) {
            return currentArray;
        }
        childRefs.forEach(el => {
            currentArray.push({id: el.id, title:  el.customer, title2: el.name + " (" + el.project_id + ")",
                parentId: el.parentId, input : el.name + " (copy)"});
            this.createProjectTree(el.childRefs, currentArray);
        });
        return currentArray;
    }


    updateCompanyData = async () => {
        let company = {};
        let companies = await DataHandler.get({url: 'subjects/companies'});
        companies = companies.map(el => {
            el.label = el.name;
            return el;
        });
        if(companies.length > 0) {
            company = companies.find(el => el.id == this.props.data.companiesId);
            if(!company) {
                company = companies[0];
            }
        }
        this.setState({
            companies: companies,
            company: company,
            account: {},
        }, () => {
            this.updateAutoCompleteData(company);
        });
        this.simpleTreeList.current?.list.current?.checkAll();
    }

    updateAutoCompleteData = async (company) => {
        const a = await DataHandler.get({url: 'subjects/accounts/' + company.id, all_accounts: 1});
        const accounts = a.filter(x => x.allow_project_creation == 1);
        const pipelines = await DataHandler.get({url: 'subjects/pipelines/' + company.id, include_predefined: 1});
        let pipeline = {};
        for(let i = 0; i < pipelines.length; i++) {
            if(pipelines[i].is_default > 0) {
                pipeline = pipelines[i];
            }

            if (pipelines[i].id < 0) {
                pipelines[i].name = this.tr(pipelines[i].name);
                pipelines[i].label = this.tr(pipelines[i].label);
            }
        }

        const employeeData = await DataHandler.get({ url: `projects/employees/${company.id}`, no_dynamic_rights: 1 });
        const preselectedAccount = accounts.filter(acc => acc.name == this.props.data.customer)

 	this.setState({
            accounts: accounts,
            pipelines: pipelines,
            employees: employeeData.employees,
            salesUsers: employeeData.privileged_employees,
            pipeline: pipeline,
            projectManager: {id: 0},
            salesAgent: {id: 0, users_id: 0},
            account: preselectedAccount[0] ? preselectedAccount[0] : {}
        }, () => {
            if(pipeline.id !== undefined) {
                this.onPipelineChange(pipeline);
            }
        });
    };

    onPipelineChange = async (pipeline) => {
        let url;
        switch(pipeline.id) {
            case '-1':
                url = 'subjects/project_states/' + this.state.company.id;
                break;
            case '-5':
                url = 'subjects/internal_states/' + this.state.company.id;
                break;
            default:
                url = 'subjects/sales_states/' + pipeline.id;
                break;
        }
        let stages = await DataHandler.get({url: url });
        stages = stages.map(el => {
            el.label = el.name;
            return el;
        });
        let stage = {};
        if(stages.length > 0) {
            stage = stages[0];
        }
        this.setState({stages: stages, pipeline: pipeline, stage: stage});
    }

    onCopyFieldChange = (item, field) => {
        if(field === 'copy_all_fields') {
            for(const i in item) {
                item[i] = item.copy_all_fields;
            }
            this.copyFields = this.copyFields.map(el => {
                if(el.name !== 'copy_all_fields') {
                    el.locked = item.copy_all_fields;
                }
                return el;
            });
        }
        this.setState({copyFields: item});
    }

    getCheckedProjects = () => {
        if(this.simpleTreeList.current) {
            return this.simpleTreeList.current.getCheckedRows();
        }
        return [];
    }

    validate = () => {
        const accountSelected = this.state.account.id > 0;
        const pipelineSelected = this.state.pipeline != undefined;
        const stageSelected = this.state.stage.id > 0;
        const projectManagerSelected = this.state.projectManager.id > 0;
        const salesAgentSelected = this.state.salesAgent.id > 0;
        this.setState({
            invalidAccount: !accountSelected,
            invalidPipeline: !pipelineSelected,
            invalidStage: !stageSelected,
            //invalidProjectManager: !projectManagerSelected,
            //invalidSalesAgent: !salesAgentSelected,
        });
        if(!this.state.noProjectsSelected && accountSelected && pipelineSelected && stageSelected
            /* && projectManagerSelected && salesAgentSelected*/) {
            return true;
        }
        this.props.enqueueSnackbar(this.tr("Fill all the required fields"), {
            variant: 'error',
        });
        return false;
    }

    copyProject = () => {
        if(!this.validate() || this.state.saving) {
            return;
        }
        const snackbarKey = this.props.enqueueSnackbar(this.tr("Copying..."), {
            variant: 'info',
            persist: true
        });
        const checkedProjectsMap = {};
        this.getCheckedProjects().forEach(el => {
            checkedProjectsMap[el] = true;
        });
        const projects = this.state.projectTree.filter(el => {
            return checkedProjectsMap[el.id];
        }).map(el => {
            return {id: el.id, parentId: el.parentId, name: el.input};
        });
        let status = 0;
        let pipeline = this.state.pipeline.id;
        if(pipeline == -1) {
            status = 1;
        } else if(pipeline == -5) {
            status = 5;
        }
        if(status != 0) {
            pipeline = 0;
        }
        const data = {
            account: this.state.account.id,
            projects_pipelines_id: pipeline,
            status: status,
            projects_sales_states_id: this.state.stage.id,
            users_id: this.state.projectManager.id,
            status_users_id: this.state.salesAgent.id,
            company: this.state.company.id,
            copy_fields: this.state.copyFields,
            projects: projects,
            main_projects_id: this.state.selectedMainProject?.id,
            startdate: this.state.newStartDate.startDate

        };
        this.setState({saving: true});
        DataHandler.post({url: 'projects/copy'},data).done((resp) => {
            this.props.closeSnackbar(snackbarKey);
            this.props.enqueueSnackbar(this.tr("Projects copied!"), {
                variant: 'success',
            });
            
            this.props.refreshList && this.props.refreshList();
            this.props.updateLimitInfo && this.props.updateLimitInfo();
            this.props.onDialogClose(resp);
        });
    }

    checkSelectedProjects = () => {
        this.setState({noProjectsSelected: this.getCheckedProjects().length == 0}) ;
    }

    onProjectNameChange = (newValue, data) => {
        const projectTree = this.state.projectTree.slice();
        for(let i = 0; i < projectTree.length; i++) {
            if(projectTree[i].id == data.id) {
                projectTree[i].input = newValue;
                this.setState({projectTree: projectTree});
                break;
            }
        }
    }

    onDateChange = date => {
        date = format(new Date(date), "YYYY-MM-DD");

        this.setState({
            newStartDate: {
                startDate: date,
                endDate: date,
                input: date,
            },
        });
    };

    onDateInputChange = (text, date) => {
        date = format(new Date(date), "YYYY-MM-DD");

        this.setState({
            date: {
                startDate: date,
                endDate: date,
                input: date,
            },
        });
    };

    render() { 
        const { tr } = this;
        const { userObject } = this.context;
        const simpleTreeList = this.simpleTreeList;
        let copyFields = this.copyFields.slice();
        if(this.state.company.id != this.props.data.companiesId) {
            copyFields = copyFields.filter(el => el.name != 'price_list');
        }
        return (
            <DialogBorder
                id="CopyProjectDialog"
                title={this.tr("Copy")}
                onClose={this.props.onDialogClose}
                className="TMRDialogGrid TMRDialogInputs"
                onRendered={() => {
                    simpleTreeList.current.list.current.checkAll(true)
                }}
                rightButtons={
                    <div className={"TMRDialogButton blue " + ( (this.state.saving || this.state.noProjectsSelected) ? 'disabled' : '')} onClick={this.copyProject} >{this.tr("Copy")}</div>
                }>
               
                <div  class="section-container horizontal">
                    <DialogSection>
                        <h3 className="section-header full">{this.tr("Details")}</h3>

                        <DataList
                            label={this.tr("Company")}
                            name="company"
                            className="full"
                            options={this.state.companies}
                            onChange={(value) => {
                                this.setState({company: value}, () => {
                                    this.updateAutoCompleteData(value)
                                });
                            }}
                            value={this.state.company}

                        />
                    
                        <DataList
                            label={this.tr("Copy to account")}
                            name="account"
                            error={this.state.invalidAccount}
                            className="full copy_account"
                            options={this.state.accounts}
                            onChange={(value) => {this.setState({account: value})}}
                            value={this.state.account}
                            company={this.state.company.id}
                            shownCount={20}
				        />

                        <DatePicker
                            label={this.tr("Projects new starting date")}
                            className="full date"
                            date={this.state.newStartDate.input}
                            dateFormat={userObject.dateFormat}
                            onChange={this.onDateChange}
                            onInputChange={this.onDateInputChange}
                            closeCalendarOnComplete={true}
                            closeOnComplete={false}
                            disabled={false}/>

                        <ProjectTreeDropdown
                            name="project" // TODO: Onko tarpeellinen?
                            label={this.tr("Add as a sub-project under project")}
                            value={this.state.selectedMainProject?.id}
                            disabled={false}
                            queryParameters={{ right: 'write', company: this.state.company?.id }}
                            treeDropdownProps={{
                                activateBestMatch: true,
                                highlightMatches: true,
                                useTooltip: true,
                                growOptionContainer: false,
                            }}
                            noOptionsMessage={AddProject}
                            noOptionsMessageProps={{
                                selectProps: {
                                    onItemCreated: (e) => {
                                        this.setState({selectedMainProject: e});
                                    }
                                }
                            }}
                            onSelect={e => {
                                const { data } = e;

                                this.setState({ selectedMainProject: data });
                            }}
                        />

                        <DataList
                            label={this.tr("Funnel")}
                            name="pipeline"
                            error={this.state.invalidPipeline}
                            className="half"
                            options={this.state.pipelines}
                            onChange={this.onPipelineChange}
                            value={this.state.pipeline}

				        />
                        <DataList
                            label={this.tr("Stage")}
                            name="stage"
                            error={this.state.invalidStage}
                            className="half"
                            options={this.state.stages}
                            onChange={(value) => {this.setState({stage: value})}}
                            value={this.state.stage}
				        />
                        <DataList
                            label={this.tr("Project Manager")}
                            name="project_manager"
                            //error={this.state.invalidProjectManager}
                            className="half"
                            options={this.state.employees}
                            onChange={(value) => {this.setState({projectManager: value})}}
                            value={this.state.projectManager}  
				        />
                        <DataList
                            label={this.tr("Sales Agent")}
                            //error={this.state.invalidSalesAgent}
                            name="sales_agent"
                            className="half"
                            options={this.state.salesUsers}
                            onChange={(value) => {this.setState({salesAgent: value})}}
                            value={this.state.salesAgent}
				        />
                        <div className={"copyfields full " + ( this.state.copyFields.copy_all_fields ? 'copy_all_fields' : '')}>
                        <SettingsGrid settings={copyFields}  item={this.state.copyFields} onChange={this.onCopyFieldChange} />
                        </div>
                    </DialogSection>
                    <DialogSection className={"project_tree " + ( this.state.noProjectsSelected ? 'error ' : ' ')}>
                        <h3 className="section-header full">{this.tr("Select Project & Edit")}</h3> 

                        <SimpleTreeList 
                            ref={this.simpleTreeList}
                            hideHeader={true}
                            data={this.state.projectTree}
                            height={56 * 6 + 280} // normal rows + scroll area - header
                            onCheck={this.checkSelectedProjects}
                            onInputChange={this.onProjectNameChange}
                            additionalColumns={[
                                { name: "input", header: "", width:  280}
                            ]}
                        />
                    </DialogSection>
                </div>
            </DialogBorder>
        )
    }
}
);
