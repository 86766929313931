import React from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import { cloneDeep, debounce, isEqual, range } from 'lodash';
import { MenuItem, Popper, Tooltip } from '@mui/material';
import { withSnackbar } from 'notistack';
import { Delete, KeyboardArrowLeft, KeyboardArrowRight, Save } from '@mui/icons-material';
import moment from 'moment-timezone';
import tzmoment from 'moment-timezone';

import TaimerComponent from '../../TaimerComponent';
import BulkEntryListRow from './BulkEntryListRow';
import DataHandler from '../../general/DataHandler';
import ProjectTreeDropdown from '../../projects/ProjectTreeDropdown';
import ClickAwayWrapper from '../../general/ClickAwayWrapper';
import ScrollList from '../../general/ScrollList';
import List from '../../list/List';
import CoreDialog from '../../dialogs/mass_operations/CoreDialog';

import styles from './BulkEntry.module.scss';
import DataList from '../../general/DataList';
import { AddCatalogDialog } from '../../list/dialogs/ProductCatalogDialogs';
import InsightDropDown from '../../dashboard/insights/InsightDropDown';
import colors from '../../colors';
import { startOfWeek, format } from 'date-fns';
import Lottie from 'react-lottie';
// import AdvancedSearch from '../../search/AdvancedSearch';
const click = require('../../general/animations/clickAnimation.json');
import { ReactComponent as NoDataImage } from '../../dashboard/images/ActivitiesGraphic.svg';

const CustomOptionStyles = {
    margin: 0,
    padding: 0,
};

const CustomTemplateOption = (props) => {
    const rows = props.options;
    let menuAutoWidth = props.menuWidth || 'auto';
    if (rows && rows.length > 0 && !props.menuWidth) {
        const len = Math.max(...rows.map((x) => String(x.label || '').length));
        menuAutoWidth = len + 4 + 'ch';
    }
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            disabled={props.isDisabled}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
                width: menuAutoWidth,
                minWidth: '-webkit-fill-available',
                display: 'flex',
                justifyContent: 'space-between',
            }}
            {...props.innerProps}
        >
            <div>
                <p style={CustomOptionStyles}>{props.data.label}</p>
            </div>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    props.selectProps.onDeletePressed(props.data);
                }}
                className={styles.customOptionButton}
            >
                <Delete />
            </button>
        </MenuItem>
    );
};
class BulkEntry extends TaimerComponent {
    static defaultProps = {
        parentComponent: undefined, //this is for analytics
    }

    constructor(props, context) {
        super(props, context, 'workhours/time-tracker/BulkEntry');

        this.viewPresets = [
            {
                key: 'own_projects',
                label: this.tr('Own projects'),
                action: this.addOwnProjects,
            },
            {
                key: 'projects_with_entries',
                label: this.tr('Projects with entries this week'),
                action: this.getProjectsWithTrackedHours,
            },
            {
                key: 'clear_list',
                label: this.tr('Clear list'),
                'data-testid':'clear_list',
                action: this.openEmptyListDialog,
                style: { color: colors.carnation_pink },
            },
        ];

        this.columns = this.getColumns();

        const { timeTracker } = this.context;

        this.hours = range(0, 13).map((n) => {
            return {
                label: `${n} h`,
                value: n,
            };
        });
        this.minutes = range(0, 60, timeTracker.bulkentry_interval).map((n) => {
            return {
                label: n == 0 ? `${n} min` : `${n} min (${Math.round((n / 60) * 100) / 100})`,
                value: n,
            };
        });

        this.savedListDropdown = React.createRef();
        this.entryContainer = React.createRef();
        this.list = React.createRef();
        this.edited = false;
        this.scrollingList = false;
        this.scrollingEntryContainer = false;
        this.getEntriesTimeout = undefined;
        this.getEntriesTimeoutInterval = 300;
        this.sortData = undefined;
        this.shouldUpdateEntries = false;
        this.projectsAndTemplatesLoaded = false;
        this.editExistingData = undefined;
        this.onFocusSelectionTimeout = undefined;

        this.state = {
            projects: this.props.demoMode ? this.props.demoProjects : [],
            templates: [],
            selectedTemplate: null,
            tasks: [],
            entries: this.parseEntries(),
            shownDays: this.getWeekdaysForStart(
                startOfWeek(new Date(), {
                    weekStartsOn: this.context.calendar.startOfWeek,
                })
            ),
            popoverAnchor: undefined,
            selectedEntry: undefined,
            loadingInitialProjects: true,
            loadingOwnProjects: false,
            loadingTrackedProjects: false,
            listScrollBarHeight: 0,
            entryContainerScrollbarWidth: 0,
            showEmptyListDialog: false,
            showAddTemplateDialog: false,
            jobtypeErrorKey: undefined,
            descriptionErrorKey: undefined,
        };
    }

    // Lifecycle

    componentDidMount = () => {
        super.componentDidMount();
        window.addEventListener('resize', this.onWindowResize);
        if (!this.props.demoMode) {
            this.getEntries();
            this.getTemplates();
            this.getInitialProjects();
            this.getTasks();
        }
    };

    componentDidUpdate = (oldProps, oldState) => {
        if (oldState.selectedEntry?.key != this.state.selectedEntry?.key || oldState.selectedEntry?.date != this.state.selectedEntry?.date) {
            if (oldState.selectedEntry) {
                this.context.functions.sendMixpanelEvent('track_hours', {
                    /*'From': 'Slider',*/
                    'origin_point': this.props.parentComponent
                });
                this.saveEntry(oldState.selectedEntry);
            }
        }

        if (!isEqual(oldProps.events, this.props.events) || this.shouldUpdateEntries) {
            this.shouldUpdateEntries = false;
            this.setState({ entries: this.parseEntries() });
        }

        if (oldState.projects != this.state.projects) {
            this.setScrollBarSizes();
        }

        if (!isEqual(oldProps.selectedDays, this.props.selectedDays)) {
            this.list.current && this.list.current.windowOnResize();
        }

        if (!this.projectsAndTemplatesLoaded && this.state.projects.length > 0 && this.state.templates.length > 0) {
            this.projectsAndTemplatesLoaded = true;
            const matchingTemplateIndex = this.state.templates.findIndex((t) =>
                isEqual(
                    t.projects.map((p) => p.key),
                    this.state.projects.map((p) => p.key)
                )
            );
            if (matchingTemplateIndex != -1) {
                const template = this.state.templates[matchingTemplateIndex];
                this.setState({
                    selectedTemplate: {
                        ...template,
                        id: template.title,
                        label: template.title,
                    },
                });
            }
        }
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.onWindowResize);
    };

    // Layout

    getEntryContainerWidth = () => {
        return 60 * ((this.props.selectedDays || []).length + (this.props.isMyDay ? 2 : 3));
    };

    getListScrollbarHeight = () => {
        const list = document.querySelector('.mainListWrapper');
        if (!list) return;
        const scrollbarHeight = list.offsetHeight - list.clientHeight;
        return scrollbarHeight;
    };

    getEntryContainerScrollbarWidth = () => {
        const rows = this.entryContainer.current;
        if (!rows) return;
        const scrollbarWidth = rows.offsetWidth - rows.clientWidth;
        return scrollbarWidth;
    };

    onWindowResize = debounce(() => {
        this.setScrollBarSizes();
    }, 400);

    setScrollBarSizes = () => {
        this.setState({
            listScrollBarHeight: this.getListScrollbarHeight(),
            entryContainerScrollbarWidth: this.getEntryContainerScrollbarWidth(),
        });
    };

    // Date and time

    getFullTotal = () => {
        let total = 0;
        this.state.shownDays.forEach((day) => {
            const dayString = day.format('YYYY-MM-DD');
            total += Object.values(this.state.entries).reduce((prev, entryObj) => {
                const dateObj = entryObj[dayString] || {};
                return (prev += Number.isFinite(Number(dateObj.hours)) ? Number(dateObj.hours) : 0);
            }, 0);
        });
        return total;
    };

    isHoliday = (date) => {
        const { workdays } = this.props;

        const w = workdays[date] || {type: 0};

        return w.type === 3;
    };

    isWorkday = (dateString) => {
        const { workdays } = this.props;
        const w = workdays[dateString] || {type: 0};

        return w.type === 1;
    };

    getWeeklyHours = () => {
        let hours = 0;
        this.state.shownDays.forEach((day) => {
            const d = day.format('YYYY-MM-DD');

            hours += this.getWorkhoursForDate(d);
        });
        return hours;
    };

    getPercentageOfWeeklyTotal = (hours) => {
        const weekly = this.getWeeklyHours();
        return weekly !== 0 ? Math.round((hours / weekly) * 100) : 0;
    };

    getWorkhoursForDate = (date) => {
        const { workdays } = this.props;

        const w = workdays[date] || {workday: false};

        if (w.workday) {
            return w.hours;
        } else if (w.type === 4) {
            // Vacations should be marked too
            return w.hours;
        } else {
            return 0;
        }
    };

    getSelectedEntryTime = () => {
        const fullHours = this.state.selectedEntry?.hours || 0;
        const n = new Date(0, 0);
        n.setMinutes(fullHours * 60);
        const hours = n.getHours();
        const minutes = n.getMinutes();
        return { hours, minutes };
    };

    getWeekdaysForStart = (start) => {
        const days = [];
        for (let i = 0; i < 7; i++) {
            days.push(moment(start).add(i, 'days'));
        }
        return days;
    };

    setPreviousWeek = () => {
        this.getEntriesTimeout && clearTimeout(this.getEntriesTimeout);
        const shownDays = this.getWeekdaysForStart(moment(this.state.shownDays[0]).subtract(1, 'week'));
        this.setState({ shownDays, entries: [] }, () => {
            this.getEntriesTimeout = setTimeout(() => {
                this.getEntries();
            }, this.getEntriesTimeoutInterval);
        });
    };

    setNextWeek = () => {
        this.getEntriesTimeout && clearTimeout(this.getEntriesTimeout);
        const shownDays = this.getWeekdaysForStart(moment(this.state.shownDays[0]).add(1, 'week'));
        this.setState({ shownDays, entries: [] }, () => {
            this.getEntriesTimeout = setTimeout(() => {
                this.getEntries();
            }, this.getEntriesTimeoutInterval);
        });
    };

    // Networking

    editExistingEntries = async (project, editData) => {
        const { entries, oldRow } = editData;
        const { enqueueSnackbar } = this.props;
        this.closeEmptyListDialog();
        const promises = [];
        Object.values(entries).forEach((entryDay) => {
            const minutes = Number(entryDay.hours || 0) * 60;
            const description = editData.overrideDescription ? project.description : entryDay.description || project.description;
            promises.push(
                DataHandler.post(
                    { url: 'timetracker/bulk' },
                    {
                        day: entryDay.date,
                        minutes,
                        project: project.id,
                        worktype: project.jobtype?.id || '0',
                        worktype_old: oldRow.jobtype?.id || '0',
                        description,
                        wh_projects_resource_id: project.task?.id || '0',
                        wh_projects_resource_id_old: oldRow.task?.id || '0',
                        timezone: tzmoment.tz.guess(),
                    }
                )
            );
        });
        Promise.all(promises)
            .then((responses) => {
                let errorMessage = null;
                responses.forEach((response) => {
                    if (response.status != 'ok') {
                        errorMessage = response.errorcode === 'END_OF_DAY' ? this.tr('Day is already full.') : this.tr('Saving entry failed!');
                        if (response.errorcode == 'INVALID_WORKTASK') {
                            errorMessage = this.tr('The selected jobtype cannot be used for this project.');
                        }
                    }
                });
                if (errorMessage) {
                    enqueueSnackbar(errorMessage, { variant: 'error' });
                    this.shouldUpdateEntries = true;
                } else {
                    enqueueSnackbar(this.tr('Entries edited succesfully!'), {
                        variant: 'success',
                    });
                }
                window.dispatchEvent(new Event('workhourSaved'));
            })
            .catch((e) => {
                enqueueSnackbar(this.tr('Saving entries failed!'), { variant: 'error' });
                this.shouldUpdateEntries = true;
                window.dispatchEvent(new Event('workhourSaved'));
                console.log(e);
            });
    };

    saveEntry = async (entryDay) => {
        const { enqueueSnackbar, demoMode } = this.props;
        const analyticsData = {
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
            taimer_version: this.context.versionId,
            feature_name: this.props.parentComponent,
            hours_entry_products: 0,
            hours_entry_cpq: 0,
            hours_entry_invoiceable_row: 0,
            hours_entry_mileage: 0,
            hours_entry_daily_allowance: 0,
            hours_entry_expense: 0,
        };
        const eventFunction = 'hours_created';

        if (!this.edited) return;
        if (demoMode) {
            this.props.onEntryCreated && this.props.onEntryCreated();
            return;
        }
        if (!this.checkDescription(entryDay)) {
            this.shouldUpdateEntries = true;
            window.dispatchEvent(new Event('workhourSaved'));
            return;
        }
        const project = this.state.projects.find((p) => p.key == entryDay.key);
        if (!project.jobtype?.id || Number(project.jobtype?.id) <= 0) {
            enqueueSnackbar(this.tr('Please select a jobtype!'), {
                variant: 'error',
            });
            this.setState({ jobtypeErrorKey: entryDay.key }, () => {
                const cell = document.getElementsByClassName('bulkEntryJobtypeCell')[0];
                if (cell && cell.scrollIntoView) {
                    cell.scrollIntoView();
                }
            });
            return false;
        }
        this.edited = false;
        const description = entryDay.description || project.description;
        if (!project || !Number.isFinite(Number(entryDay.hours || 0))) return;
        const minutes = Number(entryDay.hours || 0) * 60;
        try {
            const response = await DataHandler.post(
                { url: 'timetracker/bulk' },
                {
                    day: entryDay.date,
                    minutes,
                    project: project.id,
                    worktype: project.jobtype?.id || '0',
                    description,
                    wh_projects_resource_id: project.task?.id || '0',
                    timezone: tzmoment.tz.guess(),
                }
            );
            if (response.status != 'ok') {
                let errorMessage = response.errorcode === 'END_OF_DAY' ? this.tr('Day is already full.') : this.tr('Saving entry failed!');
                if (response.errorcode == 'INVALID_WORKTASK') {
                    errorMessage = this.tr('The selected jobtype cannot be used for this project.');
                    this.setState({ jobtypeErrorKey: entryDay.key });
                } else if (response.errorcode === 'OVERLAPS_DISABLED') {
                    errorMessage = this.tr("Hour entries can't overlap");
                  }
                enqueueSnackbar(errorMessage, { variant: 'error' });
                this.shouldUpdateEntries = true;
                window.dispatchEvent(new Event('workhourSaved'));
                return;
            } else {
                // prevent flashing when tabbing through fields quickly, only refreshing entries when all inputs blurred
                if (!this.state.popoverAnchor) {
                    setTimeout(() => {
                        window.dispatchEvent(new Event('workhourSaved'));
                    }, 1000);
                }
                //timeout because of throttle and outside the above if so that all bulk entries are tracked
                setTimeout(() => {
                    //this.context.functions.sendAnalytics(eventFunction, analyticsData);
                }, 600)
            }
        } catch (e) {
            enqueueSnackbar(this.tr('Saving entry failed!'), { variant: 'error' });
            this.shouldUpdateEntries = true;
            window.dispatchEvent(new Event('workhourSaved'));
            console.log(e);
        }
    };

    getDefaultIds = async () => {
        let defaultProjects = (await DataHandler.get({ url: 'timetracker/sidebar' })) || [];
        if (defaultProjects) {
            defaultProjects = defaultProjects.map((p) => p.id);
        }
        if (!defaultProjects || defaultProjects.length === 0) {
            defaultProjects = await DataHandler.get({ url: 'timetracker/defaultProjects' });
        }
        return defaultProjects;
    };

    removePinnedValues = (projects, pinned) => {
        return projects.filter((p) => pinned.findIndex((pp) => pp.key == p.key) == -1);
    };

    getProjects = async (projectIds = null, template = null) => {
        const { preventMulticompanyHourentries } = this.props;
        const { userObject } = this.context;
        let projects = (await DataHandler.post({ url: 'timetracker/get_bulk_projects' }, { ...this.sortData, projectIds, template })) || [];
        if (projectIds == 'own_projects') {
            const pinned = this.getPinnedProjects();
            projects = [...pinned, ...this.removePinnedValues(projects, pinned)];
        }
        projects = projects.sort(this.sortByPinned);
        if (preventMulticompanyHourentries) {
            projects = projects.filter((p) => p.company == userObject.companies_id);
        }
        this.setState({ projects, loadingInitialProjects: false, loadingOwnProjects: false }, () => {
            if (!!template || projectIds == 'own_projects') {
                this.saveProjects(this.state.projects);
            }
        });
    };

    // onSearchTriggered = (searchParams) => {
    //     const { freetextSearchTerm } = searchParams;
    //     this.getProjects(null, null, freetextSearchTerm)
    // };

    getTemplates = async () => {
        const templates = (await DataHandler.get({ url: 'timetracker/bulk_projects_templates' })) || [];
        this.setState({ templates });
    };

    loadFromTemplate = async (template) => {
        this.setState(
            {
                selectedTemplate: template,
            },
            () => {
                this.getProjects(null, template.projects);
            }
        );
    };

    getTasks = async () => {
        const tasks = (await DataHandler.get({ url: 'dialogs/resources' })) || [];
        this.setState({ tasks: tasks.filter((t) => t.done != 1) });
    };

    saveProjects = async (projects) => {
        const { enqueueSnackbar, demoMode } = this.props;
        if (demoMode) return;
        try {
            await DataHandler.post({ url: 'timetracker/bulk_projects' }, { data: projects });
        } catch (e) {
            console.log(e);
            enqueueSnackbar(this.tr('Saving projects failed!'), {
                variant: 'error',
            });
            this.getProjects();
        }
    };

    saveProjectTemplate = (template) => {
        const { enqueueSnackbar } = this.props;
        const { name, update } = template;
        const templates = cloneDeep(this.state.templates);
        const templateTitle = name;
        const foundIndex = templates.findIndex((t) => t.title == templateTitle);
        if (!update && foundIndex != -1) {
            enqueueSnackbar(this.tr('A template with the same name already exists!'), {
                variant: 'error',
            });
            return;
        }
        let finalTemplate;
        if (update) {
            finalTemplate = {
                ...templates[foundIndex],
                projects: this.state.projects,
            };
            templates[foundIndex] = finalTemplate;
        } else {
            finalTemplate = {
                id: templateTitle,
                title: templateTitle,
                label: templateTitle,
                projects: this.state.projects,
            };
            templates.push(finalTemplate);
        }

        this.setState({ showAddTemplateDialog: false, selectedTemplate: finalTemplate }, () => {
            this.saveTemplates(templates, true);
        });
    };

    saveTemplates = async (templates, showSuccessSnackbar = false) => {
        const { enqueueSnackbar } = this.props;
        try {
            await DataHandler.post({ url: 'timetracker/bulk_projects_templates' }, { data: templates });
            if (showSuccessSnackbar) {
                enqueueSnackbar(this.tr('List saved!'), {
                    variant: 'success',
                });
            }
            setTimeout(() => {
                this.getTemplates();
            }, 1000);
        } catch (e) {
            console.log(e);
            enqueueSnackbar(this.tr('Saving list failed!'), {
                variant: 'error',
            });
        }
    };

    // Parsing, filtering, sorting, formatting etc. helpers

    getColumns = () => {
        const staticColumnConfig = {
            showMenu: false,
            resizeable: false,
            showResizeMarker: false,
            moveable: false,
            hideable: false,
            visibleInToolbar: true,
        };

        const dynamicColumnConfig = {
            showMenu: true,
            resizeable: true,
            showResizeMarker: true,
            moveable: true,
            hideable: true,
            visibleInToolbar: false,
        };

        const columns = [
            ...(!this.props.demoMode ? [{
                field: 'menu',
                name: 'menu',
                header: '',
                width: 50,
                ...staticColumnConfig,
            },
            {
                field: 'favorite',
                name: 'favorite',
                header: '',
                width: 35,
                ...staticColumnConfig,
            }] : []),
            {
                field: 'customer',
                name: 'customer',
                header: this.tr('Account'),
                width: 200,
                ...dynamicColumnConfig,
            },
            {
                field: 'name',
                name: 'name',
                header: this.tr('Project'),
                width: 200,
                ...dynamicColumnConfig,
                hideable: false,
            },
            {
                field: 'jobtype',
                name: 'jobtype',
                header: this.tr('Jobtype'),
                width: 200,
                ...dynamicColumnConfig,
                hideable: false,
            },
            {
                field: 'description',
                name: 'description',
                header: this.tr('Default description'),
                width: 200,
                ...dynamicColumnConfig,
            },
        ];

        const { timeTracker } = this.context;

        if (timeTracker.resourcingEnabled && this.context.addons && this.context.addons.resourcing) {
            columns.splice(4, 0, {
                field: 'task',
                name: 'task',
                header: this.tr('Task'),
                width: 200,
                ...dynamicColumnConfig,
            });
        }
        return columns;
    };

    formatHours = (hours, addDifferencePrefix = false) => {
        if (!hours && !addDifferencePrefix) {
            return hours;
        }
        const endsWithDot = String(hours).endsWith('.');
        const numeric = Number(hours || 0);
        if (endsWithDot || !Number.isFinite(numeric)) {
            return hours;
        }
        let value = Math.round(numeric * 100) / 100;

        if (addDifferencePrefix) {
            value = this.addDifferencePrefix(value);
        }
        return value;
    };

    addDifferencePrefix = (value) => {
        if (value > 0) {
            return `+${value}`;
        } else if (value == 0) {
            return `±${value}`;
        }
        return value;
    };

    parseEntries = () => {
        const { events } = this.props;
        let entries = {};
        for (const entry of events) {
            if (entry.is_task == 1) continue;
            let projectId;
            if (entry.subproject && entry.subproject.id) {
                projectId = entry.subproject.id;
            } else {
                projectId = entry.project.id;
            }
            const jobtypeId = entry.worktask?.id && Number(entry.worktask?.id) > 0 ? entry.worktask?.id : '0';
            const taskId = entry.wh_projects_resource?.id && Number(entry.wh_projects_resource?.id) > 0 ? entry.wh_projects_resource?.id : '0';
            const id = `${projectId}_${jobtypeId}_${taskId}`;
            const date = moment(entry.start).format('YYYY-MM-DD');
            const diff = entry.end - entry.start;
            const entryHours = diff / 3600000;
            const entryObj = entries[id] || {};
            const entryDateObj = entryObj[date] || {};
            const hours = Number(entryDateObj.hours || 0) + entryHours;
            const arr = [...(entryDateObj.entries || [])];
            arr.push(entry);
            let disabledObj = {};
            if (arr.length > 1 || !entry.editable) {
                disabledObj = {
                    disabled: true,
                };
            }
            entries = {
                ...entries,
                [id]: {
                    ...entries[id],
                    [date]: {
                        entries: arr,
                        hours,
                        description: entry.description,
                        key: id,
                        date,
                        ...disabledObj,
                    },
                },
            };
        }
        return entries;
    };

    getPinnedProjects = () => {
        return this.state.projects.filter((p) => p.pinned == '1');
    };

    getProjectsWithTrackedHours = () => {
        this.setState({ loadingTrackedProjects: true, selectedTemplate: null }, async () => {
            const start = this.state.shownDays[0];
            const end = this.state.shownDays[this.state.shownDays.length - 1];
            const dates = { start_date: start.format('YYYY-MM-DD'), end_date: end.format('YYYY-MM-DD') };
            const projectsWithHours = await DataHandler.get({ url: `timetracker/trackedProjects`, dates });
            const trackedProjects = this.parseEntriesWithProjects(projectsWithHours);
            const pinned = this.getPinnedProjects();
            const projects = [...pinned, ...this.removePinnedValues(trackedProjects, pinned)];
            this.setState({ projects: projects.sort(this.sortBySortData).sort(this.sortByPinned), loadingTrackedProjects: false }, () => {
                this.saveProjects(projects);
            });
        });
    };

    parseEntriesWithProjects = (projectIds) => {
        const { events } = this.props;
        const projects = [];
        events
            .filter((e) => e.is_task != 1)
            .forEach((entry) => {
                let projectId;
                let projectName;
                if (entry.subproject && entry.subproject.id) {
                    projectId = entry.subproject.id;
                    projectName = entry.subproject.name;
                } else {
                    projectId = entry.project.id;
                    projectName = entry.project.name;
                }
                const jobtypeId = entry.worktask?.id && Number(entry.worktask?.id) > 0 ? entry.worktask?.id : '0';
                const taskId = entry.wh_projects_resource?.id && Number(entry.wh_projects_resource?.id) > 0 ? entry.wh_projects_resource?.id : '0';
                const key = `${projectId}_${jobtypeId}_${taskId}`;
                if (projects.findIndex((p) => p.key == key) == -1 && projectIds.findIndex((pId) => pId == projectId) != -1) {
                    projects.push({
                        key,
                        id: projectId,
                        name: projectName,
                        can_add_hours: true,
                        customer: {
                            id: entry.unit && Number(entry.unit.id) > 0 ? entry.unit.id : entry.customer?.id,
                            name: entry.unit && Number(entry.unit.id) > 0 ? entry.unit.name : entry.customer?.name,
                        },
                        description: entry.description,
                        jobtype: {
                            id: jobtypeId,
                            name: entry.worktask.name,
                        },
                        task: {
                            id: taskId,
                            name: entry.wh_projects_resource?.name,
                        },
                    });
                }
            });
        return projects;
    };

    getInitialProjects = async () => {
        const projectIds = await this.getDefaultIds();
        this.getProjects(projectIds);
    };

    getTasksForProject = (project) => {
        return (this.state.tasks || []).filter((t) => t.projects_id == project.id);
    };

    getEntries = () => {
		const { onViewChange, onRequestData } = this.props;

        const start = this.state.shownDays[0];
        const end = this.state.shownDays[this.state.shownDays.length - 1];

		onViewChange?.("week", start.toDate(), end.toDate());
        onRequestData(this.state.shownDays[0], this.state.shownDays[this.state.shownDays.length - 1], true);
    };

    sortByPinned = (a, b) => {
        const aPinned = Number(a.pinned || '0');
        const bPinned = Number(b.pinned || '0');
        return bPinned - aPinned;
    };

    sortBySortData = (a, b) => {
        let first = a;
        let second = b;
        if (this.sortData?.sortasc == true) {
            first = b;
            second = a;
        }
        let firstTerm = first.name;
        let secondTerm = second.name;
        switch (this.sortData?.sortby) {
            case 'customer':
                firstTerm = first.customer?.name;
                secondTerm = second.customer?.name;
                break;
            case 'task':
                firstTerm = first.task?.name;
                secondTerm = second.task?.name;
                break;
            case 'jobtype':
                firstTerm = first.jobtype?.name;
                secondTerm = second.jobtype?.name;
                break;
            case 'description':
                firstTerm = first.description;
                secondTerm = second.description;
                break;
        }
        firstTerm += first.key;
        secondTerm += second.key;
        if ((firstTerm || '').toLowerCase() < (secondTerm || '').toLowerCase()) {
            return -1;
        } else if ((firstTerm || '').toLowerCase() > (secondTerm || '').toLowerCase()) {
            return 1;
        }
        return 0;
    };

    // Action handlers

    onProjectSelected = (e) => {
        const { data } = e;
        if (!data) return;
        let projects = this.state.projects;
        const jobtype = {
            id: '0',
            name: '',
        };
        const description = '';
        const key = `${data.id}_${jobtype.id}_0`;
        const project = {
            key,
            id: data.id,
            name: data.pro_name,
            customer: {
                id: data.customer,
                name: data.cus_name,
            },
            can_add_hours: true,
            description,
            jobtype: {
                id: jobtype.id,
                name: jobtype.name,
            },
            task: {
                id: '0',
                name: '',
            },
        };
        if (this.state.projects.findIndex((p) => p.key == key) != -1) {
            this.props.enqueueSnackbar(this.tr("This project already exists in the list, but doesn't have a jobtype. Please select a jobtype for that row before adding it again."), {
                variant: 'error',
            });
            return;
        }
        projects = [...this.state.projects, project];
        this.setState({ projects: projects.sort(this.sortBySortData).sort(this.sortByPinned), selectedTemplate: null }, () => {
            this.saveProjects(projects);
        });
    };

    showEditExistingEntriesDialog = (project, editData) => {
        this.setState({
            showEmptyListDialog: true,
            emptyListDialogData: {
                onConfirm: () => this.editExistingEntries(project, editData),
                onCancel: this.closeEmptyListDialog,
                header: this.tr('Edit existing entries?'),
                translatedConfirmButtonText: this.tr('Edit entries'),
                cancelButtonText: this.tr('Keep entries'),
                warning: () => this.tr('Do you also want to edit the entries that already exist this week?'),
            },
        });
    };

    onRemoveProject = (project) => {
        this.setState({
            showEmptyListDialog: true,
            emptyListDialogData: {
                onConfirm: () => this.removeProject(project),
                onCancel: this.closeEmptyListDialog,
                header: this.tr('Hide row'),
                translatedConfirmButtonText: this.tr('Hide row'),
                cancelButtonText: this.tr('Cancel'),
                warning: () => this.tr('Are you sure you want to hide this row from the list?'),
            },
        });
    };

    removeProject = (project) => {
        const projects = cloneDeep(this.state.projects);
        const foundIndex = projects.findIndex((p) => p.key == project.key);
        if (foundIndex != -1) {
            projects.splice(foundIndex, 1);
        }
        this.setState({ projects, selectedTemplate: null, showEmptyListDialog: false }, () => {
            this.saveProjects(projects);
        });
    };

    onCopyRow = (project) => {
        const customer = project.unit && project.unit.id != 0 ? project.unit : project.customer;
        this.onProjectSelected({
            data: {
                id: project.id,
                pro_name: project.name,
                customer: customer.id,
                cus_name: customer.name,
            },
        });
    };

    validateRowUpdate = (key, value, row) => {
        const { enqueueSnackbar } = this.props;
        const updatedRow = {
            ...row,
            [key]: value,
        };
        if (isEqual(row[key], updatedRow[key])) return false;
        const projects = this.state.projects;
        const newKey = `${updatedRow.id}_${updatedRow.jobtype?.id}_${updatedRow.task?.id}`;
        const sameIndex = projects.findIndex((d) => d.key == newKey);
        const oldIndex = projects.findIndex((d) => d.key == row.key);
        let itemNameChanged = false;
        if (oldIndex == sameIndex && (key == 'jobtype' || key == 'task')) {
            if (row[key]?.name != updatedRow[key]?.name) {
                itemNameChanged = true;
            }
        }
        if (!itemNameChanged && sameIndex != -1 && key != 'description') {
            if (projects[sameIndex].jobtype?.name != updatedRow.jobtype?.name) {
                enqueueSnackbar(this.tr('A row with the same information is already on this list, but with an old jobtype name ${jobtype}.', { jobtype: projects[sameIndex].jobtype?.name }), {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar(this.tr('A row with the same information is already on this list.'), {
                    variant: 'error',
                });
            }
            return false;
        } else {
            if (this.state.entries[row.key] && !(key == 'description' && !value) && !itemNameChanged) {
                this.editExistingData = {
                    entries: this.state.entries[row.key],
                    overrideDescription: key == 'description',
                    oldRow: row,
                };
            }
        }
        return true;
    };

    onUpdateRow = (row) => {
        const projects = cloneDeep(this.state.projects);
        const foundIndex = projects.findIndex((d) => d.key == row.key);
        // key has not been updated yet, will do it here ->
        if (foundIndex != -1) {
            const rowWithNewKey = {
                ...row,
                key: `${row.id}_${row.jobtype?.id}_${row.task?.id}`,
            };
            projects[foundIndex] = rowWithNewKey;
        }

        // sorting data just to update UI in real time -> no jumping after reloading data
        this.setState({ jobtypeErrorKey: undefined, descriptionErrorKey: undefined, projects: projects.sort(this.sortBySortData).sort(this.sortByPinned), selectedTemplate: null }, () => {
            this.saveProjects(projects);
            if (this.editExistingData) {
                this.showEditExistingEntriesDialog(row, this.editExistingData);
                this.editExistingData = undefined;
            }
        });
    };

    addOwnProjects = () => {
        this.setState({ loadingOwnProjects: true, selectedTemplate: null }, () => {
            this.getProjects('own_projects');
        });
    };

    openAddTemplateDialog = () => {
        this.setState({ showAddTemplateDialog: true });
    };

    openRemoveTemplateDialog = (template) => {
        this.savedListDropdown.current && this.savedListDropdown.current.blur();
        this.setState({
            showEmptyListDialog: true,
            emptyListDialogData: {
                onConfirm: () => this.removeTemplate(template),
                onCancel: this.closeEmptyListDialog,
                header: this.tr('Remove saved list'),
                translatedConfirmButtonText: this.tr('Remove list'),
                cancelButtonText: this.tr('Cancel'),
                warning: () => this.tr('Are you sure you want to remove this saved list?'),
            },
        });
    };

    removeTemplate = (template) => {
        const templates = cloneDeep(this.state.templates);
        const foundIndex = templates.findIndex((t) => t.title == template.title);
        if (foundIndex != -1) {
            templates.splice(foundIndex, 1);
        }
        let selectedTemplate = this.state.selectedTemplate;
        if (isEqual(selectedTemplate, template)) {
            selectedTemplate = null;
        }
        this.setState({ templates, selectedTemplate, showEmptyListDialog: false }, () => {
            this.saveTemplates(templates);
        });
    };

    closeAddTemplateDialog = () => {
        this.setState({ showAddTemplateDialog: false });
    };

    openEmptyListDialog = () => {
        const pinnedProjects = this.getPinnedProjects();
        let warningText = this.tr('Are you sure you want to clear the list?');
        const confirmAction = () => this.onEmptyProjects(true);
        let cancelAction = () => this.closeEmptyListDialog();
        let confirmText = this.tr('Clear list');
        let cancelText = this.tr('Cancel');
        if (pinnedProjects.length > 0) {
            warningText = this.tr('Do you also want to remove the starred rows?');
            cancelAction = () => this.onEmptyProjects(false);
            confirmText = this.tr('Yes');
            cancelText = this.tr('No');
        }
        this.setState({
            showEmptyListDialog: true,
            emptyListDialogData: {
                onConfirm: confirmAction,
                onCancel: cancelAction,
                testid: 'clear-list-dialog',
                header: this.tr('Clear list'),
                translatedConfirmButtonText: confirmText,
                cancelButtonText: cancelText,
                warning: () => warningText,
            },
        });
    };

    closeEmptyListDialog = () => {
        this.setState({ showEmptyListDialog: false });
    };

    onEmptyProjects = (emptyAll) => {
        const projects = emptyAll ? [] : this.state.projects.filter((p) => p.pinned == '1');
        this.setState({ projects, selectedTemplate: null, showEmptyListDialog: false }, () => {
            this.saveProjects(projects);
        });
    };

    onSortRows = (name, sortasc) => {
        this.sortData = { sortby: name, sortasc };
        this.getProjects();
    };

    closePopper = (e) => {
        if (e) {
            const target = e.target;
            if (target.name == 'hourEntryInput') {
                return;
            }
        }
        if (!this.checkDescription(this.state.selectedEntry)) return;
        this.setState({ popoverAnchor: undefined, selectedEntry: undefined });
    };

    checkDescription = (selectedEntry) => {
        const { enqueueSnackbar } = this.props;
        const {
            functions: { getTimeTrackerSettings },
        } = this.context;

        if (!selectedEntry) return true;
        if ((selectedEntry.hours || 0) == 0 || !this.edited) return true;
        const project = this.state.projects.find((p) => p.key == selectedEntry.key);
        const timeTrackerSettings = getTimeTrackerSettings(project.company);
        const description = selectedEntry.description || project.description;
        if (timeTrackerSettings.hour_entry_description && !description) {
            enqueueSnackbar(this.tr('Please enter a description.'), {
                variant: 'error',
            });
            this.setState({ descriptionErrorKey: selectedEntry.key }, () => {
                const cell = document.getElementsByClassName('bulkEntryDescriptionCell')[0];
                if (cell && cell.scrollIntoView) {
                    cell.scrollIntoView();
                }
            });
            return false;
        }
        return true;
    };

    onInputFocus = (e, entryDay, defaultEntry) => {
        this.onFocusSelectionTimeout && clearTimeout(this.onFocusSelectionTimeout);
        const selectedEntry = entryDay || defaultEntry;
        this.setState({ popoverAnchor: e.target, selectedEntry }, () => {
            this.onFocusSelectionTimeout = setTimeout(() => {
                this.state.popoverAnchor && this.state.popoverAnchor.select && this.state.popoverAnchor.select();
            }, 50);
        });
    };

    onInputChange = (e) => {
        const selectedEntry = cloneDeep(this.state.selectedEntry);
        const entries = cloneDeep(this.state.entries);
        if (!selectedEntry) return;
        const value = e.target.value.replace(',', '.');
        const endsWithDot = value.endsWith('.');
        if (!endsWithDot && !Number.isFinite(Number(value || 0))) {
            return;
        }
        let hours = endsWithDot ? value : Number(value || 0);
        if (hours == '0') hours = '';
        selectedEntry.hours = hours;
        entries[selectedEntry.key] = {
            ...entries[selectedEntry.key],
            [selectedEntry.date]: {
                ...selectedEntry,
            },
        };
        this.edited = true;
        this.setState({ entries, selectedEntry });
    };

    onDescriptionInputChange = (e) => {
        const selectedEntry = cloneDeep(this.state.selectedEntry);
        const entries = cloneDeep(this.state.entries);
        if (!selectedEntry) return;
        const description = e.target.value;
        selectedEntry.description = description;
        entries[selectedEntry.key] = {
            ...entries[selectedEntry.key],
            [selectedEntry.date]: {
                ...selectedEntry,
            },
        };
        this.edited = true;
        this.setState({ entries, selectedEntry, descriptionErrorKey: undefined });
    };

    onDescriptionInputFocus = (e) => {
        e.persist();
        setTimeout(() => {
            e.target.select();
        }, 0);
    };

    onChangeTime = (event) => {
        const selectedEntry = cloneDeep(this.state.selectedEntry);
        const entries = cloneDeep(this.state.entries);
        if (!selectedEntry) return;
        const { name, value } = event.target;
        let fullHours = Number(selectedEntry.hours || 0);
        const n = new Date(0, 0);
        n.setMinutes(fullHours * 60);
        const hours = n.getHours();
        const minutes = n.getMinutes();
        if (name == 'hours') {
            const minuteDecimal = minutes / 60;
            fullHours = value + minuteDecimal;
        } else {
            const minuteDecimal = value / 60;
            fullHours = hours + minuteDecimal;
        }
        if (fullHours == '0') fullHours = '';
        selectedEntry.hours = fullHours;

        entries[selectedEntry.key] = {
            ...entries[selectedEntry.key],
            [selectedEntry.date]: {
                ...selectedEntry,
            },
        };
        this.edited = true;
        this.setState({ entries, selectedEntry }, () => {
            if (this.state.popoverAnchor && this.state.popoverAnchor.focus) {
                this.state.popoverAnchor.focus();
            }
        });
    };

    handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key == 'Escape' || e.key == 'Esc') {
            e.preventDefault();
            this.state.popoverAnchor && this.state.popoverAnchor.blur && this.state.popoverAnchor.blur();
            this.closePopper();
        } else if (e.key == 'Tab') {
            if (!this.checkDescription(this.state.selectedEntry)) {
                e.preventDefault();
            }
        }
    };

    handleDescriptionKeyPress = (e) => {
        if (e.key == 'Tab') {
            e.preventDefault();
            if (this.checkDescription(this.state.selectedEntry)) {
                this.state.popoverAnchor && this.state.popoverAnchor.focus && this.state.popoverAnchor.focus();
            }
        } else if (e.key === 'Enter' || e.key == 'Escape' || e.key == 'Esc') {
            e.preventDefault();
            this.state.popoverAnchor && this.state.popoverAnchor.blur && this.state.popoverAnchor.blur();
            this.closePopper();
        }
    };

    onListScroll = (scrollTop) => {
        if (!this.entryContainer.current) return;
        if (!this.scrollingEntryContainer) {
            this.scrollingList = true;
            this.entryContainer.current.scrollTop = scrollTop;
        }
        this.scrollingEntryContainer = false;
    };

    onEntryContainerScroll = () => {
        if (!this.entryContainer.current || !this.list.current) return;
        if (!this.scrollingList) {
            this.scrollingEntryContainer = true;
            const scrollTop = this.entryContainer.current.scrollTop;
            this.list.current.setScrollTop(scrollTop);
        }
        this.scrollingList = false;
    };

    getSelectedDateRange = () => {
        const start = this.state.shownDays[0];
        const end = this.state.shownDays[this.state.shownDays.length - 1];

        return {
            startDate: format(start, 'YYYY-MM-DD'),
            endDate: format(end, 'YYYY-MM-DD'),
        };
    };

    // Renders

    renderPopper = () => {
        const { popoverAnchor, descriptionErrorKey } = this.state;
        const { isMyDay } = this.props;
        const { hours, minutes } = popoverAnchor ? this.getSelectedEntryTime() : { hours: 0, minutes: 0 };
        const project = this.state.projects.find((p) => p.key == this.state.selectedEntry?.key);
        const defaultDescription = project?.description || '';
        const description = popoverAnchor ? this.state.selectedEntry.description || '' : '';
        const popperOptions = {
            modifiers: {
                preventOverflow: { enabled: true, boundariesElement: 'viewport', padding: { bottom: 50 } },
            },
        };
        return (
            <Popper anchorEl={popoverAnchor} open={!!popoverAnchor} popperOptions={popperOptions} disablePortal={false} placement="left-start">
                <ClickAwayWrapper active={!!popoverAnchor} onClickAway={this.closePopper}>
                    <div className={styles.hourSelector}>
                        <div className={styles.hours}>
                            <ScrollList noTab name="hours" value={hours} onChange={this.onChangeTime} options={this.hours} />
                            <ScrollList noTab name="minutes" value={minutes} onChange={this.onChangeTime} options={this.minutes} />
                        </div>
                        <div className={styles.description}>
                            <p>{this.tr('Description')}</p>
                            <textarea
                                className={!!project && descriptionErrorKey == project.key ? styles.error : ''}
                                placeholder={defaultDescription}
                                value={description}
                                onChange={this.onDescriptionInputChange}
                                onFocus={this.onDescriptionInputFocus}
                                onKeyDown={this.handleDescriptionKeyPress}
                            />
                        </div>
                    </div>
                </ClickAwayWrapper>
            </Popper>
        );
    };

    renderNoDataOverlay = () => {
        const { projects, loadingInitialProjects } = this.state;
        if (loadingInitialProjects || projects.length > 0) return null;
        return (
            <div className={styles.noDataOverlay}>
                <NoDataImage />
                <h1>{this.tr('No projects selected.')}</h1>
                <p>{this.tr('Add projects from the dropdown to start using bulk entry.')}</p>
            </div>
        );
    };

    render() {
        const {
            projects,
            shownDays,
            tasks,
            selectedEntry,
            entries,
            loadingOwnProjects,
            loadingTrackedProjects,
            listScrollBarHeight,
            entryContainerScrollbarWidth,
            showEmptyListDialog,
            emptyListDialogData,
            showAddTemplateDialog,
            templates,
            selectedTemplate,
            jobtypeErrorKey,
            descriptionErrorKey,
        } = this.state;
        const { selectedDays, isMyDay, demoMode } = this.props;
        const {
            functions: { checkPrivilege, getTimeTrackerSettings },
        } = this.context;
        const today = moment();
        const filteredDays = shownDays.filter((s) => (selectedDays || []).includes(s.day()));
        const entryContainerWidth = this.getEntryContainerWidth();
        const visibleTotals = {};
        let fullVisibleTotal = 0;
        let totalPercentage = 0;
        const fullTotal = this.getFullTotal();
        const percentageOfFullTotal = this.getPercentageOfWeeklyTotal(fullTotal);
        const differenceInPercentage = percentageOfFullTotal - 100;
        const weekDate = (this.state.shownDays || []).length > 1 ? this.state.shownDays[1] : this.state.shownDays[0];
        const weekNumber = weekDate
            ? format(weekDate.toDate(), 'WW', {
                  weekStartsOn: this.context.calendar.startOfWeek,
              })
            : '-';
        return (
            <div id={styles.bulkEntry} className="no-my-day-drag">
                {!demoMode && (
                    <div className={styles.topSection}>
                        <div className={styles.editProjects}>
                            <div className={styles.top}>
                                {/* <AdvancedSearch hideAdvanced noRequests onSearchTrigger={this.onSearchTriggered} /> */}
                                <ProjectTreeDropdown
                                    name="project" // TODO: Onko tarpeellinen?
                                    label={this.tr('Add project to list')}
                                    value={undefined}
                                    queryParameters={{
                                        noLockedCustomers: true,
                                        right: 'read',
                                        company: this.props.preventMulticompanyHourentries ? this.context.userObject.companies_id : undefined,
                                        context: 'workhours',
                                    }}
                                    treeDropdownProps={{
                                        activateBestMatch: true,
                                        highlightMatches: true,
                                        useTooltip: true,
                                        growOptionContainer: true,
                                        inputClassName: 'selectProjectInput',
                                        usePopper: true,
                                    }}
                                    onlyOwnProjects={false}
                                    onSelect={this.onProjectSelected}
                                />
                                {templates.length > 0 && (
                                    <DataList
                                        ref={this.savedListDropdown}
                                        label={this.tr('Saved lists')}
                                        value={selectedTemplate}
                                        name="template"
                                        blurInputOnSelect
                                        onDeletePressed={this.openRemoveTemplateDialog}
                                        customOption={CustomTemplateOption}
                                        options={(templates || []).map((t) => ({ ...t, id: t.title, label: t.title }))}
                                        onChange={this.loadFromTemplate}
                                        className={styles.templateDropdown}
                                        shownCount={20}
                                    />
                                )}
                                {projects.length > 0 && (
                                    <button className={`${styles.textButton}`} onClick={this.openAddTemplateDialog}>
                                        <Save />
                                        <p>{this.tr('Save list')}</p>
                                    </button>
                                )}
                            </div>
                            {loadingOwnProjects || loadingTrackedProjects ? (
                                <Loading  />
                            ) : (
                                <InsightDropDown anchorVertical={30} transformHorizontal="left" anchorHorizontal="left" tabs={this.viewPresets} titleLabel={this.tr('Show list of')} />
                            )}
                        </div>
                        <div className={styles.weekSelector}>
                            <button onClick={this.setPreviousWeek}>
                                <KeyboardArrowLeft />
                            </button>
                            <h2>{`${this.tr('Week')} ${weekNumber}`}</h2>
                            <button onClick={this.setNextWeek}>
                                <KeyboardArrowRight />
                            </button>
                        </div>
                    </div>
                )}
                <div className={styles.mainContent} style={{ width: '100%' }}>
                    <div className={styles.listContainer} style={{ width: `calc(100% - ${entryContainerWidth}px` }}>
                        <List
                            ref={this.list}
                            enforceMinimumWidths={false}
                            className={styles.list}
                            columns={this.columns}
                            data={projects}
                            rowKey="key"
                            idType="string"
                            height={this.props.listHeight || 'fitRemaining'}
                            saveColumnConfig
                            userListSettingsKey="bulk_entry_project_list_settings"
                            onScroll={this.onListScroll}
                            sharedData={{ tasks }}
                            rowProps={{
                                jobtypeErrorKey,
                                descriptionErrorKey,
                                onUpdate: this.onUpdateRow,
                                onRemoveProject: this.onRemoveProject,
                                onCopyRow: this.onCopyRow,
                                validateRowUpdate: this.validateRowUpdate,
                                demoMode: this.props.demoMode
                            }}
                            onSortRows={this.onSortRows}
                            noStateData
                            rowHeight={60}
                            trimHeight={-80}
                            minHeight={0}
                            listRowType={BulkEntryListRow}
                        />
                    </div>
                    <div className={styles.entryContainer} style={{ width: entryContainerWidth }}>
                        <div className={styles.header} style={{ paddingRight: entryContainerScrollbarWidth + 6 }}>
                            {filteredDays.map((day) => {
                                const dayString = day.format('YYYY-MM-DD');
                                return (
                                    <div key={dayString} className={`${styles.column} ${this.isHoliday(dayString) ? styles.holiday : ''} ${day.isSame(today, 'day') ? styles.selected : ''}`}>
                                        <h3>{day.format('ddd')}</h3>
                                        <p>{day.format(this.context.userObject.dateFormatShort)}</p>
                                    </div>
                                );
                            })}
                            <div className={styles.column}>
                                <h3>{this.tr('Week tot.')}</h3>
                            </div>
                            {!demoMode && (
                                <div className={styles.column}>
                                    <h3>{this.tr('% of goal')}</h3>
                                </div>
                            )}
                            {!isMyDay && !demoMode && <div className={styles.emptyColumn} />}
                        </div>
                        <div style={{ paddingBottom: listScrollBarHeight + (isMyDay ? 0 : 8) }} ref={this.entryContainer} onScroll={this.onEntryContainerScroll} className={styles.rows}>
                            {projects.map((row) => {
                                const invalidJobtype = !row.jobtype?.id || row.jobtype?.id == 0;
                                const invalidTask = (getTimeTrackerSettings().hour_entry_task && this.getTasksForProject(row).length > 0 && Number(row.task?.id) < 1) ? true : (Number(row.task?.id) > 0 ? this.getTasksForProject(row).findIndex((t) => t.id == row.task?.id) == -1 : false);
                                const entriesForRow = entries[row.key] || {};
                                const totalHours = Object.values(entriesForRow).reduce((prev, val) => {
                                    return (prev += Number.isFinite(Number(val.hours)) ? Number(val.hours) : 0);
                                }, 0);
                                const weeklyPercentage = this.getPercentageOfWeeklyTotal(totalHours);
                                fullVisibleTotal += totalHours;
                                totalPercentage += weeklyPercentage;
                                return (
                                    <div data-testid={`bulkdays_${row.key}`} key={row.key} className={styles.row}>
                                        {filteredDays.map((day, i) => {
                                            const dayString = day.format('YYYY-MM-DD');
                                            const entryDay = entriesForRow[dayString];
                                            visibleTotals[dayString] = (visibleTotals[dayString] || 0) + Number(entryDay?.hours || 0);
                                            const input = (
                                                <input
                                                    data-testid={`bulk-day-${day.format('d')}`}
                                                    autoComplete="off"
                                                    name="hourEntryInput"
                                                    className={entryDay?.entries && entryDay?.entries[0]?.status == '-1' ? 'declined' : ''}
                                                    disabled={invalidTask || invalidJobtype || entryDay?.disabled || (!this.props.demoMode && !checkPrivilege('workhours', 'write')) || (!row.can_add_hours && !entryDay?.hours)}
                                                    onFocus={(e) => this.onInputFocus(e, entryDay, { date: dayString, key: row.key })}
                                                    onChange={this.onInputChange}
                                                    onKeyDown={this.handleKeyPress}
                                                    value={this.formatHours(entryDay?.hours || '')}
                                                />
                                            );
                                            return (
                                                <div key={dayString} className={styles.column}>
                                                    <div
                                                        className={`${styles.box} ${this.isHoliday(dayString) ? styles.holiday : ''} ${
                                                            selectedEntry?.date == dayString && selectedEntry?.key == row.key ? styles.selected : ''
                                                        }`}
                                                    >
                                                        {invalidTask || invalidJobtype ? (
                                                            <Tooltip
                                                                placement="top"
                                                                title={invalidTask ? this.tr('The selected task is not valid.') : this.tr('Please select a jobtype to track hours.')}
                                                            >
                                                                {input}
                                                            </Tooltip>
                                                        ) : (
                                                            input
                                                        )}
                                                    </div>
                                                    {this.props.demoMode && i == this.props.animationIndex && !this.state.popoverAnchor && <div className={styles.animation}>
                                                        <Lottie
                                                            options={{
                                                                loop: true,
                                                                autoplay: true,
                                                                animationData: click,
                                                            }}
                                                            speed={0.7}
                                                            height={200}
                                                            width={200}
                                                        />
                                                    </div>}
                                                </div>
                                            );
                                        })}
                                        <div className={styles.column} data-testid="bulk_weektotal">
                                            {this.formatHours(totalHours)}
                                        </div>
                                        {!demoMode && <div className={styles.column}>{weeklyPercentage + ' %'}</div>}
                                        {!isMyDay && !demoMode && <div className={styles.emptyColumn} />}
                                    </div>
                                );
                            })}
                        </div>
                        {this.renderPopper()}
                    </div>
                    {this.renderNoDataOverlay()}
                </div>
                <div className={styles.footer}>
                    <div className={styles.left}>
                        <div className={`${styles.column} ${styles.end}`}>
                            <h3>{this.tr("Selected Projects' Total")}</h3>
                            <h3>{this.tr('Total')}</h3>
                            <p>{this.tr('Difference to Goal')}</p>
                        </div>
                    </div>
                    <div className={styles.right} style={{ paddingRight: entryContainerScrollbarWidth + 6, width: entryContainerWidth }}>
                        {filteredDays.map((day) => {
                            const dayString = day.format('YYYY-MM-DD');
                            const dayTotal = Object.values(entries).reduce((prev, entryObj) => {
                                const dateObj = entryObj[dayString] || {};
                                return (prev += Number.isFinite(Number(dateObj.hours)) ? Number(dateObj.hours) : 0);
                            }, 0);
                            return (
                                <div key={dayString} className={styles.column}>
                                    <h3>{this.formatHours(visibleTotals[dayString] || 0)}</h3>
                                    <h3>{this.formatHours(dayTotal)}</h3>
                                    <p>{this.formatHours(dayTotal - this.getWorkhoursForDate(dayString), true)}</p>
                                </div>
                            );
                        })}
                        <div className={styles.column}>
                            <h3>{this.formatHours(fullVisibleTotal)}</h3>
                            <h3>{this.formatHours(fullTotal)}</h3>
                            <p>{this.formatHours(fullTotal - this.getWeeklyHours(), true)}</p>
                        </div>
                        {!demoMode && (
                            <div className={styles.column}>
                                <h3>{this.getPercentageOfWeeklyTotal(fullVisibleTotal) + ' %'}</h3>
                                <h3>{this.getPercentageOfWeeklyTotal(fullTotal) + ' %'}</h3>
                                <p>{`${this.addDifferencePrefix(differenceInPercentage)} %`}</p>
                            </div>
                        )}
                        {!isMyDay && !demoMode && <div className={styles.emptyColumn} />}
                    </div>
                </div>
                {showAddTemplateDialog && (
                    <AddCatalogDialog
                        header={this.tr('Save list')}
                        label={this.tr('Name new list')}
                        optionsTitle={this.tr('Update existing list')}
                        newTitle={this.tr('Save as new')}
                        optionsLabel={this.tr('Select list')}
                        options={[{ id: '-1', label: this.tr('Not selected') }, ...templates.map((t) => ({ ...t, label: t.title }))]}
                        onClose={this.closeAddTemplateDialog}
                        onSave={this.saveProjectTemplate}
                    />
                )}
                {showEmptyListDialog && (
                    <CoreDialog
                        dialogType="delete"
                        dialogProps={{
                            onCloseClick: this.closeEmptyListDialog,
                            close: this.closeEmptyListDialog,
                            ...emptyListDialogData,
                        }}
                    />
                )}
            </div>
        );
    }
}

export default withSnackbar(BulkEntry);