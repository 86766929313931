import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import FieldEditSlider, { EditableField, FieldEditSliderProps } from './FieldEditSlider';
import DataHandler from './DataHandler';
import _, { cloneDeep } from 'lodash';
import { random_password_generate } from '../users/TabPersonalDetails';
import { randomColor } from '../helpers';
import moment from 'moment/min/moment-with-locales';
import EntityCreatedSnackbar from './EntityCreatedSnackbar';
import VersionContentManager from './VersionContentManager';

interface Props extends WithSnackbarProps, FieldEditSliderProps {
    initialSelectionProps?: any;
}

interface State {
    user: any;
    open: boolean;
    currency: string;
    companies: any[];
    users: any[];
    permissionGroups: any[];
    teamGroups: any[];
    protitles: any[];
    dimensionTeams: any[];
    workWeekTemplates: any[];
}

class AddUserSlider extends TaimerComponent<Props, State> {
    languageOptions: any;
    userTypes: any;
    constructor(props, context) {
        super(props, context, 'general/AddUserSlider');
        this.userTypes = [
            { id: '1', value: '1', label: this.tr('User') },
            { id: '2', value: '2', label: this.tr('Freelancer') },
        ];
        this.state = {
            user: {
                type: '1',
                employmentStartdate: moment().format('YYYY-MM-DD'),
                balanceStartdate: moment().format('YYYY-MM-DD'),
                companyId: this.context.userObject.companies_id,
                ...this.props.initialSelectionProps,
            },
            open: false,
            currency: 'EUR',
            companies: [],
            users: [],
            permissionGroups: [],
            teamGroups: [],
            protitles: [],
            dimensionTeams: [],
            workWeekTemplates: []
        };
    }

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    componentDidUpdate = (_, oldState) => {
        if (oldState.open != this.state.open && this.state.open) {
            this.getCompanies();
            this.getUsers();
            this.getGroups();
            this.getWorkWeekTemplates();
        }
        if (oldState.user.companyId != this.state.user.companyId) {
            this.getUsers();
            this.getGroups();
            this.getWorkWeekTemplates();
        }
    };

    getGroups = async () => {
        const {
            user: { companyId },
        } = this.state;
        const responses = await Promise.all([
            DataHandler.get({ url: `settings/usergroups/${companyId}` }),
            DataHandler.get({ url: `settings/usergroups/${companyId}`, type: 'teams' }),
            DataHandler.get({ url: `settings/company/${companyId}/protitles` }),
            DataHandler.get({ url: `subjects/dimensions/teams/${companyId}` }),
        ]);
        const permissionGroups = (responses[0] || []).map((p) => ({ ...p, label: p.name, value: p.id }));
        const teamGroups = (responses[1] || []).map((t) => ({ ...t, label: t.name, value: t.id }));
        const protitles = (responses[2] || []).map((p) => ({ ...p, label: p.name, value: p.id }));
        const dimensionTeams = (responses[3] || []).map((d) => ({ ...d, label: d.name, value: d.id }));
        this.setState({ permissionGroups, teamGroups, protitles, dimensionTeams });
    };

    getWorkWeekTemplates = async () => {
        const {
            user: { companyId },
        } = this.state;

        const weeks = (await DataHandler.get({ url: `settings/${companyId}/workweeks` }))
            .filter(ww => !ww.is_percentage)
            .map(ww => {
                ww.label = ww.name;
                ww.value = ww.id;

                return ww;
            });

        this.setState({
            user: {
                ...this.state.user,
                workWeekTemplate: weeks.find(w => w.is_default)
            },
            workWeekTemplates: weeks.filter(w => !w.deleted)
        });
    };

    getUsers = async () => {
        const { user } = this.state;
        let users = await DataHandler.get({ url: `subjects/employees/${user.companyId}`, frTransl: this.tr('Freelancer') });
        users = users.map((u) => ({ ...u, label: u.name, value: u.id }));
        this.setState({ users });
    };

    getCompanies = async () => {
        let companies = await DataHandler.get({ url: 'subjects/companies/admin/admin', currency: 1 });
        companies = companies.map((c) => ({ ...c, label: c.name, value: c.id }));
        const user = cloneDeep(this.state.user);
        user.companyId = (companies.find((c) => c.id == user.companyId) || companies[0])?.id;
        const currency = companies.find((c) => c.id == this.state.user.companyId)?.currency || this.state.currency;
        this.setState({ user, currency, companies: companies.map((c) => ({ ...c, label: c.name, value: c.id })) });
    };

    onClose = () => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    onSave = async (user) => {
        const { enqueueSnackbar, closeSnackbar } = this.props;
        const creatingSnackbar: any = enqueueSnackbar(this.tr('Creating user...'), {
            variant: 'info',
            persist: true,
        });
        const data = {
            ...user,
            password: random_password_generate(12, 8),
            color: randomColor(),
            permissionGroupIds: (user.permissionGroupIds || []).map((p) => p.id),
            teamGroupIds: (user.teamGroupIds || []).map((t) => t.id),
            workWeekTemplateId: user?.workWeekTemplate?.id || null,
        };
        this.onClose();
        try {
            const response = await DataHandler.post({ url: 'users' }, data);
            closeSnackbar(creatingSnackbar);
            const viewProps = this.context.functions.getViewProps();
            const moveToUserCard = (viewProps.module == 'contacts' && viewProps.action == 'main' && viewProps.selectedTab == 'users') || (viewProps.module == 'users' && viewProps.action == 'view');
            const id = response.id;

            this.context.functions.sendMixpanelEvent('create_user', {
                'origin_point': this.props.initialSelectionProps?.origin_point,
            });
            this.context.functions.sendMixpanelPeople('set_once', {
                'first_create_user_start': new Date().toISOString(),
            });
            this.context.functions.sendMixpanelPeople('set', {
                'last_create_user_start': new Date().toISOString(),
            });
            this.context.functions.sendMixpanelPeople('increment', {
                'lifetime_create_user': 1,
            });

            const createdSnackbar: any = enqueueSnackbar(
                <EntityCreatedSnackbar
                    type="user"
                    id={id}
                    onClose={() => closeSnackbar(createdSnackbar)}
                    url={!moveToUserCard ? { module: 'users', action: 'view', id: response.id, company: data.companyId || 0 } : undefined}
                />,
                {
                    variant: 'default',
                    autoHideDuration: 5000,
                    className: 'entityCreatedSnackbar',
                }
            );

            if(parseInt(user.type) === 2) 
            {
                enqueueSnackbar(
                    <EntityCreatedSnackbar
                        type="freelancer"
                    />,
                    {
                        variant: 'warning',
                        autoHideDuration: 5000,
                    }
                );     
            }

            if (moveToUserCard) {
                this.context.functions.updateView({ module: 'users', action: 'view', id: response.id, company: data.companyId || 0 });
            }
            setTimeout(() => {
                window.dispatchEvent(new Event('userCreated'));
            }, 1000);
        } catch (e) {
            const errorResponse: any = e;
            closeSnackbar(creatingSnackbar);
            let errorDescription = '';
            switch (errorResponse.responseJSON?.error) {
                case 'LIMIT_REACHED':
                    errorDescription = ` ${this.tr('The user license limit has been reached')}.`;
                    break;
                case 'EMAIL_IN_USE':
                    errorDescription = ` ${this.tr('The mail is already in use')}.`;
                    break;
                default:
                    break;
            }
            enqueueSnackbar(`${this.tr('Creating user failed!')}${errorDescription}`, {
                variant: 'error',
            });
        }
    };

    onItemChanged = (user) => this.setState({ user });

    getFields = () => {
        const { user, companies, users, currency, protitles, permissionGroups, teamGroups, dimensionTeams, workWeekTemplates } = this.state;
        const companyId: number = parseInt(user.companyId);
        const {
            addons,
            taimerAccount: { isMulticompany },
        } = this.context;
        
        const fields: EditableField[] = [
            {
                key: 'type',
                required: true,
                title: this.tr('User type'),
                type: 'data_select',
                options: this.userTypes,
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'userType'),
                setOtherValuesWithSelection: (_, value) => {
                    return { companyId: value == 2 ? '0' : this.context.userObject.companies_id };
                },
            },
            {
                key: 'companyId',
                required: true,
                isHidden: (item) => item.type == 2 || !isMulticompany,
                title: this.tr('Company'),
                type: 'data_select',
                options: companies,
            },
            {
                key: 'firstname',
                required: true,
                title: this.tr('First name'),
            },
            {
                key: 'lastname',
                required: true,
                title: this.tr('Last name'),
            },
            {
                key: 'email',
                required: true,
                title: this.tr('Email'),
                validation: 'email',
            },
            {
                key: 'phone',
                title: this.tr('Phone'),
                validation: 'phone',
            },
            {
                key: 'title',
                title: this.tr('Title'),
            },
            {
                key: 'position',
                title: this.tr('Position'),
            },
            {
                key: 'supervisorId',
                title: this.tr('Supervisor'),
                type: 'data_select',
                options: users,
                addNoneOption: true,
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'supervisor'),
            },
            {
                key: 'dimension_teams_id',
                title: this.tr('Dimension team'),
                type: 'data_select',
                options: dimensionTeams,
                addNoneOption: true,
                isHidden: () => (addons?.dimensions?.used_by_companies || []).indexOf(user.companyId) == -1,
                hideIfEmpty: true,
            },
            {
                key: 'protitle_groups_id',
                title: this.tr('Professional title'),
                type: 'data_select',
                options: protitles,
                hideIfEmpty: true,
                addNoneOption: true,
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'professionalTitle'),
            },
            {
                key: 'permissionGroupIds',
                title: this.tr('Permission groups'),
                type: 'select',
                isMulti: true,
                options: permissionGroups,
                hideIfEmpty: true,
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'permissionGroups'),
            },
            {
                key: 'teamGroupIds',
                title: this.tr('Teams'),
                type: 'select',
                isMulti: true,
                options: teamGroups,
                hideIfEmpty: true,
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'teams'),
            },
            {
                key: 'employmentStartdate',
                title: this.tr('Employment start date'),
                type: 'date',
                additionalProps: {
                    noEmptyDates: true,
                },
            },
            {
                key: 'employmentEnddate',
                title: this.tr('Employment end date'),
                type: 'date',
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'employmentEndDate'),
            },
            {
                key: 'workWeekTemplate',
                title: this.tr('Employment template'),
                type: 'select',
                options: workWeekTemplates,
                setOtherValuesWithSelection: (_, value) => {
                    return { disable_user_balance: !value.count_balance };
                },
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'workWeekTemplate'),
            },
            {
                key: 'overtime_balancestart',
                title: this.tr('Overtime balance start date'),
                type: 'date',
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'overtime'),
            },
            {
                key: 'disable_user_balance',
                title: this.tr('Disable balance for user'),
                type: 'switch',
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'balance'),
            },
            {
                key: 'price',
                title: this.tr('User price / hour'),
                validation: 'numeric',
                additionalProps: {
                    format: 'currency',
                    currency,
                    maximumFractionDigits: 4,
                },
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'hourlyPrice'),
            },
            {
                key: 'internal_cost',
                title: this.tr("User's internal cost"),
                validation: 'numeric',
                additionalProps: {
                    format: 'currency',
                    currency,
                    maximumFractionDigits: 4,
                },
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'internalCost'),
            },
        ].filter((field: EditableField) => {
            if(!companyId && field.key === "workWeekTemplate") {
                return false;
            }

            return true;
        });

        return fields;
    };

    render() {
        const { user, open } = this.state;
        return <FieldEditSlider open={open} onClose={this.onClose} title={this.tr('Add user')} onSave={this.onSave} onItemChanged={this.onItemChanged} item={user} fields={this.getFields()} />;
    }
}

export default withSnackbar(AddUserSlider);
