import React from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment/min/moment-with-locales';
import { format } from 'date-fns';
import customTooltips from '../../../general/ChartTooltip';

const OverviewBlockChart = (props) => {
    const { data: chartData, monthTitles, hasSubtitles } = props;
    return (
        <Line
            options={{
                maintainAspectRatio: false,
                responsive: true,
                layout: {
                    padding: {
                        top: hasSubtitles ? 15 : 5,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                        external: customTooltips,
                        callbacks: {
                            labelColor: () => {
                                return {
                                    backgroundColor: chartData.color,
                                };
                            },
                            label: (tooltipItem) => {
                                let label = tooltipItem.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                label += chartData.datasets[tooltipItem.datasetIndex].formatTooltipLabel
                                    ? chartData.datasets[tooltipItem.datasetIndex].formatTooltipLabel(tooltipItem.raw)
                                    : tooltipItem.raw;
                                return '  ' + label;
                            },
                        },
                        mode: 'index',
                        titleFontSize: 12,
                        titleFontStyle: 'bold',
                        titleMarginBottom: 15,
                        bodyFontSize: 11,
                        bodyFontColor: 'rgba(255,255,255,0.7)',
                        bodySpacing: 8,
                        caretSize: 0,
                        padding: 20,
                        intersect: false,
                    },
                },
                scales: {
                    y: {
                        display: false,
                        beginAtZero: true,
                        grid: {
                            display: false,
                        },
                    },
                    hidden: {
                        display: false,
                        beginAtZero: true,
                        grid: {
                            display: false,
                        },
                    },
                    x: {
                        display: false,
                        grid: {
                            display: false,
                        },
                    },
                },
            }}
            data={{
                datasets: chartData.datasets.map((dataset) => ({
                    label: dataset.label,
                    lineTension: 0,
                    borderColor: 'rgba(0,0,0,0.35)',
                    fill: dataset.hidden
                        ? undefined
                        : {
                              target: 'origin',
                              below: 'rgba(0, 0, 0, 0.18)',
                          },
                    pointRadius: 0,
                    data: dataset.values,
                    showLine: !dataset.hidden,
                    yAxisID: dataset.hidden ? 'hidden' : undefined
                })),
                labels: chartData.months.map((month) => {
                    let monthTitle = moment(month, 'MM-YYYY').format('MMM');
                    Object.keys(monthTitles || {}).forEach((title) => {
                        if (month.startsWith(title)) {
                            monthTitle = monthTitles[title];
                        }
                    });
                    return monthTitle + ' ' + moment(month, 'MM-YYYY').format('YYYY');
                }),
            }}
        />
    );
};

export default OverviewBlockChart;
