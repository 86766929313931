import React from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
import TaimerComponent from "../../TaimerComponent";
import List from "../../list/List";
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import Button from '@mui/material/Button';
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import ActivityDialog from '../../dialogs/ActivitySettingsDialog';
import CoreDialog from '../../dialogs/mass_operations/CoreDialog';
import Utils from "../../general/Utils";
import RadioButtonCell from "../../list/cells/RadioButtonCell";


import PropsOnlyListRow from "../../list/PropsOnlyListRow";
import { Switch } from "@mui/material";
import ActivityTranslationSlider from './ActivityTranslationSlider';

//Activity icons 
import { ReactComponent as Email } from '../img/Activity_icons/Email.svg'
import { ReactComponent as Call } from '../img/Activity_icons/Phone.svg'
import { ReactComponent as Note } from '../img/Activity_icons/Note.svg'
import { ReactComponent as Meeting } from '../img/Activity_icons/Meeting3.svg'
import Online_meeting from '../img/Activity_icons/Online_meeting.svg'


import ContextMenu from "../../general/ContextMenu";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import EditIcon from '@mui/icons-material/Edit';

import "./Activity.css"
import $ from "jquery";

const rowHeight   = 73;
const rowHeightPx = rowHeight + "px";

const defaultActivities = [
    {name:'Meeting', is_default: true, icon: "Meeting3.svg", has_all_translations:'1'}, 
    {name:'Online meeting',  is_default: false, icon: "Online_meeting.svg",  has_all_translations:'1'},
    {name: 'Call', is_default: false, icon: "Phone.svg", has_all_translations:'1' },
    {name: 'Email', is_default: false, icon: "Email.svg", has_all_translations:'1' },
    {name: 'Note', is_default: false, icon: "Note.svg", has_all_translations:'1'}
];

class ActivityListRow extends PropsOnlyListRow {
	constructor(props, context) {
        super(props, {}, {}, "settings/pages/Activity");

        this.context = context;
    }

    defineClassName() {
        return "ActivityListRow";
    }

    defineCells() {
        const { data, rowProps } = this.props;

        const translatedStatus = data['has_all_translations'] > 0 ? this.tr("Translated") : (data['has_some_translations'] ? this.tr("Partially translated") : this.tr("Not translated"));
        let cells = {
            context:   
                <ContextMenu
                    label={<MoreHoriz />}
                    buttonProps={{ className: "action-menu" }}
                    className="cell edit row-menu"
                    width={this.props.columnWidthMap["context"]}
                    style={{
                        width: this.props.columnWidthMap["context"] + "px",
                        flex: this.props.columnWidthMap["context"] + " 1 0px",
                    }}
                    noExpandIcon
                 >
                   <MenuItem className="edit" onClick={() => {rowProps.openEditDialog(data)}}><EditIcon />{this.tr("Edit")}</MenuItem>
                   {!rowProps.lastActivity && data.is_default == 0 && <MenuItem className="delete" onClick={() => {this.delete()}}><RemoveIcon />{this.tr("Remove")}</MenuItem>}
                
            </ContextMenu>,
            icon:
                <ListCell alignCell onlyDisplay name="icon" className={data.old_icon_set > 0 ? 'fixed-width' : ''}>
                   <img 
                       className={"iconImg"} 
                       src={data.old_icon_set > 0 
                           ? data.icon 
                       : rowProps.icons[data.icon]} />
                </ListCell>,
            name:   
                <ListCell 
                    showTooltipForOverflownText={true}
                    className={"activity-settings-name-cell"}
                    editable={false}
                    value={data.name} />,
            translations:
                <ListCell   
                    onlyDisplay 
                    alignCell
                    width={this.props.columnWidthMap['translations']}
                    name="translation" 
                    value={translatedStatus}>
                    <Button className="translationLink" onMouseUp={() => rowProps.toggleSlider(data)} size="large"> 
                        {translatedStatus}
                    </Button>
                </ListCell>,
            is_default:
                <ListCell className="defaultRadio" width={this.props.columnWidthMap['default']} onlyDisplay>
                   {/*  <Switch color="primary" checked={data.is_default > 0} onChange={() => this.setDataAndUpdate({...data, is_default: '1'})} /> */}
                   <RadioButtonCell 
                        onEdited={(event, checked) => {this.setDataAndUpdate('is_default', checked); }}
                        name="is_default" 
                        width={this.props.columnWidthMap['default']}
                        checked={data.is_default == 1}
                    />
                </ListCell>

        };

        return cells;
    }
}

class Activity extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/Activity");

        this.state = {
            currentDialog: undefined,
            dialogData: {},
            activityTypes:[],
            sliderOpen: false,
            sliderData: [],
            initialized: false,
        };
        this.dialogs = {
            activityDialog: ActivityDialog,
            delete: CoreDialog
        };

        this.activityList = React.createRef();

        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };

        this.columns = [
            { width: 80, name: "context", header: "", ...dColConf },
            { width: 80, name: "icon", header: "", ...dColConf },
            { width: 300, name: "name", header: this.tr("Type"), ...dColConf },
            { width: 300, name: "translations", header: this.tr("Translation"), ...dColConf },
            { width: 100, name: "is_default", header: this.tr("Default"), ...dColConf }
        ];        
       
        this.deleteDialogProps = {
            header: `${this.tr("Delete Activity type")}?`,
            warning: () => "",
            close: () => this.setState({currentDialog: undefined, dialogData: {}}),
            onCloseClick: () => this.setState({currentDialog: undefined, dialogData: {}}),
        }

        this.images = Utils.generateImgSrcsMap(Utils.refineGlobImportKeys(import.meta.glob([
            '../img/Activity_icons/*.png',
            '../img/Activity_icons/*.jpg',
            '../img/Activity_icons/*.jpeg',
            '../img/Activity_icons/*.svg'
          ], { as: 'raw', eager: true })));

        const { userObject } = context;
        this.defaultNameField = Utils.getNameFieldByUserLang(userObject.language, 'name');        

    }

    componentDidMount() {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }    

    toggleSlider = (data) => {
        this.setState({ sliderOpen: !this.state.sliderOpen, sliderData: data || [] });
    }

    onSave = (data) => {
        const {company} = this.props;

        const dataSend  = {...data};

        if (dataSend.old_icon_set)
            delete dataSend.icon;

        DataHandler.post({url: `settings/company/${company}/activities`}, {...dataSend, companies_id: company}).done(r => {
            setTimeout(() => {
                this.updateComponentData();
                this.setState({sliderData: data});
            }, 1000); // To counter-act the lag in replication.
        })
    }

    updateComponentData = async () => {
        this.setState({currentDialog: undefined, dialogData: {}});
        const { company } = this.props;
        const data = await DataHandler.get({url: `settings/company/${company}/activities`});
        // if (data.length < 1)
        //     data = [...defaultActivities];
        this.setState({activityTypes: data, initialized:true}); 
    }

    render() {
        const { tr } = this;
        const { company } = this.props;
        const { activityTypes, sliderData, sliderOpen, currentDialog, dialogData , initialized} = this.state;
        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };

        const Dialog = this.dialogs[currentDialog];
        let dialogProperties= {
            open: true, 
            company: company,
            dialogData: dialogData,
            onClose: () => this.setState({currentDialog: undefined, dialogData: {}}),
            onSave: (data) => this.onSave(data),
            dialogType: currentDialog
        };

        if (currentDialog == 'delete') {
            dialogProperties.dialogProps = {
                ...this.deleteDialogProps, 
                warning: () => {return this.tr("Are you sure you want to delete activitytype: ${name}? This cannot be undone.", {name: dialogData?.name})},
                onConfirm: () => DataHandler.delete({url: `settings/company/${company}/activities/${dialogData.id}`}).done(() => {
                    this.setState({currentDialog: undefined, dialogData: {}});
                    this.activityList.current.removeRow(dialogData.id);
                    this.props.enqueueSnackbar(this.tr("Activitytype deleted successfully!"), {
                        variant: 'success'
                    });
                    // r => this.updateComponentData();
                }).fail(response => {
                    this.props.enqueueSnackbar(this.tr("Error in deleting activitytype!"), {
                        variant: "error",
                    });
                })
            };
        }

        return (
           
            <div id="settings-feature-activity">
                {!initialized && 
                    <div> 
                        <Loading style={loaderStyle} />
                    </div>
                } 
                {initialized && 
                    <React.Fragment>
                    <ActivityTranslationSlider
                        open={sliderOpen}
                        data={sliderData}
                        onClose={() => this.toggleSlider()}
                        onSave={data => this.onSave(data)}
                        icons={this.images}
                    />
                    <div className="settings-feature-firstwrapper">
                        <h3>{tr("Activity type")}</h3>
                        <p className="subtitle">{tr("Define Activity types. Give your activity a name.")}</p>
                        <Button className="green" onMouseUp={() => this.setState({currentDialog: 'activityDialog'})} size="large">{tr("Add Activity type")}</Button>
                        <List
                            id="activities"
                            ref={this.activityList}
                            className="settingsList"
                            height="auto"
                            rowHeight={73}
                            trimHeight={+25}
                            listRowType={ActivityListRow}
                            emptyNewDataOnUpdate
                            data={activityTypes}
                            columns={this.columns}
                            rowProps={{
                                onUpdate: data => this.onSave(data),
                                onCreate: data => this.onSave(data),
                                onDelete: data => this.setState({currentDialog: 'delete', dialogData: data}),
                                openEditDialog: (data) => this.setState({currentDialog: 'activityDialog', dialogData: data}),
                                toggleSlider : this.toggleSlider,
                                icons: this.images,
                                defaultNameField: this.defaultNameField,
                                lastActivity: activityTypes.length < 2
                            }}
                            hideHeader={this.state.activityTypes.length === 0}
                            noStateData
                            newRow={{
                                type: "",
                                companies_id: company
                            }}
                            
                        />
                    </div>

                    {currentDialog && <Dialog {...dialogProperties} />}
                    
                </React.Fragment>
            }
            </div>
        );
    }
}

export default Activity;
