import React from "react";
import ListCell from "./ListCell";
import styles from "./ColorListCell.module.scss";

class ColorListCell extends ListCell {
	presentValue(value) {
        if (value && value.length === 6) {
            value += "0";
        }

		return (
            <div className={styles.colorListCellContainer}>
                <div className={styles.colorListCell} style={{ backgroundColor: value }} />
            </div>
        );
	}
}

export default ColorListCell;
