import MoreHoriz from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment/min/moment-with-locales';
import ContextMenu, { ContextSubMenu } from '../general/ContextMenu';
import CurrencyListCell from "../list/CurrencyListCell";
import LinkListCell from "../list/LinkListCell";
import ListCell from "../list/ListCell";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import AttachmentCell from "../list/cells/AttachmentCell";
import CheckboxCell from "../list/cells/CheckboxCell";
import DateCell from "../list/cells/DateCell";
import StatusCell from '../list/cells/StatusCell';
import TextInputCell from "../list/cells/TextInputCell";
import { SettingsContext } from './../SettingsContext';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import PrintIcon from '@mui/icons-material/Print';
import { ReactComponent as CopyIcon } from '../general/icons/copy.svg';
import { ReactComponent as ViewIcon } from '../general/icons/view.svg';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';
import SendIcon from '@mui/icons-material/Send';

class TravelExpenseListRow extends PropsOnlyListRow {
	static contextType = SettingsContext;

	constructor(props) {
		super(props, {}, {}, 'expenses/TravelExpenseListRow');

		this.cellEdited  = this.cellEdited.bind(this);
		this.check 		 = this.check.bind(this);
		this.openExpense = this.openExpense.bind(this);
		this.setExpenseState = this.setExpenseState.bind(this);
		this.openAttachment = this.openAttachment.bind(this);

		this.states = [];
        this.states[0] = {name: this.tr("Draft"), color: "#6B7897"};
        this.states[1] = {name: this.tr("Waiting"), color: "#ffb822"};
        this.states[2] = {name: this.tr("Approved"), color: "#50e3c2"};
		this.states[3] = {name: this.tr("Declined"), color: "#f7548f"};
		this.states[4] = {name: this.tr("Archived"), color: "#716aca"};
	}


	cellEdited(name, value) {
		const { rowProps: { openStatusCheckDialog } } = this.props;

		if (name == "state" && openStatusCheckDialog) {
			openStatusCheckDialog(value, {...this.props.data, state: value});
			return;
		}

        this.setDataAndUpdate(name, value);
	}

	openExpense() {
		this.props.rowProps.updateView({
			"module": "worktrips",
			"action": "modify",
            "id": this.props.data['id'],
            "expenseType": 2
		}, false);
	}

	addFromDraft = () => {
		this.context.functions.addTravelExpense({draftId: this.props.data['id'], origin_point: "expense_list"});
	}

	setExpenseState(state) {
		this.cellEdited("state", state);
	}

	openAttachment() {
		this.props.rowProps.attachmentHandler(this.props.data.id);
    }

    defineClassName() {
        return "expenseListRow"; 
    }

    defineCells() {
		const { taimerAccount } = this.context;
		const { rowProps: { stateUpdateConditions } } = this.props;

        const printLanguageOptions = this.props.rowProps.printLanguageOptions;
		const isInvoiced = Number(this.props.data.is_invoiced) > 0;
		const isSent = this.props.data.is_sent != 0;
		const stateConditions = isInvoiced || isSent ? stateUpdateConditions.invoiced : stateUpdateConditions.notInvoiced;

		const bills = (this.props.data.bills_ids && this.props.data.bills_ids.split(',')) || ["0"];
		const billed = bills.indexOf("0") === -1;
        const billing_type = billed ? 'Billed' : (this.props.data.billing === "1" ? 'Unbilled' : 'Non Billable');

        const start = moment(this.props.data.raw_starttime).format("LT");
        const end   = moment(this.props.data.raw_endtime).format("LT");
		
		const total = (this.props.data['mileage_allowance_total_vat']-0) + (this.props.data['daily_allowance_total_vat']-0) + (this.props.data['other_allowance_total_vat']-0) + (this.props.data['additional_allowance_total_vat']-0);

		const cells = {
			context:
				<ContextMenu data-testid="TravelExpense_list_row" label={<MoreHoriz />} buttonProps={{className: 'action-menu'}} className="cell row-menu" width={this.props.columnWidthMap['context']} style={{width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + " 1 0px" }} noExpandIcon>
					<MenuItem data-testid="TravelExpense_list_row_open" onClick={() => {this.openExpense()}}><ViewIcon />{this.tr('Open')}</MenuItem>
					{ this.context.functions.checkPrivilege("worktrips", "write") && <MenuItem data-testid="duplicate_travel_expense" onClick={() => {this.addFromDraft()}}><CopyIcon />{this.tr('Use as Draft')}</MenuItem>}
					{ stateConditions.approve.includes(Number(this.props.data['state'])) && this.props.data['can_approve'] && <MenuItem onClick={() => {this.setExpenseState(2)}}><CheckIcon />{this.tr('Approve')}</MenuItem>}
					{ stateConditions.reject.includes(Number(this.props.data['state'])) && this.props.data['can_approve'] && <MenuItem data-testid="reject_travel_expense_button" onClick={() => {this.setExpenseState(3)}}><CloseIcon  className="expenseReject"/>{this.tr('Decline')}</MenuItem>}
					{ stateConditions.setWaiting.includes(Number(this.props.data['state'])) &&  <MenuItem data-testid="set_as_waiting" onClick={() => {this.setExpenseState(1)}}><HourglassFullIcon />{this.tr('Set as waiting')}</MenuItem>}
					{ stateConditions.archive.includes(Number(this.props.data['state'])) && this.props.data['can_approve'] && <MenuItem onClick={() => {this.setExpenseState(4)}}><ArchiveIcon />{this.tr('Archive')}</MenuItem>}
					<MenuItem onClick={() => this.props.rowProps.print(this.props.data.id)}><PrintIcon title="" /> {this.tr('Print')}</MenuItem>
					<MenuItem onClick={() => this.props.rowProps.send(this.props.data.id)}><SendIcon title="" /> {this.tr('Send')}</MenuItem>
				</ContextMenu>,
			attachment:
				<AttachmentCell style={{width: this.props.columnWidthMap['attachment'] + 'px'}} width={this.props.columnWidthMap['attachment']} onClick={() => this.openAttachment()} />,
			checked:
				<CheckboxCell style={{width: this.props.columnWidthMap['checked'] + 'px'}} checked={this.props.checked} width={this.props.columnWidthMap['checked']} onClick={() => this.props.listRef.check(this.props.data.id)} />,
			id:
				<LinkListCell
					urlHandler={value => `index.html?module=worktrips&action=modify&expenseType=2&id=${this.props.data.id}`}
					style={{width: this.props.columnWidthMap['id'] + 'px'}}
					width={this.props.columnWidthMap['id']}
					value={this.props.data['id']} editable={false}
					noTab={true} />,
            fullname:
		    	<ListCell style={{width: this.props.columnWidthMap['fullname'] + 'px'}} width={this.props.columnWidthMap['fullname']} value={this.props.data['fullname']} editable={false} />,
			account:
				<LinkListCell
					urlHandler={value => `index.html?module=customers&action=view&id=${this.props.data.customers_id}`}
					style={{width: this.props.columnWidthMap['account'] + 'px'}}
					width={this.props.columnWidthMap['account']}
					value={this.props.data['customer_name']}
					editable={false}
					asText={this.props.data.can_see_account == 0}
					noTab={true} />,
		    project:
				<LinkListCell
					urlHandler={value => `index.html?module=projects&action=view&id=${this.props.data.subprojects_id}`}
					style={{width: this.props.columnWidthMap['project'] + 'px'}}
					width={this.props.columnWidthMap['project']}
					value={this.props.data['project_name']}
					editable={false}
					noTab={true} />,			
			description:
				<TextInputCell style={{width: this.props.columnWidthMap['description'] + 'px'}} width={this.props.columnWidthMap['description']} name="description" value={this.props.data['description']} onEdited={this.cellEdited} listCellProps={{editable: this.props.data['can_modify'] == 1}}/>,
			route:
				<TextInputCell style={{width: this.props.columnWidthMap['route'] + 'px'}} width={this.props.columnWidthMap['route']} name="route" value={this.props.data['route']} onEdited={this.cellEdited} listCellProps={{editable: this.props.data['can_modify'] == 1}}/>,
            startdate:
				<DateCell style={{width: this.props.columnWidthMap['startdate'] + 'px'}} width={this.props.columnWidthMap['startdate']} value={this.props.data['startdate']} name="startdate" onEdited={this.cellEdited} listCellProps={{className: "date"}} editable={false} />,
            starttime:
                <TextInputCell
                    width={this.props.columnWidthMap['starttime']}
                    name="starttime"
                    inputType="time"
                    value={start}
                    // editValue={this.props.data.starttime}
					//onEdited={this.cellEdited}
					editable={false}
                />,
            enddate:
				<DateCell style={{width: this.props.columnWidthMap['enddate'] + 'px'}} width={this.props.columnWidthMap['enddate']} value={this.props.data['enddate']} name="enddate" onEdited={this.cellEdited} listCellProps={{className: "date"}} editable={false} />,
            endtime:
                <TextInputCell
                    width={this.props.columnWidthMap['endtime']}
                    name="endtime"
                    inputType="time"
                    value={end}
                    // editValue={this.props.data.endtime}
					//onEdited={this.cellEdited}
					editable={false}
                />,
            state:
				<StatusCell style={{width: this.props.columnWidthMap['state'] + 'px'}} value={this.states[this.props.data['state']]?.name} width={this.props.columnWidthMap['state']} displayData={{color: this.states[this.props.data['state']]?.color, name: this.states[this.props.data['state']]?.name}}/>,
			team_groups:
				<ListCell style={{width: this.props.columnWidthMap['team_groups'] + 'px'}} width={this.props.columnWidthMap['team_groups']} value={this.props.data['name']} editable={false} />,
			total:
                <CurrencyListCell data-testid={"travel_expense_total_value-" + this.props.rowIndex} style={{width: this.props.columnWidthMap['total'] + 'px'}} width={this.props.columnWidthMap['total']} value={total} currency={this.props.rowProps.currency} editable={false} />,
            billing_type:
				<TextInputCell style={{width: this.props.columnWidthMap['billing_type'] + 'px'}} width={this.props.columnWidthMap['billing_type']} name="billing_type" value={billing_type} editable={false} />,
			in_procountor:
		    	<ListCell style={{width: this.props.columnWidthMap['in_procountor'] + 'px'}} width={this.props.columnWidthMap['in_procountor']} value={this.props.data['in_procountor'] === "P" ? "P" : ""} editable={false} />,
		    mileage_distance:
                <ListCell style={{width: this.props.columnWidthMap['mileage_distance'] + 'px'}} width={this.props.columnWidthMap['mileage_distance']} value={this.props.data.mileage_distance} editable={false} />,
            daily_allowance_days:
                <ListCell style={{width: this.props.columnWidthMap['daily_allowance_days'] + 'px'}} width={this.props.columnWidthMap['daily_allowance_days']} value={this.props.data.daily_allowance_days} editable={false} />,
            country:
                <ListCell style={{width: this.props.columnWidthMap['country'] + 'px'}} width={this.props.columnWidthMap['country']} value={this.props.data['country']} editable={false} />,
			billing:
		    	<ListCell style={{width: this.props.columnWidthMap['billing'] + 'px'}} width={this.props.columnWidthMap['billing']} value={this.tr(this.props.data['billing_text'])} editable={false} />,
			invoiced:
		    	<ListCell style={{width: this.props.columnWidthMap['invoiced'] + 'px'}} width={this.props.columnWidthMap['invoiced']} value={this.tr(this.props.data['invoiced_text'])} editable={false} />,
        };

        return cells;
    }

    // render() {
    // return (
    // <div className={className} style={{display: "flex", height: !this.props.hidden ? "44px" : "0px", lineHeight: "44px" }}>
    // <FocusGroup focusOnInit={this.props.data.id < 0}>
    // {this.props.columnOrder.map(columnName => cells[columnName])}
    // </FocusGroup>
    // </div>
    // );
    // }
}

export default TravelExpenseListRow;
