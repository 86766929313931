import React from "react";
import colors from "../../../colors";
import OverviewBlockChart from "./OverviewBlockChart";
import InsightDropDown from "../../insights/InsightDropDown";

import "./OverviewBlock.css";
import BlockTopBar from "./BlockTopBar";
import { format } from "date-fns";
import moment from 'moment/min/moment-with-locales';
import TaimerComponent from "../../../TaimerComponent";

const defaultGraphData = {
  datasets: [],
  months: [],
  color: colors.dark_sky_blue,
};

class OverviewBlock extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "dashboard/my_day/components/OverviewBlock");
    this.monthTitles = {
      "01": this.tr("Jan"),
      "02": this.tr("Feb"),
      "03": this.tr("Mar"),
      "04": this.tr("Apr"),
      "05": this.tr("May"),
      "06": this.tr("Jun"),
      "07": this.tr("Jul"),
      "08": this.tr("Aug"),
      "09": this.tr("Sep"),
      "10": this.tr("Oct"),
      "11": this.tr("Nov"),
      "12": this.tr("Dec"),
    };
  }

  _getMonthTitle = (month) => {
    let monthTitle = moment(month, "MM-YYYY").format("MMM");
    Object.keys(this.monthTitles || {}).forEach((title) => {
      if (month.startsWith(title)) {
        monthTitle = this.monthTitles[title];
      }
    });
    return monthTitle + " " + moment(month, "MM-YYYY").format("YYYY");
  };

  render() {
    const {
      title,
      subtitles,
      graphData = defaultGraphData,
      hideGraph,
      content,
      extraTopBarComponents,
      className,
      dropdownItems,
      selected,
      showMenuButton,
      loading,
      showDateOverlay = true,
      overlayBottomOffset = 0,
      overlayTopOffset = 0,
      darkTitle,
    } = this.props;
    return (
      <div className={`overview-block ${className}`}>
        <BlockTopBar
          loading={loading}
          title={title}
          subtitles={subtitles}
          dark={darkTitle}
          dropdownItems={dropdownItems}
          dropdownColor={!hideGraph && "#fff"}
          dropdownTitleColor={darkTitle ? "#2c3547" : !hideGraph && "#fff"}
          selected={selected}
          extraTopBarComponents={extraTopBarComponents}
          className={!hideGraph && !darkTitle && "white"}
          showMenuButton={showMenuButton}
        />
        {!hideGraph && (
          <div
            className="graph-container no-my-day-drag"
            style={{ backgroundColor: graphData.color || colors.dark_sky_blue }}
          >
            <OverviewBlockChart
              hasSubtitles={!!subtitles}
              monthTitles={this.monthTitles}
              data={graphData}
            />
            {showDateOverlay && (
              <div
                style={{
                  marginBottom: overlayBottomOffset,
                  marginTop: overlayTopOffset,
                }}
                className="date-lines"
              >
                {graphData.months.slice(0, 6).map((month, i) => (
                  <div
                    className={
                      month == moment().format("MM-YYYY") ? "dark" : ""
                    }
                    key={i}
                  >
                    <div className="line" />
                    <p>{this._getMonthTitle(month)}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="content-container no-my-day-drag">{content}</div>
      </div>
    );
  }
}

export default OverviewBlock;
