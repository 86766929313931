import TaimerComponent from '../TaimerComponent';
import Link from './../general/Link';

interface Props {
    value: string;
    url: string;
    data: object;
    colDef: {
      field: string;
      pivotValueColumn: any;
    };
    node: {
      field: string;
      group: boolean;
      footer: boolean;
      expanded: boolean;
      aggData: object;
      allLeafChildren: {data}[];
    };
    column: {colId: string};
    groupedDataValue?: string | number;
}

export default class LinksCellRenderer extends TaimerComponent<Props> {
  render() {
    let data = this.props.groupedDataValue 
      ? this.props.groupedDataValue 
      : this.props.data && this.props.data[this.props.colDef.field];

    if(!data) {
      return <div/>;
    }

    const links: any[] = [];

    let i = 0;

    if (this.props.colDef?.pivotValueColumn) {
      data = {
        name: data,
        as_text: true
      }
    }

    const arr = Array.isArray(data) ? data : [data];

    for (const part of arr) {
      if (part.hidden) {
        continue;
      }

      let url = this.props.url;

      const matches = [...url.matchAll(/\$\(([a-zA-Z0-9]+)\)/g)]; // eg. $(id)
      matches.forEach(el => {
        if(el[1] && part[el[1]]) {
          url = url.replace(el[0], part[el[1]]);
        }
      });

      if (links.length) {
        links.push(', ');
      }

      links.push(<Link key={i++} url={url}>{part?.name ?? url}</Link>)
    }

    return links;
  }
}