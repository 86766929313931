import React from 'react';
import { SettingsContext } from './../SettingsContext';
import TabPersonalDetails from './TabPersonalDetails';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabMemo from './TabMemo';
import TabAttachments from '../general/TabAttachments';
import TaimerComponent from '../TaimerComponent';
import NoPermissionOverlay from '../overlays/NoPermissionOverlay';
import TabActivities from './../general/TabActivities'


import { withSnackbar } from 'notistack';
import WithTabs from '../navigation/WithTabs';
import cardStyles from '../general/styles/CardStyles.module.scss';
import { Button, MenuItem } from '@mui/material';

import './UserView.css';
import DataHandler from '../general/DataHandler';
import ContextMenu from '../general/ContextMenu';

import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';
import { ReactComponent as ActivateIcon } from '../general/icons/Activate.svg';
import { ReactComponent as DeactivateIcon } from '../general/icons/Deactivate.svg';
import { ReactComponent as ResetPasswordIcon } from '../general/icons/ResetPassword.svg';
import { UserDialog } from '../list/dialogs/ProductCatalogDialogs';

class UserView extends TaimerComponent {
    static contextType = SettingsContext;
    headerTitle = this.props.id ? this.tr('User') : this.tr('Add user');
    backButton = {
        visible: true,
        fallbackLocation: { module: 'contacts', action: 'main', selectedTab: 'users' },
    };

    tabs = [
        { id: 'personal_details', label: this.tr('Personal Details') },
        { id: 'memo', label: this.tr('Memo'), disabled: !this.props.id },
        { id: 'attachments', label: this.tr('Attachments'), disabled: !this.props.id },
        { id: 'activities', label: this.tr('Activities')}

    ];

    constructor(props) {
        super(props, null, 'users/UserView');
        this.tabPersonalDetails = React.createRef();
        this.state = {
            user: {},
            showUserNameInHeader: (this.props.selectedTab || 'personal_details') != 'personal_details',
            userDialogData: {
                open: false,
            },
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        DataHandler.get({ url: `users/${this.props.id}` }).done((response) => {
            this.setState({
                user: response.user,
            });
        });
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedTab && prevProps.selectedTab !== this.props.selectedTab) {
            this.setState({ showUserNameInHeader: (this.props.selectedTab || 'personal_details') != 'personal_details' }, () => {
                this.context.functions.setOverrideHeaderTitles(undefined);
            });
        }
    };

    refreshUserData = () => {
        this.tabPersonalDetails.current && this.tabPersonalDetails.current.addUser && this.tabPersonalDetails.current.fetchData();
    };

    unlockUser = (data) => {
        const { id } = data;
        const { enqueueSnackbar } = this.props;

        DataHandler.post({ url: 'settings/users/' + id + '/unlock' }).done((resp) => {
            if (resp.status && resp.status == 'error') {
                enqueueSnackbar(this.tr(`Not enough licenses`), {
                    variant: 'error',
                });
            }
            setTimeout(() => {
                this.refreshUserData();
            }, 1000);
        });
        this.closeUserDialog();
    };

    lockUser = (data) => {
        const { id } = data;
        DataHandler.post({ url: 'settings/users/' + id + '/lock' }).done((resp) => {
            setTimeout(() => {
                this.refreshUserData();
            }, 1000);
        });
        this.closeUserDialog();
    };

    closeUserDialog = () => {
        this.setState({ userDialogData: { ...this.state.userDialogData, open: false } });
    };

    openUserDialog = (data, action, confirmFunc) => {
        this.setState({ userDialogData: { open: true, data, action, onClose: this.closeUserDialog, onConfirm: confirmFunc } });
    };

    onDelete = () => {
        const { user: data } = this.state;
        this.context.functions.showConfirmationDialog({
            header: this.tr('Delete user'),
            warning: this.tr('Do you want to delete user') + ': ' + data.lastname + '  ' + data.firstname + ' (US' + data.id + ')' + '?',
            confirmText: this.tr('Delete'),
            onConfirm: () => {
                DataHandler.delete({ url: 'settings/users/' + data.id })
                    .done(() => {
                        this.context.functions.updateView({ module: 'contacts', action: 'main', selectedTab: 'users' });
                        this.props.enqueueSnackbar(this.tr('User deleted!'), { variant: 'success' });
                    })
                    .fail(() => {
                        this.props.enqueueSnackbar(this.tr('Deleting user failed!'), { variant: 'error' });
                    });
            },
        });
    };

    sendPassword = (data) => {
        const { enqueueSnackbar } = this.props;

        if (this.state.passwordResetInProgress) {
            enqueueSnackbar(this.tr(`Password reset is already in progress`), {
                variant: 'error',
            });
            return;
        }

        enqueueSnackbar(this.tr(`Sending password reset mail`), {
            variant: 'info',
        });

        DataHandler.post({ url: 'settings/users/' + data.id + '/send_password_email' })
            .done((resp) => {
                if (resp.result === 'fail')
                    enqueueSnackbar(this.tr(`Password reset failed. please check user email`), {
                        variant: 'error',
                    });
                else
                    enqueueSnackbar(this.tr(`Password reset mail sent`), {
                        variant: 'success',
                    });
            })
            .fail(() => enqueueSnackbar(this.tr(`Something went wrong. Please try again`), { variant: 'error' }))
            .always(() => this.setState({ passwordResetInProgress: false }));

        this.setState({ passwordResetInProgress: true });
        this.closeUserDialog();
    };

    renderActionButtons = () => {
        const { user: data } = this.state;
        return (
            <div className={cardStyles.actionButtons}>
                <ContextMenu
                    //@ts-ignore
                    className={cardStyles.optionsMenu}
                    buttonProps={{
                        color: 'green',
                        stickyIcon: true,
                        size: 'large',
                    }}
                    variant="outlined"
                    label={this.tr('Actions')}
                    size="medium"
                    placement={'bottom-end'}
                >
                    {data.locked < 1 && (
                        <MenuItem
                            onClick={() => {
                                this.openUserDialog(data, 'Deactivate', this.lockUser);
                            }}
                        >
                            <DeactivateIcon />
                            {this.tr('Dectivate')}
                        </MenuItem>
                    )}
                    {data.locked < 1 && (
                        <MenuItem
                            onClick={() => {
                                this.openUserDialog(data, 'Reset_password', this.sendPassword);
                            }}
                        >
                            <ResetPasswordIcon />
                            {this.tr('Reset password')}
                        </MenuItem>
                    )}
                    {data.locked >= 1 && (
                        <MenuItem
                            onClick={() => {
                                this.openUserDialog(data, 'Activate', this.unlockUser);
                            }}
                        >
                            <ActivateIcon />
                            {this.tr('Activate')}
                        </MenuItem>
                    )}
                    {data.deletable > 0 && (
                        <MenuItem
                            className="delete"
                            onClick={() => {
                                this.onDelete();
                            }}
                        >
                            <RemoveIcon className="Delete" />
                            {this.tr('Delete')}
                        </MenuItem>
                    )}
                </ContextMenu>
            </div>
        );
    };

    getHeaderTitles = () => {
        const noUserName = !this.state?.user?.firstname || !this.state.showUserNameInHeader;
        return !this.props.id
            ? [this.tr('Add user')]
            : noUserName
            ? [this.tr('User')]
            : [
                  {
                      label: `${this.state?.user?.firstname} ${this.state?.user?.lastname}`,
                      sublabel: this.state?.user?.title ? { label: this.state?.user?.title } : undefined,
                  },
              ];
    };

    setShowUserNameInHeader = (showUserNameInHeader) => {
        this.context.functions.setOverrideHeaderTitles(
            showUserNameInHeader && this.state?.user?.firstname
                ? [
                      {
                          label: `${this.state?.user?.firstname} ${this.state?.user?.lastname}`,
                          sublabel: this.state?.user?.title ? { label: this.state?.user?.title } : undefined,
                      },
                  ]
                : undefined
        );
    };

    hasRightToView = (view) => {
        const { privileges } = this.context;

        switch (view) {
            case 'activities':
                return (
                    privileges?.projects?.project_crm_read?.length > 0 || 
                    privileges?.customers?.customer_crm_read?.length > 0
                );
            default: 
                return true;
        }
    };

    render() {
        const {
            functions: { checkPrivilege, hasPrivilege },
        } = this.context;
        const { company } = this.props;
        const { userDialogData } = this.state;
        return (
            <div className="taimer-view" id="user-view">
                <WithTabs checkItemPrivilege={this.hasRightToView} height={55} tabs={this.tabs} selectedTab={this.props.selectedTab} rightComponent={this.renderActionButtons()}>
                    {(selectedTab) => {
                        switch (selectedTab) {
                            case 'personal_details':
                                if ((company > 0 && checkPrivilege('admin', 'admin', company)) || (company == 0 && hasPrivilege('admin', 'admin'))) {
                                    return <TabPersonalDetails setShowUserNameInHeader={this.setShowUserNameInHeader} ref={this.tabPersonalDetails} {...this.props} />;
                                } else {
                                    return <NoPermissionOverlay />;
                                }
                            case 'memo':
                                if (checkPrivilege('admin', 'admin', company) || (company == 0 && hasPrivilege('admin', 'admin'))) {
                                    return <TabMemo {...this.props} />;
                                } else {
                                    return <NoPermissionOverlay />;
                                }
                            case 'attachments':
                                if (checkPrivilege('admin', 'admin', company) || (company == 0 && hasPrivilege('admin', 'admin'))) {
                                    return <TabAttachments {...this.props} editable={true} entity="users" />;
                                } else {
                                    return <NoPermissionOverlay />;
                                }
                            case 'activities': {
                                if (checkPrivilege('admin', 'admin', company) || (company == 0 && hasPrivilege('admin', 'admin'))) {
                                    return <TabActivities entity='users' company={this.state.user.companyId} entity='users' module='users' userId={this.state.user.id}/>
                                } else {
                                    return <NoPermissionOverlay />;
                                }
                            }
                            default:
                                return null;
                        }
                    }}
                </WithTabs>
                {userDialogData && userDialogData.open && <UserDialog {...userDialogData} />}
            </div>
        );
    }
}

export default withSnackbar(UserView);
