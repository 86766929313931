import React from 'react';
import $ from 'jquery';
import Utils from "../general/Utils";
import TaimerComponent from "../TaimerComponent";
import { withSnackbar } from 'notistack';
import Select, { components } from 'react-select';
import { Button, MenuItem, TextField, Switch } from '@mui/material';
import { Portal } from '@mui/base';
import CreatableSelect from 'react-select/creatable';
import DatePicker from "./../general/react-date-range/src/components/DatePicker";
import DataHandler from "../general/DataHandler";
import { validEmail } from "../dialogs/Validate";
import { SettingsContext } from './../SettingsContext';
import List from "../list/List";
import ListRow from "../list/ListRow";
import BillRow from "./BillRow";
import ListCell from "../list/ListCell";
import LinkListCell from "../list/LinkListCell";
import TextInputCell from "../list/cells/TextInputCell";
import OutlinedField from "./../general/OutlinedField";
import SupplierSelect from './../general/SupplierSelect';
import { format } from "date-fns";
import LabelFieldGroup from "../general/LabelFieldGroup";
import { FlexChild, FlexContainer } from "../general/FlexUtils";
import { ArrowBack } from '@mui/icons-material';
import Paper from "../general/Paper";
import { Header, ContentArea, Footer, Totals, determinePaperAndSideMenuWeights } from "../general/PaperUtils";
import BillSummaryRow from "./BillSummaryRow";
import ProgressBar from "../general/ProgressBar";
import PropTypes from 'prop-types';
import DialogBorder from './../dialogs/DialogBorder';
import DialogSection from './../dialogs/DialogSection';
import BillTargeting from './BillTargeting';
import PoSyncing from './PoSyncing';
import BillAssign from "../list/dialogs/BillAssign";
import { ResizeSensor } from "css-element-queries";
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined'
import ContextMenu from '../general/ContextMenu';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import Settings from '@mui/icons-material/Settings';
import Checkbox from "./../general/Checkbox";
import ConfirmationDialog from "./../list/dialogs/ConfirmationDialog";
import "./ReceivedInvoiceView.css";
import NoPermissionOverlay from '../overlays/NoPermissionOverlay';
import AccountingSlider from '../invoices/AccountingSlider';
import AccountingCell from '../invoices/AccountingCell';
import TopBar from './TopBar';
import { returnCurrencyList } from "./../general/Currencies";
import DataList from './../general/DataList';
import RateChanceForInvoiceDialog from "./../settings/dialogs/RateChanceForInvoiceDialog";
import { formatInputNumber } from '../helpers';
import _ from 'lodash';
import Log from './Log';
import FileSaver from 'file-saver';

import { ReactComponent as RemoveIcon } from './../general/icons/remove.svg';
import { ReactComponent as ViewIcon } from './../general/icons/view.svg';
import { ReactComponent as BookkeepingIcon } from '../general/icons/bookkeeping.svg';
import colors from '../colors';

const currencyList = returnCurrencyList();
const currencySymbolsMap = currencyList.reduce((acc, cl) => ({...acc, [cl.id]: cl.symbol}), {});
class ReceivedInvoiceView  extends TaimerComponent {
    static contextType  = SettingsContext;
    static defaultProps = { id: -1 };
    static propTypes    = { enqueueSnackbar: PropTypes.func.isRequired,
                            closeSnackbar: PropTypes.func.isRequired
    };

    headerTitle = this.tr('Bill');
    backButton = {
        visible: true,
        fallbackLocation: { module: 'costs', action: 'main', selectedTab: 'bills' },
    };

    constructor(props, context) {
        super(props, context, "bills/ReceivedInvoiceView");

        this.STATUSES = [
            { id: 0, name: this.tr("Waiting"), color: "#ffb822" }, 
            { id: 2, name: this.tr("Rejected"), color: "#f7548f" }, 
            { id: 3, name: this.tr("Pre-approved"), color: "#979797" },
            { id: 1, name: this.tr("Approved"), color: colors.greenish_cyan }, 
            { id: 5, name: this.tr("To payment"), color: "#2d9ff7" },
            { id: 4, name: this.tr("Archived"), color: "#716aca" },
            { id: 100, name: this.tr('To Circulation'), color: "#ff9900" },
            { id: 101, name: this.tr('Message'), color: "#ff9900" },
            { id: 102, name: this.tr('Edited'), color: "#ff9900" },
            { id: 103, name: this.tr('Targeted'), color: "#ff9900" },
            { id: 104, name: this.tr('Created'), color: "#ff9900" },
            { id: 105, name: this.tr('Synced'), color: colors.greenish_cyan },
            { id: 106, name: this.tr('Unsynced'), color: "#f7548f" },
            { id: 107, name: this.tr('Approving failed'), color: "#f7548f" }
        ];
        this.context = context;
        this.state   = {
            id: isNaN(parseInt(this.props.id)) ? -1 : this.props.id,
            statusLog: [],
            users: [],
            attachments: [],
            receivedInvoiseUserId: undefined,
            receivedInvoiceState: 0,
            previousReceivedInvoiceState: 0,
            selectedUserId: undefined,
            message: "",
            statusDialogOpen: false,
            newStatusId: undefined,
            newStatusName: "",
            editMode: !this.props.id || this.props.id < 0,
            billRows: [],
            project_targeting: [],
            project_targeting_delete: [],
            subtotal: 0,
            supplierId: 0,
            companyCurrency: "EUR",
            company: props.company || context.functions.getCompany("receivedinvoices", "approve") || context.functions.getCompany("receivedinvoices", "pre_approve"),
            comment: "",
            activeCurrencies: [],
            fromFortnox: false,
            fromMeritaktiva: false,
            logLoaded: false,
            invoiceNr: "",
            companies: [],
            suppliers: [],
            supplier: {},
            showArchivedAccounts: true,
            supplierModifiable: false,
            supplierError: false,
            googleDrive: (this.context.addons.googledrive && this.context.addons?.googledrive?.used_by_companies.indexOf(this.context.userObject.companies_id) > -1),
            googleDriveAuthorized: false,
        };

        this.paperMaxWidth = undefined;
        this.horContainerResizeTimeout = undefined;
        this.statusMap = {};
        this.statuses = this.STATUSES.map(s => {
            s.label = s.name;

            return s;
        });

        this.STATUSES.forEach(status => this.statusMap[status.id] = status);

        this.receivedInvoiceInitialized = this.receivedInvoiceInitialized.bind(this);
        this.fetchReceivedInvoiceLog    = this.fetchReceivedInvoiceLog.bind(this);
        this.sendMessage                = this.sendMessage.bind(this);
        this.initialize                 = this.initialize.bind(this);
        this.deleteTargetingRow         = this.deleteTargetingRow.bind(this);
        this.refreshAttachments         = this.refreshAttachments.bind(this);
        this.fetchAttachments           = this.fetchAttachments.bind(this);
        this.uploadFiles                = this.uploadFiles.bind(this);

        this.container         = React.createRef();
        this.targeting         = React.createRef();
        this.receivedInvoice   = React.createRef();
        this.logEntryContainer = React.createRef();
        this.horContainer      = React.createRef();
        this.upload            = React.createRef();
        this.buttonContainer   = React.createRef();
        this.rootContainer     = React.createRef();
        this.poSyncing         = React.createRef();

        this.dialogs = {
            rateChanceForInvoiceDialog: RateChanceForInvoiceDialog
        };

        this.initialize();
    }


    componentDidMount() {
        super.componentDidMount();
        DataHandler.get({url: `subjects/companies/receivedinvoices/approve+pre_approve`}).done(companies => this.setState({companies}));
        new ResizeSensor(this.horContainer.current, (e) => {
            clearTimeout(this.horContainerResizeTimeout);

            this.horContainerResizeTimeout = setTimeout(() => {
                if(this.paperMaxWidth === undefined)
                    return;

                this.setState({
                    ...(determinePaperAndSideMenuWeights(this.paperMaxWidth, this.horContainer.current.getBoundingClientRect().width, [3, 1]))
                });
            }, 150);
        });

        if(this.state.googleDrive) this.checkGoogleDriveAttachments();
    }

    companyChanged = (e) => {
        this.setState({[e.target.name]: e.target.value}, () => {
            this.receivedInvoice.current && this.receivedInvoice.current.initialize(false, e.target.value);
            this.setState({company: e.target.value, supplier: {}}, () => this.poSyncing.current && this.poSyncing.current.unSync()); 
        })
    }

    getCurrency = async () => {
        const companies = await DataHandler.get({ url: `subjects/companies/receivedinvoices/approve+pre_approve`, currency: 1 });
        let c = false;
        let currency = "EUR";
        companies.forEach(company => {
            if (company.id == this.state.company) {
                currency = company.currency;
                c = true;
            }
        })
        if (!c) {
            currency = companies[0].currency;
        }

        return currency;
    }

    getComment = async () => {
        if(this.state.id == -1)
            return "";
            
        const comment = await DataHandler.get({ url: `bills/${this.state.id}/comment`});
        return comment;
    }

    updateComment = (comment) => {
        if (this.state.id == -1)
            return;

        DataHandler.put({ url: `bills/${this.state.id}/comment` }, { 
            comment: comment,
        }).then(() => {
            this.setState({comment: comment});
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.statusLog && this.state.statusLog && prevState.statusLog.length !== this.state.statusLog.length) {
            setTimeout(() => {
                if(this.logEntryContainer.current !== null)
                    this.logEntryContainer.current.scrollTop = this.logEntryContainer.current.scrollHeight;
            }, 250);
        }

        if (prevState.company != this.state.company) {
            this.fetchActiveCurrencies(this.state.company, this.state.id < 1);
        }
    }


    async initialize() {
        const { company } = this.state;
        
        if(this.state.id > -1)
            this.fetchReceivedInvoiceLog(this.state.id);
        
        let attachments = [];
        let users = [];
        let allUsers = [];
        let companyCurrency = "EUR";
        let comment = "";

        try {
            attachments = await this.fetchAttachments();
        } catch(ex) {}
        try {
            users = await DataHandler.get({ url: "subjects/employees/receivedinvoices/approve+pre_approve/" + company });
        } catch(ex) {}
        try {
            allUsers = await DataHandler.get({ url: "subjects/employees", locked: [0, -1, 1]});
        } catch(ex) {}
        try {
            companyCurrency = await this.getCurrency();
        } catch(ex) {}
        try {
            comment = await this.getComment();
        } catch(ex) {}

        this.setState({ 
            attachments,
            users,
            allUsers,
            companyCurrency,
            comment
        },()=>{
            if(this.state.googleDriveAuthorized) this.checkIfHasAttachments('received-invoices');
        });
    }

    fetchActiveCurrencies = (company, resetCurrency = false, rateInfo = false, initial = false) => {
        DataHandler.get({url: `invoices/currency_rates`, company: company}).done(currencies => {
            this.currencies = [...currencies.map(c => ({rateId: c.id > 0 ? c.id : undefined, id: c.currency_label, status: c.active_status, name: c.currency_label, label: c.currency_label, rate: c.currency_rate, symbol: currencySymbolsMap[c.currency_label], rate_reverse: c.currency_rate_reverse, reverse: c.reverse }))];
            const activeCurrencies = this.currencies.filter(c => c.status == 'active');

            if (rateInfo) {
                const currency = rateInfo.invoiceCurrency ? rateInfo.invoiceCurrency : this.state.companyCurrency;
                const currencyFound = activeCurrencies?.find(e => e.id == currency);
                if (!currencyFound) {
                    activeCurrencies.push({ id: currency, label: currency, name: currency, rate: this.state.currencyRate, rateId: 0, status: "active" });
                }
            }
            if (resetCurrency) {
                this.setState({ activeCurrencies, invoiceCurrency: activeCurrencies[0]?.id, currencyRateId: 0, currencyRate: 1 });
                this.receivedInvoice.current && this.receivedInvoice.current.onCurrencyChange({ invoiceCurrency: activeCurrencies[0]?.id, currencyRate: 1, currencyRateId: 0 });
            }
            else {
                this.setState({ activeCurrencies });
            }

            if (rateInfo && initial) {
                if (this.state.id > -1 && rateInfo.currencyRateId) { 
                    const lastRate = this.state.activeCurrencies?.find(c => c.rateId == rateInfo.currencyRateId);
                    if (rateInfo.currencyRateId > 0 && rateInfo.currencyRate !== lastRate?.rate) {
                        this.props.enqueueSnackbar(this.tr("Selected currency rate on invoice does not match to current active rate. Edit to apply updated rate."), { variant: "warning" });
                    }                    
                }
            }
        }).fail(err => {
            this.setState({ activeCurrencies: [] });
        })
    }


    async refreshAttachments() {
        this.setState({ attachments: await this.fetchAttachments() }, () => {
            if(this.state.googleDriveAuthorized) this.checkIfHasAttachments('received-invoices');
        });
    }


    async fetchAttachments() {
        return (this.state.id > -1 ? await DataHandler.get({ url: `bills/${this.state.id}/attachments` }) : []).filter(att => att.id);
    }


    async sendMessage(message) {
        if(this.state.id === undefined || this.state.id < 0)
            return;

        const data = {
            ids: JSON.stringify([this.state.id]),
            message,
            status: "101"
        };

        await DataHandler.post({url: 'bills/status'}, {...data});
        this.fetchReceivedInvoiceLog(this.state.id);
    }


    receivedInvoiceInitialized(data = false, initial = false) {
        if (!data) {
            this.fetchActiveCurrencies(this.state.company, false, {currencyRateId: 0, currencyRate: 1, invoiceCurrency: this.state.companyCurrency }, initial);
            return;
        }
      
        if (data.ri_details) {
            const currencyRateId = data.ri_basic.currency_rates_id;
            const currencyRate = data.ri_basic.currency_rate;
            const supplierId = data.ri_basic?.customers_id;
            const supplier   = supplierId !== undefined ? data.fetchedSuppliers.find(s => s.id === supplierId) : undefined; 
            this.fetchActiveCurrencies(data.ri_receiver.companies_id, false, {currencyRateId, currencyRate, invoiceCurrency: data.ri_basic.invoice_currency }, initial);

            this.setState({
                receivedInvoiceUserId: data.ri_receiver.users_id,
                receivedInvoiceState: data.ri_details.state,
                previousReceivedInvoiceState: data.previous_state,
                project_targeting: data.project_targeting.map(row => { return { ...row } }),
                project_targeting_delete: [],
                company: data.ri_receiver.companies_id,
                invoiceNr: data.ri_details.invoice_nr,
                suppliers: data.fetchedSuppliers || [],
                supplierId,
                supplier,
                supplierModifiable: data.supplierModifiable,
                invoiceCurrency: data.ri_basic.invoice_currency,
                currencyRate: currencyRate,
                currencyRateId: currencyRateId,
                companyTotal: data.ri_details.sum_tax_previous,
                companyTotalNoVat: data.ri_details.sum_previous,
                procountorId: data.ri_details.procountor_id,
                netvisorId: data.ri_details.netvisor_id
            });
        }
        else {
            this.setState({ suppliers: data.fetchedSuppliers }, () =>
                this.fetchActiveCurrencies(this.state.company, false, {currencyRateId: 0, currencyRate: 1, invoiceCurrency: this.state.companyCurrency }, initial)
            );
        }
    }

    suppliersFetched = (suppliers) => {
        this.setState({suppliers});
    }

    async fetchReceivedInvoiceLog(id) {
        let statusLog = await DataHandler.get({url: `bills/${id}/log`});
        let fromFortnox = false;
        let fromMeritaktiva = false;

        if (statusLog.error) {
            this.setState({ fromFortnox: true, fromMeritaktiva: true, logLoaded: true });
            return;
        }

        statusLog = statusLog?.received_invoice_log ? statusLog.received_invoice_log : [];

        statusLog.forEach(s => {
            if (s.event == 105 || s.event == 106) {
                const event = Number(s.users_id) < 1 ? "Automatically synced" : (s.event == 105 ? "Synced" : "Unsynced");
                s.message = this.tr(event + " Purchase order #${po_id} from supplier ${supplier}", {po_id: s.po_id || "#", supplier: s.supplier ? s.supplier : ""});
            }
            if (s.event == 104 && s.message == "fortnox") {
                fromFortnox = true;
                s.message = this.tr("Fetched from") + " Fortnox"; 
            }
            if (s.event == 104 && s.message == "meritaktiva") {
                fromMeritaktiva = true;
                s.message = this.tr("Fetched from") + " Merit Aktiva"; 
            }
        })

        this.setState({ statusLog, fromFortnox, fromMeritaktiva, logLoaded: true });
      
    }

    onDragOver = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy';
    };

    uploadFiles(e) {
        e.stopPropagation();
        e.preventDefault();

        const { enqueueSnackbar } = this.props;
        const { taimerAccount: { attachmentMaxSize } } = this.context;

        const files = Array.from(e.target.files || e.dataTransfer.files);
        let totalSize = 0;

        files.forEach(function(file){
            totalSize += file.size;
        });

        if (totalSize > attachmentMaxSize) {
            enqueueSnackbar(this.tr('Selected file is too large'), {
                variant: "error"
            });
            return;
        }

        files.forEach(ee => {
            DataHandler.file({url: `bills/${this.state.id}/attachments?id=${this.state.id}`}, ee, undefined, ee => {
                if (ee.responseJSON === false) {
                    enqueueSnackbar(this.tr('Uploading file failed'), {
                        variant: "error"
                    });
                    return;
                }

                this.refreshAttachments();
            });
        });
    }

    supplierCreated = (supplier) => {
        this.setState({ supplier, supplierError: false }, () => {
            this.receivedInvoice.current && this.receivedInvoice.current.supplierCreated(supplier);
        });
    }

    accountUpdated = (account) => {
        this.setState({ supplier: account });
    }

    deleteTargetingRow(id) {
        const project_targeting = [...this.state.project_targeting];        

        const rowIndex = project_targeting.findIndex(e => e.id == id);
        const row      = project_targeting[rowIndex];

        const project_targeting_delete = [...this.state.project_targeting_delete, {...row, deleted: 1}];
        
        this.setState({project_targeting_delete});
    }

    onError = (error) => this.setState(error);

    renderTopBar = () => {
        const { invoiceNr } = this.state;
        const number = "#" + invoiceNr;

        const links = [
            {
                header: this.tr("Bills"),
                module: "costs",
                action: "main",
                other: {selectedTab: "bills"}
            }
        ];
        const info = number;
        const buttons = this.createTopBarButtons();
        const progessBar = Number(this.state.id) > -1 ? this.renderProgressBar() : "";
        return <TopBar links={links} info={info} buttons={buttons} extra={[progessBar]} />
    }

    createTopBarButtons = () => {
        const { functions: { checkPrivilege } } = this.context;
        const buttons = [];

        if (this.state.id > -1 && this.state.editMode) {
            const button1 = 
                <Button
                        data-testid="bill-cancel-button"
                        className="btn"
                        onClick={() => {
                            this.setState({ editMode: false, supplierError: false });
                            this.receivedInvoice.current.cancel();
                            this.poSyncing.current.getData();


                        }}>{this.tr("CANCEL")}
                </Button>
            buttons.push(button1);
        }
      
        const button2 = 
            <Button
                data-testid="bill-save-button"
                className="btn"
                onClick={() => {
                    const isNew = !this.props.id || Number(this.props.id) < 0;

                    const isImportedEdit = this.receivedInvoice.current.state.billingInfo.allow_imported_bills_edit > 0 && this.receivedInvoice.current.state.billingInfo.invoicing_api_id;

                    if (isNew && !this.receivedInvoice.current.getSupplierId()) {
                        this.setState({ supplierError: true });
                        this.props.enqueueSnackbar(this.tr("No supplier selected!"), {
                            variant: "error"
                        });
                    }
                    else if (this.state.editMode && this.targeting.current.validate()) {
                        const add = {
                            project_targeting: this.targeting.current
                                ? JSON.stringify([...this.targeting.current.getRows(), ...this.state.project_targeting_delete])
                                : undefined
                        };

                        const poSyncing = this.poSyncing.current.getRows()[0];
                        add.po_syncing = poSyncing ? poSyncing.id : 0;
                        if(isImportedEdit) {
                            this.setState({ confirmImportedEditDialog: true, confirmImportedEditDialogSaveFunc:  () => this.receivedInvoice.current.save(add)});
                        } else {
                            this.receivedInvoice.current.save(add);
                            this.setState({ editMode: !this.state.editMode, supplierError: false });
                        }
                    } else if (!this.state.editMode) {
                        this.setState({ editMode: true });
                        if(isImportedEdit) {
                            this.props.enqueueSnackbar(this.tr("allow_imported_edit_warning"), { variant: "warning" });
                        }
                    }

                }} size="medium">{this.state.editMode ? this.tr("SAVE") : this.tr("EDIT")}
            </Button>

            buttons.push(button2);

        if (!this.state.editMode && (checkPrivilege("receivedinvoices", "pre_approve") || checkPrivilege("receivedinvoices", "approve")) && this.state.id > -1 && (checkPrivilege("receivedinvoices", "approve") ? [0, 1, 3] : [0]).indexOf(parseInt(this.state.receivedInvoiceState)) > -1) {
            const button3 =
                <Button
                    data-testid="bill-reject-button"
                    className="btn red"
                    onClick={() => {
                        // Opens the status dialog.
                        this.setState({
                            newStatusId: 2,
                            newStatusName: this.tr("Rejected"),
                            statusDialogOpen: true
                        });
                    }}
                    size="medium">{this.tr("REJECT")}
                </Button>
            buttons.push(button3);
        }

        return buttons;
    }

    renderProgressBar = () => {
        const { functions: { checkPrivilege } } = this.context;
        const topItems = [
            { id: 0, title: this.tr("Waiting"), color: "#ffb822" }, 
            { id: 3, title: this.tr("Pre-approved"), color: "#979797" }
        ];
        if(checkPrivilege("receivedinvoices", "approve", this.state.company)) {
            topItems.push({ id: 1, title: this.tr("Approved"), color: "#50e3c2" });
            topItems.push({ id: 5, title: this.tr("To payment"), color: "#2d9ff7" });
            topItems.push({ id: 4, title: this.tr("Archived"), color: "#716aca" });
        }
        const disabled = parseInt(this.state.purchaseOrderState) == 2;

        return (
            <ProgressBar
                disabled={disabled}
                tooltip="Change status"
                value={parseInt(this.state.receivedInvoiceState) === 2 ? this.state.previousReceivedInvoiceState : this.state.receivedInvoiceState}
                disableColorAll={false}
                // colorActiveOnly={true}
                items={topItems}
                onClick={item => {
                    const newState = parseInt(item.id);
                    const pre = checkPrivilege("receivedinvoices", "pre_approve", this.state.company);
                    const approve = checkPrivilege("receivedinvoices", "approve", this.state.company);
                    const curState = parseInt(this.state.receivedInvoiceState);

                    if (([0, 3].indexOf(newState) === -1 && !approve) || (newState === 3 && (!pre && !approve))) {
                        this.props.enqueueSnackbar(this.tr("You don't have the needed rights to perform this operation."), {
                            variant: "error"
                        });

                        return;
                    }

                    if (curState === 2 && newState === 4) {
                        this.props.enqueueSnackbar(this.tr("Rejected bills cannot be archived."), {
                            variant: "error"
                        });

                        return;
                    }

                    let allowed = true;
                    if ([4, 5].includes(Number(curState)) && [1, 2, 3].includes(Number(newState)))
                        allowed = false

                    if (!allowed) {
                        this.props.enqueueSnackbar(this.tr("Status change not allowed."), {
                            variant: "error"
                        });

                        return;
                    }

                    // Opens the status dialog.
                    this.setState({
                        newStatusId: newState,
                        newStatusName: item.title,
                        statusDialogOpen: true
                    });
                }} />
        )
    }

    openCurrencyRateDialog = (dialogData) => {
        this.setState({
            currentDialog: 'rateChanceForInvoiceDialog',
            dialogData
        });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false });
    };

    toggleEditMode = () => {
        this.setState({ editMode: !this.state.editMode });
    }

    multicurrencyIntegrationInUse = () => {
        const { addons } = this.context;

        return (addons?.netvisor?.used_by_companies?.indexOf(this.state.company) > -1) ||
            (addons?.procountor?.used_by_companies?.indexOf(this.state.company) > -1) ||
            (addons?.fortnox?.used_by_companies?.indexOf(this.state.company) > -1) ||
            (addons?.meritaktiva?.used_by_companies.indexOf(this.state.company) > -1)
    }

    isFromMulticurrencyIntegration = () => {
        const { procountorId, netvisorId, fromFortnox, fromMeritaktiva } = this.state;
        return procountorId || netvisorId || fromFortnox || fromMeritaktiva;
    }

    renderCurrencySettings = () => {
        const { activeCurrencies, companyCurrency, invoiceCurrency, currencyRate, companyTotal, editMode, logLoaded } = this.state;
        const { addons } = this.context;
        const currency = invoiceCurrency ? invoiceCurrency : companyCurrency;
        const hasInvoiceCurrency = addons?.invoice_currency;
        const showCurrencySettings = this.multicurrencyIntegrationInUse() || this.isFromMulticurrencyIntegration() || hasInvoiceCurrency;
        const settings = [];

        showCurrencySettings && settings.push(
            <DataList 
                label={this.tr("Invoice currency")}
                name="currency_code"
                className={"bill-details-field"}
                value={activeCurrencies?.find(e => e.id == currency)}
                options={activeCurrencies}
                onChange={(e) => { this.setState({    
                        invoiceCurrency: e.id,
                        currencyRate: e.rate,
                        currencyRateId: e.rateId
                    }, () => this.receivedInvoice.current.onCurrencyChange({ invoiceCurrency: e.id, currencyRate: e.rate, currencyRateId: e.rateId }))
                }
                }
                isDisabled={!editMode || this.isFromMulticurrencyIntegration() || !logLoaded || !hasInvoiceCurrency} 
                shownCount={20}
                />
        );

        showCurrencySettings && settings.push(
            <OutlinedField 
                label={this.tr("Currency_rate")}
                name="currency_rate"
                className={"bill-details-field"}
                value={currencyRate || '1.000000'}
                disabled />
        );

        showCurrencySettings && settings.push(
            <OutlinedField 
                label={this.tr("Total value in company's currency")}
                name="currency_total"
                className={"bill-details-field"}
                value={`${companyTotal ? formatInputNumber(companyTotal) : 0} ${currencySymbolsMap[companyCurrency]}`}
                disabled />
        );        
        
        settings.length && settings.unshift(
            <h1>{this.tr("Currency")}</h1>
        )
        
        return (
            <div className="bill-details-section">
                {settings}
            </div>
        )
    }


    renderBillDetailsFields = () => {
        const { companies, company, suppliers, supplier, editMode, showArchivedAccounts, supplierModifiable, supplierError } = this.state;
        const { enqueueSnackbar } = this.props;

        return (
            <div className="bill-details-section">
                <h1>{this.tr("Details")}</h1>
                {companies.length > 1 &&
                    <OutlinedField
                        label={this.tr("Company")}
                        value={company}
                        className={"bill-details-field"}
                        disabled={Number(this.state.id) > -1}
                        select
                        onChange={e => this.companyChanged(e)}>
                        {companies.map(row => (
                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                        ))}
                    </OutlinedField>
                }
                {!supplierModifiable &&
                    <>
                        <SupplierSelect
                            name="bill_supplier_select"
                            label={this.tr("Supplier")}
                            value={supplier}
                            options={suppliers}
                            showArchivedAccounts={showArchivedAccounts}
                            className={"bill-details-field"}
                            isDisabled={!editMode}
                            shownCount={20}
                            company={company}
                            error={supplierError}
                            enqueueSnackbar={enqueueSnackbar}
                            supplierCreated={(supplier) => this.supplierCreated(supplier)}
                            accountUpdated={(supplier) => {
                                this.setState({ supplier, supplierError: false }, () => {
                                    this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.tr("Account ${account} updated successfully!", {account: supplier.name}), {
                                        variant: "success"
                                    });
                                    this.receivedInvoice.current && this.receivedInvoice.current.supplierChanged(supplier);
                                });
                            }}
                            onChange={supplier => {
                                this.setState({ supplier, supplierError: false }, () => {
                                    this.receivedInvoice.current && this.receivedInvoice.current.supplierChanged(supplier);
                                });
                            }}
                        />
                        {editMode && <Checkbox
                            name="showArchived"
                            checked={showArchivedAccounts}
                            onChange={() =>
                                this.setState({ showArchivedAccounts: !showArchivedAccounts })
                            }
                            label={this.tr("Show archived accounts in supplier search")}
                        />}
                    </>}
            </div>)
    }

    /* Google Drive related functionality - would be better if this would use TabAttachments also */

    checkGoogleDriveAttachments = () => {
        //First we check if user is authorized
        DataHandler.post({ url: `drive/google/connect` }, { company: this.props.company })
            .done(response => {
                if (response.authenticated){
                    this.checkIfHasAttachments('received-invoices');
                    this.setState({googleDriveAuthorized: true});
                } 
            })
            .fail(response => {
                console.log(response);
            });
    }

    checkIfHasAttachments = (type) => {
        DataHandler.post({ url: 'drive/google/getattachmentsforinvoioces' }, { id: this.props.id, type: type })
            .done(response => {
                if (response.status !== 'no-attachments' && Object.keys(response.files).length > 0) {
                        this.setState({ attachments: [...this.state.attachments, ...response.files] });
                }
            })
            .fail(err => {
                console.log(err);
            });
    }

    deleteDriveFile = (fileId) => {
        const { enqueueSnackbar } = this.props;
        DataHandler.post({ url: 'drive/google/deletefile' }, { file_id: fileId })
            .done(response => {
                if (!response.error) {

                    enqueueSnackbar(this.tr('Attachment removed succesfully!'), {
                        variant: "success"
                    });

                } else {
                    console.log(response.error);
                }
            })
            .fail(err => {
                console.log(err);
            });
    }


    renderRightColumnComponents = () => {
        const { tr } = this;
        const {id} = this.state;
        const { addons } = this.context;

        const showCommentField = (addons?.netvisor?.used_by_companies?.indexOf(this.state.company) > -1) ||
            (addons?.heeros?.used_by_companies?.indexOf(this.state.company) > -1)

        return (
        <>
            {id > -1 && 
                <div className="bill-details-section">
                    <h1>{this.tr("Messages & Log")}</h1>
                    <Log
                        users={this.state.allUsers}
                        statusLog={this.state.statusLog}
                        statusMap={this.statusMap}
                        sendMessage={(message) => this.sendMessage(message)}
                        id={this.state.id}
                        className="received-invoice-log"
                    />
                </div>
            }
            {this.renderCurrencySettings()}
            {id > -1 &&
                <>
                {showCommentField &&<div className="bill-details-section">
                    <h1>{this.tr("Bill comment")}</h1>
                    <div id="bill-comment">
                        <OutlinedField
                            className={"bill-details-field"}
                            label={tr("Comment")}
                            onChange={(e) => this.updateComment(e.target.value)}
                            disabled={this.state.id == -1}
                            name="comment"
                            value={this.state.comment}
                            fullWidth={true}
                        />
                    </div>
                </div>}

                <div id="attachments">
                    <h1>{this.tr("Attachments")}</h1>
                    <div className="add-file" onDragOver={this.onDragOver} onDrop={this.uploadFiles}>
                        <div>
                            <CloudDownloadOutlined /><br />
                            <span onClick={() => this.upload.current.click()}>{this.tr("Choose a file")}</span> {this.tr("or drag it here")}
                            <input ref={this.upload} onChange={this.uploadFiles} type="file" multiple />
                        </div>
                    </div>
                    {this.state.attachments.length > 0 && <List
                        fluid={true}
                        id="receivedInvoiceAttachmentList"
                        height={200}
                        data={this.state.attachments.sort((a, b) => {
                            const af = a.filename.toLowerCase();
                            const bf = b.filename.toLowerCase();

                            if (af < bf)
                                return -1;
                            if (af > bf)
                                return 1;

                            return 0;
                        })}
                        userListSettingsKey="ri_attachment_list"
                        saveColumnConfig={true}
                        rowHeight={44}
                        listRowType={ReceivedInvoiceAttachmentRow}
                        rowProps={{
                            delete: (id,locationtype) => {
                                this.setState({
                                    deleteAttachmentId: id,
                                    deleteAttachmentDialogOpen: true,
                                    deleteFromGoogleDrive: locationtype === 'googledrive'
                                });
                            }
                            /*delete: async (id) => {
                            }*/
                        }}
                        columns={[
                            { name: "context", header: "", width: 30, showMenu: false },
                            { name: "filename", header: this.tr("Filename"), width: 170, showMenu: false }
                        ]} />}
                </div>
                </>}
        </>)
    }

    render() {
        const { tr } = this;
        const { functions: { checkPrivilege } } = this.context;
        const { enqueueSnackbar, closeSnackbar } = this.props;
        const { error, companyCurrency, currentDialog, dialogData, activeCurrencies, companies, company, suppliers, supplier, editMode, showArchivedAccounts, supplierModifiable } = this.state;
        const Dialog = currentDialog ? this.dialogs[currentDialog] : undefined;

        if (error) {
            return <NoPermissionOverlay />
        }

        return (
            <div id="receivedInvoiceView" ref={container => this.rootContainer = container}>
                {this.state.statusDialogOpen &&
                    <BillAssign 
                        status={this.state.newStatusId}
                        makeRequest={true}
                        ids={[this.state.id]}
                        user={this.context.userObject.usersId}
                        autoCompleteData={{ 
                            users: this.state.users.map(u => {
                                u.name = `${u.lastname} ${u.firstname}`;

                                return u;
                            }), 
                            bills_statuses: this.statuses 
                        }}
                        onClose={() => this.setState({ statusDialogOpen: false })}
                        onSaved={async obj => {
                            const details = {
                                userId: obj.user,
                                message: obj.message,
                                state: obj.status 
                            };

                            if (obj.status == 2) {
                                const refreshedData = await DataHandler.get({url: `bills/${this.props.id}/data`});
                                this.setState({ statusDialogOpen: false, receivedInvoiceState: 2, previousReceivedInvoiceState: refreshedData.previous_state });
                            }
                            else {
                                this.setState({ statusDialogOpen: false, newStatusId: undefined, receivedInvoiceState: details.state });
                                if (parseInt(details.userId) !== this.context.userObject.usersId)
                                    this.context.functions.updateView({ module: 'costs', action: 'main', selectedTab: 'bills' });
                            }

                            this.fetchReceivedInvoiceLog(this.state.id);

                        }} />}
                        <FlexContainer forwardedRef={this.container} style={{ width: "100%" }}>
                            <FlexChild weight={1}>
                                <FlexContainer className="controls" style={{ width: "100%" }}>
                                    <FlexChild weight={2}>
                                        {this.renderTopBar()} 
                                    </FlexChild>
                                    </FlexContainer>
                                    <FlexContainer>
                                        <FlexChild weight={1}>
                                            <BillTargeting
                                                company={this.state.company}
                                                ref={this.targeting}
                                                editMode={this.state.editMode} 
                                                rows={this.state.project_targeting} 
                                                billRows={this.state.billRows}
                                                total={this.state.companyTotalNoVat}
                                                currency={companyCurrency}
                                                onDelete={this.deleteTargetingRow}
                                                enqueueSnackbar={this.props.enqueueSnackbar}/>
                                            </FlexChild>
                                        </FlexContainer>
                                    <FlexContainer>
                                        <FlexChild weight={1}>
                                            <PoSyncing
                                                ref={this.poSyncing}
                                                supplierId={this.state.supplierId}
                                                company={this.state.company}
                                                editMode={this.state.editMode} 
                                                billsId={this.state.id} 
                                                currency={companyCurrency}
                                                enqueueSnackbar={this.props.enqueueSnackbar}/>
                                            </FlexChild>
                                    </FlexContainer>
                                        <FlexContainer forwardedRef={this.horContainer}>
                                            <FlexChild className="container-left" weight={65}>
                                                <ReceivedInvoice
                                                    company={this.state.company}
                                                    ref={this.receivedInvoice} 
                                                    activeCurrencies={activeCurrencies}
                                                    openCurrencyRateDialog={this.openCurrencyRateDialog}
                                                    closeDialog={this.closeDialog}
                                                    onInit={this.receivedInvoiceInitialized}
                                                    suppliersFetched={this.suppliersFetched}
                                                    onError={this.onError} 
                                                    onSave={id => {
                                                        if(!id) {
                                                            this.props.enqueueSnackbar(this.tr("There was an error saving the bill. You can try re-saving the bill, but the problem may persist."), { variant: "error" });
                                                            this.setState({ editMode: true });
                                                            return;
                                                        }

                                                        this.setState({ id: id }, () => {
                                                            this.poSyncing.current.getData();
                                                            this.props.updateUrl({ id: this.state.id });
                                                            this.fetchReceivedInvoiceLog(this.state.id);
                                                        });
                                                    }}
                                                    billRowsUpdated={rows => this.setState({ billRows: rows })}
                                                    subtotalUpdated={(subtotal, companyTotal, companyTotalNoVat) => this.setState({ subtotal, companyTotal, companyTotalNoVat })}
                                                    enqueueSnackbar={enqueueSnackbar}
                                                    closeSnackbar={closeSnackbar}
                                                    id={this.state.id} 
                                                    editMode={this.state.editMode} 
                                                    toggleEditMode={this.toggleEditMode}
                                                    paperProps={{ container: this.container }}
                                                    buttonContainer={this.buttonContainer}
                                                    onCompanyChange={(company) => {
                                                        this.setState({company}, () => this.poSyncing.current && this.poSyncing.current.unSync()); 
                                                    }}
                                                    rootContainer={this.rootContainer}/>
                                                
                                                    {this.state.attachments.filter(attachment => {
                                                        return attachment.type && attachment.type.indexOf("/") > -1 && (attachment.type.split("/")[0] === "image" || attachment.type === "application/pdf");
                                                    }).map(attachment => {
                                                        const params = {
                                                            attachment: "attachment",
                                                            id: attachment.type !== "application/pdf" ? attachment.id : attachment.thumb_id,
                                                            _auth: this.context.functions.getStorage().taimerToken,
                                                        };
                                                        return <img className="attachment" src={this.context.functions.getDownloadPath() + $.param(params)} alt="" />;
                                                    })}
                                                </FlexChild>
                                                <FlexChild className="container-right" weight={35}>
                                                    <div className="shadowedContainer rightMargin">
                                                        {(companies.length > 1 || !supplierModifiable) && this.renderBillDetailsFields()}
                                                        {this.renderRightColumnComponents()}
                                                    </div>
                                                    </FlexChild>
                                                </FlexContainer>
                                            </FlexChild>
                                        </FlexContainer>
                                        {this.state.deleteAttachmentDialogOpen && <ConfirmationDialog
                                            data={{ text: this.tr("Do you really want to delete the attachment?") }}
                                            onDialogSave={async () => {

                                                if(this.state.deleteFromGoogleDrive) this.deleteDriveFile(this.state.deleteAttachmentId);
                                                else await DataHandler.delete({url: `attachments/${this.state.deleteAttachmentId}`});

                                                this.refreshAttachments();

                                                this.setState({ 
                                                    deleteAttachmentId: undefined, 
                                                    deleteAttachmentDialogOpen: false,
                                                    deleteFromGoogleDrive: false
                                                });
                                            }}
                                            onDialogClose={() => this.setState({ 
                                                deleteAttachmentId: undefined, 
                                                deleteAttachmentDialogOpen: false,
                                                deleteFromGoogleDrive: false
                                            })}
                                        />}
                                        {this.state.confirmImportedEditDialog && <ConfirmationDialog
                                            data={{ text: this.tr("Do you really want to edit imported bill?") }}
                                            onDialogSave={async () => {

                                                this.state.confirmImportedEditDialogSaveFunc();

                                                this.setState({ 
                                                    editMode: false,
                                                    supplierError: false,
                                                    confirmImportedEditDialog: false,
                                                    confirmImportedEditDialogSaveFunc: false
                                                });
                                            }}
                                            onDialogClose={() => this.setState({ 
                                                confirmImportedEditDialog: false,
                                                confirmImportedEditDialogSaveFunc: false
                                            })}
                                        />}
                                        {Dialog && <Dialog
                                            open
                                            onDialogClose={this.closeDialog}
                                            onDialogSave={() => {}}
                                            data={dialogData}
                                        />} 
                                    </div>
        );
    }
}

class ReceivedInvoiceAttachmentRow extends ListRow {
    constructor(props, context) {
        super(props, context, {}, {}, "bills/ReceivedInvoiceAttachmentRow");
    }


    defineClassName() {
        return "riAttachmentRow";
    }


    defineCells() {
        const download = () => {
            const { id } = this.props.data;
            if(this.props.data?.locationtype === 'googledrive'){
                window.open(this.props.data?.file_url, "_blank");
            } else {
                DataHandler.getArrayBuffer({ url: `attachment/${id}`}).done((response, status, xhr) => {
                    const type = xhr.getResponseHeader("content-type") || "";
                    const filename = xhr.getResponseHeader("Content-Disposition").match(/filename=(.*)/)[1] || "file";
                    const blob = new Blob([response], { type });
                    FileSaver.saveAs(blob, filename);
                });
            }
        }
        
        return {
            context: 
            <ListCell permanentEditMode>
                <ContextMenu className="cell row-menu" label={<ContextMenuIcon />} buttonProps={{ className: 'action-menu' }}  noExpandIcon>

					<MenuItem onClick={download}><ViewIcon />{this.tr("View")}</MenuItem>
					<MenuItem className="delete" onClick={() => {this.props.rowProps.delete(this.props.data.id,this.props.data?.locationtype)}}><RemoveIcon className="Delete"/>{this.tr("Delete")}</MenuItem>
                </ContextMenu>
            </ListCell>,
            filename: <LinkListCell editable={false} value={this.props.data.filename} handleClick={download} />
        };
    }
}

class ReceivedInvoice extends TaimerComponent {
    static contextType  = SettingsContext;
    static defaultProps = {
        id: -1, 
        paperProps: {},
        editMode: true,
        onSave: () => {},
        onInit: () => {},
        billRowsUpdated: () => {},
        subtotalUpdated: () => {}
    };


    constructor(props, context) {
        super(props, context, "bills/ReceivedInvoice");

        this.context = context;

        this.list = React.createRef();

        this.lastData                = undefined;
        this.updateTotalTimeout      = undefined;
        this.addedSupplierIds        = [];
        this.addedSupplierAddressIds = [];

        this.state = {
            id: this.props.id,
            supplierId: undefined,
            // supplierBusinessId: undefined,
            suppliers: [],
            supplierAddresses: [],
            supplierAddressId: undefined,
            supplierAddress: { name: undefined, address: undefined, zip: undefined, city: undefined, state: undefined, country: undefined, phone: undefined, email: undefined }, 
            bankAccounts: [],
            billingInfo: { bill_number: undefined, bill_date: new Date(), voucher_date: new Date(), due_date: new Date(), reference: undefined, buyer_reference: undefined, penalty_interest: undefined, additional_info: "" },
            billRows: [],
            subtotal: 0,
            vatTotal: 0,
            total: 0,
            nonValidFields: [],
            companies: [],
            company: props.company,
	        showAccounting: false,
            accountingData: [],
            companyCurrency: "EUR",
            deletedRows: [],
            supplierChanged: false,
            usesAccounting: false
        };

        this.initialize = this.initialize.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.getSuppliers = this.getSuppliers.bind(this);
        this.getSupplierAddresses = this.getSupplierAddresses.bind(this);
        this.setSupplierAddress = this.setSupplierAddress.bind(this); 
        this.editSupplierAddress = this.editSupplierAddress.bind(this);
        this.setBillingInfo = this.setBillingInfo.bind(this);
        this.editBillingInfo = this.editBillingInfo.bind(this); 
        this.fetchReceivedInvoice = this.fetchReceivedInvoice.bind(this);
        this.setReceivedInvoiceData = this.setReceivedInvoiceData.bind(this);
        this.addBankAccount = this.addBankAccount.bind(this);
        this.editBankAccount = this.editBankAccount.bind(this);
        this.editingDisabledSnackbarNotificationKey = undefined;

        this.accountingAddons = [
            "netvisor",
            "heeros",
            "procountor"
        ];
    
        this.initialize(() => {
            if(!isNaN(parseInt(props.id)) && props.id > -1)
                this.fetchReceivedInvoice(props.id, true);
            else 
                this.props.onInit && this.props.onInit({fetchedSuppliers: this.state.suppliers}, true);
        });
    }
    componentDidMount() {
	    this.getAccountingData();

        DataHandler.get({url: `subjects/companies/receivedinvoices/approve+pre_approve`, currency: 1}).done(companies => {
            let c = false;
            companies.forEach(company => {
                if(company.id == this.state.company) {
                    this.setState({companyCurrency: company.currency});
                    c = true;
                }
            })
            if (!c) {
                this.setState({companyCurrency: companies[0].currency})
            }
        });
    }

    getSupplierId = () => {
        return this.state.supplierId;
    }

    async fetchReceivedInvoice(id, initial = false) {
        const data = await DataHandler.get({url: `bills/${id}/data`});
        this.setReceivedInvoiceData(data, initial);
    }
    
    isCompanyUsingAccounting = () => {
        const { addons } = this.context;
        const { company } = this.state;
        let usesAccounting = false;

        this.accountingAddons.forEach(a => {
            if (addons[a] && addons[a].used_by_companies.indexOf(company) > -1) {
                usesAccounting = true;
            }
        });

        return usesAccounting;
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.supplierId !== this.state.supplierId)
            this.getSupplierAddresses(this.state.supplierId);

        if(prevState.supplierAddressId !== this.state.supplierAddressId && (this.state.id < 0 || this.state.supplierChanged))
            this.setSupplierAddress(this.state.supplierAddresses.find(address => address.id == this.state.supplierAddressId));

        if(prevState.billRows !== this.state.billRows)
            this.props.billRowsUpdated(this.state.billRows);
        
        if (this.state.company !== prevState.company) {
            DataHandler.get({url: `subjects/companies/receivedinvoices/approve+pre_approve`, currency: 1}).done(companies => {
                let c = false;
                companies.forEach(company => {
                    if(company.id == this.state.company) {
                        this.setState({companyCurrency: company.currency});
                        c = true;
                    }
                })
                if (!c) {
                    this.setState({companyCurrency: companies[0].currency})
                }
            });
            this.getAccountingData();
        }

        const noEditingDisabledInfo = this.state.showAccounting != prevState.showAccounting;
        if (!noEditingDisabledInfo && this.state.billingInfo.editing_disabled && this.props.editMode && !this.editingDisabledSnackbarNotificationKey) {
            this.editingDisabledSnackbarNotificationKey = this.props.enqueueSnackbar(this.tr('Invoice fields can not be edited'), {
                variant: "info"
            });
        } else {
            this.props.closeSnackbar(this.editingDisabledSnackbarNotificationKey);
            this.editingDisabledSnackbarNotificationKey = false;
        }
    }
        

    setReceivedInvoiceData(data, initial = false) {
        this.lastData = data;

        if (data.status === 'FAILED') {
            this.props.onError({error: data.errorcode})
            return;
        }

        // efina_id is int in database, returned as a string
        // Netvisor and Procountor needs to be enabled because customers want to set accounting info for rows before approving the invoice back to Netvisor/Procountor
        const invoicingApiId = parseInt(data.ri_details.efina_id) || data.ri_details.maventa_id || data.ri_details.talenom_id || data.ri_details.accounting_integration_id /* || data.ri_details.netvisor_id || data.ri_details.procountor_id*/;
        let editing_disabled = invoicingApiId ? true : false;
        if(data.ri_details.allow_imported_bills_edit > 0) {
            editing_disabled = false;
        }

        const state = {
            company: data.ri_receiver.companies_id,
            invoiceCurrency: data.ri_basic.invoice_currency,
            currencyRate: data.ri_basic.currency_rate,
            currencyRateId: data.ri_basic.currency_rates_id,
            allow_imported_bills_edit: data.ri_details.allow_imported_bills_edit,
            supplierId: data.ri_basic.customers_id,
            // supplierBusinessId: data.ri_basic.company_bid,
            supplierAddress: {
                company: data.ri_basic.company_name,
                address: data.ri_basic.company_address1,
                zip: data.ri_basic.company_post_code,
                city: data.ri_basic.company_post_office,
                state: data.ri_basic.company_state,
                country: data.ri_basic.company_country,
                business_id: data.ri_basic.company_bid,
                phone: data.ri_basic.company_phone,
                email: data.ri_basic.company_email
            },
            bankAccounts: data.bankaccounts,
            billingInfo: {
                bill_number: data.ri_details.invoice_nr,
                bill_date: data.ri_details.date,
                voucher_date: data.ri_details.voucher_date,
                due_date: data.ri_details.date_due,
                reference: data.ri_details.reference_nr,
                buyer_reference: data.ri_details.original_customer_reference,
                penalty_interest: parseFloat(data.ri_details.company_interest),
                additional_info: data.ri_details.message,
                maventa_id: data.ri_details.maventa_id,
                talenom_id: data.ri_details.talenom_id,
                procountor_id: data.ri_details.procountor_id,
                netvisor_id: data.ri_details.netvisor_id,
                efina_id: data.ri_details.efina_id,
                invoicing_api_id: invoicingApiId,
                editing_disabled: editing_disabled,
                allow_imported_bills_edit: data.ri_details.allow_imported_bills_edit
            },
            billRows: data.invoice_rows.map(row => {
                return {
                    id: row.id,
                    description: row.subject,
                    quantity: parseFloat(row.amount),
                    unit_price: parseFloat(row.price),
                    total_no_vat: parseFloat(row.sum),
                    currency_unit_price: parseFloat(row.currency_price),
                    currency_total_no_vat: parseFloat(row.currency_sum),
                    vat: parseFloat(row.tax) * 100,
                    total: parseFloat(row.sum_tax),
                    currency_total: parseFloat(row.currency_sum_tax),
                    purchase_account: row.purchase_account,
                    dimension_item: row.dimension_item,
                    purchase_vatcode: row.purchase_vatcode,
                    dimension_values: row.dimension_values
                };
            }),
            accountingData: data.invoice_rows.map(row => {
                return {
                    id: row.id,
                    purchase_account: row.purchase_account,
                    dimension_item: row.dimension_item,
                    purchase_vatcode: row.purchase_vatcode,
                    dimension_values: row.dimension_values
                };
            }).reverse()
        };

        data.supplierModifiable = this.state.id > -1 && (state.supplierAddress?.company || state.supplierId == 0);
        data.fetchedSuppliers = this.state.suppliers;
        this.props.onInit(data, initial);

        this.setState(state);
    }

    getAccountingData = () => {
        const usesAccounting = this.isCompanyUsingAccounting();
        DataHandler.get({ url: 'invoices/accounting/' + this.state.company}).done(data => {  
            data.purchaseVatCodes = _.cloneDeep(data.purchaseVatCodes).map(pv => {
                pv.topText = pv.percentage + " %";
                pv.subText = pv.name;
                return pv;
            })

            this.setState({
                ...data,
                usesAccounting
            });
        });
    }

    async initialize(callback = () => {}, newCompany = false) {
        const suppliers  = this.transformData(await this.getSuppliers(newCompany));

        this.setState({
            suppliers: suppliers,
            company: newCompany ? newCompany : this.state.company,
            supplierId: 0,
            supplier: {}
        }, () => {
            !newCompany && callback();
            newCompany && this.props.onInit && this.props.onInit({fetchedSuppliers: this.state.suppliers}, true);
        });
    }

    onCurrencyChange(data) {
        const { invoiceCurrency, currencyRate, currencyRateId} = data;
        const dataRows = this.list.current.getVisibleRows().map(row => row.getData());

        const billRows = dataRows.map(row => {
            const vatMultip = 1 + parseFloat(row.vat) / 100;
            const currency_unit_price = (parseFloat(row.unit_price) * currencyRate).toFixed(2);
            const currency_total_no_vat = (currency_unit_price * Number(row.quantity)).toFixed(2);
            const currency_total = (currency_total_no_vat * vatMultip).toFixed(2);
            return {
                ...row,
                currency_unit_price,
                currency_total_no_vat,
                currency_total,
            };
        });

        this.setState({
            invoiceCurrency,
            currencyRate: !currencyRate || currencyRate == 1 ? "1.000000" : currencyRate,
            currencyRateId,
            billRows
        }, () => this.updateTotals(billRows))
    }

    
    // Returns a boolean; true is saving is ok, false otherwise.
    checkSave() {
        const { email } = this.state.supplierAddress;
        const nonValidFields = [];

        if(!validEmail(email, true))
            nonValidFields.push("email");

        this.setState({ nonValidFields });

        return nonValidFields.length === 0;
    }

    async save(add = {}, checkCurrency = true, rateUpdated = false, accountingSave = false) {
        const { currencyRateId, currencyRate, invoiceCurrency } = this.state;
        const fixedRate = this.props.activeCurrencies?.find(c => c.rateId == currencyRateId || c.id == invoiceCurrency)?.rate || 1;

        if (checkCurrency && currencyRateId && currencyRateId > 0 && currencyRate !== fixedRate) {
            const dialogData = {
                onSave: () => this.save(add, false),
                onSaveAndEdit: () => this.setState({ currencyRate: fixedRate }, () => this.save(add, false, true)),
                text: this.tr("The currency has been updated since last save. Do you want to update the invoice with the new rates?`Note! this might change the total sum of the invoice.")
            }
            this.props.openCurrencyRateDialog(dialogData);
            return;
        }
        this.props.closeDialog();

        let dataRows         = this.list.current.getVisibleRows().map(row => row.getData());

        if (rateUpdated) {
            dataRows = dataRows.map(row => {
                row.currency_unit_price   = parseFloat(row.unit_price) * fixedRate;
                row.currency_total        = parseFloat(row.total) * fixedRate;
                row.currency_total_no_vat = parseFloat(row.total_no_vat) * fixedRate;
                return row;
            });
        }

        const sum            = (dataRows && dataRows.length > 0 ? dataRows : [0]).map(r => parseFloat(r.total_no_vat)).reduce((a, c) => a + c);
        const sumTax         = (dataRows && dataRows.length > 0 ? dataRows : [0]).map(r => parseFloat(r.total)).reduce((a, c) => a + c);
        const currency_sum     = (dataRows && dataRows.length > 0 ? dataRows : [0]).map(r => parseFloat(r.currency_total_no_vat)).reduce((a, c) => a + c);
        const currency_sum_tax = (dataRows && dataRows.length > 0 ? dataRows : [0]).map(r => parseFloat(r.currency_total)).reduce((a, c) => a + c);
        const virtualBarcode =  "";
        const name           = this.props.id !== undefined && parseInt(this.props.id) > -1 ? this.state.supplierAddress.company : this.state.suppliers.find(s => s.id == this.state.supplierId).label;
        const address = this.props.id !== undefined && parseInt(this.props.id) > -1 ? this.state.supplierAddress.address : this.state.supplierAddresses.length > 0 ? this.state.supplierAddresses.find(address => address.id == this.state.supplierAddressId).label : ""; 

        this.state.deletedRows.forEach(function(row) {
            dataRows.push(row);
        });

        const data = {
            id: this.state.id,
            companies_id: this.state.company,
            state: this.state.id > -1 ? undefined : 0,
            receiver_details: JSON.stringify({ companies_id: this.state.company, users_id: this.context.userObject.usersId }),
            seller_details: JSON.stringify({
                id: this.state.supplierId,
                customers_id: this.state.supplierId,
                name: name,
                company_name: name,
                company_address1: address,
                company_post_code: this.state.supplierAddress.zip,
                company_post_office: this.state.supplierAddress.city,
                company_state: this.state.supplierAddress.state,
                company_country: this.state.supplierAddress.country,
                company_bid: this.state.supplierAddress.business_id,
                company_phone: this.state.supplierAddress.phone,
                company_email: this.state.supplierAddress.email
            }),
            invoice_details: JSON.stringify({
                invoice_nr: this.state.billingInfo.bill_number, 
                date: format(this.state.billingInfo.bill_date, "YYYY-MM-DD"),
                voucher_date: format(this.state.billingInfo.voucher_date, "YYYY-MM-DD"),
                date_due: format(this.state.billingInfo.due_date, "YYYY-MM-DD"), 
                reference_nr: this.state.billingInfo.reference, 
                original_customer_reference: this.state.billingInfo.buyer_reference, 
                company_interest: this.state.billingInfo.penalty_interest, 
                sum: sum,
                sum_tax: sumTax, 
                currency_sum: currency_sum,
                currency_sum_tax: currency_sum_tax, 
                message: this.state.billingInfo.additional_info,
                virtual_barcode: virtualBarcode,
                invoice_currency: this.state.invoiceCurrency,
                currency_rate: this.state.currencyRate,
                currency_rates_id: this.state.currencyRateId
            }),
            bankaccounts: JSON.stringify(this.state.bankAccounts),
            invoice_rows: JSON.stringify(dataRows.map((row, index) => {
                const accountingData = this.state.accountingData.find(r => r.id == row.id);
                return {
                    id: row.id,
                    rowIndex: index,
                    subject: row.description,
                    amount: parseFloat(row.quantity),
                    price: parseFloat(row.unit_price),
                    sum: row.total_no_vat,
                    sum_tax: row.total,
                    currency_price: parseFloat(row.currency_unit_price),
                    currency_sum: row.currency_total_no_vat,
                    currency_sum_tax: row.currency_total,
                    tax: (row.vat / 100).toFixed(4),
                    purchase_account: accountingData ? accountingData.purchase_account : "",
                    dimension_item: accountingData ? accountingData.dimension_item : "",
                    purchase_vatcode: row.purchase_vatcode || "",
                    dimension_values: accountingData ? accountingData.dimension_values : [],
                    deleted: row.deleted
                };
            }))
        };

        for(const i in add)
           data[i] = add[i];
        
        const response = await DataHandler.post({ url: `bills/${this.state.id}/save`}, {...data});

        if (response && response.id) {
            try {
                await DataHandler.post({ url: `bills/${response.id}/savePoSync`}, {po_id: add.po_syncing});
            } catch (e) {}
        } 

        if(!response || (response.hasOwnProperty("status") && response.status === "FAILED")) {
            this.props.onSave(false);
            return;
        }
            
        // if the selected supplier was created during this bill's creation, update the supplier
        // with new data that was entered
        if(this.addedSupplierIds.indexOf(parseInt(this.state.supplierId)) > -1) {
            await DataHandler.put({ url: `accounts/${this.state.supplierId}` }, { 
                vatid: this.state.supplierAddress.business_id,
                email: this.state.supplierAddress.email,
                telephone: this.state.supplierAddress.phone
            });
        }

        // if the selected address is in the set of added addresses, save the address' data
        if(this.addedSupplierAddressIds.indexOf(parseInt(this.state.supplierAddressId)) > -1) {
            await DataHandler.put({ url: `accounts/delivery_address/${this.state.supplierAddressId}` }, {
                name: name, 
                vatid: this.state.supplierBusinessId, 
                contact: "", 
                address: address, 
                postalcode: this.state.supplierAddress.zip, 
                city: this.state.supplierAddress.city, 
                state: this.state.supplierAddress.state,
                country: this.state.supplierAddress.country, 
                e_operator: "", 
                e_address: "", 
                email: this.state.supplierAddress.email
            });
        }

        if (this.state.id < 1) {
            this.context.functions.sendMixpanelEvent('Create bill');
            //this.context.mixpanel.people.increment('# of bills created');
        }

        this.props.onSave(response.id);

        this.setState({ id: response.id, deletedRows: [] }, () => {
            this.list.current.emptyNewData();

            if (accountingSave) {
                this.props.enqueueSnackbar(this.tr("Bill accounting saved!"), {
                    variant: "success"
                });
            }

            setTimeout(() => {
                this.fetchReceivedInvoice(this.state.id);
            }, 1000);
        });

        return true;
    }


    cancel() {
        // this.setReceivedInvoiceData(this.lastData); // This doesn't seem to work? Better to just fetch the whole bill from the backend again.
       
        this.fetchReceivedInvoice(this.state.id);

        this.setState({ bankAccounts: this.state.bankAccounts.filter(b => b.id > -1), deletedRows: [] });
    }


    transformData(array) {
        return array.map(element => {
            element.value = element.id;

            if(!element.hasOwnProperty("label"))
                element.label = element.name;

            return element;
        });
    }
    
    
    getSuppliers(newCompany = false) {
        const company = newCompany ? newCompany : this.state.company;
        return DataHandler.get({ url: `subjects/suppliers/${company}`, get_customers: 1});
    }


    async getSupplierAddresses(supplierId, supplierAddressId = undefined, callback = () => {}) {
        const { company } = this.state;
        const supplierAddresses = this.transformData(await DataHandler.get({ url: `accounts/${supplierId}/delivery_addresses/${company}` }));

        // If the passed supplierAddressId is not undefined, use that. If it is undefined, use the first address' id.
        this.setState({
            supplierAddresses: supplierAddresses.map(a => {return {...a, business_id: a.vatid}}),
            supplierAddressId: supplierAddressId !== undefined ? supplierAddressId : Array.isArray(supplierAddresses) && supplierAddresses.length > 0 ? supplierAddresses[0].id : undefined
        }, () => {
            callback();
        });
    }


    setSupplierAddress(address = {}) {
        const keys = Object.keys(this.state.supplierAddress);

        if(address.hasOwnProperty("postalcode"))
            address.zip = address.postalcode;

        for(const i in keys) {
            if(!address.hasOwnProperty(keys[i]))
                address[keys[i]] = "";
        }

        this.setState({ supplierAddress: address });
    }


    editSupplierAddress(name, value) {
        const curAddress = this.state.supplierAddress;
        curAddress[name] = value;

        let { nonValidFields } = this.state;

        if(nonValidFields.indexOf(name) > -1)
            nonValidFields = nonValidFields.filter(f => f !== name);

        this.setState({
            supplierAddress: curAddress,
            nonValidFields: nonValidFields
        });
    }


    setBillingInfo(info) {
        this.setState({ billingInfo: info });
    }


    editBillingInfo(name, value) {
        const curInfo = this.state.billingInfo;
        curInfo[name] = value;

        this.setState({
            billingInfo: curInfo 
        });
    }


    addBankAccount(e) {
        e.preventDefault();

        const bankAccounts = this.state.bankAccounts;

        bankAccounts.push({
            id: bankAccounts.filter(b => b.id < 0).length * -1 - 1,
            bank: "",
            swift: "",
            iban: ""
        });

        this.setState({ bankAccounts });
    }


    editBankAccount(name, value, id) {
        this.setState({ bankAccounts: this.state.bankAccounts.map(ba => {
            if(ba.id === id)
                ba[name] = value.trim();

            return ba;
        })});
    }


    updateTotals(data) {
        const {currencyRate} = this.state;
        let dataRows = [];
        if (!data.data)
            dataRows = data;
        else
            dataRows = [...data.data, ...data.newData.map(r => r.data)].filter(r => r !== undefined);

        if(!dataRows || Array.isArray(dataRows) && dataRows.length === 0) {
            this.setState({
                subtotal: 0,
                vatTotal: 0,
                total: 0
            });

            this.props.subtotalUpdated(0, 0, 0);

            return;
        }

        const noCurrencySums = !currencyRate || currencyRate == "1.000000";
        const totalNoVat = dataRows.map(row => noCurrencySums ? parseFloat(row.total_no_vat) : parseFloat(row.currency_total_no_vat)).reduce((acc, cur) => acc + cur);
        const total      = dataRows.map(row => noCurrencySums ? parseFloat(row.total) : parseFloat(row.currency_total)).reduce((acc, cur) => acc + cur);
        const vatTotal   = total - totalNoVat;
        const companyTotal = dataRows.map(row =>parseFloat(row.total)).reduce((acc, cur) => acc + cur);
        const companyTotalNoVat = dataRows.map(row =>parseFloat(row.total_no_vat)).reduce((acc, cur) => acc + cur);

        clearTimeout(this.updateTotalTimeout);

        this.updateTotalTimeout = setTimeout(() => {
            this.setState({
                subtotal: totalNoVat,
                vatTotal: vatTotal,
                total: total
            });

            this.props.subtotalUpdated(totalNoVat, companyTotal, companyTotalNoVat);
        }, 200);
    }

    updateTotalsAndRemove(dataRows, removedId) {
        const {currencyRate} = this.state;
        if (!dataRows || Array.isArray(dataRows) && dataRows.length === 0) {
            this.setState({
                subtotal: 0,
                vatTotal: 0,
                total: 0
            });

            this.props.subtotalUpdated(0, 0, 0);
            if (removedId)
                this.list.current.removeNewRow(removedId);
            return;
        }

        const noCurrencySums = !currencyRate || currencyRate == "1.000000";
        const totalNoVat = dataRows.map(row => noCurrencySums ? parseFloat(row.total_no_vat) : parseFloat(row.currency_total_no_vat)).reduce((acc, cur) => acc + cur);
        const total      = dataRows.map(row => noCurrencySums ? parseFloat(row.total) : parseFloat(row.currency_total)).reduce((acc, cur) => acc + cur);
        const vatTotal = total - totalNoVat;
        const companyTotal = noCurrencySums ? total : dataRows.map(row =>parseFloat(row.total)).reduce((acc, cur) => acc + cur);
        const companyTotalNoVat = noCurrencySums ? totalNoVat : dataRows.map(row =>parseFloat(row.total_no_vat)).reduce((acc, cur) => acc + cur);

        setTimeout(() => {
            this.setState({
                subtotal: totalNoVat,
                vatTotal: vatTotal,
                total: total
            });

            this.props.subtotalUpdated(totalNoVat, companyTotal, companyTotalNoVat);
        }, 500);

        if (removedId)
            this.list.current.removeNewRow(removedId);
    }

    updateRowToTotals(data) {
        let newRows = this.list.current.getVisibleRows().map(row => row.getData());
        newRows = newRows.filter(function(row) { 
            return row.id !== data.id; 
        }); 

        this.updateTotals(newRows.concat(data));
    }

    deleteRow(data) {
        data.deleted = 1;
        let dataRows = this.list.current.getVisibleRows().map(row => row.getData());
        dataRows = dataRows.filter(function (row) {
            return row.id !== data.id;
        });
        this.updateTotalsAndRemove(dataRows);
        this.setState({ deletedRows: this.state.deletedRows.concat(data), billRows: dataRows });
    }

    accountingRowEdited = (name, value, rowId) => {
        const rows = _.cloneDeep(this.state.accountingData);

        const rowIndex = rows.findIndex(e => e.id == rowId);
        rows[rowIndex][name] = value;

        this.setState({accountingData: rows});
    }

    updateAccountingData = (data) => {
        const currentRows = this.state.accountingData;

        const rows = [
			...data.data.map(row => {
                //row.purchase_account = currentRows.findIndex(r => r.id == row.id) > -1 ? currentRows.find(r => r.id == row.id).purchase_account : 0;
				return row;
			}), ...data.newData.map(row => {
                row.data.purchase_account = currentRows.findIndex(r => r.id == row.data.id) > -1 ? currentRows.find(r => r.id == row.data.id).purchase_account : (this.state.default_purchase_accounts_id || 0);
                row.data.dimension_item = currentRows.findIndex(r => r.id == row.data.id) > -1 ? currentRows.find(r => r.id == row.data.id).dimension_item : 0;
                row.data.purchase_vatcode = currentRows.findIndex(r => r.id == row.data.id) > -1 ? currentRows.find(r => r.id == row.data.id).purchase_vatcode : 0;
                row.data.dimension_values = currentRows.findIndex(r => r.id == row.data.id) > -1 ? currentRows.find(r => r.id == row.data.id).dimension_values : [];
                return row.data;
			})
        ];

        this.setState({accountingData: rows.map(row => {return {id: row.id, purchase_account: row.purchase_account, dimension_item: row.dimension_item, purchase_vatcode: row.purchase_vatcode, dimension_values: row.dimension_values, description: row.description, total: row.total}})});
    }

    createNewSupplier = async (supplier) => {
        const company = this.state.company;
        const exists = await this.checkExistingSupplier("sub_contractor", supplier.value);

        if (!exists) {
            const createdSupplier = await DataHandler.post({ url: `suppliers/-1/${company}` }, { name: supplier.value });
            this.supplierCreated(createdSupplier);
        }
    }

    supplierCreated = async (supplier) => {
        const suppliers = this.transformData(await this.getSuppliers());

        // Save the id, so if the bill is saved with this supplier, we can update the supplier with the given business id, etc.
        this.addedSupplierIds.push(supplier.id);
        this.props.suppliersFetched && this.props.suppliersFetched(suppliers);
        this.setState({ suppliers }, () => this.setState({ supplierId: supplier.id, supplierChanged: true }, () => this.getSupplierAddresses(this.state.supplierId)));
    }

    checkExistingSupplier = async (typeName, accountName) => {
        const company = this.state.company;
        if (!company)
            return false;

        const allAccounts = await DataHandler.get({ url: `subjects/accounts/${company}`, show_all: true});
        const oldAccount  = allAccounts.find(e => e.name === accountName);
        
        if (oldAccount) {
            const relationData = {
                typeName: typeName,
                customers_id: oldAccount.id,
                companies_id: company,
                allow_project_creation: 1,
                r_locked: -1
            }
            DataHandler.post({ url: `accounts/relations/new` }, { data: relationData }).done(e => {
                this.supplierCreated(oldAccount);
            })
            .catch(err => {
                this.props.enqueueSnackbar(this.tr("Error in creating supplier!"), {
                    variant: "error"
                });
            })

            return true;
        }

        return false;
    }

    setSupplierError = () => {
        this.setState({ nonValidFields: this.state.nonValidFields.concat("company") });
    }

    supplierChanged = (supplier) => {
        if (!supplier?.id) {
            return;
        }
        const nonValidFields = _.cloneDeep(this.state.nonValidFields);
        const companyErrorIndex = nonValidFields.findIndex(n => n == "company")
        if (companyErrorIndex > -1)
            nonValidFields.splice(companyErrorIndex, 1);

        this.setState({ nonValidFields }, () => this.changeSupplier(supplier));
    }

    changeSupplier = (supplier) => {
        if(supplier.__isNew__) {
            this.createNewSupplier(supplier);
        } else {
            this.setState({ 
                supplierId: supplier.id,
                supplierBusinessId: supplier.business_id,
                supplierChanged: true
            });
        }
    }

    openAccountingSlider = () => {
        this.setState({ showAccounting: true });
    }

    renderRowAccountingCell = (rowId, listCellProps) => {
        const { accountingData } = this.state;
        const rowData = accountingData.find(a => a.id == rowId) || {};

        return <AccountingCell 
        listCellProps={{
                ...listCellProps,
                textAlign: "right", 
                innerStyle: { textAlign: "right" },
                className: "accounting-cell"
            }}            
            type="purchase_invoices" 
            rowData={rowData} 
            accountingData={this.getAccountingOptionsData()} 
            showAccounting={this.openAccountingSlider} />
    }

    getAccountingOptionsData = () => {
        const { purchaseAccounts, purchaseVatCodes, purchase_dimensions, purchase_dimension_headers } = this.state;

        return { 
            purchaseAccounts, 
            purchaseVatCodes, 
            dimensions: purchase_dimensions, 
            dimension_headers: purchase_dimension_headers 
        };
    }

    onAccountingSave = () => {
        this.setState({ showAccounting: false }, () => !this.props.editMode && this.save({}, false, false, true))
    }

    onAccountingClose = (data) => {
        this.setState({ showAccounting: false, accountingData: data.originalRows });
    }

    onAccountingRowsCopied = (newRows, callback = () => {}) => {
        this.setState({accountingData: newRows}, () => callback && callback());
    }

    isCompanyUsingRowSpecificVatcodes = () => {
        const { addons } = this.context;
        return (addons.heeros && addons.heeros.used_by_companies.indexOf(this.state.company) > -1);
    }

    render() {
        const { tr } = this;
        const columnConfig = { showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false };
        const { functions: { checkPrivilege }, taimerAccount: { showState } } = this.context;
        const { company, companyCurrency, invoiceCurrency , currencyRate, supplierId, suppliers, usesAccounting, showAccounting, purchaseVatCodes} = this.state;
        const currency = invoiceCurrency ? invoiceCurrency : companyCurrency;
        
        const usMode = /* (item && item.state) ||  */showState;
        const supplierSelectable = this.state.id > 1 || !this.state.supplierAddress?.company;
        const supplierModifiable = this.state.id > -1 && (this.state.supplierAddress?.company || supplierId == 0);
        const addressModifiable = this.state.id > -1 && !this.state.supplierChanged;

        return (
            <React.Fragment>       
                <Paper {...this.props.paperProps} style={{ paddingBottom: "200px" }}>
                    <Header>
                        <LabelFieldGroup
                            flexWeight={3}
                            editMode={this.props.editMode}
                            title={tr("SUPPLIER")}
                            onTextFieldChange={this.editSupplierAddress}
                            errorsInFields={this.state.nonValidFields.filter(f => ["email", "company"].indexOf(f) > -1)}
                            required
                            fields={[
                                { label: this.tr("Account"), name: "company", 
                                    value: supplierModifiable ? this.state.supplierAddress.company : supplierId, 
                                    disabled: supplierSelectable && !supplierModifiable,
                                    editorType: supplierModifiable ? undefined : (checkPrivilege("customers", "write") ? CreatableSelect : Select), options: suppliers, onChange: async supplier => {
                                        this.supplierChanged(supplier);
                                }},
                                { label: this.tr("Address"), name: "address", 
                                    value: addressModifiable ? this.state.supplierAddress.address : this.state.supplierAddressId, 
                                    editorType: addressModifiable ? undefined : CreatableSelect, options: this.state.supplierAddresses, onChange: async address => {
                                        let addressId = address.id;

                                        if(address.__isNew__) {
                                            const response = await DataHandler.post({ url: `accounts/${this.state.supplierId}/address/${company}` }, { address: address.value, address_types: [3]});
                                            _.forEach(response.ids, (id, i) => {
                                                if(i == 3)
                                                    addressId = id;
                                            })
                                            // Save this, so when saving the bill itself, we know which address to edit with the new address data.
                                            this.addedSupplierAddressIds.push(addressId);

                                            this.getSupplierAddresses(this.state.supplierId, addressId);
                                        } else {
                                            this.setState({ supplierAddressId: addressId });
                                        }
                                }},
                                usMode && { label: this.tr("City"), name: "city", value: this.state.supplierAddress.city },
                                usMode && { label: this.tr("State"), name: "state", value: this.state.supplierAddress.state },
                                { label: this.tr("Zip"), name: "zip", value: this.state.supplierAddress.zip },
                                !usMode && { label: this.tr("City"), name: "city", value: this.state.supplierAddress.city },
                                { label: this.tr("Country"), name: "country", value: this.state.supplierAddress.country },
                                { label: this.tr("Business ID"), name: "business_id", value: this.state.supplierAddress.business_id },
                                { label: this.tr("Phone"), name: "phone", value: this.state.supplierAddress.phone },
                                { label: this.tr("Email"), name: "email", value: this.state.supplierAddress.email }
                            ]} />
                        <LabelFieldGroup
                            flexWeight={2}
                            editMode={this.props.editMode}
                            onTextFieldChange={this.editBillingInfo}
                            title={this.tr("BILLING INFO")}
                            fields={[
                                { label: this.tr("Bill nr."), name: "bill_number", value: this.state.billingInfo.bill_number, },
                                { label: this.tr("Bill date"), name: "bill_date", date: this.state.billingInfo.bill_date, 
                                    onInputChange: (name, date) => this.editBillingInfo("bill_date", date),
                                    onChange: date => this.editBillingInfo("bill_date", date), editorType: DatePicker, dateFormat: this.context.userObject.dateFormat },
                                { label: this.tr("Accounting date"), name: "voucher_date", date: this.state.billingInfo.voucher_date, 
                                    onInputChange: (name, date) => this.editBillingInfo("voucher_date", date),
                                    onChange: date => this.editBillingInfo("voucher_date", date), editorType: DatePicker, dateFormat: this.context.userObject.dateFormat },
                                { label: this.tr("Due date"), name: "due_date", date: this.state.billingInfo.due_date, 
                                    onInputChange: (name, date) => this.editBillingInfo("due_date", date),
                                    onChange: date => this.editBillingInfo("due_date", date), editorType: DatePicker, dateFormat: this.context.userObject.dateFormat },
                                { label: this.tr("Reference nr."), name: "reference", value: this.state.billingInfo.reference },
                                { label: this.tr("Buyer reference"), name: "buyer_reference", value: this.state.billingInfo.buyer_reference },
                                { label: this.tr("Penalty interest"), name: "penalty_interest", value: this.state.billingInfo.penalty_interest },
                                { label: this.tr("Additional info"), name: "additional_info", value: this.state.billingInfo.additional_info }
                            ]} />
                        <FlexChild weight={2}>
                            <h4>{this.tr('BANK ACCOUNT')}</h4> 
                            {this.state.bankAccounts.map((account, index) => {
                                return <LabelFieldGroup
                                    editMode={this.props.editMode}
                                    onTextFieldChange={(name, value) => {
                                        const id = account.id;

                                        this.editBankAccount(name, value, id);
                                    }}
                                    values={account}
                                    fields={[
                                        { label: this.tr("Bank name"), name: "bank" },
                                        { label: this.tr("BIC/SWIFT"), name: "swift" },
                                        { label: this.tr("IBAN"), name: "iban" } 
                                    ]} />;
                            })} 

                            {(!this.state.billingInfo.editing_disabled && this.props.editMode) && <Button className="bankAccountButton" onClick={this.addBankAccount} data-testid="add_bank_account_button">+{this.tr(' Add bank account')}</Button>} 
                        </FlexChild>
                    </Header>
                    <ContentArea>
                        {usesAccounting && ((this.list.current?.getVisibleRows() || []).length || 0) > 0 && (
                            <React.Fragment>
                            <div className="accounting-option">
                                <div className="show-accounting-button" data-testid="show_accounting_button" onClick={() => this.openAccountingSlider()}><BookkeepingIcon /><span>{this.tr('Show accounting')}</span></div>
                            </div>
                            </React.Fragment>
                        )}
                        <List
                            fluid={true}
                            id="receivedInvoiceList"
                            className="paperList"
                            ref={this.list}
                            reverseNewData={true}
                            renderNewDataAtEnd={true}
                            noColorVariance={true}
                            ignoreRowPropsChange={false}
                            height="auto"
                            data={this.state.billRows}
                            rowHeight={this.isCompanyUsingRowSpecificVatcodes() ? 50 : 40} // row height + ONE margin
                            listRowType={BillRow}
                            summaryRowType={BillSummaryRow}
                            onDataChange={data => {
                                this.updateTotals(data);
                                this.updateAccountingData(data);
                            }}
                            rowProps={{
                                renderRowAccountingCell: this.renderRowAccountingCell,
                                useRowSpecificCatcodes: this.isCompanyUsingRowSpecificVatcodes(),
                                company: this.state.company,
                                editMode: this.state.billingInfo.editing_disabled ? false : this.props.editMode,
                                currencyRate: currencyRate,
                                purchaseVatCodes: purchaseVatCodes,
                                onChange: (data) => {
                                        this.updateRowToTotals(data);
                                },
                                onDelete: (data) => {
                                    if (data.id < 0) {
                                        let dataRows = this.list.current.getVisibleRows().map(row => row.getData());
                                        dataRows = dataRows.filter(function (row) {
                                            return row.id !== data.id;
                                        });

                                        this.updateTotalsAndRemove(dataRows, data.id);
                                    }
                                    else
                                        this.deleteRow(data);
                                }
                            }}
                            summaryRowProps={{
                                usesAccounting,
                                currency: currency,
                                currencyRate: currencyRate,
                                editMode: this.state.billingInfo.editing_disabled ? false : this.props.editMode,
                                rows: this.list.current ? this.list.current.getVisibleRows().map(row => row.getData()) : [],
                                addItem: () => {
                                    this.list.current.addNewRow();
                                }

                            }}
                            newRow={{
                                description: this.tr("Description"),
                                quantity: 0,
                                unit_price: 0,
                                total_no_vat: 0,
                                vat: 0,
                                total: 0,
                                currency_unit_price: 0,
                                currency_total: 0,
                                currency_total_no_vat: 0
                            }}
                            columns={[
                                { name: "context", header: "", width: 30, ...columnConfig },
                                { name: "description", header: this.tr("Description"), width: 170, ...columnConfig },
                                { name: "quantity", header: this.tr("Qty"), width: 40, ...columnConfig, alignRight: true },
                                { name: "unit_price", header: this.tr("Unit Price"), width: 50, ...columnConfig, alignRight: true },
                                { name: "total_no_vat", header: this.tr("Tot. 0%"), width: 50, ...columnConfig, alignRight: true },
                                { name: "vat", header: this.tr("Vat %"), width: this.isCompanyUsingRowSpecificVatcodes() ? 50 : 40, ...columnConfig, alignRight: true },
                                { name: "total", header: this.tr("Total"), width: 50, ...columnConfig, alignRight: true },
                                usesAccounting && { name: "accounting", header: "", width: 30, ...columnConfig, alignRight: true },
                            ].filter(c => c)}
                            {...this.props.listProps} /> 
                    </ContentArea>
                    <Footer>
                        <FlexChild weight={5} style={{ paddingRight: "16px" }}></FlexChild>
                        <FlexChild weight={3}>
                            <Totals
                                currency={currency}
                                rows={[
                                    { grey: true, name: this.tr("SUBTOTAL"), sum: Utils.truncateDecimals(this.state.subtotal), "data-testid": "bill_subtotal" },
                                    { grey: true, name: this.tr("VAT TOTAL"), sum: Utils.truncateDecimals(this.state.vatTotal), "data-testid": "bill_vattotal" },
                                    { black: true, name: this.tr("TOTAL"), sum: Utils.truncateDecimals(this.state.total), "data-testid": "bill_total" }
                                ]} />
                        </FlexChild>
                    </Footer>
                </Paper>
                {showAccounting && <AccountingSlider
                    type="purchase_invoices"
                    invoiceRows={_.cloneDeep(this.state.accountingData)}
                    dataRows={this.list.current ? this.list.current.getVisibleRows().map(row => row.getData()) : []}
                    onSave={this.onAccountingSave}
                    accountingData={this.getAccountingOptionsData()}                   
                    company={company}
                    open={true}
                    onClose={(data) => this.onAccountingClose(data)}
                    currency={currency}
                    editRow={this.accountingRowEdited}
                    onRowsCopied={(newRows, callback) => this.onAccountingRowsCopied(newRows, callback) }
                />}
            </React.Fragment>
        );
    }
}

export default withSnackbar(ReceivedInvoiceView);

