
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import { exportContext } from '../Taimer';
import DataHandler from "./DataHandler";
import InvoiceTranslations from './backendTranslations/InvoiceTranslations';

const InvoiceApi = {
    finvoice: (idObject) => {
        const nWin = window.open(`index.html?module=invoices&action=print_invoice&select=${idObject.ids}&lang=finvoice`,'_blank');
        nWin.onload = function() {
            nWin.document.title = `Invoice ${idObject.bill_ids}`;
        }
    },
    finvoice2: (idObject) => {
        DataHandler.get({url: `invoices/print_finvoice`, ids: idObject.ids}).done((response) => {
            const blob = new Blob([response.documentElement.outerHTML], {
                type: 'application/xml'
            });

            FileSaver.saveAs(blob, 'finvoice.xml');
        });
    },
    finvoice3: (idObject) => {
        DataHandler.getArrayBuffer({url: `invoices/print_finvoice3`, ids: idObject.ids}).done((response) => {
            const blob = new Blob([response], {
                type: 'application/xml;charset=ISO-8859-15'
            });

            FileSaver.saveAs(blob, 'finvoice3.xml');
        });
    },
    print_tietotili: (idObject) => {
        DataHandler.getArrayBuffer({url: `invoices/print_tietotili`, ids: idObject.ids}).done((response) => {
            const blob = new Blob([response], {
                type: 'application/xml;charset=ISO-8859-15'
            });

            FileSaver.saveAs(blob, 'tietotili_finvoice3.xml');
        });
    },
    send_tietotili: async (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater = null, company, tr, setSendingStatus) => {
        if(idObject.ids == "")
            return;

        const langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: idObject.ids});
		const transl = new InvoiceTranslations().returnTranslations(langs);

        setSendingStatus(true);

        // eslint-disable-next-line no-loop-func
        DataHandler.put({url: `invoices/send_tietotili`}, {ids: idObject.ids, company: company, translations: transl}).done(resp => {
            setSendingStatus(false);

            if (resp?.status == "ok") {
                enqueueSnackbar(tr('Invoices successfully sent to TietotiliOnline'), {
                    variant: "success",
                });
            } else {
                enqueueSnackbar(tr('Sending failed to TietotiliOnline'), {
                    variant: "error",
                });
            }
        }).fail(() => {
            setSendingStatus(false);
            
            enqueueSnackbar(tr("Sending failed to TietotiliOnline"), {
                variant: "error",
            });
        });
    },
    gothia: (idObject) => {
        DataHandler.getArrayBuffer({url: `invoices/print_gothia`, ids: idObject.ids}).done((response) => {
            const blob = new Blob([response], {
                type: 'application/xml;charset=ISO-8859-15'
            });

            FileSaver.saveAs(blob, 'gothia.xml');
        });
    },
    maventa: (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater = null, company, tr, setSendingStatus) => {
        if(idObject.ids == "")
            return;
        
        const chunks = _.chunk(idObject.ids, 3);
        const idCount = idObject.ids.length;
        let sent = 0;

        setSendingStatus(true);

        for (const chunk of chunks) {
            DataHandler.put({url: `invoices/maventa`}, {ids: chunk}).done(resp => {
                if (!resp)
                    return;
                
                resp.sent.length && resp.sent.forEach(e => enqueueSnackbar(tr('invoice nr. ${bill_id} sent to Maventa', { bill_id: e.bill_id }), { variant: "success" }));
                resp.failed.length && resp.failed.forEach(e => enqueueSnackbar(tr('invoice nr. ${bill_id} failed to send to Maventa', { bill_id: e.bill_id }), { variant: "error" }));
            
                sent += chunk.length;

                if (sent == idCount) {
                    setSendingStatus(false);

                    enqueueSnackbar(tr('${idCount1} / ${idCount2} invoices exported.', { idCount1: idCount, idCount2: idCount }), {
                        variant: "success",
                    });

                    updateData();
                } else {
                    enqueueSnackbar(tr('${sent} / ${idCount} invoices exported successfully. Exporting now more invoices...', { sent, idCount }), {
                        variant: "success",
                    });
                }
            }).fail(() => {
                setSendingStatus(false);
                
                enqueueSnackbar(tr("Failed to export invoices"), {
                    variant: "error",
                });
            });
        }
    },
    efina: (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater, company, tr, analyticsKey) => {
        if(idObject.ids == "")
            return;
        
        const context = exportContext(); 
        const time = moment().format('DD.MM.YYYY HH:mm:ss');
        const analyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": true,
        }
        const failedAnalyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }
        if(idObject && idObject.ids.length > 0) stateUpdater({loading: true});

        DataHandler.put({url: `invoices/efina`}, {ids: idObject.ids}).done(resp => {

            stateUpdater({loading: false});

            if (!resp)
                return;

            if(resp.sent.length) {
                resp.sent.forEach((e) => {
                    analyticsData.invoices_sent++;
                    enqueueSnackbar(tr('invoice nr. ${e} sent to Efina', { e: e.bill_id }), { variant: "success" });
                });
                //context.functions.sendAnalytics("invoice_sent", analyticsData);  
            } 


            if (resp.failed.length) {
                resp.failed.forEach((e) => {
                    failedAnalyticsData.invoices_sent++;
                    enqueueSnackbar(tr('invoice nr. ${e} failed to send to Efina', { e: e.bill_id }), { variant: "error" });
                    setTimeout(() => {
                        //context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
                    }, 600);
                });
            }
            resp.authfailed && enqueueSnackbar(tr("Connection to eFina has failed"), { variant: "error" });
            
            updateData();

        }).fail( (e) => {
            enqueueSnackbar(tr("Failed to export invoices"), {  variant: "error" });
            //context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
        });
    },
    talenom: async (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater, company, tr, analyticsKey) => {
        const context = exportContext();
        const time = moment().format('DD.MM.YYYY HH:mm:ss');
        const analyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": true,
        }

        const failedAnalyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }

        if(idObject && idObject.ids.length > 0) stateUpdater({loading: true});

        const talenomResponses = {
            "success": [
                tr("invoice nr"),
                tr("sent to Talenom")
            ],
            "error": {
                "default": tr("error in invoice nr")
            }
        };

        const idCount = idObject.ids.length;
        const chunks = _.chunk(idObject.ids, 3);
        let sent = 0;

        for (const chunk of chunks) {
            let resp = false;
            try {
                resp = await DataHandler.put({ url: `invoices/talenom` }, { ids: chunk });
            }
            catch (err) {}

            if (!resp) {
                stateUpdater({ loading: false });
                enqueueSnackbar(tr("Error in exporting invoices"), {
                    variant: "error",
                });
                return;
            }

            let error = false;
            let chunkSuccessAmount = 0;

            resp.forEach((response) => {

                if (response.success) {
                    sent++;
                    chunkSuccessAmount++;
                    analyticsData.invoices_sent++;
                } else {
                    let errorMsg = response.translate_message == 1 ? tr(response.msg) : response.msg;
                    if (response.original_message) {
                        errorMsg += " (" + response.original_message + ")";
                    }
                    enqueueSnackbar(`${talenomResponses.error['default']} ${response.bill_id}: ${errorMsg}`, { variant: "error", autoHideDuration: 10000 })
                    failedAnalyticsData.invoices_sent++;
                    error = true;
                }
            })

            if (error) {
                chunkSuccessAmount > 0 && enqueueSnackbar(tr('${sent} / ${idCount} invoices exported successfully.', { sent, idCount }), {
                    variant: "success",
                });
                break;
            }
            else if (sent == idCount) {
                enqueueSnackbar(tr("Invoices exported successfully"), {
                    variant: "success",
                });
            } else {
                enqueueSnackbar(tr('${sent} / ${idCount} invoices exported successfully. Exporting now more invoices...', { sent, idCount }), {
                    variant: "success",
                });
            }
        }

        if (analyticsData.invoices_sent > 0) {
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
        }
        if (failedAnalyticsData.invoices_sent > 0) {
            setTimeout(() => {
                //context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
            }, 600);
        }

        stateUpdater({ loading: false });
        updateData();
    },
    quickbooks: (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        setSendingStatus(true);

        DataHandler.post({url: `quickbooks/export/invoices`}, {ids :idObject.ids}).done(response =>{
            setSendingStatus(false);

            if(response.success > 0 && response.error == 0){
                analyticsData.success = true;
                //context.functions.sendAnalytics("invoice_sent", analyticsData);
                
                enqueueSnackbar(tr("Invoices exported successfully"), {
                    variant: "success",
                });
            } else if (response.success == 0 && response.error > 0) {
                //context.functions.sendAnalytics("invoice_sent", analyticsData);    
                enqueueSnackbar(tr("Failed to export invoices"), {
                    variant: "error",
                });
            } else if (response.success > 0 && response.error > 0) {
                
                analyticsData.invoices_sent = response.success;
                analyticsData.success = true;
                //context.functions.sendAnalytics("invoice_sent", analyticsData);
                
                setTimeout(() => {
                    analyticsData.invoices_sent = response.error;
                    analyticsData.success = false;
                    //context.functions.sendAnalytics("invoice_sent", analyticsData);
                }, 600);
                enqueueSnackbar(tr("Failed to export some invoices"), {
                    variant: "error",
                });
            }
            updateData();
        }).fail(() => {
            setSendingStatus(false);    
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar(tr("Failed to export invoices"), {
                variant: "error",
            });
        });
    },
    procountor: async (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const sortIds = (a, b) => {
            return Number(a) - Number(b);
        }

        const ids = idObject.ids?.sort(sortIds) || [];
        const idCount = ids.length;
        const context = exportContext();
        const time = moment().format('DD.MM.YYYY HH:mm:ss'); 
        const analyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": true,
        }
        const failedAnalyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }
        const chunks = _.chunk(ids, 3);

        let sent = 0;

        setSendingStatus(true);

        const langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: ids});
		const transl = new InvoiceTranslations().returnTranslations(langs);

        for (const chunk of chunks) {
            const response = await DataHandler.put({url: `invoices/procountor`}, {company: company, ids: chunk, translations: transl});
            if(response.invalid.length == 0){
                sent += chunk.length;
                analyticsData.invoices_sent += chunk.length;

                if (sent == idCount) {
                    setSendingStatus(false);

                    enqueueSnackbar(tr("Invoices exported successfully"), {
                        variant: "success",
                    });

                    updateData();
                } else {
                    enqueueSnackbar(tr('${sent} / ${idCount} invoices exported successfully. Exporting now more invoices...', { sent, idCount }), {
                        variant: "success",
                    });
                }  
            } else if (response.invalid.length > 0) {
                const errors = response.invalid.map(el => {
                    failedAnalyticsData.invoices_sent++;
                    return el.bill_id + ": " + el.errors.map(c => c.translate_message == 1 ? tr(c.message) : c.message).join(", ") + "\n";
                });
                const errorMsg = errors.join("\n");

                setSendingStatus(false);

                enqueueSnackbar(tr("Failed to export some invoices") + " - \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });

                updateData();

                break;
            } else {
                setSendingStatus(false);
                failedAnalyticsData.invoices_sent += chunk.length;
                enqueueSnackbar(tr("Failed to export invoices"), {
                    variant: "error",
                });
                
                updateData();

                break;
            }
        }
        if (analyticsData.invoices_sent > 0) {
            //context.functions.sendAnalytics("invoice_sent", analyticsData);          
        }
        if (failedAnalyticsData.invoices_sent > 0) {
            setTimeout(() => {
                //context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
            }, 600);
        }
        updateData();
    },
    wintime: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext();
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        setSendingStatus(true);
    
        DataHandler.put({url: `invoices/wintime`}, {company: company, ids: idObject.ids}).done(response => {
            setSendingStatus(false);
            if(response.status && response.status == "success"){
                analyticsData.success = true;
                //context.functions.sendAnalytics("invoice_sent", analyticsData);
                enqueueSnackbar(tr("Invoices exported successfully"), {
                    variant: "success",
                });                
            } else {
                //context.functions.sendAnalytics("invoice_sent", analyticsData);
                enqueueSnackbar(tr("Failed to export invoices"), {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();      
        }).fail(() => {
            setSendingStatus(false);
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar(tr("Failed to export invoices"), {
                variant: "error",
            });
        });
    },
    wintime_demo: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;

        DataHandler.put({url: `invoices/wintime_demo`}, {company: company, ids: ids}).done(response =>{
            const blob = new Blob([response], {
                type: 'text/plain'
            });
            FileSaver.saveAs(blob, 'wintime_demo.txt');
        }).fail(() => {
            enqueueSnackbar(tr("Failed to generate file"), {
                variant: "error",
            });
        });
    },
    netvisor: async (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;
        const context = exportContext();
        const time = moment().format('DD.MM.YYYY HH:mm:ss')
        const ids = idObject.ids;
        const idCount = ids.length;
        const chunks = _.chunk(ids, 3);
        let sent = 0;

        setSendingStatus(true);
        const analyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": true,
        }
        const failedAnalyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }
        for (const chunk of chunks) {
            const response = await DataHandler.put({url: `invoices/netvisor`}, {company: company, ids: chunk});
            if(response.success == 1) {
                sent += chunk.length;
                analyticsData.invoices_sent++;

                if (sent == idCount) {
                    setSendingStatus(false);

                    enqueueSnackbar(tr("Invoices exported successfully"), {
                        variant: "success",
                    });

                    updateData();
                } else {
                    enqueueSnackbar(tr('${sent} / ${idCount} invoices exported successfully. Exporting now more invoices...', { sent, idCount }), {
                        variant: "success",
                    });
                }  
            } else {
                setSendingStatus(false);

                if (response.invalid.length > 0) {
                    const billErrors = response.invalid.map(el => {
                        failedAnalyticsData.invoices_sent++;
                        const errorMsg = el.translate_message == 1 ? tr(el.response) : el.response;
                        return tr("Error in invoice ${invoice_nr}", {invoice_nr: el.bill_id}) + ": " + errorMsg;
                    });

                    enqueueSnackbar(billErrors, {
                        variant: "error",
                        autoHideDuration: 10000
                    });
                }

                updateData();

                break;
            }
            if (analyticsData.invoices_sent > 0) {    
                //context.functions.sendAnalytics("invoice_sent", analyticsData);
            }
            if (failedAnalyticsData.invoices_sent > 0) {
                setTimeout(() => {
                    //context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
                }, 600);
            }

        }
    },
    ropocapital: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        setSendingStatus(true);
            
        DataHandler.put({url: `invoices/ropocapital`}, {company: company, ids: ids}).done(response =>{
            setSendingStatus(false);

            if(response.invalid.length == 0){
                analyticsData.success = true;
                //context.functions.sendAnalytics("invoice_sent", analyticsData);
                
                enqueueSnackbar(tr("Invoices exported successfully"), {
                    variant: "success",
                });                
            } else if (response.invalid.length > 0) {
                analyticsData.invoices_sent = response.invalid.length;
                const errors = response.invalid.map(el => {
                    return el.error + "\n";
                });
                const errorMsg = errors.join("\n");
                if (idObject.ids.length > response.invalid.length) {
                    analyticsData.invoices_sent = idObject.ids.length - response.invalid.length;
                    analyticsData.success = true;
                    //context.functions.sendAnalytics("invoice_sent", analyticsData);
                }
                setTimeout(() => {
                    analyticsData.invoices_sent = response.invalid.length;
                    analyticsData.success = false;
                    //context.functions.sendAnalytics("invoice_sent", analyticsData);
                }, 600);
                enqueueSnackbar(tr("Failed to export some invoices") + ": \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();      
        }).fail(() => {
            setSendingStatus(false);    
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar(tr("Failed to export invoices"), {
                variant: "error",
            });
        });
    },
    emce: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        DataHandler.put({url: `invoices/emce`}, {company: company, ids: ids}).done(response =>{
            analyticsData.success = true;
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            const blob = new Blob([response], {
                type: 'text/plain'
            });
            FileSaver.saveAs(blob, 'emce.txt');
        }).fail(() => {
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar(tr("Failed to generate file"), {
                variant: "error",
            });
        });
    },
    emce_invoice: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;

        DataHandler.getArrayBuffer({url: `invoices/emce_invoice`, company: company, ids: ids}).done(response =>{
            const blob = new Blob([response], {
                type: 'application/xml;charset=ISO-8859-15'
            });
            FileSaver.saveAs(blob, 'rsklasku.xml');
        }).fail(() => {
            enqueueSnackbar(tr("Failed to generate file"), {
                variant: "error",
            });
        });

        DataHandler.getArrayBuffer({url: `invoices/emce_address`, company: company, ids: ids}).done(response =>{
            const blob = new Blob([response], {
                type: 'application/xml;charset=ISO-8859-15'
            });
            FileSaver.saveAs(blob, 'osoite.xml');
        }).fail(() => {
            enqueueSnackbar(tr("Failed to generate file"), {
                variant: "error",
            });
        });
    },
    fennoa: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        setSendingStatus(true);
            
        DataHandler.put({url: `invoices/fennoa`}, {company: company, ids: ids}).done(response =>{
            setSendingStatus(false);

            if(response.invalid.length == 0){
                analyticsData.success = true;
                //context.functions.sendAnalytics("invoice_sent", analyticsData);
                
                enqueueSnackbar(tr("Invoices exported successfully"), {
                    variant: "success",
                });                
            } else if (response.invalid.length > 0) {
                if (response.success?.length > 0) {
                    enqueueSnackbar(tr("${amount} invoices exported successfully", {amount: response.success?.length}), {
                        variant: "success",
                        autoHideDuration: 10000
                    });
                }
                const errors = response.invalid.map(el => {
                    const errorMsg = el.translate_message ? tr(el.error) : el.error;
                    return el.bill_id + ": " + errorMsg + "\n";
                });
                const errorMsg = errors.join("\n");
                
                if (idObject.ids.length > response.invalid.length) {
                    analyticsData.invoices_sent = idObject.ids.length - response.invalid.length;
                    analyticsData.success = true;
                    //context.functions.sendAnalytics("invoice_sent", analyticsData);
                }
                setTimeout(() => {
                    analyticsData.invoices_sent = response.invalid.length;
                    analyticsData.success = false;
                    //context.functions.sendAnalytics("invoice_sent", analyticsData);
                }, 600);
                enqueueSnackbar(tr("Failed to export some invoices") + ": \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();
        }).fail(() => {
            setSendingStatus(false);
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            
            enqueueSnackbar(tr("Failed to export invoices"), {
                variant: "error",
            });

            updateData();
        });
    },
    meritaktiva: async (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        setSendingStatus(true);

        const langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: ids});
		const transl = new InvoiceTranslations().returnTranslations(langs);
            
        DataHandler.put({url: `invoices/meritaktiva`}, {company: company, ids: ids, translations: transl}).done(response =>{
            setSendingStatus(false);

            if(response.invalid.length == 0){
                analyticsData.success = true;
                //context.functions.sendAnalytics("invoice_sent", analyticsData);
                
                enqueueSnackbar(tr("Invoices exported successfully"), {
                    variant: "success",
                });                
            } else if (response.invalid.length > 0) {
                const errors = response.invalid.map(el => {
                    const error = el.translate_message == 1 ? tr(el.error) : el.error;
                    return el.bill_id + ": " + error + "\n";
                });
                const errorMsg = errors.join("\n");
                if (idObject.ids.length > response.invalid.length) {
                    analyticsData.invoices_sent = idObject.ids.length - response.invalid.length;
                    analyticsData.success = true;
                    //context.functions.sendAnalytics("invoice_sent", analyticsData);
                }
                setTimeout(() => {
                    analyticsData.invoices_sent = response.invalid.length;
                    analyticsData.success = false;
                    //context.functions.sendAnalytics("invoice_sent", analyticsData);
                }, 600);
                enqueueSnackbar(tr("Failed to export some invoices") + ": \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();
        }).fail(() => {
            setSendingStatus(false);
            //context.functions.sendAnalytics("invoice_sent", analyticsData);      
            enqueueSnackbar(tr("Failed to export invoices"), {
                variant: "error",
            });

            updateData();
        });
    },
    xero: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        setSendingStatus(true);
            
        DataHandler.put({url: `invoices/xero`}, {company: company, ids: ids}).done(response =>{
            setSendingStatus(false);

            if(response.invalid.length == 0){
                enqueueSnackbar(tr("Invoices exported successfully"), {
                    variant: "success",
                });                
            } else if (response.invalid.length > 0) {
                const errors = response.invalid.map(el => {
                    return el.bill_id + ": " + el.error + "\n";
                });
                const errorMsg = errors.join("\n");
                if (idObject.ids.length > response.invalid.length) {          
                    analyticsData.invoices_sent = idObject.ids.length - response.invalid.length;
                    analyticsData.success = true;
                    //context.functions.sendAnalytics("invoice_sent", analyticsData);
                }
                setTimeout(() => {
                    analyticsData.invoices_sent = response.invalid.length;
                    analyticsData.success = false;
                    //context.functions.sendAnalytics("invoice_sent", analyticsData);
                }, 600);
                enqueueSnackbar(tr("Failed to export some invoices") + ": \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();
        }).fail(() => {
            setSendingStatus(false);
            //context.functions.sendAnalytics("invoice_sent", analyticsData);

            enqueueSnackbar(tr("Failed to export invoices"), {
                variant: "error",
            });

            updateData();
        });
    },
    fivaldi: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;
         
        const context = exportContext();    
        const analyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        DataHandler.put({url: `invoices/fivaldi`}, {company: company, ids: idObject.ids}).done(response =>{
            if(response.success == 1){
                analyticsData.invoices_sent = idObject.ids.length;
                analyticsData.success = true;
                //context.functions.sendAnalytics("invoice_sent", analyticsData);
                enqueueSnackbar(tr("Invoices exported successfully"), {
                    variant: "success",
                });                
            } else {
                /*let errors = response.invalid.map(el => {
                    return el.bill_id + ": " + el.error.constraintViolations.map(c => c.errorCode).join(", ") + "\n";
                });*/
                const errorMsg = tr("Failed to send invoices");
                analyticsData.invoices_sent = idObject.ids.length;
                //context.functions.sendAnalytics("invoice_sent", analyticsData);
                enqueueSnackbar(errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }      
            updateData();      
        }).fail(() => {
            analyticsData.invoices_sent = idObject.ids.length;
            analyticsData.success = false;
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar(tr("Failed to export invoices"), {
                variant: "error",
            });
        });
    },
    fivaldi_file: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        DataHandler.getArrayBuffer({url: `invoices/fivaldi_file`, company: company, ids: ids}).done(response =>{
            const blob = new Blob([response], {
                type: 'application/xml;charset=ISO-8859-15'
            });
            FileSaver.saveAs(blob, 'fivaldi.xml');

            analyticsData.success = true;
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            updateData();
        }).fail(() => {
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar(tr("Failed to generate file"), {
                variant: "error",
            });
        });
    },
    nav: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;
        
        const context = exportContext();
        const ids = idObject.ids;

        setSendingStatus(true);
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }
        DataHandler.put({url: `invoices/nav`}, {company: company, ids: idObject.ids}).done(response => {
            setSendingStatus(false);

            /*if(response.status && response.status == "success"){
                enqueueSnackbar("Invoices exported successfully", {
                    variant: "success",
                });                
            } else {
                enqueueSnackbar("Failed to export invoices", {
                    variant: "error",
                    autoHideDuration: 10000
                });
            }*/

            /*var blob = new Blob([response], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            FileSaver.saveAs(blob, 'nav.xlsx');*/

            const el = document.createElement("a");
            el.setAttribute("href", " data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
            el.setAttribute("download", 'nav.xlsx');
            el.style.display = "none";
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            analyticsData.success = true;
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            updateData();      
        }).fail(() => {
            setSendingStatus(false);
            //context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar(tr("Failed to export invoices"), {
                variant: "error",
            });
        });
    },
    navDebug: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;
        
        const context = exportContext();
        const ids = idObject.ids;

        DataHandler.put({url: `invoices/nav_debug`}, {company: company, ids: idObject.ids}).done(response => {
            setSendingStatus(false);

            const el = document.createElement("a");
            el.setAttribute("href", " data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
            el.setAttribute("download", 'nav_debug.xlsx');
            el.style.display = "none";
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
          
            updateData();      
        }).fail(() => {
            setSendingStatus(false);
            enqueueSnackbar(tr("Failed to get debug data"), {
                variant: "error",
            });
        });
    },
    navcsv: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        DataHandler.getArrayBuffer({url: `invoices/navcsv`, company: company, ids: ids}).done(response =>{
            const blob = new Blob([response], {
                type: 'text/plain'
            });
            FileSaver.saveAs(blob, 'invoices.csv');

            analyticsData.success = true;
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            updateData();
        }).fail(() => {
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar(tr("Failed to generate file"), {
                variant: "error",
            });
        });
    },
    fortnox: async (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const idCount = ids.length;
        const context = exportContext(); 
        const time = moment().format('DD.MM.YYYY HH:mm:ss');
        const analyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": true,
        }
        const failedAnalyticsData = {
            "invoices_sent": 0,
            "taimer_version": context.versionId,
            "event_date_time": time,
            "invoice_destination": analyticsKey,
            "success": false,
        }

        const chunks = _.chunk(ids, 3);
        let sent = 0;

        setSendingStatus(true);

        const langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: ids});
		const transl = new InvoiceTranslations().returnTranslations(langs);

        for (const chunk of chunks) {
            const response = await DataHandler.put({url: `invoices/fortnox`}, {company: company, ids: chunk, translations: transl});
            
            if(response.invalid.length == 0){
                sent += chunk.length;
                analyticsData.invoices_sent += chunk.length;

                if (sent == idCount) {
                    setSendingStatus(false);
                    enqueueSnackbar(tr("Invoices exported successfully"), {
                        variant: "success",
                    });
                    updateData();
                } else {
                    enqueueSnackbar(tr('${sent} / ${idCount} invoices exported successfully. Exporting now more invoices...', { sent, idCount }), {
                        variant: "success",
                    });
                }              
            } else if (response.invalid.length > 0) {
                setSendingStatus(false);
                if (response.success?.length > 0) {
                    sent += response.success?.length;
                    analyticsData.invoices_sent += response.success?.length;

                    enqueueSnackbar(tr('${sent} / ${idCount} invoices exported successfully.', { sent, idCount }), {
                        variant: "success",
                    });
                }

                const errors = response.invalid.map(el => {
                    return el.bill_id + ": " + el.message + "\n";
                });
                const errorMsg = errors.join("\n");
                failedAnalyticsData.invoices_sent += response.invalid.length;

                enqueueSnackbar(tr("Failed to export some invoices") + ": \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });
                updateData();
                break;
            } else {
                setSendingStatus(false);
                failedAnalyticsData.invoices_sent += chunk.length;

                enqueueSnackbar(tr("Failed to export invoices"), {
                    variant: "error",
                });
                updateData();
                break;
            }
        }

        if (analyticsData.invoices_sent > 0) {
            //context.functions.sendAnalytics("invoice_sent", analyticsData);          
        }
        if (failedAnalyticsData.invoices_sent > 0) {
            setTimeout(() => {
                //context.functions.sendAnalytics("invoice_sent", failedAnalyticsData);
            }, 600);
        }
    },
    s3: (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater = null, company, tr, setSendingStatus) => {
        if (idObject.ids == "")
            return;

        setSendingStatus(true);

        DataHandler.put({ url: `invoices/s3` }, { ids: idObject.ids, companies_id: company, target: idObject.target }).done(resp => {
            if (!resp)
                return;

            setSendingStatus(false);

            if (resp.already_sent_bills?.length > 0) {
                const errorText = tr('${amount} invoices could not be sent', { amount: resp.already_sent_bills.length }) + ": " + tr('Invoices have already been sent');
                enqueueSnackbar(errorText, {
                    variant: "error",
                });
            }
            if (resp.sent_bills?.length > 0) {
                enqueueSnackbar(tr('${amount} invoices sent.', { amount: resp.sent_bills.length }), {
                    variant: "success",
                });
                updateData();
            }
        }).fail(() => {
            setSendingStatus(false);

            enqueueSnackbar(tr("Failed to export invoices"), {
                variant: "error",
            });
        });
    },
    heeros: async (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey, htmlTr) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const idCount = ids.length;

        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        let sent = 0;
        let infoSnackbarKey = false;
        let sendingStatusHidden = false;

        setSendingStatus(true);

        for (const id of ids) {
            const response = await DataHandler.put({url: `invoices/heeros`}, {company: company, ids: [id]});
            
            if(response.invalid.length == 0){
                sent++;

                if (!infoSnackbarKey) {
                    infoSnackbarKey = enqueueSnackbar(htmlTr("Invoices are marked as sending until they receive a response from Heeros Sales Invoices. ${linebreak} You can refresh the page to see the updated status after a while.", {linebreak: [<br />, <br />]}), {
                        variant: "info",
                        persist: true
                    });
                }

                if (sent == idCount) {
                    !sendingStatusHidden && setSendingStatus(false);
                    sendingStatusHidden = true;

                    enqueueSnackbar(htmlTr('${sent} invoices started sending successfully!', { sent }), {
                        variant: "success",
                    });

                    updateData();
                } else {
                    !sendingStatusHidden && setSendingStatus(false);
                    sendingStatusHidden = true;

                    enqueueSnackbar(tr('${sent} / ${idCount} invoices started sending successfully. Sending next invoice...', { sent, idCount }), {
                        variant: "success",
                    });
                }            
            } else if (response.invalid.length > 0) {
                const errors = response.invalid.map(el => {
                    const message = el.translate_message == 1 ? tr(el.message) : el.message;
                    return el.bill_id + ": " + message + "\n";
                });
                const errorMsg = errors.join("\n");
                !sendingStatusHidden && setSendingStatus(false);
                sendingStatusHidden = true;
    
                enqueueSnackbar(tr("Failed to export invoice") + ": \n" + errorMsg, {
                    variant: "error",
                    autoHideDuration: 10000
                });

                updateData();

                break;
            } else {
                setSendingStatus(false);
            
                enqueueSnackbar(tr("Failed to send invoices"), {
                    variant: "error",
                });

                updateData();

                break;
            }
        }
    },

    datev_file: async (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater = null, company) => {
        const langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: idObject.ids});
		const transl = new InvoiceTranslations().returnTranslations(langs);

        DataHandler.getArrayBuffer({url: `invoices/print_datev`, ids: idObject.ids, company: company, translations: transl}).done((response) => {
            const blob = new Blob([response], {
                type: 'application/zip'
            });

            const now = new Date();
            const y = now.getFullYear();
            const m = String(now.getMonth() + 1).padStart(2, '0');
            const d = String(now.getDate()).padStart(2, '0');
            const stamp = y + m + d;

            FileSaver.saveAs(blob, 'datev_' + stamp + '.zip');
        });
    },

    sap: async (idObject, enqueueSnackbar, updateData, closeSnackbar = null, stateUpdater = null, company, tr, setSendingStatus) => {
        if(idObject.ids == "")
            return;

        setSendingStatus(true);

        // eslint-disable-next-line no-loop-func
        DataHandler.put({url: `invoices/sap`}, {ids: idObject.ids, company: company}).done(resp => {
            setSendingStatus(false);

            if (resp?.status == "ok") {
                enqueueSnackbar(tr('Invoices successfully sent to SAP S/4HANA'), {
                    variant: "success",
                });
            } else {
                enqueueSnackbar(tr('Sending failed to SAP S/4HANA'), {
                    variant: "error",
                });
            }
        }).fail(() => {
            setSendingStatus(false);
            
            enqueueSnackbar(tr("Sending failed to SAP S/4HANA"), {
                variant: "error",
            });
        });
    },

    netsuite: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        DataHandler.getArrayBuffer({url: `invoices/netsuite`, type: 1, company: company, ids: ids}).done(response =>{
            const blob = new Blob([response], {
                type: 'text/plain'
            });
            FileSaver.saveAs(blob, 'netsuite_invoices.csv');

            analyticsData.success = true;
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            updateData();
        }).fail(() => {
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar(tr("Failed to generate file"), {
                variant: "error",
            });
        });
    },

    netsuite_credit: (idObject, enqueueSnackbar, updateData, closeSnackbar, stateUpdater = null, company, tr, setSendingStatus, analyticsKey) => {
        if(idObject.ids == "")
            return;

        const ids = idObject.ids;
        const context = exportContext(); 
        const analyticsData = {
            "invoices_sent": idObject.ids.length,
            "taimer_version": context.versionId,
            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
            "invoice_destination": analyticsKey,
            "success": false,
        }

        DataHandler.getArrayBuffer({url: `invoices/netsuite`, type: 2, company: company, ids: ids}).done(response =>{
            const blob = new Blob([response], {
                type: 'text/plain'
            });
            FileSaver.saveAs(blob, 'netsuite_credit_invoices.csv');

            analyticsData.success = true;
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            updateData();
        }).fail(() => {
            context.functions.sendAnalytics("invoice_sent", analyticsData);
            enqueueSnackbar(tr("Failed to generate file"), {
                variant: "error",
            });
        });
    },
};

export default InvoiceApi;
