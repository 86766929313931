import React from 'react';
import { MonetizationOn, Money, ShoppingCart } from '@mui/icons-material';
import moment from 'moment/min/moment-with-locales';
import TaimerComponent from '../TaimerComponent';
import TabQuotes from './TabQuotes';
import OldTabQuotes from './TabQuotes.old';
import RevenueRecognition from './RevenueRecognition';
import InsightDropDown from '../dashboard/insights/InsightDropDown';
import MyDayDateRangeModal from '../dashboard/my_day/components/MyDayDateRangeModal';
import NoPermissionOverlay from '../overlays/NoPermissionOverlay';
import CoreDialog from './../dialogs/mass_operations/CoreDialog';
import './TabSales.css';

export default class TabSales extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'projects/TabSales');

        this._dropdown = React.createRef();
        this.revenueRecognition = React.createRef();

        this.tabs = {};

        this.rights = {
            quote: props.project.rights.includes("project_cost_estimate_read"),
            revenueRecognition: props.project.rights.includes("revenue_recognition_read")
        }

        if (this.rights.quote) {
            this.tabs = {
                ...this.tabs,
                quote: {
                    key: 'quote',
                    title: this.tr('Quote'),
                    icon: <MonetizationOn />,
                    action: () => this.changeTab(this.tabs.quote),
                },
                // oldquote: {
                //     key: 'oldquote',
                //     title: this.tr('Quote'),
                //     icon: <MonetizationOn />,
                //     action: () => this.changeTab(this.tabs.oldquote),
                // },
            };
        }
 
        if (this.rights.revenueRecognition) {
            this.tabs = {
                ...this.tabs,
                revenueRecognition: {
                    key: 'revenueRecognition',
                    title: this.tr('Revenue Recognition'),
                    icon: <Money />,
                    action: () => this.changeTab(this.tabs.revenueRecognition),
                },
            };
        }

        let mode = this.tabs[Object.keys(this.tabs)[0]];

        if (this.props.tabView && this.tabs[this.props.tabView]) {
            mode = this.tabs[this.props.tabView];
        }
        this.state = {
            mode,
            startDate: props.project.startdate && props.project.startdate != '0000-00-00' ? moment(props.project.startdate, 'YYYY-MM-DD') : null,
            endDate: props.project.enddate && props.project.enddate != '0000-00-00' ? moment(props.project.enddate, 'YYYY-MM-DD') : null,
        };
    }

    changeTab = (mode) => {
        this.setState({ mode });
        this.context.functions.updateView({ tabView: mode.key })
    }

    updateRevenueRecognition = () => {
        this.revenueRecognition.current && this.revenueRecognition.current.updateRevenueRecognition();
    };

    openProjectDurationDialog = (dateRange) => {
        this.context.functions.showDialog(
            <CoreDialog
                dialogType={"delete"}
                dialogProps={{
                    closeIconTooltip: this.tr("Do not update project duration"),
                    hideWarningIcon: false,
                    header: this.tr('Update project duration'),
                    confirmButtonClass: "blue",
                    translatedConfirmButtonText:  this.tr('Update'),
                    cancelButtonText:  this.tr('Cancel'),
                    warning: () => this.tr('Do you want to update project duration?'),
                    close: this.context.functions.closeDialog,
                    onCloseClick: this.context.functions.closeDialog,
                    onConfirm: () => {
                        this.context.functions.closeDialog();
                        this._dropdown.current && this._dropdown.current.close();
                        const startDate = moment(dateRange.startDate);
                        const endDate = moment(dateRange.endDate);
                        this.setState(
                            {
                                startDate,
                                endDate,
                            },
                            () => {
                                this.props.valueChanged({
                                    startdate: startDate.format('YYYY-MM-DD'),
                                    enddate: endDate.format('YYYY-MM-DD'),
                                });
                            }
                        );
                    },
                    onCancel: () => {
                        this._dropdown.current && this._dropdown.current.close();
                        this.context.functions.closeDialog();
                    },
                }}
            />
        );
    };

    _renderContent = () => {
        const { mode, startDate, endDate } = this.state;

        switch (mode.key) {
            case 'quote':
                return <TabQuotes {...this.props} />;
            case 'oldquote':
                return <OldTabQuotes {...this.props} />;
            case 'revenueRecognition':
                return <RevenueRecognition ref={this.revenueRecognition} startDate={startDate} endDate={endDate} renderProjectTimespan={this.renderProjectTimespan} {...this.props} />;
            default:
                return null;
        }
    };

    _setDates = (dateRange) => {
        this.openProjectDurationDialog(dateRange);
    };

    _renderDatePicker = () => {
        const dateRange = {
            startDate: (this.state.startDate || moment()).toDate(),
            endDate: (this.state.endDate || moment()).toDate(),
            key: 'selection',
        };
        return <MyDayDateRangeModal initialDateRange={dateRange} mode={'daterange'} saveChanges={this._setDates} />;
    };

    renderProjectTimespan = () => {
        const { mode, startDate, endDate } = this.state;
        const {
            functions: { updateView },
            userObject: { dateFormat },
        } = this.context;
        return (
            this.props.checkPrivilege('projects', 'write', this.props.project.companies_id) && (
                <InsightDropDown
                    ref={this._dropdown}
                    customComponent={this._renderDatePicker()}
                    defaultSelection={{
                        label: (!startDate || !endDate) ? this.tr("Set project timespan") : `${startDate.format(dateFormat)} - ${endDate.format(dateFormat)}`,
                    }}
                    title={this.tr('Project Timespan')}
                />
            )
        );
    }
    
    render() {
        const { mode } = this.state;
        if (!this.props.checkPrivilege('projects', 'project_cost_estimate_read', this.props.project.companies_id) && !this.props.checkPrivilege('projects', 'revenue_recognition_read', this.props.project.companies_id) && !this.props.project.rights.includes("purchase_order_read")) {
            return <NoPermissionOverlay />;
        }
        return (
            <div id="tab-sales" style={{ minWidth: mode.key == 'revenueRecognition' ? 800 : 0 }}>
                {this._renderContent()}
            </div>
        );
    }
}
