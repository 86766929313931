import React, { ChangeEvent, Component } from 'react'

import styles from './ReportsGallery.module.scss';

/* local components */
import TaimerComponent from '../../TaimerComponent';
import Slider from '../../general/Slider';
import SearchTextInput from "../../general/SearchTextInput";
import ReportsGallerySection from './ReportsGallerySection';
import SliderCheckBoxRowGroup from './SliderCheckBoxRowGroup';
import { LabelCheck as CheckFilter } from './SliderCheckBoxRowGroup';

/* context */
import { SettingsContext } from '../../SettingsContext';
import _ from 'lodash';
import { ReportType, TemplateListItem, TemplateData } from '../TemplateUtils';

interface Props {
    templates: TemplateListItem[];
    open?: boolean;
    onClose?: () => void;
    onSelectTemplate?: (template: TemplateListItem) => void;
    onUpdateTemplate?: (template: TemplateListItem, update: Partial<TemplateListItem>) => void;
    editTemplate?: (template: TemplateData, deleted: boolean) => void;
}

interface State {
    filterTextValue: string;
    filteredTemplates: TemplateListItem[];
    enabledCheckFilters: ("showDefault" | "showOwn" | "showShared")[];
}

export interface ReportsSection {
    id: ReportType;
    title: string;
}

const searchSection: ReportsSection = {
    id: 0,
    title: 'Search results',
}

const sections: ReportsSection[] = [
    {
        id: ReportType.Hours,
        title: 'Hours',
    },
    {
        id: ReportType.Invoicing,
        title: 'Invoicing',
    },
    {
        id: ReportType.ScheduledInvoicing,
        title: 'Scheduled invoicing',
    },
    {
        id: ReportType.Sales,
        title: 'Sales',
    },
    {
        id: ReportType.Costs,
        title: 'Costs',
    },
    {
        id: ReportType.Projects,
        title: 'Projects',
    },
    {
        id: ReportType.Accounts,
        title: 'Accounts',
    },
    {
        id: ReportType.Resourcing,
        title: 'Resourcing',
    },
    {
        id: ReportType.Activities,
        title: 'Activities',
    },
    {
        id: ReportType.Forecast,
        title: 'Forecast',
    },
    {
        id: ReportType.Contacts,
        title: 'Contacts',
    },
    {
        id: ReportType.Users,
        title: 'Users',
    },
    {
        id: ReportType.RevenueRecognition,
        title: 'Revenue recognition',
    },
];

export default class ReportsGallery extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;
    checkFilters: CheckFilter[];

    constructor(props, context) {
        super(props, context, "new_reports/components/ReportsGallery");

        this.checkFilters = [
            {
                name: "showDefault",
                label: this.tr("Default reports"),
            },
            {
                name: "showOwn",
                label: this.tr("Own reports"),
            },
            {
                name: "showShared",
                label: this.tr("Shared reports"),
            }
        ];
        
        this.state = {
            filterTextValue: "",
            filteredTemplates: props.templates,
            enabledCheckFilters: ["showDefault", "showOwn", "showShared"],
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        const { templates } = this.props;

        if (!_.isEqual(prevProps.templates, templates)) {
           this.filterChanged(); // Do this so filtered templates show correctly e.g. after toggle favorite.
        }
    }

    isSectionVisible = (type: ReportType): boolean => {
        const {
            functions: { hasPrivilege }, addons
        } = this.context;

        switch (type) {
            case ReportType.Invoicing:
                return hasPrivilege("new_reports", "invoicing");
            case ReportType.ScheduledInvoicing:
                return hasPrivilege("new_reports", "scheduled_invoicing");
            case ReportType.Hours:
                return hasPrivilege("new_reports", "hours") || hasPrivilege("new_reports", "hours_no_hr") || hasPrivilege("new_reports", "hours_own_subordinates") || hasPrivilege("new_reports", "hours_own_projects");
            case ReportType.Costs:
                return hasPrivilege("new_reports", "costs");
            case ReportType.Projects:
                return hasPrivilege("new_reports", "projects"); // Projects tab shows if right to new_reports->projects. So no projects->read check is needed in here either.
            case ReportType.Accounts:
                return hasPrivilege("new_reports", "accounts");
            case ReportType.Forecast:
                return hasPrivilege("new_reports", "forecast");
            case ReportType.Activities:
                return hasPrivilege("new_reports", "activities");
            case ReportType.Sales:
                return hasPrivilege("new_reports", "sales"); 
            case ReportType.Contacts:
                return hasPrivilege("new_reports", "contacts");
            case ReportType.Users:
                return hasPrivilege("new_reports", "users") && addons.new_user_report;
            case ReportType.RevenueRecognition:
                return hasPrivilege("new_reports", "revenue_recognition");
            case ReportType.Resourcing:
                return hasPrivilege("new_reports", "resourcing") && addons.resourcing;  
            default:
                return false;
        }
    }

    componentWillUnmount = () => {
        super.componentWillUnmount();
    }

    onTextFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        const filterTextValue = event.target.value;

        this.setState({
            filterTextValue,
        }, () => this.filterChanged());
    }

    onCheckFilterChange = (enabledCheckFilters) => {
        this.setState({
            enabledCheckFilters,
        }, () => this.filterChanged());
    }
    
    filterChanged = () => {
        const { templates } = this.props;
        const { filterTextValue, enabledCheckFilters } = this.state;

        const showDefault = enabledCheckFilters.find(e => e == "showDefault") ? true : false;
        const showOwn     = enabledCheckFilters.find(e => e == "showOwn") ? true : false;
        const showShared  = enabledCheckFilters.find(e => e == "showShared") ? true : false;

        const filteredTemplates = templates.filter(t => {
            let visible = false;
            if (t.is_default) {
                visible = showDefault;
            }
            else if (t.is_own) {
                visible = showOwn;
            }
            else {
                visible = showShared;
            }
            return visible && (t?.name || "").toLowerCase().includes(filterTextValue.toLowerCase());
        });

        this.setState({
            filteredTemplates
        });
    }

    render() {
        const { tr } = this;
        const { open, onClose, onSelectTemplate, onUpdateTemplate, editTemplate } = this.props;
        const { filterTextValue, filteredTemplates, enabledCheckFilters } = this.state;

        const templatesByReportType = _.chain(filteredTemplates)
            .filter(x => !x.is_hidden)
            .filter(x => this.isSectionVisible(x.report_type))
            .sortBy(x => [!x.is_default, !x.is_favorite, x.name?.toLowerCase(), x.order_nr, x.id])
            .groupBy(x => x.report_type)
            .value();

        return (
            <Slider className={styles.root} title={tr("Report gallery")} open={open} onClose={onClose}>
                <div className={styles.top}>
                    <div className={styles.filterContainer}>
                        <SearchTextInput
                            className={styles.textFilter} 
                            initValue={filterTextValue}
                            placeholder={this.tr("Search for reports...")}
                            onKeyUp={this.onTextFilterChange}
                        />
                        <SliderCheckBoxRowGroup
                            boxes={this.checkFilters}
                            checkedBoxes={enabledCheckFilters}
                            title={this.tr("Show") + ":"}
                            onCheckChange={(checkedFilters) => this.onCheckFilterChange(checkedFilters)}
                        />
                    </div>
                </div>
                {filteredTemplates.length == 0 && <div className={styles.header}>
                    <div>{this.tr("No search results")}</div>
                </div>}
                <div className={styles.sections}>
                    {sections.map(x => this.isSectionVisible(x.id) && ((templatesByReportType[x.id] || 0).length > 0) && <ReportsGallerySection 
                        key={x.id}
                        section={x}
                        templates={templatesByReportType[x.id] ?? []}
                        onSelectTemplate={onSelectTemplate}
                        onUpdateTemplate={onUpdateTemplate}
                        editTemplate={editTemplate}
                        />)}
                </div>
            </Slider>
        )
    }
}
