import React from 'react';
import { exportContext } from '../Taimer';
import TaimerComponent from '../TaimerComponent';

import { ReactComponent as ActivityIcon } from './icons/activities.svg';
import { ReactComponent as TimeTrackerIcon } from './icons/hours.svg';
import { ReactComponent as InvoiceIcon } from './icons/invoices.svg';
import { ReactComponent as ProjectsIcon } from './icons/projects.svg';
import { ReactComponent as PurchaseOrderIcon } from './icons/purchase_orders.svg';
import { ReactComponent as TasksIcon } from './icons/tasks.svg';

import { EditableField } from '../general/FieldEditSlider';
import VersionContentManager from '../general/VersionContentManager';
import { NotificationLevel, NotificationSubType, NotificationType } from './api';

interface SettingsConfig {
    title: string;
    fields: EditableField[];
}

interface NotificationSetting {
    id: number;
    /**
     * Controls is an array containing the subtypes this setting affects
     */
    controls?: NotificationSubType[];
    header: string;
    active: boolean;
    visible: boolean;
    forcedGrouping?: boolean;
    levels?: NotificationLevels[];

    settingsString?: (data: any) => string;
    settingsConfig?: SettingsConfig;
}

interface SettingsGroup {
    title: string;
    settings: NotificationSetting[];
}

interface NotificationLevels {
    header?: string;
    level: NotificationLevel;
    id?: number;
    controls?: NotificationSubType[];

    settingsString?: (data: any) => string;
    settingsConfig?: SettingsConfig;
}

interface NotificationsCategory {
    icon: React.ComponentType;
    header: string;
    visible?: boolean;
    settings?: NotificationSetting[];
    setting_groups?: Record<number, SettingsGroup>;
    commonForAllCompanies?: boolean;
    sortOrder: number;
}

interface NotificationDefinition {
    icon: React.ComponentType;
    sub_type: NotificationSubType;
    actions: Record<string, string>;
    visible: boolean;
}

type NotificationsDefinitions = Record<NotificationType, NotificationsCategory>;

//HELLO FELLOW DEVS!
//If you add new notifications please update the latest runnning sub_type below so we don't have to keep searching for it.
//Current largest sub_type id: 50
//PS: PLEASE UPDATE CONFLUENCE DOCUMENTATION OR CREATE IT IF IT'S MISSING.

const days = [
    { id: 1, label: 'Monday', },
    { id: 2, label: 'Tuesday', },
    { id: 3, label: 'Wednesday', },
    { id: 4, label: 'Thursday', },
    { id: 5, label: 'Friday', },
    { id: 6, label: 'Saturday', },
    { id: 7, label: 'Sunday', },
];

const NotificationsDefinitions = {
    getDefinitions: (): NotificationsDefinitions => {
        const taimercomponent = new TaimerComponent({}, {}, "notifications/NotificationsDefinitions");

        const { tr } = taimercomponent;
        const context = exportContext();

        const { hasPrivilege } = context.functions;

        console.log("Version ID: ", context.versionId);
        console.log([10,11].includes(Number(context.versionId)));

        const definitions: NotificationsDefinitions = {
            //timetracker
            [NotificationType.TimeTracker]: {
                sortOrder: 0,
                icon: TimeTrackerIcon,
                header: tr("Time Tracker"),
                visible: !!context.addons.timetracker && hasPrivilege("workhours", "write"),
                setting_groups: {
                    1: {
                        title: tr("Time Tracker"),
                        settings: [
                            {
                                id: 43,
                                controls: [NotificationSubType.HOUR_ENTRY_REMINDER_DAILY, NotificationSubType.HOUR_ENTRY_REMINDER_WEEKLY, NotificationSubType.HOUR_ENTRY_REMINDER_MONTHLY],
                                header: tr("Remind me to track hours"),
                                active: true,
                                visible: true,
                                forcedGrouping: true,
                                levels: [
                                    { 
                                        level: NotificationLevel.NOTIFICATION_GROUP, 
                                        header: tr("Remind me to track hours"),
                                    },
                                    {
                                        level: NotificationLevel.NOTIFICATION_LEVEL_ALL,
                                        id: 44,
                                        controls: [NotificationSubType.HOUR_ENTRY_REMINDER_DAILY],
                                        header: tr('At the end of each weekday'),
                                        settingsString: (data) => tr('At ${time}, if less than ${percentage}% tracked', {
                                            time: String(data.time).split(':').slice(0, 2).join(':'),
                                            percentage: data.percentage,
                                        }),
                                        settingsConfig: {
                                            title: tr("Hour entry reminder settings"),
                                            fields: [
                                                {
                                                    key: 'time',
                                                    type: 'data_select',
                                                    title: tr('Remind at'),
                                                    options: [
                                                        { id: '7:00:00', label: '7:00' },
                                                        { id: '8:00:00', label: '8:00' },
                                                        { id: '9:00:00', label: '9:00' },
                                                        { id: '10:00:00', label: '10:00' },
                                                        { id: '11:00:00', label: '11:00' },
                                                        { id: '12:00:00', label: '12:00' },
                                                        { id: '13:00:00', label: '13:00' },
                                                        { id: '14:00:00', label: '14:00' },
                                                        { id: '15:00:00', label: '15:00' },
                                                        { id: '16:00:00', label: '16:00' },
                                                        { id: '17:00:00', label: '17:00' },
                                                        { id: '18:00:00', label: '18:00' },
                                                        { id: '19:00:00', label: '19:00' },
                                                        { id: '20:00:00', label: '20:00' },
                                                        { id: '21:00:00', label: '21:00' },
                                                        { id: '22:00:00', label: '22:00' },
                                                        { id: '23:00:00', label: '23:00' },
                                                    ],
                                                },
                                                {
                                                    key: 'percentage',
                                                    type: 'data_select',
                                                    title: tr('If less than'),
                                                    options: [
                                                        { id: '10', label: `10% ${tr('tracked')}` },
                                                        { id: '20', label: `20% ${tr('tracked')}` },
                                                        { id: '30', label: `30% ${tr('tracked')}` },
                                                        { id: '40', label: `40% ${tr('tracked')}` },
                                                        { id: '50', label: `50% ${tr('tracked')}` },
                                                        { id: '60', label: `60% ${tr('tracked')}` },
                                                        { id: '70', label: `70% ${tr('tracked')}` },
                                                        { id: '80', label: `80% ${tr('tracked')}` },
                                                        { id: '90', label: `90% ${tr('tracked')}` },
                                                        { id: '100', label: `100% ${tr('tracked')}` },
                                                    ],
                                                },
                                            ],
                                        },
                                    },     
                                    {
                                        level: NotificationLevel.NOTIFICATION_LEVEL_ALL,
                                        id: 45,
                                        controls: [NotificationSubType.HOUR_ENTRY_REMINDER_WEEKLY],
                                        header: tr('At the end of the week'),
                                        settingsString: (data) => tr('${day} at ${time}, if less than ${percentage}% tracked', {
                                            day: tr(`On ${days.find(x => x.id == data.day)?.label}`),
                                            time: String(data.time).split(':').slice(0, 2).join(':'),
                                            percentage: data.percentage,
                                        }),
                                        settingsConfig: {
                                            title: tr("Hour entry reminder settings"),
                                            fields: [
                                                {
                                                    key: 'day',
                                                    type: 'data_select',
                                                    title: tr('Week ends on'),
                                                    options: days.map(x => ({
                                                        ...x,
                                                        label: tr(x.label),
                                                    })),
                                                },
                                                {
                                                    key: 'time',
                                                    type: 'data_select',
                                                    title: tr('Remind at'),
                                                    options: [
                                                        { id: '7:00:00', label: '7:00' },
                                                        { id: '8:00:00', label: '8:00' },
                                                        { id: '9:00:00', label: '9:00' },
                                                        { id: '10:00:00', label: '10:00' },
                                                        { id: '11:00:00', label: '11:00' },
                                                        { id: '12:00:00', label: '12:00' },
                                                        { id: '13:00:00', label: '13:00' },
                                                        { id: '14:00:00', label: '14:00' },
                                                        { id: '15:00:00', label: '15:00' },
                                                        { id: '16:00:00', label: '16:00' },
                                                        { id: '17:00:00', label: '17:00' },
                                                        { id: '18:00:00', label: '18:00' },
                                                        { id: '19:00:00', label: '19:00' },
                                                        { id: '20:00:00', label: '20:00' },
                                                        { id: '21:00:00', label: '21:00' },
                                                        { id: '22:00:00', label: '22:00' },
                                                        { id: '23:00:00', label: '23:00' },
                                                    ],
                                                },
                                                {
                                                    key: 'percentage',
                                                    type: 'data_select',
                                                    title: tr('If less than'),
                                                    options: [
                                                        { id: '10', label: `10% ${tr('tracked')}` },
                                                        { id: '20', label: `20% ${tr('tracked')}` },
                                                        { id: '30', label: `30% ${tr('tracked')}` },
                                                        { id: '40', label: `40% ${tr('tracked')}` },
                                                        { id: '50', label: `50% ${tr('tracked')}` },
                                                        { id: '60', label: `60% ${tr('tracked')}` },
                                                        { id: '70', label: `70% ${tr('tracked')}` },
                                                        { id: '80', label: `80% ${tr('tracked')}` },
                                                        { id: '90', label: `90% ${tr('tracked')}` },
                                                        { id: '100', label: `100% ${tr('tracked')}` },
                                                    ],
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        level: NotificationLevel.NOTIFICATION_LEVEL_ALL,
                                        id: 46,
                                        controls: [NotificationSubType.HOUR_ENTRY_REMINDER_MONTHLY],
                                        header: tr('At the end of the month'),
                                        settingsString: (data) => tr('At ${time}, if less than ${percentage}% tracked', {
                                            time: String(data.time).split(':').slice(0, 2).join(':'),
                                            percentage: data.percentage,
                                        }),
                                        settingsConfig: {
                                            title: tr("Hour entry reminder settings"),
                                            fields: [
                                                {
                                                    key: 'time',
                                                    type: 'data_select',
                                                    title: tr('Remind at'),
                                                    options: [
                                                        { id: '7:00:00', label: '7:00' },
                                                        { id: '8:00:00', label: '8:00' },
                                                        { id: '9:00:00', label: '9:00' },
                                                        { id: '10:00:00', label: '10:00' },
                                                        { id: '11:00:00', label: '11:00' },
                                                        { id: '12:00:00', label: '12:00' },
                                                        { id: '13:00:00', label: '13:00' },
                                                        { id: '14:00:00', label: '14:00' },
                                                        { id: '15:00:00', label: '15:00' },
                                                        { id: '16:00:00', label: '16:00' },
                                                        { id: '17:00:00', label: '17:00' },
                                                        { id: '18:00:00', label: '18:00' },
                                                        { id: '19:00:00', label: '19:00' },
                                                        { id: '20:00:00', label: '20:00' },
                                                        { id: '21:00:00', label: '21:00' },
                                                        { id: '22:00:00', label: '22:00' },
                                                        { id: '23:00:00', label: '23:00' },
                                                    ],
                                                },
                                                {
                                                    key: 'percentage',
                                                    type: 'data_select',
                                                    title: tr('If less than'),
                                                    options: [
                                                        { id: '10', label: `10% ${tr('tracked')}` },
                                                        { id: '20', label: `20% ${tr('tracked')}` },
                                                        { id: '30', label: `30% ${tr('tracked')}` },
                                                        { id: '40', label: `40% ${tr('tracked')}` },
                                                        { id: '50', label: `50% ${tr('tracked')}` },
                                                        { id: '60', label: `60% ${tr('tracked')}` },
                                                        { id: '70', label: `70% ${tr('tracked')}` },
                                                        { id: '80', label: `80% ${tr('tracked')}` },
                                                        { id: '90', label: `90% ${tr('tracked')}` },
                                                        { id: '100', label: `100% ${tr('tracked')}` },
                                                    ],
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                            {
                                id: 1,
                                controls: [NotificationSubType.HOURBALANCE_MIN_EXCEED, NotificationSubType.HOURBALANCE_MAX_EXCEED],
                                header: tr("Notify when my hour balance exceeds companylimit +/-"),
                                active: true,
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 2,
                                controls: [NotificationSubType.HOURBALANCE_MIN_USER_EXCEED, NotificationSubType.HOURBALANCE_MAX_USER_EXCEED],
                                header: tr("Notify when my team members hour balance exceeds company limit +/-"),
                                active: true,
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 3,
                                controls: [NotificationSubType.WORKTIME_DELETED],
                                header: tr("Get notified about deleted hours"),
                                active: !!context.addons.delete_user_workhours || !VersionContentManager.isFeatureHidden('settings/pages/TimeManagement', 'hoursApproval'),
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 4,
                                controls: [NotificationSubType.WORKTIME_APPROVED, NotificationSubType.WORKTIME_DECLINED],
                                header: tr("Notify when my workhour entry is approved/declined"),
                                active: true,
                                visible: !VersionContentManager.isFeatureHidden('settings/pages/TimeManagement', 'hoursApproval'),
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 5,
                                controls: [NotificationSubType.WORKTIME_SENT_TO_APPROVAL, NotificationSubType.WORKTIME_SENT_TO_MANAGER],
                                header: tr("Notify me when I receive hours for approval"),
                                active: true,
                                visible: !VersionContentManager.isFeatureHidden('settings/pages/TimeManagement', 'hoursApproval'),
                                forcedGrouping: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when I receive hours for approval") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("From my suboordinates"), id: 5, controls: [24] },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("For the project where I am the project manager"), id: 6, controls: [31] },
                                ],
                            },
                            {
                                id: 7,
                                controls: [NotificationSubType.OVERTIME_APPROVED, NotificationSubType.OVERTIME_DECLINED],
                                header: tr("Notify when my overtime entry is approved"),
                                active: true,
                                visible: !VersionContentManager.isFeatureHidden('settings/pages/TimeManagement', 'overtime'),
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            }, 
                            {
                                id: 8,
                                controls: [NotificationSubType.OVERTIME_APPROVE_ENTRY],
                                header: tr("Notify when my subordinates make overtime entries"),
                                active: true,
                                visible: !VersionContentManager.isFeatureHidden('settings/pages/TimeManagement', 'overtime'),
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            }, 
                            {
                                id: 9,
                                controls: [NotificationSubType.OVERTIME_APPROVE_ENTRY_MODIFY],
                                header: tr("Notify when my subordinates modify entries"),
                                active: true,
                                visible: !VersionContentManager.isFeatureHidden('settings/pages/TimeManagement', 'overtime'),
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            }, 
                        ],
                    }
                }

            },
            [NotificationType.Invoice]: {
                sortOrder: 1,
                icon: InvoiceIcon,
                header: tr("Invoicing"),
                visible: !!context.addons.invoicing && (hasPrivilege("invoices", "write_simple") || hasPrivilege("invoices", "write_full")),
                setting_groups: {
                    1: {
                        title: tr("Invoicing"),
                        settings: [
                            {
                                //controls is an array containing the subtypes this setting affects
                                id: 10,
                                controls: [NotificationSubType.INVOICE_CREATED],
                                header: tr("Notify me when an invoice is created"),
                                active: true,
                                visible: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when an invoice is created") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr('For all accounts and projects') },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_MANAGER, header: tr("Where I'm the account manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_TEAM, header: tr("Where I'm a account team member") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("Where I'm the project manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("Where I'm the sales agent") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("Where I'm a project team member") },
                                ],

                            },
                            {
                                id: 12,
                                controls: [NotificationSubType.INVOICE_OVERDUE],
                                header: tr("Notify me when an invoice is overdue"),
                                active: true,
                                visible: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when an invoice is overdue") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr('For all accounts and projects') },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_MANAGER, header: tr("Where I'm the account manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_TEAM, header: tr("Where I'm a account team member") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("Where I'm the project manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("Where I'm the sales agent") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("Where I'm a project team member") },
                                ],
                            },
                            {
                                id: 14,
                                controls: [NotificationSubType.INVOICE_PAID],
                                header: tr("Notify me when an invoice is paid"),
                                active: true,
                                visible: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when an invoice is paid") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr('For all accounts and projects') },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_MANAGER, header: tr("Where I'm the account manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_TEAM, header: tr("Where I'm a account team member") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("Where I'm the project manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("Where I'm the sales agent") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("Where I'm a project team member") },
                                ],
                            },
                        ],
                    },
                }
            },
            [NotificationType.Projects]: {
                sortOrder: 2,
                icon: ProjectsIcon,
                header: tr("Projects"),
                setting_groups: {
                    1: {
                        title: tr("Projects"),
                        settings: [
                            {
                                id: 16,
                                controls: [18],
                                header: tr("Notify me for all new projects created"),
                                active: true,
                                visible: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when a project is created.") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("Notify me for all new projects created.") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("Notify me for all new projects created where I'm the project manager.") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("Notify me for all new projects created where I'm the sales manager.") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("Notify me for all new projects created where I'm a project team member.") },
                                ],
                            },
                            {
                                id: 17,
                                controls: [19, 20, 21],
                                header: tr("Notify me when I am added to a project"),
                                active: true,
                                visible: true,
                                forcedGrouping: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when I am added to a project") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("Notify me when I'm added as the project manager"), id: 17, controls: [19] },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("Notify me when I'm added as the sales agent"), id: 18, controls: [20] },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("Notify me when I'm added as a team member"), id: 19, controls: [21] },
                                ],
                            },
                            {
                                id: 10000,
                                controls: [NotificationSubType.PROJECT_IMPORT_HUBSPOT],
                                header: tr("Notify me when a project is added from HubSpot"),
                                active: false,
                                visible: context.addons.hubspot,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                        ],
                    },
                },
            },
            [NotificationType.PurchaseOrders]: {
                sortOrder: 3,
                icon: PurchaseOrderIcon,
                header: tr("Purchase orders"),
                visible: !!context.addons.bills,
                setting_groups: {
                    1: {
                        title: tr("Purchase orders"),
                        settings: [
                            {
                                id: 20,
                                controls: [NotificationSubType.PO_CREATED],
                                header: tr("Notify me when a purchase order is created"),
                                active: true,
                                visible: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when a purchase order is created") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("For all accounts and projects") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_MANAGER, header: tr("For an account where I'm the account manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_TEAM, header: tr("For an account where I'm a account team member") },                                    
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("For a project where I'm the project manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("For a project where I'm the sales agent") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("For a project where I'm a team member") },
                                ]
                            },
                            {
                                id: 21,
                                controls: [NotificationSubType.PO_SYNCED, NotificationSubType.PO_AUTO_SYNCED, NotificationSubType.PO_MULTI_AUTO_SYNCED],
                                header: tr("Notify when a purchase order is synced to a bill"),
                                active: true,
                                visible: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify when a purchase order is synced to a bill") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("For all accounts and projects") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_MANAGER, header: tr("For an account where I'm the account manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_TEAM, header: tr("For an account where I'm a account team member") },                                     
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("For a project where I'm the project manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("For a project where I'm the sales agent") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("For a project where I'm a team member") },
                                ]
                            },
                        ],
                    }
                },
            },
            [NotificationType.Activity]: {
                sortOrder: 4,
                icon: ActivityIcon,
                header: tr('Activities'),
                setting_groups: {
                    1: {
                        title: tr('Activities'),
                        settings: [
                            {
                                id: 33,
                                controls: [NotificationSubType.ACTIVITY_CREATED_ACCOUNT, NotificationSubType.ACTIVITY_CREATED_PROJECT],
                                header: tr('Notify me when an activity is created'),
                                active: true,
                                visible: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when an activity is created") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("For all accounts and projects") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_MANAGER, header: tr("For an account where I'm the account manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_TEAM, header: tr("For an account where I'm a account team member") },                                     
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("For a project where I'm the project manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("For a project where I'm the sales agent") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("For a project where I'm a team member") },
                                ],
                            },                        
                            {
                                id: 34,
                                controls: [NotificationSubType.ACTIVITY_ASSIGNED_ACCOUNT, NotificationSubType.ACTIVITY_ASSIGNED_PROJECT],
                                header: tr('When activity is assigned to me'),
                                active: true,
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 47,
                                controls: [NotificationSubType.ACTIVITY_UNASSIGNED_ACCOUNT, NotificationSubType.ACTIVITY_UNASSIGNED_PROJECT],
                                header: tr('When activity is unassigned'),
                                active: true,
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },                             
                            {
                                id: 35,
                                controls: [NotificationSubType.ACTIVITY_DUE_ACCOUNT, NotificationSubType.ACTIVITY_DUE_PROJECT],
                                header: tr('When activity is due'),
                                active: true,
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },                           
                        ],
                    }
                },
            },
            [NotificationType.Bills]: {
                sortOrder: 5,
                icon: ProjectsIcon,
                header: tr("Received invoices"),
                visible: !!context.addons.bills && (hasPrivilege('receivedinvoices', 'approve') || hasPrivilege('receivedinvoices', 'pre_approve')),
                setting_groups: {
                    1: {
                        title: tr("Received invoices"),
                        settings: [
                            {
                                id: 22,
                                controls: [60],
                                header: tr("Notify me when a bill is created or received"),
                                active: true,
                                visible: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when a bill is created or received") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("For all accounts") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_MANAGER, header: tr("For an account where I'm the account manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_TEAM, header: tr("For an account where I'm a account team member") },
                                ],
                            },
                            {
                                id: 23,
                                controls: [61, 62],
                                header: tr("A bill is assigned to me"),
                                active: true,
                                visible: true,
                                forcedGrouping: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("A bill is assigned to me") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("Manually"), id: 24, controls: [61] },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("When bill is received from integration"), id: 25, controls: [62] },
                                ],
                            },
                            {
                                id: 26,
                                controls: [63],
                                header: tr("A bill is targeted to my project"),
                                active: true,
                                visible: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("A bill is targeted to my project") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("Where I'm the project manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("Where I'm the sales agent") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("Where I'm a team member") },
                                ]
                            },
                            {
                                id: 27,
                                controls: [64],
                                header: tr("Notify men when a bill's payment is due"),
                                active: true,
                                visible: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify men when a bill's payment is due") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("For all accounts and projects") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_MANAGER, header: tr("For an account where I'm the account manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ACCOUNT_TEAM, header: tr("For an account where I'm a account team member") },                                     
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("For a project where I'm the project manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("For a project where I'm the sales agent") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("For a project where I'm a team member") },                                    
                                ],
                            },
                        ],
                    },
                },
            },
            [NotificationType.Expenses]: {
                sortOrder: 6,
                icon: ProjectsIcon,
                header: tr("Expenses"),
                visible: !!context.addons.expenses && (
                    hasPrivilege('worktrips', 'write') || hasPrivilege('worktrips', 'approve') ||
                    hasPrivilege('worktrips', 'approve_projectmanager')) || hasPrivilege('worktrips', 'approve_superior') ||
                    hasPrivilege('worktrips', 'modify_all'),
                setting_groups: {
                    1: {
                        title: tr("Expenses"),
                        settings: [
                            {
                                id: 28,
                                controls: [70, 71, 72],
                                header: tr("Notify me when an expense is created"),
                                active: true,
                                visible: true,
                                forcedGrouping: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when an expense is created") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("For all projects"), id: 28, controls: [70] },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("Where I'm the project manager"), id: 29, controls: [70]},
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("Where I'm the sales agent"), id: 29, controls: [70] },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("Where I'm a team member"), id: 29, controls: [70] },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("By my subordinate"), id: 30, controls: [72] },
                                ],
                            },
                            {
                                id: 31,
                                controls: [73],
                                header: tr("My expense is approved"),
                                active: true,
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 32,
                                controls: [74],
                                header: tr("My expense is declined"),
                                active: true,
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                        ],
                    },
                },
            },
            [NotificationType.TravelExpenses]: {
                sortOrder: 7,
                icon: ProjectsIcon,
                header: tr("Travel Expenses"),
                visible: !!context.addons.expenses && ![10,11].includes(Number(context.versionId)) && (
                    hasPrivilege('worktrips', 'write') || hasPrivilege('worktrips', 'approve') ||
                    hasPrivilege('worktrips', 'approve_projectmanager') || hasPrivilege('worktrips', 'approve_superior') ||
                    hasPrivilege('worktrips', 'modify_all')),
                setting_groups: {
                    1: {
                        title: tr("Travel Expenses"),
                        settings: [
                            {
                                id: 51,
                                controls: [100, 101, 102],
                                header: tr("Notify me when a travel expense is created"),
                                active: true,
                                visible: true,
                                forcedGrouping: true,
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("Notify me when a travel expense is created") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("For all projects"), id: 28, controls: [100] },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("Where I'm the project manager"), id: 29, controls: [100]},
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("Where I'm the sales agent"), id: 29, controls: [100] },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("Where I'm a team member"), id: 29, controls: [100] },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr("By my subordinate"), id: 30, controls: [102] },
                                ],
                            },
                            {
                                id: 52,
                                controls: [103],
                                header: tr("My travel expense is approved"),
                                active: true,
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 53,
                                controls: [104],
                                header: tr("My travel expense is declined"),
                                active: true,
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                        ],
                    },
                },
            },
            [NotificationType.RESOURCING]: {
                sortOrder: 8,
                icon: TasksIcon,
                header: tr('Resourcing'),
                visible: !!context.addons.resourcing,
                setting_groups: {
                    1: {
                        title: tr('Resourcing'),
                        settings: [
                            {
                                id: 36,
                                header: tr('When task is created'),
                                active: true,
                                visible: true,
                                controls: [NotificationSubType.TASK_CREATED],
                                levels: [
                                    { level: NotificationLevel.NOTIFICATION_GROUP, header: tr("When task is created") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_ALL, header: tr('All new tasks') },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_PROJECT_MANAGER, header: tr("In a project where I'm the project manager") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_SALES_MANAGER, header: tr("In a project where I'm the sales agent") },
                                    { level: NotificationLevel.NOTIFICATION_LEVEL_TEAM_MEMBER, header: tr("In a project where I'm team member") },
                                ],
                            },
                            {
                                id: 37,
                                header: tr('When task is assigned to me'),
                                active: true,
                                visible: true,
                                controls: [NotificationSubType.TASK_ASSIGNED],
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 38,
                                header: tr('When task is unassigned from me'),
                                active: true,
                                visible: true,
                                controls: [NotificationSubType.TASK_UNASSIGNED],
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 39,
                                header: tr('When task is due'),
                                active: true,
                                visible: true,
                                controls: [NotificationSubType.TASK_DUE],
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 40,
                                header: tr('My task is marked as done'),
                                active: true,
                                visible: true,
                                controls: [NotificationSubType.TASK_DONE],
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                            {
                                id: 42,
                                header: tr('Another user tracks hours on my task'),
                                active: true,
                                visible: true,
                                controls: [NotificationSubType.TASK_HOURS_TRACKED],
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },                            
                            {
                                id: 41,
                                header: tr('Another user marks their hours done on my task'),
                                active: true,
                                visible: true,
                                controls: [NotificationSubType.TASK_DONE_USER],
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                        ]
                    },
                },
            },
            [NotificationType.Reports]: {
                sortOrder: 9,
                icon: TasksIcon,
                header: tr('Reports'),
                visible: true,
                commonForAllCompanies: true,
                setting_groups: {
                    1: {
                        title: tr('Reports'),
                        settings: [
                            {
                                id: 50,
                                controls: [90],
                                header: tr("Notify me when a report has been shared with me"),
                                active: true,
                                visible: true,
                                levels: [{ level: NotificationLevel.NOTIFICATION_LEVEL_ALL }],
                            },
                        ]
                    },
                },
            },            
        };

        return definitions;
    }
}

export default { NotificationsDefinitions };
