import TaimerComponent from '../TaimerComponent';

interface Props {
    value?: string;
}

export default class ValueRenderer extends TaimerComponent<Props> {
    render() {
        // Rendering undefined crashes.
        return this.props.value || this.props.value?.toString() === "0" ? this.props.value : null;
    }
}