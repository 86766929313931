import React from "react";
import "./HourlySplit.css";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import cn from "classnames";
import {
  addDays,
  format,
  addMonths,
  addWeeks,
  isSameWeek,
  isSameMonth,
  startOfWeek,
  startOfMonth,
} from "date-fns";
import InsightBlock from "../../InsightBlock";
import InsightSlider from "../../InsightSlider";
import TaimerComponent from "../../../../TaimerComponent";
import TaimerAvatar from "../../../../general/TaimerAvatar";
import moment from 'moment/min/moment-with-locales';
import InsightTabs from "../../InsightTabs";
import {
  ScrollSync,
  AutoSizer,
  Grid as VirtualizedGrid,
  Table as VirtualizedTable,
  Column,
} from "react-virtualized";

const ModeButton = (props) => {
  const { onClick, label, selected } = props;
  return (
    <div className="mode-button-container">
      <Button
        className="mode-button"
        style={{
          fontWeight: selected ? "bold" : "normal",
          opacity: selected ? 1 : 0.5,
        }}
        onClick={onClick}
      >
        {label}
      </Button>
      {selected && <div className="selected-indicator" />}
    </div>
  );
};

class HourlySplit extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "dashboard/insights/hours/HourlySplit");

    const { tr } = this;
    this.dateModes = [
      {
        label: tr("Day"),
        key: "day",
        action: () => this._setDateMode("day"),
      },
      {
        label: tr("Week"),
        key: "week",
        action: () => this._setDateMode("week"),
      },
      {
        label: tr("Month"),
        key: "month",
        action: () => this._setDateMode("month"),
      },
    ];

    this.sliderColumns = props.byUser
      ? ["project", "account", "tracked", "split"]
      : ["user", "tracked", "split"];
    this.leftStaticColumns = props.byUser
      ? [
          {
            key: "user",
            label: "User",
            width: 212,
          },
        ]
      : [
          // {
          //   key: "no",
          //   label: "No.",
          //   width: 50,
          // },
          {
            key: "project",
            label: "Project",
            width: 212,
            isWide: true,
          },
          {
            key: "account",
            label: "Account",
            width: 212,
            isWide: true,
          },
        ];
    this.rightStaticColumns = props.byUser && !props.hideExtras
      ? [
          {
            label: "Available h",
            key: "available_h",
            width: 100,
          },
          {
            label: "Tracked h",
            key: "tracked_h",
            width: 100,
          },
          {
            label: "Difference",
            key: "difference",
            width: 100,
          },
        ]
      : [
          {
            label: "Total",
            key: "tracked_h",
            width: 120,
          },
        ];

    this.grid = React.createRef();

    this.state = {
      dateMode: "day",
      mode: "hours",
      sliderData: { open: false, data: [], label: "" },
      scrollBarSize: 0,
      hoverColumn: -1,
      hoverRow: -1,
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
  };

  componentWillUnmount = () => {
    super.componentWillUnmount();
  };

  _setDateMode = (dateMode) => {
    this.setState({ dateMode });
  };

  _setMode = (mode) => {
    this.setState({ mode }, () => {
      this.grid.current && this.grid.current.forceUpdate();
    });
  };

  __renderLeftTableHeader = ({ className, columns, style }) => {
    return (
      <div className={className + " grid-header"} style={style}>
        {columns}
      </div>
    );
  };

  __renderLeftTableCell = ({ columnIndex, rowIndex }) => {
    const { data } = this.props;
    const item = data[rowIndex];
    const column = this.leftStaticColumns[columnIndex];

    const displayedUsername = () => {
      let name = item.users_name;
      if (name == '' || !name)
        return;
      if (item.users_company < 1)
        name = `${item.users_name} (${this.tr("freelancer")})`;
      if (item.user_locked > 0)
        name = `${name} (${this.tr("locked")})`;
      return name;
    }

    if (column.key == "user") {
      return (
        <div
          className={cn(
            "UserAvatarCell",
            rowIndex === this.state.hoverRow && "hour-split-row-hover"
          )}
        >
          <TaimerAvatar
            id={item.users_id}
            name={item.users_name}
            color={item.users_color}
          />

          <div className="UserInfo">
            <div>{displayedUsername() || "–"}</div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={cn(
          "grid-table-cell grid-table-cell-left",
          rowIndex === this.state.hoverRow && "hour-split-row-hover"
        )}
        style={{ minWidth: column.width }}
      >
        {item[column.key]}
      </div>
    );
  };

  _renderLeftTable = (onScroll, scrollTop) => {
    const { data } = this.props;
    const { tr } = this;

    let width = 0;

    const columns = this.leftStaticColumns.map((col) => {
      width += col.width;
      return (
        <Column
          headerClassName="table-header-cell"
          cellRenderer={this.__renderLeftTableCell}
          label={tr(col.label)}
          dataKey={col.key}
          width={col.width}
        />
      );
    });

    return (
      <div className="drop-shadow-right">
        <AutoSizer disableWidth>
          {({ height }) => (
            <VirtualizedTable
              headerRowRenderer={this.__renderLeftTableHeader}
              gridClassName="no-outline no-scrollbar"
              onScroll={onScroll}
              scrollTop={scrollTop}
              onRowClick={({ index }) => {
                const obj = data[index];
                this._toggleSlider({
                  ...obj,
                  label: obj.project || obj.users_name,
                });
              }}
              onRowMouseOver={({ index }) => this.setState({ hoverRow: index })}
              headerHeight={48}
              rowHeight={44}
              rowCount={data.length}
              rowGetter={({ index }) => data[index]}
              width={width}
              height={height - this.state.scrollBarSize}
            >
              {columns}
            </VirtualizedTable>
          )}
        </AutoSizer>
      </div>
    );
  };

  __renderRightTableHeader = ({ className, columns, style }) => {
    return (
      <div className={className + " grid-header"} style={style}>
        {columns}
      </div>
    );
  };

  _renderRightTableCell = ({ columnIndex, rowIndex }) => {
    const { data } = this.props;
    const item = data[rowIndex];
    const column = this.rightStaticColumns[columnIndex];
    const { mode } = this.state;

    const rowValue = item[column.key] || 0;
    let value = rowValue;
    if (column.key == "difference") {
      const tracked = item.tracked_h || 0;
      const available = item.available_h || 0;
      const hourDifference = tracked - available;
      value = hourDifference;
      if (mode == "percentage") {
        value =
          available != 0 ? Math.round((hourDifference / available) * 100) : 0;
      }
    } else {
      const total = data.reduce(
        (prev, val) => prev + (val[column.key] || 0),
        0
      );
      if (mode == "percentage") {
        value = total != 0 ? Math.round((rowValue / total) * 100) : 0;
      }
    }

    return (
      <div
        className={cn(
          "grid-table-cell grid-table-cell-right",
          rowIndex === this.state.hoverRow && "hour-split-row-hover",
          value && "has-value"
        )}
        style={{
          minWidth: column.width,
          color: value < 0 ? "#f7548f" : "#2f404f",
        }}
      >
        {mode == "percentage"
          ? value.toFixed(2) + " %"
          : value.toFixed(2) + " h"}
      </div>
    );
  };

  _renderRightTable = (onScroll, scrollTop) => {
    const { data } = this.props;
    const { tr } = this;

    let width = 0;

    const columns = this.rightStaticColumns.map((col) => {
      width += col.width;
      return (
        <Column
          headerClassName="table-header-cell"
          cellRenderer={this._renderRightTableCell}
          label={tr(col.label)}
          dataKey={col.key}
          width={col.width}
        />
      );
    });

    return (
      <div className="drop-shadow-left">
        <AutoSizer disableWidth>
          {({ height }) => (
            <VirtualizedTable
              className="no-outline"
              headerRowRenderer={this.__renderRightTableHeader}
              gridClassName="no-outline no-scrollbar"
              onScroll={onScroll}
              onRowMouseOver={({ index }) => this.setState({ hoverRow: index })}
              onRowClick={({ index }) => {
                const obj = data[index];
                this._toggleSlider({
                  ...obj,
                  label: obj.project || obj.users_name,
                });
              }}
              scrollTop={scrollTop}
              headerHeight={48}
              rowHeight={44}
              rowCount={data.length}
              rowGetter={({ index }) => data[index]}
              width={width}
              height={height - this.state.scrollBarSize}
            >
              {columns}
            </VirtualizedTable>
          )}
        </AutoSizer>
      </div>
    );
  };

  _getDates = () => {
    const { dateMode } = this.state;
    const date = this.props.start || new Date();
    let incDate =
      dateMode == "month"
        ? startOfMonth(date)
        : dateMode == "week"
        ? startOfWeek(date, { weekStartsOn: this.context.calendar.startOfWeek })
        : new Date(date);
    let dates = [incDate];
    const end = new Date(this.props.end);
    end.setHours(23,59);
    while (incDate < end) {
      switch (dateMode) {
        case "day":
          incDate = addDays(incDate, 1);
          break;
        case "week":
          incDate = addWeeks(incDate, 1);
          break;
        case "month":
          incDate = addMonths(incDate, 1);
          break;
      }

      if (incDate < end) dates.push(incDate);
    }
    return dates;
  };

  _getDateValueForMode = (obj) => {
    const { dateMode } = this.state;

    switch (dateMode) {
      case "day":
        return moment(obj).format("MMM DD, YYYY");
      case "week":
        return `${this.tr("wk")} ${moment(obj).format("WW")}`;
      case "month":
        return moment(obj).format("MMM YYYY");
      default:
        return "-";
    }
  };

  _getCellValueForDate = (obj, date) => {
    const { dateMode, mode } = this.state;
    switch (dateMode) {
      case "day":
        return obj.values_by_date[format(date, "YYYY-MM-DD")] || 0;
      case "week": {
        const dateStrings = Object.keys(obj.values_by_date || {}) || [];
        const totalForWeek = dateStrings.reduce((prev, dateString) => {
          const rowDate = new Date(dateString);
          if (isSameWeek(rowDate, date)) {
            prev += obj.values_by_date[dateString] || 0;
          }
          return prev;
        }, 0);
        return totalForWeek || 0;
      }
      case "month":
        const dateStrings = Object.keys(obj.values_by_date || {}) || [];
        const totalForMonth = dateStrings.reduce((prev, dateString) => {
          const rowDate = new Date(dateString);
          if (isSameMonth(rowDate, date)) {
            prev += obj.values_by_date[dateString] || 0;
          }
          return prev;
        }, 0);
        return totalForMonth || 0;
      default:
        return 0;
    }
  };

  _renderScrollableTableRows = (dates) => {
    const { data, byUser } = this.props;
    const { mode } = this.state;
    return (
      <TableBody>
        {data.map((obj, i) => {
          return (
            <TableRow
              key={i}
              className={cn(
                byUser ? "UserRow" : "ProjectRow",
                "row highlightable"
              )}
            >
              {dates.map((date) => {
                let value = this._getCellValueForDate(obj, date);

                if (mode == "percentage")
                  value = obj.total ? (value / obj.total) * 100 : 0;

                // " ? ( += " %") : (value += " h");
                return (
                  <TableCell className={value && "has-value"} align="right">
                    {value.toFixed(2)}{" "}
                    {mode == "percentage" ? "%" : this.tr("h")}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  _headerCellRenderer = ({ style, columnIndex }) => {
    const obj = this.dates[columnIndex];

    return (
      <div
        className="grid-header-cell"
        style={{
          ...style,
          // color: colObj.color
        }}
      >
        {this._getDateValueForMode(obj)}
      </div>
    );
  };

  _renderScrollableHeader = (onScroll, scrollTop, scrollLeft) => {
    return (
      <div className="grid-header-center">
        <AutoSizer>
          {({ width, height }) => (
            <VirtualizedGrid
              className="no-outline hidden-scroll"
              scrollLeft={scrollLeft}
              ref={this.headerGrid}
              cellRenderer={this._headerCellRenderer}
              columnCount={this.dates.length}
              columnWidth={120}
              height={height}
              rowCount={1}
              rowHeight={48}
              width={width - this.state.scrollBarSize}
            />
          )}
        </AutoSizer>
      </div>
    );
  };

  _cellRenderer = ({ style, rowIndex, columnIndex }) => {
    const { mode } = this.state;

    const obj = this.props.data[rowIndex];
    const date = this.dates[columnIndex];

    let value = this._getCellValueForDate(obj, date);

    if (mode == "percentage") value = obj.total ? (value / obj.total) * 100 : 0;

    return (
      <div
        className={cn(
          "hour-split-cell",
          this.state.hoverRow === rowIndex && "hour-split-row-hover",
          value && "has-value"
        )}
        style={{
          ...style,
          // color: colObj.color
        }}
        onClick={() =>
          this._toggleSlider({
            ...obj,
            label: obj.project || obj.users_name,
          })
        }
        onMouseOver={() => {
          this.setState({
            hoverColumn: columnIndex,
            hoverRow: rowIndex,
          });
        }}
      >
        {value.toFixed(2)} {mode == "percentage" ? "%" : this.tr("h")}
      </div>
    );
  };

  _renderScrollableContent = (onScroll, scrollTop, scrollLeft) => {
    const { data } = this.props;

    return (
      <AutoSizer>
        {({ width, height }) => (
          <VirtualizedGrid
            ref={this.grid}
            className="no-outline scroll-overlay"
            onScroll={onScroll}
            scrollTop={scrollTop}
            onScrollbarPresenceChange={(e) =>
              this.setState({ scrollBarSize: e.size })
            }
            scrollLeft={scrollLeft}
            cellRenderer={this._cellRenderer}
            columnCount={this.dates.length}
            columnWidth={120}
            height={height - 48}
            width={width}
            estimatedColumnSize={120}
            estimatedRowSize={44}
            rowCount={data.length}
            rowHeight={44}
          />
        )}
      </AutoSizer>
    );
  };

  _renderFilters = () => {
    const { dateMode, mode } = this.state;
    return (
      <div className="filter-container">
        <InsightTabs tabs={this.dateModes} selected={dateMode} />
        <div className="format-modes-container">
          <div
            onClick={() => this._setMode("hours")}
            className={
              "format-mode-button" + (mode == "hours" ? " selected" : "")
            }
          >
            h
          </div>
          <div
            onClick={() => this._setMode("percentage")}
            className={
              "format-mode-button" + (mode == "percentage" ? " selected" : "")
            }
          >
            %
          </div>
        </div>
      </div>
    );
  };

  _toggleSlider = (sliderData) => {
    const { tr } = this;
    const { byUser, showSlider } = this.props;

    sliderData.labelPrefix = tr(byUser ? "Hourly Split by User" : "Hourly Split by Project");
    showSlider(sliderData);
  };

  dates = [];

  render() {
    const { tr } = this;
    const { currency, byUser } = this.props;

    this.dates = this._getDates();

    return (
      <InsightBlock
        className="full-width hourly-split"
        title={tr(byUser ? "Hourly Split by User" : "Hourly Split by Project")}
        rootClassName="block"
        wrapperClass="block-wrapper"
        headerClass="block-header"
        filterComponents={this._renderFilters()}
        width={6}
        onMouseOut={() => this.setState({ hoverColumn: -1, hoverRow: -1 })}
      >
        <ScrollSync>
          {({ onScroll, scrollTop, scrollLeft }) => (
            <div className="hourly-split-container">
              {this._renderLeftTable(onScroll, scrollTop)}
              <div className="grid">
                {this._renderScrollableHeader(onScroll, scrollTop, scrollLeft)}
                {this._renderScrollableContent(onScroll, scrollTop, scrollLeft)}
              </div>
              {this._renderRightTable(onScroll, scrollTop)}
            </div>
          )}
        </ScrollSync>
      </InsightBlock>
    );
  }
}

export default HourlySplit;
