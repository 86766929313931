import React from "react";
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

import _ from "lodash";
/* import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs"; */
import { Tabs, Tab } from '@mui/material';
import List from "../../list/List";
import SettingsList from "../../list/lists/SettingsList";
import WorkWeekTemplateList from "../../list/lists/WorkWeekTemplateList";
import moment from 'moment/min/moment-with-locales';
import Button from "@mui/material/Button";
import { MenuItem } from '@mui/material';

import DataHandler from "../../general/DataHandler";
import SettingRow from "../../list/rows/SettingRow";
import SettingsGrid from "./SettingsGrid";
import OutlinedField from "../../general/OutlinedField";
import OvertimeMultipliers from "./OvertimeMultipliers";

import TaimerComponent from "../../TaimerComponent";
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import ImportHourEntriesDialog from "./../../dialogs/imports/ImportHourEntriesDialog";
import HourImportTemplate from './../../dialogs/imports/import-examples/hour_import_example.xlsx';
import ProjectTreeDropdown from "../../projects/ProjectTreeDropdown";
import withStyles from '@mui/styles/withStyles';
import { withSnackbar } from "notistack";
import { SettingsContext } from "../../SettingsContext";
import { DateRangePicker } from "./../../general/react-date-range/src";
import { format, endOfMonth, startOfMonth, getTime, getYear } from "date-fns";

import { AccessTime, RemoveRedEye, ArrowDropDown, Settings, KeyboardArrowRightRounded, Add } from '@mui/icons-material';

import TimeManagementSettingRow from "../../list/rows/TimeManagementSettingRow";
import TimeManagementJobtypeRow from "../../list/rows/TimeManagementJobtypeRow";
import TimeManagementHolidayRow from "../../list/rows/TimeManagementHolidayRow";
import TimeManagementProtitleRow from "../../list/rows/TimeManagementProtitleRow";

import EyeMenu from "../../general/EyeMenu";

import "../Settings.css";
import "./TimeManagement.css";
import VersionContentManager from "../../general/VersionContentManager";

const thisMonth = { start: startOfMonth(new Date()), end: endOfMonth(new Date()) };
const thisYear = getYear(new Date());
const yearOptions = _.range(getYear(new Date()) - 1, getYear(new Date()) + 6);

class TimeManagement extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/TimeManagement");

        this.timeManagementList = React.createRef();
        this.jobTypeList = React.createRef();
        this.holidayList = React.createRef();
        this.workWeekList = React.createRef();
        this.protitlesList = React.createRef();
        this.overtimeManagementList = React.createRef();

        const tabs = ["timetracker", "hourbalance", "overtime"];

        this.state = {
            whData: [],
            worktasks: [],
            initialized:false,
            users: [],
            selectedUser: -1,
            selectedTab: this.props.selectedTab && tabs.includes(this.props.selectedTab) ? this.props.selectedTab : "timetracker",
            selectedYear: thisYear,
            holidays: [],
            protitles: [],
            internalProjects: [],
            overtimeManagementData: [],
            worktrip_project: undefined,
            timeTrackerSettings: {
                dailyworkinghours: undefined,
                dailymaxworkinghours: undefined,
                balancemode: undefined,
                workhour_accuracy: undefined,
                lock_workhours_slide: undefined,
                lock_old_workhours: undefined,
                prevent_adding_old_workhours: undefined,
                notify_hour_balance_max: undefined,
                notify_hour_balance_min: undefined,
                prevent_multicompany_hourentries: undefined,
                prevent_main_project_hours: undefined,
                hour_entry_description:undefined,
                hour_entry_task: undefined,
                disable_hours_overlap:undefined,
            },
            overtimeSettings: {
                approve_hours_before_billing: undefined,
                enable_approval_submitting: undefined,
                activate_overtime_tracking: undefined,
                overtime_description_mandatory: undefined,
                overtime_calculate_daily_hours_before: undefined,
                project_to_deduct_overtime: undefined,
            },
            hoursApprovalSettings: {
                approve_hours_before_billing: undefined,
            },
            overtimeHoursSettings: {
                activate_overtime_tracking: undefined,
                overtime_description_mandatory: undefined,
                overtime_calculate_daily_hours_before: undefined,
                project_to_deduct_overtime: undefined,
            },
            dateRange: {
                startDate: format(thisMonth.start, "YYYY-MM-DD"),
                endDate: format(thisMonth.end, "YYYY-MM-DD"),
                key: "selection",
            },
            dialogData: undefined,
            selectedJobTypeTab: "active",
            salesAccounts: [],
            purchasesAccounts: [],
            stockAccounts: [],
            hHeaderVisible:false,
            jTHeaderVisible:false,
            pTHeaderVisible:false,
            mUHBHedaerVisible:false,
            oHBHedaerVisible:false,
            importRowsDialogOpen: false
        };

        this.dialogs = {
            confirmation: ConfirmationDialog,
        };
        
        this.context.functions.updateView({selectedTab: this.state.selectedTab});

        this.onUserChange = this.onUserChange.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
        this.switchTab = this.switchTab.bind(this);
    }

    updateWhData = () => {

        const { company } = this.props;
        const {
            dateRange: { startDate, endDate },
        } = this.state;
        const user = this.state.selectedUser;

        DataHandler.post({url: `settings/company/${company}/hour_balance_management`}, {is_overtime: 0, user_id: user, start: startDate, end: endDate}).done(response => {
            if(response.managementData) {
                this.setState({whData: response.managementData});
            }
            if(response.managementData.length == 0){
                this.setState({mUHBHedaerVisible:false});
            } 
        }).fail(err => {
            console.error(err);
        });
    }

    getOvertimeManagementData = () => {
        const { company } = this.props;
        const {
            dateRange: { startDate, endDate },
        } = this.state;
        const user = this.state.selectedUser;

        DataHandler.post({url: `settings/company/${company}/hour_balance_management`}, {is_overtime: 1, user_id: user, start: startDate, end: endDate}).done(response => {
            if(response.managementData) {
                this.setState({overtimeManagementData: response.managementData});
            }
            if(response.managementData.length == 0){
                this.setState({oHBHedaerVisible:false});
            } 

        }).fail(err => {
            console.error(err);
        });
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.getAccounts();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        const users = [];
        const whData = [];

        const { company } = this.props;
        let timeTrackerSettings;

        DataHandler.get({url:"subjects/employees/" + company, locked: [0,-1,1]}).then(users => {

            this.setState({
                users: users,
                whData: whData
            });

            this.updateWhData();
        });

        this.getJobTypes("active");

        DataHandler.get({ url: `settings/timetracker/${company}`})
            .then(respOne => {
                    timeTrackerSettings = respOne;
                    timeTrackerSettings.bulkentry_daystart = moment(
                        timeTrackerSettings.bulkentry_daystart,
                        "hh:mm:ss"
                    ).format("hh:mm:ss");

                    this.setState({
                        timeTrackerSettings: timeTrackerSettings,
                    });
            } 
            

        );
        /* .then(respTwo => {
                this.setState({
                    timeTrackerSettings: timeTrackerSettings,
                    //worktasks: respTwo.worktasks_hierarchical,
                });
            }); */

        DataHandler.get({ url: `settings/company/${company}/overtime` }).then(response => {
            if (response.overtimeSettings) {
                this.setState({
                    overtimeSettings: response.overtimeSettings,
                    internalProjects: response.internalProjects
                });
            }
        });

        DataHandler.get({ url: "settings/holidays", company:company }).then(response => {
            this.setState({ holidays: response,initialized:true });
        });

        this.refreshProtitles();

       /*  DataHandler.get({ url: "settings/holidays" }).then(response => {
            this.setState({ holidays: response });
        }); */

        DataHandler.get({ url: `accounting/products/${company}/all` }).done(response => {
            this.setState(response);
        });

        DataHandler.get({url: `accounting/products/product_register`, company_id: company}).done(response => {
            this.setState(response);
        });
        
        this.getOvertimeManagementData();

        DataHandler.get({ url: `settings/company/${company}/worktripProject` }).done(response => {
           this.setState({ worktrip_project: Number(response.worktrip_project)});
        });
    };

    

    getJobTypes = type => {
        const { company } = this.props;

        DataHandler.get({ url: `settings/company/${company}/jobtype/${type}` })
            .done(response => {
                if (response.worktasks && Array.isArray(response.worktasks)) {
                    if (response.worktasks.length <= 0) {
                        this.setState({ worktasks: [] });
                    } else {
                        this.setState({ worktasks: response.worktasks });
                    }
                }
                if(response.worktasks.length == 0){
                    this.setState({jTHeaderVisible:false});
                } 
            })
            .fail(err => {
                console.error(err);
            });
    };

    async getAccounts()  {
        const salesAccount = await DataHandler.get({ url: `accounting/accounts/${this.props.company}/nav/1` });
        const purchasesAccounts = await DataHandler.get({ url: `accounting/accounts/${this.props.company}/nav/2` });
        const stockAccounts = await DataHandler.get({ url: `accounting/accounts/${this.props.company}/nav/3` });

        this.setState({
            salesAccount: salesAccount.salesAccounts,
            purchasesAccounts: purchasesAccounts.purchaseAccounts,
            stockAccounts: stockAccounts.stockAccounts
        });
    }

    refreshProtitles = () => {
        const { company } = this.props;

        DataHandler.get({ url: `settings/company/${company}/protitles` }).then(protitles => {
            this.setState({ protitles });
            if(protitles.length == 0){
                this.setState({pTHeaderVisible:false});
            } 
        });
    };

    openDialog = name => {
        this.setState({ currentDialog: name });
    };

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    };

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    };

    onDateChange = event => {
        const { startDate, endDate, selectedTab } = event.selection;
        this.setState(
            {
                dateRange: {
                    startDate: format(startDate, "YYYY-MM-DD"),
                    endDate: format(endDate, "YYYY-MM-DD"),
                    key: "selection",
                },
            },
            () => {
                if(this.state.selectedTab === "overtime")
                    this.getOvertimeManagementData();
                else {
                    this.updateWhData();
                }
            }
        );
    };

    onDateInputChange = (dateType, date) => {
        const { endDate, startDate } = this.state.dateRange;
        date = format(date, "YYYY-MM-DD");

        if (dateType == "start") {
            this.setState(
                {
                    dateRange: {
                        startDate: date,
                        endDate: endDate,
                        key: "selection",
                    },
                },
                () => {
                    if(this.state.selectedTab === "overtime")
                        this.getOvertimeManagementData();
                    else {
                        this.updateWhData();
                    }
                }
            );
        } else {
            this.setState(
                {
                    dateRange: {
                        startDate: startDate,
                        endDate: date,
                        key: "selection",
                    },
                },
                () => {
                    if(this.state.selectedTab === "overtime")
                        this.getOvertimeManagementData();
                    else {
                        this.updateWhData();
                    }
                }
            );
        }
    };

    onUserChange(user) {
        if (this.state.selectedUser === user) return;
        if(this.state.selectedTab == "overtime") {
            this.setState({ selectedUser: user }, () => this.getOvertimeManagementData());
        } else {
            this.setState({ selectedUser: user }, () => this.updateWhData());
        }
    }

    onYearChange(year) {
        this.setState({ selectedYear: year });
        const holidays = this.filterHolidays(this.state.holidays)
        if(holidays.length == 0){
            this.setState({hHeaderVisible:false});
        } 
       
        
    }

    filterHolidays = data => {
        const { selectedYear } = this.state;
        return data.length > 0 ? data.filter(h => getYear(h.date) == selectedYear) : [];
    };

    onHolidayChange = data => {
        const { enqueueSnackbar, company } = this.props;
        if (!yearOptions.includes(getYear(data.date))) {
            enqueueSnackbar(this.tr("You can add holidays to selectable years only"), {
                variant: "error",
            });
            return;
        }
        const errorp1 = this.tr("Holiday ");
        const errorp2 = this.tr(" already exists");
        DataHandler.post({ url: `settings/update_holidays`, company:company, ...data }).done(response => {
            if (response.exists) {
                enqueueSnackbar(`${errorp1} ${format(data.date, this.context.userObject.dateFormat)} ${errorp2}`, {
                    variant: "error",
                });
                return;
            }
            if (getYear(data.date) != this.state.selectedYear) {
                enqueueSnackbar(`${this.tr("Holiday was added under year")} ${getYear(data.date)}`, {
                    variant: "warning",
                });  
            }
            this.setState({ holidays: response.holidays });
            if(data.id < 0){
                this.holidayList.current.removeNewRow(data.id);
            }      
        });
    };

    switchTab(tabName) {
        this.context.functions.updateView({ selectedTab: tabName });
        if(tabName == "overtime") {
            this.setState({ selectedTab: tabName }, () => this.getOvertimeManagementData());
        } else if(tabName == "hourbalance") {
            this.setState({ selectedTab: tabName }, () => this.updateWhData());
        } else {
            this.setState({ selectedTab: tabName });
        }
    }

    createTab(name, label, className) {
        const StyledTab = withStyles({
            label: {
                fontSize: "14px",
                letterSpacing: "1px"
            },
            selected: {
				color: "#003A78 !important",
			}
        })(Tab);

        return (
            <StyledTab 
                className={`settings-styled-tab ${className} ${this.state.selectedTab !== name ? "not-selected" : ""}`} 
                value={name}
                label={this.tr(label)} 
                href={this.context.functions.urlify({...this.props.viewProps, selectedTab: name})} 
                onClick={(evt) => !evt.ctrlKey && !evt.metaKey && evt.preventDefault()} 
            />
        )
    }

    render() {
        const {
            userObject,
            userObject: { companies_id },
        } = this.context;

        const { dateRange, selectedYear, hHeaderVisible, pTHeaderVisible, jTHeaderVisible, mUHBHedaerVisible , oHBHedaerVisible, initialized, importDialogOpen, timeTrackerSettings } = this.state;
        const { tr } = this;
        const { company } = this.props;

        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };

         const StyledTabs = withStyles({
            indicator: {
                display: 'none',
                "&.with-indicator": {
                    display: 'block',
                    backgroundColor: "#003A78",
                    height: "3px",
                },
                "&.rounded-indicator": {
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                },
            }
        })(Tabs); 
        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        const settings = [
            // {
            //     type: "text",
            //     name: "dailyworkinghours",
            //     label: tr("Work hours / day"),
            //     className: "respSetting third marginTop",
            //     validation: ["numeric"],
            // },
            /* {
                type: "text",
                name: "dailymaxworkinghours",
                label: tr("Daily flextime hours"),
                className: "respSetting third"
            }, */
            {
                type: "select",
                name: "balancemode",
                label: tr("Hour balance"),
                className: "respSetting third",
                options: [
                    { value: 1, label: tr("From all registrations") },
                    { value: 2, label: tr("Only from current month") },
                    //{ value: 3, label: tr("Periodicity") }
                ],
            },
            {
                type: "time",
                name: "bulkentry_daystart",
                label: tr("Start of Day for Bulk Entries"),
                className: "respSetting third",
            },
            {
                type: "text",
                name: "workhour_accuracy",
                label: tr("Define hourly intervals in calendar view (mins)"),
                className: "respSetting third",
            },
            {
                type: "text",
                name: "bulkentry_interval",
                label: tr("Define interval in bulk view list (mins)"),
                className: "respSetting third",
            },
            {
                type: "text",
                name: "notify_hour_balance_max",
                label: tr("Define maximum amount of hours for sending hour balance notifications"),
                className: "respSetting third",
                //hideCond: () => !this.context.addons.notifications,
            },
            {
                type: "text",
                name: "notify_hour_balance_min",
                label: tr("Define minimum amount of hours for sending hour balance notifications"),
                className: "respSetting third",
                //hideCond: () => !this.context.addons.notifications,
            },
            {
                type: "title",
                label: tr("Hour Entry Rules & Permissions"),
                className: "respSetting third hourmarginTop",
            },
            {
                type: "check",
                name: "prevent_adding_old_workhours",
                label: tr("Restrict adding entries to previous months"),
                className: "respSetting third SettingsSmall",
            },
            {
                type: "check",
                name: "lock_old_workhours",
                label: tr("Restrict editing previous month entries"),
                className: "respSetting third SettingsSmall",
            },
            {
                type: "check",
                name: "prevent_multicompany_hourentries",
                label: tr("Restrict users to add entries for other companies"),
                className: "respSetting third SettingsSmall"
            },
            {
                type: "check",
                name: "prevent_main_project_hours",
                label: tr("Prevent adding hours to main projects"),
                className: "respSetting third SettingsSmall"
            },     
            {
                type: "check",
                name: "hour_entry_description",
                label: tr("Set description as mandatory for hour entry"),
                className: "respSetting third SettingsSmall",
            },
            {
                type: "check",
                name: "hour_entry_task",
                label: tr("Set task as mandatory for hour entry"),
                className: "respSetting third SettingsSmall",
            },
            {
                type: "check",
                name: "disable_hours_overlap",
                label: tr("Disallow overlapping hour entries"),
                className: "respSetting third SettingsSmall",
            }, 
            {
                type: "text",
                name: "lock_workhours_slide",
                label: tr("Number of days in new month, to add/edit previous month entries"),
                className: "respSetting third",
                hideCond: opt => opt["prevent_adding_old_workhours"] == 0 && opt["lock_old_workhours"] == 0,
            },
        ];

        const settingsHoursApproval = [
            {
                type: "check",
                name: "approve_hours_before_billing",
                label: tr("approve hours before billing"),
                className: "respSetting third SettingsSmall",
            },
            {
                type: "check",
                name: "enable_approval_submitting",
                label: tr("Enable approval submitting"),
                'data-testid': 'approval-setting',
                className: "respSetting third SettingsSmall",
            },
        ];

        const settingsOvertimeHours = [
            {
                type: "check",
                name: "activate_overtime_tracking",
                label: tr("activate overtime tracking"),
                className: "respSetting third SettingsSmall",
            },
            {
                type: "check",
                name: "approve_overtime_hours_before_billing",
                label: tr("approve overtime hours before billing"),
                className: "respSetting third SettingsSmall",
                locked: this.state.overtimeSettings.activate_overtime_tracking == "0"
            },
            {
                type: "check",
                name: "approve_overtime_hours_before_balance",
                label: tr("approve overtime hours before adding to overtime balance"),
                className: "respSetting third SettingsSmall",
                locked: this.state.overtimeSettings.activate_overtime_tracking == "0"
            },
            {
                type: "check",
                name: "overtime_description_mandatory",
                label: tr("overtime description mandatory"),
                className: "respSetting third SettingsSmall",
                locked: this.state.overtimeSettings.activate_overtime_tracking == "0"
            },
            {
                type: "check",
                name: "overtime_calculate_daily_hours_before",
                label: tr("overtime calculate daily hours before"),
                className: "respSetting third SettingsSmall",
                locked: this.state.overtimeSettings.activate_overtime_tracking == "0"
            },
            {
                type: "select",
                name: "project_to_deduct_overtime",
                label: tr("Choose project to use overtime hours"),
                className: "respSetting third",
                options: this.state.internalProjects,
                locked: this.state.overtimeSettings.activate_overtime_tracking == "0"
            },
        ];
        
        const holidays = this.filterHolidays(this.state.holidays);

        return (
            <div id="settings-timemanagement">
                {!initialized && 
                    <div> 
                      <Loading style={loaderStyle} />
                    </div>
                }  
                {initialized && 
                    <React.Fragment> 
                        <StyledTabs classes={{indicator: 'with-indicator rounded-indicator'}} value={this.state.selectedTab} variant="standard" onChange={(evt, tab) => !evt.ctrlKey && !evt.metaKey && this.switchTab(tab)}>
                        {this.createTab("timetracker", "Time Tracker")}
                        {this.createTab("hourbalance", "Hour Balance Management")}
                        {!VersionContentManager.isFeatureHidden(this.namespace, 'overtime') && this.createTab("overtime", "Overtime", "tab-overtime")}
                        </StyledTabs>
                        <hr />                       
                        <div style={{ height: 42, width: "100%" }}></div>

                        {this.state.selectedTab === "timetracker" && <div>
                            <h3>{tr("Time Tracking details")}</h3>
                            <p className="subtitle">{tr("Define default settings for time entries.")}</p>

                            <SettingsGrid settings={settings} item={this.state.timeTrackerSettings} onChange={settings => {
                                if(settings['notify_hour_balance_min'] && settings['notify_hour_balance_min'] > 0) 
                                    settings['notify_hour_balance_min'] =  settings['notify_hour_balance_min'] * -1;
                                DataHandler.put({ url: `settings/timetracker/${company}` }, settings)
                                .done(resp => {
                                    setTimeout(this.context.functions.refreshTimeTrackerSettings, 1000);
                                });

                                

                                this.setState({ timeTrackerSettings: settings });
                            }} />

                            <h3 style={{ marginTop: "35px" }}>
                                {tr("Employment templates")}
                            </h3>
                            <p className="subtitle">{tr("Create employment templates to manage users' work hours")}</p>

                            <WorkWeekTemplateList 
                                ref={this.workWeekList} 
                                companyId={company}
                                enqueueSnackbar={this.props.enqueueSnackbar}
                                mode="time_management_settings"
                                excludeFields={[
                                    "description",
                                    "start_date",
                                    "end_date",
                                    "template_id"
                                ]}
                            />

                                <div className="settings-feature-wrapper">
                                    <h3 style={{ marginTop: "24px" }}>{tr("Travel hours")}</h3>
                                    <p className="subtitle">{tr("Create a Project to track travel hours and select the Project from here. Note that, if the total work hours including the travel time exceeds the ‘Work hours / day’ limit as defined above, the additional hours from the travel time will not be counted towards your overall hour balance.")}</p>
                                    <div className="respSetting third">
                                        <ProjectTreeDropdown
                                            name="project" // TODO: Onko tarpeellinen?
                                            className="select"
                                            value={this.state.worktrip_project ? this.state.worktrip_project : ""}
                                            label={this.tr("Choose project to track travel hours")}
                                            disabled={false}
                                            queryParameters={{right: 'admin', company: this.props.company, onlyInternal: "true"}}
                                            addNoneOption={true} 
                                            handleBackspace={true}
                                            noOptionsMessage={"No options"}
                                            treeDropdownProps={{
                                                activateBestMatch: true,
                                            }}
                                            onSelect={e => {
                                                const { data } = e;
                                                let update = {};

                                                if (data && (data.id || Number(data.id) === 0)) {
                                                    update = {
                                                        worktrip_project: data.id
                                                    }
                                                    DataHandler.put({ url: `settings/company/${this.props.company}/worktripProject` }, update);
                                                }

                                                this.setState({ worktrip_project: data && data.id ? data.id : "" });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className ="settings-feature-wrapper">

                                <h3 style={{ marginTop: "24px" }}>{tr("Holidays")}</h3>
                                <p className="subtitle">{tr("Manage and view holidays")}</p>

                                <div className="respSetting third">
                                    <OutlinedField className="select" select label={this.tr("Year")} value={this.state.selectedYear} fullWidth onChange={e => this.onYearChange(e.target.value, holidays)} shrinkLabel={true} >
                                        {
                                            yearOptions.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </OutlinedField>
                                        <Button
                                            className="green holiday-button"
                                            onMouseUp={() => {this.holidayList.current.addNewRow()}}
                                            size="large"
                                        >
                                            {tr(" Add holiday")}
                                        </Button>
                                    </div>
                                    <div className="respSetting third">
                                        <List
                                            id="holidayList"
                                            className="settingsList"
                                            ref={this.holidayList}
                                            key={selectedYear}
                                            height="auto"
                                            fluid
                                            // manualListWidth={1000}
                                            rowHeight={SettingRow.rowHeight}
                                            listRowType={TimeManagementHolidayRow}
                                            hideHeader={(holidays && holidays.length > 0) ? false: true}
                                            showPageSelector={false}
                                            emptyNewDataOnUpdate={false}
                                            rowProps={{
                                                onCreate: data => this.onHolidayChange(data),
                                                onUpdate: data => this.onHolidayChange(data),
                                                onDelete: data => {
                                                    this.setState({
                                                        dialogData: {
                                                            id: data.id,
                                                            saveFunc: () =>
                                                                DataHandler.post({
                                                                    url: `settings/delete_holidays`,
                                                                    company: company,
                                                                    ...data,
                                                                }).done(response => {
                                                                    this.setState(
                                                                        {
                                                                            holidays: response,
                                                                        },
                                                                        () => this.holidayList.current.removeNewRow(data.id)
                                                                    );
                                                                   
                                                                        if(holidays.length == 0){
                                                                            this.setState({hHeaderVisible:false});
                                                                        }  
                                                                    
                                                                }),
                                                            text:
                                                                data.date != ""
                                                                    ? tr("Do you want to delete ") +
                                                                    format(data.date, userObject.dateFormat) +
                                                                    "?"
                                                                    : tr("Do you want to delete empty row?"),
                                                        },
                                                    });
                                                    this.openDialog("confirmation");
                                                },
                                            }}
                                            newRow={{
                                                id: -1,
                                                date: "",
                                            }}
                                            columns={[
                                                { width: 1, name: "delete", header: "", ...dColConf },
                                                { width: 4, name: "date", header: tr("Date"), ...dColConf },
                                            ]}
                                            data={holidays}
                                        />
                                    </div>
                                </div>
                                {!VersionContentManager.isFeatureHidden(this.namespace, 'hoursApproval') && <div className="settings-feature-wrapper">
                                    <h3>{tr("hours approval")}</h3>
                                    <p className="subtitle">
                                        {tr(
                                            "enable the toggle button to approve or decline work hours. once enabled, you will be able to approve or decline the work hours tracked."
                                        )}
                                    </p>
                                    <SettingsGrid
                                        settings={settingsHoursApproval}
                                        item={this.state.overtimeSettings}
                                        onChange={overtimeSettings => {
                                            DataHandler.put(
                                                { url: `settings/company/${company}/overtime` },
                                                overtimeSettings
                                            ).then(response => {
                                                this.setState({ overtimeSettings });
                                            });
                                        }}
                                    />
                                </div>}
                                
                                <div className="settings-feature-wrapper">
                                    <h3>{tr("Jobtypes")}</h3>
                                    <p className="subtitle">
                                        {tr(
                                            "Define default Jobtypes for invoicing and reporting purposes. Give Jobtype a name and an hourly rate for invoicing. Added jobtypls are mandatory for all time entries, you can choose one jobtype per entry. Invoice creation based on jobtype and hourly rate is made super easy."
                                        )}
                                    </p>
                                    <div className="jobtypes-header-buttons">
                                        {this.state.selectedJobTypeTab === "active" && <Button
                                            className="green"
                                            onMouseUp={() =>{ this.jobTypeList.current.addNewRow(); this.setState({jTHeaderVisible:true})}}
                                            size="large"
                                        >
                                            {tr("Add jobtype")}
                                        </Button>}
                                        <Tabs
                                            value={this.state.selectedJobTypeTab}
                                            color="primary"
                                            className="jobtypes-header-buttons-tabs"
                                            onChange={(event, val) => {
                                                if(val !== "active")
                                                    this.jobTypeList.current.emptyNewData();
                                                this.setState({ selectedJobTypeTab: val });
                                                this.getJobTypes(val);
                                            }}
                                        >
                                            <Tab
                                                value="active"
                                                label={this.tr("active")}
                                                className={
                                                    "jobtypes-header-buttons-tabs-tab tab-left" +
                                                    (this.state.selectedJobTypeTab === "active" ? " selected" : "")
                                                }
                                            />
                                            <Tab
                                                value="deactive"
                                                label={this.tr("deactive")}
                                                className={
                                                    "jobtypes-header-buttons-tabs-tab" +
                                                    (this.state.selectedJobTypeTab === "deactive" ? " selected" : "")
                                                }
                                            />
                                            <Tab
                                                value="archived"
                                                label={this.tr("archived")}
                                                className={
                                                    "jobtypes-header-buttons-tabs-tab" +
                                                    (this.state.selectedJobTypeTab === "archived" ? " selected" : "")
                                                }
                                            />
                                            <Tab
                                                value="all"
                                                label={this.tr("all")}
                                                className={
                                                    "jobtypes-header-buttons-tabs-tab tab-right" +
                                                    (this.state.selectedJobTypeTab === "all" ? " selected" : "")
                                                }
                                            />
                                        </Tabs>
                                    </div>
                                    <List
                                        id="jobTypeList"
                                        className={this.state.worktasks && Array.isArray(this.state.worktasks) && this.state.worktasks.length > 0 ? "settingsList" : "settingsList"}
                                        ref={this.jobTypeList}
                                        height="auto"
                                        fluid
                                        // manualListWidth={1000}
                                        rowHeight={SettingRow.rowHeight}
                                        hideHeader={( jTHeaderVisible || (this.state.worktasks && this.state.worktasks.length > 0)) ? false: true}
                                        listRowType={TimeManagementJobtypeRow}
                                        showPageSelector={false}
                                        emptyNewDataOnUpdate={false}
                                        rowProps={{
                                            tr: this.tr,
                                            accountingProducts: this.state.accountingProducts,
                                            productRegisterProducts: this.state.productRegisterProducts,
                                            salesAccounts: this.state.salesAccount,
                                            purchasesAccounts: this.state.purchasesAccounts,
                                            stockAccounts: this.state.stockAccounts,
                                            onCreate: data => {
                                                const originalId = data.id;

                                                let error = false;
                                                this.state.worktasks.filter(wt => {
                                                    if(wt.id != data.id && wt.name.toLowerCase() === data.name.toLowerCase())
                                                        error = true
                                                })
                                                if(data.name == ""){
                                                    this.props.enqueueSnackbar((tr("Enter jobtype ") + "."), {
                                                        variant: "error",
                                                    });
                                                    return;
                                                }
                                                if (!error) {
                                                    DataHandler.post({ url: `settings/company/${company}/jobtype` }, data)
                                                        .done(data => {
                                                            this.jobTypeList.current.removeNewRow(originalId);
                                                            this.getJobTypes(this.state.selectedJobTypeTab);
                                                        })
                                                        .fail(err => {
                                                            console.error(err);
                                                        });
                
                                                }  if (error) {
                                                    this.props.enqueueSnackbar((data.name + tr(" Jobtype already exists") + "."), {
                                                        variant: "error",
                                                    });
                                                    return;
                                                }

                                            },
                                            onUpdate: data => {
                                                DataHandler.put({ url: `settings/company/${company}/jobtype` }, data)
                                                    .done(() => {
                                                        this.getJobTypes(this.state.selectedJobTypeTab);
                                                    })
                                                    .fail(err => {

                                                        if (err.status === 422) {
                                                            this.props.enqueueSnackbar(tr("Cannot archive last jobtype."), {
                                                                variant: "error",
                                                            });

                                                            this.jobTypeList.current.editData({...data, active: "1"}, data.id);
                                                        }

                                                        console.error(err);
                                                    });
                                            },
                                            onDelete: data => {
                                                let archivetext = "";

                                                if(data.id < 1) {
                                                    this.jobTypeList.current.removeNewRow(data.id);
                                                    return;
                                                }

                                                if (data.deleted === "1") {
                                                    archivetext = tr("Do you want to unarchive jobtype");
                                                } else {
                                                    archivetext = tr("Do you want to archive jobtype");
                                                }

                                                this.setState({
                                                    dialogData: {
                                                        id: data.id,
                                                        saveFunc: id => {
                                                            if (data.deleted === "1") {
                                                                data.deleted = "0";
                                                            } else {
                                                                data.deleted = "1";
                                                                data.active = "0";
                                                            }
                                                            DataHandler.put(
                                                                { url: `settings/company/${company}/jobtype` },
                                                                data
                                                            )
                                                                .done(() => {
                                                                    this.getJobTypes(this.state.selectedJobTypeTab);
                                                                })
                                                                .fail(err => {
                                                                    this.props.enqueueSnackbar((tr("Cannot archive last jobtype.")), {
                                                                        variant: "error",
                                                                    });


                                                                    console.error(err);
                                                                });
                                                            },
                                                            text: archivetext + ": " + data.name + "?",
                                                        },
                                                }, this.openDialog("confirmation"));
                                            },
                                        }}
                                        // parentKey="parentId"
                                        newRow={{
                                            id: -1,
                                            name: "",
                                            cost: 0.0,
                                            billable: true,
                                            companies_id: company,
                                            product_id: 0,
                                            active: "1",
                                            deleted: "0",
                                        }}
                                        columns={[
                                            { width: 1, name: "context", header: "", ...dColConf },
                                            { width: 9, name: "name", header: tr("Name"), ...dColConf },
                                            { width: 3, name: "archived", header: "", ...dColConf },
                                            { width: 3, name: "price",  showTitle: true, header: tr("Hourly Rate"), ...dColConf },
                                            { width: 2, name: "active",  showTitle: true, header: tr("active"), ...dColConf },
                                            { width: 3, name: "billable",  showTitle: true, header: tr("Billable"), ...dColConf },
                                            /* 
                                            Elä sie poista näitä. Tarvitaan myöhemmin TAIM9-2114.
                                            { width: 3, name: "starts", header: tr("starts"), ...dColConf },
                                            { width: 3, name: "ends", header: tr("ends"), ...dColConf }, 
                                            */
                                            ...(this.context.addons.procountor_accounting
                                                ? [
                                                    {
                                                        width: 5,
                                                        name: "product_id",
                                                        header: tr("Accounting Product"),
                                                        ...dColConf,
                                                    },
                                                ]
                                                : []
                                            ),
                                            ...(this.context.addons.heeros
                                                ? [
                                                    {
                                                        width: 5,
                                                        name: "product_register_id",
                                                        header: tr("Linked product"),
                                                        ...dColConf,
                                                    },
                                                ]
                                                : []
                                            ),
                                            ...(this.context.addons.nav
                                                ? [
                                                    { width: 4, name: "accounting_sales_account_id", showTitle: true, header: tr("Debit account"), ...dColConf },
                                                    { width: 4, name: "accounting_purchases_account_id",  showTitle: true, header: tr("Credit account"), ...dColConf },
                                                    { width: 4, name: "accounting_stock_account_id",  showTitle: true, header: tr("Stock account"), ...dColConf },
                                                    { width: 3, name: "integration_id", showTitle: true, header: tr("Integration ID"), ...dColConf },
                                                    { width: 2, name: 'use_in_quote', showTitle: true, header: tr("Use in quote"), ...dColConf }
                                                ]
                                                : []
                                            ),
                                            ...(this.context.addons.default_pricelist ? [{ width: 2, name: "in_default_pricelist",  showTitle: true, header: tr("Default pricelist"), ...dColConf }] : []),
                                            { width: 3, name: "editedField", header: "", ...dColConf },
                                        ]}
                                        data={this.state.worktasks}
                                    />
                                </div>
                                {!VersionContentManager.isFeatureHidden(this.namespace, 'professionalTitles') && <div className="settings-feature-wrapper">
                                    <h3>{tr("Professional Titles")}</h3>
                                    <p className="subtitle">{tr("Manage Professional Titles")}</p>
                                    <Button
                                        className="green"
                                        onMouseUp={() => {this.protitlesList.current.addNewRow(); this.setState({pTHeaderVisible:true})}}
                                        size="large"
                                    >
                                        {tr("Add professional title")}
                                    </Button>
                                    <SettingsList
                                        id="protitlesList"
                                        className="settingsList"
                                        innerRef={this.protitlesList}
                                        key={selectedYear}
                                        height="auto"
                                        rowHeight={SettingRow.rowHeight}
                                        hideHeader={( pTHeaderVisible || (this.state.protitles && this.state.protitles.length > 0)) ? false: true}
                                        listRowType={TimeManagementProtitleRow}
                                        showPageSelector={false}
                                        fluid
                                        rowProps={{
                                            onCreate: data => {
                                            /*    if (!data.name) return; */
                                                let error = false;
                                                this.state.protitles.filter(pt => {
                                                    if(pt.id != data.id && pt.name.toLowerCase() === data.name.toLowerCase())
                                                        error = true
                                                })
                                                if(data.name == ""){
                                                    this.props.enqueueSnackbar((tr("Enter professional title ") + "."), {
                                                        variant: "error",
                                                    });
                                                    return;
                                                }
                                                if (!error) {
                                                    return DataHandler.post(
                                                        { url: `settings/company/${company}/protitles` },
                                                        data
                                                    );
                
                                                } if (error) {
                                                    this.props.enqueueSnackbar(( data.name + tr(" Professional title already exists") + "."), {
                                                        variant: "error",
                                                    });
                                                    return;
                                                }
                                            },
                                            onUpdate: data => {
                                                DataHandler.put(
                                                    { url: `settings/company/${company}/protitles/${data.id}` },
                                                    data
                                                ).then(() => {
                                                });
                                            },
                                            onDelete: (data, _, list) => {
                                                if (data.id < 0) {
                                                    list.removeRow(data.id);

                                                    // this.protitlesList.current.emptyNewData();
                                                    // if(this.state.protitles.length == 0){
                                                        // this.setState({pTHeaderVisible:false});
                                                    // } 
                                                    return;
                                                }
                                                this.setState({
                                                    dialogData: {
                                                        id: data.id,
                                                        saveFunc: () => {
                                                            DataHandler.delete({
                                                                url: `settings/company/${company}/protitles/${data.id}`,
                                                            }).then(() => {
                                                                list.removeRow(data.id);
                                                            })
                                                        },
                                                        text:tr("Do you want to delete professional title " + data.name + "?") ,
                                                    },
                                                });
                                                this.openDialog("confirmation");
                                            },
                                        }}
                                        newRow={{
                                            id: -1,
                                            name:"",
                                            price:""
                                        }}
                                        columns={[
                                            { width: 1, name: "delete", header: "", ...dColConf },
                                            { width: 15, name: "name", header: tr("Name"), ...dColConf },
                                            { width: 2, name: "price", header: tr("Hourly Rate"), ...dColConf },
                                        ]}
                                        data={this.state.protitles}
                                    />
                                </div>}
                                <div className="settings-feature-wrapper">
                                    <h3>{tr("Import hour entries")}</h3>
                                    <p className="subtitle">
                                    <span>
                                        {this.tr("Import hour entries XLSX format.")}
                                    </span>
                                    <span>
                                        <a style={{ color: "#2D9FF7", marginLeft: "0.5em" }} href={HourImportTemplate} download>
                                            {this.tr("Download file template")}
                                        </a>.
                                    </span>
                                    </p>
                                    <Button
                                        className="green"
                                        onClick={() => this.setState({ importDialogOpen: true })}
                                        size="large"
                                    >
                                        {tr("Import hour entries")}
                                    </Button>
                                </div>
                            </div>
                        }
                        {this.state.selectedTab === "hourbalance" && (
                            <div className="time-management-hours">
                                <h3>{tr("Manage user hour balance")}</h3>
                                <p className="subtitle">
                                    {tr(
                                        "As an admin you can manage your users hour balance. Choose timespan and user to view hour balance."
                                    )}
                                </p>
                                <div className="time-management-hours-selectors">
                                    <Button
                                        className="timemanagement-button-add"
                                        onMouseUp={() => {this.timeManagementList.current.addNewRow(); this.setState({mUHBHedaerVisible:true})}}
                                        size="large"
                                    >
                                        {tr("Add user hour balance")}
                                    </Button>
                                    <DateRangePicker
                                        className="timemanagement-select-daterange"
                                        ranges={[dateRange]}
                                        onChange={this.onDateChange}
                                        onInputChange={this.onDateInputChange}
                                        label={this.tr("Time span")}
                                        dateFormat={userObject.dateFormat}
                                    />
                                    <OutlinedField
                                        name="user"
                                        select
                                        className="timemanagement-select-daterange"
                                        label={tr("View user")}
                                        value={this.state.selectedUser}
                                        onChange={e => this.onUserChange(e.target.value)}
                                        margin="normal"
                                        shrinkLabel={true}
                                    >
                                        {[{ id: -1, name: tr("Any") }].concat(this.state.users).map(user => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.name}
                                            </MenuItem>
                                        ))}
                                    </OutlinedField>
                                </div>
                                <List
                                    id="timeManagementList"
                                    className="settingsList"
                                    ref={this.timeManagementList}
                                    hideHeader={( mUHBHedaerVisible || (this.state.whData && this.state.whData.length > 0)) ? false: true}
                                    fluid
                                    columns={[
                                        { width: 1, name: "delete", header: "", ...dColConf },
                                        { width: 9, name: "user", header: tr("User"), ...dColConf },
                                        { width: 4, name: "date", header: tr("Date"), ...dColConf },
                                        { width: 4, name: "paydate", header: tr("Signed Off"), ...dColConf },
                                        { width: 3, name: "hours", header: tr("Hours"), ...dColConf },
                                        { width: 7, name: "description", header: tr("Description"), ...dColConf },
                                    ]}
                                    newRow={{
                                        id: -1,
                                        user: -1,
                                        date: format(getTime(new Date()), "YYYY-MM-DD"),
                                        paydate: format(getTime(new Date()), "YYYY-MM-DD"),
                                        hours: "0.00",
                                        description: "",
                                        is_overtime: 0,
                                        deleted: 0
                                    }}
                                    sharedData={this.state.users}
                                    listRowType={TimeManagementSettingRow}
                                    rowProps={{
                                        onCreate: (data) =>{
                                            if (!data.users_id)
                                                return;
                                            DataHandler.put({url: `settings/company/${company}/hour_balance_management`}, {...data})
                                            .done(respData => {
                                                this.timeManagementList.current.emptyNewData();
                                                this.updateWhData();
                                            }).fail(err => {
                                                this.timeManagementList.current.emptyNewData();
                                                this.updateWhData();
                                            });
                                        },
                                        onUpdate: data =>
                                            DataHandler.put({url: `settings/company/${company}/hour_balance_management`}, {...data})
                                            .done(respData => {
                                                this.updateWhData();
                                            }).fail(err => {
                                                this.updateWhData();
                                            }),
                                        onDelete: data => {
                                            data.deleted = 1;
                                            this.setState({
                                                dialogData: {
                                                    id: data.id,
                                                    saveFunc: () =>
                                                    DataHandler.put({url: `settings/company/${company}/hour_balance_management`}, {...data})
                                                    .done(respData => {
                                                        this.updateWhData();
                                                    }).fail(err => {
                                                        this.updateWhData();
                                                    }),
                                                    text:tr("Do you want to delete  " + data.fullname +  " hour balance ?") ,
                                                },
                                            });
                                            this.openDialog("confirmation");
                                        }, 
                                        returnTab: this.state.selectedTab
                                    }}
                                    data={this.state.whData}
                                />
                            </div>
                        )}
                        {this.state.selectedTab === "overtime" && (
                            <div>
                                <h3>{tr("overtime hours")}</h3>
                                <p className="subtitle">
                                    {tr("enable the toggle button to allow users to track hours as overtime.")}
                                </p>
                                <SettingsGrid
                                    settings={settingsOvertimeHours}
                                    item={this.state.overtimeSettings}
                                    onChange={overtimeSettings => {
                                        if (overtimeSettings.activate_overtime_tracking == "0") {
                                            overtimeSettings.overtime_description_mandatory = 0;
                                            overtimeSettings.approve_overtime_hours_before_billing = 0;
                                            overtimeSettings.overtime_calculate_daily_hours_before = 0;
                                        }

                                        DataHandler.put(
                                            { url: `settings/company/${company}/overtime` },
                                            overtimeSettings
                                        ).then(response => {
                                            this.setState({ overtimeSettings });
                                        });
                                    }}
                                />

                                <div className="settings-feature-wrapper">

                                    <h3>{tr("Overtime Multipliers")}</h3>
                                    <p className="subtitle">
                                        {tr("select multipliers for overtime balance.")}
                                    </p>

                                    <OvertimeMultipliers company={company} />
                                </div>

                                <div className="settings-feature-wrapper">
                                    <h3>{tr("Overtime hour balance")}</h3>
                                    <p className="subtitle">{tr("As a manager you can adjust users overtime hour balance.")}</p>
                                    <div className="time-management-overtime-selectors" >
                                        <Button
                                            className="timemanagement-button-add"
                                            onMouseUp={() => { this.overtimeManagementList.current.addNewRow(); this.setState({ oHBHedaerVisible: true }) }}
                                            size="large"
                                        >
                                            {tr("Add overtime hour balance")}
                                        </Button>
                                        <DateRangePicker
                                            className="timemanagement-select-daterange"
                                            ranges={[dateRange]}
                                            onChange={this.onDateChange}
                                            onInputChange={this.onDateInputChange}
                                            label={this.tr("Time span")}
                                            dateFormat={userObject.dateFormat}
                                        />
                                        <OutlinedField
                                            name="user"
                                            select
                                            className="timemanagement-select-daterange"
                                            label={tr("View user")}
                                            value={this.state.selectedUser}
                                            onChange={e => this.onUserChange(e.target.value)}
                                            margin="normal"
                                            shrinkLabel={true}
                                        >
                                            {[{ id: -1, name: tr("Any") }].concat(this.state.users).map(user => (
                                                <MenuItem key={user.id} value={user.id}>
                                                    {user.name}
                                                </MenuItem>
                                            ))}
                                        </OutlinedField>
                                    </div>
                                    <List
                                        id="overtimeManagementList"
                                        className="settingsList"
                                        ref={this.overtimeManagementList}
                                        hideHeader={(oHBHedaerVisible || (this.state.overtimeManagementData && this.state.overtimeManagementData.length > 0)) ? false : true}
                                        fluid
                                        height="auto"
                                        columns={[
                                            { width: 1, name: "delete", header: "", ...dColConf },
                                            { width: 9, name: "user", header: tr("User"), ...dColConf },
                                            { width: 4, name: "date", header: tr("Date"), ...dColConf },
                                            { width: 4, name: "paydate", header: tr("Signed Off"), ...dColConf },
                                            { width: 3, name: "hours", header: tr("Hours"), ...dColConf },
                                            { width: 7, name: "description", header: tr("Description"), ...dColConf },
                                        ]}
                                        newRow={{
                                            id: -1,
                                            user: -1,
                                            date: format(getTime(new Date()), userObject.dateFormat),
                                            paydate: format(getTime(new Date()), userObject.dateFormat),
                                            hours: "0.00",
                                            description: "",
                                            is_overtime: 1,
                                            deleted: 0
                                        }}
                                        sharedData={this.state.users}
                                        listRowType={TimeManagementSettingRow}
                                        rowProps={{
                                            onCreate: (data) => {
                                                if (!data.users_id)
                                                    return;

                                                DataHandler.put({ url: `settings/company/${company}/hour_balance_management` }, { ...data })
                                                    .done(respData => {
                                                        this.overtimeManagementList.current.emptyNewData();
                                                        this.getOvertimeManagementData();
                                                    }).fail(err => {

                                                    });
                                            },

                                            onUpdate: data =>
                                                DataHandler.put({ url: `settings/company/${company}/hour_balance_management` }, { ...data })
                                                    .done(respData => {
                                                        this.getOvertimeManagementData();
                                                    }).fail(err => {

                                                    }),
                                            onDelete: data => {
                                                data.deleted = 1;
                                                this.setState({
                                                    dialogData: {
                                                        id: data.id,
                                                        saveFunc: () =>
                                                            DataHandler.put({ url: `settings/company/${company}/hour_balance_management` }, { ...data })
                                                                .done(respData => {
                                                                    this.getOvertimeManagementData();
                                                                }).fail(err => {

                                                                }),
                                                        text: tr("Do you want to delete  " + data.fullname + " overtime hour balance ?"),
                                                    },
                                                });
                                                this.openDialog("confirmation");
                                            },
                                            returnTab: this.state.selectedTab
                                        }}
                                        data={this.state.overtimeManagementData}

                                    />
                                </div>
                            </div>
                        )}
                        </React.Fragment>
                    }
                {Dialog && (
                    <Dialog
                        open
                        onDialogClose={this.closeDialog}
                        onDialogSave={this.confirmDialog}
                        data={this.state.dialogData}
                    />
                )}
                {importDialogOpen &&
                    <ImportHourEntriesDialog
                        onClose={() => this.setState({ importDialogOpen: false })}
                        company={company}
                        descriptionRequired={timeTrackerSettings?.hour_entry_description == 1}
                    />}
            </div>
        );
    }
}

export default withSnackbar(TimeManagement);
