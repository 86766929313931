import React from 'react';

/* css */
import './TabGoals.css';

/* material-ui */
import { ShowChart, PieChart, Payment, MonetizationOn } from '@mui/icons-material';

/* other */
import TaimerComponent from '../TaimerComponent';
import moment from 'moment/min/moment-with-locales';

/* context */
import { SettingsContext } from '../SettingsContext';
import PageTopSection from '../general/PageTopSection';
import GoalsTable from './GoalsTable';

import DataHandler from '../general/DataHandler';
import { withSnackbar } from 'notistack';
import InsightDropDown from '../dashboard/insights/InsightDropDown';
import WithTabs from '../navigation/WithTabs';
import { ReactComponent as Loading } from '../dashboard/insights/img/loading.svg';

const SETTINGS_KEY = 'goals_last_settings_';
const GOAL_KEY = 'goals_selected_tab_';

export const GOAL_TYPES = {
    target: 1,
    commitment: 2,
    forecast: 3,
};

export const GOAL_IDS = {
    gross_profit: 3,
    gross_margin: 4,
    net_profit: 5,
    net_margin: 6,
};

class TabGoals extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'accounts/TabGoals');

        const { tr } = this;
        this.tableContainerEnd = React.createRef();

        this.goals = [
            {
                id: GOAL_IDS.gross_profit,
                key: 'gross_profit',
                label: tr('Gross Profit'),
                description: this.tr('Gross Profit formula: Invoiced sum deducted by approved bills, expenses and travel expenses.'),
            },
            {
                id: GOAL_IDS.net_profit,
                key: 'net_profit',
                label: tr('Net Profit'),
                description: this.tr('Net Profit formula: Invoiced sum deducted by hourly own costs, approved bills, expenses and travel expenses'),
            },
            {
                id: GOAL_IDS.gross_margin,
                key: 'gross_margin',
                label: tr('Gross Margin'),
                description: this.tr('Gross Margin formula: Invoiced sum deducted by approved bills, expenses and travel expenses.'),
            },
            {
                id: GOAL_IDS.net_margin,
                key: 'net_margin',
                label: tr('Net Margin'),
                description: this.tr('Net Margin formula: Budgeted costs on sales quote deducted by hourly own costs, approved bills, expenses and travel expenses.'),
            },
        ];

        this.viewModes = [
            {
                key: 'currency',
                label: tr('Currency'),
                action: () => this.setState({ selectedViewMode: 'currency' }),
            },
            {
                key: 'percentage',
                label: tr('Percentage'),
                action: () => this.setState({ selectedViewMode: 'percentage' }),
            },
        ];

        const selectedGoal = this.getSelectedGoalFromLS();
        const savedSettings = this.parseSettingsFromData(this.getSettingsFromLS(), selectedGoal, -1);

        this.state = {
            data: {},
            currency: context.taimerAccount.currency,
            useCommitment: null,
            dates: this.formFinancialYearsArray(),
            selectedGoals: [],
            loading: true,
            selectedYear: this.getSelectedYear(this.context.taimerAccount.financialYearStart),
            selectedGoal,
            projectTypes: [],
            selectedProjectTypes: [],
            selectedProjectType: '-1',
            selectedColumns: [],
            selectedViewMode: 'currency',
            ...savedSettings,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getSettingsAndData();
        this.getProjectTypes();
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.company !== this.props.company) {
            let selectedGoal = this.getSelectedGoalFromLS();
            this.setState({ 
                selectedGoal,
            }, () => {
                this.getSettingsAndData();
                this.getProjectTypes();
            });
        }
    }

    getSelectedYear = (financialYearStart) => {
        let start = financialYearStart || this.context.taimerAccount.financialYearStart;
        const today = moment();
        let selectedYear = today.format("YYYY");
        const financialStart = moment(`${start} ${selectedYear}`, 'M YYYY');
        if (financialStart.isAfter(today, 'month')) {
            selectedYear = moment().subtract(1, 'year').format("YYYY");
        }
        return selectedYear;
    }

    getFinancialYears = () => {
        DataHandler.get({
            url: `accounts/${this.props.account.id}/company/${this.props.company}/financialyears`,
        })
            .done((res) => {
                this.setState({
                    dates: this.formFinancialYearsArray(res.financial_years || []),
                });
            })
            .fail((e) => console.log(e));
    };

    formFinancialYearsArray = (years = [], financialYearStart = this.context.taimerAccount.financialYearStart) => {
        let currentYear = Number(moment().format('YYYY'));
        let nextYear = Number(moment().add(1, 'year').format('YYYY'));
        const financialStart = moment(`${financialYearStart} ${currentYear}`, 'M YYYY');
        if (financialStart.isAfter(moment(), 'month')) {
            currentYear = Number(moment().subtract(1, 'year').format("YYYY"));
            nextYear = Number(moment().format('YYYY'));
        }
        const dates = {
            [currentYear]: {
                label: `${currentYear}-${currentYear + 1}`,
                months: [],
            },
            [nextYear]: {
                label: `${nextYear}-${nextYear + 1}`,
                months: [],
            },
        };

        years.forEach((y) => {
            dates[y] = {
                label: `${Number(y)}-${Number(y) + 1}`,
                months: [],
            };
        });

        Object.keys(dates).forEach((year) => {
            const startDate = moment(`${financialYearStart} ${year}`, 'M YYYY');
            const months = [];
            for (let i = 0; i < 12; i++) {
                const date = moment(startDate).add(i, 'month');
                months.push(date.format('YYYY-MM-DD'));
            }
            dates[year].months = months;
        });
        return dates;
    };

    getProjectTypes = () => {
        DataHandler.get({
            url: `subjects/project_types/${this.props.company}`,
        }).done((projectTypes) => {
            this.setState({ projectTypes });
        });
    };

    getSettingsAndData = () => {
        this.setState({ loading: true }, () => {
            const promises = [
                DataHandler.get({url: `settings/company/${this.props.company}/goals`}),
                DataHandler.get({url: `settings/company/${this.props.company}/defaults`}), 
                DataHandler.get({url: `accounts/${this.props.account.id}/company/${this.props.company}/financialyears`})
            ];
            
            Promise.all(promises).then(responses => {
                const goalsSettings = responses[0];
                this.saveSettingsToLS(goalsSettings);
                const settings = this.parseSettingsFromData(goalsSettings);
                const companySettings = responses[1];
                const financialYearStart = companySettings?.financial_year_start;
                const financialYears = responses[2]?.financial_years || [];
                const dates = this.formFinancialYearsArray(financialYears, financialYearStart);
                const selectedYear = this.getSelectedYear(financialYearStart);
                this.setState({
                    ...settings,
                    selectedYear,
                    dates
                }, () => {
                    this.getData();
                })
            }).catch(err => {
                console.log(err);
                this.setState({
                    dates: this.formFinancialYearsArray(),
                    selectedYear: this.getSelectedYear(),
                }, () => {
                    this.getData()
                });
            });
        });
    };

    saveSettingsToLS = (settings) => {
        try {
            localStorage.setItem(SETTINGS_KEY+this.props.company, JSON.stringify(settings));
        } catch (e) {
            console.log(e);
        }
    };

    getSettingsFromLS = () => {
        let settings = {};
        try {
            settings = JSON.parse(localStorage.getItem(SETTINGS_KEY+this.props.company) || {});
        } catch (e) {
            console.log(e);
        }
        return settings;
    };

    saveSelectedGoalToLS = (selectedGoal) => {
        try {
            localStorage.setItem(GOAL_KEY+this.props.company, JSON.stringify(selectedGoal));
        } catch (e) {
            console.log(e);
        }
    };

    getSelectedGoalFromLS = () => {
        let selectedGoal;
        try {
            selectedGoal = JSON.parse(localStorage.getItem(GOAL_KEY+this.props.company));
        } catch (e) {
            console.log(e);
        }
        return selectedGoal;
    };

    parseSettingsFromData = (response, previousSelectedGoal = this.state.selectedGoal, previousSelectedProjectType = this.state.selectedProjectType) => {
        if (!response) return null;
        const selectedProjectTypes = response.project_types ? response.project_types.split(',') : [];
        const foundProjectType = [...selectedProjectTypes].find((pt) => pt == previousSelectedProjectType);
        let selectedProjectType = (response.project_types && response.project_types.length > 0) ? -2 : -1;
        
        if (foundProjectType) {
            selectedProjectType = foundProjectType;
        }
        const useCommitment = response.activate_commitment == 1;
        const selectedGoals = [];
        if (response.gross_profit == 1 || response.profit == 1) {
            selectedGoals.push(this.goals.find((g) => g.id == GOAL_IDS.gross_profit));
        }
        if (response.net_profit == 1) {
            selectedGoals.push(this.goals.find((g) => g.id == GOAL_IDS.net_profit));
        }
        if (response.gross_margin == 1 || response.margin == 1) {
            selectedGoals.push(this.goals.find((g) => g.id == GOAL_IDS.gross_margin));
        }
        if (response.net_margin == 1) {
            selectedGoals.push(this.goals.find((g) => g.id == GOAL_IDS.net_margin));
        }
        let selectedGoal = selectedGoals[0];
        if (selectedGoals.findIndex((g) => g.id == (previousSelectedGoal || {}).id) != -1) {
            selectedGoal = previousSelectedGoal;
        }
        return {
            useCommitment,
            selectedGoals,
            selectedGoal,
            selectedProjectTypes,
            selectedProjectType,
        };
    };

    saveTableData = (header, editedRows) => {
        const { enqueueSnackbar } = this.props;
        this.setState({ loading: true }, () => {
            const rows = this.state.dates[this.state.selectedYear].months.reduce((map, month) => {
                map[month] = editedRows[month] || 0;
                return map;
            }, {});
            const data = {
                header,
                rows,
            };
            DataHandler.post(
                {
                    url: `accounts/${this.props.account.id}/company/${this.props.company}/goals/${this.state.selectedGoal.id}/project_type/${this.state.selectedProjectType}`,
                },
                data
            )
                .done((response) => {
                    if (header.type == GOAL_TYPES.forecast && this.state.selectedForecast.id == -1) {
                        this.setState(
                            {
                                selectedForecast: {
                                    ...this.state.selectedForecast,
                                    id: response.id || -1,
                                },
                            },
                            () => {
                                setTimeout(() => {
                                    this.getData();
                                }, 1000);
                            }
                        );
                    } else {
                        setTimeout(() => {
                            this.getData();
                        }, 1000);
                    }
                    enqueueSnackbar(this.tr('Changes saved successfully!'), {
                        variant: 'success',
                    });
                })
                .fail((error) => {
                    enqueueSnackbar(this.tr('Saving changes failed!'), {
                        variant: 'error',
                    });
                });
        });
    };

    getData = () => {
        if (!this.state.selectedGoal) {
            this.setState({
                loading: false,
            });
            return;
        }
        this.setState({ loading: true }, () => {
            const types = [GOAL_TYPES.target, GOAL_TYPES.forecast];

            if (this.state.useCommitment) {
                types.push(GOAL_TYPES.commitment);
            }

            DataHandler.get({
                url: `accounts/${this.props.account.id}/company/${this.props.company}/goals/${this.state.selectedGoal.id}/project_type/${this.state.selectedProjectType}/start_date/${
                    this.state.dates[this.state.selectedYear].months[0]
                }`,
                types: types.join(','),
            }).done((response) => {
                const goals = response.goals;
                const target = goals.find((g) => g.type == GOAL_TYPES.target);
                const commitment = goals.find((g) => g.type == GOAL_TYPES.commitment);
                const forecasts = goals.filter((g) => g.type == GOAL_TYPES.forecast).sort((a, b) => Number(b.id) - Number(a.id));
                let selectedForecast = forecasts[0];
                if (this.state.selectedForecast?.id == -1 && this.state.selectedProjectType != -2) {
                    // means a new forecast is still in creation
                    selectedForecast = this.state.selectedForecast;
                }
                const previousForecastIndex = forecasts.findIndex((f) => f.id == (this.state.selectedForecast || {}).id);
                if (previousForecastIndex != -1) {
                    selectedForecast = forecasts[previousForecastIndex];
                }
                const values = response[this.state.selectedGoal.key] || {};
                this.setState({
                    loading: false,
                    selectedForecast,
                    data: {
                        target,
                        commitment,
                        forecasts,
                        [this.state.selectedGoal.key]: values,
                    },
                });
            });
        });
    };

    setSelectedGoal = (selectedGoal) => {
        this.saveSelectedGoalToLS(selectedGoal);
        this.setState({ selectedGoal }, () => {
            this.getData();
        });
    };

    setSelectedForecast = (selectedForecast) => {
        this.setState({ selectedForecast });
    };

    onCancelForecast = () => {
        let forecast;
        if ((this.state.data.forecasts || []).length > 0) {
            forecast = this.state.data.forecasts[0];
        }
        this.setSelectedForecast(forecast);
    };

    getCommonTableProps = () => {
        const { currency, dates, selectedYear, selectedViewMode } = this.state;
        return {
            currency,
            months: this.getMonths(),
            onSaveData: this.saveTableData,
            start_date: dates[selectedYear].months[0],
            selectedViewMode,
        };
    };

    renderExtraTables = () => {
        const { selectedColumns } = this.state;
        const columns = this.getDropdownColumns();
        const tables = selectedColumns.map((column) => {
            const fullColumn = columns.find((c) => c.name == column) || {};
            return (
                <GoalsTable
                    {...this.getCommonTableProps()}
                    editTitle={fullColumn.header}
                    noEdit={true}
                    columns={[{ title: fullColumn.header, align: 'right' }]}
                    dataUrl={fullColumn.dataUrl || 'test'}
                />
            );
        });
        return tables;
    };

    getMonths = () => {
        const { dates, selectedYear } = this.state;
        return dates[selectedYear].months;
    };

    setSelectedYear = (selectedYear) =>
        this.setState({ selectedYear }, () => {
            this.getData();
        });
    setSelectedProjectType = (selectedProjectType) =>
        this.setState({ selectedProjectType }, () => {
            this.getData();
        });

    getSummaryTableColumns = (mainColumn, fromKey) => {
        const { useCommitment, data } = this.state;
        const { checkPrivilege } = this.props;

        return [
            mainColumn,
            ...(checkPrivilege('customers', 'customer_budgets_target_read', this.props.company)
                ? [
                      {
                          title: this.tr('Cumulative Target'),
                          align: 'right',
                          cumulative: true,
                          cumulative_to: 'target',
                          cumulative_from: fromKey,
                      },
                  ]
                : []),
            ...(useCommitment && checkPrivilege('customers', 'customer_budgets_commitment_read', this.props.company)
                ? [
                      {
                          title: this.tr('Cumulative Commitment'),
                          align: 'right',
                          cumulative: true,
                          cumulative_to: 'commitment',
                          cumulative_from: fromKey,
                      },
                  ]
                : []),
            ...(data.forecasts && data.forecasts.length > 0 && checkPrivilege('customers', 'customer_budgets_forecast_read', this.props.company)
                ? [
                      {
                          title: this.tr('Cumulative Forecast'),
                          align: 'right',
                          cumulative: true,
                          cumulative_to: 'forecast',
                          cumulative_from: fromKey,
                      },
                  ]
                : []),
        ];
    };

    renderBasicTables = () => {
        const { tr } = this;
        const { selectedGoal, selectedForecast, data, useCommitment } = this.state;
        const { checkPrivilege } = this.props;
        if (!selectedGoal) return null;
        const commonProps = this.getCommonTableProps();
        return (
            <>
                {checkPrivilege('customers', 'customer_budgets_target_read', this.props.company) && (
                    <GoalsTable
                        {...commonProps}
                        editTitle={this.tr('Edit Target')}
                        showMonths
                        noEdit={!checkPrivilege('customers', 'customer_budgets_target_write', this.props.company) || this.state.selectedProjectType == '-2'}
                        columns={[{ title: tr('Target'), align: 'right' }]}
                        type={GOAL_TYPES.target}
                        isTotal={(this.state.selectedProjectType == '-2') ? true : false}
                        {...data.target}
                    />
                )}
                {useCommitment && checkPrivilege('customers', 'customer_budgets_commitment_read', this.props.company) && (
                    <GoalsTable
                        {...commonProps}
                        showMonths={!checkPrivilege('customers', 'customer_budgets_target_read', this.props.company)}
                        editTitle={this.tr('Edit Commitment')}
                        type={GOAL_TYPES.commitment}
                        noEdit={!checkPrivilege('customers', 'customer_budgets_commitment_write', this.props.company) || this.state.selectedProjectType == '-2'}
                        columns={[{ title: tr('Commitment'), align: 'right' }]}
                        isTotal={(this.state.selectedProjectType == '-2') ? true : false}
                        {...data.commitment}
                    />
                )}
                {checkPrivilege('customers', 'customer_budgets_forecast_read', this.props.company) && (
                    <GoalsTable
                        {...commonProps}
                        showMonths={!checkPrivilege('customers', 'customer_budgets_target_read', this.props.company) && !(useCommitment && checkPrivilege('customers', 'customer_budgets_commitment_read', this.props.company))}
                        editTitle={this.tr('Edit Forecast')}
                        createTitle={this.tr('Create New Forecast')}
                        onCreate={this._createNewForecast}
                        type={GOAL_TYPES.forecast}
                        isForecast
                        noEdit={!checkPrivilege('customers', 'customer_budgets_forecast_write', this.props.company) || this.state.selectedProjectType == '-2'}
                        onCancelForecast={this.onCancelForecast}
                        selectedForecast={selectedForecast}
                        forecasts={data.forecasts}
                        onChangeForecast={this.setSelectedForecast}
                        isTotal={(this.state.selectedProjectType == '-2') ? true : false}
                        columns={(this.state.selectedProjectType == '-2') ? [{ title: tr('Forecast'), align: 'right' }] : [{ title: selectedForecast?.title, align: 'right' }]}
                        {...selectedForecast}
                    />
                )}
                <GoalsTable
                    {...commonProps}
                    editTitle={''}
                    showMonths={!checkPrivilege('customers', 'customer_budgets_target_read', this.props.company) && !(useCommitment && checkPrivilege('customers', 'customer_budgets_commitment_read', this.props.company)) && !(selectedForecast && checkPrivilege('customers', 'customer_budgets_forecast_read', this.props.company))}
                    mainColumn={selectedGoal.key}
                    columns={this.getSummaryTableColumns(
                        {
                            title: selectedGoal.label,
                            key: selectedGoal.key,
                            align: 'right',
                        },
                        selectedGoal.key
                    )}
                    summaryTable
                    data={{
                        [selectedGoal.key]: data[selectedGoal.key],
                        target: data.target?.rows,
                        commitment: data.commitment?.rows,
                        forecast: selectedForecast ? selectedForecast.rows : {},
                    }}
                />
            </>
        );
    };

    renderTables = () => {
        const { dates, selectedYear, projectTypes, selectedProjectType, selectedProjectTypes, selectedViewMode, selectedGoal } = this.state;
        const allProjectTypes = [
            { id: '-2', label: this.tr('All tracked') },
            ...selectedProjectTypes.map((spt) => {
                return (
                    projectTypes.find((pt) => pt.id == spt) || {
                        id: spt,
                        label: '',
                    }
                );
            }),
        ];
        return (
            <div>
                {selectedGoal?.description && <p className="desc-text">{selectedGoal?.description}</p>}
                <div className="filters">
                    <div className="left">
                        <InsightDropDown
                            title={this.tr('Fiscal year')}
                            disabled={this.state.loading}
                            tabs={Object.keys(dates).map((year) => ({
                                ...dates[year],
                                key: year,
                                action: () => this.setSelectedYear(year),
                            }))}
                            selected={selectedYear}
                        />
                        {(allProjectTypes || []).length > 1 && (
                            <InsightDropDown
                                title={this.tr('Project type')}
                                disabled={this.state.loading}
                                tabs={allProjectTypes.map((pt) => ({
                                    ...pt,
                                    key: pt.id,
                                    action: () => this.setSelectedProjectType(pt.id),
                                }))}
                                selected={selectedProjectType}
                            />
                        )}
                    </div>
                    <InsightDropDown disabled={this.state.loading} title={this.tr('Value')} selected={selectedViewMode} tabs={this.viewModes} />
                </div>
                <div className="tables" style={{ opacity: this.state.loading ? 0.5 : 1 }}>
                    {this.renderBasicTables()}
                    {this.renderExtraTables()}
                    <div ref={this.tableContainerEnd} />
                </div>
            </div>
        );
    };

    getDropdownColumns = () => {
        switch (this.state.selectedGoal?.key) {
            case 'gross_profit':
                return [
                    {
                        name: 'invoiced',
                        header: this.tr('Invoiced'),
                    },
                    {
                        name: 'invoicing_material',
                        header: this.tr('Invoicing Material'),
                    },
                    {
                        name: 'sales',
                        header: this.tr('Sales'),
                    },
                    {
                        name: 'pipeline',
                        header: this.tr('Pipeline'),
                    },
                    {
                        name: 'py_actual_gross_profit',
                        header: this.tr('PY Actual Gross Profit'),
                    },
                    {
                        name: 'py_target',
                        header: this.tr('PY Target'),
                    },
                    {
                        name: 'py_commitment',
                        header: this.tr('PY Commitment'),
                    },
                ];
            case 'gross_margin':
                return [
                    {
                        name: 'bills',
                        header: this.tr('Bills'),
                    },
                    {
                        name: 'expenses',
                        header: this.tr('Expenses'),
                    },
                    {
                        name: 'py_actual_gross_margin',
                        header: this.tr('PY Actual Gross Margin'),
                    },
                    {
                        name: 'py_target',
                        header: this.tr('PY Target'),
                    },
                    {
                        name: 'py_commitment',
                        header: this.tr('PY Commitment'),
                    },
                ];
            default:
                return [];
        }
    };

    toggleColumn = (column) => {
        const selectedColumns = [...this.state.selectedColumns];
        const index = selectedColumns.indexOf(column.name);
        index == -1 ? selectedColumns.push(column.name) : selectedColumns.splice(index, 1);
        this.setState({ selectedColumns }, () => {
            if (index == -1 && this.tableContainerEnd.current) {
                this.tableContainerEnd.current.scrollIntoView({ behavior: 'smooth' });
            }
        });
    };

    _createNewForecast = () => {
        const { data, selectedGoal, selectedForecast } = this.state;
        const rows = {};
        const totalData = data[selectedGoal.key] || {};
        Object.keys(totalData).forEach((month) => {
            if (moment(month, 'YYYY-MM-DD').isBefore(moment(), 'month') && Number(totalData[month]).toFixed(2) != 0) {
                rows[month] = Number(totalData[month]).toFixed(2);
            } else {
              rows[month] = selectedForecast?.rows[month] || 0;
            }
        });
        this.setSelectedForecast({
            id: -1,
            title: '',
            rows,
        });
    };

    render() {
        const { tr } = this;
        const { selectedGoal, loading, useCommitment, selectedForecast, selectedProjectType } = this.state;

        let content = this.renderTables();

        // No settings loaded previously, show only loading indicator.
        if (useCommitment == null) {
            content = <Loading className="loading" />;
        }

        // No goals selected in settings. Should never happen as you can't remove all the selected goals from settings.
        if (!selectedGoal && !loading) {
            content = this.tr('No trackable goals selected. Please go to settings and select at least one goal you wish to track.');
        }

        return (
            <div id="accounts-goals" className="content">
                <WithTabs containerPadding={24} disabled={this.state.loading} tabs={this.state.selectedGoals.map((g) => ({
                        ...g,
                        action: () => this.setSelectedGoal(g),
                    }))} selectedTab={selectedGoal?.id}>
                        {() => {
                            return content
                        }}
                </WithTabs>
            </div>
        );
    }
}

export default withSnackbar(TabGoals);
