import React, { Component } from 'react';
import cn from 'classnames';
import { SwatchesPicker } from 'react-color';
import { Portal } from '@mui/base';
import { getElementOffset } from '../../helpers';
import TaimerComponent from '../../TaimerComponent';
import { SettingsContext } from '../../SettingsContext';
import { getColorArray } from '../../helpers';

export default class ColorBall extends TaimerComponent {
    static contextType = SettingsContext;
	constructor(props, context)
	{
		super(props, context, "workhours/time-tracker/ColorBall");


		this.state = {
            showTooltip: false,
            visible: false,
		}

        this.refBall = React.createRef();
        this.refPopup = React.createRef();
    }
    
    outsideClick = (e) => {
        if (!this.state.visible || !this.refPopup.current)
            return;

        const parent = this.refBall.current;

        if (parent.contains(e.target))
                return;

        const el = this.refPopup.current;

        if (el.contains(e.target))
            return;

        setTimeout( () => this.setState({visible: false}), 100);
    }

	componentDidMount = () => {    
        document.addEventListener("click", this.outsideClick);
    }

    componentWillUnmount = () => {
        document.removeEventListener("click", this.outsideClick);
    }
    
    handleChange = (color) => {
        this.setState({visible: false});

        this.props.onChangeColor(color.hex);
    }

    onClick = (e) => {
        if (this.props.disabled && this.props.disabled == true) {
            return;
        }
        
        const el = this.refBall.current;
        const position = getElementOffset(el);

        this.setState({position: { left: position.left + 'px', top: (position.top + el.offsetHeight) + 'px' }, visible: !this.state.visible})
    }

	render() {
        const { position, visible } = this.state;

		return (<div ref={this.refBall} className="rbc-ball-cont">
            <span title={this.tr("Change the colour")} className={cn("rbc-rec", this.props.className)} onClick={this.onClick}></span>
            {visible && <Portal><div ref={this.refPopup} className="ColorChangePopup" style={position}>
                <SwatchesPicker width={322} height={206} colors={getColorArray()} onChangeComplete={ this.handleChange } />
            </div></Portal>}
        </div>);
	}
}