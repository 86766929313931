import { ArrowBack, CloudUpload, DescriptionOutlined, InsertPhotoOutlined, NoteAddOutlined, Remove, Subject } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import FileSaver from 'file-saver';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment/min/moment-with-locales';
import React from 'react';

import TaimerComponent from '../../TaimerComponent';
import { ArrangementList, ArrangementListDrawer } from './sections/ArrangementList';
import BlankSection from './sections/BlankSection';
import CoverPage from './sections/CoverPage';
import PDFSection from './sections/PDFSection';
import { Person, ProposalContentBlockOptions, ProposalContentSection, ProposalContentSectionType, ProposalSectionOptions } from './types';

import { withSnackbar, WithSnackbarProps } from 'notistack';
import DataHandler from '../../general/DataHandler';
import LoaderButton from '../../general/LoaderButton';
import { WizardPageContentObject } from '../../general/TaimerWizard';
import WizardPageContentRenderer from '../../general/WizardPageContentRenderer';
import Utils from './../../general/Utils';
import AddContentButton from './AddContentButton';
import { AddTemplateDialog } from './AddTemplateDialog';
import styles from './ProposalEditor.module.css';
import ProposalSection from './sections/ProposalSection';
import QuoteSection from './sections/QuoteSection';
import { SelectTemplateDialog } from './SelectTemplateDialog';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

interface Proposal {
    id: string;
    companies_id?: string;
    content: ProposalContentSection[];
}

interface Props extends WithSnackbarProps {
    project?: any;
    quote?: any;
    checkPrivilege?: (group: string, right: string, company?: string) => boolean;
    template?: Proposal;
    quoteHTML?: Element;
    refreshQuoteData: () => void;
    company?: string;
    printLanguage?: string;
    printDateFormat?: string;
    showLockedUsersWithTag?: boolean;
    onProposalLoaded?: () => void;
    noTopBar?: boolean;
    afterCreated?: (id: string) => void;
    receiverId?: string;
    senderId?: string;
    overrideReceiver?: any;
    overrideSender?: any;
    onboardingContent?: WizardPageContentObject;
}

interface State {
    proposal: Proposal;
    employees: Person[];
    contacts: Person[];
    templateDialogOpen: boolean;
    addTemplateDialogOpen: boolean;
    generatingPDF: boolean;
    loadingProposal: boolean;
    loadingBGImage: boolean;
    loadingPDFIndex?: number;
    templateType?: ProposalContentSectionType;
    contentChanged: boolean;
    saving: boolean;
}

class ProposalEditor extends TaimerComponent<Props, State> {
    static defaultProps = {
        showLockedUsersWithTag: true,
    };

    sectionOptions: ProposalSectionOptions;
    contentBlockOptions: ProposalContentBlockOptions;
    sectionIcons: any;
    blockIcons: any;
    _pageRefs: any = {};
    _pdfInput: React.RefObject<HTMLInputElement> = React.createRef();
    _proposalContent: React.RefObject<HTMLDivElement> = React.createRef();
    _addPDFIndex = 0;
    _templateSectionIndex = 0;
    _newTemplateSectionIndex = 0;
    _swapTemplateContent = false;
    _removeAttachmentRequests: any = [];
    userTypeAutocompleteClasses: any[] = ['employees'];
    translations: any = {};
    logoRendered = false;
    pdfRendered = false;
    renderedPDFSections: string[] = [];

    constructor(props, context) {
        super(props, context, 'projects/ProposalEditor');

        const { quote, project } = this.props;

        this.sectionIcons = {
            cover: <DescriptionOutlined />,
            blank: <NoteAddOutlined />,
            pdf: <CloudUpload />,
        };

        this.blockIcons = {
            text: <Subject />,
            image: <InsertPhotoOutlined />,
            separator: <Remove />,
        };

        this.sectionOptions = {
            cover: {
                type: 'cover',
                key: '',
                title: this.tr('Cover Page'),
                content: {
                    title: project?.name,
                    subtitle: '',
                    startDate: project?.startdate,
                    endDate: project?.enddate,
                    sent: moment().format('YYYY-MM-DD'),
                    validUntil: moment().add(1, 'month').format('YYYY-MM-DD'),
                    createdBy: this.props.senderId,
                    preparedFor: this.props.receiverId,
                    overrideSender: this.props.overrideSender,
                    overrideReceiver: this.props.overrideReceiver,
                },
            },
            blank: {
                type: 'blank',
                key: '',
                title: this.tr('Blank Page'),
                content: [],
            },
            pdf: {
                type: 'pdf',
                key: '',
                title: this.tr('PDF File'),
                content: null,
            },
        };

        this.contentBlockOptions = {
            text: {
                type: 'text',
                title: this.tr('Text'),
                key: '',
                content: '',
            },
            image: {
                type: 'image',
                key: '',
                title: this.tr('Image'),
                content: null,
            },
            separator: {
                type: 'separator',
                key: '',
                title: this.tr('Separator'),
                content: null,
            },
        };

        this.translations = {
            locked: this.tr('locked'),
            freelancer: this.tr('freelancer'),
        };

        // @ts-ignore TODO: tarkista
        const defaultContent: ProposalContentSection[] = [{ ...cloneDeep(this.sectionOptions.cover), key: 'cover_0' }];

        if (this.props.quoteHTML) {
            defaultContent.push({
                type: 'quote',
                key: 'quote',
                title: `${this.tr('Quote')} - ${this.props.quote?.name}`,
                content: this.props.quoteHTML,
            });
        }

        this.state = {
            proposal: this.props.template || {
                id: quote.proposals_id || '-1',
                content: !quote.proposals_id || quote.proposals_id == '-1' ? defaultContent : [],
            },
            generatingPDF: false,
            contentChanged: quote && !quote.proposals_id,
            employees: [],
            contacts: [],
            templateDialogOpen: false,
            addTemplateDialogOpen: false,
            loadingProposal: quote && !!quote.proposals_id,
            loadingBGImage: false,
            loadingPDFIndex: undefined,
            saving: false,
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this._getProposal();
        this._getEmployees();
        this._getContacts();
    };

    componentDidUpdate = (oldProps, oldState) => {
        if (!oldState.loadingProposal && !isEqual(oldState.proposal, this.state.proposal)) {
            this.context.functions.setDirty(true, false, {
                title: this.tr('Changes to this proposal are not saved!'),
                text: this.tr('Are you sure you want to leave? All unsaved changes will be lost.'),
            });
            this.setState({
                contentChanged: true,
            });
        }
    };

    _getContacts = () => {
        const { project, template } = this.props;
        if (template) return;
        DataHandler.get({ url: `autocomplete_data/contacts/${project.companies_id}/customer/${project.account.id}` })
            .done((res) => {
                this.setState({ contacts: res.contacts });
            })
            .fail((err) => console.log(err));
    };

    onAddContact = (contact) => {
        const contacts = cloneDeep(this.state.contacts);
        contacts.push(contact);
        this.setState({ contacts }, () => {
            setTimeout(() => {
                this._getContacts();
            }, 1000);
        });
    };

    _getEmployees = () => {
        const { project, template } = this.props;
        if (template) return;
        const dontIgnoreCompany = project.limited_project_team == 1;
        DataHandler.get({ url: `subjects/employees/${project.companies_id}`, dontIgnoreCompany })
            .done((employees) => {
                const userTagProps = {
                    fields: { name: 'name' },
                    showLocked: this.props.showLockedUsersWithTag,
                    transl: this.translations,
                };
                employees.forEach((p, i) => {
                    employees[i] = { ...Utils.showLockedAndFreelancerUserTag(p, userTagProps) };
                });

                this.setState({ employees });
            })
            .fail((err) => console.log(err));
    };

    onProposalRendered = () => {
        this.props.onProposalLoaded && this.props.onProposalLoaded();
    };

    onLogoRendered = () => {
        this.logoRendered = true;
        if (this.pdfRendered || this.state.proposal.content.findIndex((c) => c.type == 'pdf') == -1) {
            this.onProposalRendered();
        }
    };

    onPDFRendered = (sectionKey) => {
        this.renderedPDFSections.push(sectionKey);
        const pdfSections = this.state.proposal.content.filter((c) => c.type == 'pdf');
        let allRendered = true;
        pdfSections.forEach((s) => {
            if (!this.renderedPDFSections.includes(s.key)) {
                allRendered = false;
            }
        });
        if (allRendered) {
            this.pdfRendered = true;
            if (this.logoRendered || this.state.proposal.content.findIndex((c) => c.type == 'cover') == -1) {
                this.onProposalRendered();
            }
        }
    };

    _getProposal = () => {
        const { proposal } = this.state;
        if (proposal.id == '-1' || !!this.props.template) return;
        const { project, quote } = this.props;
        DataHandler.get({ url: `projects/${project.id}/quotes/${quote.id}/proposals/${proposal.id}` })
            .then((res) => {
                const content = JSON.parse(res.response.content);
                if (this.props.quoteHTML) {
                    const oldQuoteIndex = content.findIndex((c) => c.type == 'quote');
                    if (oldQuoteIndex != -1) {
                        content.splice(oldQuoteIndex, 1);
                        content.splice(oldQuoteIndex, 0, {
                            type: 'quote',
                            key: 'quote',
                            title: `${this.tr('Quote')} - ${this.props.quote?.name}`,
                            content: this.props.quoteHTML,
                        });
                    } else {
                        content.push({
                            type: 'quote',
                            key: 'quote',
                            title: `${this.tr('Quote')} - ${this.props.quote?.name}`,
                            content: this.props.quoteHTML,
                        });
                    }
                }
                this.setState(
                    {
                        loadingProposal: false,
                        proposal: {
                            ...this.state.proposal,
                            content,
                        },
                    },
                    () => {
                        if (this.state.proposal.content.findIndex((c) => c.type == 'cover') == -1 && this.state.proposal.content.findIndex((c) => c.type == 'pdf') == -1) {
                            this.onProposalRendered();
                        }
                    }
                );
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loadingProposal: false,
                });
            });
    };

    _cancel = () => {
        this.context.functions.setOverlayComponent(null);
    };

    getProposalPrintParams = () => {
        const styleTags = document.head.getElementsByTagName('style');
        let styleString = '';
        for (const style in styleTags) {
            if (styleTags[style].innerText != '' && styleTags[style].innerText != undefined && styleTags[style].innerText != 'undefined')
                if (!styleTags[style].innerText.startsWith('iframe')) styleString += styleTags[style].innerText;
        }

        const linkTags = document.head.getElementsByTagName('link');
        const links: any = [];
        for (const link in linkTags) {
            if (linkTags[link].rel == 'stylesheet' && (linkTags[link].href.indexOf("taimer.com") > 0 || linkTags[link].href.indexOf('heeros.com') > 0 || linkTags[link].href.indexOf('taimer-stack:1025') > 0)) {
                links.push(linkTags[link].href);
            }
        }
        const pageStyleString = `@page {
                size: 8.5in 12in;
                margin: 0 !important;
            }`;
        let html = '<html><head><meta charset="UTF-8"><style>' + pageStyleString + '</style><style>' + styleString + '</style>';
        for (const link in links) {
            html += "<link rel='stylesheet' href='" + links[link] + "'>";
        }
        html += '<link rel="preconnect" href="https://fonts.googleapis.com">';
        html += '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>';
        html += '<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap" rel="stylesheet">';
        const id = styles.proposalPrint;
        html += `</head><body style="margin:0px"><div id="${id}">`;
        const proposalHtml = this._proposalContent.current?.outerHTML;
        html += proposalHtml + '</div></body></html>';
        const params = {
            html: html,
            styles: styles,
            links: links,
        };
        return params;
    };

    _print = () => {
        this.setState({ generatingPDF: true }, () => {
            const styleTags = document.head.getElementsByTagName('style');
            let styleString = '';
            for (const style in styleTags) {
                if (styleTags[style].innerText != '' && styleTags[style].innerText != undefined && styleTags[style].innerText != 'undefined')
                    if (!styleTags[style].innerText.startsWith('iframe')) styleString += styleTags[style].innerText;
            }

            const linkTags = document.head.getElementsByTagName('link');
            const links: any = [];
            for (const link in linkTags) {
                if (linkTags[link].rel == 'stylesheet' && (linkTags[link].href.indexOf('heeros.com') > 0 || linkTags[link].href.indexOf('taimer-stack:1025') > 0)) {
                    links.push(linkTags[link].href);
                }
            }
            const pageStyleString = `@page {
                size: 8.5in 12in;
                margin: 0 !important;
            }`;
            let html = '<html><head><meta charset="UTF-8"><style>' + pageStyleString + '</style><style>' + styleString + '</style>';
            for (const link in links) {
                html += "<link rel='stylesheet' href='" + links[link] + "'>";
            }
            html += '<link rel="preconnect" href="https://fonts.googleapis.com">';
            html += '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>';
            html += '<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap" rel="stylesheet">';
            const id = styles.proposalPrint;
            html += `</head><body style="margin:0px"><div id="${id}">`;
            const proposalHtml = this._proposalContent.current?.outerHTML;
            html += proposalHtml + '</div></body></html>';

            if (import.meta.env.MODE !== 'production') {
                const printWindow = window.open('', 'PRINT');
                if (printWindow && proposalHtml) printWindow.document.body.innerHTML = html;
                printWindow?.focus();
                this.setState({ generatingPDF: false });
            } else {
                const params = {
                    html: html,
                    styles: styles,
                    links: links,
                };
                DataHandler.post({ url: 'print/proposal' }, params).done((response) => {
                    this.setState({ generatingPDF: false });
                    if (response.error) {
                        return;
                    }
                    DataHandler.getArrayBuffer({ url: 'file', filename: response.filename }).done((response) => {
                        if (response == false) {
                            return;
                        }
                        const blob = new Blob([response], {
                            type: 'application/pdf',
                        });
                        //@ts-ignore
                        const filename = `${this.tr('Proposal')}_${this.props.quote?.name || ''}_${moment().format(this.context.userObject.dateFormat)}.pdf`
                            .toLowerCase()
                            .replace(/ /g, '_')
                            .replace(/ä/g, 'a')
                            .replace(/ö/g, 'o')
                            .replace(/å/g, 'a');
                        FileSaver.saveAs(blob, filename);
                    });
                });
            }
        });
    };

    _editProposal = (data, callback?) => {
        const { proposal } = this.state;
        const { project, quote, template, enqueueSnackbar, refreshQuoteData, company } = this.props;
        if (template) {
            const templateData = {
                section: data.proposal.content[0],
            };
            DataHandler.put({ url: `companies/${company}/proposal_templates/${proposal.id}` }, templateData)
                .then((res) => {
                    this.setState({ contentChanged: false, saving: false });
                    this.context.functions.setDirty(false);
                    enqueueSnackbar(this.tr('Template saved succesfully!'), { variant: 'success', className: styles.snackbar });
                    setTimeout(() => {
                        refreshQuoteData();
                    }, 1000);
                })
                .catch((err) => {
                    enqueueSnackbar(this.tr('Saving template failed!'), { variant: 'error', className: styles.snackbar });
                    console.log(err);
                });
            return;
        }
        DataHandler.put({ url: `projects/${project.id}/quotes/${quote.id}/proposals/${proposal.id}` }, data)
            .then((res) => {
                if (!callback) {
                    enqueueSnackbar(this.tr('Proposal saved succesfully!'), { variant: 'success', className: styles.snackbar });
                }
                this.setState({ contentChanged: false, saving: false });
                this.context.functions.setDirty(false);
                callback && callback(res.response);
                setTimeout(() => {
                    refreshQuoteData();
                }, 1000);
            })
            .catch((err) => {
                enqueueSnackbar(this.tr('Saving proposal failed!'), { variant: 'error', className: styles.snackbar });
                console.log(err);
            });
    };

    _createProposal = (data, callback?) => {
        const { project, quote, template, enqueueSnackbar, refreshQuoteData } = this.props;
        if (template) return;
        DataHandler.post({ url: `projects/${project.id}/quotes/${quote.id}/proposals` }, data)
            .then((res) => {
                if (!callback) {
                    enqueueSnackbar(this.tr('Proposal saved succesfully!'), { variant: 'success', className: styles.snackbar });
                }
                this.setState({ contentChanged: false, saving: false });
                this.context.functions.setDirty(false);
                callback && callback(res.response);
                setTimeout(() => {
                    refreshQuoteData();
                }, 1000);
            })
            .catch((err) => {
                enqueueSnackbar(this.tr('Saving proposal failed!'), { variant: 'error', className: styles.snackbar });
                console.log(err);
            });
    };

    _save = (callback?) => {
        this.setState({ saving: true }, () => {
            const proposal = cloneDeep(this.state.proposal);
            const {
                userObject: { usersId },
            } = this.context;
            const data = { proposal, usersId };
            if (this._removeAttachmentRequests) {
                this._removeAttachmentRequests.forEach((url) => {
                    DataHandler.delete({ url });
                });
            }
            Number(proposal.id) > 0 ? this._editProposal(data, callback) : this._createProposal(data, callback);
        });
    };

    editProposalValue = (sectionIndex: number, key: any, value: any) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        if (Array.isArray(key) && typeof value == 'object') {
            key.forEach((k) => {
                proposal.content[sectionIndex].content[k] = value[k];
            });
        } else {
            proposal.content[sectionIndex].content[key] = value;
        }
        this.setState({ proposal });
    };

    onMoveSectionUp = (sectionIndex) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        const section = proposal.content[sectionIndex];
        const newIndex = sectionIndex - 1;
        if (newIndex < 0) return;
        proposal.content.splice(sectionIndex, 1);
        proposal.content.splice(newIndex, 0, section);
        this.setState({ proposal });
    };

    onMoveSectionDown = (sectionIndex) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        const section = proposal.content[sectionIndex];
        const newIndex = sectionIndex + 1;
        if (newIndex > proposal.content.length - 1) return;
        proposal.content.splice(sectionIndex, 1);
        proposal.content.splice(newIndex, 0, section);
        this.setState({ proposal });
    };

    onMoveBlockUp = (sectionIndex, blockIndex) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        const block = proposal.content[sectionIndex].content[blockIndex];
        const newIndex = blockIndex - 1;
        if (newIndex < 0) return;
        proposal.content[sectionIndex].content.splice(blockIndex, 1);
        proposal.content[sectionIndex].content.splice(newIndex, 0, block);
        this.setState({ proposal });
    };

    onMoveBlockDown = (sectionIndex, blockIndex) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        const block = proposal.content[sectionIndex].content[blockIndex];
        const newIndex = blockIndex + 1;
        if (newIndex > proposal.content[sectionIndex].content.length - 1) return;
        proposal.content[sectionIndex].content.splice(blockIndex, 1);
        proposal.content[sectionIndex].content.splice(newIndex, 0, block);
        this.setState({ proposal });
    };

    onToggleSectionHidden = (sectionIndex) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        proposal.content[sectionIndex].hidden = !proposal.content[sectionIndex].hidden;
        this.setState({ proposal });
    };

    onToggleBlockHidden = (sectionIndex, itemKey) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        if (!proposal.content[sectionIndex].hiddenBlocks) proposal.content[sectionIndex].hiddenBlocks = [];
        const hiddenBlocks = proposal.content[sectionIndex].hiddenBlocks;
        const itemIndex = hiddenBlocks!.indexOf(itemKey);
        itemIndex == -1 ? hiddenBlocks!.push(itemKey) : hiddenBlocks!.splice(itemIndex, 1);
        this.setState({ proposal });
    };

    addNewBlock = (index, type, sectionIndex) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        const newIndex = index + 1;
        const amountOfType = (proposal.content[sectionIndex].content || []).filter((c) => c.type == type).length;
        const key = `${type}_${amountOfType}`;
        const newSection = { ...cloneDeep(this.contentBlockOptions[type]), key };
        proposal.content[sectionIndex].content.splice(newIndex, 0, newSection);
        this.setState({ proposal });
    };

    onBlockEdit = (sectionIndex, blockIndex, value) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        proposal.content[sectionIndex].content[blockIndex].content = value;
        this.setState({ proposal });
    };

    onBlockDelete = (sectionIndex, blockIndex) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        if (proposal.content[sectionIndex].content[blockIndex].type == 'image' && !!proposal.content[sectionIndex].content[blockIndex].content?.filename) {
            this._removeAttachmentRequests.push(`attachments/${proposal.content[sectionIndex].content[blockIndex].content?.filename}`);
        }
        proposal.content[sectionIndex].content.splice(blockIndex, 1);
        this.setState({ proposal });
    };

    editSectionTitle = (index, value) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        proposal.content[index].title = value;
        this.setState({ proposal });
    };

    addPDFSection = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const {
            taimerAccount: { attachmentMaxSize },
        } = this.context;
        const type = 'pdf';
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        if (file.size > attachmentMaxSize) {
            this.props.enqueueSnackbar(this.tr('Selected file is too large'), {
                variant: 'error',
                className: styles.snackbar,
            });
            return;
        }
        const proposal: Proposal = cloneDeep(this.state.proposal);
        const newIndex = this._addPDFIndex + 1;
        let newSection = cloneDeep(this.sectionOptions[type]);
        const amountOfType = proposal.content.filter((c) => c.type == type).length;
        const key = `${type}_${amountOfType}`;
        // @ts-ignore TODO: tarkista
        newSection = { ...newSection, content: file, title: 'PDF – ' + file.name, key };
        // @ts-ignore TODO: tarkista
        proposal.content.splice(newIndex, 0, newSection);
        this.setState({ proposal, loadingPDFIndex: newIndex }, () => {
            if (this._pageRefs[newIndex]) {
                this._pageRefs[newIndex].scrollIntoView({ behavior: 'smooth' });
            }
            this._uploadPDF(file, newIndex);
        });
    };

    _uploadPDF = (file, index) => {
        DataHandler.file({ url: `projects/proposals/${this.props.quote.id}/attachments` }, file, undefined, (e) => {
            if (e.responseJSON === false) {
                this.props.enqueueSnackbar(this.tr('Uploading file failed'), {
                    variant: 'error',
                    className: styles.snackbar,
                });
                this.setState({ loadingPDFIndex: undefined });
                return;
            }
            if (e.responseJSON.filename) {
                const filename = e.responseJSON.filename;
                const proposal: Proposal = cloneDeep(this.state.proposal);
                proposal.content[index].content = { filename };
                this.setState({ proposal, loadingPDFIndex: undefined });
            }
        });
    };

    addNewSection = (index, type, sectionIndex = undefined, template = undefined) => {
        if (type == 'pdf' && !template) {
            this._addPDFIndex = index;
            this._pdfInput.current && this._pdfInput.current.click();
            return;
        }
        const proposal: Proposal = cloneDeep(this.state.proposal);
        const newIndex = index + 1;
        let newSection = template || cloneDeep(this.sectionOptions[type]);
        const amountOfType = proposal.content.filter((c) => c.type == type).length;
        const key = `${type}_${amountOfType}`;
        newSection = { ...newSection, key };
        if (template && this._swapTemplateContent) {
            proposal.content[index] = newSection;
        } else {
            proposal.content.splice(newIndex, 0, newSection);
        }
        this.setState({ proposal }, () => {
            if (this._pageRefs[newIndex] && (!template || (template && !this._swapTemplateContent))) {
                this._pageRefs[newIndex].scrollIntoView({ behavior: 'smooth' });
            }
        });
    };

    deleteSection = (sectionIndex) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        if (proposal.content[sectionIndex].content?.filename) {
            this._removeAttachmentRequests.push(`attachments/${proposal.content[sectionIndex].content?.filename}`);
        }
        proposal.content.splice(sectionIndex, 1);
        this.setState({ proposal });
    };

    onAddBackground = (sectionIndex, backgroundImage) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        proposal.content[sectionIndex].backgroundImage = backgroundImage;
        this.setState({ proposal });
    };

    onRemoveBackground = (sectionIndex) => {
        const proposal: Proposal = cloneDeep(this.state.proposal);
        this._removeAttachmentRequests.push(`attachments/${proposal.content[sectionIndex].backgroundImage?.filename}`);
        proposal.content[sectionIndex].backgroundImage = undefined;
        this.setState({ proposal });
    };

    _isReadMode = () => {
        if (!this.props.checkPrivilege) {
            return !this.context.functions.checkPrivilege('projects', 'proposal_write', this.props.project ? this.props.project.companies_id : this.props.company);
        }
        return !this.props.checkPrivilege('projects', 'proposal_write');
    };

    onSaveAsTemplate = (title, id) => {
        this.closeAddTemplateDialog();
        const sectionIndex = this._newTemplateSectionIndex;
        const { project } = this.props;
        const section = this.state.proposal.content[sectionIndex];
        const {
            userObject: { usersId },
        } = this.context;
        const data = { section: { ...section, title }, usersId };
        const request = !id
            ? DataHandler.post({ url: `companies/${project.companies_id}/proposal_templates` }, data)
            : DataHandler.put({ url: `companies/${project.companies_id}/proposal_templates/${id}` }, data);
        request
            .then((res) => {
                //console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    openTemplateDialog = (sectionIndex, swapContent = false, templateType?: ProposalContentSectionType) => {
        this._templateSectionIndex = sectionIndex;
        this._swapTemplateContent = swapContent;
        this.setState({ templateDialogOpen: true, templateType });
    };

    closeTemplateDialog = () => {
        this.setState({ templateDialogOpen: false, templateType: undefined });
    };

    openAddTemplateDialog = (sectionIndex) => {
        this._newTemplateSectionIndex = sectionIndex;
        this.setState({ addTemplateDialogOpen: true });
    };

    closeAddTemplateDialog = () => {
        this.setState({ addTemplateDialogOpen: false });
    };

    onTemplateSelected = (template) => {
        this.closeTemplateDialog();
        this.addNewSection(this._templateSectionIndex, template.type, undefined, template);
    };

    _getAllowedSectionOptions = () => {
        const keys = ['blank', 'pdf'];
        if (this.state.proposal.content.findIndex((c) => c.type == 'cover') == -1) {
            keys.unshift('cover');
        }
        const options = keys.reduce((map, k) => {
            map[k] = this.sectionOptions[k];
            return map;
        }, {});
        return options;
    };

    _renderArrangementArea = () => {
        if (this.props.template) return null;

        const { proposal, loadingProposal } = this.state;
        const readMode = this._isReadMode();
        const arrangementListProps = {
            loadingProposal,
            items: proposal.content,
            onTitleEdit: this.editSectionTitle,
            onMoveSectionUp: this.onMoveSectionUp,
            onMoveSectionDown: this.onMoveSectionDown,
            onToggleSectionHidden: this.onToggleSectionHidden,
            readMode,
        };
        return (
            <>
                <div className={styles.arrangementArea}>
                    <ArrangementList {...arrangementListProps} />
                    {this.props.onboardingContent && <WizardPageContentRenderer tr={this.tr} content={this.props.onboardingContent} />}
                </div>
                <ArrangementListDrawer toggleButtonTitle={this.tr('Edit arrangement')} {...arrangementListProps} />
            </>
        );
    };

    _renderContentForSection = (section: ProposalContentSection, sectionIndex: number) => {
        const { employees, contacts, loadingBGImage, loadingPDFIndex } = this.state;
        const { checkPrivilege, project, template, quote, company } = this.props;
        const readMode = this._isReadMode();
        switch (section.type) {
            case 'cover':
                return (
                    <CoverPage
                        readMode={readMode}
                        quoteId={template ? template.id : quote.id}
                        company={project ? project.companies_id : company}
                        onToggleHidden={this.onToggleBlockHidden}
                        editProposalValue={this.editProposalValue}
                        onAddContact={this.onAddContact}
                        loadingBGImage={loadingBGImage}
                        section={section}
                        account={project?.account}
                        sectionIndex={sectionIndex}
                        employees={employees}
                        contacts={contacts}
                        checkPrivilege={checkPrivilege}
                        templateMode={!!template}
                        printLanguage={this.props.printLanguage}
                        printDateFormat={this.props.printDateFormat}
                        onLogoLoaded={this.onLogoRendered}
                    />
                );
            case 'blank':
                return (
                    <BlankSection
                        readMode={readMode}
                        quoteId={template ? template.id : quote.id}
                        section={section}
                        contentBlockOptions={this.contentBlockOptions}
                        blockIcons={this.blockIcons}
                        addNewBlock={this.addNewBlock}
                        onBlockEdit={this.onBlockEdit}
                        onBlockDelete={this.onBlockDelete}
                        onMoveBlockUp={this.onMoveBlockUp}
                        onMoveBlockDown={this.onMoveBlockDown}
                        sectionIndex={sectionIndex}
                    />
                );
            case 'pdf':
                return <PDFSection loadingPDF={loadingPDFIndex == sectionIndex} section={section} sectionIndex={sectionIndex} onPDFRendered={this.onPDFRendered} />;
            case 'quote':
                return <QuoteSection section={section} sectionIndex={sectionIndex} />;
            default:
                return null;
        }
    };

    setLoadingBGImage = (loadingBGImage: boolean) => {
        this.setState({ loadingBGImage });
    };

    render() {
        const { proposal, employees, contacts, saving, templateDialogOpen, generatingPDF, loadingProposal, addTemplateDialogOpen, templateType, contentChanged } = this.state;
        const { quote, project, template, noTopBar } = this.props;
        const readMode = this._isReadMode();
        return (
            <div id={styles.proposalEditor}>
                {!noTopBar && (
                    <div className={styles.topBar}>
                        <div className={styles.left}>
                            <Tooltip title={this.tr('Return to the previous view')}>
                                <Button onClick={this._cancel}>
                                    <ArrowBack />
                                </Button>
                            </Tooltip>
                            {quote ? quote.name : `${this.tr('Template')}: ${proposal.content[0]?.title}`}
                        </div>
                        <div className={styles.right}>
                            {!template && !contentChanged && <LoaderButton loading={generatingPDF} size="large" onClick={this._print} text={this.tr('Print')} />}
                            {contentChanged && (
                                <Button size="large" color="info" onClick={this._cancel}>
                                    {this.tr('Cancel')}
                                </Button>
                            )}
                            {!readMode && contentChanged && <LoaderButton size="large" color="primary" loading={saving} onClick={() => this._save()} text={this.tr('Save')} />}
                        </div>
                    </div>
                )}
                <div className={styles.scrollContainer}>
                    {loadingProposal ? (
                        <div className={styles.loaderContainer}>
                            <div className={styles.loaderPage}>
                                <Loading  />
                            </div>
                        </div>
                    ) : (
                        <div className={styles.contentArea} ref={this._proposalContent}>
                            <input className={styles.fileInput} ref={this._pdfInput} onChange={this.addPDFSection} type="file" accept="application/pdf" />
                            {!template && !readMode && (proposal.content.length == 0 || this.state.proposal.content.findIndex((c) => c.type == 'cover') == -1) && (
                                <AddContentButton
                                    templateTitle={this.tr('Template')}
                                    onAddTemplate={this.openTemplateDialog}
                                    sectionIndex={-1}
                                    index={-1}
                                    title={this.tr('Add new section')}
                                    icons={this.sectionIcons}
                                    showSeparatorLines={true}
                                    items={Object.values(this._getAllowedSectionOptions())}
                                    onItemClick={this.addNewSection}
                                />
                            )}
                            {proposal.content.map((section, i) => {
                                return (
                                    <ProposalSection
                                        quoteId={template ? template.id : quote.id}
                                        key={section.key}
                                        setLoadingBGImage={this.setLoadingBGImage}
                                        onAddBackground={this.onAddBackground}
                                        onRemoveBackground={this.onRemoveBackground}
                                        onAddNewSection={this.addNewSection}
                                        onDeleteSection={this.deleteSection}
                                        onSaveAsTemplate={this.openAddTemplateDialog}
                                        onAddTemplate={this.openTemplateDialog}
                                        employees={employees}
                                        contacts={contacts}
                                        sectionOptions={this._getAllowedSectionOptions()}
                                        sectionIcons={this.sectionIcons}
                                        sectionIndex={i}
                                        section={section}
                                        readMode={readMode}
                                        setPageRef={(ref) => (this._pageRefs[i] = ref)}
                                        templateMode={!!template}
                                    >
                                        {this._renderContentForSection(section, i)}
                                    </ProposalSection>
                                );
                            })}
                        </div>
                    )}
                    {this._renderArrangementArea()}
                </div>
                {templateDialogOpen && (
                    <SelectTemplateDialog templateType={templateType} company={project?.companies_id} onTemplateSelected={this.onTemplateSelected} onClose={this.closeTemplateDialog} />
                )}
                {addTemplateDialogOpen && <AddTemplateDialog company={project?.companies_id} onTemplateSelected={this.onSaveAsTemplate} onClose={this.closeAddTemplateDialog} />}
            </div>
        );
    }
}

export default withSnackbar<Props>(ProposalEditor);
