import React from "react";

import withStyles from '@mui/styles/withStyles';

import InsightsBase, { styles } from "../InsightsBase";
import TaimerComponent from "../../../TaimerComponent";
import TopBlock from "./components/TopBlock";
import FunnelBySalesAgent from "./components/FunnelBySalesAgent";
import PipelineOverview from "./components/PipelineOverview";
import PipelineByOwner from "./components/PipelineByOwner";
import InsightMonthlySplit from "../InsightMonthlySplit";
import LeadsAndDealsLeaderboard from "./components/LeadsAndDealsLeaderboard";
import DealsOverview from "./components/DealsOverview";
import WonDealsDevelopment from "./components/WonDealsDevelopment";
import WonVsLostDeals from "./components/WonVsLostDeals";
import PipelineByCloseDate from "./components/PipelineByCloseDate";
import PipelineByStageAndCloseDate from "./components/PipelineByStageAndCloseDate";
import "./SalesInsight.css";
import PieChartBlock from "./components/PieChartBlock";
import LeadsAndDealsListed from "./components/LeadsAndDealsListed";
import CategoryValueTable from "../CategoryValueTable";
import NoPermissionOverlay from "../../../overlays/NoPermissionOverlay";
import { parse, format, endOfMonth, startOfMonth, isSameYear, addYears, addMonths, getMonth, getYear } from 'date-fns';
import DataHandler from "./../../../general/DataHandler";
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';

import { CheckCircle, FilterList, FormatListBulletedOutlined } from "@mui/icons-material";
import FunnelsOverview from "./components/FunnelsOverview";
import WonDealsLeaderboard from "./components/WonDealsLeaderboard";

const MODE_KEY = "sales_insight_mode";

// Empty Data, dispalyed while loading
const baseData = {
	top_data: {
		last_7_days: {
			value: 0,
			count: 0,
		},
		last_30_days: {
			value: 0,
			count: 0,
		},
		last_90_days: {
			value: 0,
			count: 0,
		},
		all: {
			value: 0,
			count: 0,
			start: moment().format('YYYY-MM-DD'),
			end: moment().format('YYYY-MM-DD'),
		}
	},
	months: [],
	monthsLog: [],
	won_vs_lost: {
		won: [],
		lost: [],
		labelorder: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
	},
	deals_overview: {
		start: '',
		end: '',
		graph_data: {
			revenue_actual: [],
			margin_actual: []
		},
		table_data: [],
		labelorder: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		months: [],
	},
	project_type: [],
	project_category: [],
	pipeline_overview: {
		stages: [],
		values: {},
		counts: {},
	},
	pipeline_by_stage_and_close_date: {
		stages: [],
		values: {},
		counts: {},
		labelorder: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		months: [],
	},
	won_deals_development: {
		two_years_back: {
			year: 2020,
			label: '2020',
			values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		last_year: {
			year: 2019,
			label: '2019',
			values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		this_year: {
			year: 2018,
			label: '2018',
			values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		labelorder: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
	},
	monthly_hit_rate_by_agent: {
		data: [],
		totals: {
			monthly: {},
			count: 0,
			value: 0,
		},
		months: [
			'01-2020',
			'02-2020',
			'03-2020',
			'04-2020',
			'05-2020',
			'06-2020',
			'07-2020',
			'08-2020',
			'09-2020',
			'10-2020',
			'11-2020',
			'12-2020',
		],
	},
	leads_and_deals_leaderboard: [],
	funnel_by_agent: {
		sales_agent: [],
		account: []
	},
	won_deals_leaderboard: [],
	leads_by_month: {
		data: [],
		totals: {
			monthly: {},
			count: 0,
			value: 0,
		},
		months: [
			'01-2020',
			'02-2020',
			'03-2020',
			'04-2020',
			'05-2020',
			'06-2020',
			'07-2020',
			'08-2020',
			'09-2020',
			'10-2020',
			'11-2020',
			'12-2020',
		],
	},
	pipeline_by_owner: {
		stages: [],
		data: {
			user: [],
			account: [],
			account_manager: [],
			project_manager: [],
			account_group: [],
			sub_unit: [],
		},
	},
	funnels_overview: false,
}

class SalesInsight extends TaimerComponent {
	constructor(props, context) {
		super(props, context, "dashboard/insights/sales/SalesInsight");
		const { tr } = this;
		const { addons } = this.context;
		this.months = [
			tr("Jan"),
			tr("Feb"),
			tr("Mar"),
			tr("Apr"),
			tr("May"),
			tr("Jun"),
			tr("Jul"),
			tr("Aug"),
			tr("Sep"),
			tr("Oct"),
			tr("Nov"),
			tr("Dec")
		];

		this.tabs = [
			{
				label: this.tr("Pipeline Leads"),
				href: this.context.functions.urlify({...this.props.viewProps, selectedReport: "leads-overview"}),
				id: "leads",
			},
			{
				label: this.tr("Projects | Won Deals"),
				href: this.context.functions.urlify({...this.props.viewProps, selectedReport: "deals-overview"}),
				id: "deals",
			},
		];

		this.leadsByMonthTabs = [
			{
				key: "new_leads",
				label: this.tr("New Leads")
			},
			{
				key: "lost",
				label: this.tr("Lost")
			},
			{
				key: "on_hold",
				label: this.tr("On Hold")
			}
		];

		this.viewTabs = [
			{
				key: "sales_agent",
				label: this.tr("Sales Agent")
			},
			 {
				key: "account_manager",
				label: this.tr("Account Manager")
			},
			{
				key: "account_group",
				label: this.tr("Account Group")
			},
			{
				key: "project_manager",
				label: this.tr("Project Manager")
			},
			{
				key: "account",
				label: this.tr("Account")
			},
			{
				key: "sub_unit",
				label: this.tr("Sub unit")
			}
		].filter(x => x);

		this.winRateTabs = [
			{
				key: "all_leads",
				label: this.tr("Win rate all leads")
			},
			{
				key: "own_leads",
				label: this.tr("Win rate own leads")
			}
		];
		//this controls the data units (users atm) to be shown as ...(locked)	
		this.showLockedArray = ['account_manager', 'project_manager', 'sales_agent'];

		const mode = this.props.mode ? this.props.mode : this._getModeFromLS("leads");

		this.state = {
			loaded: false,
			multipipeline: false,
			currency: "USD",
			...baseData,
			sliderProps: false,
			mode,
			categoryRange: {
				startDate: moment(),
				endDate: moment(),
			},
			typeRange: {
				startDate: moment(),
				endDate: moment(),
			},
			actualRange: {
				startDate: moment(),
				endDate: moment(),
			}
		};

		this.refInsightBase = React.createRef();
	}

	_getModeFromLS = defaultValue => {
		let mode = defaultValue;
		try {
			mode = localStorage.getItem(MODE_KEY) || defaultValue;
		} catch (e) { }
		return mode;
	};

	componentDidUpdate = (oldProps) => {
		if (oldProps.mode != this.props.mode && ['leads', 'deals'].includes(this.props.mode)) {
			this.refInsightBase.current && this.refInsightBase.current.changeTab(undefined, this.props.mode);
		}
	}

	getData = async (filters, { currency, default_pipeline }, change, tab) => {
		this.setState({
			loaded: false,
			mode: tab,
			currency,
			...baseData,
		});

		const {
			dateRange: { startDate, endDate },
			create_date,
			close_date,
			won_date,
			status_date,
			customers_ids,
			projects_ids,
			customer_tags,
			project_tags,
			customership_group_ids,
			project_categories_ids,
			project_types_ids,
			reporting_group_ids,
			groups_ids,
			users_ids,
			company,
			advanced_search,
			pipeline_ids,
			status,
		} = filters;

		const dates = {};

		if (tab === "leads") {
			if (create_date.startDate && create_date.endDate) {
				dates['create_start'] = format(create_date.startDate, "YYYY-MM-DD");
				dates['create_end'] = format(create_date.endDate, "YYYY-MM-DD");
			}

			if (close_date.startDate && close_date.endDate) {
				dates['close_start'] = format(close_date.startDate, "YYYY-MM-DD");
				dates['close_end'] = format(close_date.endDate, "YYYY-MM-DD");
			}
		} else if (tab === "deals") {
			if (won_date.startDate && won_date.endDate) {
				dates['won_start'] = format(won_date.startDate, "YYYY-MM-DD");
				dates['won_end'] = format(won_date.endDate, "YYYY-MM-DD");
			}
		}

		if (status_date.startDate && status_date.endDate) {
			dates['status_start'] = format(status_date.startDate, "YYYY-MM-DD");
			dates['status_end'] = format(status_date.endDate, "YYYY-MM-DD");
		}

		try {
			const data = await DataHandler.post({
				url: 'dashboard/sales',
				company,
				// start: format(startDate, "YYYY-MM-DD"),
				// end: format(endDate, "YYYY-MM-DD"),
				tab,
				...dates,
			}, {
				...advanced_search,
				projects: projects_ids,
				project_tags: project_tags,
				customers: customers_ids,
				customer_tags: customer_tags,
				customership_groups: customership_group_ids,
				project_type: project_types_ids,
				project_category: project_categories_ids,
				reporting_group: reporting_group_ids,
				users: users_ids,
				groups: groups_ids,
				status,
				pipeline: tab === "leads" ? pipeline_ids : [-1],
			});

			this.setState({
				...data,
				loaded: true,
				// multipipeline: tab === "leads" ? pipeline_ids.length > 1 : false,
				categoryRange: {
					startDate: moment(startDate),
					endDate: moment(endDate),
				},
				typeRange: {
					startDate: moment(startDate),
					endDate: moment(endDate),
				},
				actualRange: {
					startDate: parse(data.actual_start, 'YYYY-MM-DD', new Date()),
					endDate: parse(data.actual_end, 'YYYY-MM-DD', new Date()),
				},
			});
		} catch (e) {
			console.error("sales insight load fail: ", e);
		}

		//get data for current mode;
		this.setState({ loaded: true });
	};

	_fetchSlider = async (slider, params = {}, data = {}, useDates = true, sliderExtra = {}) => {
		const { mode: tab } = this.state;
		const filters = this.refInsightBase.current.getFilters();

		const {
			create_date,
			close_date,
			won_date,
			status_date,
			customers_ids,
			projects_ids,
			customer_tags,
			project_tags,
			customership_group_ids,
			project_categories_ids,
			project_types_ids,
			reporting_group_ids,
			groups_ids,
			users_ids,
			company,
			advanced_search,
			pipeline_ids,
			status,
		} = filters;

		const dates = {};

		if (useDates && tab === "leads") {
			if (create_date.startDate && create_date.endDate) {
				dates['create_start'] = format(create_date.startDate, "YYYY-MM-DD");
				dates['create_end'] = format(create_date.endDate, "YYYY-MM-DD");
			}

			if (close_date.startDate && close_date.endDate) {
				dates['close_start'] = format(close_date.startDate, "YYYY-MM-DD");
				dates['close_end'] = format(close_date.endDate, "YYYY-MM-DD");
			}
		} else if (useDates && tab === "deals") {
			if (won_date.startDate && won_date.endDate) {
				dates['won_start'] = format(won_date.startDate, "YYYY-MM-DD");
				dates['won_end'] = format(won_date.endDate, "YYYY-MM-DD");
			}
		}

		if (useDates && status_date.startDate && status_date.endDate) {
			dates['status_start'] = format(status_date.startDate, "YYYY-MM-DD");
			dates['status_end'] = format(status_date.endDate, "YYYY-MM-DD");
		}

		try {
			const sliderData = await DataHandler.post({
				url: `dashboard/sales/slider/${slider}`,
				company,
				...dates,
				tab,
				...params,
			}, {
				...advanced_search,
				projects: projects_ids,
				project_tags: project_tags,
				customers: customers_ids,
				customer_tags: customer_tags,
				customership_groups: customership_group_ids,
				project_type: project_types_ids,
				project_category: project_categories_ids,
				reporting_group: reporting_group_ids,
				users: users_ids,
				groups: groups_ids,
				pipeline: tab === "leads" ? pipeline_ids : [-1],
				status,
				...data,
			});

			if (sliderData) {
				const totalSum = _.sumBy(sliderData, 'value');

				for (const i of sliderData) {
					if (totalSum != 0)
						i.total = totalSum;
				}
			}

			const sliderProps = { ...this.state.sliderProps, loading: false, data: sliderData, ...sliderExtra };

			this.setState({
				sliderProps,
			});
		} catch (e) {
			console.error("sales insight slider load fail: ", e);
			this.setState({ sliderProps: false });
		}
	}

	_fetchSingle = async (item, params = {}, data = {}) => {
		const { mode: tab } = this.state;
		const filters = this.refInsightBase.current.getFilters();

		const {
			customers_ids,
			projects_ids,
			customer_tags,
			project_tags,
			customership_group_ids,
			project_categories_ids,
			project_types_ids,
			reporting_group_ids,
			groups_ids,
			users_ids,
			company,
			advanced_search,
			pipeline_ids,
			status,
		} = filters;

		try {
			const itemData = await DataHandler.post({
				url: `dashboard/sales/item/${item}`,
				company,
				start: format(this.state.actualRange.startDate, "YYYY-MM-DD"),
				end: format(this.state.actualRange.endDate, "YYYY-MM-DD"),
				tab,
				...params,
			}, {
				...advanced_search,
				projects: projects_ids,
				project_tags: project_tags,
				customers: customers_ids,
				customer_tags: customer_tags,
				customership_groups: customership_group_ids,
				project_type: project_types_ids,
				project_category: project_categories_ids,
				reporting_group: reporting_group_ids,
				users: users_ids,
				groups: groups_ids,
				pipeline: tab === "leads" ? pipeline_ids : [-1],
				...data,
			});

			this.setState(itemData);

		} catch (e) {
			console.error("sales insight data load fail: ", e);
			// this.setState({ sliderProps: false });
		}
	}

	getLeadProjectTypeData = dates => {
		this.setState({ typeRange: dates });

		this._fetchSingle('type', {
			won_start: format(dates.startDate.toDate(), "YYYY-MM-DD"),
			won_end: format(dates.endDate.toDate(), "YYYY-MM-DD"),
		});
	};

	getLeadProjectCategoryData = dates => {
		this.setState({ categoryRange: dates });

		this._fetchSingle('category', {
			won_start: format(dates.startDate.toDate(), "YYYY-MM-DD"),
			won_end: format(dates.endDate.toDate(), "YYYY-MM-DD"),
		});
	};

	getDealProjectTypeData = dates => {
		this.setState({ typeRange: dates });

		this._fetchSingle('type', {
			close_start: format(dates.startDate.toDate(), "YYYY-MM-DD"),
			close_end: format(dates.endDate.toDate(), "YYYY-MM-DD"),
		});
	};

	getDealProjectCategoryData = dates => {
		this.setState({ categoryRange: dates });

		this._fetchSingle('category', {
			close_start: format(dates.startDate.toDate(), "YYYY-MM-DD"),
			close_end: format(dates.endDate.toDate(), "YYYY-MM-DD"),
		});
	};

	handleTabChange = mode => {
		this.setState({ mode });
	};

	_getLeadsTopItems = currencyFormatter => {
		const { tr } = this;
		const {
			top_data: {
				last_7_days,
				last_30_days,
				last_90_days,
				all,
			},
		} = this.state;
		const { userObject: { dateFormat } } = this.context;
		const today = moment();
		return [
			{
				title: tr("Leads last 7 days"),
				value: `${last_7_days.count} ${tr("leads")}`,
				subtitle: currencyFormatter(last_7_days.value),
				dates: `${moment().subtract(7, "days").format(dateFormat)} - ${today.format(dateFormat)}`,
				sliderData: {
					params: {
						days: 7,
					},
					label: tr("Leads last 7 days"),
					columns: ["user", "project", "account", "value", "stage", "funnel", "created_date", "closing_date"],
				}
			},
			{
				title: tr("Leads last 30 days"),
				value: `${last_30_days.count} ${tr("leads")}`,
				subtitle: currencyFormatter(last_30_days.value),
				dates: `${moment().subtract(30, "days").format(dateFormat)} - ${today.format(dateFormat)}`,
				sliderData: {
					params: {
						days: 30,
					},
					label: tr("Leads last 30 days"),
					columns: ["user", "project", "account", "value", "stage", "funnel", "created_date", "closing_date"],
				}
			},
			{
				title: tr("Leads last 90 days"),
				value: `${last_90_days.count} ${tr("leads")}`,
				subtitle: currencyFormatter(last_90_days.value),
				dates: `${moment().subtract(90, "days").format(dateFormat)} - ${today.format(dateFormat)}`,
				sliderData: {
					params: {
						days: 90,
					},
					label: tr("Leads last 90 days"),
					columns: ["user", "project", "account", "value", "stage", "funnel", "created_date", "closing_date"],
				}
			},
			{
				title: tr("All leads"),
				value: `${all.count} ${tr("leads")}`,
				subtitle: currencyFormatter(all.value),
				dates: `${moment(all.start, "YYYY-MM-DD").format(dateFormat)} - ${moment(all.end, "YYYY-MM-DD").format(dateFormat)}`,
				sliderData: {
					label: tr("All leads"),
					columns: ["user", "project", "account", "value", "stage", "funnel", "created_date", "closing_date"],
				}
			}
		];
	};

	_getDealsTopItems = currencyFormatter => {
		const { tr } = this;
		const {
			top_data: {
				last_7_days,
				last_30_days,
				last_90_days,
				all,
			},
		} = this.state;
		const { userObject: { dateFormat } } = this.context;
		const today = moment();
		return [
			{
				title: tr("Deals last 7 days"),
				value: currencyFormatter(last_7_days.value),
				subtitle: `${last_7_days.count} ${tr("deals")}`,
				dates: `${moment().subtract(7, "days").format(dateFormat)} - ${today.format(dateFormat)}`,
				sliderData: {
					params: {
						days: 7,
					},
					label: tr("Deals last 7 days"),
					columns: ["value", "user", "project", "account", "created_date", "won_date", "days_pipeline"],
				}
			},
			{
				title: tr("Deals last 30 days"),
				value: currencyFormatter(last_30_days.value),
				subtitle: `${last_30_days.count} ${tr("deals")}`,
				dates: `${moment().subtract(30, "days").format(dateFormat)} - ${today.format(dateFormat)}`,
				sliderData: {
					params: {
						days: 30,
					},
					label: tr("Deals last 30 days"),
					columns: ["value", "user", "project", "account", "created_date", "won_date", "days_pipeline"],
				}
			},
			{
				title: tr("Deals last 90 days"),
				value: currencyFormatter(last_90_days.value),
				subtitle: `${last_90_days.count} ${tr("deals")}`,
				dates: `${moment().subtract(90, "days").format(dateFormat)} - ${today.format(dateFormat)}`,
				sliderData: {
					params: {
						days: 90,
					},
					label: tr("Deals last 90 days"),
					columns: ["value", "user", "project", "account", "created_date", "won_date", "days_pipeline"],
				}
			},
			{
				title: tr("All deals"),
				value: currencyFormatter(all.value),
				subtitle: `${all.count} ${tr("deals")}`,
				dates: `${moment(all.start, "YYYY-MM-DD").format(dateFormat)} - ${moment(all.end, "YYYY-MM-DD").format(dateFormat)}`,
				sliderData: {
					params: {},
					label: tr("All deals"),
					columns: ["value", "user", "project", "account", "created_date", "won_date", "days_pipeline"],
				}
			}
		];
	};

	_showSliderForTopBlock = item => {
		const sliderProps = {
			open: true,
			loading: true,
			...item.sliderData,
		};
		this.setState({ sliderProps });

		this._fetchSlider('top', { ...item.sliderData.params });
	}

	_showSliderForFunnelsOverview = ({ id, label }, month) => {
		const { functions: { getFinancialYear } } = this.context;

		if (month !== null) {
			const filters = this.refInsightBase.current.getFilters();

			const start = addMonths(new Date(), -11 + month);

			const monthLabel = this.months[getMonth(start)];

			this._fetchSlider('cumulative', {
				date: format(startOfMonth(start), 'YYYY-MM-01'),
			}, {
				pipeline: [id]
			}, true, {
				sliderText: this.tr('Projects / values from last date of months'),
				showExport: true,
			});

			const sliderProps = {
				open: true,
				loading: true,
				label: `${label} - ${monthLabel} ${getYear(start)}`,
				columns: ["project", "account", "created_date", "funnel", "pipeline_date", "stage", "branch_of_business", "project_type", "user", "value"],
			};
			this.setState({ sliderProps });
	
		} else {
			this._fetchSlider('cumulative', {}, {
				pipeline: [id],
			});

			const sliderProps = {
				open: true,
				loading: true,
				label,
				columns: ["project", "account", "user", "value"],
			};
			this.setState({ sliderProps });
	
		}
	}

	_showSliderForPipelineOverview = ({ id, label }, month) => {
		const { multipipeline } = this.state;

		const sliderProps = {
			open: true,
			loading: true,
			label,
			columns: ["project", "account", "user", "value"],
		};
		this.setState({ sliderProps });

		if (multipipeline) {
			this._fetchSlider('pipeline', {}, {
				pipeline: [id],
			});
		} else {
			this._fetchSlider('pipeline', {
				stage: id,
			});
		}
	}

	_showSliderForPipelineByStage = ({ id, label }, month) => {
		const { pipeline_by_stage_and_close_date: d } = this.state;
		const { functions: { getFinancialYear } } = this.context;
		const { multipipeline } = this.state;

		if (multipipeline && month !== null) {
			const m = parse(d.months[month], "YYYY-MM-DD", new Date());

			const filters = this.refInsightBase.current.getFilters();

			const monthLabel = this.months[getMonth(m)];

			const sliderProps = {
				open: true,
				loading: true,
				label: `${label} - ${monthLabel} ${getYear(m)}`,
				columns: ["project", "account", "user", "value", "closing_date"],
			};
			this.setState({ sliderProps });

			this._fetchSlider('pipeline', {
				close_start: format(startOfMonth(m), 'YYYY-MM-DD'),
				close_end: format(endOfMonth(m), 'YYYY-MM-DD'),
			}, {
				pipeline: [id],
			});
		} else if (multipipeline) {
			const sliderProps = {
				open: true,
				loading: true,
				label,
				columns: ["project", "account", "user", "value", "closing_date"],
			};
			this.setState({ sliderProps });

			this._fetchSlider('pipeline', {
				close_start: d.closing_start,
				close_end: d.closing_end,
			}, {
				pipeline: [id],
			});
		}
		else if (month !== null) {
			const filters = this.refInsightBase.current.getFilters();
			const m = parse(d.months[month], "YYYY-MM-DD", new Date());

			const monthLabel = this.months[getMonth(m)];

			const sliderProps = {
				open: true,
				loading: true,
				label: `${label} - ${monthLabel} ${getYear(m)}`,
				columns: ["project", "account", "user", "value", "closing_date"],
			};
			this.setState({ sliderProps });

			this._fetchSlider('pipeline', {
				stage: id,
				close_start: format(startOfMonth(m), 'YYYY-MM-DD'),
				close_end: format(endOfMonth(m), 'YYYY-MM-DD'),
			});
		} else {
			const sliderProps = {
				open: true,
				loading: true,
				label,
				columns: ["project", "account", "user", "value", "closing_date"],
			};
			this.setState({ sliderProps });

			this._fetchSlider('pipeline', {
				stage: id,
				close_start: d.closing_start,
				close_end: d.closing_end,
			});
		}
	}

	_showSliderForPipelineByCloseDate = ({ id, label }, month) => {
		const { pipeline_by_stage_and_close_date: d } = this.state;
		const { functions: { getFinancialYear } } = this.context;

		if (month !== null) {
			const m = parse(d.months[month], "YYYY-MM-DD", new Date());

			const monthLabel = this.months[getMonth(m)];

			const sliderProps = {
				open: true,
				loading: true,
				label: `${label} - ${monthLabel} ${getYear(m)}`,
				columns: ["project", "account", "user", "value", "closing_date"],
			};
			this.setState({ sliderProps });

			this._fetchSlider(id, {
				close_start: format(startOfMonth(m), 'YYYY-MM-DD'),
				close_end: format(endOfMonth(m), 'YYYY-MM-DD'),
			});
		} else {
			const sliderProps = {
				open: true,
				loading: true,
				label,
				columns: ["project", "account", "user", "value", "closing_date"],
			};
			this.setState({ sliderProps });

			this._fetchSlider(id, {
				close_start: d.closing_start,
				close_end: d.closing_end,
			});
		}
	}

	_showSliderForWonVsLost = ({ date, type, slider, label }, month) => {
		const { functions: { getFinancialYear } } = this.context;

		const m = parse(this.state.won_vs_lost.months[month], "YYYY-MM-DD", new Date());

		const monthLabel = this.months[getMonth(m)];

		const sliderProps = {
			open: true,
			loading: true,
			label: `${label} - ${monthLabel} ${getYear(m)}`,
			columns: ["project", "account", "user", "value", slider !== 'lost' ? "won_date" : "lost_date", "days_pipeline"],
		};
		this.setState({ sliderProps });

		this._fetchSlider(slider, {
			won_start: format(startOfMonth(m), 'YYYY-MM-DD'),
			won_end: format(endOfMonth(m), 'YYYY-MM-DD'),
		}, {
			// status: 0,
		});
	}

	_showSliderForDealsOverview = ({ users_name, users_id, ...rest }, month) => {
		const { functions: { getFinancialYear } } = this.context;
		const { deals_overview: { start, end } } = this.state;

		if (users_id) {
			const sliderProps = {
				open: true,
				loading: true,
				label: users_name,
				columns: ["project", "account", "user", "value", "won_date", "days_pipeline"],
			};
			this.setState({ sliderProps });

			this._fetchSlider('user', {
				users_id,
				start,
				end,
			});
		} else {
			const m = parse(this.state.deals_overview.months[month], "YYYY-MM-DD", new Date());
			const monthLabel = this.months[getMonth(m)];

			const sliderProps = {
				open: true,
				loading: true,
				label: monthLabel,
				columns: ["project", "account", "user", "value", "won_date", "days_pipeline"],
			};
			this.setState({ sliderProps });

			this._fetchSlider('won', {
				won_start: format(startOfMonth(m), 'YYYY-MM-DD'),
				won_end: format(endOfMonth(m), 'YYYY-MM-DD'),
			});
		}
	}

	_showSliderForProjectType = ({ id, title, won }) => {
		const { typeRange: { startDate, endDate } } = this.state;
		const sliderProps = {
			open: true,
			loading: true,
			label: title,
			columns: ["project", "account", "user", "value", won ? "won_date" : "closing_date"],
		};
		this.setState({ sliderProps });

		this._fetchSlider('type', {
			type: id,
			start: format(startDate, 'YYYY-MM-DD'),
			end: format(endDate, 'YYYY-MM-DD'),
		});
	}

	_showSliderForProjectCategory = ({ id, title, won }) => {
		const { typeRange: { startDate, endDate } } = this.state;
		const sliderProps = {
			open: true,
			loading: true,
			label: title,
			columns: ["project", "account", "user", "value", won ? "won_date" : "closing_date"],
		};
		this.setState({ sliderProps });

		this._fetchSlider('type', {
			category: id,
			start: format(startDate, 'YYYY-MM-DD'),
			end: format(endDate, 'YYYY-MM-DD'),
		});
	}

	_showSliderForWonDealsDevelopment = ({ id, label, year, ...rest }, month) => {
		const start = addMonths(parse(`${year}-01-01`, "YYYY-MM-DD", new Date(0)), getMonth(this.state.actualRange.startDate) + month);

		const monthLabel = this.months[getMonth(start)];

		const sliderProps = {
			open: true,
			loading: true,
			label: `${label} - ${monthLabel} ${getYear(start)}`,
			columns: ["project", "account", "user", "value", "closing_date"],
		};
		this.setState({ sliderProps });

		this._fetchSlider('pipeline', {
			won_start: format(startOfMonth(start), 'YYYY-MM-DD'),
			won_end: format(endOfMonth(start), 'YYYY-MM-DD'),
		});
	}

	_showSliderForLeadsAndDealsLeaderboard = (mode, item) => {
		if (mode === "sales_agent") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr('Sales Agent')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				sales_agent: item.id,
			});
		} else if (mode === "account_manager") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr('Account Manager')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				account_manager: item.id,
			});
		} else if (mode === "account_group") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr('Account Group')} - ${item.name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				account_group: item.id,
			});
		} else if (mode === "project_manager") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr('Project Manager')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				project_manager: item.id,
			});
		} else if (mode === "account" || mode === "sub_unit") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr('Account')} - ${item.customers_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				customer: item.id,
				customer_subunit: mode === "sub_unit" ? 1 : 0,
			});
		} else {
			console.error("missing slider", {mode, item})
		}
	}

	_showSliderForPipelineByOwner = (mode, item) => {
		if (mode === "user") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr('Sales Agent')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				sales_agent: item.id,
			});
		} else if (mode === "account_manager") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr('Account Manager')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				account_manager: item.id,
			});
		} else if (mode === "account_group") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr('Account Group')} - ${item.name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				account_group: item.id,
			});
		} else if (mode === "project_manager") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr('Project Manager')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				project_manager: item.id,
			});
		} else if (mode === "account" || mode === "sub_unit") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr('Account')} - ${item.customers_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				customer: item.id,
				customer_subunit: mode === "sub_unit" ? 1 : 0,
			});
		} else {
			console.error("missing slider", {mode, item})
		}	
	}

	_showSliderLeadsByMonth = (mode, secondaryMode, {type, item, month}) => {
		const filters = this.refInsightBase.current.getFilters();
		const sliderParams = {};

		let title = '';
		let subtitle = '';
		let datePrefix = '';
		let sliderType = 'created';

		if (mode === 'on_hold') {
			sliderType = 'hold';
		} else if (mode === 'lost') {
			sliderType = 'lostp';
		}

		if (secondaryMode === "sales_agent") {
			title = 'Sales Agent';
			subtitle = item.users_name;
			sliderParams.sales_agent = item.id;
		} else if (secondaryMode === "account_manager") {
			title = 'Account Manager';
			subtitle = item.users_name;
			sliderParams.account_manager = item.id;
		} else if (secondaryMode === "account_group") {
			title = 'Account Group';
			subtitle = item.name;
			sliderParams.account_group = item.id;
		} else if (secondaryMode === "project_manager") {
			title = 'Project Manager';
			subtitle = item.users_name;
			sliderParams.project_manager = item.id;
		} else if (secondaryMode === "account" || secondaryMode === "sub_unit") {
			title = 'Account';
			subtitle = item.customers_name;
			sliderParams.customer = item.id;
			sliderParams.customer_subunit = secondaryMode === "sub_unit" ? 1 : 0;
		} else {
			console.error("missing slider", {secondaryMode, item})
		}

		if (type === "cell") {
			const m = parse(month, "MM-YYYY", new Date());

			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr(title)} - ${subtitle}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });

			let createStart = startOfMonth(m);
			let createEnd = endOfMonth(m);

			if (filters.create_date && filters.create_date.startDate && filters.create_date.startDate > createStart) {
				createStart = filters.create_date.startDate;
			}

			if (filters.create_date && filters.create_date.endDate && filters.create_date.endDate < createEnd) {
				createEnd = filters.create_date.endDate;
			}
			
			this._fetchSlider(sliderType, {
				create_start: format(createStart, 'YYYY-MM-DD'),
				create_end: format(createEnd, 'YYYY-MM-DD'),
				...sliderParams,
			});	
		} else if (type === "row") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Pipeline Leaderboard')} - ${this.tr(title)} - ${subtitle}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider(sliderType, {
				create_start: format(this.state.actualRange.startDate, 'YYYY-MM-DD'),
				create_end: format(this.state.actualRange.endDate, 'YYYY-MM-DD'),
				...sliderParams,
			});	
		}
	}

	_showSliderWinRateMonthly  = (mode, secondaryMode, {type, item, month}) => {
		const { functions: { getFinancialYear } } = this.context;

		const sliderParams = {};

		let title = '';
		let subtitle = '';

		if (secondaryMode === "sales_agent") {
			title = 'Sales Agent';
			subtitle = item.users_name;
			sliderParams.sales_agent = item.id;
		} else if (secondaryMode === "account_manager") {
			title = 'Account Manager';
			subtitle = item.users_name;
			sliderParams.account_manager = item.id;
		} else if (secondaryMode === "account_group") {
			title = 'Account Group';
			subtitle = item.name;
			sliderParams.account_group = item.id;
		} else if (secondaryMode === "project_manager") {
			title = 'Project Manager';
			subtitle = item.users_name;
			sliderParams.project_manager = item.id;
		} else if (secondaryMode === "account" || secondaryMode === "sub_unit") {
			title = 'Account';
			subtitle = item.customers_name;
			sliderParams.customer = item.id;
			sliderParams.customer_subunit = secondaryMode === "sub_unit" ? 1 : 0;
		} else {
			console.error("missing slider", {secondaryMode, item});
		}

		if (type === "cell") {
			const m = parse(month, "MM-YYYY", new Date());

			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Win Rate Monthly')} - ${this.tr(title)} - ${subtitle}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('won', {
				won_start: format(startOfMonth(m), 'YYYY-MM-DD'),
				won_end: format(endOfMonth(m), 'YYYY-MM-DD'),
				...sliderParams,
			});	
		} else if (type === "row") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Win Rate Monthly')} - ${this.tr(title)} - ${subtitle}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('won', {
				won_start: format(startOfMonth(this.state.actualRange.startDate), 'YYYY-MM-DD'),
				won_end: format(endOfMonth(this.state.actualRange.endDate), 'YYYY-MM-DD'),
				...sliderParams,
			});	
		}
	}

	_showSliderForWonDealsLeaderboard = (chart, mode, item) => {
		const { functions: { getFinancialYear } } = this.context;

		const fy = chart.id == 1 ? {start: this.state.actualRange.startDate, end: this.state.actualRange.endDate} : getFinancialYear(addYears(this.state.actualRange.startDate, -1));

		if (mode === "sales_agent") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Won Leaderboard')} - ${this.tr('Sales Agent')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				won_start: format(startOfMonth(fy.start), 'YYYY-MM-DD'),
				won_end: format(endOfMonth(fy.end), 'YYYY-MM-DD'),
				sales_agent: item.id,
			});
		} else if (mode === "account_manager") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Won Leaderboard')} - ${this.tr('Account Manager')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				account_manager: item.id,				
				won_start: format(startOfMonth(fy.start), 'YYYY-MM-DD'),
				won_end: format(endOfMonth(fy.end), 'YYYY-MM-DD'),
			});
		} else if (mode === "account_group") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Won Leaderboard')} - ${this.tr('Account Group')} - ${item.name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {				
				won_start: format(startOfMonth(fy.start), 'YYYY-MM-DD'),
				won_end: format(endOfMonth(fy.end), 'YYYY-MM-DD'),
				account_group: item.id,
			});
		} else if (mode === "project_manager") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Won Leaderboard')} - ${this.tr('Project Manager')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				won_start: format(startOfMonth(fy.start), 'YYYY-MM-DD'),
				won_end: format(endOfMonth(fy.end), 'YYYY-MM-DD'),
				project_manager: item.id,
			});
		} else if (mode === "account" || mode === "sub_unit") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Won Leaderboard')} - ${this.tr('Account')} - ${item.customers_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('leaderboard', {
				won_start: format(startOfMonth(fy.start), 'YYYY-MM-DD'),
				won_end: format(endOfMonth(fy.end), 'YYYY-MM-DD'),
				customer: item.id,
				customer_subunit: mode === "sub_unit" ? 1 : 0,
			});
		} else {
			console.error("missing slider", {mode, item})
		}	
	}

	_showSliderForFunnelBySalesAgent = (mode, item) => {
		if (mode === "user") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Funnel by')} ${this.tr('Sales Agent')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('all', {
				sales_agent: item.id,
			}, {
				include_lost: 1,
			});
		} else if (mode === "account_manager") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Funnel by')} ${this.tr('Account Manager')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('all', {
				account_manager: item.id,
			}, {
				include_lost: 1,
			});
		} else if (mode === "account_group") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Funnel by')} ${this.tr('Account Group')} - ${item.name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('all', {
				account_group: item.id,
			}, {
				include_lost: 1,
			});
		} else if (mode === "project_manager") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Funnel by')} ${this.tr('Project Manager')} - ${item.users_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('alöl', {
				project_manager: item.id,
			}, {
				include_lost: 1,
			});
		} else if (mode === "account" || mode === "sub_unit") {
			const sliderProps = {
				open: true,
				loading: true,
				label: `${this.tr('Funnel by')} ${this.tr('Account')} - ${item.customers_name}`,
				columns: ["sales_status", "project", "account", "value"],
			};
			this.setState({ sliderProps });
			
			this._fetchSlider('all', {
				customer: item.id,
				customer_subunit: mode === "sub_unit" ? 1 : 0,
			}, {
				include_lost: 1,
			});
		} else {
			console.error("missing slider", {mode, item})
		}
	}

	getDealsMonths = (noYear = false) => {
		const { months } = this.state;
		return months.map(m => {
			const momentDate = moment(m, "YYYY-MM-DD");
			return noYear ? this.months[momentDate.month()] : `${this.months[momentDate.month()]} ${momentDate.format("YYYY")}`;
		});
	} 

	getLeadsMonths = () => {
		const { months } = this.state;
		return months.map(m => {
			const momentDate = moment(m, "YYYY-MM-DD");
			return `${this.months[momentDate.month()]} ${momentDate.format("YYYY")}`;
		});
	}
	
	getLeadsMonthsLog = () => {
		const { monthsLog } = this.state;
		return monthsLog.map(m => {
			const momentDate = moment(m, "YYYY-MM-DD");
			return `${this.months[momentDate.month()]} ${momentDate.format("YYYY")}`;
		});
	}

	getLeadsMonthsClose = () => {
		const { pipeline_by_stage_and_close_date: { months } } = this.state;
		return months.map(m => {
			const momentDate = moment(m, "YYYY-MM-DD");
			return `${this.months[momentDate.month()]} ${momentDate.format("YYYY")}`;
		});
	}

	getCustomMonths = (months) => {
		return (months || []).map(m => {
			const momentDate = moment(m, "YYYY-MM-DD");
			return `${this.months[momentDate.month()]} ${momentDate.format("YYYY")}`;
		});
	}

	getDefaultTimeRange = () => {
        const { defaultTime, monthRange } = this.props;
        const { functions: { getFinancialYear } } = this.context;

        return getFinancialYear(new Date());
    }

	_renderComponentsForMode = () => {
		const {
			currency,
			multipipeline,
			won_vs_lost,
			deals_overview,
			pipeline_overview,
			pipeline_by_stage_and_close_date,
			project_type,
			project_category,
			categoryRange,
			typeRange,
			won_deals_development,
			monthly_hit_rate_by_agent,
			leads_and_deals_leaderboard,
			funnel_by_agent,
			won_deals_leaderboard,
			leads_by_month,
			pipeline_by_owner,
			funnels_overview,
		} = this.state;
		const { taimerAccount, addons } = this.context;
		const { tr } = this;

		const currencyFormatter = new Intl.NumberFormat(
			taimerAccount.numberFormat,
			{
				style: "currency",
				currency
			}
		).format;

		switch (this.state.mode) {
			case "leads":
				return [
					<TopBlock
						key="top_block"
						items={this._getLeadsTopItems(currencyFormatter)}
						currency={currency}
						showSlider={this._showSliderForTopBlock}
						width={6}
					/>,
					funnels_overview && <FunnelsOverview 
						key="funnels_overview"
						data={funnels_overview}
						months={this.getLeadsMonthsLog()}
						currency={currency}
						tr={this.tr}
						width={6}
						showSlider={this._showSliderForFunnelsOverview}
					/>,
					<PipelineOverview 
						key="pipeline_overview"
						data={pipeline_overview}
						currency={currency}
						tr={this.tr}
						width={6}
						showSlider={this._showSliderForPipelineOverview}
						multipipeline={multipipeline}
					/>,
					<PipelineByStageAndCloseDate
						key="pipeline_by_stage_and_close_date"
						data={pipeline_by_stage_and_close_date}
						months={this.getLeadsMonthsClose()}
						width={6}
						tr={this.tr}
						currency={currency}
						showSlider={this._showSliderForPipelineByStage}
						multipipeline={multipipeline}
					/>,
					<CategoryValueTable
						key="project_type"
						title={this.tr("Project Type")}
						// useDateSelection
						getData={this.getLeadProjectTypeData}
						data={project_type}
						types={project_type}
						colTitle={this.tr("Type")}
						sliderRows={[]}
						currency={currency}
						dateRange={typeRange}
						onClickRow={this._showSliderForProjectType}
					/>,
					<CategoryValueTable
						key="project_category"
						title={this.tr("Project Category")}
						// useDateSelection
						getData={this.getLeadProjectCategoryData}
						data={project_category}
						types={project_category}
						sliderRows={[]}
						currency={currency}
						dateRange={categoryRange}
						onClickRow={this._showSliderForProjectCategory}
					/>,
					<LeadsAndDealsLeaderboard
						width={6}
						tabs={this.viewTabs}
						data={leads_and_deals_leaderboard}
						currency={currency}
						showSlider={this._showSliderForLeadsAndDealsLeaderboard}
						showLockedUsersWithTag={this.showLockedArray}
					/>,
					<PipelineByOwner
						data={pipeline_by_owner}
						width={6}
						tr={this.tr}
						currency={currency}
						showSlider={this._showSliderForPipelineByOwner}
					/>,
					<InsightMonthlySplit
						data={leads_by_month}
						monthNames={this.months}
						months={leads_by_month.months}
						tabs={this.leadsByMonthTabs}
						tabsMode="secondary"
						secondaryTabs={this.viewTabs}
						title={this.tr("Leads by Month")}
						width={6}
						currency={currency}
						getValues={(data, mode, rowIndex, month) => {
							if (mode === "new_leads") {
								return {
									count: data?.new_count,
									value: data?.new_value,
								};
							} else if (mode === "lost") {
								return {
									count: data?.lost_count,
									value: data?.lost_value,
								};
							} else if (mode === "on_hold") {
								return {
									count: data?.hold_count,
									value: data?.hold_value,
								};
							} 

							return {count: 'MISSING'};
						}}
						getRowTotal={(data, mode, rowIndex) => {
							if (mode === "new_leads") {
								return {
									count: data?.new_count,
									value: data?.new_value,
								};
							} else if (mode === "lost") {
								return {
									count: data?.lost_count,
									value: data?.lost_value,
								};
							} else if (mode === "on_hold") {
								return {
									count: data?.hold_count,
									value: data?.hold_value,
								};
							} 

							return {count: 'MISSING'};
						}}
						getColumnTotal={(_, mode, month) => {
							const col = leads_by_month?.totals?.monthly;

							if (!col)
								return {count: ''};

							const data = col[month];
							
							if (mode === "new_leads") {
								return {
									count: data?.new_count ?? 0,
									value: data?.new_value ?? 0,
								};
							} else if (mode === "lost") {
								return {
									count: data?.lost_count ?? 0,
									value: data?.lost_value ?? 0,
								};
							} else if (mode === "on_hold") {
								return {
									count: data?.hold_count ?? 0,
									value: data?.hold_value ?? 0,
								};
							} 

							return {count: 'MISSING'};
						}}
						getTotal={(data, mode, month) => {							
							if (mode === "new_leads") {
								return {
									count: data?.new_count ?? 0,
									value: data?.new_value ?? 0,
								};
							} else if (mode === "lost") {
								return {
									count: data?.lost_count ?? 0,
									value: data?.lost_value ?? 0,
								};
							} else if (mode === "on_hold") {
								return {
									count: data?.hold_count ?? 0,
									value: data?.hold_value ?? 0,
								};
							} 

							return {count: 'MISSING'};
						}}
						showSlider={this._showSliderLeadsByMonth}
					/>,
					<PipelineByCloseDate
						data={pipeline_by_stage_and_close_date}
						months={this.getLeadsMonthsClose()}
						width={6}
						tr={this.tr}
						currency={currency}
						showSlider={this._showSliderForPipelineByCloseDate}
					/>,
				];
			case "deals":
				return [
					<TopBlock
						key="top_block"
						items={this._getDealsTopItems(currencyFormatter)}
						currency={currency}
						width={6}
						showSlider={this._showSliderForTopBlock}
					/>,
					<WonVsLostDeals
						key="won_lost"
						width={6}
						tr={tr}
						currency={currency}
						months={this.getCustomMonths(won_vs_lost.months)}
						data={won_vs_lost}
						showSlider={this._showSliderForWonVsLost}
					/>,
					<DealsOverview
						key="deals_overview"
						months={this.getCustomMonths(deals_overview.months)}
						width={6}
						tr={this.tr}
						currency={currency}
						data={deals_overview}
						showSlider={this._showSliderForDealsOverview}
					/>,
					<CategoryValueTable
						key="project_type"
						title={this.tr("Project Type")}
						getData={this.getDealProjectTypeData}
						data={project_type}
						types={project_type}
						colTitle={this.tr("Type")}
						currency={currency}
						onClickRow={this._showSliderForProjectType}
						sliderExtra={{ won: true }}
					/>,
					<CategoryValueTable
						key="project_category"
						title={this.tr("Project Category")}
						getData={this.getDealProjectCategoryData}
						data={project_category}
						types={project_category}
						currency={currency}
						onClickRow={this._showSliderForProjectCategory}
						sliderExtra={{ won: true }}
					/>,
					<WonDealsDevelopment
						months={this.getDealsMonths(true)}
						tr={this.tr}
						width={6}
						currency={currency}
						data={won_deals_development}
						showSlider={this._showSliderForWonDealsDevelopment}
					/>,
					<InsightMonthlySplit
						data={monthly_hit_rate_by_agent}
						tabs={this.winRateTabs}
						secondaryTabs={this.viewTabs}
						tabsMode="secondary"
						noValue={true}
						getValues={(data, mode, rowIndex, month) => {
							const globalTotal = monthly_hit_rate_by_agent.totals.monthly[month];

							if (mode === "all_leads") {
								return {
									count: globalTotal.total_count ? (100 * (data.won_count / globalTotal.total_count)).toFixed(2) : 0,
								}
							} else {
								return {
									count: data.total_count ? (100 * (data.won_count / data.total_count)).toFixed(2) : 0,
								}
							}
						}}
						getRowTotal={(data, mode, rowIndex) => {
							const globalTotal = monthly_hit_rate_by_agent.totals.totals;

							if (mode === "all_leads") {
								return {
									count: globalTotal.total_count ? (100 * (data.won_count / globalTotal.total_count)).toFixed(2) : 0,
								}
							} else {
								return {
									count: data.total_count ? (100 * (data.won_count / data.total_count)).toFixed(2) : 0,
								}
							}
						}}
						getColumnTotal={(_, mode, month) => {
							if (!monthly_hit_rate_by_agent?.totals?.monthly)
								return {};
								
							const data = monthly_hit_rate_by_agent?.totals?.monthly[month];

							return {
								count: data && data.total_count ? (100 * (data.won_count / data.total_count)).toFixed(2) : 0,
							}
						}}
						getTotal={(data, mode, month) => {							
							return {
								count: data && data.total_count ? (100 * (data.won_count / data.total_count)).toFixed(2) : 0,
							}
						}}
						monthNames={this.months}
						months={monthly_hit_rate_by_agent.months}
						title={this.tr("Win Rate Monthly")}
						width={6}
						unit={"%"}
						currency={currency}
						showSlider={this._showSliderWinRateMonthly}
					/>,
					<WonDealsLeaderboard
						width={6}
						tabs={this.viewTabs}
						data={won_deals_leaderboard}
						currency={currency}
						showSlider={this._showSliderForWonDealsLeaderboard}
					/>,
					<FunnelBySalesAgent
						data={funnel_by_agent}
						width={6}
						tr={this.tr}
						currency={currency}
						showSlider={this._showSliderForFunnelBySalesAgent}

					/>,
					<InsightMonthlySplit
						data={monthly_hit_rate_by_agent}
						title={this.tr("Won Deals Monthly")}
						secondaryTabs={this.viewTabs}
						tabsMode="secondary"
						width={6}
						currency={currency}
						monthNames={this.months}
						months={monthly_hit_rate_by_agent.months}
						getValues={(data, mode, rowIndex, month) => {
							return {
								count: data?.won_count,
								value: data?.won_value,
							}
						}}
						getRowTotal={(data, mode, rowIndex) => {
							return {
								count: data?.won_count,
								value: data?.won_value,
							}
						}}
						getColumnTotal={(_, mode, month) => {
							if (!monthly_hit_rate_by_agent?.totals?.monthly)
								return {};

							const data = monthly_hit_rate_by_agent?.totals?.monthly[month];

							return {
								count: data?.won_count,
								value: data?.won_value,
							}
						}}
						getTotal={(data, mode, month) => {							
							return {
								count: data?.won_count,
								value: data?.won_value,
							}
						}}
						showSlider={this._showSliderWinRateMonthly}
					/>,
				];
			default:
				return null;
		}
	};

	render() {
		const {
			functions: { hasPrivilege }, taimerAccount
		} = this.context;
		if (!hasPrivilege("dashboard", "sales_read")) {
			return <NoPermissionOverlay />;
		}

		const { loaded, mode, sliderProps } = this.state;

		const defaultTime = this.getDefaultTimeRange();
		
		let conditionalFilters = [
			taimerAccount.hasEnterpriseGroups ? "enterprise_groups" : undefined,
		];
		conditionalFilters = conditionalFilters.filter(cf => cf);
		
		return (
			<InsightsBase
				ref={this.refInsightBase}
				loaded={loaded}
				onRequestData={this.getData}
				className="sales-insight"
				defaultTime="year"
				tabs={this.tabs}
				refreshDataOnTabChange
				selectedTab={mode}
				handleTabChange={this.handleTabChange}
				savedQueryName="sales_insight"
				rightGroup="dashboard"
				right="sales_read"
				dropFilters={this.state.mode === "leads" ? ['pipelines', 'account', 'status', 'users'] : ['account', 'status', 'users']}
				advancedFilters={['project_manager', 'account_manager', 'customers', 'customers_types', 'customership_groups', 'projects', 'project_team', 'project_type', 'project_categories', 'reporting_group', 'tags', 'team', ...conditionalFilters]}
				insightFilterProps={{
					usersFilterName: this.tr("Sales Agent"),
					timePeriodTitle: this.tr(mode == "leads" ? 'Expected Close Date' : 'Marked as Won'),
					showPeriodSelector: false,
					customFilters: [
						mode == "leads" && {
							name: 'create_date',
							type: 'daterange',
							label: this.tr('Created Date'),
							extraProps: {
								allowClear: true,
							},
						},
						mode == "leads" && {
							name: 'close_date',
							type: 'daterange',
							label: this.tr('Expected Close Date'),
							extraProps: {
								allowClear: true,
							},
						},
						mode == "deals" && {
							name: 'won_date',
							type: 'daterange',
							label: this.tr('Marked as Won'),
							extraProps: {
								allowClear: true,
							},
						},
						{
							name: 'status_date',
							type: 'daterange',
							label: this.tr('Status Date'),
							extraProps: {
								allowClear: true,
							},
						},
					],
				}}
				defaultFilters={{
					status: -1,
					status_date: {
						startDate: null,
						endDate: null,
					},
					// Leads Tab
					close_date: {
						startDate: null,
						endDate: null,
					},
					create_date: {
						startDate: defaultTime.start,
						endDate: defaultTime.end,
					},
					// Won Tab
					won_date: {
						startDate: defaultTime.start,
						endDate: defaultTime.end,
					},
				}}
				sliderProps={{
					allowSort: true,
					...sliderProps,
					onClose: () => this.setState({ sliderProps: false }),
				}}
				pipelineByDefault="all"
				useSearchButton
			>
				{this._renderComponentsForMode()}
			</InsightsBase>
		);
	}
}

export default withStyles(styles)(SalesInsight);
