import React from 'react';

/* css */
import './InvoicingCumulativeChart.css';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

/* others */
import _ from 'lodash';
import InsightBlock from '../InsightBlock';
import TaimerComponent from "../../../TaimerComponent";
import { ArrowUpward as ArrowDropUp, ArrowDownward as ArrowDropDown, Remove as Dash } from '@mui/icons-material'

import CumulativeChart, { colors, colorsHover } from '../CumulativeChart';
import InsightSlider from '../InsightSlider';
import moment from 'moment/min/moment-with-locales';

class InvoicingCumulativeChart extends TaimerComponent {
    static defaultProps = {
        width: 3,
        visible: true,
    }

    constructor(props, context) {
        super(props, context, "dashboard/insights/invoicing/InvoicingCumulativeChart");

        this.state = {
            sliderYear: 0,
            sliderMonth: 0,
            sliderOpen: false,
            hiddenIndexes: []
        }
    }

    toggleHidden = index => {
        const hiddenIndexes = [...this.state.hiddenIndexes];
        const foundIndex = hiddenIndexes.indexOf(index);
        foundIndex == -1 ? hiddenIndexes.push(index) : hiddenIndexes.splice(foundIndex, 1);
        this.setState({hiddenIndexes});
    }

    clickChart = (dataset, index) => {
        const { data, sliderData } = this.props;

        const sliderSum = dataset.data[index];

        this.setState({ sliderKey: dataset.key, sliderYear: dataset.year, sliderMonth: index, sliderSum, sliderOpen: true });
    }
    
    onClickRow(sliderMonth, sliderKey, sliderSum, sliderYear) {
        this.setState({
            sliderKey,
            sliderYear,
            sliderMonth,
            sliderSum,
            sliderOpen: true,
        })
    }

    onCloseSlider = () => {
        this.setState({sliderOpen: false});
    }

    render() {
        const { data, sliderData, loaded, currency, labelOrder, months: monthKeys, ...props } = this.props;
        const { sliderKey, sliderMonth, sliderYear, sliderSum, sliderOpen, hiddenIndexes } = this.state;
        const { tr } = this;

        const { taimerAccount } = this.context;

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency,
        }).format;

        const datasets = [];

        let i = 0;
        let skipMonths = 0;

        if (data) {
            _.each(_.sortBy(data, (d) => -d.year), (val, i) => {

                if (skipMonths)
                    val.splice(0, skipMonths);

                datasets.push({
                    data: val.values,
                    backgroundColor: colorsHover[i % colors.length],
                    borderColor: colors[i % colors.length],
                    type: monthKeys.length == 1 ? "bar" : 'line',
                    label: val.label,
                    key: val.key,
                    year: val.year,
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    lineTension: 0,
                    hidden: hiddenIndexes.indexOf(i) != -1
                });

                i++;
            });
        }
        // labelorder

        const months = [tr('Jan'), tr('Feb'), tr('Mar'), tr('Apr'), tr('May'), tr('Jun'), tr('Jul'), tr('Aug'), tr('Sep'), tr('Oct'), tr('Nov'), tr('Dec'),];

        const labels = [];

        for (const m of monthKeys) {
            const momentMonth = moment(m, "YYYY-MM-DD");
            labels.push(`${months[momentMonth.month()]}`);
        }

        const charts = {
            labels,
            datasets,
        }

        let lastMonth = 0;
        let lastIncr = 0;

        return (
            <InsightBlock {...props} wrapperClass="invoicing-chart-container">
                <InsightSlider
                    open={sliderOpen}
                    label={`${charts.labels[sliderMonth]} - ${sliderYear}`}
                    onClose={this.onCloseSlider}
                    data={sliderData && sliderYear && sliderData[sliderKey] && sliderData[sliderKey][sliderMonth]}
                    sum={sliderSum}
                    table={{defaultType: 'invoice'}}
                    currency={currency}
                />

                <div className="chart-area">
                    {loaded && <CumulativeChart 
                        hiddenIndexes={hiddenIndexes}
                        toggleHidden={this.toggleHidden}
                        labels={labels}
                        datasets={datasets} 
                        onClick={this.clickChart}
                        currency={currency}
                    />}
                </div>

                <div className="table-area">
                    <Table className="summary">
                        <TableHead>
                            <TableRow>
                                <TableCell className="title">{this.tr("Month")}</TableCell>
                                <TableCell align="right" className="title">{this.tr("Cumulative Invoicing")}</TableCell>
                                <TableCell align="right" className="title">{this.tr("M to M growth")}</TableCell>
                                <TableCell className="title"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {datasets[0] && _.map(datasets[0].data, (val, i) => {
                                const incr = val - lastMonth;

                                const growth = lastMonth > 0 ? val / lastMonth - 1 : 0;

                                const Indicator = growth === 0 ? Dash : (growth > 0 ? ArrowDropUp : ArrowDropDown);

                                lastMonth = val;
                                lastIncr = incr;

                                return (
                                    <TableRow className="row" onClick={this.onClickRow.bind(this, i, datasets[0].key, val, datasets[0].year)}>
                                        <TableCell className="label">
                                            {charts.labels[i]}
                                        </TableCell>
                                        <TableCell align="right" className="number-value" >{currencyFormatter(val)}</TableCell>
                                        <TableCell align="right" className="number-value" >{currencyFormatter(incr)}</TableCell>
                                        <TableCell className="number-value" >{growth != 0 && <span className={growth > 0 ? "change-up" : "change-down"}>
                                            <Indicator />
                                            {Math.round(growth * 100)}%
                                                </span>}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
            </InsightBlock>
        );
    }
}

export default InvoicingCumulativeChart;