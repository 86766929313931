import React, { Component } from 'react';
import PropTypes from 'prop-types'

import moment from 'moment/min/moment-with-locales';
import { format } from 'date-fns';


class DateHeader extends Component {
  static contextTypes = {
    timetracker: PropTypes.object
  };

  constructor(props) {
    super(props)

    this.state = { over: false };
  }

  render() {
    if (!this.props.drilldownView) {
      return <span>{this.props.label}</span>
    }

    const { timetracker: { workdays } } = this.context;

    const dateCompare = format(this.props.date, "YYYY-MM-DD");
    const holiday = (workdays[dateCompare] ?? {type: 0}).type === 3;

    return (
      <a className={holiday && "holiday"} href="#" onClick={this.props.onDrillDown}>
        {this.props.label}
      </a>
    )
  }
}

DateHeader.contextTypes = {
  timetracker: PropTypes.object
};

DateHeader.propTypes = {
  label: PropTypes.node,
  date: PropTypes.instanceOf(Date),
  drilldownView: PropTypes.string,
  onDrillDown: PropTypes.func,
  isOffRange: PropTypes.bool,
}

export default DateHeader