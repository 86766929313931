import React, { Component } from 'react';
import classNames from 'classnames';

/* context */
import { SettingsContext } from './../../SettingsContext';

/* data backend */
import DataHandler from './../../general/DataHandler';
import { format, endOfMonth, startOfMonth, startOfYear, isValid, addMonths } from "date-fns";

/* local components */
import TaimerComponent from '../../TaimerComponent';
import DataList from './../../general/DataList';
import { DateRangePicker } from './../../general/react-date-range/src';
import OutlinedField from "./../../general/OutlinedField";

import { colorHash } from '../../helpers';

/* material ui */
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';

/* material icons */
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ChevronRight from '@mui/icons-material/ChevronRight';

/* chart-js */
import { Bar, Doughnut } from 'react-chartjs-2';
import ChartOptions from './../../general/ChartOptions';

/* extras */
import _, { cloneDeep } from 'lodash';
import debounce from 'lodash/debounce';
import moment from 'moment/min/moment-with-locales';

/* css */
import './Invoicing.css';
import './Expenses.css';
import { LinearProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

/* Images */
import { ReactComponent as ExpensesGraphic } from '../images/ExpensesOverview.svg';
import { ReactComponent as Loading } from '../insights/img/loading.svg';

const styles = theme => ({
    lastRow: {
        border: "none"
    },
    label: {
        transform: "translate(12px, 20px) scale(1) !important"
    },    
    shrink: {
        fontSize: "14px",
        transform: "translate(12px, 7px) scale(0.75) !important"
    },
    input: {
        paddingTop: "25px",
        paddingBottom: "6px"
    },
    overview: {
        margin: "20px 0 0 0",
        boxShadow: "0 1px 15px 0 rgba(69, 65, 78, 0.08)",
        background: "white",
        borderRadius: "0",  
        boxSizing: "border-box",
        display: "flex",
        
    },
    grid: {
        display: "grid",
        minWidth: 0,
        flex: 1
        //width: "100%",
    },
    chartsWrapper: {
        display: "flex",
        paddingBottom: "10px"  
    },
    barchartWrapper: {
        flex: "1",
        maxWidth: "70%",
        minWidth: 0,
        position: 'relative',
        marginLeft: "36.2px"       
    },
    nutchartWrapper: {
        flex: "1",
        maxWidth: "30%",
        minWidth: 0,
        paddingRight: 12,
        margin: "auto 0 auto 24px",
        position: 'relative'
    },
    nutLegend: {
        justifyContent: "space-around",
        position: "absolute",
        marginTop: -117.5,
        marginLeft: 10,
        textAlign: "left",
        width: "21%",
        fontSize: "14px",
        fontWeight: 600,
        color: "#34495e",
        alignSelf: "end",  
        display: "-webkit-inline-box" 
    },
    paragraph: {
        height: "30px",
        lineHeight: "30px"
    },
    total: {
        height: "30px",
        lineHeight: "30px",
        color: '#2D9FF7',
        fontSize: "16px",
        marginLeft: "12px"
    }
});

class ExpensesOverView extends TaimerComponent {
    static contextType = SettingsContext;
    constructor(props, context){
        super(props, context, "dashboard/overview/Expenses");

        const { functions: { getFinancialYear } } = context;
        const dateRange = getFinancialYear();

        const { tr } = this;

        this.monthlyLabels = [
			tr("Jan"),
			tr("Feb"),
			tr("Mar"),
			tr("Apr"),
			tr("May"),
			tr("Jun"),
			tr("Jul"),
			tr("Aug"),
			tr("Sep"),
			tr("Oct"),
			tr("Nov"),
			tr("Dec")
        ];
    
        this.state = {
            data: [],
            open: true,
            elementWidth: 10000,
            elementWidthSet: false,
            chartkey: '-',
            teams: [],
            hiddenTypes: {},
            dateRange: {
                startDate: dateRange.start,
                endDate: dateRange.end,
                key: "selection"
            },
            filters: {
            }
        }

        this.autoCompleteData = false;

        this.handleToggle = this.handleToggle.bind(this);
        this.element = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.insightFilters !== prevProps.insightFilters) {
            this.fetchData();
        }
        if (this.props.company !== prevProps.company)
            this.updateComponentData();
        if (this.props.customersId !== prevProps.customersId)
            this.fetchData();
        if(this.element.current && this.state.elementWidthSet == false)
            this.setState({elementWidth: this.element.current.clientWidth, elementWidthSet: true})
    }

    componentDidMount(){
        super.componentDidMount();
        this.updateComponentData();
        window.addEventListener('resize', this.handleResize);
    }
    updateComponentData = () => {
        const { company } = this.props;
        DataHandler.get({url: `subjects/employees/${company}`}).done(users => this.setState({users}));
        DataHandler.get({url: `subjects/user_groups/${company}`, type: 'teams'}).done(teams => this.setState({teams}));
        this.fetchData();
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = debounce(() => {
        this.updateView();
    }, 10)
    
    updateView = () => {
        const element = document.getElementById("overview-expenses");
        if(element) {
            this.setState({elementWidth: element.clientWidth})
        }
    }

    async fetchData(additionalParams = {}){
        if (this.props.insightFilters) {
            if (!this.props.insightFilters.dateRange)
                return;
                
            const {
                dateRange: { startDate, endDate },
                customers_ids,
                projects_ids,
                customer_tags,
                project_tags,
                customership_group_ids,
                project_categories_ids,
                reporting_group_ids,
                project_types_ids,
                groups_ids,
                users_ids,
                company,
                advanced_search,
            } = this.props.insightFilters;

            
            const data = await DataHandler.post({url: `data/expenses_overview/${format(startDate, "YYYY-MM-DD")}/${format(endDate, "YYYY-MM-DD")}`, insightFiltering: 1}, {
                ...advanced_search,
                company,
                projects: projects_ids,
                project_tags: project_tags,
                customers: customers_ids,
                customer_tags: customer_tags,
                customership_groups: customership_group_ids,
                project_type: project_types_ids,
                project_category: project_categories_ids,
                reporting_group: reporting_group_ids,
                users: users_ids,
                groups: groups_ids,
            });
            this.setState({...data, loaded: true});
        } else {
            const { company } = this.props;
            const { dateRange, filters } = this.state,
                startDate = format(dateRange.startDate, "YYYY-MM-DD"),
                endDate = format(dateRange.endDate, "YYYY-MM-DD");
    
            const data = await DataHandler.get({url: `data/expenses_overview/${startDate}/${endDate}`, ...filters, ...additionalParams, customersId: this.props.customersId || 0, company});
            this.setState({...data, loaded: true});
        }
    }

    onDateChange = (event) => {

        const { startDate, endDate } = event.selection;

        this.setState({
            dateRange: {
                startDate: format(startDate, "YYYY-MM-DD"),
                endDate: format(endDate, "YYYY-MM-DD"),
                key: "selection"
            }
        }, () => this.fetchData());
    }

    onDateInputChange = (dateType, date) => {

        const { endDate, startDate } = this.state.dateRange;
        date = format(date, "YYYY-MM-DD");

        if (dateType == "start") {
            this.setState({
                dateRange: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection"
                }
            }, () => this.fetchData());
        } else {
            this.setState({
                dateRange: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection"
                }
            }, () => this.fetchData());
        }
    }    

    setFilter = name => evt => {
        this.setState(state => {
            const { value } = evt.target;

            state.filters[name] = value;

            return state;
        }, () => this.fetchData());
    }

    handleToggle(){
        this.setState({open: !this.state.open});
    }

    addExpense(e){
        this.context.functions.updateView({module: 'worktrips', action: 'modify'}, e.ctrlKey || e.metaKey || e.button === 1);   
    }

    toggleTypeHidden = id => {
        this.setState({
            hiddenTypes: {
                ...this.state.hiddenTypes,
                [id]: !this.state.hiddenTypes[id]
            }
        })
    }

    render(){
        const { period, summary, total, loaded, open, dateRange, filters, types, users, teams, hiddenTypes } = this.state;
        const { classes } = this.props;
        const { taimerAccount, privileges, userObject, functions: { updateView } } = this.context;
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: this.props.currency || "EUR"
        }).format;

        if (!loaded || !types) {
            return <div><Loading className='main-page-loading-indicator' /></div>
        }

//        let data = _.last(data);

        function createDatasets() {
            const colors = ChartOptions.randomColors.map(color => {return color}),
                doughNutColors = [];

            while (colors.length < types.length) {
                colors.push(colorHash.hex(types[colors.length].name));
            }

            const doughnutLabels = [];
            const doughnutValues = [];
            let addedLabels = {};
            const barSets = cloneDeep(types).map((type, i) => {
                const index = 1;
                type.backgroundColor = colors[index];
                doughNutColors.push(colors[index]);
                colors.splice(1, 1);
                type.data = [];
                if (addedLabels[type.label]) {
                    addedLabels[type.label] += 1;
                    type.label = `${type.label} (${addedLabels[type.label]})`
                } else {
                    addedLabels = {
                        ...addedLabels,
                        [type.label]: 1
                    }
                }
                type.hidden = hiddenTypes[type.id] == true;
                const doughnutValue = type.hidden ? 0 : (summary[type.name] || 0);
                doughnutValues.push(parseFloat(doughnutValue).toFixed(2));
                doughnutLabels.push(type.name);
                return type;
            });

            const nutData = {
                datasets: [{
                    label: '',
                    data: doughnutValues,
                    backgroundColor: doughNutColors,
                }],
                labels: doughnutLabels,
            }
            return {bar: barSets, nut: nutData};
        }

        const datasets = createDatasets.bind(this)();

//bar graph
        _.map(period, month => {
            _.map(datasets.bar, set => {
                set.data.push(parseFloat(month.data[set.label.replace('*', '')]).toFixed(2))
            })
        });

        const barLabels = [];
        _.map(period, label => {
            const momentMonth = moment(label.date, "MM-YYYY");
            barLabels.push(`${this.monthlyLabels[momentMonth.month()]} ${momentMonth.format("YYYY")}`);
        })

        const dates = _.map(period, 'date');
        const expensesBarData = {
            labels: barLabels,
            dates,
            datasets: _.map(datasets.bar, set => {return set;})
        };


        const barChartOptions = {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                tooltip: {
                    enabled: true,
                    callbacks: {
                        label: function(tooltipItem) {
                            const dataset = tooltipItem.dataset;
                            return dataset.label + ": " + currencyFormatter(tooltipItem.raw);
                        }
                    }
                },
                legend: {
                    display: false,
                    position: 'bottom',
                    labels: {
                        boxWidth: 14,
                        padding: 20,
                        usePointStyle: true
                    }
                },
            },
            onClick: (bar, chart) => {
                const b = this.bar.getElementsAtEventForMode(bar, 'nearest', { intersect: true }, false)
                if (b.length == 0){
                    return
                }
                const index = b[0].index;
                
                const date = new Date(`${dates[index].split('-').reverse().join('-')}-01`);
                const parameters = { 
                    customerId:this.props.customersId,
                    expense_types:b[0].datasetIndex,
                    user:this.state.filters.user,
                    team:this.state.filters.team,
                    companies_id: this.props.company
                  
                }
                
                const expense_types = this.state.filters.expense_type ;
                updateView({
                    module: 'worktrips', 
                    action: 'list', 
                    start: format(date, 'YYYY-MM-DD'),
                    end: format(endOfMonth(date), 'YYYY-MM-DD'), 
                    ...parameters
            }, bar.ctrlKey || bar.metaKey)},
            scales: {
                y: {
                    stacked: true,
                    beginAtZero: true,
                    ticks: {
                        callback: function(value, index, values) {
                            return currencyFormatter(value);
                        },
                    }
                },
                x: {
                    stacked: true,
                    grid: {display: false},
                }
            }
        };

        const nutChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            title:{
                display: false,
            },
            plugins: {
                legend: {
                    display: false,
                    position: 'right',
                    labels: {
                        boxWidth: 11,
                        padding: 10,
                        fontSize: 11,
                        usePointStyle: true
                    }
                },
                tooltip: {
                    enabled: true
                }    
            }               
        };

        return(
            <div className={`${classNames(classes.overview)} ${!open && "closed"} content-block`} id="overview-expenses">
                <div className={`${classNames(classes.grid)}`}>
                    { this.state.empty ? <div className="grid-item overlayDiv">
                        <div className = {"overlay"} >
                            <ExpensesGraphic className="svg"/>
                            {
                                privileges.worktrips && privileges.worktrips.write ? 
                                    <p>{this.tr("No costs yet")}. <a 
                                        onClick={e => (e.button !== 1) && this.addExpense(e)} 
                                        onMouseUp={e => (e.button === 1) && this.addExpense(e)}>                                        {this.tr("Add expense")}
                                    </a></p>
                                : <p>{this.tr("There are no expenses")}</p>
                            }
                        </div>
                    </div> : undefined }
                    <div className="grid-item">

                    {this.props.insightFilters && <div className="header">
                        <div className="header-group">
                            <Button
                                size="small"
                                variant="text"
                                onClick={this.handleToggle} >

                                {this.tr("Expenses Overview")} {!open ? <ExpandMore /> : <ExpandLess />}
                            </Button>
                        </div>
                    </div>}
            
                    {!this.props.insightFilters && <div className="header">
                            <Button 
                                size="small" 
                                variant="text" 
                                onClick={this.handleToggle} >

                                   {this.tr("Expenses Overview")} {!open ? <ExpandMore /> : <ExpandLess />}
                            </Button>
                            <div className="header-group">
                                <DateRangePicker
                                    className="daterange"
                                    ranges={[dateRange]}
                                    onChange={this.onDateChange}
                                    onInputChange={this.onDateInputChange}
                                    label={this.tr("Time span")}
                                    dateFormat={userObject.dateFormat} />                             
                                <OutlinedField 
                                    select
                                    className="datalist"
                                    InputLabelProps={{classes:{root: classNames(classes.label), shrink: classNames(classes.shrink)}}}
                                    InputProps={{classes:{input: classNames(classes.input)}}}
                                    label={this.tr("User")}
                                    name='userSelect'
                                    value={filters.user}
                                    onChange={this.setFilter('user')} >
                                        <MenuItem value={undefined}>{this.tr("Show All")}</MenuItem>
                                        {users && users.map(u => 
                                            <MenuItem key={u.id} value={u.id} >{u.name}</MenuItem>
                                        )}
                                </OutlinedField>
                                <OutlinedField 
                                    select
                                    className="datalist"
                                    InputLabelProps={{classes:{root: classNames(classes.label), shrink: classNames(classes.shrink)}}}
                                    InputProps={{classes:{input: classNames(classes.input)}}}
                                    label={this.tr("Team")}
                                    name='teamSelect'
                                    value={filters.team}
                                    onChange={this.setFilter('team')} >
                                        <MenuItem value={undefined}>{this.tr("All")}</MenuItem>

                                        {teams && teams.map(t => 
                                            <MenuItem key={t.id} value={t.id} >{t.name}</MenuItem>
                                        )}
                                </OutlinedField>  
                                <OutlinedField 
                                    select
                                    className="datalist"
                                    InputLabelProps={{classes:{root: classNames(classes.label), shrink: classNames(classes.shrink)}}}
                                    InputProps={{classes:{input: classNames(classes.input)}}}
                                    label={this.tr("Type")}
                                    name='typeSelect'
                                    value={filters.type}
                                    onChange={this.setFilter('type')} >
                                        <MenuItem value={undefined}>{this.tr("Show All")}</MenuItem>

                                        {types && types.map(t => 
                                            <MenuItem key={t.id} value={t.id} >{t.label}</MenuItem>
                                        )}
                                </OutlinedField>                         
                        </div>
                    </div>}
                    <br />
                    {open && (
                        <React.Fragment>
                            {
                              // <div  ref={this.element} className={classNames(classes.chartsWrapper, this.state.elementWidth <= 1230 && "divider")}></div>  
                            }
                            <div  ref={this.element} className={classNames(classes.chartsWrapper)}>
                                <div className={classNames(classes.barchartWrapper, "bar-wrapper")}>
                                    {
                                        // <Bar key={this.state.elementWidth <= 1230 ? "chart" : "chart-big"} ref={(bar) => this.bar = bar}
                                    }
                                    <Bar key={"chart"} ref={(bar) => this.bar = bar}
                                        data={expensesBarData}
                                        height={300}
                                        options={barChartOptions}
                                    />
                                </div>
                                <div className={classNames(classes.nutchartWrapper, "nut-wrapper")}>
                                    <div className={classNames(classes.nutLegend)}>
                                        <p className={classNames(classes.paragraph)} >{this.tr("Total spending by type")}</p>
                                        <p className={classNames(classes.total, classes.paragraph)} >{currencyFormatter(total)}</p>
                                    </div>   
                                    <Doughnut ref={(nut) => this.nut = nut}
                                        data={datasets.nut}
                                        height={200}
                                        width={200}
                                        options={nutChartOptions}
                                    />                                
                                </div>
                            </div>
                            <div className="legend-container">
                                {datasets.bar.map((type, i) => (
                                    <div key={i} onClick={() => this.toggleTypeHidden(type.id)} className={hiddenTypes[type.id] ? 'disabled' : ''}>
                                        <div className="color-indicator" style={{ backgroundColor: type.backgroundColor }} />
                                        <p>{type.name} {type.id}</p>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    )}
                    </div>
                </div>
            </div>
        )
    }

}

export default withStyles(styles, { withTheme: true })(ExpensesOverView);
