import getColumnType, { ColumnDefinition, CalendarHeaderTemplate, columnSorter } from './ColumnTypes';
import { CustomFields } from './CustomFields';
import { filterColumns } from "./ColumnVisibility";
import { format } from 'date-fns';

const columnOrder = [
    "user_id",
    "firstname",
    "lastname",
    "title",
    "position",
    "company",
    "email",
    "phone",
    "team",
    "superior",
    "employment_start",
    "employment_end",
    "created_date",
    "activated_date",
    "locked_date",
    "status",
    "bank_account",
    "permission_groups",
    "overtime_balancestart",
    "hourly_price",
    "internal_cost",
    "type",
];

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
    startDate: Date | null;
    endDate: Date | null;
    dateFormat: string;
    settingsContext: any;
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
}

export default function getUserColumns(params: Params) {
    const calendarHeader = {
        tr: params.tr,
        startDate: params.startDate ? format(params.startDate, params.dateFormat) : null,
        endDate: params.endDate ? format(params.endDate, params.dateFormat) : null,
    };

    const columns: ColumnDefinition[] = [    
        {
            field: 'user_id',
            headerName: 'user id',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('persons', 'userlist_read', r.companyid),
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'firstname',
            headerName: 'First name',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('persons', 'userlist_read', r.companyid),
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'lastname',
            headerName: 'Last name',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('persons', 'userlist_read', r.companyid),
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'company',
            headerName: 'Company',
            ...getColumnType('text'),
        },
        {
            field: 'title',
            headerName: 'Title',
            ...getColumnType('text'),
        },
        {
            field: 'position',
            headerName: 'Position',
            ...getColumnType('text'),
        },
        {
            field: 'type',
            headerName: 'User type',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    { id: 0, "name": "Freelancer" },
                    { id: 1, "name": "User" },
                ],
                tr: params.tr
            } 
        },
        {
            field: 'email',
            headerName: 'Email',
            ...getColumnType('emailLink'),
        },
        {
            field: 'phone',
            headerName: 'Phone',
            ...getColumnType('text'),
        },
        {
            field: 'teams',
            headerName: 'Teams',
            ...getColumnType('multi'),
        },
        {
            field: 'permission_groups',
            headerName: 'Permission groups',
            ...getColumnType('multi'),
        },
        {
            field: 'superior',
            headerName: 'Supervisor',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('persons', 'userlist_read', r.companyid),
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            
            field: 'status',
            headerName: 'Status',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    { id: 1, name: 'Locked', color: '#F7548F' },
                    { id: 2, name: 'Active', color: '#28A589' },
                ],
                tr: params.tr
            },
        },
        // {
            // field: 'dimension_team_name',
            // headerName: 'Dimension team',
            // ...getColumnType('text'),
        // },
        // {
            // field: 'dimension_team_leader',
            // headerName: 'Dimension team leader',
            // ...getColumnType('link'),
            // cellRendererParams: {
                // url: "index.html?module=user&action=view&id=$(id)&company=$(companyid)",
                // displayLinkAsText: (r) => !params.checkPrivilege('users', 'read', r.relation_company_id),
            // },
            // defaultAggFunc: 'sumAndGroup',
        // },
        {
            field: 'employment_start',
            headerName: 'Employment start date',
            ...getColumnType('date', null, 'employee_start'),
        },
        {
            field: 'employment_end',
            headerName: 'Employment end date',
            ...getColumnType('date', null, 'employee_end'),
        },
        {
            field: 'created_date',
            headerName: 'Creation date',
            ...getColumnType('date', null, 'created_date'),
        },
        {
            field: 'activated_date',
            headerName: 'Activation date',
            ...getColumnType('date', null, 'activated_date'),
        },
        {
            field: 'locked_date',
            headerName: 'Closed',
            ...getColumnType('date', null, 'locked_date'),
        },
        {
            field: 'overtime_balancestart',
            headerName: 'Overtime balance start date',
            ...getColumnType('date', null, 'overtime_balancestart'),
        },
        {
            field: 'bank_account',
            headerName: 'Bank account',
            ...getColumnType('text'),
        },
        {
            field: 'internal_cost',
            headerName: 'Internal cost',
            ...getColumnType('currency'),
        },
        {
            field: 'hourly_price',
            headerName: 'Hourly price',
            ...getColumnType('currency'),
        },
        {
            field: 'netvisor_id',
            headerName: 'Netvisor ID',
            ...getColumnType('text'),
        },
        {
            field: 'procountor_id',
            headerName: 'Procountor ID',
            ...getColumnType('text'),
        },
        {
            field: 'mepco_id',
            headerName: 'Mepco/eSalary ID',
            ...getColumnType('text'),
        },
        {
            field: 'm2_id',
            headerName: 'M2 ID',
            ...getColumnType('text'),
        },
        {
            field: 'hr_id',
            headerName: 'HR ID ',
            ...getColumnType('text'),
        },
        // Hours etc.
        {
            field: 'hours',
            headerName: 'Hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            useValueFormatterForExport: true,
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
        },
        // {
            // field: 'billed_hours',
            // headerName: 'Billed Hours',
            // ...getColumnType('hour'),
            // grandTotal: 'sum',
            // defaultAggFunc: 'sum',
            // postfix: 'h',
        // },
        // {
            // field: 'non_billed_hours',
            // headerName: 'Non Billed Hours',
            // ...getColumnType('hour'),
            // grandTotal: 'sum',
            // defaultAggFunc: 'sum',
            // postfix: 'h',
        // },
        {
            field: 'billable_hours',
            headerName: 'Billable Hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
        },
        {
            field: 'non_billable_hours',
            headerName: 'Non Billable Hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
        },
        {
            field: 'vacation_and_leave_hours',
            headerName: 'Vacation/leave hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
        },
        {
            field: 'balance',
            headerName: 'Hour balance',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
        },
        {
            field: 'expected',
            headerName: 'Expected hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
        },
        // {
            // field: 'resourced_hours',
            // headerName: 'Resourced hours',
            // ...getColumnType('hour'),
            // grandTotal: 'sum',
            // defaultAggFunc: 'sum',
            // postfix: 'h',
            // headerComponentParams: {
                // template: CalendarHeaderTemplate(calendarHeader),
            // },
        // },
        {
            field: 'billable_rate_tracked',
            headerName: 'Billable rate (tracked hours)',
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'billable_hours', divisor: 'hours_excl_absences' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
        },
        {
            field: 'billable_rate_expected',
            headerName: 'Billable rate (expected hours)',
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'billable_hours', divisor: 'expected_excl_absences' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
        },
        // {
            // field: 'utilization_rate',
            // headerName: 'Utilization rate',
            // ...getColumnType('percentage'),
            // calculateGroupedRate: { dividend: 'resourced_hours', divisor: 'expected_excl_absences' },
            // defaultAggFunc: 'sumAndGroup',
            // sumAndGroupType: 'sum',
            // headerComponentParams: {
                // template: CalendarHeaderTemplate(calendarHeader),
            // },
        // },
    ];

    columns.sort(columnSorter(columnOrder));
    
    return filterColumns(
        columns, 
        "users", 
        params.settingsContext
    );
}
