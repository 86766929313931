import React from 'react';
import { Add, Edit, Delete, AccessTime, CheckBox } from '@mui/icons-material';
import moment from 'moment/min/moment-with-locales';
import { Popover } from '@mui/material';

import TaimerComponent from '../../TaimerComponent';
import DataHandler from '../../general/DataHandler';
import colors from '../../colors';
import ConfirmationPopUp from '../../resourcing/views/ResourcingGrid/components/ConfirmationPopUp';
import { getActivityTypes } from '../../Data';
import { withSnackbar } from 'notistack';
import MyDayDateRangeModal from '../../dashboard/my_day/components/MyDayDateRangeModal';
import MyDayList from '../../dashboard/my_day/components/MyDayList';
import ActivitiesListRow from '../../dashboard/my_day/components/ActivitiesListRow';
import { ReactComponent as MarkAsDoneAndNewIcon } from '../../dialogs/icons/MarkDoneNew.svg';
import { ReactComponent as EmptyImage } from '../../dashboard/images/ActivitiesGraphic.svg';

class NewActivitiesList extends TaimerComponent {
    static defaultProps = {
        parentComponent: false,
    };

    constructor(props, context) {
        super(props, context, 'list/lists/NewActivitiesList');
        this.myDayList = React.createRef();

        this._rowMenuItems = [
            {
                icon: CheckBox,
                label: this.tr('Mark activity as done'),
                action: (data) => this._markAsDone(data),
                isWriteItem: true,
            },
            {
                icon: MarkAsDoneAndNewIcon,
                label: this.tr('Mark as done and create new'),
                action: (data) => this._markAsDoneAndCreateNew(data),
                isWriteItem: true,
            },
            {
                icon: AccessTime,
                label: this.tr('Track hours'),
                action: (data) => this._openHourEntryDialog(data),
            },
            {
                icon: Edit,
                label: this.tr('Edit activity'),
                action: (data) => this._editActivity(data),
                isWriteItem: true,
            },
            {
                icon: Delete,
                label: this.tr('Delete'),
                color: colors.faded_red,
                action: (data) => this._showDeleteConfirmation(data),
                isWriteItem: true,
            },
        ];

        this._columns = [
            {
                field: 'actions',
                name: 'actions',
                header: '',
                width: 50,
                content: this._hasWriteRight() ? this._renderAddButton() : null,
                resizeable: false,
                moveable: false,
                hideable: false,
                showMenu: false,
            },
            {
                field: 'type',
                name: 'type',
                header: this.tr('Type'),
                width: 70,
                resizeable: false,
                moveable: false,
                hideable: false,
                showMenu: false,
            },
            {
                field: 'description',
                name: 'description',
                header: this.tr('Description'),
                width: 200,
                resizeable: true,
                moveable: true,
                hideable: false,
                showMenu: false,
            },
            {
                field: 'status',
                name: 'status',
                header: this.tr('Status'),
                width: 120,
                resizeable: true,
                moveable: true,
                hideable: false,
                showMenu: false,
            },
            {
                field: 'reminder',
                name: 'reminder',
                header: this.tr('Reminder'),
                width: 150,
                resizeable: true,
                moveable: true,
                hideable: false,
                showMenu: false,
            },
            {
                field: 'contact',
                name: 'contact',
                header: this.tr('Contact'),
                width: 150,
                resizeable: true,
                moveable: true,
                hideable: false,
                showMenu: false,
            },
            {
                field: 'user',
                name: 'user',
                header: this.tr('User'),
                width: 200,
                resizeable: true,
                moveable: true,
                hideable: false,
                showMenu: false,
            },
            {
                field: 'project_account',
                name: 'project_account',
                header: this.tr('Project / Account'),
                width: 200,
                resizeable: true,
                moveable: true,
                hideable: false,
                showMenu: false,
            },
        ].filter((c) => (this.props.columnKeys || []).indexOf(c.field) != -1 || !this.props.columnKeys);

        this.stickySearchKey = 'new_activities_list';

        this.state = {
            activityTypes: [],
            updatedValues: {},
            pageData: {
                perPage: 30,
                page: 1,
            },
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.initializeStickySearch();

        window.addEventListener('activitySaved', this.getData);
    };

    initializeStickySearch = () => {
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` })
            .done((response, _, request) => {
                if (request.status !== 200) {
                    this._getActivityTypes().then(this.getData);
                    return;
                }
                this.setState({ ...response, pageData: { ...this.state.pageData, perPage: response.perpage || this.state.pageData.perPage } }, () => this._getActivityTypes().then(this.getData));
            })
            .fail(() => {
                this._getActivityTypes().then(this.getData);
            });
    };

    saveStickySearch = () => {
        const filters = {
            perpage: this.state.pageData.perPage,
        };
        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}` }, { search: filters });
    };

    componentWillUnmount() {
        super.componentWillUnmount();

        window.removeEventListener('activitySaved', this.getData);
    }

    _getActivityTypes = () => {
        const company = this.props.companyId || this.context.userObject.companies_id;
        return new Promise((resolve) => {
            getActivityTypes('all').then((activityTypes) => {
                this.setState({ activityTypes }, () => {
                    resolve();
                });
            });
        });
    };

    _markAsDone = (data) => {
        const activity = {
            activity_id: data.id,
            projects_id: data.projects_id,
            ready: 1,
        };
        DataHandler.post({ url: `activities/state` }, { activity }).done(() => {
            this._showSnackbar(this.tr('Changes saved successfully!'), true);
            setTimeout(() => {
                this.getData();
            }, 1000);
        });
    };

    _markAsDoneAndCreateNew = (data) => {
        this._markAsDone(data);
        this._addNewActivity(data);
    };

    _openHourEntryDialog = (data) => {
        this.context.functions.addHours({
            customer: {
                id: data.customer_id || 0,
            },
            project: {
                id: data.projects_id || 0,
            },
        });
    };

    onPageSettingsChange = (pageData) => {
        this.setState({ pageData: { ...this.state.pageData, ...pageData } }, () => this.getData());
    };

    onSortRows = (sortby, sortasc) => {
        this.setState({ pageData: { ...this.state.pageData, page: 1 } }, () => this.props.onSortRows && this.props.onSortRows(sortby, sortasc));
    };

    getData = () => {
        this.saveStickySearch();
        this.props.getData && this.props.getData(this.props.project, this.state.pageData, this.state.activityTypes);
    };

    _hasWriteRight = () => {
        const {
            functions: { checkPrivilege },
        } = this.context;
        return checkPrivilege('projects', 'project_crm_write') || checkPrivilege('customers', 'customer_crm_write');
    };

    _renderAddButton = () => {
        if (!this._hasWriteRight()) return null;
        return (
            <div className="add-button-container">
                <button onClick={this._addNewActivity}>
                    <Add className="icon" />
                </button>
            </div>
        );
    };

    _closePopup = () => {
        this.props.setPopUp(null);
    };

    _showDeleteConfirmation = (row) => {
        const activity = row.description;
        const popUpComponent = (
            <ConfirmationPopUp
                header={this.tr('Are you sure?')}
                desc={this.tr('Are you sure you want to remove activity "${activity}"? Removing this deletes data for this activity across all your accounts. This change cannot be undone.', {
                    activity,
                })}
                leftButtons={[
                    {
                        label: this.tr('Cancel'),
                        style: { backgroundColor: 'transparent', color: '#34495e' },
                        onClick: this._closePopup,
                    },
                ]}
                rightButtons={[
                    {
                        label: this.tr('Delete'),
                        style: { backgroundColor: '#dc4054', color: 'white' },
                        onClick: () => {
                            this._closePopup();
                            this._deleteActivity(row);
                        },
                    },
                ]}
            />
        );
        this.props.setPopUp(popUpComponent);
    };

    _deleteActivity = (data) => {
        DataHandler.request('DELETE', {
            url: `activities/${data.id}/${data.type}`,
        }).done(() => {
            this._showSnackbar(this.tr('Activity deleted successfully!'), true);
            this.getData();
        });
    };

    _showSavingSnackbar = () => {
        const { enqueueSnackbar } = this.props;
        return enqueueSnackbar(this.tr('Saving...'), {
            variant: 'info',
            persist: true,
        });
    };

    _closeSnackbar = (key) => {
        const { closeSnackbar } = this.props;
        closeSnackbar(key);
    };

    _showSnackbar = (message, success) => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    _editActivity = (activity) => {
        const activityData = {
            activity_id: activity.id,
            projects_id: activity.projects_id,
        };

        const activityModule = activity.type;

        DataHandler.get({ url: `activities/get`, activity: activityData })
            .done((data) => {
                const dialogData = {
                    id: data.id,
                    activityModule,
                    type_id: data.type_id,
                    description: data.description,
                    ready: data.ready,
                    date: data.date,
                    customers_id: data.customers_id,
                    projects_id: data.projects_id,
                    assigned_to_id: data.assigned_to,
                    contact: data.contact_id,
                    activity_date: data.activity_date,
                    companies_id: this.props.companyId || data.project_companies_id || this.context.userObject.companies_id,
                    customer: {
                        id: data.customers_id,
                        name: activity.customer,
                    },
                    ...(!activity.project ? {} : {
                        project: {
                            id: data.projects_id,
                            name: activity.project,
                        }
                    }),
                };
                this._openDialog(dialogData);
            })
            .fail((e) => console.log(e));
    };

    _addNewActivity = (data = undefined) => {
        if (data.customer_id) {
            this._openDialog({
                customers_id: data.customer_id,
                projects_id: data.projects_id == "" ? undefined : data.projects_id,
                companies_id: data.created_by_company,
            });
        } else 
        if (this.props.project) {
            this._openDialog({
                customers_id: this.props.project.customers_id,
                projects_id: this.props.project.id,
                companies_id: this.props.companyId,
            });
        } else {
            this._openDialog({});
        }
    };

    _getEmptyOverlayContent = () => {
        return this._hasWriteRight() ? (
            <p>
                {this.tr('Nothing to do?')} <a data-testid="add-first-activity" onClick={this._addNewActivity}>{this.tr('Add an activity')}</a>
            </p>
        ) : (
            <p>{this.tr('There are no activities')}</p>
        );
    };

    _openDialog = (dialogData) => {
        this.context.functions.openActivitySlider(dialogData, { afterSave: this.getData });
    };

    _setDates = (dateRange, activity) => {
        if (!dateRange.endDate) return;
        this._updateActivity('date', moment(dateRange.endDate).format('YYYY-MM-DD'), activity);
    };

    _setType = (type, activity) => {
        this._updateActivity('type_id', type.id, activity);
    };

    _getEditPopUpComponent = (column, data) => {
        switch (column) {
            case 'reminder': {
                const dateRange = {
                    startDate: data.date && data.date != '0000-00-00' ? moment(data.date, 'YYYY-MM-DD').toDate() : new Date(),
                    endDate: data.date && data.date != '0000-00-00' ? moment(data.date, 'YYYY-MM-DD').toDate() : new Date(),
                    key: 'selection',
                };
                return (
                    <MyDayDateRangeModal
                        initialDateRange={dateRange}
                        mode={'date'}
                        saveChanges={(dateRange) => {
                            this.setState({ rowEditData: null });
                            this._setDates(dateRange, data);
                        }}
                    />
                );
            }
            case 'type':
                return (
                    <div className="my-day-activity-type-list-container">
                        {(this.state.activityTypes || [])
                            .filter((type) => type.companies_id == data.targeted_to_company)
                            .map((type) => {
                                let icon = type.icon || '';
                                if (!type.old_icon_set) icon = this.props.icons[icon];

                                return (
                                    <div
                                        onClick={() => {
                                            this.setState({ rowEditData: null });
                                            this._setType(type, data);
                                        }}
                                        className="my-day-icon-menu-row"
                                    >
                                        <div className="my-day-activity-type-icon">{icon && {icon}}</div>
                                        {type[this.props.nameField] || type.name}
                                    </div>
                                );
                            })}
                    </div>
                );
            default:
                return null;
        }
    };

    _updateActivity = (key, value, activity) => {
        const updatedValues = {
            ...this.state.updatedValues,
            [activity.key]: {
                [key]: value,
            },
        };

        this.setState({ updatedValues }, () => {
            const { id, type } = activity;
            // const snackKey = this._showSavingSnackbar();
            DataHandler.post({ url: `activities/update` }, { activity_id: id, type, name: key, value })
                .done(() => {
                    // this._closeSnackbar(snackKey);
                    window.dispatchEvent(new Event('activitySaved'));
                    this._showSnackbar(this.tr('Changes saved successfully!'), true);
                    setTimeout(() => {
                        this.getData();
                    }, 1000);
                })
                .fail(() => {
                    this.props.stopLoading && this.props.stopLoading();
                });
        });
    };

    setActivityDesc = (desc, activity) => {
        this._updateActivity('description', desc, activity);
    };

    showEditPopUp = (anchor, column, data) => {
        this.setState({
            rowEditData: {
                anchor,
                data,
                component: this._getEditPopUpComponent(column, data),
            },
        });
    };

    _renderRowEditPopUp = () => {
        const { rowEditData } = this.state;
        if (!rowEditData) return null;
        const { anchor, component } = rowEditData;
        return (
            <Popover
                open={Boolean(anchor)}
                elevation={4}
                anchorEl={anchor}
                onClose={() => this.setState({ rowEditData: null })}
                anchorOrigin={{
                    vertical: 48,
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {component}
            </Popover>
        );
    };

    _getColumns = () => {
        if (this.props.columns) {
            return [this._columns[0], ...this.props.columns];
        }
        return this._columns;
    };

    render() {
        const { pageData, updatedValues } = this.state;
        const { data, dataLoaded, totalCount, pageCount, emptyOverlayTop, height, offsetTop, trimHeight } = this.props;
        const {
            functions: { checkPrivilege },
        } = this.context;
        return (
            <div id="new-activities-list">
                <MyDayList
                    ref={this.myDayList}
                    data={data}
                    columns={this._getColumns()}
                    onColumnOrderChange={this.props.onColumnOrderChange}
                    offsetTop={offsetTop}
                    height={height}
                    trimHeight={trimHeight}
                    rowKey="key"
                    emptyOverlayData={{
                        image: EmptyImage,
                        content: this._getEmptyOverlayContent(),
                        top: emptyOverlayTop,
                    }}
                    showPageSelector={true}
                    onSortRows={(sortby, sortasc) => {
                        this.onSortRows(sortby, sortasc);
                    }}
                    pageCount={pageCount}
                    totalCount={totalCount}
                    page={pageData.page}
                    controlPage={true}
                    perpage={pageData.perPage}
                    onPerPageChange={(perPage) => {
                        this.onPageSettingsChange({ perPage, page: 1 });
                    }}
                    onPageChange={(page) => {
                        this.onPageSettingsChange({ page: page });
                    }}
                    sharedData={{
                        menuItems: this._rowMenuItems,
                        showEditPopUp: this.showEditPopUp,
                        setActivityDesc: this.setActivityDesc,
                        hasProjectRight: checkPrivilege('projects', 'project_crm_write'),
                        hasAccountRight: checkPrivilege('customers', 'customer_crm_write'),
                        updatedValues,
                    }}
                    showEmptyOverlay={(data || []).length == 0 && dataLoaded}
                    listRowType={ActivitiesListRow}
                    rowProps={{
                        enqueueSnackbar: this.props.enqueueSnackbar,
                    }}
                    userListSettingsKey="newactivities_list"
                    saveColumnConfig={true}
                    fluid={this.props?.fluid ?? false}
                />
                {this._renderRowEditPopUp()}
            </div>
        );
    }
}

export default withSnackbar(NewActivitiesList);
