import React from "react";


import { SettingsContext } from "../SettingsContext";
import TaimerComponent from "../TaimerComponent";
import DataHandler from "../general/DataHandler";

import CustomViewBase, { ViewBlock } from "../customview/base/CustomViewBase";

import styles from './ProjectStatistics.module.scss';
import ProjectListBlock from "../customview/blocks/ProjectList/ProjectListBlock";
import colors from "../colors";
import { ProjectLocked } from "../types/project";
import Keyfigures from "../customview/blocks/Keyfigures";
import { forEach, keyBy, sortBy, values } from "lodash";
import TableBlock from "../customview/blocks/Table/TableBlock";
import InvoicedVSSalesRow from "./InvoicedVSSalesRow";
import InvoiceMaterialBlock from "../customview/blocks/InvoiceMaterial/InvoiceMaterialBlock";
import UnbilledHoursBlock from "../customview/blocks/UnbilledHours/UnbilledHoursBlock";
import HourEntriesRow from "./HourEntriesRow";
import SummaryRow from "./SummaryRow";
import UserSummaryRow from "./UsersSummaryRow";
import BudgetedVsActualCostRow from "./BudgetedVsActualCostRow";
import MaterialChart from "../customview/blocks/Chart/MaterialChart";
import { parse } from "date-fns";
import InvoicesOverviewChart from "../customview/blocks/Chart/InvoicesOverviewChart";
import VersionContentManager from "../general/VersionContentManager";
import BlockBase from "../customview/blocks/BlockBase";
import { Doughnut } from "react-chartjs-2";
import ChartOptions from "../general/ChartOptions";
import { nmultiply, roundToFixedNumber } from "../general/MathUtils";
import { CurrencyUtils } from "../general/CurrencyUtils";
import moment from 'moment/min/moment-with-locales';

export type ProjectListFilterName = 'active' | 'closed' | 'onHold';
export type InvoiceMaterialTypeFilterName = 'hour_entry' | 'scheduled_invoice' | 'expense' | 'quote_row' | 'received_invoice' | 'travel_expense' | 'manually_added_cost';

const PROGRESS_GREEN = '#42b677';
const PROGRESS_RED = '#e49ebb';

const ignoredUpdates = {
    id: true,
};

interface Props {
    project: {
        id: number;

        account: {
            id: number;
        }
    };
    currency: string;
    checkPrivilege: any;
}

interface SalesByTypeColumns {
    invoiced: number;
    selling_price: number;
    own_cost: number;
    budgeted_price: number;
    budgeted_vs_selling: number;
    to_be_invoiced: number;
}

interface CostsByTypeColumns {
    budgeted_cost: number;
    actual_cost: number;
    variance: number;
    remaining_cost: number;
}

interface MaterialCosts {
    all: number;
    hours: number;
    expenses: number;
    traveling_expenses: number;
    bills: number;
    quotes: number;
    scheduled_invoices: number;
    automatic_invoices: number;
}

interface InvoicesByStatus {
    draft: number;
    waiting: number;
    sent: number;
    paid: number;
    credited: number;
    overdue: number;
}

export interface MaterialCostsWithDate extends MaterialCosts {
    date: Date;
}

export interface UninvoicedMaterial {
    id: string;
    key: InvoiceMaterialTypeFilterName;
    selected: boolean;
    row_type: InvoiceMaterialTypeFilterName;
    creation_date: string;
    user_or_product: string;
    description: string;
    quantity: number;
    value: number;
    total: number;
    costestimate_id?: string;
    receivedinvoice_id?: string;
    material_id: string;
    product_id?: string;
    cpq_id?: string;
    is_cpq?: string;
    until?: string;
    vat?: number;
    duedate_repeat_increment?: string;
    duedate_repeat?: string;
    users_company?: string;
    users_id?: string;
    projects_id?: string | number;
}

export interface UnbilledHours {
    id: string;
    key?: string;
    parent_id?: number;
    name?: string;
    hours?: string | number;
    is_billable?: boolean;
    billable?: string | number;
    unbillable?: string | number;
    billable_amount?: string | number;
    value?: string | number;
    total?: string | number;
    worktask?: string;
    quantity?: string | number;
    selected?: boolean;
    worktasks_id?: string;
    parentId?: string;
    users_id?: string;
    workinghour_id?: string;
    is_workinghour?: boolean;
    is_header?: boolean;
    is_userheader?: boolean;
    is_total?: boolean;
    username?: string;
    date?: string;
    projects_id?: string;
    children?: string[];
    workinghour_children?: string[];
}

export interface InvoicesByStatusWithDate extends InvoicesByStatus {
    date: Date;
}

export interface UserHourEntry {
    id: number;
    users_name: string;
    user_locked: number; // ?
    users_company: number;
    users_title?: string;
    users_color?: string;
    tracked: number;
}

export interface ProjectActivity {
    done: number;
    due: number;
    overdue: number;
}

interface Project {
    // Overview
    id: number;
    companies_id: number;
    customers_id: number;
    project_id: string;
    name: string;
    parentId: string;
    startdate: string;
    status: string;
    customer: string;
    invoiceable: boolean;
    deleted: number;
    locked: ProjectLocked;

    /**
     * Tracked hours
     */
    hours_done: number;

    /**
     * Allocated to project 
     */
    maxhours: number;

    /**
     * Manual resourced to project (if using useProjectExtraHours)
     */
    extra_maxhours: number;

    // Invoicing
    invoiced: number;
    budgeted_sales: number;
    budgeted_costs: number;
    
    actual_costs: number;
    actual_costs_without_hours: number;

    backlog: number;
    /**
     * Hourly Cost (omakustanne hinta)
     */
    hourly: number;
    total_costs: number;
    /**
     * Expenses to project including invoiced
     */
    expenses: number;

    /**
     * 
     */
    expenses_draft: number;

    /**
     * Travel Expenses to project, including invoiced
     */
    traveling_expenses: number;

    /**
     * 
     */
    traveling_expenses_draft: number;

    bills: number;

    actualProjectMargin: number;
    actualGrossMargin: number;

    actualProjectMargin_cost: number;
    actualGrossMargin_cost: number;

    budgetedProjectMargin: number;
    budgetedGrossMargin: number;
    budgetedGrossMargin_cost: number;
    budgetedGrossMargin_value: number;

    sales_by_type: Record<number, SalesByTypeColumns>;
    costs_by_type: Record<number, CostsByTypeColumns>;
    material: MaterialCosts;
    material_by_date: Record<string, Partial<MaterialCosts>>;
    invoices_by_date: Record<string, Partial<InvoicesByStatus>>;
    uninvoiced_material: Record<string, Partial<UninvoicedMaterial>>;
    unbilled_hours: Record<string, Partial<UnbilledHours>>;


    hour_entries: UserHourEntry[];
    activity: ProjectActivity;
}

interface ProjectWithExtra extends Project {
    selected: boolean;
}

interface State {
    loaded: boolean;
    loading: boolean;
    currency: string;
    defaultvat: number;
    finance_rights: boolean;
    allProjects: Project[];
    projects: ProjectWithExtra[];
    selectedProjectFilters: ProjectListFilterName[];
    materialTypeFilters: InvoiceMaterialTypeFilterName[];
    invoiced: number;
    budgeted_sales: number;
    budgeted_costs: number;
    /**
     * All Invoice Material Total
     */
    invoice_material: number;
    /**
     * Kululaskut (laskuttamaton)
     */
    expenses: number;

    expenses_draft: number;

    /**
     * Matkalaskut (laskuttamaton)
     */
    travel_expenses: number;

    travel_expenses_draft: number;
    /**
     * Kululaskut
     */
    all_expenses: number;
    /**
     * Matkalaskut
     */
    all_travel_expenses: number;
    /**
     * Bills amount (ostolaskut)
     */
    all_bills: number;
    /**
     * Bills amount (ostolaskut), laskuttamaton
     */
    bills: number;
    /**
     * Hourly Cost (omakustanne hinta)
     */
    hourly: number;
    /**
     * Hours charge price (tuntien laskutushinta)
     */
    hours: number;
    backlog: number;
    hours_done: number;
    maxhours: number;
    extra_maxhours: number;
    salesByType: SalesByType[];
    costsByType: CostsByType[];

    actualCostTotal: number;
    actualCostTotalWithoutHours: number;

    materialByDate: MaterialCostsWithDate[];
    materialEarliestDate: Date;
    materialLatestDate: Date;
    uninvoicedMaterial: UninvoicedMaterial[];
    unbilledHours: UnbilledHours[];

    invoicesByStatus: InvoicesByStatus;

    invoiceStatusByDate: InvoicesByStatusWithDate[];
    invoicesEarliestDate: Date;
    invoicesLatestDate: Date;

    actualProjectMargin: number;
    actualGrossMargin: number;
    actualProjectMargin_cost: number;
    actualGrossMargin_cost: number;

    budgetedProjectMargin: number;
    budgetedGrossMargin: number;

    /**
     * Budgeted Costs (excluding hours)
     */
    budgetedGrossMargin_cost: number;

    /**
     * Hour Entries Totals per User
     */
    hour_entries: UserHourEntry[];

    /**
     * Activities stats
     */
    activities: ProjectActivity;
}

interface ProjectTreeResponse {
    finance_rights: boolean;
    projects: Project[];
    currency?: string;
    defaultvat: number;
}

interface ProjectListFilter {
    key: ProjectListFilterName;
    id: string;
    name: string;
    label: string;
    value: string;
    color: string;
}

interface SalesByType extends SalesByTypeColumns {
    type_name: string;
}

interface CostsByType extends CostsByTypeColumns {
    type_name: string;
}

export default class ProjectStatistics extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    projectStatuse: ProjectListFilter[] = [
        { key: "active", id: '-1', name: this.tr('Active'), label: this.tr('Active'), value: '-1', color: colors.greenish_cyan },
        { key: "closed", id: '1', name: this.tr('Closed'), label: this.tr('Closed'), value: '1', color: "#f52b2b" },
        { key: "onHold", id: '2', name: this.tr('On hold'), label: this.tr('On hold'), value: '2', color: "#ffaf0f" }
    ];

    constructor(props, context) {
        super(props, context, "projects/ProjectStatistics");

        this.state = {
            loaded: false,
            loading: true,
            finance_rights: false,
            currency: 'EUR',
            defaultvat: this.context.taimerAccount.defaultVat || 0,
            projects: [],
            allProjects: [],
            selectedProjectFilters: ['active', 'closed', 'onHold'],
            materialTypeFilters: ['hour_entry', 'scheduled_invoice', 'expense', 'travel_expense', 'quote_row', 'received_invoice', 'manually_added_cost'],
            invoiced: 0,
            backlog: 0,
            budgeted_costs: 0,
            budgeted_sales: 0,
            actualGrossMargin: 0,
            actualGrossMargin_cost: 0,
            actualProjectMargin: 0,
            actualProjectMargin_cost: 0,
            budgetedProjectMargin: 0,
            budgetedGrossMargin: 0,
            budgetedGrossMargin_cost: 0,
            invoice_material: 0,
            bills: 0,
            expenses: 0,
            expenses_draft: 0,
            travel_expenses: 0,
            travel_expenses_draft: 0,
            all_expenses: 0,
            all_travel_expenses: 0,
            all_bills: 0,
            hourly: 0,
            hours: 0,
            hours_done: 0,
            maxhours: 0,
            extra_maxhours: 0,
            salesByType: [],
            costsByType: [],
            materialByDate: [],
            materialEarliestDate: new Date(),
            materialLatestDate: new Date(),
            uninvoicedMaterial: [],
            unbilledHours: [],
            invoicesEarliestDate: new Date(),
            invoicesLatestDate: new Date(),
            invoiceStatusByDate: [],
            invoicesByStatus: {
                draft: 0,
                waiting: 0,
                sent: 0,
                paid: 0,
                credited: 0,
                overdue: 0,
            },
            hour_entries: [],
            activities: {
                done: 0,
                due: 0,
                overdue: 0,
            },
            actualCostTotal: 0,
            actualCostTotalWithoutHours: 0,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateProjectTreeData();

        window.addEventListener('projectSaved', this.projectSaved);
        window.addEventListener('activitySaved', this.updateActivityStats);
        window.addEventListener('invoiceMaterialChanged', this.invoiceMaterialChanged);
    }

    componentWillUnmount(): void {
        window.removeEventListener('projectSaved', this.projectSaved);
        window.removeEventListener('activitySaved', this.updateActivityStats);
        window.removeEventListener('invoiceMaterialChanged', this.invoiceMaterialChanged);
    }

    projectSaved = () => {
        setTimeout(() => this.updateProjectTreeData(), 1000);
    }

    updateActivityStats = () => {
        setTimeout(() => this.updateProjectTreeData(), 1000);
    }

    invoiceMaterialChanged = () => {
        setTimeout(() => this.updateProjectTreeData(), 1000);
    }

    updateProjectTreeData = async () => {
        const { project } = this.props;
        const { selectedProjectFilters, projects: previousProjects } = this.state;

        const data = await DataHandler.get({ url: `statistics/project/${project.id}/tree` }) as ProjectTreeResponse;

        const { projects: allProjects } = data;

        const previousData = keyBy(previousProjects, x => x.id);

        const projects: ProjectWithExtra[] = this.filterProjects(allProjects, selectedProjectFilters).map(x => ({
            ...x,
            selected: previousData[x.id]?.selected ?? (x.id == project.id),
        }));

        this.setState({
            finance_rights: data.finance_rights,
            allProjects,
            projects,
            currency: data.currency || 'EUR',
            defaultvat: data.defaultvat,
        }, () => {
            this.setState({
            ...this.calculateValues(projects),
            });
        });
    }

    calculateValues = (projects: ProjectWithExtra[]) => {
        const { defaultvat } = this.state;
        
        let invoiced = 0;
        const invoiced_type = 0;
        let budgeted_sales = 0;
        let budgeted_costs = 0;
        let backlog = 0;
        let invoice_material = 0;
        let expenses = 0;
        let expenses_draft = 0;
        let travel_expenses = 0;
        let travel_expenses_draft = 0;
        let bills = 0;
        let hours = 0;
        let hourly = 0;

        let hours_done = 0;
        let maxhours = 0;
        let extra_maxhours = 0;

        let all_bills = 0;
        let all_expenses = 0;
        let all_travel_expenses = 0;

        let actualProjectMargin = 0;
        let actualProjectMargin_cost = 0;
        let actualGrossMargin = 0;
        let actualGrossMargin_cost = 0;
        let budgetedProjectMargin = 0;

        let budgetedGrossMargin = 0;
        let budgetedGrossMargin_cost = 0;

        let actualCostTotal = 0;
        let actualCostTotalWithoutHours = 0;

        const salesByTypeDefaults: SalesByTypeColumns = {
            invoiced: 0,
            own_cost: 0,
            selling_price: 0,
            budgeted_price: 0,
            budgeted_vs_selling: 0,
            to_be_invoiced: 0,
        }

        const costsByTypeDefaults: CostsByTypeColumns = {
            actual_cost: 0,
            budgeted_cost: 0,
            remaining_cost: 0,
            variance: 0,
        }

        const activity: ProjectActivity = {
            done: 0,
            due: 0,
            overdue: 0,
        }

        const salesByType: Record<string, SalesByType> = {
            1: {
                type_name: 'hours',
                ...salesByTypeDefaults,
            },
            2: {
                type_name: 'bills',
                ...salesByTypeDefaults,
            },
            3: {
                type_name: 'services',
                ...salesByTypeDefaults,
            },
            4: {
                type_name: 'products',
                ...salesByTypeDefaults,
            },
            5: {
                type_name: 'expenses',
                ...salesByTypeDefaults,
            },
            6: {
                type_name: 'scheduled',
                ...salesByTypeDefaults,
            },
            7: {
                type_name: 'automatic',
                ...salesByTypeDefaults,
            },
        }

        const costsByType: Record<string, CostsByType> = {
            1: {
                type_name: 'hours',
                ...costsByTypeDefaults,
            },
            2: {
                type_name: 'bills',
                ...costsByTypeDefaults,
            },
            3: {
                type_name: 'expenses',
                ...costsByTypeDefaults,
            },
        }

        let invoicesEarliestDate: Date | null = null;
        let invoicesLatestDate: Date | null = null;

        let materialEarliestDate: Date | null = null;
        let materialLatestDate: Date | null = null;

        const materialByDate: Record<string, MaterialCostsWithDate> = {};
        const invociesByDate: Record<string, InvoicesByStatusWithDate> = {};
        const invoicesByStatus: InvoicesByStatus = {
            draft: 0,
            waiting: 0,
            sent: 0,
            paid: 0,
            credited: 0,
            overdue: 0,
        };
        const uninvoicedMaterial: Record<string, UninvoicedMaterial> = {};
        const unbilledHours: Record<string, UnbilledHours> = {};

        const hourEntries: Record<string, UserHourEntry> = {};
        let uninvoicedMaterialId = 1;

        for (const project of projects) {
            if (!project.selected)
                continue;

            invoiced += project.invoiced;
            budgeted_sales += project.budgeted_sales;
            budgeted_costs += project.budgeted_costs;
            backlog += project.backlog;
            hourly += project.hourly;

            hours_done += project.hours_done;
            maxhours += project.maxhours;
            extra_maxhours += project.extra_maxhours;

            all_bills += project.bills;
            all_expenses += project.expenses;
            all_travel_expenses += project.traveling_expenses;

            activity.done += project.activity.done;
            activity.due += project.activity.due;
            activity.overdue += project.activity.overdue;

            actualProjectMargin += project.actualProjectMargin;
            actualGrossMargin += project.actualGrossMargin;

            actualProjectMargin_cost += project.actualProjectMargin_cost;
            actualGrossMargin_cost += project.actualGrossMargin_cost;

            budgetedProjectMargin += project.budgetedProjectMargin;
            budgetedGrossMargin += project.budgetedGrossMargin;
            budgetedGrossMargin_cost += project.budgetedGrossMargin_cost;

            expenses_draft += project.expenses_draft;
            travel_expenses_draft += project.traveling_expenses_draft;

            actualCostTotal += project.actual_costs;
            actualCostTotalWithoutHours += project.actual_costs_without_hours;

            for (const key in project.material_by_date) {
                if (Object.prototype.hasOwnProperty.call(project.material_by_date, key)) {
                    const element = project.material_by_date[key];

                    const date = parse(key, 'YYYY-MM-DD', new Date());

                    if (materialEarliestDate == null || materialEarliestDate > date) {
                        materialEarliestDate = date;
                    }
                    if (materialLatestDate == null || materialLatestDate < date) {
                        materialLatestDate = date;
                    }

                    if (!materialByDate[key]) {
                        materialByDate[key] = {
                            date,
                            all: 0,
                            bills: 0,
                            expenses: 0,
                            hours: 0,
                            quotes: 0,
                            traveling_expenses: 0,
                            automatic_invoices: 0,
                            scheduled_invoices: 0,
                        };
                    }

                    invoice_material += element.all ?? 0;
                    expenses += element.expenses ?? 0;
                    travel_expenses += element.traveling_expenses ?? 0;
                    bills += element.bills ?? 0;
                    hours += element.hours ?? 0;

                    materialByDate[key].all += element.all ?? 0;
                    materialByDate[key].bills += element.bills ?? 0;
                    materialByDate[key].expenses += element.expenses ?? 0;
                    materialByDate[key].hours += element.hours ?? 0;
                    materialByDate[key].quotes += element.quotes ?? 0;
                    materialByDate[key].traveling_expenses += element.traveling_expenses ?? 0;
                    materialByDate[key].automatic_invoices += element.automatic_invoices ?? 0;
                    materialByDate[key].scheduled_invoices += element.scheduled_invoices ?? 0;
                }
            }

            for (const key in project.invoices_by_date) {
                if (Object.prototype.hasOwnProperty.call(project.invoices_by_date, key)) {
                    const element = project.invoices_by_date[key];
                    const date = parse(key, 'YYYY-MM-DD', new Date());

                    if (invoicesEarliestDate == null || invoicesEarliestDate > date) {
                        invoicesEarliestDate = date;
                    }
                    if (invoicesLatestDate == null || invoicesLatestDate < date) {
                        invoicesLatestDate = date;
                    }

                    if (!invociesByDate[key]) {
                        invociesByDate[key] = {
                            date,
                            credited: 0,
                            draft: 0,
                            overdue: 0,
                            paid: 0,
                            sent: 0,
                            waiting: 0,
                        };
                    }

                    invociesByDate[key].credited += element.credited ?? 0;
                    invociesByDate[key].draft += element.draft ?? 0;
                    invociesByDate[key].overdue += element.overdue ?? 0;
                    invociesByDate[key].paid += element.paid ?? 0;
                    invociesByDate[key].sent += element.sent ?? 0;
                    invociesByDate[key].waiting += element.waiting ?? 0;

                    invoicesByStatus.credited += element.credited ?? 0;
                    invoicesByStatus.draft += element.draft ?? 0;
                    invoicesByStatus.overdue += element.overdue ?? 0;
                    invoicesByStatus.paid += element.paid ?? 0;
                    invoicesByStatus.sent += element.sent ?? 0;
                    invoicesByStatus.waiting += element.waiting ?? 0;
                }
            }

            for (const key in project.uninvoiced_material?.invoice_data_rows) {
                const materialKey = project.id + "_" + key; // Add unique key for material rows array.

                if (project.uninvoiced_material?.invoice_data_rows[key].billable == undefined 
                    || (Number(project.uninvoiced_material?.invoice_data_rows[key].charge_hours) > 0 && (moment(project.uninvoiced_material?.invoice_data_rows[key].creation_date) >= moment(project.uninvoiced_material?.invoice_data_rows[key].charge_hours_after) || project.uninvoiced_material?.invoice_data_rows[key].charge_hours_after == "0000-00-00"))) {
                    if (!uninvoicedMaterial[materialKey]) {

                        uninvoicedMaterial[materialKey] = {
                            id: uninvoicedMaterialId.toString(),
                            row_type: 'hour_entry',
                            selected: sessionStorage.getItem("invoiceMaterialPreselect")?.includes(project.uninvoiced_material?.invoice_data_rows[key].id) ? true : false,
                            key: project.uninvoiced_material?.invoice_data_rows[key].id,
                            creation_date: project.uninvoiced_material?.invoice_data_rows[key].creation_date,
                            description: project.uninvoiced_material?.invoice_data_rows[key].description,
                            quantity: Number(project.uninvoiced_material?.invoice_data_rows[key].quantity),
                            value: Number(project.uninvoiced_material?.invoice_data_rows[key].value),
                            material_id: project.uninvoiced_material?.invoice_data_rows[key].material_id,
                            product_id: project.uninvoiced_material?.invoice_data_rows[key]?.product_register_id,
                            cpq_id: project.uninvoiced_material?.invoice_data_rows[key]?.cpq_id,
                            is_cpq: project.uninvoiced_material?.invoice_data_rows[key]?.cpq,
                            total: 0,
                            user_or_product: '',
                            until: "",
                            vat: Number(project.uninvoiced_material?.invoice_data_rows[key]?.vat ?? defaultvat),
                            duedate_repeat_increment: "",
                            duedate_repeat: "",
                            projects_id: project.id
                        };
                        uninvoicedMaterialId++;
                    }

                    switch (project.uninvoiced_material?.invoice_data_rows[key].row_type) {
                        case "1": { //hour entry
                            uninvoicedMaterial[materialKey].row_type = 'hour_entry';
                            uninvoicedMaterial[materialKey].user_or_product = project.uninvoiced_material?.invoice_data_rows[key].name;
                            uninvoicedMaterial[materialKey].users_company = project.uninvoiced_material?.invoice_data_rows[key].users_company;
                            uninvoicedMaterial[materialKey].users_id = project.uninvoiced_material?.invoice_data_rows[key].users_id;
                            break;
                        }
                        case "2": { //scheduled invoice
                            uninvoicedMaterial[materialKey].row_type = 'scheduled_invoice';
                            uninvoicedMaterial[materialKey].user_or_product = project.uninvoiced_material?.invoice_data_rows[key].product_name ?? '';
                            uninvoicedMaterial[materialKey].until = project.uninvoiced_material?.invoice_data_rows[key].until;
                            uninvoicedMaterial[materialKey].vat = Number(project.uninvoiced_material?.invoice_data_rows[key].vat);
                            uninvoicedMaterial[materialKey].duedate_repeat_increment = project.uninvoiced_material?.invoice_data_rows[key].duedate_repeat_increment;
                            uninvoicedMaterial[materialKey].duedate_repeat = project.uninvoiced_material?.invoice_data_rows[key].duedate_repeat;
                            break;
                        }
                        case "4": { //expense
                            uninvoicedMaterial[materialKey].row_type = 'expense';
                            uninvoicedMaterial[materialKey].user_or_product = this.tr("Other cost");
                            break;
                        }
                        case "5": { //quote row
                            uninvoicedMaterial[materialKey].row_type = 'quote_row';
                            uninvoicedMaterial[materialKey].quantity = Number(project.uninvoiced_material?.invoice_data_rows[key].quantity - project.uninvoiced_material?.invoice_data_rows[key].quantity_invoiced);
                            uninvoicedMaterial[materialKey].user_or_product = project.uninvoiced_material?.invoice_data_rows[key].product_code ?? '';
                            uninvoicedMaterial[materialKey].costestimate_id = project.uninvoiced_material?.invoice_data_rows[key].costestimate_id;

                            const value = project.uninvoiced_material?.invoice_data_rows[key].value;
                            const discount = CurrencyUtils.calculateDiscountAmount(value, project.uninvoiced_material?.invoice_data_rows[key].discount_percent, 2);
                            uninvoicedMaterial[materialKey].value = Number(value) - Number(discount);
                            break;
                        }
                        case "6": { //bill
                            uninvoicedMaterial[materialKey].row_type = 'received_invoice';
                            uninvoicedMaterial[materialKey].receivedinvoice_id = project.uninvoiced_material?.invoice_data_rows[key].material_header;
                            break;
                        }
                        case "7": { // Manually added cost
                            uninvoicedMaterial[materialKey].row_type = 'manually_added_cost';
                            break;
                        }
                        case "8": { //travel expense daily allowance
                            uninvoicedMaterial[materialKey].row_type = 'travel_expense';
                            uninvoicedMaterial[materialKey].user_or_product = this.tr("Daily allowance");
                            break;
                        }
                        case "9": { //travel expense mileage
                            uninvoicedMaterial[materialKey].row_type = 'travel_expense';
                            uninvoicedMaterial[materialKey].user_or_product = this.tr("Mileage");
                            break;
                        }
                        case "10": { //travel expense other cost
                            uninvoicedMaterial[materialKey].row_type = 'travel_expense';
                            uninvoicedMaterial[materialKey].user_or_product = this.tr("Other cost");
                            break;
                        }
                        default: {
                            break;
                        }
                    }

                    if (isNaN(uninvoicedMaterial[materialKey].quantity))
                        uninvoicedMaterial[materialKey].quantity = 0;
                    if (isNaN(uninvoicedMaterial[materialKey].value))
                        uninvoicedMaterial[materialKey].value = 0;

                    uninvoicedMaterial[materialKey].total = roundToFixedNumber(nmultiply(uninvoicedMaterial[materialKey].quantity, uninvoicedMaterial[materialKey].value), 2);
                }
            }

            for (const key in project.unbilled_hours) {

                const worktaskKey = "wt_" + Number(project.unbilled_hours[key].worktasks_id);

                if (!unbilledHours["totalRow"]) {
                    unbilledHours["totalRow"] = {
                        id: "totalRow",
                        key: "totalRow",
                        hours: "",
                        billable: "",
                        unbillable: "",
                        billable_amount: "",
                        total: "",
                        value: 0,
                        worktask: "",
                        quantity: 0,
                        name: this.tr("Total"),
                        parentId: "",
                        is_total: true,
                        children: [],
                        workinghour_children: [],
                    }
                }

                if (!unbilledHours[worktaskKey]) {
                    
                    unbilledHours[worktaskKey] = {
                        id: worktaskKey,
                        key: worktaskKey,
                        hours: "",
                        billable: "",
                        unbillable: "",
                        billable_amount: "",
                        total: "",
                        value: 0,
                        worktask: "",
                        quantity: "",
                        name: project.unbilled_hours[key].name,
                        parentId: "",
                        children: [],
                        workinghour_children: [],
                    };

                    unbilledHours[worktaskKey + "_header"] = {
                        id: worktaskKey + "_header",
                        key: worktaskKey + "_header",
                        hours: "",
                        billable: this.tr("Billable hours"),
                        unbillable: this.tr("Non-billable hours"),
                        billable_amount: this.tr("Billable value"),
                        total: this.tr("Total"),
                        value: this.tr("Hour price"),
                        worktask: "",
                        quantity: this.tr("Quantity"),
                        name: this.tr("User"),
                        parentId: worktaskKey, 
                        is_header: true,
                        is_userheader: true,
                    }

                    unbilledHours["totalRow"].children?.push(worktaskKey);
                }

                const userKey = worktaskKey + "_u_" + Number(project.unbilled_hours[key].users_id);

                if (!unbilledHours[userKey]) {
                    
                    unbilledHours[userKey] = {
                        id: userKey,
                        key: userKey,
                        parentId: "wt_" + Number(project.unbilled_hours[key].worktasks_id),
                        name: project.unbilled_hours[key].username,
                        hours: "",
                        billable: "0",
                        unbillable: "0",
                        billable_amount: "",
                        total: "",
                        value: 0,
                        worktask: "",
                        quantity: "",
                        children: [],
                        workinghour_children: [],
                    };

                    unbilledHours[userKey + "_header"] = {
                        id: userKey + "_header",
                        key: userKey + "_header",
                        hours: "",
                        billable: this.tr("Billable"),
                        unbillable: "",
                        billable_amount: this.tr("Billable value"),
                        total: this.tr("Total"),
                        value: this.tr("Hour price"),
                        worktask: "",
                        quantity: this.tr("Quantity"),
                        name: this.tr("Date"),
                        parentId: userKey, 
                        is_header: true,
                    }

                    unbilledHours["totalRow"].children?.push(userKey);
                    unbilledHours[worktaskKey].children?.push(userKey);
                }

                const workinghourKey = userKey + "_wh_" + String(project.unbilled_hours[key].id);
                if (!unbilledHours[workinghourKey]) {
                    unbilledHours[workinghourKey] = {
                        id: workinghourKey,
                        key: workinghourKey,
                        hours: "",
                        billable: "",
                        unbillable: "",
                        billable_amount: "",
                        total: "",
                        value: 0,
                        worktask: "",
                        quantity: "",
                        parentId: userKey,
                        workinghour_id: project.unbilled_hours[key].id,
                        is_workinghour: true,
                        projects_id:  project.unbilled_hours[key].projects_id,
                    };

                    unbilledHours["totalRow"].children?.push(workinghourKey);
                    unbilledHours[worktaskKey].children?.push(workinghourKey);
                    unbilledHours[userKey].children?.push(workinghourKey);
                }
                //unbilledHours[workinghourKey].id = String(project.unbilled_hours[key].id);
                unbilledHours[workinghourKey].is_billable = Number(project.unbilled_hours[key]?.billable) < 0 ? false : true;
                unbilledHours[workinghourKey].hours = project.unbilled_hours[key].hours;
                unbilledHours[workinghourKey].billable = unbilledHours[workinghourKey].is_billable ? project.unbilled_hours[key].hours : "0";
                unbilledHours[workinghourKey].unbillable = !unbilledHours[workinghourKey].is_billable ? project.unbilled_hours[key].hours : "0";
                unbilledHours[workinghourKey].billable_amount = unbilledHours[workinghourKey].is_billable ? project.unbilled_hours[key].billable_amount : "0";
                unbilledHours[workinghourKey].total = project.unbilled_hours[key].billable_amount;
                unbilledHours[workinghourKey].value = Number(project.unbilled_hours[key].billable_amount) / Number(project.unbilled_hours[key].hours);
                unbilledHours[workinghourKey].worktask = project.unbilled_hours[key]?.name;
                unbilledHours[workinghourKey].quantity = Number(project.unbilled_hours[key]?.hours);
                unbilledHours[workinghourKey].name = project.unbilled_hours[key]?.date;
                

                const totalRows = ["totalRow", worktaskKey, userKey];

                forEach(totalRows, (totalRow) => {
                    unbilledHours[totalRow].quantity = Number(unbilledHours[totalRow].quantity) + Number(unbilledHours[workinghourKey].quantity);
                    unbilledHours[totalRow].billable = Number(unbilledHours[totalRow].billable) + Number(unbilledHours[workinghourKey].billable);
                    unbilledHours[totalRow].unbillable = Number(unbilledHours[totalRow].unbillable) + Number(unbilledHours[workinghourKey].unbillable);
                    unbilledHours[totalRow].billable_amount = Number(unbilledHours[totalRow].billable_amount) + Number(unbilledHours[workinghourKey].billable_amount);
                    unbilledHours[totalRow].total = Number(unbilledHours[totalRow].total) + Number(unbilledHours[workinghourKey].total);
                    unbilledHours[totalRow].value = totalRow !== "totalRow" ? /*Number(unbilledHours[totalRow].value) + */Number(unbilledHours[workinghourKey].value) : "";
                    unbilledHours[totalRow].workinghour_children?.push(workinghourKey);
                });

            }

            forEach(unbilledHours, (row) => {
                if (!row.is_header) {
                    row.quantity = Number(row?.quantity).toFixed(2);
                    row.billable = Number(row?.billable).toFixed(2);
                    row.unbillable = Number(row?.unbillable).toFixed(2);
                }
            });

            forEach(project.sales_by_type, (v, k) => {
                if (salesByType[k]) {
                    salesByType[k].invoiced += v.invoiced;
                    salesByType[k].own_cost += v.own_cost;
                    salesByType[k].selling_price += v.selling_price;
                    salesByType[k].budgeted_price += v.budgeted_price;

                    // Products
                    if (k !== '4') {
                        salesByType[k].budgeted_vs_selling += v.budgeted_vs_selling;
                    }

                    salesByType[k].to_be_invoiced += v.to_be_invoiced;
                }
            });

            forEach(project.costs_by_type, (v, k) => {
                if (costsByType[k]) {
                    costsByType[k].actual_cost += v.actual_cost;
                    costsByType[k].budgeted_cost += v.budgeted_cost;
                    costsByType[k].remaining_cost += v.remaining_cost;
                    costsByType[k].variance += v.variance;
                }
            });

            // Hour Entries
            for (const user of project.hour_entries) {
                if (!hourEntries[user.id]) {
                    hourEntries[user.id] = {
                        ...user,
                    };
                }
                else {
                    hourEntries[user.id].tracked += user.tracked;
                }
            }
        }

        const uninvoicedMaterialSorted = values(uninvoicedMaterial).sort((a, b) => {
            const rTypeResult = (a.row_type || "").localeCompare(b.row_type || "");
            if(rTypeResult != 0) {
                return rTypeResult;
            }
            const uOpResult = (a.user_or_product || "").localeCompare(b.user_or_product || "");
            if(uOpResult != 0) {
                return uOpResult;
            }
            return (a.creation_date || "").localeCompare(b.creation_date || "");

        });

        return {
            invoiced,
            budgeted_sales,
            budgeted_costs,
            backlog,
            hours,
            hourly,

            hours_done,
            maxhours,
            extra_maxhours,

            salesByType: values(salesByType),
            costsByType: values(costsByType),
            uninvoicedMaterial: uninvoicedMaterialSorted,
            unbilledHours: values(unbilledHours),

            actualCostTotal,
            actualCostTotalWithoutHours,

            materialByDate: values(materialByDate),
            materialEarliestDate: materialEarliestDate ?? new Date(),
            materialLatestDate: materialLatestDate ?? new Date(),

            invoicesByStatus,
            invoiceStatusByDate: values(invociesByDate),

            invoicesEarliestDate: invoicesEarliestDate ?? new Date(),
            invoicesLatestDate: invoicesLatestDate ?? new Date(),

            invoice_material,
            bills,
            expenses,
            expenses_draft,
            travel_expenses,
            travel_expenses_draft,

            all_bills,
            all_expenses,
            all_travel_expenses,

            actualProjectMargin,
            actualProjectMargin_cost,
            actualGrossMargin,
            actualGrossMargin_cost,

            budgetedProjectMargin,
            budgetedGrossMargin,
            budgetedGrossMargin_cost,

            hour_entries: sortBy(values(hourEntries), x => -x.tracked),
            activities: activity,
        }
    }

    toggleUninvoicedMaterialRowCheckbox = (key, selected) => {
        const { uninvoicedMaterial } = this.state;
        uninvoicedMaterial[key].selected = selected;
        this.setState({uninvoicedMaterial: uninvoicedMaterial});
    }

    onCheckedInvoiceMaterial = (ids) => {
        const { uninvoicedMaterial } = this.state;


        const um = uninvoicedMaterial.map(x => ({ ...x, selected: ids.includes(x.id) }));

        //add selected to sessionstorage
        const invoiceMaterialIds = um.filter(x => x.selected == true).map(x => x.key);
        sessionStorage.setItem("invoiceMaterialPreselect", JSON.stringify(invoiceMaterialIds));

        this.setState({
            uninvoicedMaterial: um,
        });
    }

    createInvoiceFromSelectedMaterial = (projectsId: string | number = 0) => {
        const { projects } = this.state;

        const project = projects.find(p => p.id == projectsId);
        if (!project) {
            return;
        }

        this.context.functions.addInvoice({
            project: {
                id: String(project.id),
                companies_id: String(project.companies_id),
                account: {
                    id: String(project.customers_id),
                },
            },
            company: String(project.companies_id),
            origin_point: "project_statistics_invoice_material_list",
            preselect_material_rows: true,
        });
    }

    setInvoiceMaterialFilter = (filter: InvoiceMaterialTypeFilterName | InvoiceMaterialTypeFilterName[]) => {
        const { materialTypeFilters, uninvoicedMaterial } = this.state;

        let newValue = materialTypeFilters;

        if (Array.isArray(filter)) {
            newValue = filter;
        } else {
            if (materialTypeFilters.includes(filter)) {
                newValue = materialTypeFilters.filter(x => x !== filter);
            } else {
                newValue = [...materialTypeFilters, filter];
            }
        }

        //add selected visible to sessionstorage
        const invoiceMaterialIds = uninvoicedMaterial.filter(x => (x.selected == true && (newValue.includes(x.row_type) || newValue.length < 1))).map(x => x.key);
        sessionStorage.setItem("invoiceMaterialPreselect", JSON.stringify(invoiceMaterialIds));
        localStorage.setItem("project_statistics_invoice_material_type_filter", JSON.stringify(newValue));

        this.setState({
            materialTypeFilters: newValue,
        });
    }

    onCheckedUninvoicedHours = (ids) => {
        const { unbilledHours } = this.state;


    const uh = unbilledHours.map(x => ({ ...x, selected: ids.includes(x.id)}));

        this.setState({
            unbilledHours: uh,
        });
    }

    setProjectListFilter = (filter: ProjectListFilterName | ProjectListFilterName[]) => {
        const { allProjects, selectedProjectFilters } = this.state;

        let newValue = selectedProjectFilters;

        if (Array.isArray(filter)) {
            newValue = filter;
        } else {
            if (selectedProjectFilters.includes(filter)) {
                newValue = selectedProjectFilters.filter(x => x !== filter);
            } else {
                newValue = [...selectedProjectFilters, filter];
            }
        }

        const projects = this.filterProjects(allProjects, newValue).map(x => ({
            ...x,
            selected: true,
        }));

        this.setState({
            selectedProjectFilters: newValue,
            projects,
            ...this.calculateValues(projects),
        });
    }

    filterProjects = (projects: Project[], selectedProjectFilters: ProjectListFilterName[]): Project[] => {
        return projects.filter(x => this.matchesFilter(x, selectedProjectFilters));
    }

    matchesFilter = (project: Project, selectedProjectFilters: ProjectListFilterName[]) => {
        if (selectedProjectFilters.length === 0) {
            return true;
        }

        if (selectedProjectFilters.includes('active') && project.locked === ProjectLocked.Active)
            return true;

        else if (selectedProjectFilters.includes('closed') && project.locked === ProjectLocked.Closed)
            return true;

        else if (selectedProjectFilters.includes('onHold') && project.locked === ProjectLocked.OnHold)
            return true;

        return false;
    }

    onCheckedProjects = (ids: number[]) => {
        const { projects } = this.state;

        const p = projects.map(x => ({ ...x, selected: ids.includes(x.id) }));

        this.setState({
            projects: p,
            ...this.calculateValues(p),
        });
    }

    render() {
        const { checkPrivilege } = this.props;
        const {
            allProjects, projects, selectedProjectFilters, materialTypeFilters, invoiced, hours_done, maxhours, extra_maxhours,
            salesByType, uninvoicedMaterial, unbilledHours, costsByType, materialByDate, invoiceStatusByDate, invoice_material,
            budgeted_sales, budgeted_costs, invoicesByStatus, backlog,
            hour_entries, hourly, all_bills, all_expenses, all_travel_expenses,
            materialEarliestDate, materialLatestDate,
            invoicesEarliestDate, invoicesLatestDate,
            activities, finance_rights, currency,
            expenses_draft, travel_expenses_draft,
            budgetedProjectMargin, budgetedGrossMargin, budgetedGrossMargin_cost,
            actualProjectMargin, actualGrossMargin, actualGrossMargin_cost, actualProjectMargin_cost,
        } = this.state;
        const { taimerAccount, taimerAccount: { useExtraProjectHours } } = this.context;

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency
        }).format;

        const staticColumnCommons = { resizeable: false, moveable: false, hideable: false, showMenu: false, showTitle: true };

        const selectedProjects = projects.filter(x => x.selected);

        const margin = budgeted_sales - budgeted_costs;
        const marginPercent = budgeted_sales ? margin / budgeted_sales : 0;

        const toBeInvoiced = budgeted_sales - invoiced;
        const leftInvoicedPercent = budgeted_sales ? (toBeInvoiced / budgeted_sales) * 100 : 0;

        const invoicedAndMaterial = invoiced + invoice_material;
        const invoicedAndMaterialPercent = budgeted_sales ? invoicedAndMaterial / budgeted_sales * 100 : 100;

        const projectHours = maxhours;
        const allocatedPercent = extra_maxhours ? Math.max(0, (maxhours / extra_maxhours * 100)) : 100;

        const subProjectsEnabled = !VersionContentManager.isFeatureHidden(this.namespace, 'subprojects');

        const activityGroups = [
            {
                key: 'due',
                value: activities.due,
                label: this.tr('due'),
            },
            {
                key: 'overdue',
                value: activities.overdue,
                label: this.tr('overdue'),
            },
            {
                key: 'done',
                value: activities.done,
                label: this.tr('done'),
            },
        ]

        return (<div className={styles.root}>
            <CustomViewBase
                className={styles.view}
                viewName="projects"
                title={this.tr("Project statistics")}
                blocks={[
                    {
                        name: 'project_tree',
                        title: this.tr('Projects'),
                        row: 1,
                        column: 1,
                        fullRow: true,
                        defaultVisible: true,
                        defaultCollapse: true,
                        // static: true,
                        canBeVisible: subProjectsEnabled && allProjects.length > 1,
                        render: (blockProps, subitemProps) => <ProjectListBlock
                            blockProps={blockProps}
                            {...subitemProps}
                            selectedProjectFilters={selectedProjectFilters}
                            onSelectProjectListFilter={this.setProjectListFilter}
                            onCheckedProjects={this.onCheckedProjects}
                            projects={projects}
                            finance_rights={finance_rights}
                        />,
                    },
                    {
                        name: 'keyfigures',
                        title: this.tr('Keyfigures'),
                        row: 2,
                        column: 1,
                        defaultVisible: true,
                        render: (blockProps, subitemProps) => <Keyfigures figures={[
                            {
                                key: 'project_value',
                                type: 'text',
                                title: this.tr('Project Value'),
                                text: currencyFormatter(budgeted_sales),
                                canBeVisible: finance_rights,
                                subfigures: [
                                    {
                                        key: 'project_margin',
                                        type: 'text',
                                        title: this.tr('Estimated Margin'),
                                        text: currencyFormatter(margin),
                                        extra: <div className={styles.percentbox}>
                                            {(marginPercent * 100).toFixed(2)} %
                                        </div>
                                    }
                                ],
                            },
                            {
                                key: 'invoiced',
                                type: 'text',
                                title: this.tr('Invoiced'),
                                text: currencyFormatter(invoiced),
                                tooltipText: this.tr('Invoiced sum is calculated from sent status onwards'),
                                canBeVisible: finance_rights,
                                classNameSubfigures: styles.trackedSubFigures,
                                subfigures: [
                                    {
                                        key: 'invoiced_draft',
                                        type: 'text',
                                        title: this.tr('Drafts'),
                                        text: currencyFormatter(invoicesByStatus.draft),
                                    },
                                    {
                                        key: 'invoiced_waiting',
                                        type: 'text',
                                        title: this.tr('Waiting'),
                                        text: currencyFormatter(invoicesByStatus.waiting),
                                    }
                                ],
                            },
                            {
                                key: 'material',
                                type: 'text',
                                title: this.tr('Invoice Material'),
                                text: currencyFormatter(invoice_material),
                                canBeVisible: finance_rights,
                                subfigures: selectedProjects.length === 1 && selectedProjects[0].invoiceable && selectedProjects[0] && invoice_material > 0 ? [
                                    {
                                        key: 'link',
                                        type: 'action',
                                        text: this.tr('Create Invoice'),
                                        action: () => this.context.functions.addInvoice({
                                            project: {
                                                id: String(selectedProjects[0].id),
                                                companies_id: String(selectedProjects[0].companies_id),
                                                account: {
                                                    id: String(selectedProjects[0].customers_id),
                                                },
                                            },
                                            company: String(selectedProjects[0].companies_id),
                                            origin_point: "project_statistics"
                                        }),
                                    },
                                ] : [],
                            },
                            {
                                key: 'to-be-invoiced',
                                type: 'text',
                                title: this.tr('Project Value - Invoiced'),
                                text: currencyFormatter(toBeInvoiced),
                                canBeVisible: finance_rights,
                                subfigures: [
                                    {
                                        key: 'progress',
                                        type: 'progress_multi',
                                        title: leftInvoicedPercent >= 0 ? this.tr('${percent}% left to be invoiced', {
                                            percent: leftInvoicedPercent.toFixed(2),
                                        }) : this.tr('${percent}% more invocied than planned. ', {
                                            percent: Math.abs(leftInvoicedPercent).toFixed(2),
                                        }),
                                        segments: [
                                            {
                                                value: Math.min(invoiced, budgeted_sales),
                                                color: PROGRESS_GREEN,
                                                tooltip: currencyFormatter(Math.min(invoiced, budgeted_sales)),
                                            },
                                            {
                                                value: Math.max(0, invoiced - budgeted_sales),
                                                color: PROGRESS_RED,
                                                tooltip: currencyFormatter(Math.max(0, invoiced - budgeted_sales)),
                                            }
                                        ],
                                        minSize: Math.max(100, budgeted_sales),
                                    }
                                ],
                            },
                            {
                                key: 'invoiced_and_material',
                                type: 'text',
                                title: this.tr('Invoiced + uninvoiced material'),
                                text: currencyFormatter(invoicedAndMaterial),
                                defaultVisible: false,
                                canBeVisible: finance_rights,
                                subfigures: [
                                    {
                                        key: 'progress',
                                        type: 'progress_multi',
                                        title: invoicedAndMaterialPercent > 100 ? this.tr('${percent}% more than planned.', {
                                            percent: (invoicedAndMaterialPercent - 100).toFixed(2),
                                        }) : this.tr('${percent}% of project value', {
                                            percent: invoicedAndMaterialPercent.toFixed(2),
                                        }),
                                        segments: [
                                            {
                                                value: Math.min(invoicedAndMaterial, budgeted_sales),
                                                color: PROGRESS_GREEN,
                                                tooltip: currencyFormatter(Math.min(invoicedAndMaterial, budgeted_sales)),
                                            },
                                            {
                                                value: Math.max(0, invoicedAndMaterial - budgeted_sales),
                                                color: PROGRESS_RED,
                                                tooltip: currencyFormatter(Math.max(0, invoicedAndMaterial - budgeted_sales)),
                                            }
                                        ],
                                        minSize: Math.max(100, budgeted_sales),
                                    }
                                ],
                            },
                            {
                                key: 'backlog',
                                type: 'currency',
                                title: this.tr('Backlog'),
                                tooltipText: this.tr('Backlog: Project Value - (Billed hours + Unbilled Hours + Expenses + Bills)'),
                                currency,
                                value: backlog,
                                defaultVisible: false,
                                canBeVisible: finance_rights,
                                subfigures: [
                                ],
                            },
                            {
                                key: 'tracked',
                                type: 'number',
                                title: this.tr('Tracked'),
                                value: hours_done,
                                postfix: 'h',
                                classNameSubfigures: styles.trackedSubFigures,
                                subfigures: [
                                    {
                                        key: 'project_resourced',
                                        type: 'number',
                                        title: this.tr('Allocated to Projects'),
                                        postfix: 'h',
                                        value: projectHours,
                                    },
                                    {
                                        key: 'project_hours_left',
                                        type: 'number',
                                        title: this.tr('Hours Left'),
                                        postfix: 'h',
                                        value: projectHours - hours_done,
                                    },
                                ],
                            },
                            {
                                key: 'resourced',
                                type: 'number',
                                title: useExtraProjectHours ? this.tr('Budgeted') : this.tr('Allocated'),
                                value: useExtraProjectHours ? extra_maxhours : maxhours,
                                postfix: 'h',
                                defaultVisible: false,
                                subfigures: useExtraProjectHours ? [
                                    {
                                        key: 'progress',
                                        type: 'progress_multi',
                                        title: this.tr('${percent}% of ${hours} allocated of to tasks', {
                                            percent: allocatedPercent.toFixed(2),
                                            hours: extra_maxhours,
                                        }),
                                        segments: extra_maxhours > 0 ? [
                                            {
                                                value: Math.min(extra_maxhours, maxhours),
                                                color: PROGRESS_GREEN,
                                                tooltip: `${Math.min(extra_maxhours, maxhours)} h`,
                                            },
                                            {
                                                value: Math.max(0, maxhours - extra_maxhours),
                                                color: PROGRESS_RED,
                                                tooltip: `${Math.max(0, maxhours - extra_maxhours)} h`,
                                            }
                                        ] : [
                                            {
                                                value: maxhours,
                                                color: PROGRESS_GREEN,
                                                tooltip: `${maxhours} h`,
                                            },
                                        ],
                                        minSize: extra_maxhours,
                                    },
                                ] : undefined,
                            },
                            {
                                key: 'expenses',
                                type: 'currency',
                                title: this.tr('Expense Bills'),
                                tooltipText: this.tr('Sum of approved expenses'),
                                value: all_expenses,
                                currency,
                                defaultVisible: false,
                                canBeVisible: finance_rights,
                                subfigures: [
                                    {
                                        key: 'waiting',
                                        type: 'text',
                                        title: this.tr('Waiting'),
                                        text: currencyFormatter(expenses_draft),
                                    }
                                ],
                            },
                            {
                                key: 'travel_expenses',
                                type: 'currency',
                                title: this.tr('Travel Expenses'),
                                tooltipText: this.tr('Sum of approved travel expenses'),
                                value: all_travel_expenses,
                                currency,
                                defaultVisible: false,
                                canBeVisible: finance_rights,
                                subfigures: [
                                    {
                                        key: 'waiting',
                                        type: 'text',
                                        title: this.tr('Waiting'),
                                        text: currencyFormatter(travel_expenses_draft),
                                    }
                                ],
                            },
                            // Net Profit
                            {
                                key: 'netprofit',
                                type: 'text',
                                title: this.tr('Net Profit'),
                                tooltipText: this.tr('Income - (hourly cost + bills + expenses)'),
                                text: currencyFormatter(invoiced - (hourly + all_bills + all_travel_expenses + all_expenses)),
                                classNameSubfigures: styles.trackedSubFigures,
                                defaultVisible: false,
                                canBeVisible: finance_rights,
                                subfigures: [
                                    {
                                        key: 'income',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Income'),
                                        value: invoiced,
                                        classNameContent: styles.figure_income,
                                    },
                                    {
                                        key: 'expenses',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Actual Costs'),
                                        value: hourly + all_bills + all_travel_expenses + all_expenses,
                                        classNameContent: styles.figure_expenses,
                                    },
                                ],
                            },
                            // Gross Profit
                            {
                                key: 'grossprofit',
                                type: 'text',
                                title: this.tr('Gross Profit'),
                                tooltipText: this.tr('Income - (bills + expenses)'),
                                text: currencyFormatter(invoiced - (all_bills + all_travel_expenses + all_expenses)),
                                classNameSubfigures: styles.trackedSubFigures,
                                defaultVisible: false,
                                canBeVisible: finance_rights,
                                subfigures: [
                                    {
                                        key: 'income',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Income'),
                                        value: invoiced,
                                        classNameContent: styles.figure_income,
                                    },
                                    {
                                        key: 'expenses',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Actual Costs'),
                                        value: all_bills + all_travel_expenses + all_expenses,
                                        classNameContent: styles.figure_expenses,

                                    },
                                ],
                            },
                            {
                                key: 'budgeted_project_margin',
                                type: 'currency',
                                title: this.tr('Budgeted Project Margin'),
                                tooltipText: this.tr('Budgeted costs - (hourly cost + bills + expenses)'),
                                value: budgetedProjectMargin,
                                currency,
                                classNameSubfigures: styles.trackedSubFigures,
                                defaultVisible: false,
                                canBeVisible: finance_rights,
                                subfigures: [
                                    {
                                        key: 'income',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Budgeted Sales'),
                                        value: budgeted_sales,
                                        classNameContent: styles.figure_income,
                                    },
                                    {
                                        key: 'actual',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Budgeted Costs'),
                                        value: budgeted_costs,
                                        classNameContent: styles.figure_expenses,
                                    },
                                ],
                            },
                            {
                                key: 'budgeted_gross_margin',
                                type: 'currency',
                                title: this.tr('Budgeted Gross Margin'),
                                tooltipText: this.tr('Budgeted costs - (bills + expenses)'),
                                value: budgetedGrossMargin,
                                currency,
                                classNameSubfigures: styles.trackedSubFigures,
                                defaultVisible: false,
                                canBeVisible: finance_rights,
                                subfigures: [
                                    {
                                        key: 'income',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Budgeted Sales'),
                                        value: budgeted_sales,
                                        classNameContent: styles.figure_income,
                                    },
                                    {
                                        key: 'actual',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Budgeted Costs'),
                                        value: budgetedGrossMargin_cost,
                                        classNameContent: styles.figure_expenses,
                                    },
                                ],
                            },
                            {
                                key: 'actual_project_margin',
                                type: 'currency',
                                title: this.tr('Actual Project Margin'),
                                currency,
                                value: actualProjectMargin,
                                canBeVisible: finance_rights,
                                classNameSubfigures: styles.trackedSubFigures,
                                defaultVisible: false,
                                subfigures: [
                                    {
                                        key: 'estimate',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Estimated Sales'),
                                        value: budgeted_sales,
                                        classNameContent: styles.figure_income,
                                    },
                                    {
                                        key: 'actual',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Targeted Expenses'),
                                        value: actualProjectMargin_cost,
                                        classNameContent: styles.figure_expenses,
                                    },
                                ],
                            },
                            {
                                key: 'actual_gross_margin',
                                type: 'currency',
                                title: this.tr('Actual Gross Margin'),
                                currency,
                                value: actualGrossMargin,
                                canBeVisible: finance_rights,
                                classNameSubfigures: styles.trackedSubFigures,
                                defaultVisible: false,
                                subfigures: [
                                    {
                                        key: 'estimate',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Estimated Sales'),
                                        value: budgeted_sales,
                                        classNameContent: styles.figure_income,
                                    },
                                    {
                                        key: 'actual',
                                        type: 'currency',
                                        currency,
                                        title: this.tr('Targeted Expenses'),
                                        value: actualGrossMargin_cost,
                                        classNameContent: styles.figure_expenses,
                                    },
                                ],
                            },
                        ]} blockProps={blockProps} {...subitemProps} />,
                    },
                    {
                        name: 'hourEntries',
                        title: this.tr('Users Hours'),
                        row: 3,
                        column: 1,
                        defaultVisible: true,
                        render: (blockProps, subitemProps) => <TableBlock
                            listRowType={HourEntriesRow}
                            summaryRowType={UserSummaryRow}
                            data={hour_entries}
                            listProps={{
                                idType: "string",
                                columns: [
                                    { field: "user", name: "user", header: this.tr("User"), width: 300, ...staticColumnCommons },
                                    { field: "tracked", name: "tracked", header: this.tr("Tracked Hours"), width: 300, ...staticColumnCommons },
                                ],
                                sharedData: {
                                    currency,
                                }
                            }}
                            listRowProps={{
                                currency,
                            }}
                            blockProps={blockProps} {...subitemProps} />,
                    },
                    {
                        name: 'salesByType',
                        title: this.tr('Quote Selling Price vs. Invoicing'),
                        row: 4,
                        column: 1,
                        defaultVisible: true,
                        canBeVisible: finance_rights,
                        render: (blockProps, subitemProps) => <TableBlock
                            listRowType={InvoicedVSSalesRow}
                            summaryRowType={SummaryRow}
                            data={salesByType}
                            listProps={{
                                rowKey: "type_name",
                                idType: "string",
                                columns: [
                                    { field: "type_name", name: "type_name", header: this.tr("Type"), width: 300, ...staticColumnCommons },
                                    { field: "budgeted_price", name: "budgeted_price", header: this.tr("Budgeted selling_price"), width: 200, ...staticColumnCommons },
                                    { field: "selling_price", name: "selling_price", header: this.tr("Selling_price"), width: 200, ...staticColumnCommons },
                                    { field: "own_cost", name: "own_cost", header: this.tr("Own cost"), width: 200, ...staticColumnCommons },
                                    { field: "budgeted_vs_selling", name: "budgeted_vs_selling", header: this.tr("Budgeted vs selling"), width: 200, ...staticColumnCommons },
                                    { field: "invoiced", name: "invoiced", header: this.tr("Invoiced"), width: 200, ...staticColumnCommons },
                                    { field: "to_be_invoiced", name: "to_be_invoiced", header: this.tr("To be invoiced"), width: 200, ...staticColumnCommons },
                                ],
                                sharedData: {
                                    currency,
                                }
                            }}
                            listRowProps={{
                                currency,
                            }}
                            blockProps={blockProps} {...subitemProps} />,
                    },
                    {
                        name: 'costByType',
                        title: this.tr('Quote budgeted Costs vs. Actual Costs'),
                        row: 5,
                        column: 1,
                        defaultVisible: true,
                        canBeVisible: finance_rights,
                        render: (blockProps, subitemProps) => <TableBlock
                            listRowType={BudgetedVsActualCostRow}
                            summaryRowType={SummaryRow}
                            data={costsByType}
                            listProps={{
                                rowKey: "type_name",
                                idType: "string",
                                columns: [
                                    { field: "type_name", name: "type_name", header: this.tr("Type"), width: 300, ...staticColumnCommons },
                                    { field: "budgeted_cost", name: "budgeted_cost", header: this.tr("Budgeted cost"), width: 200, ...staticColumnCommons },
                                    { field: "actual_cost", name: "actual_cost", header: this.tr("Actual cost"), width: 200, ...staticColumnCommons },
                                    { field: "variance", name: "variance", header: this.tr("Variance"), width: 200, ...staticColumnCommons },
                                    { field: "remaining_cost", name: "remaining_cost", header: this.tr("Remaining cost"), width: 200, ...staticColumnCommons }
                                ],
                                sharedData: {
                                    currency,
                                }
                            }}
                            listRowProps={{
                                currency,
                            }}
                            blockProps={blockProps} {...subitemProps} />,
                    },
                    {
                        name: 'material',
                        title: this.tr('Uninvoiced material by type'),
                        row: 6,
                        column: 1,
                        defaultVisible: true,
                        canBeVisible: finance_rights,
                        render: (blockProps, subitemProps) => <MaterialChart
                            earliestDate={materialEarliestDate}
                            latestDate={materialLatestDate}
                            materialByDate={materialByDate}
                            currencyFormatter={currencyFormatter}
                            blockProps={blockProps} {...subitemProps} />,
                    },
                    {
                        name: 'invoices',
                        title: this.tr('Invoices Overview'),
                        row: 7,
                        column: 1,
                        defaultVisible: true,
                        canBeVisible: finance_rights,
                        render: (blockProps, subitemProps) => <InvoicesOverviewChart
                            earliestDate={invoicesEarliestDate}
                            latestDate={invoicesLatestDate}
                            invoicesByStatus={invoiceStatusByDate}
                            currencyFormatter={currencyFormatter}
                            blockProps={blockProps} {...subitemProps} />,
                    },
                    {
                        name: 'activities',
                        title: this.tr('Activities'),
                        row: 8,
                        column: 1,
                        defaultVisible: true,
                        render: (blockProps, subitemProps) => <BlockBase {...blockProps}>
                            <div className={styles.activity_block}>
                                <div className={styles.chart}>
                                    <Doughnut data={{
                                        labels: activityGroups.map(x => x.label),
                                        datasets: [
                                            {
                                                label: this.tr('Activities'),
                                                backgroundColor: activityGroups.map(x => ChartOptions.pieColors_v2[x.key]),
                                                data: activityGroups.map(x => x.value),
                                            },
                                        ],
                                    }} options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                    }} />
                                </div>
                                <div className={styles.legend}>
                                    <div className={styles.legend_container}>
                                        {activityGroups.map((group, i) => (
                                            <div key={group.key}>
                                                <div className={styles.color} style={{ backgroundColor: ChartOptions.pieColors_v2[group.key] }}></div>
                                                <div className={styles.key}>
                                                    <span>{group.value}</span> {group.label}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.add_activity}>
                                        {checkPrivilege('projects', 'project_crm_write') && (
                                            <a
                                                onClick={() =>
                                                    this.context.functions.openActivitySlider({
                                                        customers_id: this.props.project.account.id,
                                                        projects_id: this.props.project.id,
                                                    })
                                                }
                                            >
                                                {this.tr('Add activity')}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </BlockBase>,
                    },
                    {
                        name: 'invoice_material',
                        title: this.tr('Invoice Material'),
                        row: 9,
                        column: 1,
                        // fullRow: true,
                        defaultVisible: false,
                        defaultCollapse: false,
                        // static: true,
                        //canBeVisible: subProjectsEnabled && allProjects.length > 1,
                        render: (blockProps, subitemProps) => <InvoiceMaterialBlock
                            blockProps={blockProps}
                            {...subitemProps}
                            materialTypeFilters={materialTypeFilters}
                            onSelectInvoiceMaterialListFilter={this.setInvoiceMaterialFilter}
                            onCheckedInvoiceMaterial={this.onCheckedInvoiceMaterial}
                            createInvoiceFromSelectedMaterial={this.createInvoiceFromSelectedMaterial}
                            invoiceMaterial={uninvoicedMaterial.filter((r) => (this.state.materialTypeFilters.includes(r.row_type) || this.state.materialTypeFilters.length < 1))}
                            currency={currency}
                            project={this.props.project}
                        />,
                    },
                    {
                        name: 'unbilled_hours',
                        title: this.tr('Unbilled Hours'),
                        row: 10,
                        column: 1,
                        // fullRow: true,
                        defaultVisible: false,
                        defaultCollapse: false,
                        // static: true,
                        //canBeVisible: subProjectsEnabled && allProjects.length > 1,
                        render: (blockProps, subitemProps) => <UnbilledHoursBlock
                            blockProps={blockProps}
                            {...subitemProps}
                            unbilledHours={unbilledHours}
                            onCheckedUninvoicedHours={this.onCheckedUninvoicedHours}
                            currency={currency}
                            project={this.props.project}
                        />,
                    },
                ]}
            />
        </div>);
    }
}