import React, { Component } from 'react';
import cn from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment/min/moment-with-locales';

// Wrapper to support Drag & Drop of Projects
// Week/day view
class TimeSlotWrapper extends Component
{
	static contextTypes = {
		timetracker: PropTypes.object
	};

	constructor(props)
	{
		super(props)
		
		this.dragEnter = this.dragEnter.bind(this);
		this.dragLeave = this.dragLeave.bind(this);
		this.dragOver  = this.dragOver.bind(this);
		this.drop      = this.drop.bind(this);

		this.state = {over: false};

		this.refEl = React.createRef();
	}

	componentDidMount() {
		const { value } = this.props;

		if (this.refEl.current && value && value.getHours() === 6 && value.getMinutes() < 30)
		{
			this.refEl.current.scrollIntoView();
		}
	}

	dragEnter(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;

		this.setState({over: true});
	}

	dragLeave(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;

		this.setState({over: false});
	}

	dragOver(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;
			
		e.preventDefault();
	}

	drop(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;

		const data = e.dataTransfer.getData("event");
		const event = JSON.parse(data);

		event.start = this.props.value;
		event.end = this.props.value;
		this.setState({over: false});

		this.context.timetracker.createNewEvent(event, new Date(event.start), new Date(event.end), false);
	}

	render() {

		const dateCompare = moment(this.props.value).format("YYYY-MM-DD"); 
        //const holiday = this.context.timetracker.holidays.find(date => date.date === dateCompare);
        const holiday = false;

		return (
        <div ref={this.refEl}
			className={cn("rbc-time-slot-wrapper", holiday ? "holiday" : "workday", this.state.over && "rbc-addons-dnd-over")} 
			onDragEnter={this.dragEnter} onDragLeave={this.dragLeave} onDrop={this.drop} onDragOver={this.dragOver}>
			{this.props.children}
		</div>);
	}
}

TimeSlotWrapper.contextTypes = {
	timetracker: PropTypes.object
};

export default TimeSlotWrapper