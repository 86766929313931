import React from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

/* css */
import './TabBasic.css';

/* material-ui */
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';

import { Tooltip } from '@mui/material';
import { NotificationsActive, Delete, Visibility, CopyAll, Groups2 as TeamIcon } from '@mui/icons-material';
import ToolTip from '@mui/material/Tooltip';

/* others */
import _, { cloneDeep, uniqBy } from 'lodash';
import cn from 'classnames';
import DataList from './../general/DataList';
import PropTypes from 'prop-types';
import PriceList from '../general/PriceList';
import { format, isValid } from 'date-fns';
import { formatInputNumber, isCompanyUsingInvoiceCountryCode, validateVatNumber, formatVatNumber } from '../helpers';
import { hasSubvalue } from './../general/TreeSelect';
import { AddAccount, AddContact } from '../general/no-options/AddItemComponents';
import DataHandler from './../general/DataHandler';
import TaimerAvatar from '../general/TaimerAvatar';
import CustomFields from './customfields/CustomFields';
import ChartOptions from './../general/ChartOptions';
import AddProjectType from '../general/no-options/AddProjectType';
import TaimerComponent from '../TaimerComponent';
import { Doughnut, Bar } from 'react-chartjs-2';
import { SettingsContext } from './../SettingsContext';
import { DatePicker } from '../general/react-date-range/src';
import { ReactComponent as SubprojectIcon } from './images/subproject.svg';
import { ReactComponent as HoursOverlay } from './images/time_tracker.svg';
import { ReactComponent as InvoiceOverlay } from './images/invoicing_overview.svg';
import { ReactComponent as ActivitiesGraphic } from '../dashboard/images/ActivitiesGraphic.svg';
import { ReactComponent as AssignAsProjectManagerIcon } from '../general/icons/AssignAsProjectManager.svg';
import { ReactComponent as AssignAsSalesAgentIcon } from '../general/icons/AssignAsSalesAgent.svg';
import { ReactComponent as UserIcon } from '../navigation/ActionIcons/my_profile.svg';
import { ReactComponent as ArrowSplitIcon } from '../general/icons/arrow_split.svg';

import PageTopSection from '../general/PageTopSection';
import Link from '../general/Link';
import { getAutocompleteDataForDialog } from '../resourcing/helpers';
import StatusTag from '../general/StatusTag';
import Skeleton from 'react-loading-skeleton';
import ToggleableContainerList from '../general/ToggleableContainerList';
import Slider from '../general/Slider';

import colors from '../colors';
import styles from './TabBasic.module.scss';
import cardStyles from '../general/styles/CardStyles.module.scss';
import AddTag from '../general/no-options/AddTag';
import ProjectListRow from '../list/rows/ProjectListRow';
import { default as TaimerList } from '../list/List';
import moment from 'moment/min/moment-with-locales';
import SliderFieldGroup from '../general/SliderFieldGroup';
import UserAvatarRow from '../general/UserAvatarRow';
import CustomFieldsPreview from './customfields/CustomFieldsPreview';
import VersionContentManager from '../general/VersionContentManager';
import QuoteValues from './QuoteValues';
import InvoiceMultipleAccountsSlider from './InvoiceMultipleAccountsSlider';
import ColorPicker from '../general/ColorPicker';
import OutlinedField from '../general/OutlinedField';

function TooltipOption(props) {
    return (
        <ToolTip title={props.data.tooltip} placement="right">
            <MenuItem
                buttonRef={props.innerRef}
                selected={props.isFocused}
                disabled={props.isDisabled}
                component="div"
                style={{
                    fontWeight: props.isSelected ? 500 : 400,
                }}
                disabled={props.data.disabled}
                {...props.innerProps}
            >
                <span>{props.data.label}</span>
            </MenuItem>
        </ToolTip>
    );
}

class TabBasic extends TaimerComponent {
    static contextType = SettingsContext;

    usesDimensions = Boolean(this.context.addons?.dimensions?.used_by_companies?.indexOf(this.props.company) > -1);
    googleDrive = Boolean(this.context.addons?.googledrive?.used_by_companies?.indexOf(this.props.company) > -1);
    googleDriveAuth =  false; 
    googleDriveRootFolderId = '';

    detailsSections = [
        {
            key: 'details',
            title: this.tr('Project details'),
            initiallyOpen: true,
        },
        {
            key: 'invoicing_details',
            title: this.tr('Invoicing details'),
            initiallyOpen: true,
        },
        {
            key: 'addresses',
            title: this.tr('Addresses'),
            tabs: [
                {
                    id: 'invoicing_address',
                    label: this.tr('Invoicing'),
                },
                {
                    id: 'delivery_address',
                    label: this.tr('Delivery'),
                },
            ],
        },
        {
            key: 'team',
            title: this.tr('Team'),
            tabs: [
                {
                    id: 'project_team',
                    label: this.tr('Project team'),
                },
                ...(this.usesDimensions
                    ? [
                          {
                              id: 'dimensions',
                              label: this.tr('Dimension team'),
                              hidden: () => this.state.dimensionTeamMembers.length == 0,
                          },
                      ]
                    : []),
            ],
        },
        {
            key: 'contacts',
            title: this.tr('Contacts'),
            tabs: [
                {
                    id: 'project_contacts',
                    label: this.tr('Project contacts'),
                },
                {
                    id: 'partners',
                    label: this.tr('Partners'),
                },
            ],
        },
        {
            key: 'hourly_rates',
            title: this.tr('Hourly rates'),
            hidden: !this.props.checkPrivilege('projects', 'project_pricelist_read', this.props.project.companies_id),
            tooltip:
                this.tr('You can specify hourly rates for users & job types in Settings, Account card & Project Card.') +
                '\r\n' +
                this.tr('Project Card rates override Account Card & Settings rates.') +
                '\r\n\r\n' +
                this.tr('Additionally, user based rates override Jobtype rates.'),
        },
        {
            key: 'hour_tracking_settings',
            title: this.tr('Hour tracking settings'),
            hidden: !this.context.addons?.timetracker || VersionContentManager.isFeatureHidden(this.namespace, 'hourTrackingSettings'),
        },
    ];

    state = {
        allContacts: [],
        contacts: [],
        accounts: [],
        employees: [],
        branchesOfBusiness: [],
        categoryCount: 0,
        dimensionTeams: [],
        dimensionTeamMembers: [],
        reportingGroups: [],
        billing_addresses: [],
        project_types: [],
        defaultPrices: {
            users: {},
            worktypes: {},
            protitles: {},
        },
        companies: [],
        companyCurrency: 'EUR',
        activities: {},
        next_activity: {},
        project_hours: [],
        statusReasons: {},
        invoicing: {
            invoiced: 0,
            paid: 0,
            billentry_id: 0,
        },
        project_team_setting: '',
        billingAccountId: 0,
        customershipGroups: [],
        show_agreement_order_identifier: false,
        mediaproProducts: [],
        mediaproContracts: [],
        statisticsDataLoaded: false,
        resourcingSettings: {
            allow_tracking_all_tasks: false,
        },
        tagpool: [],
        fieldLimits: {},
        showInvoiceNotes: false,
        hiddenFields: [],
        tagPoolSettingsData: {
            use_separate_tag_pools: 0,
            create_tags_only_from_settings: 1,
        },
        loadingTreeStructure: false,
        treeStructureProjects: [],
        allowedCountryCodes: [],
        allCountryCodes: [],        
        vatErrorMessage: "",
        use_events: 0,
        teams: [],
        invoiceMultipleAccountsSliderVisible: false,
        allBillingAddresses: [],
        invoicing_addresses: []
    };

    billingAddressAccount = 0;

    constructor(props, context) {
        super(props, context, 'projects/TabBasic');

        //if GD addon is activated we want to check whether the user has authorized Google. Must be able to relocate the attachments if accuont changed
        if(this.googleDrive) this.checkIfGoogleDriveAuthorized();

        this.treeStructureListFields = [
            {
                field: 'project_id',
                name: 'project_id',
                header: this.tr('Nr.'),
                width: this.context.addons.custom_project_id ? 90 : 61,
                showMenu: false,
                resizeable: false,
                showResizeMarker: false,
                moveable: false,
                hideable: false,
            },
            {
                field: 'name',
                name: 'name',
                header: this.tr('Project'),
                width: 200,
                showMenu: false,
                resizeable: false,
                showResizeMarker: false,
                moveable: false,
                hideable: false,
                visualizationType: 'tree',
            },
            {
                field: 'customer',
                name: 'customer_id',
                header: this.tr('Account'),
                width: 200,
                visualizationType: 'tree',
                showMenu: false,
                resizeable: false,
                showResizeMarker: false,
                moveable: false,
                hideable: false,
            },
        ];
        this.showProjectNameInHeader = false;
        this.currencyFormatter = new Intl.NumberFormat(this.context.taimerAccount.numberFormat, {
            style: 'currency',
            currency: 'EUR',
        }).format;

        this.activityLegendKeys = {
            due: this.tr('due'),
            overdue: this.tr('overdue'),
            done: this.tr('done'),
        };

        this.state.billingAccountId = this.props.project.invoicing_address.customers_id;
        this.state.invoicing_addresses = this.props.project.invoicing_addresses || [];

        this.refPriceList = React.createRef();
        this.refCustomFields = React.createRef();
        this.invoicingAddressSlider = React.createRef();
        this.projectDetailsSlider = React.createRef();

        this.translations = {
            locked: this.tr('locked'),
            freelancer: this.tr('freelancer'),
        };

        this.reverseChargeFields = [
            { id: 0, label: this.tr('No reverse charge') },
            { id: 1, label: this.tr('Reverse charge applied') },
        ];

        /**
         * When changing also  update list in History\Project
         */
        this.projectsGrouping = [
            { id: '0', label: this.tr('Not selected') },
            { id: '1', label: this.tr('By project'), tooltip: this.tr('Group entries by project. When invoicing multiple projects all entries grouped under respective project topic row.') },
            {
                id: '2',
                label: this.tr('No project grouping'),
                tooltip: this.tr('When invoicing multiple projects, use this selection to hide topic rows with project names and show all entries in one list.'),
            },
            {
                id: '3',
                label: this.tr('By project and task'),
                tooltip: this.tr('View each task entires separately. Each task name is added to the topic name after the project name.'),
                disabled: !(this.context.addons && this.context.addons.resourcing),
            },
        ];

        /**
         * When changing also  update list in History\Project
         */
        this.workhoursGrouping = [
            { id: '0', label: this.tr('Not selected') },
            {
                id: '3',
                label: this.tr('Per employee'),
                tooltip: this.tr('Group all hours per employee to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ.'),
            } /* disabled when procountor is enabled */,
            {
                id: '2',
                label: this.tr('Per employee and task'),
                tooltip: this.tr('Group all hours per employee and task to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ.'),
            },
            {
                id: '4',
                label: this.tr('Per task'),
                tooltip: this.tr('Group all hours per task to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ'),
            },
            {
                id: '5',
                label: this.tr('All-in-one, show qty'),
                tooltip: this.tr('Group all hours to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ.'),
            } /* disabled when procountor is enabled */,
            { id: '7', label: this.tr('All-in-one, qty 1'), tooltip: this.tr('Group all hours to one row, regardless of hourly selling price. Quantity set as 1 and one total row for the sum.') },
            { id: '1', label: this.tr('By entry, show employee'), tooltip: this.tr('All entries by users specified by row showing date, user, jobtype and description.') },
            { id: '6', label: this.tr('By entry, hide employee'), tooltip: this.tr('All entries by users, excluding user name, specified by row showing date, jobtype and description.') },
            ...((this.context.addons?.separate_description_grouping?.used_by_companies.indexOf(this.props.company) > -1) ? [{id: "10", label: this.tr("By entry, descriptions separately"), tooltip: this.tr("All entires separately showing the date and the user. Description will be separately on a description row.")}] : []),
            { id: '8', label: this.tr('By employee and entry'), tooltip: this.tr('Each employee separately shown by entry and date. Row showing employee, date, jobtype and description.') },
            ...((this.context.addons?.unit_and_protitle_grouping?.used_by_companies.indexOf(this.props.company) > -1) ? [{ id: "9", label: this.tr("By dimension team and professional title"), tooltip: this.tr("Group all hours by user's dimension team and professional title.")}] : []),
        ];

        /* remove some grouping options when procountor accounting is enabled */
        if (this.context.addons.procountor_accounting) this.workhoursGrouping = this.workhoursGrouping.filter((e) => ['3', '5'].includes(e.id) === false);

        /**
         * When changing also  update list in History\Project
         */
        this.worktripsGrouping = [
            { id: '0', label: this.tr('Not selected') },
            { id: '3', label: this.tr('Travel expenses all in one') },
            { id: '1', label: this.tr('Daily allowance, mileage and other expenses specified') },
            { id: '2', label: this.tr('Daily allowance and mileage in one, other expenses specified') },
        ];

        /**
         * When changing also update list in History\Project
         */
        this.costestGrouping = [
            { id: '0', label: this.tr('Not selected') },
            { id: '2', label: this.tr('Do not import description rows from quote') },
            { id: '1', label: this.tr('Import description rows from quote') },
        ];

        this.stateWorkarounds = {};
    }

    accountPriceList = () => {
        const { enqueueSnackbar } = this.props;

        this.context.functions.showConfirmationDialog({
            header: this.tr('Import Pricelist'),
            warning: this.tr("This will replace current pricelist in project card."),
            confirmText: this.tr('Import Pricelist'),
            confirmButtonClass: 'blue',
            onConfirm: async () => {
                try {
                    const res = await DataHandler.post({ url: `projects/${this.props.project.id}/pricelist/import_customer` });

                    if (res.message === 'NOTHING_TO_IMPORT') {
                        enqueueSnackbar(this.tr('Nothing to import!'), {
                            variant: "info"
                        });
                    } else if (res.message === 'NOTHING_TO_UPDATE') {
                        enqueueSnackbar(this.tr('No changes!'), {
                            variant: "info"
                        });
                    } else if (res.message === 'OK') {
                        enqueueSnackbar(this.tr('Pricelist updated!'), {
                            variant: "success"
                        });
                    } else if (res.message === 'NO_ACTIVE_PRICELIST') {
                        enqueueSnackbar(this.tr('Customer does not have active pricelist.'), {
                            variant: "error"
                        });
                    }
                    
                    setTimeout(() => {
                        this.refPriceList.current && this.refPriceList.current.fetchData(true);
                    }, 1000);
                } catch (error) {
                    enqueueSnackbar(this.tr('Error importing pricelist'), {
                        variant: "error"
                    });
                }
            },
        });
    };

    checkIfGoogleDriveAuthorized = () => {

        DataHandler.post({ url: `drive/google/connect` }, { company: this.props.company })
            .done(response => {
                if (response.authenticated) {
                    this.googleDriveAuth = true;
                    this.googleDriveRootFolderId = response.selected_folder; //if no rights shared to the user on GD, this will be empty
                }
            })
            .fail(response => {
                console.log(response);
            });

    }

    accountChanged = (account) => {

        this.updateBillingAddresses(account.id);
        this.updateMediaproData(account.id);
        this.setState({ billingAccountId: account.id });

        DataHandler.get({ url: `accounts/defaultprices`, account: account.id }).done((defaultPrices) => {
            this.setState({ defaultPrices });
        });
        DataHandler.get({ url: `projects/reporting_groups/${this.props.company}`, account: account.id }).done((reportingGroups) => this.setState({ reportingGroups }));
        this.state.reportingGroups.forEach((rgs) => {
            if (rgs.is_default == 1) {
                const groups_id = rgs.id;
                this.onChange({
                    target: {
                        name: 'product_structures_id',
                        value: groups_id,
                    },
                });
            }
        });
    };

    billingAccountChanged = (account, evt) => {
        this.setState({ billingAccountId: account.id }, this.updateBillingAddresses);
    };

    addTeamMember = (member) => {
        const { team_members, account, id } = this.props.project;
        const customers_id = account['id'];
        const company = this.props.company;

        if (member.id == -1) {
            DataHandler.get({ url: `projects/account_team/${customers_id}/${company}/${id}` }).done((finalUsers) => {
                finalUsers &&
                    finalUsers.forEach((element) => {
                        this.props.valueChanged({ team_members: team_members.concat([element]) });
                        team_members.push(element);
                    });
            });
        }
        if (member['id'] != -1) {
            this.props.valueChanged({ team_members: team_members.concat([member]) });
        }
    };

    rmTeamMember = (member) => {
        const { team_members } = this.props.project;
        team_members.splice(
            team_members.findIndex((e) => e.id === member),
            1
        ).length && this.props.valueChanged({ team_members });
    };

    addContact = (contact) => {
        if (!contact.id) return;

        const { contacts: old } = this.props.project;
        if (old.some((row) => row.id == contact.id)) {
            return;
        }

        const contacts = old.concat([contact]);
        contacts.sort(function (a, b) {
            const nameA = a.lastname.toUpperCase();
            const nameB = b.lastname.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        this.props.valueChanged({ contacts });
    };

    rmContact = (contact) => {
        const { contacts } = this.props.project;
        contacts.splice(
            contacts.findIndex((e) => e.id === contact),
            1
        ).length && this.props.valueChanged({ contacts });
    };

    addPartner = (partner) => {
        if (!partner.id) return;
        const { partners: old } = this.props.project;
        const partners = old.concat([partner]);
        partners.sort(function (a, b) {
            const nameA = a.lastname.toUpperCase();
            const nameB = b.lastname.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        this.props.valueChanged({ partners });
    };

    rmPartner = (partner) => {
        const { partners } = this.props.project;
        partners.splice(
            partners.findIndex((e) => e.id === partner),
            1
        ).length && this.props.valueChanged({ partners });
    };

    createCurrencyFormatter = (currency) => {
        return new Intl.NumberFormat(this.context.taimerAccount.numberFormat, {
            style: 'currency',
            currency,
        }).format;
    };

    isCompanyUsingCountryCode = () => {
        const { addons } = this.context;
        return isCompanyUsingInvoiceCountryCode(addons, this.props.company);
    }
    getCompanyCurrency = (companies) => {
        const foundCompany = (companies || []).find((c) => c.id == this.props.project.companies_id);
        const companyCurrency = foundCompany?.currency || companies[0]?.currency || this.state.companyCurrency;
        return companyCurrency;
    };

    getCountryCodes = () => {
        const company = this.props.project?.companies_id;

        DataHandler.get({ url: `subjects/vat_codes/${company}`}).done(vatcodes => {
            const allowedCountryCodes = [];
            const addedCodes = {};
            vatcodes.forEach(v => {
                if (v.country_code && !addedCodes[v.country_code]) {
                    allowedCountryCodes.push({ label: v.country_code, name: v.country_code, id: v.country_code, value: v.country_code });
                    addedCodes[v.country_code] = 1;
                }
            });
            this.setState({ allowedCountryCodes })
        });
        DataHandler.get({ url: `settings/autocomplete`, country_codes: 1 }).done(settings => {
            this.setState({ allCountryCodes: settings.country_codes })
        });
    }

    updateComponentData = () => {
        const { id, project } = this.props;
        const { addons } = this.context;

        this.updateBillingLanguages();
        this.updateBillingAddresses();
        this.getTagPool();
        this.getTagSettings();
        this.getAllBillingAddresses();

        if (this.isCompanyUsingCountryCode()) {
            this.getCountryCodes();
        }

        const company = project.companies_id;

        getAutocompleteDataForDialog(company).then((resourcingSettings) => this.setState({ resourcingSettings }));
        const companyCurrency = this.getCompanyCurrency(this.state.companies);
        this.currencyFormatter = this.createCurrencyFormatter(companyCurrency);
        this.setState({ companyCurrency });
        DataHandler.get({ url: `subjects/accounts/${company}`, project_accounts: true }).done((accounts) => {
            this.setState({ accounts }, () => {
                // This updates customer name for new projects with default customer
                if (!id && project.customers_id) {
                    this.onChange({
                        target: {
                            name: 'customers_id',
                            value: project.customers_id,
                        },
                    });
                }
            });
        });

        if ((addons?.dimensions || { used_by_companies: [] }).used_by_companies.indexOf(company) > -1) {
            DataHandler.get({ url: `subjects/dimensions/teams/${company}` }).done((teams) => {
                this.setState({
                    dimensionTeams: teams,
                });
            });

            if (parseInt(project.dimension_teams_id) > 0) {
                DataHandler.get({ url: `subjects/dimensions/users/${project.dimension_teams_id}` }).done((members) => {
                    this.setState({
                        dimensionTeamMembers: members,
                    });
                });
            }
        }

        if (this.props.project && this.props.project.account && this.props.project.account.id) {
            DataHandler.get({ url: `autocomplete_data/contacts/${company}/customer/${this.props.project.account.id}` })
                .done((response) => {
                    let contacts = [];
                    if (response.contacts && Array.isArray(response.contacts) && response.contacts.length >= 0) {
                        contacts = response.contacts;
                    }
                    this.setState({ contacts });
                })
                .fail((error) => console.log(error));
        }

        // All contacts in the system.
        DataHandler.get({ url: `subjects/contacts` }).done((contacts) => {
            this.setState({
                allContacts: contacts,
            });
        });

        DataHandler.get({ url: `subjects/partners/company/${company}` }).done((partners) => this.setState({ partners }));

        this.getProjectTypes();
        DataHandler.get({ url: `settings/company/${company}/project/status_reasons` }).done((data) => this.setState({ statusReasons: data.statusReasons }));

        DataHandler.get({ url: `accounts/defaultprices/${company}`, account: project.account && project.account.id }).done((defaultPrices) => {
            this.setState({ defaultPrices });
        });
        DataHandler.get({ url: `settings/company/${company}/use_events` }).done((data) => {
            this.setState(data);
        });

        DataHandler.get({ url: 'dialogs/branches', companies_id: company, deleted: 1 }).done((branchesOfBusiness) => {
            const actualBobCount = branchesOfBusiness.length;
            this.setState({ branchesOfBusiness, categoryCount: actualBobCount });
        });

        const cusId = this.props.customers_id ? this.props.customers_id : project.account && project.account.id;

        DataHandler.get({ url: `projects/reporting_groups/${company}`, account: cusId ? cusId : '' }).done((reportingGroups) => this.setState({ reportingGroups }));

        DataHandler.get({ url: `projects/project_team_setting/${company}` }).done((data) => {
            this.setState({ project_team_setting: data.project_team_option });
        });

        DataHandler.get({ url: `subjects/customership_groups/${company}` }).done((response) => {
            this.setState({ customershipGroups: response });
        });

        DataHandler.get({ url: `subjects/user_groups/all`, type: "teams" }).done((response) => {
            this.setState({ teams: response });
        });


        this.updateMediaproData(this.props.project.account.id);

        this.updateStatistics();
    };

    updateStatistics = () => DataHandler.get({ url: `projects/${this.props.id}/statistics` }).done((statistics) => this.setState({ ...statistics, statisticsDataLoaded: true }));

    updateBillingLanguages = () => {
        const companyData = this.state.companies.find((c) => c.id == this.props.company);

        const billingLanguages = companyData
            ? companyData.print_languages.map((pl) => {
                  return { ...pl, label: this.tr(pl.label) };
              })
            : this.state.billingLanguages;

        this.setState({ billingLanguages });
    };

    updateBillingAddresses = (customers_id) => {
        const { id, project } = this.props;

        const newBillingAccount = this.state.billingAccountId || project.account.id || customers_id;

        if (this.state.invoicing_addresses?.length > 0 || newBillingAccount === this.billingAddressAccount) return;

        this.billingAddressAccount = newBillingAccount;

        this.billingAddressAccount &&
            DataHandler.get({ url: `subjects/billing_addresses_by_company/${project.companies_id}/${this.billingAddressAccount}` }).done((response) => {
                response = response.filter(r => (r.deleted < 1 || project.invoicing_address.id == r.id));
                //jos osoitteelta puuttuu valuuttaa, lisätään se.
                response = response.map((r) => {
                    if (!r.currency) {
                        r.currency = this.state.companyCurrency;
                        if (r.id === project.invoicing_address.id) this.props.valueChanged({ invoicing_address: r, use_single_account: this.use_single_account });
                    }
                    delete r.deleted;
                    return r;
                });

                this.setState({ billing_addresses: response }, () => {
                    !id &&
                        response.length &&
                        this.props.valueChanged({
                            customer_address_id: response[0].id,
                            invoicing_address: response[0],
                            use_single_account: this.use_single_account
                        });
                    //select first address from list when changing invoice company
                    if (
                        (!project.invoicing_address?.customers_id && response[0]) ||
                        (project.invoicing_address?.customers_id &&
                            response.length > 0 &&
                            response[0].customers_id &&
                            response.find((x) => Number(x.customers_id) === Number(this.state.billingAccountId))?.customers_id !== project.invoicing_address?.customers_id)
                    ) {
                        this.setState({ billingAccountId: response[0].customers_id });
                        this.props.valueChanged({ invoicing_address: response[0], use_single_account: this.use_single_account });
                    }
                });

                this.use_single_account = false;
            });

        this.billingAddressAccount &&
            DataHandler.get({ url: `subjects/delivery_addresses/${project.account.id || customers_id}` }).done((response) => {
                //this.billingAddressAccount && DataHandler.get({url: `subjects/delivery_addresses/${this.billingAddressAccount}`}).done(response => {
                this.setState({ delivery_addresses: response }, () => {
                    !id &&
                        response.length &&
                        this.props.valueChanged({
                            delivery_address: response[0],
                        });
                });
            });
    };

    updateMediaproData = async (accountId) => {
        const mapFn = (p) => {
            return {
                ...p,
                label: p.name,
                value: p.id,
            };
        };

        let products = await DataHandler.get({ url: `accounts/${accountId}/company/${this.props.company}/mediapro_products` });
        let contracts = await DataHandler.get({ url: `accounts/${accountId}/company/${this.props.company}/mediapro_contracts` });

        products = products.map(mapFn);
        contracts = contracts.map(mapFn).map((c) => {
            return {
                ...c,
                label: `${c.mediapro_id} - ${c.label}`,
            };
        });

        const { customers_mediapro_products_id, customers_mediapro_contracts_id } = this.props.project;

        this.setState({
            mediaproProducts: products,
            mediaproContracts: contracts,
        });

        const changeValues = {};

        // If the current values aren't in these new sets,
        // reset their corresponding value.
        if (!products.find((p) => p.id === customers_mediapro_products_id)) {
            changeValues.customers_mediapro_products_id = null;
        }

        if (!contracts.find((p) => p.id === customers_mediapro_contracts_id)) {
            changeValues.customers_mediapro_contracts_id = null;
        }

        if (Object.keys(changeValues).length === 0) {
            return;
        }

        changeValues.mediapro_account_id = accountId;

        this.props.valueChanged(changeValues);
    };

    componentDidMount = () => {
        super.componentDidMount();

        this.updateComponentData();

        this.getInvoiceLimits();
        this.getCompanies();
        this.getCurrencyRates();

        window.addEventListener('workhourSaved', this.updateStatistics);
        window.addEventListener('activitySaved', this.updateStatistics);
    };

    componentDidUpdate = (prevProps) => {
        const nprop = (key) => {
            /* returns new prop value if it differs frum prevprops */
            if (this.props?.project[key] !== prevProps?.project[key]) return this.props.project[key];
            else if (this.props?.project[key] !== undefined && this.stateWorkarounds[key] !== this.props.project[key]) return (this.stateWorkarounds[key] = this.props.project[key]);

            return false;
        };

        /* if some of the datalist values changes, search for corresponding object and push it to state. doesnt work because  */
        let id,
            update = {};
        if ((id = nprop('invoicing_hour_task_grouping'))) update.invoicing_hour_task_grouping = this.projectsGrouping.find((e) => e.id === id);
        if ((id = nprop('invoicing_hour_grouping'))) update.invoicing_hour_grouping = this.workhoursGrouping.find((e) => e.id === id);
        if ((id = nprop('invoicing_expense_grouping'))) update.invoicing_expense_grouping = this.worktripsGrouping.find((e) => e.id === id);
        if ((id = nprop('invoicing_quote_grouping'))) update.invoicing_quote_grouping = this.costestGrouping.find((e) => e.id === id);

        if (Object.keys(update).length) {
            update = _.cloneDeep(update);
            this.setState(update);
        }
        if (this.props.project.account.id !== this.billingAddressAccount) this.updateBillingAddresses();
        if (prevProps.project.companies_id !== this.props.project.companies_id) {
            this.showAgreementOrderIdentifier();
            this.updateComponentData();
            this.getInvoiceLimits();
        }
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener('workhourSaved', this.updateStatistics);
        window.removeEventListener('activitySaved', this.updateStatistics);
    };

    getInvoiceLimits = async () => {
        const limitations = await DataHandler.get({ url: `invoices/field_limitations`, company: this.props.project.companies_id});
        this.setState({ fieldLimits: limitations.fieldLimits, hiddenFields: limitations.hiddenFields, showInvoiceNotes: limitations.showNotes });
    };

    getCompanies = () => {
        DataHandler.get({ url: `subjects/companies/projects/read+write`, currency: 1, print_lang: 1, print_options: 1 }).done((companies) => {
            const companyCurrency = this.getCompanyCurrency(companies);
            this.currencyFormatter = this.createCurrencyFormatter(companyCurrency);

            this.setState({ companyCurrency, companies }, () => {
                this.showAgreementOrderIdentifier();
                this.updateBillingLanguages();
            });
        });
    };

    getCurrencyRates = () => {
        DataHandler.get({ url: `invoices/currency_rates`, company: this.props.project.companies_id }).done((currencies) => {
            const activeCurrencies = currencies.filter((cc) => cc.active_status == 'active');
            this.companyCurrencies = activeCurrencies;
        });
    };

    getTagSettings = () => {
        const { project } = this.props;
        DataHandler.get({ url: `settings/company/${project.companies_id}/tagSettings` })
            .done((response) => {
                if (response.tagPoolSettingsData) this.setState({ tagPoolSettingsData: response.tagPoolSettingsData });
            })
            .fail((response) => {
                console.log('Error', response);
            });
    };

    getProjectTypes = () => {
        const { project } = this.props;
        const company = project.companies_id;
        DataHandler.get({ url: `subjects/project_types/${company}` }).done((project_types) => this.setState({ project_types }));
    };

    getTagPool = () => {
        const { project } = this.props;
        DataHandler.get({ url: `tags/${project.companies_id}`, type: 'project' }).done((data) => {
            this.setState({ tagpool: data.tags });
        });
    };

    getAllBillingAddresses = () => {
        const { project } = this.props;

        DataHandler.get({ url: `invoices/billing_addresses/${project.companies_id}`, check_customer_permission: 1 }).done((response) => {
            this.setState({ allBillingAddresses: response.map(r => { r.label = r.name; return r }) });
        });
    }

    /* gets called when form value changes */
    onChange = (evt) => {
        const { project } = this.props;
        let { name, value, checked } = evt.target;

        /* checkboxes work differently */
        if (['charge_hours', 'charge_others', 'charge_traveling', 'charge_received_invoices', 'charge_costest'].indexOf(name) !== -1) value = checked ? '1' : '-1';
        else if (['only_selected_worktasks', 'team_hour_rights', 'allow_mass_invoicing'].indexOf(name) !== -1) value = checked ? '1' : '0';
        else if (['allow_tracking_all_tasks'].includes(name)) {
            value = checked;
        }

        const update = { [name]: value };

        if (name === 'companies_id') update.account = {};

        if (name == 'status_reason_comment') {
            update.status_reason_id = project.status_reason_id;
        }

        if (name == 'projects_sales_states_id') {
            update.state_changed = format(new Date(), 'YYYY-MM-DD');
        }

        this.props.valueChanged(update);
    };

    onDeliveryAddressSaved = (address) => {
        const { project } = this.props;
        const accountId = project.account.id;
        const newAddress = !address.id || address.id == -1;
        if (newAddress) {
            DataHandler.post({ url: `accounts/${accountId}/delivery_address` }, address).done((e) => {
                const delivery_addresses = cloneDeep(this.state.delivery_addresses);
                delivery_addresses.push({ ...e, label: e.name, value: e.id });
                this.setState({ delivery_addresses }, () => {
                    this.props.valueChanged({ delivery_address: e });
                });
            });
        } else {
            DataHandler.put({ url: `accounts/delivery_address/${address.id}` }, address);
            this.props.valueChanged({ delivery_address: address });
        }
    };

    showAgreementOrderIdentifier = (evt) => {
        this.state.companies.map((row) => {
            if (row.id == this.props.project?.companies_id && row.show_agreement_order_identifier_on_invoice == 2) {
                this.setState({ show_agreement_order_identifier: true });
            } else if (row.id == this.props.project?.companies_id && row.show_agreement_order_identifier_on_invoice != 2) {
                this.setState({ show_agreement_order_identifier: false });
            }
        });

        evt?.target && this.onChange(evt);
    };

    createTeamDropdown = () => {
        const employees = (this.props.employees ? this.props.employees : this.props.autoCompleteData.employees).filter((el) => el.id > 0);
        if (this.state.project_team_setting == 0) {
            let atm = '';
            atm = Object.assign({}, atm);
            atm.label = atm.name = this.tr('Account team members');
            atm.id = -1;

            employees.unshift(atm);
        }
        return employees;
    };

    isCompanyUsingNav = () => {
        const { addons } = this.context;
        return (addons.nav && addons.nav.used_by_companies.indexOf(this.props.company) > -1);
    }

    validateVat = (address) => {
        if (this.isCompanyUsingNav()) {
            const check = validateVatNumber(formatVatNumber(address.vatid), address.country_code);

            if (check?.error) {
                const vatErrorMessage = !address.vatid ? this.tr("VAT no. is required") : (check?.format ? this.tr('VAT no. is not in correct format (e.g. ${exampleFormat})', {exampleFormat: check?.format}) : this.tr("VAT no. is not in correct format (begin with correct countrycode and have only numbers or letters)"));
                this.setState({ vatErrorMessage })
                return false;
            }
        } else if (false) {
            const isValid = address.vatid && String(address.vatid).length > 0;

            this.setState({ vatErrorMessage: !isValid ? this.tr("VAT no. is required") : "" });

            return isValid;
        }

        this.setState({ vatErrorMessage: "" })
        return true;
    }

    getDeliveryAddressFields = () => {
        const { checkPrivilege } = this.props;
        const disabled = !checkPrivilege('customers', 'write') || !checkPrivilege('projects', 'write');
        return [
            {
                title: this.tr('Account'),
                key: 'name',
            },
            {
                title: this.tr('Address'),
                key: 'address',
                disabled: disabled,
            },
            ...(this.context.taimerAccount.showState
                ? [
                      {
                          title: this.tr('City'),
                          key: 'city',
                          disabled: disabled,
                      },
                      {
                          title: this.tr('State'),
                          key: 'state',
                          disabled: disabled,
                      },
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                          disabled: disabled,
                      },
                  ]
                : [
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                          disabled: disabled,
                      },
                      {
                          title: this.tr('City'),
                          key: 'city',
                          disabled: disabled,
                      },
                  ]),
            {
                title: this.tr('Country'),
                key: 'country',
                disabled: disabled,
            },
            {
                key: 'contact',
                title: this.tr('Contact person'),
                disabled: disabled,
            },
        ];
    };

    getInvoicingAddressFields = () => {
        const { billing_addresses } = this.state;
        const { checkPrivilege, project } = this.props;
        const disabled = !checkPrivilege('customers', 'write') || !checkPrivilege('projects', 'write');
        const currencies = this.companyCurrencies?.map((c) => ({ rateId: c.id, id: c.currency_label, name: c.currency_label, label: c.currency_label, rate: c.currency_rate }));
        return [
            {
                title: this.tr('Address'),
                key: 'id',
                type: 'data_select',
                options: billing_addresses,
                isHidden: (item, isPreview) => !isPreview,
                setOtherValuesWithSelection: (item, value) => {
                    const foundItem = billing_addresses.find((ba) => ba.id == value) || {};
                    return foundItem;
                },
            },
            {
                title: this.tr('Account'),
                key: 'name',
                isHidden: (item) => !!item.id && item.id != -1,
            },
            {
                title: this.tr('VAT no.'),
                key: 'vatid',
                disabled: disabled,
                checkValidity: (address) => this.validateVat(address),
                required: this.isCompanyUsingNav(),
                errorMessage: this.tr(this.state.vatErrorMessage)
            },
            ...((this.context.addons.fortnox && this.context.addons.fortnox.used_by_companies.indexOf(this.props.company) > -1) ||
            (this.context.addons.meritaktiva && this.context.addons.meritaktiva.used_by_companies.indexOf(this.props.company) > -1)
                ? [
                      {
                          title: this.tr('VAT no. 2'),
                          key: 'vatid_2',
                          disabled: disabled,
                      },
                  ]
                : []),
            {
                title: this.tr('Address'),
                key: 'address',
                disabled: disabled,
            },
            ...(this.context.addons.fortnox && this.context.addons.fortnox.used_by_companies.indexOf(this.props.company) > -1
                ? [
                      {
                          title: this.tr('Address 2'),
                          key: 'address_2',
                          disabled: disabled,
                      },
                  ]
                : []),
            ...(this.context.taimerAccount.showState
                ? [
                      {
                          title: this.tr('City'),
                          key: 'city',
                          disabled: disabled,
                      },
                      {
                          title: this.tr('State'),
                          key: 'state',
                          disabled: disabled,
                      },
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                          disabled: disabled,
                      },
                  ]
                : [
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                          disabled: disabled,
                      },
                      {
                          title: this.tr('City'),
                          key: 'city',
                          disabled: disabled,
                      },
                  ]),
            {
                title: this.tr('Country'),
                key: 'country',
                disabled: disabled,
            },
            {
                title: this.tr('E-invoice operator'),
                key: 'e_operator',
                disabled: disabled,
            },
            {
                title: this.tr('E-invoice address'),
                key: 'e_address',
                disabled: disabled,
            },
            {
                title: this.tr('Invoicing email'),
                key: 'email',
                disabled: disabled,
            },
            {
                key: 'bill_language',
                title: this.tr('Invoice language'),
                type: 'data_select',
                options: this.state.billingLanguages,
                disabled: disabled,
            },
            ...(this.context.addons.nav == undefined
                ? [
                      {
                          key: 'reverse_charge',
                          title: this.tr('Reverse charge'),
                          type: 'data_select',
                          options: this.reverseChargeFields,
                          disabled: disabled,
                      },
                  ]
                : []),
            ...(this.context.addons.invoice_currency
                ? [
                      {
                          key: 'currency',
                          title: this.tr('Invoice currency'),
                          type: 'data_select',
                          disabled: disabled,
                          options: currencies,
                      },
                  ]
                : []),
            {
                key: 'contact',
                title: this.tr('Contact person'),
                disabled: disabled,
                tooltip: this.tr('Project invoicing address contact person tooltip'),
                getPlaceholder: (item) => ((item?.id || -1) == -1 ? '' : project.invoicing_address?.contact),
            },
            ...(this.isCompanyUsingCountryCode()
                ? [
                      {
                          key: 'country_code',
                          title: this.tr('Country code'),
                          type: 'data_select',
                          disabled: disabled,
                          options: this.state.allowedCountryCodes,
                          allOptions: this.state.allCountryCodes,                          
                          required: true
                      },
                  ]
                : []),
        ];
    };

    editInvoicingAddress = (address) => {
        const { project } = this.props;
        const billing_addresses = cloneDeep(this.state.billing_addresses);
        const addressWithoutContact = cloneDeep(address);
        delete addressWithoutContact.contact;
        DataHandler.put({ url: `accounts/billing_address/${address.id}` }, addressWithoutContact);
        this.props.valueChanged({ invoicing_address: { ...addressWithoutContact, contact: project.invoicing_address?.contact } });
        if (project.invoice_contact_person || (!project.invoice_contact_person && project.invoicing_address.contact !== address.contact)) {
            this.props.valueChanged({ invoice_contact_person: address.contact });
        }

        const index = billing_addresses.findIndex(b => b.id == address.id);
        billing_addresses[index] = address;
        this.setState({ billing_addresses });
    };

    onInvoicingAddressSaved = (address) => {
        const { billingAccountId } = this.state;
        const { project } = this.props;
        const newAddress = !address.id || address.id == -1;
        address.vatid = formatVatNumber(address.vatid);
        if (newAddress) {
            this.invoicingAddressSlider.current && this.invoicingAddressSlider.current.onCloseSlider();
            DataHandler.post({ url: `accounts/${billingAccountId}/billing_address` }, address).done((e) => {
                const billing_addresses = cloneDeep(this.state.billing_addresses);
                billing_addresses.push({ ...e, label: e.name, value: e.id });
                this.setState({ billing_addresses }, () => {
                    this.props.valueChanged({ invoicing_address: e });
                });
            });
            if (project.invoice_contact_person || (!project.invoice_contact_person && project.invoicing_address.contact !== address.contact)) {
                this.props.valueChanged({ invoice_contact_person: address.contact });
            }
        } else {
            let addressEdited = false;
            Object.keys(address).forEach((key) => {
                if (key != 'contact' && project.invoicing_address[key] != address[key]) {
                    addressEdited = true;
                }
            });
            if (addressEdited) {
                this.context.functions.showConfirmationDialog({
                    header: this.tr('Edit invoicing address'),
                    warning: this.tr("The changes you have made will be saved to the account's invoicing address. Do you still want to continue?"),
                    confirmText: this.tr('Save changes'),
                    confirmButtonClass: 'blue',
                    onConfirm: () => {
                        this.invoicingAddressSlider.current && this.invoicingAddressSlider.current.onCloseSlider();
                        this.editInvoicingAddress(address);
                    },
                });
            } else {
                this.invoicingAddressSlider.current && this.invoicingAddressSlider.current.onCloseSlider();
                this.editInvoicingAddress(address);
            }
        }
    };

    onInvoicingAddressChanged = (address) => {
        const { project } = this.props;
        let update = { invoicing_address: address };
        if (project.invoice_contact_person || (!project.invoice_contact_person && project.invoicing_address.contact !== address.contact)) {
            update = { 
                ...update,
                invoice_contact_person: address.contact 
            };
        }
        this.props.valueChanged(update);
    };

    onInvoicingAddressesSave = (invoicing_addresses) => {
        this.setState({ invoicing_addresses }, () => {
            this.use_single_account = false;   
            this.props.updateProjectData();
        });
    }

    openUseSingleAccountConfirmation = () => {
        this.context.functions.showConfirmationDialog({
            header: this.tr('Invoice single account?'),
            warning: this.tr('Are you sure you want to invoice single account? All selected accounts will be removed and default address from project customer selected as invoicing address.'),
            confirmText: this.tr('Invoice single account'),
            onConfirm: this.updateDefaultInvoicingAddress,
            confirmButtonClass: "blue"
        });
    }

    updateDefaultInvoicingAddress = () => {
        this.setState({ invoicing_addresses: [] }, () => {
            // Set this.billingAddressAccount to -1 so it is different than billingAccountId and invoicing address gets updated.
            this.billingAddressAccount = -1;
            // Set billingAccountId to 0 so project's account is used to fetch new invoicing address.  
            this.use_single_account = true;   
            this.billingAccountChanged({ id: 0 });
        });
    }

    getAddedTeamMembers = () => {
        const { project } = this.props;

        const teamMembersWithTags = project.team_members?.filter((c) => c.deleted != 1 && c.type != "team")
            .map(t => ({ ...t, label: `${t.label}${this.props.employees.find(e => e.id == t.users_id)?.companies_id > 0 ? '' : ` (${this.translations.freelancer})`}${this.props.employees.find(e => e.id == t.users_id)?.locked > 0 ? ` (${this.translations.locked})` : ''}` }))

        const addedTeams = project.team_members?.filter((c) => c.deleted != 1 && c.type == "team")
            .map(t => {
                t.oneRow = true;
                return t;
            }) || [];

        (teamMembersWithTags || []).push(...addedTeams);

        return teamMembersWithTags;
    }

    getTeamOptions = () => {
        const { teams } = this.state;
        const { project } = this.props;

        const projectTeamOptions = this.createTeamDropdown();
        const filteredTeamMembers = projectTeamOptions.filter((e) => project.team_members.find((ee) => ee.users_id === e.users_id) === undefined);

        const allowedTeams = project.limited_project_team == 0
            ? teams
            : teams.filter(t => t.companies_id == project.companies_id);

        const filteredTeams = allowedTeams.filter((e) => project.team_members.find((ee) => ee.id === "t_" + e.id) === undefined);

        const users = filteredTeamMembers.map(u => {
            const id = "u_" + u.id;
            return {
                ...u,
                id: id,
                value: id,
                users_id: u.id,
                headerName: "user",
                type: "user",
            };
        });

        const groups = filteredTeams.map(g => {
            const id = "t_" + g.id;
            return {
                id: id,
                value: id,
                label: g.name,
                name: g.name,
                headerName: "team",
                type: "team",
            };
        });

        const options =
            [
                { icon: TeamIcon, type: "header", label: this.tr('Teams'), name: 'team' },
                ...groups,
                { icon: UserIcon, type: "header", label: this.tr('Users'), name: 'user' },
                ...users
            ];

        return options;
    }

    renderTeam = (showAll) => {
        const { project, editable, valueChanged, sales_users, employees } = this.props;

        return (
            <>
                <SliderFieldGroup
                    canDeleteItem={() => editable}
                    editingDisabled={!editable}
                    onItemAdded={this.addTeamMember}
                    onItemDeleted={(item) => this.rmTeamMember(item.id)}
                    items={this.getAddedTeamMembers()}
                    renderRightComponentForItem={(item) => 
                        item.type == "team"
                            ? <TeamIcon className={styles.teamListTeamIcon}/>
                            : <TaimerAvatar name={item.label} color={item.color} id={item.users_id} />
                    }
                    allowAdd
                    showAll={showAll}
                    addFromDropdownItems={this.getTeamOptions()}
                    addFromDropdownModeHasHeaders={true}
                    getOptions={(teamMember) =>
                        editable
                            ? [
                                  ...(teamMember.type == "team" || !this.context.functions.checkPrivilege('admin', 'admin', project.companies_id)
                                      ? []
                                      : [
                                            {
                                                key: 'view',
                                                title: this.tr('View user'),
                                                onClick: (item) => {
                                                    this.closeTeamMemberSlider();
                                                    this.context.functions.updateView({
                                                        module: 'users',
                                                        action: 'view',
                                                        id: item.users_id,
                                                        company: project.companies_id,
                                                    });
                                                },
                                                icon: <Visibility />,
                                            },
                                        ]),
                                ...(teamMember.type != "team" && employees.find(u => u.id == teamMember.users_id) 
                                    ? [
                                        {
                                            key: 'project_manager',
                                            title: this.tr('Assign as project manager'),
                                            onClick: (item) => valueChanged({ users_id: item.users_id }),
                                            icon: <AssignAsProjectManagerIcon />,
                                        },
                                    ]
                                    : []),
                                ...(teamMember.type != "team" && sales_users.find(u => u.id == teamMember.users_id) 
                                    ? [
                                        {
                                            key: 'sales_manager',
                                            title: this.tr('Assign as sales manager'),
                                            onClick: (item) => valueChanged({ status_users_id: item.users_id }),
                                            icon: <AssignAsSalesAgentIcon />,
                                        },
                                    ]
                                    : []),
                                  {
                                      key: 'remove',
                                      title: this.tr('Remove'),
                                      isDelete: true,
                                      icon: <Delete />,
                                  },
                              ]
                            : []
                    }
                    fields={[
                        {
                            key: 'label',
                            title: this.tr('Name'),
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'label',
                            title: this.tr('Team'),
                            isHidden: (item) => item.type != "team"
                        },
                        {
                            key: 'email',
                            title: this.tr('Email'),
                            disabled: true,
                            formURL: (value) => `mailto:${value}`,
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'phone',
                            title: this.tr('Phone'),
                            disabled: true,
                            formURL: (value) => `tel:${value}`,
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'role',
                            title: this.tr('Title'),
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'tags',
                            title: this.tr('Roles'),
                            isHidden: (item, inPreview) => item.type == "team" || !inPreview || !(item.users_id === project.users_id || item.users_id === project.status_users_id),
                            customPreviewComponent: (item) => {
                                const text = [];
                                if (item?.users_id === project.users_id) text.push(this.tr('project manager'));
                                if (item?.users_id === project.status_users_id) text.push(this.tr('sales manager'));
                                return <h3>{text.join(', ')}</h3>;
                            },
                        },
                    ]}
                />
            </>
        );
    };

    renderDimensionTeam = (showAll) => {
        const { dimensionTeamMembers } = this.state;
        return (
            <SliderFieldGroup
                editingDisabled
                showAll={showAll}
                items={dimensionTeamMembers}
                renderRightComponentForItem={(item) => <TaimerAvatar name={item.name} color={item.color} id={item.id} />}
                fields={[
                    {
                        key: 'name',
                        title: this.tr('Name'),
                    },
                    {
                        key: 'email',
                        title: this.tr('Email'),
                        disabled: true,
                        formURL: (value) => `mailto:${value}`,
                    },
                    {
                        key: 'title',
                        title: this.tr('Title'),
                    },
                ]}
            />
        );
    };

    renderContacts = (showAll) => {
        const { contacts } = this.state;
        const { project, editable } = this.props;
        return (
            <div>
                <SliderFieldGroup
                    onItemAdded={this.addContact}
                    editingDisabled
                    canDeleteItem={(_, isPreview) => editable && isPreview}
                    onItemDeleted={(item) => this.rmContact(item.id)}
                    items={project.contacts}
                    newItemDefaultValues={contacts[0]}
                    renderRightComponentForItem={(contact) => <TaimerAvatar className={styles.contactAvatar} key={contact.id} name={contact.label} noavatar title="" />}
                    showAll={showAll}
                    allowAdd={editable}
                    addFromDropdownItems={(contacts || []).filter((c) => (project.contacts || []).findIndex((pc) => pc.id == c.id) == -1)}
                    addDropdownNoOptions={AddContact}
                    addDropdownNoOptionsProps={{
                        companies_id: project.companies_id,
                        customers_id: project.account?.id,
                        contact_type: '1',
                        projects: [
                            {
                                name: `${project.name} (${project.project_id})`,
                                label: `${project.name} (${project.project_id})`,
                                id: project.id,
                                value: project.id,
                            },
                        ],
                    }}
                    getOptions={() =>
                        editable
                            ? [
                                  ...(this.context.functions.checkPrivilege('persons', 'read', project.companies_id)
                                      ? [
                                            {
                                                key: 'view',
                                                title: this.tr('View contact'),
                                                onClick: (item) => {
                                                    this.closeContactSlider();
                                                    this.context.functions.updateView({ module: 'contact', action: 'view', id: item.id, company: project.companies_id });
                                                },
                                                icon: <Visibility />,
                                            },
                                        ]
                                      : []),
                                  {
                                      key: 'remove',
                                      title: this.tr('Remove'),
                                      isDelete: true,
                                      icon: <Delete />,
                                  },
                              ]
                            : []
                    }
                    fields={[
                        {
                            key: 'label',
                            title: this.tr('Name'),
                            disabled: true,
                        },
                        {
                            key: 'title',
                            title: this.tr('Title'),
                            disabled: true,
                        },
                        {
                            key: 'phone',
                            title: this.tr('Phone'),
                            validation: 'phone',
                            disabled: true,
                        },
                        {
                            key: 'email',
                            title: this.tr('Email'),
                            validation: 'email',
                            disabled: true,
                        },
                    ]}
                />
            </div>
        );
    };

    renderPartners = (showAll) => {
        const partners = this.state.allContacts;
        const { project, editable } = this.props;
        return (
            <div>
                <SliderFieldGroup
                    ref={this.partnersSection}
                    editingDisabled
                    onItemDeleted={(item) => this.rmPartner(item.id)}
                    canDeleteItem={(_, isPreview) => editable && isPreview}
                    onItemAdded={this.addPartner}
                    items={project.partners}
                    newItemDefaultValues={partners[0]}
                    renderRightComponentForItem={(contact) => <TaimerAvatar className={styles.contactAvatar} key={contact.id} name={contact.label} noavatar title="" />}
                    showAll={showAll}
                    allowAdd={editable}
                    addFromDropdownItems={(partners || []).filter((p) => (project.partners || []).findIndex((pp) => pp.id == p.id) == -1)}
                    addDropdownNoOptions={AddContact}
                    addDropdownNoOptionsProps={{
                        companies_id: project.companies_id,
                        customers_id: project.account?.id,
                        contact_type: '2',
                        projects: [
                            {
                                name: `${project.name} (${project.project_id})`,
                                label: `${project.name} (${project.project_id})`,
                                id: project.id,
                                value: project.id,
                            },
                        ],
                    }}
                    getOptions={() =>
                        editable
                            ? [
                                  ...(this.context.functions.checkPrivilege('persons', 'read', project.companies_id)
                                      ? [
                                            {
                                                key: 'view',
                                                title: this.tr('View contact'),
                                                onClick: (item) => this.context.functions.updateView({ module: 'contact', action: 'view', id: item.id, company: project.companies_id }),
                                                icon: <Visibility />,
                                            },
                                        ]
                                      : []),
                                  {
                                      key: 'remove',
                                      title: this.tr('Remove'),
                                      isDelete: true,
                                      icon: <Delete />,
                                  },
                              ]
                            : []
                    }
                    fields={[
                        {
                            key: 'label',
                            title: this.tr('Name'),
                            disabled: true,
                        },
                        {
                            key: 'title',
                            title: this.tr('Title'),
                            disabled: true,
                        },
                        {
                            key: 'phone',
                            title: this.tr('Phone'),
                            validation: 'phone',
                            disabled: true,
                        },
                        {
                            key: 'email',
                            title: this.tr('Email'),
                            validation: 'email',
                            disabled: true,
                        },
                    ]}
                />
            </div>
        );
    };

    renderInvoicingAddresses = (showAll) => {
        const { invoicing_addresses } = this.state;
        const { editable } = this.props;
        const { taimerAccount } = this.context;

        const percentFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            useGrouping: false,
            minimumFractionDigits: 0,
            maximumFractionDigits: 4
        }).format;

        return (
            <div>
                <SliderFieldGroup
                    editingDisabled
                    items={invoicing_addresses}
                    showAll={showAll}
                    customButtons={editable ? [
                        {
                            label: this.tr("Edit"),
                            onClick: () => this.openInvoiceMultipleAccountsSlider()
                        },
                        {
                            label: this.tr("Invoice single account"),
                            onClick: () => {                            
                                this.openUseSingleAccountConfirmation();
                            }
                        },
                    ] : []}
                    fields={[
                        {
                            key: 'customer_name',
                            title: this.tr('Account'),
                            disabled: true,
                            forceVisible: true
                        },
                        {
                            key: 'address_label',
                            title: this.tr('Address'),
                            disabled: true,
                            forceVisible: true
                        },
                        {
                            key: 'percentage',
                            title: this.tr('Share of invoicing'),
                            disabled: true,
                            forceVisible: true,
                            formatValue: (value, field) => percentFormatter(value) + " %" 
                        },
                        {
                            key: 'customer_reference',
                            title: this.tr('Your reference'),
                            disabled: true,
                            forceVisible: false
                        },
                    ]}
                />
            </div>
        );
    };

    getGoogleDriveProjectFolder = async (projectId) => {
        const response = await DataHandler.post({ url: 'drive/google/getprojectfolder' }, { project_id: projectId })
            .done(response => {
                if (response.error) {
                    console.log(response.error);
                }
            })
            .fail(err => {
                console.log(err);
            });

        return response;
    };

    getGoogleDriveAccountFolder = async (clientId) => {

        const response = await DataHandler.post({ url: 'drive/google/getclientfolder' }, { client_id: clientId })
            .done(response => {
                if (response.error) {
                    console.log(response.error);
                }
            })
            .fail(err => {
                console.log(err);
            });
       return response;
    };

    createGoogleDriveFolder = async (folderParent, folderName) => {
        const response = await DataHandler.post({ url: 'drive/google/createfolder' }, { folder_parent: folderParent, folder_name: folderName })
            .done(response => {
                if (response.error) {
                    console.log(response.error);
                }
            })
            .fail(err => {
                console.log(err);
            });
        return response.folder;
    };

    setGoogleDriveClientFolder = (clientId, folderId) => {
        DataHandler.post({ url: 'drive/google/setclientfolder' }, { client_id: clientId, folder_id: folderId })
            .done(response => {
                if (response.error) {
                    console.log(response.error);
                }
            })
            .fail(err => {
                console.log(err);
            });
    };

    setNewAccountToProjectFolder = (projectFolderId,accountFolderId) => {

        const { enqueueSnackbar } = this.props;

        DataHandler.post({ url: 'drive/google/movefile' }, { file_id: projectFolderId, parent_id: accountFolderId })
        .done(response => {
            if (response.error) {
                console.log(response.error);
            } else {
                enqueueSnackbar(this.tr('Attachment folder moved'), {
                    variant: "success"
                });
            }
        })
        .fail(err => {
            console.log(err);
        });
    }

    onProjectDetailsEdited = async (details) => {
        let formattedFields = {};
        if (details.tags) {
            formattedFields = {
                ...formattedFields,
                tags: (details.tags || []).map((t) => (typeof t == 'string' ? t : t.label)),
            };
        }
        const formattedDetails = {
            ...details,
            ...formattedFields,
        };
        if (details.account?.id != this.props.project?.account?.id) {

            if(this.googleDrive){

                const { enqueueSnackbar } = this.props;

                if(!this.googleDriveAuth || this.googleDriveRootFolderId === ''){
                    enqueueSnackbar(this.tr('Unable to change the account. Please check your settings regarding Google Drive.'), {
                        variant: "error"
                    });
                    return;
                } 

                const projectFolder = await this.getGoogleDriveProjectFolder(this.props.project.id).catch(err => {  console.log(err); });

                if(projectFolder.status !== 'no-folder' && projectFolder.status !== 'folder-trashed'){
                    
                    //Let's still check the status as there might be more variation in the future
                    if(projectFolder.status ===  'ok'  && projectFolder.folder.id ){  

                        enqueueSnackbar(this.tr('Attempting to move attachment folder on Google Drive'), {
                            variant: "info"
                        });

                        const accountFolder = await this.getGoogleDriveAccountFolder(details.account.id).catch(err => { console.log(err); });

                        //If the new client has a folder, we set it as the parent. If not, we have to create it first
                        if(accountFolder.status !== 'no-folder' && accountFolder.status !== 'folder-trashed'){
                           
                            if(accountFolder.status ===  'ok' && accountFolder.folder.id ) this.setNewAccountToProjectFolder(projectFolder.folder.id,accountFolder.folder.id);
                            
                        } else {
                            
                            const folder = await this.createGoogleDriveFolder('', details.account.name + ' (' + details.account.id + ')').catch(err => { console.log(err); });
                
                            if (folder && Object.keys(folder).length > 0) {
                                this.setGoogleDriveClientFolder(details.account.id, folder.id);
                                this.setNewAccountToProjectFolder(projectFolder.folder.id,folder.id);
                            } else {
                                enqueueSnackbar(this.tr('Creating folder for the account has failed. Contact the administrator.'), {
                                    variant: "error"
                                });
                                return;
                            }

                        }
    
                    }
                }
            }

            this.accountChanged(details.account);
        }
        this.props.valueChanged(formattedDetails);
        if (details.tags) {
            this.getTagPool();
        }
        if (details.types) {
            this.getProjectTypes();
        }
    };

    formatInvoicingSwitchValue = (dateString) => {
        const date = new Date(dateString);
        if (isValid(date)) {
            return `${this.tr('from ${date}', { date: format(date, this.context.userObject.dateFormat) })}`;
        }
        return '';
    };

    isStatusReasonHidden = () => {
        const projectWon = this.props.project?.status == '1' && this.props.project?.locked == '-1';
        const projectLost = this.props.project?.status == '0' && (this.props.project?.locked == '1' || this.props.project?.locked == '3');
        return !((projectWon && this.props.projectWonReasonIsInUse()) || (projectLost && this.props.projectLostReasonIsInUse()));
    };

    renderProjectNameField = (item) => {
        if (!item) return <></>;
        return (
            <this.ProjectNameField project={item} onChange={{}} />
        )
    }

    ProjectNameField = ({ project, onChange }) => {
        console.log('project', project);
        return (
            <div className={styles.nameAndColorPicker}>
                <ColorPicker
                    key={'color'}
                    color={project?.color}
                    className={styles.colorPicker}
                    onChange={(color) => onChange({ target: { name: 'color', value: color } })}
                />
                <OutlinedField
                    key={'name'}
                    disabled={false}
                    label={this.tr('Project name')}
                    name={'name'}
                    value={project?.name || ''}
                    required={true}
                    onChange={onChange}
                    error={!!this.projectDetailsSlider.current.slider.current.state.fieldErrors['name']}
                />
            </div>
        );
    };

    renderContentForDetailsSection = (section, showAll = false) => {
        const { taimerAccount, addons } = this.context;
        const { checkPrivilege, project, salesStates, customFields, customFieldsErrors, formErrors, editable } = this.props;
        const {
            accounts,
            project_types,
            companies,
            companyCurrency,
            customershipGroups,
            dimensionTeams,
            statusReasons,
            tagpool,
            branchesOfBusiness,
            reportingGroups,
            mediaproContracts,
            mediaproProducts,
            hiddenFields
        } = this.state;

        const canWriteMediapro = checkPrivilege('customers', 'mediapro_write', this.props.company);
        const canSeeMediapro =
            addons.hasOwnProperty('mediapro') &&
            addons.mediapro.used_by_companies.indexOf(this.props.company) > -1 &&
            checkPrivilege('customers', 'mediapro_read', this.props.company);

        let statusKey = 'won';
        const projectWon = project.status == '1' && project.locked == '-1';
        const projectLost = project.status == '0' && (project.locked == '1' || project.locked == '3');
        if (projectLost) statusKey = 'lost';

        const filteredBranchesOfBusiness = branchesOfBusiness.filter((x) => x.deleted != 1 || hasSubvalue(x, project.branchofbusiness_id));
        
        switch (section) {
            
            case 'details':
                return (
                    <>
                        <SliderFieldGroup
                            items={[project]}
                            editButtonLocation="top"
                            onItemSaved={this.onProjectDetailsEdited}
                            sliderEditTitle={this.tr('Edit project details')}
                            editingDisabled={!editable}
                            ref={this.projectDetailsSlider}
                            additionalFields={(item, onFieldChanged) => (
                                <CustomFields
                                    project={project}
                                    header={this.tr('Additional information')}
                                    errors={{ ...customFieldsErrors, ...formErrors }}
                                    projects_sales_states={salesStates}
                                    ref={this.refCustomFields}
                                    fields={customFields}
                                    form={0}
                                    values={item?.custom || {}}
                                    onChange={(key, value) => {
                                        const values = {
                                            custom: {
                                                ...item.custom,
                                                [key.replace('custom_', '')]: value,
                                            },
                                        };
                                        onFieldChanged(values);
                                    }}
                                />
                            )}
                            fields={[
                                {
                                    key: 'companies_id',
                                    title: this.tr('Company'),
                                    type: 'data_select',
                                    options: companies.map((c) => ({ ...c, label: c.name, value: c.id })),
                                    disabled: true,
                                    isHidden: () => !taimerAccount.isMulticompany,
                                },
                                {
                                    key: 'account',
                                    title: this.tr('Account'),
                                    type: 'select',
                                    options: accounts,
                                    disabled: this.googleDrive ? !this.googleDriveAuth : false,
                                    noOptions: AddAccount,
                                    additionalProps: {
                                        companies_id: project.companies_id,
                                        disabledSubtext: '(' + this.tr('Project creation not allowed') + ')',
                                        isOptionDisabled: (account) => account.allow_project_creation != 1,
                                    },
                                },
                                {
                                    key: 'name',
                                    title: this.tr('Project name'),
                                    required: true,
                                    customComponent: this.renderProjectNameField,
                                    customComponentMediator: (slider) => {
                                        return {
                                            onChange: (e) => {
                                                slider.setFieldValue(e.target.name, e.target.value);
                                            },

                                        }
                                    }
                                },
                                {
                                    key: 'project_id',
                                    title: this.tr('Project number'),
                                    required: addons.custom_project_id,
                                    isHidden: () => !addons.custom_project_id,
                                },
                                {
                                    key: 'dimension_teams_id',
                                    title: this.tr('Dimension team'),
                                    type: 'data_select',
                                    options: dimensionTeams,
                                    required: taimerAccount.mandatory_team_selection_for_projects,
                                    isHidden: () => !this.usesDimensions || dimensionTeams.length == 0,
                                    setOtherValuesWithSelection: (item, value) => {
                                        const branchofbusiness_id = this.context.taimerAccount.force_teams_branchofbusiness_for_projects
                                            ? this.state.dimensionTeams.find((t) => t.id === value)?.category
                                            : item.branchofbusiness_id;
                                        return { branchofbusiness_id };
                                    },
                                },
                                {
                                    key: 'branchofbusiness_id',
                                    title: this.tr('Category'),
                                    type: 'treeselect',
                                    options: branchesOfBusiness,
                                    required: filteredBranchesOfBusiness.length > 0,
                                    isHidden: () => filteredBranchesOfBusiness.length == 0,
                                    disabled: this.usesDimensions && taimerAccount.force_teams_branchofbusiness_for_projects && project.dimension_teams_id && project.branchofbusiness_id,
                                    additionalProps: {
                                        customItemLock: (item) => {
                                            return item.deleted == 1 || item.locked == 1;
                                        },
                                        hideOptions: (options, value) => {
                                            function filter(options, value) {
                                                const newOptions = [];
                                                for (const o of options.filter((f) => f.locked != 1 || (f.locked == 1 && f.id == value))) {
                                                    o.children = filter(o.children, value);
                                                    newOptions.push(o);
                                                }
                                                return newOptions;
                                            }
                                            const o = filter(options, value);
                                            return o;
                                        },
                                    },
                                },
                                {
                                    key: 'types',
                                    title: this.tr('Project Type'),
                                    type: 'select',
                                    isMulti: true,
                                    options: project_types.map((pt) => ({ ...pt, value: pt.id })),
                                    formatMultiValue: (value) => ({ ...value, value: value.id }),
                                    noOptions: AddProjectType,
                                    additionalProps: {
                                        company: project.companies_id,
                                    },
                                },
                                {
                                    key: 'product_structures_id',
                                    title: this.tr('Reporting group'),
                                    type: 'treeselect',
                                    options: reportingGroups.map((c) => ({ ...c, label: c.name, value: c.id })),
                                    hideIfEmpty: true,
                                },
                                {
                                    key: 'customership_groups_id',
                                    title: this.tr('Customership group'),
                                    type: 'data_select',
                                    options: customershipGroups.filter((g) => g.deleted != 1).map((c) => ({ ...c, label: c.name, value: c.id })),
                                    addNoneOption: true,
                                    hideIfEmpty: true,
                                },
                                {
                                    key: 'project_timespan',
                                    startKey: 'startdate',
                                    endKey: 'enddate',
                                    title: this.tr('Project duration'),
                                    type: 'daterange',
                                },
                                ...(this.state.use_events > 0
                                    ? [
                                          {
                                              key: 'event_range',
                                              startKey: 'event_startdate',
                                              endKey: 'event_enddate',
                                              title: this.tr('Event dates'),
                                              type: 'daterange',
                                          },
                                      ]
                                    : []),
                                {
                                    key: 'closing_date',
                                    title: this.tr('Expected close date'),
                                    type: 'date',
                                    isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'pipelines')
                                },
                                {
                                    key: 'extra_maxhours',
                                    title: this.tr('Allocated hours'),
                                    validation: 'numeric',
                                    formatValue: (value) => Number(value).toFixed(2),
                                    isHidden: () => !this.context.taimerAccount.useExtraProjectHours,
                                },
                                {
                                    key: 'maxhours',
                                    title: this.tr('Total allocated hours'),
                                    validation: 'numeric',
                                    formatValue: (value) => Number(value).toFixed(2),
                                    disabled: project.has_tasks || this.context.taimerAccount.useExtraProjectHours,
                                },
                                {
                                    key: 'customers_mediapro_contracts_id',
                                    type: 'data_select',
                                    title: this.tr('Mediapro contract'),
                                    options: mediaproContracts,
                                    disabled: !canWriteMediapro,
                                    isHidden: () => !canSeeMediapro,
                                },
                                {
                                    key: 'customers_mediapro_products_id',
                                    type: 'data_select',
                                    title: this.tr('Mediapro product'),
                                    options: mediaproProducts,
                                    disabled: !canWriteMediapro,
                                    isHidden: () => !canSeeMediapro,
                                },
                                {
                                    key: 'integration_id',
                                    title: this.tr('Hubspot ID'),
                                    isHidden: () => !(this.context.addons && this.context.addons.hubspot),
                                },
                                {
                                    key: 'status_reason_id',
                                    title: project.status == 1 ? this.tr('Won Reason') : this.tr('Lost Reason'),
                                    type: 'data_select',
                                    options: (statusReasons[statusKey] || []).map((reason) => ({ ...reason, label: reason.name })),
                                    isHidden: this.isStatusReasonHidden,
                                },
                                {
                                    key: 'status_reason_comment',
                                    title: project.status == 1 ? this.tr('Won Comment') : this.tr('Lost Comment'),
                                    isHidden: this.isStatusReasonHidden,
                                    additionalProps: {
                                        multiline: true,
                                    },
                                },
                                {
                                    key: 'costest_sum',
                                    title: this.tr('Deal Value'),
                                    isHidden: () => !checkPrivilege('projects', 'project_cost_estimate_read'),
                                    disabled: !this.state.can_edit_quote,
                                    validation: 'numeric',
                                    additionalProps: {
                                        format: 'currency',
                                        currency: companyCurrency,
                                    },
                                },
                                {
                                    key: 'costest_margin',
                                    title: this.tr('Deal Margin'),
                                    isHidden: () => !checkPrivilege('projects', 'project_cost_estimate_read'),
                                    disabled: !this.state.can_edit_quote,
                                    validation: 'numeric',
                                    additionalProps: {
                                        format: 'currency',
                                        currency: companyCurrency,
                                    },
                                },
                                {
                                    key: 'tags',
                                    title: this.tr('Tags'),
                                    type: 'select',
                                    isMulti: true,
                                    noOptions: AddTag,
                                    options: tagpool.map((t) => ({ ...t, value: t.id, label: t.name })),
                                    formatMultiValue: (value, options) => options.find((o) => (value?.id ? o.id == value.id : o.tag == value)),
                                    additionalProps: {
                                        company: project.companies_id,
                                        tagType: 2,
                                    },
                                },
                                {
                                    key: 'resourcing_note',
                                    title: this.tr('Resourcing Note'),
                                    isHidden: () => !(this.context.addons && this.context.addons.resourcing),
                                    additionalProps: {
                                        multiline: true,
                                    },
                                },
                                {
                                    key: 'misc_info',
                                    title: this.tr('Additional info'),
                                },
                                {
                                    key: 'log_created',
                                    title: this.tr('Project Created'),
                                    type: 'date',
                                    disabled: !editable,
                                },
                                {
                                    key: 'projects_pipelines_date',
                                    title: this.tr('Added to Pipeline'),
                                    type: 'date',
                                    disabled: !editable,
                                    isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'pipelines')
                                },
                                {
                                    key: 'state_changed',
                                    title: this.tr('Stage Changed'),
                                    type: 'date',
                                    disabled: !editable,
                                    isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'pipelines')
                                },
                                {
                                    key: 'status_date',
                                    title: this.tr('Status Changed'),
                                    type: 'date',
                                    disabled: !editable,
                                    isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'pipelines')
                                },
                            ]}
                        />
                        <CustomFieldsPreview fields={customFields} values={project.custom} form={0} />
                    </>
                );
            case 'project_team': {
                return this.renderTeam(showAll);
            }
            case 'dimensions': {
                return this.renderDimensionTeam(showAll);
            }
            case 'project_contacts': {
                return this.renderContacts(showAll);
            }
            case 'partners': {
                return this.renderPartners(showAll);
            }
            case 'invoicing_address':
                return this.state.invoicing_addresses?.length > 0
                ? this.renderInvoicingAddresses(showAll) 
                : (
                    <div className={styles.invoicingAddress}>
                        <DataList
                            label={this.tr('Define billable account')}
                            name="companies_id"
                            value={accounts.find((x) => x.id === this.state.billingAccountId)}
                            options={accounts}
                            onChange={this.billingAccountChanged}
                            shownCount={20}
                            key={accounts.id}
                            style={{ marginBottom: 8 }}
                            rightIcon={
                                <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr("Invoice multiple accounts")} placement="top" arrow>
                                    <ArrowSplitIcon 
                                        onClick={() => this.openInvoiceMultipleAccountsSlider()} 
                                        className="clickable-icon" 
                                    />
                                </Tooltip>
                            }
                        />
                        <SliderFieldGroup
                            ref={this.invoicingAddressSlider}
                            key="invoicing_address"
                            editingDisabled={!editable}
                            items={[{ ...project.invoicing_address, contact: project.invoice_contact_person }]}
                            fields={this.getInvoicingAddressFields()}
                            overrideOnItemSaved
                            onItemSaved={this.onInvoicingAddressSaved}
                            onItemChanged={this.onInvoicingAddressChanged}
                            newItemDefaultValues={{ name: project.account?.name, reverse_charge: '0' }}
                            allowChange
                            allowAdd={Number(this.state.billingAccountId || 0) > 0}
                            changeButtonTooltip={this.tr('Change invoicing address')}
                            sliderEditTitle={this.tr('Edit invoicing address')}
                            sliderAddTitle={this.tr('Add invoicing address')}
                            getOptions={() =>
                                editable
                                    ? [
                                          {
                                              key: 'edit',
                                              title: this.tr('Edit'),
                                              isEdit: true,
                                          },
                                      ]
                                    : []
                            }
                            changeFromDropdownItems={(this.state.billing_addresses || []).map((ba) => ({ ...ba, label: `${ba.label} / ${ba.address}` }))}
                        />
                    </div>
                );
            case 'delivery_address':
                return (
                    <div>
                        <SliderFieldGroup
                            key="delivery_address"
                            editingDisabled={!editable}
                            items={[project.delivery_address]}
                            fields={this.getDeliveryAddressFields()}
                            onItemSaved={this.onDeliveryAddressSaved}
                            onItemChanged={this.onDeliveryAddressSaved}
                            newItemDefaultValues={{ name: project.account?.name }}
                            allowAdd
                            allowChange
                            changeButtonTooltip={this.tr('Change delivery address')}
                            sliderEditTitle={this.tr('Edit delivery address')}
                            sliderAddTitle={this.tr('Add delivery address')}
                            getOptions={() =>
                                editable
                                    ? [
                                          {
                                              key: 'edit',
                                              title: this.tr('Edit'),
                                              isEdit: true,
                                          },
                                      ]
                                    : []
                            }
                            changeFromDropdownItems={this.state.delivery_addresses}
                        />
                    </div>
                );
            case 'invoicing_details': {
                const { project } = this.props;

                const invoicingTypes = [
                    {
                        value: '1',
                        id: '1',
                        label: this.tr('Invoiceable'),
                    },
                    {
                        value: '2',
                        id: '2',
                        label: this.tr('Not invoiceable'),
                    },
                    {
                        value: '3',
                        id: '3',
                        label: this.tr('Vacation'),
                    },
                ];

                return (
                    <div>
                        <SliderFieldGroup
                            items={[project]}
                            editButtonLocation="top"
                            onItemSaved={this.onProjectDetailsEdited}
                            sliderEditTitle={this.tr('Edit invoicing details')}
                            editingDisabled={!editable}
                            groupName="invoicing_details"
                            testKey={section}
                            fields={[
                                {
                                    key: 'type',
                                    title: this.tr('Invoicing Status'),
                                    options: invoicingTypes,
                                    type: 'data_select',
                                    verticalAlignment: 'center',
                                    disabled: VersionContentManager.isFeatureDisabled(this.namespace, 'invoicingStatus'),
                                    customPreviewComponent: () => {
                                        const invoiceabilityData = this.getInvoiceabilityData();
                                        return <StatusTag text={invoiceabilityData.text} color={invoiceabilityData.color} />;
                                    },
                                },
                                {
                                    key: 'active_billable_header',
                                    type: 'header',
                                    title: this.tr('Active billable items'),
                                    tooltip: this.tr('Active billable items tooltip'),
                                    noItemsText:
                                        (project.charge_hours || 0) == '0' &&
                                        (project.charge_traveling || 0) == '0' &&
                                        (project.charge_others || 0) == '0' &&
                                        (project.charge_received_invoices || 0) == '0' &&
                                        (project.charge_costest || 0) == '0' &&
                                        this.tr('No active billable items.'),
                                },
                                {
                                    key: 'charge_hours',
                                    dateKey: 'charge_hours_after',
                                    title: this.tr('Hours'),
                                    type: 'dateswitch',
                                    tooltip: this.tr('Charge hours tooltip'),
                                    valuePostfix: () => this.formatInvoicingSwitchValue(project.charge_hours_after),
                                },
                                {
                                    key: 'charge_traveling',
                                    dateKey: 'charge_traveling_after',
                                    title: this.tr('Expenses'),
                                    type: 'dateswitch',
                                    valuePostfix: () => this.formatInvoicingSwitchValue(project.charge_traveling_after),
                                },
                                {
                                    key: 'charge_others',
                                    title: this.tr('Scheduled billing'),
                                    type: 'switch',
                                },
                                {
                                    key: 'charge_received_invoices',
                                    title: this.tr('Bills'),
                                    type: 'switch',
                                    isHidden: () => !(this.context.versionId == 4 || this.context.addons.bills) || VersionContentManager.isFeatureHidden(this.namespace, 'bills'),
                                },
                                {
                                    key: 'charge_costest',
                                    title: this.tr('Quote'),
                                    type: 'switch',
                                },
                                {
                                    key: 'row_grouping_header',
                                    type: 'header',
                                    title: this.tr('Invoice row grouping'),
                                    noItemsText:
                                        (project.invoicing_hour_task_grouping || 0) == '0' &&
                                        (project.invoicing_hour_grouping || 0) == '0' &&
                                        (project.invoicing_expense_grouping || 0) == '0' &&
                                        (project.invoicing_quote_grouping || 0) == '0' &&
                                        this.tr('No active groupings.'),
                                },
                                {
                                    key:  'invoicing_hour_task_grouping',
                                    title: !(this.context.addons && this.context.addons.resourcing) ? this.tr("Project grouping") : this.tr('Project & Task grouping'),
                                    type: 'data_select',
                                    isHidden: (item, isPreview) => (isPreview && item.invoicing_hour_task_grouping == 0),
                                    options: this.projectsGrouping,
                                    additionalProps: {
                                        customOption: TooltipOption,
                                    },
                                },
                                {
                                    key: 'invoicing_hour_grouping',
                                    title: this.tr('Workhours grouping'),
                                    type: 'data_select',
                                    isHidden: (item, isPreview) => (isPreview && item.invoicing_hour_grouping == 0),
                                    options: this.workhoursGrouping,
                                    additionalProps: {
                                        customOption: TooltipOption,
                                    },
                                },
                                {
                                    key: 'invoicing_expense_grouping',
                                    title: this.tr('Worktrips grouping'),
                                    type: 'data_select',
                                    isHidden: (item, isPreview) => (isPreview && item.invoicing_expense_grouping == 0),
                                    options: this.worktripsGrouping,
                                },
                                {
                                    key: 'invoicing_quote_grouping',
                                    title: this.tr('Costestimate grouping'),
                                    type: 'data_select',
                                    isHidden: (item, isPreview) => (isPreview && item.invoicing_quote_grouping == 0),
                                    options: this.costestGrouping,
                                },
                                {
                                    key: 'other_header',
                                    type: 'header',
                                    title: this.tr('Other invoicing settings'),
                                },
                                {
                                    key: 'allow_mass_invoicing',
                                    title: this.tr('Mass invoicing'),
                                    type: 'switch',
                                    tooltip: this.tr('Mass invoicing tooltip'),
                                    isHidden: () => !(project.company_mass_invoicing == 1) || VersionContentManager.isFeatureHidden(this.namespace, 'massInvoicing'),
                                },
                                {
                                    key: 'e_invoice_target',
                                    title: this.tr('E-invoice Target'),
                                    tooltip: this.tr('E-invoice target tooltip'),
                                    disabled: true,
                                },
                                {
                                    key: 'customer_reference',
                                    title: this.tr('Your reference'),
                                    forceVisible: true,
                                    type: 'limited_text',
                                    additionalProps: {
                                        limit: this.state.fieldLimits.customerreference,
                                    },
                                },
                                {
                                    key: 'invoice_our_reference',
                                    title: this.tr('Our reference'),
                                    forceVisible: true,
                                    type: 'limited_text',
                                    additionalProps: {
                                        limit: this.state.fieldLimits.reference,
                                    },
                                },
                                {
                                    key: 'invoice_note',
                                    title: this.tr('Note to invoice'),
                                    forceVisible: true,
                                    type: 'limited_text',
                                    isHidden: () => !this.state.showInvoiceNotes,
                                    additionalProps: {
                                        limit: this.state.fieldLimits.notes,
                                    },
                                },
                                ...(!hiddenFields?.find(h => h == 'agreement_identifier') 
                                ? [{
                                        key: 'agreement_identifier',
                                        title: this.tr('Agreement identifier'),
                                        isHidden: () => !this.state.show_agreement_order_identifier,
                                        forceVisible: true,
                                    }] 
                                : []),
                                ...(!hiddenFields?.find(h => h == 'order_identifier') 
                                ? [{
                                    key: 'order_identifier',
                                    title: this.tr('Order identifier'),
                                    isHidden: () => !this.state.show_agreement_order_identifier,
                                    forceVisible: true,
                                }] 
                            : []),
                            ]}
                        />
                    </div>
                );
            }
            case 'hourly_rates': {
                const { id, employees, worktypes } = this.props;
                const { defaultPrices } = this.state;
                return (
                    <div>
                        <PriceList
                            needsUpgrade={VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'hourlyRates')}
                            ref={this.refPriceList}
                            editable={checkPrivilege('projects', 'project_pricelist_write', project.companies_id)}
                            entity="project"
                            baseUrl={`projects/${id}/pricelist`}
                            employees={employees}
                            worktypes={worktypes}
                            protitles={_.values(defaultPrices.protitles)}
                            defaultPrices={defaultPrices}
                            currency={companyCurrency}
                            onlySelectedWorktasks={project.only_selected_worktasks == '1'}
                            onOnlySelectedWorktasksChanged={(checked) => this.onChange({ target: { name: 'only_selected_worktasks', value: checked, checked } })}
                            onImportPrices={this.accountPriceList}
                            importPricesLabel={this.tr('Bring account card price list')}
                        />
                    </div>
                );
            }
            case 'hour_tracking_settings': {
                const { resourcingSettings } = this.state;
                const { project } = this.props;
                return (
                    <div>
                        <SliderFieldGroup
                            items={[project]}
                            editButtonLocation="top"
                            onItemSaved={this.onProjectDetailsEdited}
                            sliderEditTitle={this.tr('Edit hour tracking settings')}
                            editingDisabled={!editable}
                            needsUpgrade={VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'hourTrackingSettings')}
                            fields={[
                                {
                                    key: 'hours_multiplier',
                                    title: this.tr('Hour multiplier'),
                                    tooltip: this.tr('Hour multiplier tooltip'),
                                    validation: 'numeric',
                                    formatValue: (value) => formatInputNumber(value),
                                    forceVisible: true,
                                },
                                {
                                    key: 'team_hour_rights',
                                    title: this.tr('Only team members'),
                                    tooltip: this.tr('Hour tracking only for team members'),
                                    type: 'switch',
                                    disabled: !editable,
                                },
                                {
                                    key: 'allow_tracking_all_tasks',
                                    title: this.tr('Other users tasks'),
                                    tooltip: this.tr('Allow tracking hours to other users tasks'),
                                    type: 'switch',
                                    disabled: !editable,
                                },
                            ]}
                        />
                    </div>
                );
            }
            default:
                return '';
        }
    };

    openInvoiceMultipleAccountsSlider = () => {
        this.setState({ invoiceMultipleAccountsSliderVisible: true });
    }

    closeInvoiceMultipleAccountsSlider = () => {
        this.setState({ invoiceMultipleAccountsSliderVisible: false });
    }

    openProjectTreeSlider = () => {
        this.setState({ projectTreeSliderVisible: true, loadingTreeStructure: true }, async () => {
            const url = {
                url: 'projects/list_new?\\',
                showTreeStructures: true,
                wholeTrees: true,
                matchesAndChildren: false,
                markMatches: true,
                mode: 'advanced',
                company: this.props.project.companies_id,
                perpage: 30,
                page: 1,
                view: 'list',
                status: 0,
                expectedClosingDate: 0,
                salesAgent: 0,
                projectStatus: -1,
                projects_pipelines_id: this.props.project.projects_pipelines_id,
            };
            const params = {
                advanced_search_criteria: JSON.stringify({
                    filters: {
                        name: [
                            {
                                operator: 'eq',
                                type: 'entity',
                                value: this.props.project.id,
                            },
                        ],
                    },
                }),
            };
            const response = await DataHandler.post(url, params);
            const treeStructureProjects = response.projects_new || [];
            this.setState({ loadingTreeStructure: false, treeStructureProjects });
        });
    };

    closeProjectTreeSlider = () => this.setState({ projectTreeSliderVisible: false });

    getStatusTagData = () => {
        const { project } = this.props;
        let color = colors.greenish_cyan;
        let text = this.tr('Active');
        if ((project.locked == '1' || project.locked == '3') && (project.status == 1 || project.status == 5)){
            color = colors.faded_red;
            text = this.tr('Archived');
        }else if (project.locked == 1 || project.locked == 3){
            color = colors.faded_red;
            text = this.tr('Lost');
        } else if (project.locked == 2) {
            color = colors.orangey_yellow;
            text = this.tr('On hold');
        }


        return { color, text };
    };

    getInvoiceabilityData = () => {
        const { project } = this.props;
        let color;
        let text;
        switch (Number(project.type)) {
            case 1:
                color = colors.bluey_purple;
                text = this.tr('Invoiceable');
                break;
            case 2:
                color = colors.carnation_pink;
                text = this.tr('Not invoiceable');
                break;
            case 3:
                color = colors.deep_sky_blue;
                text = this.tr('Vacation');
                break;
            default:
                color = colors.light_sky_blue;
                text = '-';
        }
        return { color, text };
    };

    renderDetailsTopSection = () => {
        const { project, salesStates, valueChanged, editable, sales_users, pipelines, checkPrivilege, employees } = this.props;
        const { userObject, functions: { isProjectReadCompanyUsingQuoteCurrencies } } = this.context;

        const salesStateOptions = salesStates.map((ss) => ({ ...ss, label: ss.name, value: ss.id }));
        const currentState = salesStates.find((ss) => ss.id == project.projects_sales_states_id);

        const pipelineOptions = [
            ...pipelines.map((p) => ({ ...p, label: p.name, value: p.id })),
            { id: '-1', value: '-1', label: this.tr('Won Deal') },
            { id: '-5', value: '-5', label: this.tr('Internal Project') },
        ];
        const pipelineId =
            project.status > 0 ? (this.props.pipeline_id ? this.props.pipeline_id : project.status * -1) : this.props.pipeline_id ? this.props.pipeline_id : project.projects_pipelines_id;
        const pipelineValue = pipelineOptions.find((p) => p.id == pipelineId);

        let statusClass;
        if (project.status === '0') statusClass = styles.done;
        if (project.status === '1') statusClass = styles.won;
        if (project.status === '5') statusClass = styles.internal;
        if (project.locked === '1' || project.locked === '3') statusClass = styles.lost;
        if (project.locked === '2') statusClass = styles.hold;

        const statusTagData = this.getStatusTagData();
        const invoiceabilityData = this.getInvoiceabilityData();
        const hasAnAccount = Number(project.account?.id || 0) > 0;

        return (
            <div className={cardStyles.detailsTopSection}>
                {!!this.props.id && (
                    <div className={cardStyles.topRow}>
                        <div className={cardStyles.tags}>
                            <StatusTag text={statusTagData.text} color={statusTagData.color} />
                            <StatusTag text={invoiceabilityData.text} color={invoiceabilityData.color} />
                        </div>
                        <div className={cardStyles.row}>
                            {Number(project.parentid || 0) != 0 && (
                                <div className={cardStyles.buttons}>
                                    <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr('This project is part of a project tree. Click to view tree structure.')}>
                                        <button onClick={this.openProjectTreeSlider}>
                                            <SubprojectIcon />
                                            {this.tr('Subproject')}
                                        </button>
                                    </Tooltip>
                                </div>
                            )}
                            {project.copied_from && project.copied_from.id > 0 && project.copied_from.companies_id != project.companies_id && (
                                <Tooltip
                                    classes={{ tooltip: 'darkblue-tooltip' }}
                                    placement="right"
                                    arrow
                                    title={
                                        <div>
                                            <div class="text">{this.tr('Copied from')}:</div>
                                            <div
                                                className={cn('link', !project.copied_from.has_right && 'disabled')}
                                                onMouseUp={
                                                    project.copied_from.has_right &&
                                                    ((evt) => {
                                                        this.context.functions.updateView({ module: 'projects', action: 'view', id: project.copied_from.id }, evt.button === 1);
                                                    })
                                                }
                                            >
                                                {project.copied_from.customer_name} - {project.copied_from.name}
                                            </div>
                                        </div>
                                    }
                                >
                                    <div className={styles.copied}>
                                        <CopyAll />
                                        <p>{this.tr('Copied project')}</p>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )}
                <div className={`${cardStyles.titles} ${!this.props.id ? cardStyles.noTopMargin : ''}`}>
                    <h1>{!this.props.id ? this.tr('New project') : project.name ? `${project.name} (${project.project_id})` : <Skeleton />}</h1>
                    {!!this.props.id && (
                        <Link url={hasAnAccount ? { module: 'customers', action: 'view', id: project.account?.id } : undefined} noColor={!hasAnAccount}>
                            {project.account?.id == undefined ? <Skeleton /> : project.account?.name || this.tr('No account found.')}
                        </Link>
                    )}
                </div>
                <div className={cardStyles.keyInfo}>
                    {!!this.props.id && checkPrivilege('projects', 'project_cost_estimate_read') && (
                        <>
                        <div>
                            <p>{this.tr('Deal Value')}:</p>
                            <p>{this.currencyFormatter(project.costest_sum || 0)}</p>
                        </div>
                        {project.quote_currencies_count > 0 && isProjectReadCompanyUsingQuoteCurrencies(project.companies_id) && <div>
                            <p>{this.tr('Deal value in quote currency')}:</p>
                            <p>
                                <div className={cardStyles.linkText}>
                                    <QuoteValues 
                                        quotes={project?.quotes || []} 
                                        projectId={project.id} 
                                        quoteReadRight={checkPrivilege('projects', 'project_cost_estimate_read')}
						                quoteWriteRight={checkPrivilege('projects', 'project_cost_estimate_write')}
                                    />
                                </div>
                            </p>
                        </div>}
                        </>
                    )}
                    {!!this.props.id && !VersionContentManager.isFeatureHidden(this.namespace, 'pipelines') && (
                        <div>
                            <p>{this.tr('Closing date')}:</p>
                            <DatePicker
                                usePopper
                                popperBottom
                                preventDefaultOnCalendarClick
                                className="basic-info yet-another-date-design"
                                date={project.closing_date > '2000-01-01' ? project.closing_date : undefined}
                                onChange={(e) => valueChanged({ closing_date: format(e, 'YYYY-MM-DD') })}
                                dateFormat={userObject.dateFormat}
                                disabled={!editable}
                            />
                        </div>
                    )}
                    {!!this.props.id && (
                        <div>
                            <p>{this.tr('Sales Agent')}:</p>
                            <DataList
                                placeholder={this.tr('Select sales agent')}
                                variant="standard"
                                classNamePrefix="gura"
                                className="seller"
                                value={project.sales_user || ''}
                                options={sales_users}
                                onChange={(user) => valueChanged({ status_users_id: user.id, sales_user: user }, true)}
                                isDisabled={!editable}
                            />
                        </div>
                    )}
                    {!!this.props.id && (
                        <div>
                            <p>{this.tr('project manager')}:</p>
                            <DataList
                                placeholder={this.tr('Select project manager')}
                                variant="standard"
                                classNamePrefix="gura"
                                className="seller"
                                value={project.project_manager || ''}
                                options={employees}
                                onChange={(user) => valueChanged({ users_id: user.id, project_manager: user }, true)}
                                isDisabled={!editable}
                            />
                        </div>
                    )}
                    {!VersionContentManager.isFeatureHidden(this.namespace, 'pipelines') && <div>
                        <p>{this.tr('Funnel')}:</p>
                        <DataList
                            placeholder={this.tr('Select funnel')}
                            variant="standard"
                            classNamePrefix="gura"
                            className="seller"
                            menuPosition="fixed"
                            value={pipelineValue}
                            options={pipelineOptions}
                            onChange={(pipeline) => this.props.onPipelineChanged({ target: { value: Number(pipeline.id) } })}
                            isDisabled={!editable}
                        />
                    </div>}
                    {!VersionContentManager.isFeatureHidden(this.namespace, 'pipelines') && <div>
                        <p>{this.tr('Stage')}:</p>
                        <DataList
                            placeholder={this.tr('Select stage')}
                            variant="standard"
                            classNamePrefix="gura"
                            className="seller"
                            menuPosition="fixed"
                            value={salesStateOptions.find((ss) => ss.id == project.projects_sales_states_id)}
                            options={salesStateOptions}
                            onChange={(ss) => valueChanged({ projects_sales_states_id: ss.id }, true)}
                            isDisabled={!editable}
                        />
                    </div>}
                    {!VersionContentManager.isFeatureHidden(this.namespace, 'pipelines') && <div className={styles.stageIndicators}>
                        {salesStates.length == 0 ? (
                            <Skeleton height={'100%'} width={'100%'} />
                        ) : (
                            salesStates
                                .sort((a, b) => Number(a.stateorder) - Number(b.stateorder))
                                .map((ss) => {
                                    const stateLog = project.statelog || [];
                                    let daysInState = 0;
                                    if (stateLog.length == 0 && currentState?.stateorder == ss.stateorder) {
                                        daysInState = moment().diff(moment(project.state_changed, 'YYYY-MM-DD'), 'day');
                                    } else {
                                        daysInState = stateLog.find((s) => s.id == ss.id)?.days;
                                    }
                                    return (
                                        <Tooltip
                                            key={ss.id}
                                            title={
                                                Number(currentState?.stateorder) >= Number(ss.stateorder)
                                                    ? this.tr('In stage ${stage} for ${days} days', { stage: ss.name, days: daysInState || 0 })
                                                    : ss.name
                                            }
                                        >
                                            <div
                                                key={ss.id}
                                                className={`${styles.stageIndicator} ${Number(currentState?.stateorder) >= Number(ss.stateorder) && statusClass}`}
                                                onClick={() => this.onChange({ target: { name: 'projects_sales_states_id', value: ss.id } })}
                                            >
                                                <p>{Number(currentState?.stateorder) >= Number(ss.stateorder) ? `${daysInState || 0} d` : ''}</p>
                                            </div>
                                        </Tooltip>
                                    );
                                })
                        )}
                    </div>}
                    <div className={cardStyles.peoplePreview}>
                        <div className={cardStyles.contactPreview}>
                            <p>{this.tr('Team members')}:</p>
                            <UserAvatarRow
                                useAvatars
                                tooltip={(items) => {
                                    const showItems = items.slice(0, 10);
                                    const rest = items.length - showItems.length;
                                    return (
                                        <div className={cardStyles.contactPreviewLabels}>
                                            {showItems.length == 0 ? <p>{this.tr('Click to add team members')}</p> : showItems.map((c) => <p>{`${c.label}${c.role ? `, ${c.role}` : ''}`}</p>)}
                                            {rest > 0 && <p>+{this.tr('${amount} others', { amount: rest })}</p>}
                                            <button onClick={this.showTeamMemberSlider}>{showItems.length == 0 ? this.tr('Add team members') : this.tr('View team members')}</button>
                                        </div>
                                    );
                                }}
                                onClick={this.showTeamMemberSlider}
                                items={this.getAddedTeamMembers()}
                            />
                        </div>
                        <div className={cardStyles.contactPreview}>
                            <p>{this.tr('Contacts')}:</p>
                            <UserAvatarRow
                                tooltip={(items) => {
                                    const showItems = items.slice(0, 10);
                                    const rest = items.length - showItems.length;
                                    return (
                                        <div className={cardStyles.contactPreviewLabels}>
                                            {showItems.length == 0 ? <p>{this.tr('Click to add contacts')}</p> : showItems.map((c) => <p>{`${c.label}${c.title ? `, ${c.title}` : ''}`}</p>)}
                                            {rest > 0 && <p>+{this.tr('${amount} others', { amount: rest })}</p>}
                                            <button onClick={this.showContactSlider}>{showItems.length == 0 ? this.tr('Add contacts') : this.tr('View contacts')}</button>
                                        </div>
                                    );
                                }}
                                onClick={this.showContactSlider}
                                items={uniqBy([...(project?.contacts || []), ...(project?.partners || [])], 'id')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    closeTeamMemberSlider = () => this.setState({ teamMemberSliderVisible: false });
    showTeamMemberSlider = () => this.setState({ teamMemberSliderVisible: true });

    closeContactSlider = () => this.setState({ contactSliderVisible: false });
    showContactSlider = () => this.setState({ contactSliderVisible: true });

    onDetailsScroll = (e) => {
        if (!this.props.id) return;
        if (e.currentTarget.scrollTop >= 100 && !this.showProjectNameInHeader) {
            this.showProjectNameInHeader = true;
            this.props.setShowProjectNameInHeader(true);
        } else if (e.currentTarget.scrollTop < 100 && this.showProjectNameInHeader) {
            this.showProjectNameInHeader = false;
            this.props.setShowProjectNameInHeader(false);
        }
    };

    render() {
        const { loadingTreeStructure, treeStructureProjects, invoiceMultipleAccountsSliderVisible } = this.state;
        return (
            <div id="projects-basic">
                {this.props.showHeader && this.props.header && <PageTopSection header={this.props.header} subheaders={this.props.subheaders} />}
                <div className="projects-basic-container">
                    <div className={`${cardStyles.detailsBox} ${cardStyles.withDoubleTabs} ${styles.sidebar_sticky}`} onScroll={this.onDetailsScroll} style={{borderTopColor: this.props.project.color.length === 6 ? `${this.props.project.color}0` : this.props.project.color}}>
                        {this.renderDetailsTopSection()}
                        <div className={styles.detailsSections}>
                            <ToggleableContainerList sections={this.detailsSections} renderContentForSection={this.renderContentForDetailsSection} />
                        </div>
                    </div>
                    {this.props.children}
                </div>
                <Slider title={this.tr('Contacts associated with project')} open={this.state.contactSliderVisible} onClose={this.closeContactSlider}>
                    <div className={cardStyles.contactSliderContainer}>
                        {
                            <ToggleableContainerList
                                sections={[
                                    {
                                        key: 'contacts',
                                        title: this.tr('Contacts'),
                                        initiallyOpen: true,
                                        onlyContent: true,
                                        tabs: [
                                            {
                                                id: 'project_contacts',
                                                label: this.tr('Project contacts'),
                                            },
                                            {
                                                id: 'partners',
                                                label: this.tr('Partners'),
                                            },
                                        ],
                                    },
                                ]}
                                renderContentForSection={(section) => this.renderContentForDetailsSection(section, true)}
                            />
                        }
                    </div>
                </Slider>
                <Slider title={this.tr('Project team members')} open={this.state.teamMemberSliderVisible} onClose={this.closeTeamMemberSlider}>
                    <div className={cardStyles.contactSliderContainer}>
                        {
                            <ToggleableContainerList
                                sections={[
                                    {
                                        key: 'team',
                                        title: this.tr('Team'),
                                        initiallyOpen: true,
                                        onlyContent: true,
                                        tabs: [
                                            {
                                                id: 'project_team',
                                                label: this.tr('Project team'),
                                            },
                                            ...(this.usesDimensions
                                                ? [
                                                      {
                                                          id: 'dimensions',
                                                          label: this.tr('Dimension team'),
                                                          hidden: () => this.state.dimensionTeamMembers.length == 0,
                                                      },
                                                  ]
                                                : []),
                                        ],
                                    },
                                ]}
                                renderContentForSection={(section) => this.renderContentForDetailsSection(section, true)}
                            />
                        }
                    </div>
                </Slider>
                <Slider title={this.tr('Project tree')} open={this.state.projectTreeSliderVisible} onClose={this.closeProjectTreeSlider}>
                    <div className={styles.treeStructure}>
                        {loadingTreeStructure ? (
                            <Loading  />
                        ) : (
                            <TaimerList
                                data={treeStructureProjects}
                                columns={this.treeStructureListFields}
                                height="fitRemaining"
                                fluid
                                sharedData={{
                                    bobOptions: [],
                                    project_tags: [],
                                    custom_fields: [],
                                    customers: [],
                                    sales_states: [],
                                    dynamic_right_employees: [],
                                    privileged_employees: [],
                                    customer_types: [],
                                    customerType_map: {},
                                    type_map: {},
                                    statuses: [],
                                }}
                                rowProps={{ addons: this.context.addons, projectWonReasonIsInUse: () => false, projectLostReasonIsInUse: () => false }}
                                parentKey="parentId"
                                listRowType={ProjectListRow}
                                treeData={true}
                                parentsExpandedOnInit={true}
                                noStateData={true}
                            />
                        )}
                    </div>
                </Slider>
                {invoiceMultipleAccountsSliderVisible && <InvoiceMultipleAccountsSlider
                    addresses={this.state.invoicing_addresses?.length > 0 
                        ? this.state.invoicing_addresses
                        : [{
                            id: 1,
                            address_id: this.props.project.invoicing_address?.id,
                            customers_id: this.state.billingAccountId,
                            percentage: 100
                        }]} 
                    projectsId={this.props.project.id}
                    accountOptions={this.state.accounts} 
                    /* 
                        Concat billing_addresses options with allBillingAddresses 
                        so updated addresses from billing_addresses are shown correctly in slider 
                        and don't need to update allBillingAddresses everytime.
                    */
                    addressOptions={this.state.billing_addresses 
                        .map(b => { b.customers_id =  this.state.billingAccountId; return b })
                        .concat(this.state.allBillingAddresses
                        .filter(item2 => !this.state.billing_addresses.some(item1 => item1.id === item2.id)
                    ))}
                    onClose={() => this.closeInvoiceMultipleAccountsSlider()}
                    onSave={(addresses) => {
                        this.closeInvoiceMultipleAccountsSlider();
                        this.onInvoicingAddressesSave(addresses);
                    }}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                    fieldLimits={this.state.fieldLimits}
                />}
            </div>
        );
    }
}

TabBasic.propTypes = {
    id: PropTypes.string,
    project: PropTypes.object.isRequired,
    salesStates: PropTypes.array.isRequired,
    employees: PropTypes.array.isRequired,
};

export default TabBasic;
