import React from "react";
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

import List from "../../list/List";
import Button from "@mui/material/Button";
import ListCell from "../../list/ListCell";
import ProjectTreeDropdown from "../../projects/ProjectTreeDropdown";
import TreeStructureIndicatorCell from "../../list/cells/TreeStructureIndicatorCell";
import TextInputCell from "../../list/cells/TextInputCell";
import SettingRow from "../../list/rows/SettingRow";
import TreeListRow from "../../list/TreeListRow";
import DataHandler from "../../general/DataHandler";
import CheckboxCell from "../../list/cells/CheckboxCell";
import ContextMenu from '../../general/ContextMenu';
import CustomFields from "./CustomFields";
import SettingsGrid from "./SettingsGrid";
import TrashCanIcon from "@mui/icons-material/Delete";
import TaimerComponent from "../../TaimerComponent";
import { withSnackbar } from "notistack";
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import { SettingsContext } from "../../SettingsContext";
import Radio from "@mui/material/Radio";
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { MenuItem, Switch } from '@mui/material';
import clone from "lodash/clone";
import RadioButtonCell from "../../list/cells/RadioButtonCell";
import DropdownTabs from '../../navigation/DropdownTabs';

import "./Project.css";
import PropsOnlyListRow from "../../list/PropsOnlyListRow";

import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import { ReactComponent as ActivateIcon } from '../../general/icons/Activate.svg';
import { ReactComponent as ArchiveIcon } from '../../general/icons/Archive.svg';
import { ReactComponent as AddSubCategoryIcon } from '../../general/icons/AddSubCategory.svg';
import DateCell from "../../list/cells/DateCell";
import LinkListCell from "../../list/LinkListCell";
import { cloneDeep } from "lodash";
import ProjectStatusReasonDialog from '../../dialogs/ProjectStatusReasonDialog';
import VersionContentManager from "../../general/VersionContentManager";

import ProposalEditor from "../../projects/proposal/ProposalEditor";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
const NoProposalTemplatesOverlay = props =>  {
    const { header, subtitle } = props;
    return (
        <div className="list-overlay">
            <Loading  />
            <h1 className="header">{header}</h1>
            <p className="subtitle">{subtitle}</p>
        </div>
    );
  }

class ProposalTemplateRow extends SettingRow {
    render() {
        const cells = {
            delete: (
                <ListCell className="noBg pointer" permanentEditMode={true} width={this.props.columnWidthMap["delete"]}>
                    <TrashCanIcon onClick={() => this.delete(this.state.data)} />
                </ListCell>
            ),
            title: (
                <LinkListCell
                    handleClick={() => this.props.rowProps.onOpenTemplate(this.state.data)}
                    editable={false}
                    width={this.props.columnWidthMap["title"]}
                    value={this.state.data.title} />
            ),
            created_at: (
                <DateCell
                    name="created_at"
                    onlyDisplay
                    editable={false}
                    width={this.props.columnWidthMap["created_at"]}
                    value={this.state.data["created_at"]} />
            ),
            created_by: (
                <TextInputCell
                    editable={false}
                    name="created_by"
                    width={this.props.columnWidthMap["created_by"]}
                    value={this.state.data["created_by"]}
                />
            ),
        };

        return (
            <div
                className="listElement row flex"
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
            >
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}

class ProjectStatusReasonRow extends SettingRow {
    render() {
        const cells = {
            delete: (
                <ListCell className="noBg pointer" permanentEditMode={true} width={this.props.columnWidthMap["delete"]}>
                    <TrashCanIcon onClick={() => this.delete(this.state.data)} />
                </ListCell>
            ),
            name: (
                <TextInputCell
                    onEdited={this.editAndSave}
                    name="name"
                    editable={!this.props.data.saving && !this.props.rowProps.disabled}
                    validation={['empty']}
                    width={this.props.columnWidthMap["name"]}
                    value={this.state.data["name"]}
                    listCellProps={{ inEditMode: this.state.data.id < 0 }} 
                    focusOnMount={true}
                />
            )
        };

        return (
            <div
                className={`listElement row flex ${this.props.data.saving ? 'disabled-settings-row' : ''}`}
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
            >
                {this.props.columnOrder.map(columnName => cells[columnName])}
                {this.props.data.saving && <Loading className={"settings-row-loading-indicator"} />}
            </div>
        );
    }
}

class ProjectTypeSettingsRow extends SettingRow {
    render() {
        const cells = {
            delete: (
                <ListCell className="noBg pointer" permanentEditMode={true} width={this.props.columnWidthMap["delete"]}>
                    <TrashCanIcon onClick={() => this.delete(this.state.data)} />
                </ListCell>
            ),
            name: (
                <TextInputCell
                    onEdited={this.editAndSave}
                    name="name"
                    validation={['empty']}
                    width={this.props.columnWidthMap["name"]}
                    value={this.state.data["name"]}
                    listCellProps={{ inEditMode: this.state.data.id < 0 }} 
                    focusOnMount={true}
                />
            ),
            is_default: this.props.rowProps.oneprojecttype ? (
                <Radio
                    checked={this.props.data["is_default"] == 1}
                    /* onChange={event => {

                        this.editAndSave("is_default", event.target.value === "1");
                    }} */
                    onClick={event => {
                        if(event.target.value === "1" && this.props.data["is_default"] == 1) {
                            this.editAndSave("is_default", false);
                        } else {
                            this.editAndSave("is_default", true);
                        }
                    }}
                    value={1}
                    name="is_default"
                    style={{
                        width: this.props.columnWidthMap["is_default"] + "px",
                        flex: this.props.columnWidthMap["is_default"] + " 1 0px",
                        padding: "0px"
                    }}
                />
            ) : (
                <CheckboxCell
                    onEdited={checked => {
                        this.editAndSave("is_default", checked);
                    }}
                    color="primary"
                    width={this.props.columnWidthMap["is_default"]}
                    name="is_default"
                    checked={this.props.data["is_default"] == 1}
                />
            ),
        };

        return (
            <div
                className="listElement row flex"
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
            >
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}

class ProjectCategoryRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, { childRowType: ProjectCategoryRow }, "list/rows/ProjectCategoryRow");
    }

    onEnter = (event) => {
        if(event.keyCode === 13 && this.props.data.id > 0) {
            let data = clone(this.props.data);
            data.name = event.target.value;
            this.setDataAndUpdate(data);
        }
    }

    setArchiveStatus = async () => {
        if(this.props.data.id > 0) {
            await this.props.rowProps.changeLocked();
            this.setDataAndUpdate({ locked: !Boolean(this.props.data.locked) }); 
        }
    }

    setDefault = async () => {
        if(typeof this.props.data.is_default !== "boolean")
            Boolean(this.props.data.is_default);
        if (this.props.data.is_default)
            return;
        
        if(this.props.data.id > 0) {
            await this.props.rowProps.changeDefault();
            this.setDataAndUpdate({ is_default: !Boolean(this.props.data.is_default) });
        } else {
            this.projectBobList.current.addNewRow({ parent_id: this.props.data.id });
        }
    }

    onChangeHubspotId = (value) => {
        let data = clone(this.props.data);
        data.hubspot_id = value;
        this.setDataAndUpdate(data);
    }

    defineClassName() {
        if(this.props.data.locked)
            return "lockedCategoryRow"
    }

    defineCells() {
        const cells = {
            contextMenu: 
                <ContextMenu label={<MoreHoriz />} buttonProps={{className: 'action-menu'}} className="cell row-menu" width={this.props.columnWidthMap['contextMenu']} style={{width: this.props.columnWidthMap['contextMenu'] + 'px', flex: this.props.columnWidthMap['contextMenu'] + " 1 0px" }} noExpandIcon>
                    {this.props.data.locked == 0 && <MenuItem 
                        onClick={() => {
                            this.props.rowProps.addChild(this.props.data)
                        }}><AddSubCategoryIcon />{this.props.rowProps.addChildLabel ? this.props.rowProps.addChildLabel : "Add child"}</MenuItem>
                    }
                    {!this.props.data.is_default && (this.props.data.locked == 0 ?   
                        <MenuItem 
                            onClick={() => {
                                this.setArchiveStatus();
                            }}><ArchiveIcon />{this.props.rowProps.archiveLabel ? this.props.rowProps.archiveLabel : "Archive"}
                        </MenuItem>
                        :
                        <MenuItem 
                            onClick={() => {
                                this.setArchiveStatus();
                            }}><ActivateIcon />{this.props.rowProps.activateLabel ? this.props.rowProps.activateLabel : "Activate"}
                        </MenuItem>)
                    }
          
                    <MenuItem className="delete" onClick={this.delete}><RemoveIcon className="Delete" />{this.props.rowProps.deleteLabel ? this.props.rowProps.deleteLabel : "Delete"}</MenuItem>
                </ContextMenu>,
            name: 
                <TreeStructureIndicatorCell
                childrenVisible={this.props.childrenVisible}
                listCellProps={{ alignCell: true, textAlign: "left" }}
                onExpanderClick={e => this.props.listRef.toggleShowChildren(this.props.data.id)}
                rowData={this.props}
                value={this.props.data.name}>
                    <TextInputCell 
                        onEdited={(name, value) => {
                            let data   = clone(this.props.data);
                            data[name] = value;
                            this.setDataAndUpdate(data);
                        }}
                        width={this.props.columnWidthMap.name}
                        name="name"
                        validation={['empty']}
                        value={this.props.data['name']} 
                        editable={!this.props.data.locked}
                        onEnter={this.onEnter}
                        listCellProps={{ 
                            inEditMode: this.props.data.id < 0, 
                            style: { height: "44px", width: "100%" },
                            className: "categoryNameCell" 
                        }} />
                </TreeStructureIndicatorCell>,
            is_default:
                <ListCell className="categoryDefaultCell" width={this.props.columnWidthMap['is_default']} onlyDisplay>
                   {/*  <Switch disabled={this.props.data.locked} checked={this.props.data.is_default} onChange={() => {this.setDefault()}} color="primary" /> */}
                    <RadioButtonCell 
                        onEdited={() => {this.setDefault(); }}
                        name="is_default" 
                        disabled={this.props.data.locked}
                        width={this.props.columnWidthMap['is_default']}
                        checked={this.props.data.is_default}
                    />
                </ListCell>,
            hubspot_id:
                this.context.addons.hubspot && <TextInputCell
                    //listCellProps={{ permanentEditMode: value.id < 0 }}
                    //ref={this.refName}
                    width="100%"
                    //placeholder={this.tr("Enter Name")}
                    //placeholderOnEmpty
                    name="hubspot_id"
                    editable
                    value={this.props.data['hubspot_id']}
                    onEdited={this.onChangeHubspotId}
                />,
            accounting_integration_id:
                this.context.addons.procountor && <TextInputCell
                    //listCellProps={{ permanentEditMode: value.id < 0 }}
                    //ref={this.refName}
                    width="100%"
                    //placeholder={this.tr("Enter Name")}
                    //placeholderOnEmpty
                    name="accounting_integration_id"
                    editable
                    value={this.props.data['accounting_integration_id']}
                    onEdited={(value) => {
                        let data = clone(this.props.data);
                        data.accounting_integration_id = value;
                        this.setDataAndUpdate(data);
                    }}
                />,
            dimension_id:
                this.context.addons.talenom && <AutoCompleteCell
                    autoCompleteData={this.props.rowProps.dimensions}
                    value={this.props.data['dimension_id']}
                    onEdited={(value) => {
                        let data = clone(this.props.data);
                        data.dimension_id = value.id;
                        this.setDataAndUpdate(data);
                    }}
                />
        }
        
        /*return (
            <div
                className="listElement row flex"
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
            >
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );*/
        return cells;
    }
}

class HRProjectRow extends SettingRow{
    render() {
        const cells = {
            delete: <ListCell className="noBg pointer" permanentEditMode={true} width={this.props.columnWidthMap['delete']}><TrashCanIcon onClick={() => this.delete(this.state.data)} /></ListCell>,
            project: <ListCell permanentEditMode={true} className="treedropdown-cell">
                <ProjectTreeDropdown
                    name="project" // TODO: Onko tarpeellinen?
                    label={this.tr("Project")}
                    value={this.state.data.projects_id > 0 ? this.state.data.projects_id  : false}
                    disabled={false}
                    openOnMount={this.state.data.id < 0}
                    queryParameters={{right: 'admin', company: this.props.rowProps.company}}
                    disableAutoSelect
                   /*  error={this.state.data.projects_id > 0 ? false  : true} */
                    treeDropdownProps={{
                        activateBestMatch: true,
                        highlightMatches: true
                    }}
                    onSelect={e => {
                        const { data } = e;
                        this.editAndSave('project', data);
                    }}
                    />
                </ListCell>,
        };
       return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }

}

class Project extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/Project");

        this.projectTagList = React.createRef();
        this.projectBobList = React.createRef();
        this.projectTypeList = React.createRef();
        this.projectHRList = React.createRef();
        this.proposalTemplateList = React.createRef();

        this.projectStatusReasonItems = [
            {
                title: this.tr("Won Reasons"),
                buttonTitle: this.tr("Add Won Reason"),
                newRow: { id: -1, name: '', status: '1' },
                dataKey: 'won',
            },
            {
                title: this.tr("Lost Reasons"),
                buttonTitle: this.tr("Add Lost Reason"),
                newRow: { id: -1, name: '', locked: '1' },
                dataKey: 'lost',
            },
        ]

        this.seqId = 0;

        this.state = {
            initialized: false,
            selectedTab: 'projectSettings',
            selectedProposalMode: undefined,
            proposalTemplates: [],
            settings: undefined,
            targetingSettings: {extended_cost_targeting: 0},
            quoteCurrencySettings: {use_quote_currencies: 0},
            eventDateSettings: {use_events: 0},
            projectCategorySettings:{project_category_settings:0},
            revenueRecognitionSettings: { 
                revenue_recognition_sync_scheduled_invoices: 0, 
                revenue_recognition_equal_project_value: 0,
                revenue_recognition_month_lock_day: 0,
                revenue_recognition_month_lock_user_splits: 0,
                revenue_recognition_bills_by_date: 1,
                revenue_recognition_expenses_by_date: 1,
                revenue_recognition_purchase_orders_by_date: 1,
            },
            projectTeamSettings: {project_team_option: 0},
            data: {
                projectTags: [],
            },
            pipelines: [],
            stages: [],
            customFields: [],
            projectForms: [],
            statusReasons: {},
            hrProjects: [],
            selectedForm: 0,
            dialogData: undefined,
            typesettings: { oneprojecttype: "1" },
            statusReasonSettings: { use: {
                    won: '0',
                    lost: '0'
                }
            },
            defaultCategoryChanged: false,
            default_quote_vat:{default_quote_vat:""},
            categoryLockedChanged: false,
            pCHeaderVisible:false,
            pTHeaderVisible:false,
            eFHeaderVisible:false,
            hRHeaderVisible:false,
            proposalTemplatesLoaded: false,
            dimensions: []
        };

        this.dialogs = {
            confirmation: ConfirmationDialog,
        };

        this.proposalModes = [
            {
                id: 'covers',
                name: this.tr("Cover Templates"),
                action: (tabId) =>  this.setSelectedProposalMode(tabId, 'covers')
            },
            {
                id: 'contentTemplates',
                name: this.tr("Content Templates"),
                action: (tabId) =>  this.setSelectedProposalMode(tabId, 'contentTemplates')
            }
        ];

        this.tabs = [
            {
                id: 'projectSettings',
                label: this.tr("Project Settings"),
            },
            {
                id: 'proposalTemplates',
                label: this.tr("Proposal Templates"),
                items: this.proposalModes
            },
        ]

        this.fetchProjectTypes = this.fetchProjectTypes.bind(this);
        this.fetchProjectCategories = this.fetchProjectCategories.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCustomFields = this.onChangeCustomFields.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        const { userObject } = this.context;
        this.updateComponentData();
    }

    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    setSelectedTab = (selectedTab) => {
        let selectedProposalMode = undefined;
        if (selectedTab.id == 'proposalTemplates') {
            selectedProposalMode = this.proposalModes.id;
            this.setState({ selectedTab: selectedTab, selectedProposalMode });
        }
        else
            this.setState({ selectedTab: selectedTab.id, selectedProposalMode });
    }

    setSelectedProposalMode = (selectedTab, selectedProposalMode) => {
        this.setState({ selectedTab, selectedProposalMode });
    }

    getProposalTemplates = () => {
        DataHandler.get({
            url: `companies/${this.props.company}/proposal_templates/`,
        })
        .done((proposalTemplates) => {
            this.setState({ proposalTemplates, proposalTemplatesLoaded: true });
        })
        .fail((err) => {
            this.setState({ proposalTemplatesLoaded: true });
            console.log(err);
        });
    }
    
    getRevenueRecognitionSettings = () => {
        const { company } = this.props;
        DataHandler.get({ url: `settings/company/${company}/revenue_recognition` }).done(revenueRecognitionSettings => {
            this.setState({ revenueRecognitionSettings });
        });
    }

    getStatusReasons = () => {
        const { company } = this.props;
        this.setState({ loadingStatusReasons: true }, async () => {
            const data = await DataHandler.get({ url: `settings/company/${company}/project/status_reasons` });
            this.setState({ statusReasons: data.statusReasons, statusReasonSettings: { use: { won: data.use_won, lost: data.use_lost, } }, loadingStatusReasons: false });
        });
    }

    updateComponentData = () => {
        const { company } = this.props;

        let stateSettings = {
            charge_hours: undefined,
            charge_others: undefined,
            charge_traveling: undefined,
            charge_costest: undefined,
            charge_received_invoices: undefined,
            default_project_status: undefined,
            project_mass_invoicing: undefined
        };

        let tags = [];

        this.getProposalTemplates();
        this.getRevenueRecognitionSettings();
        this.getStatusReasons();

        this.fetchDimensions();

        DataHandler.get({ url: `settings/company/${company}/project/statuses` })//tämä haku kai deprecated, obsolete joka tapausekssa??
            .then(data => {
                /* settings.find(s => s.name === "default_project_status").options = data.map(type => {
                type.value = type.id;
                type.label = type.name;

                return type;
            }); */

                return DataHandler.get({ url: `settings/company/${company}/project/invoicing` });
            })
            .then(data => {
                stateSettings = data.project_settings;

                return DataHandler.get({ url: `settings/company/${company}/project/tags` });
            })
            .then(data => {
                tags = data.tags;

                return DataHandler.get({ url: `settings/company/${company}/project/branchofbusiness`});
            })
            .then(data => {
                this.setState({
                    initialized: true,
                    settings: stateSettings,
                    data: {
                        projectTags: tags.sort((a, b) => b.id - a.id),
                        projectBobs: data.branchofbusiness_leveled,
                    },
                });

                DataHandler.get({ url: `settings/company/${company}/pipelines` }).done(data => {
                    this.setState(data);
                });
                DataHandler.get({ url: `settings/company/${company}/cost_targeting` }).done(data => {
                    this.setState({targetingSettings: data});
                });
                DataHandler.get({ url: `settings/company/${company}/use_quote_currencies` }).done(data => {
                    this.setState({quoteCurrencySettings: data});
                });
                DataHandler.get({ url: `settings/company/${company}/use_events` }).done(data => {
                    this.setState({eventDateSettings: data});
                });
                DataHandler.get({ url: `settings/company/${company}/default_project_category` }).done(data => {
                    this.setState({projectCategorySettings: data});
                });  
                DataHandler.get({ url: `settings/company/${company}/project_team_option` }).done(data => {
                    this.setState({projectTeamSettings: data});
                }); 
            })
            .catch(error => {
                console.log(error);
            });
        this.updateCustomFields();

        this.fetchProjectTypes();
        this.fetchHRProjects();
        this.fetchDefaultQuoteVatSettings();
    }

    openDialog = name => {
        this.setState({ currentDialog: name });
    };

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    };

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    };

    fetchProjectTypes(callback = () => {}) {
        const { company } = this.props;
        return DataHandler.get({ url: `settings/company/${company}/project/project_types` }).done(data => {
            this.setState(
                {
                    projectTypes: data["types"]
                        ? data["types"]
                        : [],
                    typesettings: data["oneprojecttype"]
                        ? { oneprojecttype: data["oneprojecttype"] }
                        : { oneprojecttype: "0" },
                },
                callback()
            );

            if(data["types"].length == 0){
                this.setState({pTHeaderVisible:false});
            }
            callback();
        });
    }

    fetchProjectCategories(callback = () => {}) {
        const { company } = this.props;
        DataHandler.get({ url: `settings/company/${company}/project/branchofbusiness`}).done(data => {
            let stateData = this.state.data;
            stateData["projectBobs"] = data.branchofbusiness_leveled;

            if(this.state.data.projectBobs.length == 0){
                this.setState({pCHeaderVisible:false});
            }

            this.setState({ data: stateData }, () => {
                callback();
            });
        });
    }

    fetchDimensions(callback = () => {}) {
        const { company } = this.props;
        DataHandler.get({url: `accounting/dimensions/${company}/talenom`}).done(response => {
            response.dimensions.push({id: 0, name: '', label: ''});
            this.setState({dimensions: response.dimensions}, () => {
                callback();
            });
        });    
    }

    onChange(settings, setting, value) {
        this.setState({ settings: settings });
        const { company } = this.props;
        const seqId = ++this.seqId;

        DataHandler.post(
            { url: `settings/company/${company}/project/invoicing` },
            { data: JSON.stringify({ [setting]: value }), seqId: seqId }
        ).done(data => {
            if (this.seqId == data.seqId && data.hasOwnProperty("project_settings"))
                this.setState({ settings: data.project_settings });
        });
    }

    onChangeDefaultQuoteVat = (defaultQuoteVat) => {
		const {company } = this.props;
		DataHandler.post({  url: `settings/company/${company}/project/defaultQuoteVat` }, { defaultQuoteVat: defaultQuoteVat })
			.done(response => {
				this.fetchDefaultQuoteVatSettings();
                this.props.enqueueSnackbar(this.tr('Default quote vat saved'), {
					variant: 'success',
					preventDublicate: true,
				});
			});
    };
    
    fetchDefaultQuoteVatSettings = () => {
		const { company } = this.props;
		DataHandler.get({ url: `settings/company/${company}/project/defaultQuoteVat` }).done(response => {
            if (response.error) return;
            
            this.setState(
                {
                        default_quote_vat: response['default_quote_vat']
                        ? { default_quote_vat: response['default_quote_vat']}
                        : { default_quote_vat: "24" },
                }
            );
		});
	};

    onChangeOneProjecType = (type, setting, value) => {
        this.setState({ typesettings: { oneprojecttype: value } });
        const { company } = this.props;

        DataHandler.post({ url: `settings/company/${company}/project/oneprojecttype` }, { oneprojecttype: value })
            .done(data => {
                if (value === 1) {
                    this.props.enqueueSnackbar(this.tr(`default project types unselected`), {
                        variant: "info",
                    });
                }
                this.fetchProjectTypes();
            })
            .fail(err => console.error(err));
    };

    onChangeUseProjectStatusReason = (value) => {
        this.setState({ statusReasonSettings: { ...this.state.statusReasonSettings, use: value }  }, () => {
            const { company } = this.props;
            DataHandler.post({ url: `settings/company/${company}/project/use_status_reason` }, value)
                .done(() => {})
                .fail(err => {
                    this.props.enqueueSnackbar(this.tr("Saving settings failed!"), {
                        variant: "error",
                    });
                    console.error(err)
                });
        });
    };

    updateCustomFields = () => {
        const { company } = this.props;

        return DataHandler.get({url: `settings/company/${company}/project/customfields`}).then(customFields => {
            this.setState({customFields});
        });
    }

    createCustomField = async field => {
        const { company } = this.props;
        const customFields = await DataHandler.post(
            { url: `settings/company/${company}/project/customfields` },
            { customFields: [field] }
        );
        if (Array.isArray(customFields)) this.setState({ customFields });
    };

    fetchHRProjects = () => {
        const { company } = this.props;
        return DataHandler.get({url: `settings/company/${company}/hr_projects`}).then(projects => {
            this.setState({hrProjects: projects});

            if(projects.length == 0){
                this.setState({hRHeaderVisible:false});
            }
        });
    }

    createHRProjects = (data) => {
        const { company } = this.props;
        const projectsId = data.project ? data.project.id : 0;
        let error = false;
        this.state.hrProjects.filter(hr => {
            if( hr.id != data.id &&  hr.projects_id == projectsId)
                error = true
        })
        if (!error) {
            DataHandler.post({url: `settings/company/${company}/hr_projects`, projects_id: projectsId}).then(projects => {
                this.projectHRList.current.removeNewRow(data.id)
                this.fetchHRProjects();
            });   
        }
        else{
            this.props.enqueueSnackbar(( data.name + this.tr(" HR Project already exists") + "."), {
                variant: "error",
            });
            return;

        }
    }

    updateHRProjects = (data) => {
        const { company } = this.props;
        DataHandler.put({url: `settings/company/${company}/hr_projects/${data.id}`,  projects_id: data.project.id}).then(projects => {
            this.fetchHRProjects();
        });
    }

    deleteHRProjects = (data) => {
        if (Number(data.id) < 0) {
            this.projectHRList.current.removeNewRow(data.id);
            if(this.state.hrProjects.length == 0){
                this.setState({hRHeaderVisible:false});
            }
            return;
        }

        const { company } = this.props;
        this.setState({
            dialogData: {
                id: data.id,
                saveFunc: (id) => DataHandler.delete({url: `settings/company/${company}/hr_projects/${data.id}`}).done(() => {
                    this.fetchHRProjects();
                }),
                text: this.tr("Do you want to remove HR-project:") + " " + (data.project ? data.project.name : '') + "?" 
            }
        });
        this.openDialog('confirmation');
    }

    createCustomField = async (field) => {
        const { company } = this.props;

        const customFields = await DataHandler.post({url: `settings/company/${company}/project/customfields`}, {customFields: [field]});

        if (Array.isArray(customFields))
            this.setState({customFields});
    }

    async onChangeCustomFields(e, change) {
        const { company } = this.props;

        const {
            target: { value },
        } = e;

        this.setState({ customFields: value });

        const customFields = await DataHandler.post(
            { url: `settings/company/${company}/project/customfields` },
            { customFields: change || value }
        );

        if (!change && Array.isArray(customFields)) this.setState({ customFields });
    }

    close() {
        this.props.onClose();
    }


    onCreateStatusReason = (data, dataKey) => {
        if (this.state.loadingStatusReasons) return;
        if (!data.name) {
            this.props.enqueueSnackbar(this.tr("Please add a name for the reason!"), {
                variant: "error",
            });
            return;
        }
        const fakeNewId = 999999;
        const statusReasons = cloneDeep(this.state.statusReasons);
        const newIndex = (statusReasons[dataKey] || []).findIndex(reason => reason.id == -1);
        if (newIndex != -1) {
            statusReasons[dataKey][newIndex] = {
                ...statusReasons[dataKey][newIndex],
                ...data,
                id: fakeNewId, // just to show that something is happening in the front end
                saving: true
            };
        }
        this.setState({ loadingStatusReasons: true, statusReasons }, async () => {
            const { company } = this.props;
            try {
                const response = await DataHandler.post({ url: `settings/company/${company}/project/status_reasons` }, { ...data });
                const statusReasons = cloneDeep(this.state.statusReasons);
                const newIndex = (statusReasons[dataKey] || []).findIndex(reason => reason.id == fakeNewId && reason.saving);
                if (newIndex != -1 && response?.id) {
                    statusReasons[dataKey][newIndex] = {
                        ...statusReasons[dataKey][newIndex],
                        ...data,
                        saving: false,
                        id: response.id
                    };
                }
                this.setState({ statusReasons, loadingStatusReasons: false });
            } catch (e) {
                console.error(e);
                this.props.enqueueSnackbar(this.tr("Creating the reason failed!"), {
                    variant: "error",
                });
                this.getStatusReasons();
            }
        })
    }

    onEditStatusReason = (data, dataKey) => {
        if (this.state.loadingStatusReasons) return;
        const statusReasons = cloneDeep(this.state.statusReasons);
        const newIndex = (statusReasons[dataKey] || []).findIndex(reason => reason.id == data.id);
        if (newIndex != -1) {
            statusReasons[dataKey][newIndex] = {
                ...statusReasons[dataKey][newIndex],
                ...data,
                saving: true
            };
        }
        this.setState({ loadingStatusReasons: true, statusReasons }, async () => {
            const { company } = this.props;
            try {
                await DataHandler.put({ url: `settings/company/${company}/project/status_reasons/${data.id}` }, { ...data });
                const statusReasons = cloneDeep(this.state.statusReasons);
                const newIndex = (statusReasons[dataKey] || []).findIndex(reason => reason.id == reason.id && reason.saving);
                if (newIndex != -1) {
                    statusReasons[dataKey][newIndex] = {
                        ...statusReasons[dataKey][newIndex],
                        saving: false
                    };
                }
                this.setState({ statusReasons, loadingStatusReasons: false })
            } catch (e) {
                console.error(e);
                this.props.enqueueSnackbar(this.tr("Saving the reason failed!"), {
                    variant: "error",
                });
                this.getStatusReasons();
            }
        })
    }

    onDeleteStatusReason = (id, dataKey) => {
        if (this.state.loadingStatusReasons) return;
        const statusReasons = cloneDeep(this.state.statusReasons);
        const index = statusReasons[dataKey].findIndex(reason => reason.id == id);
        if (index != -1) statusReasons[dataKey].splice(index, 1);
        if (id == -1) {
            this.setState({ statusReasons });
            return;
        };
        this.setState({ statusReasons, loadingStatusReasons: true }, async () => {
            const { company } = this.props;
            await DataHandler.delete({ url: `settings/company/${company}/project/status_reasons/${id}` });
            setTimeout(() => {
                this.getStatusReasons();
            }, 1000);
        })
    }

    setRevenueRecognitionSettings = data => {
        const { company } = this.props;

        if (Number(data.revenue_recognition_month_lock_day) < 0) {
            data.revenue_recognition_month_lock_day = "0"
        } else if (Number(data.revenue_recognition_month_lock_day) > 31) {
            data.revenue_recognition_month_lock_day = "31"
        }
        
        this.setState({ revenueRecognitionSettings: data }, () => {
            DataHandler.put(
                { url: `settings/company/${company}/revenue_recognition` }, data)
            .then(revenueRecognitionSettings => {
                // no need to set to state here really, just leads to jumping if editing quickly
                // setTimeout(() => {
                //     this.setState({ revenueRecognitionSettings });
                // }, 1000);
            }).catch(() => {
                this.props.enqueueSnackbar(this.tr("Saving settings failed!"), {
                    variant: "error",
                });
                this.getRevenueRecognitionSettings();
            });
        });
    }

    renderStatusReasonSection = ({title, buttonTitle, newRow, dataKey}) => {
        const { statusReasonSettings } = this.state;
        const colConfig = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        const useStatusReasons = statusReasonSettings.use[dataKey] == 1;

        const useProjectWonReason = [
            {
                type: "check",
                name: "won",
                label: this.tr("Use project won reason"),
                className: "respSetting half",
            },
        ];

        const useProjectLostReason = [
            {
                type: "check",
                name: "lost",
                label: this.tr("Use project lost reason"),
                className: "respSetting half",
            },
        ];

        return (
            <div key={dataKey} className={`project-status-reason-section`}>
                <h4>{title}</h4>
                <SettingsGrid
                    settings={dataKey == 'won' ? useProjectWonReason : useProjectLostReason}
                    item={this.state.statusReasonSettings.use}
                    onChange={this.onChangeUseProjectStatusReason}
                />
                <div className={`${!useStatusReasons ? 'disabled-project-setting-section' : ''}`}>
                    <Button
                        className="green new-type-button"
                        disabled={!statusReasonSettings.use || (this.state.statusReasons[dataKey] || []).findIndex(reason => reason.id == -1) != -1}
                        onClick={() => {
                            const statusReasons = cloneDeep(this.state.statusReasons);
                            const data = [...(statusReasons[dataKey] || [])];
                            data.push(newRow);
                            statusReasons[dataKey] = data;
                            this.setState({ statusReasons });
                        }}
                        size="large"
                    >
                        {buttonTitle}
                    </Button>
                    <List
                        id="statusReasonList"
                        fluid={true}
                        className="settingsList"
                        height="auto"
                        hideHeader={(this.state.statusReasons[dataKey] || []).length == 0}
                        rowHeight={SettingRow.rowHeight}
                        listRowType={ProjectStatusReasonRow}
                        emptyNewDataOnUpdate={false}
                        rowProps={{
                            disabled: !useStatusReasons,
                            onCreate: (data) => this.onCreateStatusReason(data, dataKey),
                            onUpdate: (data) => this.onEditStatusReason(data, dataKey),
                            onDelete: data => {
                                this.setState({
                                    dialogData: {
                                        id: data.id,
                                        saveFunc: (id) => this.onDeleteStatusReason(id, dataKey),
                                        text: this.tr("Do you want to remove this reason?"),
                                    },
                                }, () => {
                                    this.openDialog("confirmation");
                                });
                            },
                        }}
                        columns={[
                            { width: 2, name: 'delete', header: '', ...colConfig },
                            { width: 30, name: 'name', header: this.tr('Name'), ...colConfig },
                        ]}
                        data={this.state.statusReasons[dataKey] || []}
                    />
                </div>
            </div>
        );
    }

    renderContentForMode = () => {

        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        const { tr } = this;
        const {hRHeaderVisible, pCHeaderVisible, eFHeaderVisible, pTHeaderVisible} = this.state;

        const settings = [
            {
                type: "check",
                name: "charge_hours",
                label: tr("Invoice hours"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "charge_traveling",
                label: tr("Invoice expenses from"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "charge_others",
                label: tr("Scheduled / Recurring invoicing"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "charge_costest",
                label: tr("Invoice quote"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "charge_received_invoices",
                label: tr("Invoice project bills"),
                className: "respSetting half",
                hideCond: () => VersionContentManager.isFeatureHidden(this.namespace, 'bills')
            },
            {
                type: "check",
                name: "project_mass_invoicing",
                label: tr("Show in mass invoicing"),
                className: "respSetting half",
                hideCond: () => VersionContentManager.isFeatureHidden(this.namespace, 'massInvoicing')
            },
        ];

        const singletype = [
            {
                type: "check",
                name: "oneprojecttype",
                label: tr("only one project type can be selected for a project"),
                className: "respSetting half",
            },
        ];

        const defaultQuoteVat = [
            {
				type: 'text',
				name: 'default_quote_vat',
				label: tr('Default VAT%'),
				className: 'respSetting third marginTop',
				validation: ["numeric"],
			},
        ];

        const targetingSettings = [
            {
                type: "check",
                name: "extended_cost_targeting",
                label: tr("Show cost targeting during cost creating"),
                className: "respSetting half",
                locked: false
            },
        ];

        const quoteCurrencySettings = [
            {
                type: "check",
                name: "use_quote_currencies",
                label: tr("Allow creating sales quotes in other currencies"),
                className: "respSetting half",
                locked: false
            },
        ];

        const eventDateSettings = [
            {
                type: "check",
                name: "use_events",
                label: tr("Activate"),
                className: "respSetting half",
                locked: false
            },
        ];
        const projectCategorySettings = [
            {
                type: "check",
                name: "project_category_settings",
                label: tr("Set default category for a new project"),
                className: "respSetting half",
                locked: false
            },
        ];   

        const revenueRecognitionSettings = [
            {
                type: "check",
                name: "revenue_recognition_equal_project_value",
                label: tr("Recognized revenue must equal to project value or 0"),
                className: "respSetting half",
                locked: false
            },
            {
                type: "check",
                name: "revenue_recognition_sync_scheduled_invoices",
                label: tr("Synchronize scheduled invoices in revenue recognition"),
                className: "respSetting half",
                locked: false
            },
            {
                type: "labeltext",
                name: "revenue_recognition_month_lock_day",
                label: tr("Day of current month"),
                formLabel: tr("Lock Recognition previous months"),
                className: "respSetting half",
                validation: ["numeric"],
                locked: false
            },
            {
                type: "check",
                name: "revenue_recognition_month_lock_user_splits",
                label: tr("Lock user specific split form previous month"),
                className: "respSetting half",
                locked: false
            },
            {
                type: "select",
                name: "revenue_recognition_bills_by_date",
                label: tr("Show bills by"),
                className: "respSetting third",
                options: [
                    { value: 1, label: tr("Bill date") },
                    { value: 2, label: tr("Due date") },
                ],
            },
            {
                type: "select",
                name: "revenue_recognition_expenses_by_date",
                label: tr("Show expenses and travel expenses by"),
                className: "respSetting third",
                options: [
                    { value: 1, label: tr("Creation date") },
                    { value: 2, label: tr("Expense date and travel expense end date") },
                ],
            },
            {
                type: "select",
                name: "revenue_recognition_purchase_orders_by_date",
                label: tr("Show purchase orders by"),
                className: "respSetting third",
                options: [
                    { value: 1, label: tr("Order date") },
                    { value: 2, label: tr("Estimated due date") },
                ],
            },
        ];
        
        const projectTeamSettings = [
            {
                type: "check",
                name: "project_team_option",
                label: tr("Always bring account team to new projects"),
                className: "respSetting half",
                locked: false
            },
            {
                type: "check",
                name: "limited_project_team",
                label: tr("Show only users of the project's company in project team selection"),
                className: "respSetting half",
                locked: false
            },
        ];        

        const { company } = this.props;
        const { functions: { checkPrivilege }, addons } = this.context;

        switch (this.state.selectedTab) {
            case 'proposalTemplates':
                const templatesForMode = this.state.proposalTemplates.filter(t => this.state.selectedProposalMode == 'covers' ? t.type == 'cover' : t.type != 'cover');
                const columns = [
                    { width: 30, name: "title", header: tr("Name"), ...dColConf },
                    { width: 15, name: "created_at", header: tr("Created at"), ...dColConf },
                    { width: 20, name: "created_by", header: tr("Created by"), ...dColConf },
                ];
                if (checkPrivilege('projects', 'proposal_write', company)) {
                    columns.unshift({ width: 4, name: "delete", header: "", ...dColConf });
                }
                return (
                    <div>
                        <List
                            ref={this.proposalTemplateList}
                            fluid={true}
                            className="settingsList"
                            height="auto"
                            rowHeight={SettingRow.rowHeight}
                            listRowType={ProposalTemplateRow}
                            showOverlay={this.state.proposalTemplatesLoaded && templatesForMode.length == 0}
                            overlayProps={{
                                header: this.tr("No data to show."), 
                                subtitle: this.tr('Create templates from the proposal editor to see them here.')
                            }}
                            overlayComponent={NoProposalTemplatesOverlay}
                            hideHeader={this.state.proposalTemplates.length == 0}
                            emptyNewDataOnUpdate={false}
                            columns={columns}
                            rowProps={{
                                onOpenTemplate: (data) => {
                                    const template = {
                                        id: data.id,
                                        content: [data]
                                    }
                                    this.context.functions.setOverlayComponent(<ProposalEditor refreshQuoteData={this.getProposalTemplates} company={this.props.company} template={template} />);
                                },
                                onDelete: data => {
                                    this.setState({
                                        dialogData: {
                                            id: data.id,
                                            saveFunc: id =>
                                                DataHandler.delete({ url: `companies/${this.props.company}/proposal_templates/${id}`}).done(
                                                    () => {
                                                        this.getProposalTemplates()
                                                    }
                                                ),
                                            text: this.tr("Do you want to remove this proposal template?"),
                                        },
                                    });
                                    this.openDialog("confirmation");
                                },
                            }}
                            data={templatesForMode} />
                    </div>
                );
            case 'projectSettings':
                return (
                    <React.Fragment>
                        <h3>{tr("Default invoice types for projects moved to won deals")}</h3>
                        <p className="subtitle">{tr("Taimer allows you to invoice hours, expenses, bills, scheduled & recurring invoicing. Define your default invoicing types to be activated when a project is moved to won deals. Activated types will show on invoice upon creation.")}</p>
                        <SettingsGrid settings={settings} item={this.state.settings} onChange={this.onChange} />

                        <div className='settings-feature-wrapper'>
                                <div className='respSetting half clearfix'>
                                    <h3>{tr('Quote setting')}</h3>
                                    <SettingsGrid settings={defaultQuoteVat} item={this.state.default_quote_vat} onChange={this.onChangeDefaultQuoteVat}/>
                                </div>
                        </div>

                        {!VersionContentManager.isFeatureHidden(this.namespace, 'costTargeting') && <div className="settings-feature-wrapper">
                            <h3>{tr("Project cost targeting")}</h3>
                            <p className="subtitle">
                                {tr("Enable to control the cost targeting already during entering the data.")}
                            </p>
                            <SettingsGrid
                                settings={targetingSettings}
                                item={this.state.targetingSettings}
                                onChange={data => {
                                    DataHandler.put(
                                        { url: `settings/company/${company}/cost_targeting` }, data)
                                    .then(response => {
                                        this.setState({ targetingSettings: response});
                                    });
                                }}
                            />
                        </div>}
                        {addons.invoice_currency && <div className='settings-feature-wrapper'>
                            <h3>{tr('Creating sales quotes in other currencies')}</h3>
                            <p className="subtitle">
                                {tr("By activating this setting you allow creating sales quotes in other currencies. The used currencies and their rates can be managed in the \"Invoicing & Bills\" settings in \"Currency settings\" tab")}
                            </p>
                            <SettingsGrid
                                settings={quoteCurrencySettings}
                                item={this.state.quoteCurrencySettings}
                                onChange={data => {
                                    DataHandler.put(
                                        { url: `settings/company/${company}/use_quote_currencies` }, data)
                                        .then(response => {
                                            this.setState({ quoteCurrencySettings: response });
                                        });
                                }}
                            />
                        </div>}
                        <div className="settings-feature-wrapper">
                            <h3>{tr("Event date range selection")}</h3>
                            <p className="subtitle">
                                {tr("Activate the event date range to add a new date range selection to projects, use the event date range to filter specific dates different to project start and end date. Event date can also be used for sales reports.")}
                            </p>
                            <SettingsGrid
                                settings={eventDateSettings}
                                item={this.state.eventDateSettings}
                                onChange={data => {
                                    DataHandler.put(
                                        { url: `settings/company/${company}/use_events` }, data)
                                    .then(response => {
                                        this.setState({ eventDateSettings: response});
                                    });
                                }}
                            />
                        </div>                        
                        {!VersionContentManager.isFeatureHidden(this.namespace, 'revenueRecognition') && <div className="settings-feature-wrapper">
                            <h3>{tr("Revenue Recognition")}</h3>
                            <SettingsGrid
                                settings={revenueRecognitionSettings}
                                item={this.state.revenueRecognitionSettings}
                                onChange={this.setRevenueRecognitionSettings}
                            />
                        </div>}
                        {!VersionContentManager.isFeatureHidden(this.namespace, 'team') && <div className="settings-feature-wrapper">
                            <h3>{tr("Project team")}</h3>
                            <p className="subtitle">
                                {tr("Project team setting description.")}
                            </p>
                            <SettingsGrid
                                settings={projectTeamSettings}
                                item={this.state.projectTeamSettings}
                                onChange={data => {
                                    DataHandler.put(
                                        { url: `settings/company/${company}/project_team_option` }, data)
                                    .then(response => {
                                        this.setState({ projectTeamSettings: response});
                                    });
                                }}
                            />
                        </div>}

                        <div className ="settings-feature-wrapper">
                            <h3>{tr("Project Category for advanced reporting")}</h3>
                            <p className="subtitle">{tr("Additionally you can define a project category hierarchy to be added to projects, this will give you one more additional attribute to report sales, hours and more. One project category can be added to each project. Project categories are typically internal services and cost centres businesses wish to follow up on.")}</p>
                            <SettingsGrid
                                settings={projectCategorySettings}
                                item={this.state.projectCategorySettings}
                                onChange={data => {
                                    DataHandler.put(
                                        { url: `settings/company/${company}/default_project_category` }, data)
                                    .then(response => {
                                        this.setState({ projectCategorySettings: response});
                                    });
                                }}
                            />
                            <Button className="green" onMouseUp={evt => {this.projectBobList.current.addNewRow(); this.setState({pCHeaderVisible:true})}} size="large">{tr("Add project category")}</Button>
                            <List
                                id="projectBobList"
                                className="settingsList"
                                height="auto"
                                fluid
                                rowHeight={44}
                                listRowType={ProjectCategoryRow}
                                ref={this.projectBobList}
                                minimizeRerendersInTrees={true}
                                emptyNewDataOnUpdate={false}
                                showPageSelector={false}
                                hideHeader={( pCHeaderVisible || (this.state.data.projectBobs && this.state.data.projectBobs.length > 0)) ? false: true}
                                treeData={true}
                                noStateData={true}
                                rowKey={"id"}
                                parentKey={"parent_id"}
                                rowProps={{
                                    // rowClassName: "projectCategoryRow",
                                    rowHeight: 44,
                                    addChildLabel: tr("Add sub-category"),
                                    archiveLabel: tr("Archive"),
                                    activateLabel: tr("Activate"),
                                    deleteLabel: tr("Delete"),
                                    indentWidth: 24,
                                    dimensions: this.state.dimensions,
                                    onCreate: data => {
                                        if (data.is_default == 0)
                                            delete data.is_default;

                                        const error = this.state.data.projectBobs.find(pb => {
                                            const pbName = pb.name?.toLowerCase() || "";

                                            return pb.id != data.id && pbName === data.name.toLowerCase();
                                        }) !== undefined;

                                        if(data.name == ""){
                                            this.props.enqueueSnackbar((tr("Enter project category") + "."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        if (!error) {
                                            DataHandler.put({url: `settings/company/${company}/project/project_category`, data}).done(response => {
                                                this.projectBobList.current.editData({ id: response.id, newRowId: data.id }, data.id); 
                                                this.fetchProjectCategories();
                                            });    
                                        }
                                        else{
                                            this.props.enqueueSnackbar(( data.name + tr(" Project category already exists") + "."), {
                                                variant: "error",
                                            });
                                            return;

                                        }
                                    },
                                    onUpdate: data => {
                                        let mainCopy = {...data}
                                        delete data.is_default; 
                                        delete data.locked;
                                        if(this.state.categoryLockedChanged)
                                            data.locked = mainCopy.locked;
                                        if(this.state.defaultCategoryChanged)
                                            data.is_default = mainCopy.is_default;

                                        if (this.state.categoryLockedChanged && mainCopy.is_default) {
                                            this.props.enqueueSnackbar(tr("Cannot archive default category"), {
                                                variant: "error"
                                            });
                                            return;
                                        }

                                        DataHandler.put({url: `settings/company/${company}/project/project_category`, data}).done(response => {
                                            this.setState({categoryLockedChanged: false, defaultCategoryChanged: false}, () => {
                                                if (data.hasOwnProperty("locked")) {
                                                    response.ids.forEach(id => {
                                                        this.projectBobList.current.editData({locked: data.locked}, id);
                                                    })
                                                }
                                                this.fetchProjectCategories();
                                            });
                                        });
                                    },
                                    onDelete: data => {
                                         if (Number(data.id) < 0) {
                                            this.projectBobList.current.removeRow(data.id);
                                             if(this.state.data.projectBobs.length == 0){
                                                this.setState({pCHeaderVisible:false});
                                            } 
                                            return;
                                        } 
                                        this.setState({
                                            dialogData: {
                                                id: data.id,
                                                saveFunc: (id) => {
                                                    if (!data.is_default)
                                                        delete data.is_default; 
                                                   
                                                    DataHandler.delete({url: `settings/company/${company}/project/project_category`, data}).done(() => {
                                                        this.projectBobList.current.removeRow(String(id));
                                                        this.fetchProjectCategories();
                                                    });
                                                    
                                                    
                                                },
                                                text: tr("Do you want to delete project category") + ": " + data.name + "?"
                                            }
                                        });

                                        this.openDialog('confirmation');
                                    },
                                    addChild: data => {
                                        if(data.id < 0) {
                                            DataHandler
                                                .post({url: `settings/company/${company}/project/project_category`, data})
                                                .done(resp => {
                                                    this.projectBobList.current.editData({ id: resp.id }, data.id);
                                                    this.projectBobList.current.addNewRow({ parent_id: resp.id });
                                                });
                                            } else {
                                                this.projectBobList.current.addNewRow({ parent_id: data.id });
                                            }
                                        },
                                    changeLocked: () => {this.setState({categoryLockedChanged: true}, () => {return true})},
                                    changeDefault: () => {this.setState({defaultCategoryChanged: true}, () => {return true})},
                                }}
                                parentKey="parent_id"
                                newRow={{
                                    // id: -1,
                                    name: "",
                                    is_default: this.state.data.projectBobs.length > 0 ? 0 : 1,
                                }}
                                columns={[
                                    { width: 2, name: "contextMenu", header: "", ...dColConf },
                                    { width: 30, name: "name", header: tr("Project category"), ...dColConf },
                                    { width: 10, name: "is_default", header: tr("Default category"), ...dColConf},
                                    ...(this.context.addons.hubspot ? [{width: 10, name: "hubspot_id", header: tr("HubSpot ID"), ...dColConf}] : []),
                                    ...(this.context.addons.procountor ? [{width: 10, name: "accounting_integration_id", header: tr("Number"), ...dColConf}] : []),
                                    ...(this.context.addons.talenom ? [{width: 10, name: "dimension_id", header: tr("Dimension"), ...dColConf}] : [])
                                ]}
                                data={this.state.data.projectBobs}
                            />
                            </div>
                            <div className="settings-feature-wrapper">
                                <h3>{tr("Project types")}</h3>
                                <p className="subtitle projec-types-subtitle">
                                    {this.tr(
                                        "You can give attributes to your projects to help you manage and report what types of projects you are selling and managing. A project can have default types. Typical types are e.g. Fee, Monthly, One-off projects.."
                                    )}
                                </p>
                                <SettingsGrid
                                    settings={singletype}
                                    item={this.state.typesettings}
                                    onChange={this.onChangeOneProjecType}
                                />
                                <Button
                                    className="green new-type-button"
                                    onMouseUp={() => {this.projectTypeList.current.addNewRow(); this.setState({pTHeaderVisible:true})}}
                                    size="large"
                                >
                                    {tr("Add project type")}
                                </Button>
                                <List
                                    id="projectTypeList"
                                    ref={this.projectTypeList}
                                    fluid={true}
                                    className="settingsList"
                                    height="auto"
                                    rowHeight={SettingRow.rowHeight}
                                    listRowType={ProjectTypeSettingsRow}
                                    hideHeader={( pTHeaderVisible || (this.state.projectTypes && this.state.projectTypes.length > 0)) ? false: true}
                                    emptyNewDataOnUpdate={false}
                                    newRow={{ name: "", is_default: "0", show_in_budget: "0" }}
                                    columns={[
                                        { width: 2, name: "delete", header: "", ...dColConf },
                                        { width: 30, name: "name", header: tr("Name"), ...dColConf },
                                        { width: 5, name: "is_default", header: tr("Default type"), ...dColConf },
                                    ]}
                                    rowProps={{
                                        oneprojecttype: this.state.typesettings.oneprojecttype === "1",
                                        onCreate: data =>{
                                            if(data.name == ""){
                                                this.props.enqueueSnackbar((tr("Enter project type") + "."), {
                                                    variant: "error",
                                                });
                                                return;
                                            }
                                                DataHandler.post({ url: `settings/company/${company}/project/project_types`}, { project_types: JSON.stringify([data]) })
                                                    .done(() => {
                                                        this.fetchProjectTypes(() =>
                                                            this.projectTypeList.current.removeNewRow(data.id)
                                                        );
                                                    })
                                                    .fail(() => {
                                                        const { enqueueSnackbar } = this.props;
                                                        enqueueSnackbar(
                                                            this.tr("The project type:") +
                                                                " " +
                                                                data.name +
                                                                "  " +
                                                                this.tr("already exists"),
                                                            {
                                                                variant: "error",
                                                            }
                                                        );
                                                        this.fetchProjectTypes();
                                                    });

                                        },
                                        onUpdate: data => {
                                            if (
                                                this.state.typesettings &&
                                                this.state.projectTypes &&
                                                data.is_default &&
                                                this.state.typesettings.oneprojecttype === "1" &&
                                                this.state.projectTypes.some(e => e.is_default === "1" && e.id != data.id)
                                            ) {
                                                //There wasn't time for better solution :(
                                                this.state.projectTypes.forEach(type => {
                                                    if (type.is_default === "1") {
                                                        type.is_default = "0";
                                                        DataHandler.post({ url: `settings/company/${company}/project/project_types`}, { project_types: JSON.stringify([type]) })
                                                            .done(() => {});
                                                    }
                                                });

                                                DataHandler.post({ url: `settings/company/${company}/project/project_types`}, { project_types: JSON.stringify([data]) }
                                                ).done(() => {
                                                    this.fetchProjectTypes();
                                                });

                                                /* this.props.enqueueSnackbar(this.tr("Only one project type is enabled"), {
                                                    variant: "error",
                                                }); */
                                            } else {
                                                DataHandler.post({ url: `settings/company/${company}/project/project_types`}, { project_types: JSON.stringify([data]) }
                                                ).done(() => {
                                                    this.fetchProjectTypes();
                                                });
                                            }
                                        },
                                        onDelete: data => {
                                            if (Number(data.id) < 0) {
                                                this.projectTypeList.current.removeNewRow(data.id);
                                                if(this.state.projectTypes.length == 0){
                                                    this.setState({pTHeaderVisible:false});
                                                }
                                                return;
                                            }
                                            this.setState({
                                                dialogData: {
                                                    id: data.id,
                                                    saveFunc: id =>
                                                        DataHandler.delete({ url: "settings/project_types/" + id }).done(
                                                            () => {
                                                                this.fetchProjectTypes();
                                                            }
                                                        ),
                                                    text: this.tr("Do you want to delete project type: ") + data.name + "?",
                                                },
                                            });
                                            this.openDialog("confirmation");
                                        },
                                    }}
                                data={this.state.projectTypes} />
                        </div> 
                        {!VersionContentManager.isFeatureHidden(this.namespace, 'extraFields') && <div className ="settings-feature-wrapper">
                            <h3>{tr("Add new extra field to project details")}</h3>
                            <p className="subtitle">{tr("Define if your extra field is free text or dropdown. You can even make the extra field as a mandatory selector.")}</p>
                            
                            <CustomFields 
                                name="customFields" 
                                value={this.state.customFields} 
                                onChange={this.onChangeCustomFields}
                                onCreateNew={this.createCustomField}
                                form={0} />
                        </div>}
                        <div className="settings-feature-wrapper">
                            <h3>{tr('Won & Lost Reason')}</h3>
                            <p className="subtitle">
                                {this.tr('Add types for selecting won and lost reason. When closing a deal as won or lost for a project, user willl be shown a popup to define won/lost type and reason.')}
                            </p>
                            {this.projectStatusReasonItems.map(reasonItem => this.renderStatusReasonSection(reasonItem))}
                        </div>
                        <div className ="settings-feature-lastwrapper">
                            <h3>{tr("HR projects")}</h3>
                                <p className="subtitle">{this.tr('Projects which visibility in reporting can be defined in permissions. Are used for maternity leaves and such.')}</p>
                                <Button className="green" onMouseUp={() => {this.projectHRList.current.addNewRow(); this.setState({hRHeaderVisible:true})}} size="large">{tr("Add HR-project")}</Button>
                                <List id="projectHRList"
                                    ref={this.projectHRList}
                                    fluid={true} 
                                    className="settingsList"
                                    height="auto" 
                                    rowHeight={SettingRow.rowHeight}
                                    hideHeader={( hRHeaderVisible || this.state.hrProjects.length > 0) ? false: true}
                                    listRowType={HRProjectRow}
                                    emptyNewDataOnUpdate={false}
                                    newRow={{ name: "", is_default: "0", show_in_budget: "0" }}
                                    columns={[
                                            { width:20,  name: "delete", header: "", ...dColConf },
                                            {  name: "project", header: tr("Project"), ...dColConf },
                                        ]}
                                        rowProps={{
                                            company: company,
                                            onCreate: this.createHRProjects,
                                            onUpdate: this.updateHRProjects,
                                            onDelete: this.deleteHRProjects
                                        }}
                                    data={this.state.hrProjects}
                                />
                        </div>

                    </React.Fragment>
                );
                default:
                    return null;
        }
    }

    render() {
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        const { functions: { checkPrivilege } } = this.context;

        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };
          

        return (
            <div id="settings-feature-projects">
                {!this.state.initialized && 
                    <div> 
                        <Loading style={loaderStyle} />
                    </div>
                }
                {this.state.initialized
                    && <>
                    {checkPrivilege('projects', 'proposal_read', this.props.company) && <DropdownTabs onTabClick={this.setSelectedTab} tabs={this.tabs} selected={this.state.selectedTab} selectedSubItem={this.state.selectedProposalMode} />}
                    {this.renderContentForMode()}
                    </>
                }

                {Dialog && <Dialog
                    open
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData} />}
            </div>
        );
    }
}

export default withSnackbar(Project);
