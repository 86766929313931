import { ColumnDefinition } from './ColumnTypes';

// In the db:
// 10 - free
// 11 - growth
// 12 - business
// 4  - enterprise

// Here:
// 10 - free
// 20 - growth
// 30 - business
// 40 - enterprise

const VERSION_MAP = {
    1: 40,
    10: 10,
    11: 20,
    12: 30,
    4: 40
};

interface VisibilityRule {
    version?: number;
    addon?: string | undefined;
}

interface VisibilityRules {
    [key: string]: {
        [key: string]: VisibilityRule | number | ((c: Context, version: number) => boolean);
    }
}

interface Context {
    versionId: string | number;
    addons: { 
        [name: string]: { used_by_companies: (string | number)[]; }; 
    };
    taimerAccount: { 
        isMulticompany: boolean,
        useExtraProjectHours: boolean
    };
    misc: { 
        hours_approval_used_for_companies: (string | number)[],
        resourcing_priority_shown_for_companies: (string | number)[],
        resourcing_task_type_shown_for_companies: (string | number)[]
    };
}

const RULES: Readonly<VisibilityRules> = {
    shared: {
        // Customer
        'account'                   : 10,
        'account_num'               : 10,
        'account_parent'            : 40,
        'customer_account_managers' : 10,
        'customer_account_group'    : 30,
        'customer_enterprise_group' : 40,
        'customer_type'             : 10,
        'customer_industry'         : 10,
        'customer_tags'             : 10,
        'customer_locked'           : 10,
        'customer_sap_account_number' : c => c?.addons?.sap?.used_by_companies?.length > 0,
        // Project
        'project'                   : 10,
        'project_id'                : 10,
        'project_parent'            : 40,
        'seller'                    : 10,
        'project_manager'           : 10,
        'project_category'          : 10,
        'project_pipeline'          : 30,
        'project_stage'             : 30,
        'project_reporting_group'   : 30,
        'project_tags'              : 10,
        'project_type'              : 10,
        'project_locked'            : 10,
        'project_account_group'     : 30,
        // User
        '%%prefix%%_id'             : 10,
        '%%prefix%%'                : 10,
        '%%prefix%%_superior'       : 40,
        '%%prefix%%_teams'          : 30,
        '%%prefix%%_pro_title'      : 40,
        '%%prefix%%_type'           : 40,
        '%%prefix%%_locked'         : 10,
        '%%prefix%%_employee_start' : 10,
        '%%prefix%%_employee_end'   : 10,
    },
    invoicing: {
        'billentries_id'      : 10,
        'bill_id'             : 10,
        'company'             : c => c?.taimerAccount?.isMulticompany,
        'invoice_account'     : 10,
        'invoice_type'        : 10,
        'invoice_state'       : 10,
        'integration_used'    : c => {
            return [
                "efina",
                "emce",
                "fennoa",
                "fivaldi",
                "fortnox",
                "gothia",
                "maventa",
                "meritaktiva",
                "netvisor",
                "procountor",
                "ropocapital",
                "talenom",
                "tietotili",
            ].find(i => c.addons[i] && c.addons[i].used_by_companies?.length > 0) !== undefined;
        },
        'currency'            : { addon: "invoice_currency" },
        'currency_rate'       : { addon: "invoice_currency" },
        'creationdate'        : 10,
        'creationdate_month'  : 10,
        'creationdate_year'   : 10,
        'creationdate_quarter': 10,
        'duedate'             : 10,
        'duedate_month'       : 10,
        'duedate_year'        : 10,
        'duedate_quarter'     : 10,
        'deliverydate'        : 10,
        'deliverydate_month'  : 10,
        'deliverydate_year'   : 10,
        'deliverydate_quarter': 10,
        'vat'                 : 10,
        'vatAmount'           : 10,
        'totalNoVat'          : 10,
        'totalVat'            : 10,
        'log_created'         : 10,
        'log_created_month'   : 10,
        'log_created_year'    : 10,
        'log_created_quarter' : 10,
        'paid_date'           : 10,
        'row_type'            : 10,
        'content_type'        : 10,
        'product'             : 10,
        'cpq'                 : 30,
        'description'         : 10,
        'paid_amount'         : 10,
        'financial_year'      : 10,
        'payment_reference'   : 10,
        'your_reference'      : 10,
        'terms'               : 10,
        'annotation'          : 10,
        'country'             : 10,
        'billingstartdate'    : 10,
        'billingenddate'      : 10,
        'worktask_product'    : 10,
    },
    scheduled_invoicing: {
        'billentries_id': 10,
        'account'       : 10,
        'company'       : c => c?.taimerAccount?.isMulticompany,
        'project'       : 10,
        'project_id'    : 10,
        'vat'           : 10,
        'vatAmount'     : 10,
        'totalNoVat'    : 10,
        'totalVat'      : 10,
        'log_created'   : 10,
        'product'       : 10,
        'cpq'           : 30,
        'description'   : 10,
        'financial_year': 10,
        'project_parent': 10,
        'seller'        : 10,
        'invoiced'      : 10,
    },
    workingHours: {
        'id'                      : 10,
        'company'                 : c => c?.taimerAccount?.isMulticompany,
        'user_company'            : c => c?.taimerAccount?.isMulticompany,
        'task'                    : (c, v) => c?.addons?.resourcing?.used_by_companies?.length > 0 || v >= 40,
        'description'             : 10,
        'overtime_description'    : 30,
        'jobtype'                 : 10,
        'status'                  : c => c?.misc?.hours_approval_used_for_companies?.length > 0,
        'billable'                : 10,
        'billed_price'            : 10,
        'billable_amount'         : 10,
        'own_cost'                : 30,
        'billed'                  : 10,
        'hours'                   : 10,
        'billed_hours'            : 10,
        'non_billed_hours'        : 10,
        'billable_hours'          : 30,
        'non_billable_hours'      : 30,
        'vacation_and_leave_hours': 30,
        'is_overtime'             : 30,
        'date'                    : 10,
        'start'                   : 10,
        'end'                     : 10,
        'date_year'               : 10,
        'date_quarter'            : 10,
        'date_month'              : 10,
        'log_created'             : 10,
        'date_fy'                 : 10,
        'bill'                    : 10,
        'billable_rate'           : 30,
    },
    costs: {
        'row_id'           : 10,
        'entity_number'    : 10,
        'cost_type'        : 10,
        'row_type'         : 30,
        'state'            : 10,
        'creationdate'     : 10,
        'payment_type'     : 10,
        'allowance_type'   : 30,
        'expense_types'    : 10,
        'description'      : 10,
        'row_description'  : 10,
        'vat_amount'       : 10,
        'total_without_vat': 10,
        'total_with_vat'   : 10,
        'selling_price'    : 10,
        'billed'           : 10,
        'billable'         : 10,
        'billed_price'     : 10,
        'cost_date'        : 10,
        'startdate'        : 30,
        'starttime'        : 30,
        'enddate'          : 30,
        'endtime'          : 30,
        'route'            : 30,
        'mileage'          : 30,
        'days'             : 30,
        'due_date'         : { version: 40, addon: "bills" },
        'currency'         : 10,
        'currency_rate'    : 10,
        'total_in_currency': 10,
        'buyer_reference'  : { version: 40, addon: "bills" },
        'reference_nr'     : { version: 40, addon: "bills" },
        'bill'             : 10,
        'synced_po'        : { version: 40, addon: "bills" },
        'supplier'         : { version: 40, addon: "bills" },
        'company'          : c => Boolean(c?.taimerAccount?.isMulticompany),
        'creator_company'  : c => Boolean(c?.taimerAccount?.isMulticompany),
        'date_year'        : 10,
        'date_month'       : 10,
        'date_quarter'     : 10,
        'date_fy'          : 10,
        'assigned_to'      : { version: 40, addon: "bills" },
        'approved_by'      : 10,
    },
    projects: {
        'project_company'                    : c => Boolean(c?.taimerAccount?.isMulticompany),
        'project_invoicing_status'           : 30,
        'project_quote_status'               : 10,
        'project_status_changed'             : 10,
        'project_project_created'            : 10,
        'project_startdate'                  : 10,
        'project_enddate'                    : 10,
        'project_event_startdate'            : 10,
        'project_event_enddate'              : 10,
        'project_pipeline_date'              : 30,
        'project_stage_date'                 : 30,
        'project_stage_probability'          : 30,
        'project_closing_date'               : 10,
        'project_budgeted_hours'             : c => c?.taimerAccount?.useExtraProjectHours,
        'hours_tracked'                      : 10,
        'hours_approved'                     : c => c?.misc?.hours_approval_used_for_companies?.length > 0,
        'hours_submitted'                    : c => c?.misc?.hours_approval_used_for_companies?.length > 0,
        'hours_billable'                     : 30,
        'hours_non_billable'                 : 30,
        'hours_invoiced'                     : 10,
        'hours_invoiced_price'               : 10,
        'hours_cost'                         : 30,
        'resourcing_allocated'               : { version: 40, addon: "resourcing" },
        'resourcing_resourced'               : { version: 40, addon: "resourcing" },
        'bill_bills'                         : { version: 40, addon: "bills" },
        'bill_bills_waiting'                 : { version: 40, addon: "bills" },
        'bill_bills_invoiced'                : { version: 40, addon: "bills" },
        'bill_purchase_orders'               : { version: 40, addon: "bills" },
        'expense_expenses'                   : 10,
        'expense_expenses_waiting'           : 10,
        'expense_expenses_invoiced'          : 10,
        'expense_travel'                     : 30,
        'expense_travel_waiting'             : 30,
        'expense_travel_invoiced'            : 30,
        'expense_added_costs'                : 10,
        'expense_added_costs_invoiced'       : 10,
        'costestimate_project_value'         : 10,
        'costestimate_project_margin'        : 10,
        'costestimate_budgeted_gross_margin' : 10,
        'costestimate_budgeted_sales_margin' : 10,
        'invoice_invoiced'                   : 10,
        'invoice_drafts_and_waiting'         : 10,
        'probable_value'                     : 30,
        'probable_gross_margin'              : 30,
        'probable_sales_margin'              : 30,
        'gross_margin_invoicing'             : 10,
        'gross_margin_invoicing_percentage'  : 10,
        'gross_margin_invoicing_per_hour'    : 10,
        'sales_margin_invoicing'             : 10,
        'sales_margin_invoicing_percentage'  : 10,
        'sales_margin_invoicing_per_hour'    : 10,
        'project_backlog'                    : 10,
        'gross_margin_revenue'               : 40,
        'gross_margin_revenue_percentage'    : 40,
        'gross_margin_revenue_per_hour'      : 40,
        'sales_margin_revenue'               : 40,
        'sales_margin_revenue_percentage'    : 40,
        'sales_margin_revenue_per_hour'      : 40,
        'material_total'                     : 10,
        'revenue_recognition_recognised'     : 40,
        'revenue_recognition_estimated_costs': 40,
    },
    accounts: {
        'company'                            : c => Boolean(c?.taimerAccount?.isMulticompany),
        'telephone'                          : 10,
        'email'                              : 10,
        'www'                                : 10,
        'defaultpenaltyinterest'             : 10,
        'defaultannotation'                  : 10,
        'defaulttermsofpayment'              : 10,
        'hours_tracked'                      : 10,
        'hours_approved'                     : c => c?.misc?.hours_approval_used_for_companies?.length > 0,
        'hours_submitted'                    : c => c?.misc?.hours_approval_used_for_companies?.length > 0,
        'hours_billable'                     : 30,
        'hours_non_billable'                 : 30,
        'hours_invoiced'                     : 10,
        'hours_invoiced_price'               : 10,
        'hours_cost'                         : 30,
        'expense_expenses'                   : 10,
        'expense_expenses_waiting'           : 10,
        'expense_expenses_invoiced'          : 10,
        'expense_travel'                     : 30,
        'expense_travel_waiting'             : 30,
        'expense_travel_invoiced'            : 30,
        'expense_added_costs'                : 10,
        'expense_added_costs_invoiced'       : 10,
        'invoice_invoiced'                   : 10,
        'invoice_drafts_and_waiting'         : 10,
        'probable_value'                     : 30,
        'probable_gross_margin'              : 30,
        'probable_sales_margin'              : 30,
        'gross_margin_invoicing'             : 10,
        'gross_margin_invoicing_percentage'  : 10,
        'gross_margin_invoicing_per_hour'    : 10,
        'sales_margin_invoicing'             : 10,
        'sales_margin_invoicing_percentage'  : 10,
        'sales_margin_invoicing_per_hour'    : 10,
        'project_backlog'                    : 10,
        'gross_margin_revenue'               : 40,
        'gross_margin_revenue_percentage'    : 40,
        'gross_margin_revenue_per_hour'      : 40,
        'sales_margin_revenue'               : 40,
        'sales_margin_revenue_percentage'    : 40,
        'sales_margin_revenue_per_hour'      : 40,
        'material_total'                     : 10,
        'revenue_recognition_recognised'     : 40,
        'revenue_recognition_estimated_costs': 40,
        'resourcing_allocated'               : { version: 40, addon: "resourcing" },
        'resourcing_resourced'               : { version: 40, addon: "resourcing" },
        'bill_bills'                         : { version: 40, addon: "bills" },
        'bill_bills_waiting'                 : { version: 40, addon: "bills" },
        'bill_bills_invoiced'                : { version: 40, addon: "bills" },
        'bill_purchase_orders'               : { version: 40, addon: "bills" },
        'project_budgeted_hours'             : c => c?.taimerAccount?.useExtraProjectHours,
        'customer_sap_account_number'        : c => c?.addons?.sap?.used_by_companies?.length > 0,
    },
    forecast: {
        'project_company'                    : c => c?.taimerAccount?.isMulticompany,
        'project_startdate'                  : 10,
        'project_enddate'                    : 10,
        'invoice_invoiced'                   : 10,
        'gross_margin_invoicing'             : 10,
        'gross_margin_invoicing_percentage'  : 10,
        'sales_margin_invoicing'             : 10,
        'sales_margin_invoicing_percentage'  : 10,
        'gross_margin_revenue'               : 40,
        'gross_margin_revenue_percentage'    : 40,
        'sales_margin_revenue'               : 40,
        'sales_margin_revenue_percentage'    : 40,
        'revenue_recognition_recognised'     : 40,
        'material_date_year'                 : 10,
        'material_date_month'                : 10,
        'material_date_quarter'              : 10,
        'gross_costs'                        : 10,
        'sales_costs'                        : 10,
    },
    activities: {
        'project_company'                    : c => c?.taimerAccount?.isMulticompany,
    },
    sales: {
        'project_company'                    : c => c?.taimerAccount?.isMulticompany,
        'cpq'                                : 30,
        'project_pipeline_date'              : 30,
        'project_pipeline_date_year'         : 30,
        'project_pipeline_date_month'        : 30,
        'project_pipeline_date_quarter'      : 30,
        'project_stage_date'                 : 30,
        'project_stage_date_year'            : 30,
        'project_stage_date_month'           : 30,
        'project_stage_date_quarter'         : 30,
        'currency'                           : { addon: "invoice_currency" },
        'currency_rate'                      : { addon: "invoice_currency" },
    },
    contacts: {
        "firstname"         : 10,
        "lastname"          : 10,
        "account"           : 10,
        "company"           : c => c?.taimerAccount?.isMulticompany,
        "title"             : 10,
        "position"          : 10,
        "contact_type"      : 10,
        "owner"             : 10,
        "mailing_lists"     : 10,
        "email"             : 10,
        "phone"             : 10,
        "address"           : 10,
        "zip_code"          : 10,
        "city"              : 10,
        "country"           : 10,
        "language"          : 10,
        "tags"              : 10,
        "created_by"        : 10,
        "edited_by"         : 10,
        "last_edited_on"    : 10,
        "primary_account"   : 10,
        "last_activity_date": 10,
    },
    resourcing: {
        'project_company': c => c?.taimerAccount?.isMulticompany,
        'priority'       : c => c?.misc?.resourcing_priority_shown_for_companies?.length > 0,
        'task_type'      : c => c?.misc?.resourcing_task_type_shown_for_companies?.length > 0,
    },  
    users: {
        'user_id'              : 10,
        'firstname'            : 10,
        'lastname'             : 10,
        'company'              : c => c?.taimerAccount?.isMulticompany,
        'title'                : 10,
        'position'             : 10,
        'type'                 : 40,
        'email'                : 10,
        'phone'                : 10,
        'teams'                : 30,
        'permission_groups'    : 30,
        'superior'             : 40,
        'status'               : 10,
        'employee_start'       : 10,
        'employee_end'         : 10,
        'created_date'         : 10,
        'activated_date'       : 10,
        'locked_date'          : 10,
        'overtime_balancestart': 40,
        'bank_account'         : 10,
        'internal_cost'        : 30,
        'hourly_price'         : 10,
        // Integration ids:
        'netvisor_id'          : (c) => c?.addons?.netvisor?.used_by_companies?.length > 0,
        'procountor_id'        : (c) => c?.addons?.procountor?.used_by_companies?.length > 0,
        'mepco_id'             : (c) => c?.addons?.mepco?.used_by_companies?.length > 0 
            || c?.addons?.mepco_v2?.used_by_companies?.length > 0
            || c?.addons?.esalary?.used_by_companies?.length > 0,
        'm2_id'                : (c) => c?.addons?.m2_expenses_import?.used_by_companies?.length > 0,
        'hr_id'                : (c) => c?.addons?.hr?.used_by_companies?.length > 0,
    },
    revenue_recognition: {
        'project_company': c => c?.taimerAccount?.isMulticompany,
    },
};

function filterColumns(
    fields: ColumnDefinition[], 
    reportType: string | undefined = undefined,
    context: Context, 
    prefix: string | undefined = undefined
): ColumnDefinition[] {
    if(reportType !== undefined && !(RULES?.[reportType] ?? false)) {
        throw new Error(`Type ${reportType} not found in ColumnVisibility rules.`);
    }

    const appVersion      = Number(context.versionId);
    const version: number = VERSION_MAP[appVersion];

    const copiedRules: { 
        [key: string]: VisibilityRule | number | ((c: Context, version: number) => boolean); 
    } = {};
    const usedRules: { 
        [key: string]: VisibilityRule | number | ((c: Context, version: number) => boolean); 
    } = {
        ...RULES.shared,
        ...(reportType !== undefined ? RULES?.[reportType] : {})
    };

    Object.keys(usedRules).forEach((key: string) => {
        const value: VisibilityRule | number | ((c: Context, version: number) => boolean) = usedRules[key];

        if(prefix === undefined) {
            copiedRules[key] = value;

            return;
        }

        const rKey = key.replace("%%prefix%%", prefix);

        copiedRules[rKey] = value;
    });

    return fields.filter(f => {
        const rule: VisibilityRule | number | ((c: Context, version: number) => boolean) = copiedRules?.[f.field] ?? 0

        if(typeof(rule) === "number") {
            return version >= rule;
        }

        if(typeof(rule) === "function") {
            return rule(context, version);
        }

        if(rule.addon && !rule.version) {
            return context.addons?.[rule?.addon]?.used_by_companies?.length > 0;
        }

        if(rule.addon && rule.version) {
            return version >= rule.version
                || context.addons?.[rule?.addon]?.used_by_companies?.length > 0;
        }

        if(rule.version) {
            return appVersion >= version;
        }

        return false;
    });
}

export {
    filterColumns
}
