import React, { Component } from 'react';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";
/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import DataHandler from '../../general/DataHandler';
import DialogBorder from '../DialogBorder';
import DialogSection from '../DialogSection';
import TaimerComponent from '../../TaimerComponent';
import ImportTasksReview from './ImportTasksReview';

/* dialog parts */
import ImportTool from '../../general/ImportTool';
import LoadingScreen from '../../general/LoadingScreen';
import FileUpload from './FileUpload';
import ResultsScreen from './ResultsScreen';
import ErrorScreen from './ErrorScreen';

/* material ui */
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';

/* other */
import { withSnackbar } from 'notistack';
import _ from 'lodash';

/* css */
import styles from './ImportTaskDialog.module.scss'

import { format, isValid, parse } from 'date-fns';
import moment from 'moment/min/moment-with-locales';
import tzmoment from 'moment-timezone';

const initialState = {
    file: [],
    fileData: false,
    errors: [],
    allTasks: [],
    importObjects: [],
    importData: [],
    importStage: "upload",
    noMemory: false,
    onlyIntStr: false,
    importResults: {},
    importStep: 0,
}

class ImportTaskDialog extends TaimerComponent {

    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context, "dialogs/imports/ImportTaskDialog");

        this.productFields = [
            { id: 0, name: "" },
            { id: 'hierarchy', name: this.tr("Task Hierarchy") },
            { id: 'skills_id', name: this.tr("Task Type") },
            { id: 'description', name: this.tr("Description") },
            { id: 'additional_description', name: this.tr("Notes") },
            { id: 'priorities_id', name: this.tr("Priority") },
            { id: 'startdate', name: this.tr("Start Date") },
            { id: 'enddate', name: this.tr("End Date") },
            { id: 'hours', name: this.tr("Allocated") },
        ];

        this.state = {
            ..._.cloneDeep(initialState),
            autoComplete: {},
            importStage: "init",
        };
        this.dialog = React.createRef();
        this.upload = React.createRef();


        [
            "setErrorHandling",
            "setImportObjects",
            "setFileData",
            "startLoadingAnimation",
            "reviewImport",
            "save",
            "handleNext",
            "handleBack",
            "stageFile",
            "uploadFile"
        ].forEach(e => this[e] = this[e].bind(this));
    }

    async componentDidMount() {
        const { company } = this.props;

        super.componentDidMount();

        const response = await DataHandler.get({url: `resourcing/autocomplete/${company}`});

        response.priorities.forEach(p => {
            p.value = p.id;
            p.translated = this.tr(p.name);
            p.label = p.translated;
        } );

        response.skills.forEach(s => {
            s.value = s.id;
            s.label = s.name;
        } );

        this.setState({ autoComplete: response, importStage: "upload" });
    }

    stageFile(file) {
        this.setState({ file: file });
    }

    uploadFile() {
        const { enqueueSnackbar } = this.props;
        const { file } = this.state;
        if (file.length == 0) {
            enqueueSnackbar(this.tr(`Upload a file to continue.`), {
                variant: "error",
            });
            return;
        }
        if (file.length > 0 && !file[0].name.endsWith('xlsx')) {
            enqueueSnackbar(this.tr(`File format is not XLSX.`), {
                variant: "error",
            });
            return;
        }
        if (file.length > 0 && file[0].size > (1048576 * 5)) {
            enqueueSnackbar(this.tr(`File is too large`, "."), {
                variant: "error",
            });
            return;
        }

        this.upload.current.uploadFiles(file);
    }

    setImportObjects(importObjects) {
        this.setState({ importObjects: importObjects });
    }

    setFileData(data) {
        if (!data) {
            this.setState({ importStage: "error" });
        }
        else if (data.errors?.out_of_memory) {
            this.setState({ importStage: "error", noData: true });
        }
        else if (data.errors?.only_int_with_str) {
            this.setState({ importStage: "error", onlyIntStr: true });
        }
        else if (data.errors?.error || !data.data) {
            this.setState({ importStage: "error" });
        }
        else {
            const objects = [];
            _.forEach(data.data, d => {
                const obj = JSON.parse(d)
                objects.push(obj)
            })
            data.data = objects;

            this.setState({ fileData: data, importStage: "config", file: [] });
            this.handleNext();
        }
    }

    startLoadingAnimation(e) {
        if (e.target.name === "upload")
            this.setState({ importStage: "loading" });
    }

    setErrorHandling(e) {
        this.setState({ errorHandling: e });
    }

    close = () => {
        this.props.onDialogClose();
    }

    handleNext(state = {}) {
        let { importStep } = this.state;
        importStep++;
        this.setState({ importStep: importStep, ...state });
    }

    handleBack() {
        let component = "";
        let { importStep } = this.state;
        const stage = this.state.importStage;
        let extra = {};

        if (stage === "config" || stage === "error" || stage === "errors") {
            component = "upload";
            extra = _.cloneDeep(initialState);
        } else if (stage === "review") {
            component = "config";
        }
        if (importStep > 0)
            importStep--;
        this.setState({ importStep: importStep, importStage: component, ...extra });
    }

    getParentLevel = (id) => {
        const p = id.split('.');
        p.pop();

        return p.join('.');
    }

    reviewImport() {
        const { fileData, importObjects, autoComplete: { skills, priorities } } = this.state;
        const { taimerAccount: { useSubresources } } = this.context;
        const { enqueueSnackbar } = this.props
        let duplicates = 0;
        const fieldsToSave = [];

        const fieldsMapped = {};

        _.forEach(importObjects, object => {
            if (object.importable) {
                fieldsMapped[object.taimerField.id] = object;

                if (fieldsToSave.find(x => x.taimerField.id == object.taimerField.id)) {
                    duplicates++;
                }
                fieldsToSave.push(object);
            }
        });

        
        if (['description', 'hours', 'startdate', 'enddate'].find(x => !fieldsMapped[x])) {
            enqueueSnackbar(this.tr("Required fields missing, you need to import at least Description, Allocation, Start Date and End Date"), {
                variant: "error",
            });
            return;
        }

        if (duplicates > 0) {
            enqueueSnackbar(this.tr(`You have `) + duplicates + this.tr(` duplicate fields`), {
                variant: "error",
            });
            return;
        }

        const defaultSkill = skills.find(x => x.is_default === "1")?.id ?? 0;
        const defaultPriority = priorities.find(x => x.is_default === "1")?.id ?? 3;

        this.setState({ importStage: "loading" }, () => {
            const tasksByID = {};
            const tasksWithoutID = [];
            const requiredTasks = [];
            const allTasks = [];
            const errors = [];
            const warnings = {};

            let num = 1;

            const dates = {startdate: true, enddate: true};
            const numbers = {hours: true};

            fileData.data.forEach((row, i) => {
                const task = {
                    id: num++,
                    parent: '',
                    parentId: 0,
                    skills_id: defaultSkill,
                    priorities_id: defaultPriority,
                    timezone: tzmoment.tz.guess(),
                };

                _.forEach(fieldsToSave, (field, n) => {
                    if (!row[field.id])
                        return;

                    const value = row[field.id].name;
                    const valueStr = String(value).trim();
                    const valueInt = Number(valueStr);

                    if (field.taimerField.id === 'skills_id') {
                        const skill = skills.find(x => x.name.toLowerCase() === valueStr.toLowerCase());

                        if (skill) {
                            task.skills_id = skill.id;
                        } else if (valueStr) {
                            warnings[`skill_${valueStr}_404`] = {
                                row: i + 1,
                                column: field.importField,
                                type: 'skill_not_found',
                                skill: valueStr,
                            }
                        }

                    } else if (field.taimerField.id === 'priorities_id') {
                        const priority = priorities.find(x => x.name.toLowerCase() === valueStr.toLowerCase() || x.translated.toLowerCase() === valueStr.toLowerCase());

                        if (priority) {
                            task.priorities_id = priority.id;
                        } else if (valueStr) {
                            warnings[`priority_${valueStr}_404`] = {
                                row: i + 1,
                                column: field.importField,
                                type: 'priority_not_found',
                                priority: valueStr,
                            }
                        }
                    } else if (dates[field.taimerField.id]) {
                        const date = parse(valueStr, 'YYYY-MM-DD', new Date());  

                        if (!isValid(date)) {
                            errors.push({
                                row: i + 1,
                                column: field.importField,
                                type: 'invalid_date',
                                value: valueStr,
                            });
                            task[field.taimerField.id] = format(new Date(), 'YYYY-MM-DD');
                        } else {
                            task[field.taimerField.id] = format(date, 'YYYY-MM-DD');
                        }

                    } else if (numbers[field.taimerField.id]) {
                        task[field.taimerField.id] = valueInt;
                    } else {
                        task[field.taimerField.id] = value;
                    }

                    if (typeof task[field.taimerField.id] === "string") {
                        task[field.taimerField.id] = task[field.taimerField.id].trim();
                    }
                });

                if (task.hierarchy && useSubresources) {
                    task.parent = this.getParentLevel(task.hierarchy);
                    task.parent && requiredTasks.push(task.parent);

                    if (tasksByID[task.hierarchy]) {
                        errors.push({
                            type: 'duplicate_task_hierarchy',
                            id: task.hierarchy,
                        });
                    }

                    tasksByID[task.hierarchy] = task;
                } else {
                    tasksWithoutID.push(task);
                }

                allTasks.push(task);
            });

            allTasks.forEach(task => {
                if (task.parent && useSubresources) {
                    if (!tasksByID[task.parent]) {
                        errors.push({
                            type: 'task_parent_missing',
                            id: task.hierarchy,
                        });
                        return;
                    }

                    task.parentId = tasksByID[task.parent].id;
                }

                if (!task.startdate || !task.enddate || task.enddate < task.startdate) {
                    errors.push({
                        type: 'invalid_start_end',
                        id: task.hierarchy,
                    });
                }

                if (task.hours < 0 || isNaN(task.hours)) {
                    errors.push({
                        type: 'negative_allocated',
                        id: task.hierarchy,
                    });
                }
            });

            setTimeout(() => {
                this.handleNext({ errors, warnings: _.values(warnings), allTasks, importStage: errors.length > 0 ? "errors" : "review" });
            }, 1000);
        });

    }

    async save() {
        const { projectId } = this.props;
        const { allTasks, importResults, importStep } = this.state;
        this.setState({ importStage: "import" });

        try {
            const response = await DataHandler.post({ url: `imports/tasks` }, {
                projects_id: projectId,
                tasks: allTasks,
            });
    
            this.handleNext({ importResults: importResults, importStage: "results" });
		    window.dispatchEvent(new Event("tasksChanged"));
        } catch (error) {
            this.handleNext({ importResults: importResults, importStage: "error" });
        }
    }

    formatError = (error) => {
        // 
        let row = "";

        if (error.row && error.column) {
            row = this.tr("Row ${row}, Column '${column}': ", {row: error.row, column: error.column});
        }

        if (error.type === "invalid_date") {
            return row + this.tr("Date '${value}' is not valid", {
                value: error.value
            });
        } else if (error.type === 'duplicate_task_hierarchy') {
            return row + this.tr("Task '${id}' is definited more than once", {
                id: error.id
            });
        } else if (error.type === 'task_parent_missing') {
            return row + this.tr("Task '${id}' is missing parent", {
                id: error.id
            });       
        } else if (error.type === 'negative_allocated') {
            return row + this.tr("Task '${id}' has invalid allocation", {
                id: error.id
            });  
        } else if (error.type === 'invalid_start_end') {
            return row + this.tr("Task '${id}' ends before it starts", {
                id: error.id
            });  
        } else {
            return `${error.type}`;
        }
    }

    render() {
        const { fileData, importStage, importObjects, errors, warnings, importStep, importResults, allTasks, autoComplete: { skills, priorities, resourcing_disable_skill, resourcing_disable_priority } } = this.state;
        const { company } = this.props;
        const { tr } = this;

        const importSteps = [tr("Upload File"), tr("Map Attributes"), tr("Review Import"), tr("Results")];

        const resultHeaders = {
            totals: {
                success: tr("Products Imported"),
                total: tr("Rows Processed"),
                skip: tr("Rows Skipped"),
                errors: tr("Errors")
            },
            errors: {
                product: {
                    product: tr("Failed to import product on row"),
                    group: tr("Failed to import product and product group(s) on row"),
                }
            }
        };

        const Components = {
            init:
                <LoadingScreen loadingMessage={tr("Loading...")} />,
            error:
                <ErrorScreen errorParams={{ error: true }} />,
            errors:
                <div>
                    <h3>{this.tr("Error during import")}</h3>

                    <ul>
                        {errors.map((x, i) => <li key={i}>{this.formatError(x)}</li>)}
                    </ul>
                </div>,
            upload:
                <FileUpload
                    ref={this.upload}
                    startLoadingAnimation={this.startLoadingAnimation}
                    setFileData={this.setFileData}
                    stageFile={this.stageFile}
                    company={company}
                    uploadHeader={tr("Import tasks by dragging a file here or by")}
                    suggestedFilesize={tr("Max file size: 5.0MiB")}
                    fileSizeWarningThreshold={(1048576 * 5)}
                    extra={{formatDates: 1}}
                />,
            loading:
                <LoadingScreen loadingMessage={tr("Processing data...")} />,
            config:
                <ImportTool
                    importFields={Object.keys(importObjects).length > 0 ? importObjects : fileData.field_names}
                    exampleData={fileData.example_data}
                    taimerFields={this.productFields}
                    setData={this.setImportObjects}
                    dialogRef={this.dialog}
                />,
            import:
                <LoadingScreen loadingMessage={tr("Importing...")} />,
            review:
                <ImportTasksReview
                    warnings={warnings}
                    errors={errors}
                    data={allTasks}
                    skills={skills}
                    priorities={priorities}
                    resourcing_disable_skill={resourcing_disable_skill}
                    resourcing_disable_priority={resourcing_disable_priority}
                />,
            results: <div>
                    <h3>{this.tr("Import Complete!")}</h3>
                </div>
        }

        const Component = Components[importStage];

        return (
            <DialogBorder
                title={importStage !== "review" ? tr("Import tasks from file") : tr("Review & import")}
                onClose={this.close}
                className={styles.dialog}
                id="ImportDialog"
                saveText={importStage === "results" ? tr("Close") : importStage === "review" ? tr("Import") : tr("Continue")}
                onSave={
                    importStage === "upload" ? this.uploadFile :
                        importStage === "review" && this.state.errors.length == 0 ? this.save :
                            importStage === "config" ? this.reviewImport :
                                importStage === "results" ? this.close :
                                    undefined
                }
                hideCancel={importStage === "results"}
                onBack={(importStage !== "results" && importStage !== "upload" && importStage !== "loading" && importStage !== "import") ? this.handleBack : undefined}
                dialogRef={this.dialog}>
                <DialogSection>
                    <Stepper
                        activeStep={importStep}
                        alternativeLabel
                        className="stepper">
                        {importSteps.map(label => (
                            <Step key={label} className="step">
                                <StepLabel className="step-label">{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {Component}
                </DialogSection>
            </DialogBorder>
        );
    }
}

export default withSnackbar(ImportTaskDialog);