import React from 'react';
import moment from 'moment/min/moment-with-locales';
import FileSaver from 'file-saver';
import TaimerComponent from '../../../../TaimerComponent';
import InsightTable from '../../InsightTable';
import GoalsVsActualRow from './GoalsVsActualRow';
import InsightDropDown from '../../InsightDropDown';
import './GoalVsActual.css';
import InsightSlider from '../../InsightSlider';
import GoalVsActualSliderRow from './GoalVsActualSliderRow';
import DataHandler from '../../../../general/DataHandler';
import InsightExportButton from '../../InsightExportButton';

import cloneDeep from "lodash/cloneDeep";

export default class GoalVsActual extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'dashboard/insights/goals/components/GoalVsActual');

        this._commonColumnSettings = {
            resizeable: false,
            moveable: false,
            hideable: false,
            showMenu: false,
        };
        this._monthKeyFormat = 'YYYY-MM';
        this.filters = {};

        const viewTypes = [
            {
                key: 'account_group',
                label: this.tr('Account Group'),
                action: () => this.setViewType('account_group'),
            },
            {
                key: 'account_manager',
                label: this.tr('Account Manager'),
                action: () => this.setViewType('account_manager'),
            },
            {
                key: 'account',
                label: this.tr('Account'),
                action: () => this.setViewType('account'),
            },
        ];

        this.state = {
            data: [],
            viewTypes,
            pipelines: [
                {
                    id: 1,
                    name: 'Pipeline 1',
                },
                {
                    id: 2,
                    name: 'Pipeline 2',
                },
            ],
            selectedMonth: moment().format(this._monthKeyFormat),
            selectedWeek: '-1',
            selectedViewType: 'account_group',
            sliderData: {
                open: false,
                showBackButton: false,
                data: [],
            },
        };
    }

    setViewType = (selectedViewType) => {
        this.setState({ selectedViewType }, () => {
            this.getData();
        });
    };

    componentDidUpdate = (oldProps) => {
        if (oldProps.months != this.props.months && (this.props.months || []).length > 0) {
            const selected = moment(this.state.selectedMonth, this._monthKeyFormat);
            const currentMonthIndex = this.props.months.findIndex((m) => m.month() == selected.month());
            const indexToSet = currentMonthIndex != -1 ? currentMonthIndex : 0;
            this.setState({
                selectedMonth: this.props.months[indexToSet].format(this._monthKeyFormat),
            });
        }
    };

    _getViewTypes = (filters) => {
        const viewTypes = [
            {
                key: 'account_group',
                label: this.tr('Account Group'),
                action: () => this.setViewType('account_group'),
            },
            {
                key: 'account_manager',
                label: this.tr('Account Manager'),
                action: () => this.setViewType('account_manager'),
            },
            {
                key: 'account',
                label: this.tr('Account'),
                action: () => this.setViewType('account'),
            },
        ];

        const {
            functions: { checkPrivilege },
        } = this.context;

        if (checkPrivilege('customers', 'unit_read', filters.company)) {
            viewTypes.push({
                key: 'subunit',
                label: this.tr('Sub-unit'),
                action: () => this.setViewType('subunit'),
            });
        }
        return viewTypes;
    };

    getData = (filters = this.filters) => {
        const { getFilters } = this.props;
        const { selectedViewType } = this.state;
        this.filters = filters;
        console.trace(filters)

        filters = {
            ...getFilters(),
            filters,
        };

        const startDate = moment(filters.dateRange.startDate);

        this.props.setLoaded(false);
        const viewTypes = this._getViewTypes(filters);

        this.setState(
            {
                data: [],
                viewTypes,
            },
            () => {
                DataHandler.post(
                    {
                        url: `insightweeklyvsactual/company/${filters.company}/goals/${filters.goal_type}/start_date/${startDate.format('YYYY-MM-DD')}`,
                    },
                    { ...filters, group: selectedViewType }
                ).done((response) => {
                    const data = response.data;
                    this.props.setLoaded(true);
                    this.setState({
                        data,
                    });
                });
            }
        );
    };

    getSelectedViewType = () => {
        return this.state.viewTypes.find((vt) => vt.key == this.state.selectedViewType);
    };

    getColumns = () => {
        const { monthTitles, selectedGoal, selectedGoalType } = this.props;
        const { selectedMonth, selectedWeek } = this.state;

        const viewType = this.getSelectedViewType();
        const timespan = selectedWeek != '-1' ? `${this.tr('wk')} ${moment(selectedWeek, 'WW-YYYY').format('WW')}` : monthTitles[moment(selectedMonth, this._monthKeyFormat).month() + 1];

        const selectedGoalTypeName = selectedGoalType ? selectedGoalType.name : '';
        const selectedGoalName = selectedGoal ? selectedGoal.name : '';

        return [
            {
                field: 'label',
                name: 'label',
                header: viewType.label,
                width: 200,
                ...this._commonColumnSettings,
            },
            {
                field: 'type_ytd',
                name: 'type_ytd',
                header: `${selectedGoalTypeName} ${this.tr('YTD')}`,
                width: 150,
                ...this._commonColumnSettings,
            },
            {
                field: 'goal_type_ytd',
                name: 'goal_type_ytd',
                header: `${selectedGoalName} ${this.tr('YTD')}`,
                width: 150,
                ...this._commonColumnSettings,
            },
            {
                field: 'cumulative_difference_ytd',
                name: 'cumulative_difference_ytd',
                header: this.tr('Cum. diff. YTD'),
                width: 150,
                ...this._commonColumnSettings,
            },
            {
                field: 'type_month',
                name: 'type_month',
                header: `${selectedGoalTypeName} ${timespan}`,
                width: 120,
                ...this._commonColumnSettings,
            },
            {
                field: 'goal_type_month',
                name: 'goal_type_month',
                header: `${selectedGoalName} ${timespan}`,
                width: 150,
                ...this._commonColumnSettings,
            },
            {
                field: 'difference_month',
                name: 'difference_month',
                header: `${this.tr('Difference ${timespan}', { timespan })}`,
                width: 150,
                ...this._commonColumnSettings,
            },
        ];
    };

    getSliderColumns = (projectMode = false) => {
        const { selectedViewType } = this.state;
        const { selectedGoalType, selectedGoal } = this.props;
        const commonSettings = {
            resizeable: false,
            moveable: false,
            hideable: false,
            showMenu: false,
        };

        const selectedGoalTypeName = selectedGoalType ? selectedGoalType.name : '';
        const selectedGoalName = selectedGoal ? selectedGoal.name : '';

        if (projectMode) {
            return [
                {
                    field: 'number',
                    name: 'number',
                    header: this.tr('Nr.'),
                    width: 70,
                    ...commonSettings,
                },
                {
                    field: 'project',
                    name: 'project',
                    header: this.tr('Project'),
                    width: 150,
                    ...commonSettings,
                },
                {
                    field: 'creation_date',
                    name: 'creation_date',
                    header: this.tr('Creation Date'),
                    width: 150,
                    ...commonSettings,
                },
                {
                    field: 'funnel',
                    name: 'funnel',
                    header: this.tr('Funnel'),
                    width: 150,
                    ...commonSettings,
                },
                {
                    field: 'status',
                    name: 'status',
                    header: this.tr('Status'),
                    width: 150,
                    ...commonSettings,
                },
                {
                    field: 'stage',
                    name: 'stage',
                    header: this.tr('Stage'),
                    width: 150,
                    ...commonSettings,
                },
                {
                    field: 'status_date',
                    name: 'status_date',
                    header: this.tr('Status changed'),
                    width: 150,
                    ...commonSettings,
                },
                {
                    field: 'goal_type',
                    name: 'goal_type',
                    header: `${selectedGoalName}`,
                    width: 200,
                    ...commonSettings,
                },
            ];
        }

        switch (selectedViewType) {
            default:
                return [
                    {
                        field: 'number',
                        name: 'number',
                        header: this.tr('Nr.'),
                        width: 70,
                        ...commonSettings,
                    },
                    {
                        field: 'account',
                        name: 'account',
                        header: this.tr('Account'),
                        width: 150,
                        ...commonSettings,
                    },
                    {
                        field: 'projects',
                        name: 'projects',
                        header: this.tr('Projects'),
                        width: 150,
                        ...commonSettings,
                    },
                    {
                        field: 'type_ytd',
                        name: 'type_ytd',
                        header: `${selectedGoalTypeName} ${this.tr('YTD')}`,
                        width: 200,
                        ...commonSettings,
                    },
                    {
                        field: 'goal_type_ytd',
                        name: 'goal_type_ytd',
                        header: `${selectedGoalName} ${this.tr('YTD')}`,
                        width: 200,
                        ...commonSettings,
                    },
                    {
                        field: 'cumulative_difference_ytd',
                        name: 'cumulative_difference_ytd',
                        header: this.tr('Cum. diff. YTD'),
                        width: 200,
                        ...commonSettings,
                    },
                ];
        }
    };

    showSlider = (data) => {
        const viewType = this.getSelectedViewType();
        const goal = this.props.selectedGoal.name;

        this.setState(
            {
                sliderData: {
                    ...this.state.sliderData,
                    open: true,
                    label: data.label || '-',
                    monthLabel: `${viewType.label} - ${this.tr('Goal')}: ${goal}`,
                    loading: true,
                    customers_ids: data.customers_ids,
                    projects_ids: data.projects_ids,
                    overrideColumns: this.getSliderColumns(),
                    data: [],
                },
            },
            () => {
                const filters = this.filters;
                const startDate = moment(filters.dateRange.startDate);
                const params = { ...this.state.sliderData, type: filters.type, project_type: filters.project_type  };

                DataHandler.post(
                    {
                        url: `insightweeklyvsactualslider/company/${filters.company}/goals/${filters.goal_type}/start_date/${startDate.format('YYYY-MM-DD')}`,
                    },
                    params
                ).done((response) => {
                    const translatedData = response.data.map(d => ({...d, projects: d.projects.map(p => ['Won deals', 'Internal projects'].includes(p.funnel) ? {...p, funnel: this.tr(p.funnel)} : p) }))

                    this.setState({
                        sliderData: {
                            ...this.state.sliderData,
                            data: translatedData,
                            loading: false,
                        },
                    });
                });
            }
        );
    };

    setSliderProjectMode = (data) => {
        this.setState({
            sliderData: {
                ...this.state.sliderData,
                previousData: {
                    ...this.state.sliderData,
                },
                overrideColumns: this.getSliderColumns(true),
                label: `${this.state.sliderData.label} - ${data.account_name}`,
                showBackButton: true,
                data: data.projects,
            },
        });
    };

    setSliderDefaultMode = () => {
        this.setState({
            sliderData: {
                ...this.state.sliderData.previousData,
            },
        });
    };

    getWeeksForSelectedMonth = () => {
        const { selectedMonth } = this.state;
        const month = moment(selectedMonth, this._monthKeyFormat).startOf('month');
        let currentDate = month;
        const weeks = [];
        while (currentDate.isSame(month, 'month')) {
            weeks.push(moment(currentDate));
            currentDate = moment(currentDate.add(1, 'week'));
        }
        return weeks;
    };

    export = (exportType) => {
        const {selectedViewType} = this.state;
        let data = cloneDeep(this.state.data);
        const isGroupbasedView = ['account_manager', 'account_group'].includes(selectedViewType);
        const basicColumns = this.getColumns().filter(c => c.name == 'label' || c.name.includes('ytd')).map(m => ({name: m.name, header: m.header}));
        const accountColumns = this.getSliderColumns().filter(c => c.name == 'account' || c.name.includes('ytd')).map(m => ({name: m.name, header: m.header}));
        const statesMap = [
            {
                id: -1,
                name: this.tr("Active")
            },
            {
                id: 1,
                name: this.tr("Locked")
            },
            {
                id: 2,
                name: this.tr("On Hold")
            },
        ];

        this.setState({loading: true});

        //first columns
        let columns = isGroupbasedView ? 
            [...basicColumns, ...accountColumns.map(ac => ({...ac, name: (ac.name.includes('ytd') ? `customer_${ac.name}` : ac.name)}))] : 
            [...basicColumns];
        
        //project columns
        columns = [...columns, ...this.getSliderColumns(true).filter((g, i) => i != 0).map(m =>  ({name: m.name, header: m.header}))]

        const {company, type, project_type, goal_type, dateRange} = this.filters;
        const startDate = moment(dateRange.startDate)?.format('YYYY-MM-DD');
        const params = {
            type: type, 
            project_type: project_type,
            customer_ids: data.reduce((acc, d) => [...acc, ...d.customers_ids], []).filter((v, i, arr) => arr.indexOf(v) === i),
            dataArray: data.reduce((acc, d) => ({...acc, [d.id]: {customers_ids: d.customers_ids, project_ids: d.projects_ids}}), {})
        };
        DataHandler.post({url: `insightweeklyvsactualslidermultiple/company/${company}/goals/${goal_type}/start_date/${startDate}`,}, params).done((response) => {
            const translatedData = response.map(d => ({...d, projects: d.projects?.map(p => ['Won deals', 'Internal projects'].includes(p.funnel) ? {...p, funnel: this.tr(p.funnel)} : p) }));
            if (isGroupbasedView) {
                translatedData.forEach(t => {
                    data.find(d => d.id == t.id).customers = (data.find(d => d.id == t.id).customers || []).concat([t]);
                });
            } else {
                translatedData.forEach(t => {
                    data.find(d => d.id == t.id).projects = t.projects.filter(p => p.project_id > 0);
                });
            }
            let exportData = [];
            const columnOrder = columns.reduce((acc, c) => [...acc, c.name], []);

            data.map(d => {
                let projects = {}, customers = {}, row = {}, rows = [];

                columnOrder.forEach(co => {
                    switch (co) {
                        case "label": {
                            row[co] = d[co];
                            break;
                        }
                        case "type_ytd": {
                            row[co] = (d.type ? Object.values(d.type)?.reduce((acc, v) => acc + v, 0) : 0).toFixed(2);
                            break;
                        }
                        case "goal_type_ytd": {
                            row[co] = (d.goal_type ? Object.values(d.goal_type)?.reduce((acc, v) => acc + v, 0) : 0).toFixed(2);
                            break;
                        }
                        case "cumulative_difference_ytd": {
                            row[co] = ((d.goal_type ? Object.values(d.goal_type)?.reduce((acc, v) => acc + v, 0) : 0) - (d.type ? Object.values(d.type)?.reduce((acc, v) => acc + v, 0) : 0)).toFixed(2);
                            break;
                        }
                        case "account": {
                            if (isGroupbasedView) {
                                d.customers.forEach(c => {
                                    customers[c.account_id] = {};
                                    customers[c.account_id][co] = c.account_name;
                                })
                            } else
                                return null;
                            break;
                        }
                        case "customer_type_ytd":
                        case "customer_goal_type_ytd":
                        case "customer_cumulative_difference_ytd": {
                            if (isGroupbasedView) {
                                d.customers.forEach(c => customers[c.account_id][co] = c[co]?.toFixed(2));
                            } else
                                return null;
                            break;
                        }
                        case "project": {
                            if (isGroupbasedView) {
                                d.customers.forEach(c => {
                                    projects[c.account_id] = {};
                                    c.projects.forEach(p => {
                                        projects[c.account_id][p.project_id] = {};
                                        projects[c.account_id][p.project_id][co] = p.project_name;
                                    })
                                });
                            } else {
                                d.projects.forEach(p => {
                                    projects[p.project_id] = {};
                                    projects[p.project_id][co] = p.project_name;
                                })
                            }
                            break;
                        }
                        case "creation_date":
                        case "funnel":
                        case "stage":
                        case "status_date": {
                            if (isGroupbasedView) {
                                d.customers.forEach(c => {
                                    c.projects.forEach(p => projects[c.account_id][p.project_id][co] = p?.[co]);
                                });
                            } else {
                                d.projects.forEach(p => projects[p.project_id][co] = p?.[co])
                            }
                            break;
                        }
                        case "status": {
                            if (isGroupbasedView) {
                                d.customers.forEach(c => {
                                    c.projects.forEach(p => projects[c.account_id][p.project_id][co] = statesMap.find(s => s.id == p[co])?.name );
                                });
                            } else {
                                d.projects.forEach(p => projects[p.project_id][co] = statesMap.find(s => s.id == p[co])?.name)
                            }
                            break;
                        }
                        case "goal_type": {
                            if (isGroupbasedView) {
                                d.customers.forEach(c => {
                                    c.projects.forEach(p => projects[c.account_id][p.project_id][co] = p[co]?.toFixed(2));
                                });
                            } else {
                                d.projects.forEach(p => projects[p.project_id][co] = p[co]?.toFixed(2));
                            }
                            break;
                        }                                               
                        default:
                            return null;
                    }                
                });
                if (isGroupbasedView) {
                    Object.entries(customers).forEach(([k, v], i) => {
                        if (i < 1) {
                            rows = [...rows, {...row, ...v, ...projects[k][Object.keys(projects[k])[0]]}];
                            Object.entries(projects[k]).forEach(([pk, pv], j) => {
                                if (j == 0) 
                                    return;
                                rows = [...rows, {...pv}];
                            });
                        } else {
                            rows = [...rows, {...v, ...projects[k][Object.keys(projects[k])[0]]}];
                            Object.entries(projects[k]).forEach(([pk, pv], j) => {
                                rows = [...rows, {...pv}];
                            });
                        }

                    })
                } else {
                    Object.entries(projects).forEach(([pk, pv], j) => {
                        if (j == 0) 
                            rows = [...rows, {...row, ...pv}];
                        else
                            rows = [...rows, {...pv}];
                    });
                }
                exportData = [...exportData, ...rows];

            });

            const params = {
                data: exportData,
                order: columnOrder,
                headers: columns.reduce((acc, c) => [...acc, c.header], []),
                target: exportType,
                currency: this.context.taimerAccount.currency,
                fileName: `${this.tr('Goal vs. Actual')} ${this.tr('by')} ${this.state.viewTypes.find(v => v.key == this.state.selectedViewType).label} ${moment(dateRange.startDate)?.format(this.context.taimerAccount.companyDateFormat)}`
            };

            DataHandler.postArrayBuffer({ url: `insightweeklyvsactualsliderexport` }, { ...params }, true).done((response) => {
                let type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
                if (exportType == 'csv') {
                    type = 'text/csv';
                }
                var blob = new Blob([response], {
                    type,
                });
    
                FileSaver.saveAs(blob, `${params.fileName}.${exportType}`);
            }).fail(() => {

            });

            this.setState({loading: false});
        });

    }

    render() {
        const { data, selectedMonth, selectedWeek, selectedViewType, sliderData, viewTypes } = this.state;
        const { months, monthTitles } = this.props;
        return (
            <>
                <InsightSlider
                    {...sliderData}
                    rowType={GoalVsActualSliderRow}
                    onBackPress={this.setSliderDefaultMode}
                    sharedData={{
                        setProjectMode: this.setSliderProjectMode,
                    }}
                    onClose={() =>
                        this.setState({
                            sliderData: { ...this.state.sliderData, open: false },
                        })
                    }
                />
                <InsightTable
                    {...this.props}
                    rowKey="id"
                    columns={this.getColumns()}
                    blockProps={{
                        rootClassName: 'block',
                        wrapperClass: 'block-wrapper',
                        headerClass: 'block-header',
                    }}
                    filterComponents={
                        <div className="filters">
                            <InsightDropDown
                                title={this.tr('Week')}
                                tabs={[
                                    {
                                        key: '-1',
                                        label: this.tr('Not selected'),
                                        action: () => {
                                            this.setState({
                                                selectedWeek: '-1',
                                            });
                                        },
                                    },
                                    ...this.getWeeksForSelectedMonth().map((w) => ({
                                        key: w.format('WW-YYYY'),
                                        label: w.format('WW'),
                                        action: () =>
                                            this.setState({
                                                selectedWeek: w.format('WW-YYYY'),
                                            }),
                                    })),
                                ]}
                                selected={selectedWeek}
                            />
                            <InsightDropDown
                                title={this.tr('Month')}
                                tabs={months.map((m) => ({
                                    key: m.format(this._monthKeyFormat),
                                    label: monthTitles[m.month() + 1],
                                    action: () => {
                                        this.setState({
                                            selectedMonth: m.format(this._monthKeyFormat),
                                            selectedWeek: '-1',
                                        });
                                    },
                                }))}
                                selected={selectedMonth}
                            />
                            <InsightDropDown title={this.tr('View')} tabs={viewTypes} selected={selectedViewType} />
                            <InsightExportButton 
                                title={this.tr('EXPORT')}
                                disabled={data?.length < 1}
                                loading={this.state.loading}
                                exportTypes={['xlsx', 'csv']}
                                action={this.export} />
                        </div>
                    }
                    className="goal-vs-actual"
                    title={this.tr('Goal vs. Actual')}
                    height={400}
                    hideDefaults={true}
                    showSlider={this.showSlider}
                    ignoreRowPropsChange={false}
                    rowProps={{
                        month: selectedMonth,
                        week: selectedWeek,
                        monthTitles,
                    }}
                    data={data}
                    rowType={GoalsVsActualRow}
                    currency={this.context.taimerAccount.currency}
                />
            </>
        );
    }
}
