import { format } from 'date-fns';
import getAccountColumns from './AccountColumns';
import getColumnType, { CalendarHeaderTemplate, ColumnDefinition, columnSorter } from './ColumnTypes';
import { CustomFields } from './CustomFields';
import getProjectColumns from './ProjectColumns';
import { filterColumns } from "./ColumnVisibility";
import Link from './../general/Link';

const columnOrder = [

];

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
    startDate: Date | null;
    endDate: Date | null;
    dateFormat: string;
    settingsContext: any;
}

export default function getAccountDataColumns(params: Params) {
    const calendarHeader = {
        tr: params.tr,
        startDate: params.startDate ? format(params.startDate, params.dateFormat) : null,
        endDate: params.endDate ? format(params.endDate, params.dateFormat) : null,
    }

    const columns: ColumnDefinition[] = [
        {
            field: 'company',
            headerName: 'Company',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'invoice_invoiced',
            headerName: 'Invoiced total',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'telephone',
            headerName: 'Phone',
            ...getColumnType('text'),
        },
        {
            field: 'email',
            headerName: 'Email',
            ...getColumnType('emailLink'),
        },
        {
            field: 'www',
            headerName: 'Website',
            ...getColumnType('textLink'),
        },
        {
            field: 'defaultpenaltyinterest',
            headerName: 'Interest rate',
            ...getColumnType('percentage'),
        },
        {
            field: 'defaultannotation',
            headerName: 'Notice period (d)',
            ...getColumnType('number'),
        },
        {
            field: 'defaulttermsofpayment',
            headerName: 'Payment term (d)',
            ...getColumnType('number'),
        },
        {
            field: 'hours_tracked',
            headerName: 'Tracked Hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_approved',
            headerName: 'Approved Hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_submitted',
            headerName: 'Submitted Hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_billable',
            headerName: 'Billable Hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_non_billable',
            headerName: 'Non Billable Hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_invoiced',
            headerName: 'Invoiced Hours Amount',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_invoiced_price',
            headerName: 'Invoiced Hours Price',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'hours_cost',
            headerName: 'Internal cost of hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'resourcing_allocated',
            headerName: 'Allocated',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'resourcing_resourced',
            headerName: 'Resourced',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'bill_bills',
            headerName: 'Bills',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'bill_bills_waiting',
            headerName: 'Waiting bills',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'bill_bills_invoiced',
            headerName: 'Invoiced bills',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'bill_purchase_orders',
            headerName: 'Purchase orders',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_expenses',
            headerName: 'Expenses',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_expenses_waiting',
            headerName: 'Waiting expenses',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_expenses_invoiced',
            headerName: 'Invoiced expenses',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_travel',
            headerName: 'Travel expenses',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_travel_waiting',
            headerName: 'Waiting travel expenses',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_travel_invoiced',
            headerName: 'Invoiced travel expenses',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_added_costs',
            headerName: 'Added costs',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_added_costs_invoiced',
            headerName: 'Invoiced added costs',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'costestimate_project_value',
            headerName: 'Project value',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'costestimate_budgeted_gross_margin',
            headerName: 'Budgeted gross margin',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'costestimate_budgeted_sales_margin',
            headerName: 'Budgeted sales margin',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'invoice_drafts_and_waiting',
            headerName: 'Drafts and waiting invoices',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'probable_value',
            headerName: 'Probable value',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'probable_gross_margin',
            headerName: 'Probable budgeted gross margin',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'probable_sales_margin',
            headerName: 'Probable budgeted sales margin',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_invoicing',
            headerName: 'Gross margin (invoicing)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_invoicing_percentage',
            headerName: 'Gross margin % (invoicing)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'gross_margin_invoicing', divisor: 'invoice_invoiced' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum',
            decimalAmount: 0
        },
        {
            field: 'gross_margin_invoicing_per_hour',
            headerName: 'Gross margin (invoicing) / hour',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            calculateGroupedRate: { dividend: 'gross_margin_invoicing', divisor: 'hours_approved' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum'
        },
        {
            field: 'sales_margin_invoicing',
            headerName: 'Sales margin (invoicing)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'sales_margin_invoicing_percentage',
            headerName: 'Sales margin % (invoicing)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'sales_margin_invoicing', divisor: 'invoice_invoiced' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum',
            decimalAmount: 0
        },
        {
            field: 'sales_margin_invoicing_per_hour',
            headerName: 'Sales margin (invoicing) / hour',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            calculateGroupedRate: { dividend: 'sales_margin_invoicing', divisor: 'hours_approved' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum'
        },
        {
            field: 'project_backlog',
            headerName: 'Backlog',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_revenue',
            headerName: 'Gross margin (revenue recognition)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_revenue_percentage',
            headerName: 'Gross margin % (revenue recognition)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'gross_margin_revenue', divisor: 'revenue_recognition_recognised' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum',
            decimalAmount: 0
        },
        {
            field: 'gross_margin_revenue_per_hour',
            headerName: 'Gross margin (revenue recognition) / hour',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            calculateGroupedRate: { dividend: 'gross_margin_revenue', divisor: 'hours_approved' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum'
        },
        {
            field: 'sales_margin_revenue',
            headerName: 'Sales margin (revenue recognition)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'sales_margin_revenue_percentage',
            headerName: 'Sales margin % (revenue recognition)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'sales_margin_revenue', divisor: 'revenue_recognition_recognised' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum',
            decimalAmount: 0
        },
        {
            field: 'sales_margin_revenue_per_hour',
            headerName: 'Sales margin (revenue recognition) / hour',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            calculateGroupedRate: { dividend: 'sales_margin_revenue', divisor: 'hours_approved' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum'
        },
        {
            field: 'material_total',
            headerName: 'Invoiceable Material',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'revenue_recognition_recognised',
            headerName: 'Recognised',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'revenue_recognition_estimated_costs',
            headerName: 'Estimated costs',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'project_budgeted_hours',
            headerName: 'Budgeted Hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            hide: true
        },
        ...getAccountColumns(params, true)
    ];

    columns.sort(columnSorter(columnOrder));
    
    return filterColumns(
        columns, 
        "accounts", 
        params.settingsContext
    );
}
