import React from 'react';
import TaimerComponent from '../TaimerComponent';
import SubtitleDialog from '../dialogs/SubtitleDialog';
import OutlinedField from "../general/OutlinedField";
import DataHandler from '../general/DataHandler';
import LoaderButton from "../general/LoaderButton";
import DataList from '../general/DataList';
import ErrorsList from '../dialogs/elements/ErrorsList';
import { DateRangePicker } from '../general/react-date-range/src';
import { data as errorData } from '../dialogs/elements/ErrorsList';
import { defaultFilterState } from './FiltersState';
import { ReactComponent as UserIcon } from '../navigation/ActionIcons/my_profile.svg';
import { ReactComponent as TeamIcon } from '../navigation/NavIcons/customers.svg';
import { Business } from '@mui/icons-material';
import { DateStringSpan } from '../general/DateTimeUtils';

import styles from './SaveTemplateDialog.module.scss';
import { FilterDataJson, ReportType, SharedToData, TemplateData, clearTemplateFromCache, reportNameToReportType, getTemplateData } from './TemplateUtils';
import { cloneDeep } from 'lodash';

interface Dropitem {
    id?: string|number;
    value?: string|number;
    label: string;
    name: string;
    headerName?: 'team'|'user'|'company';
    type?: 'team'|'user'|'company'|'header';
    icon?: React.ElementType;
    relation_type?: string|number;
    permission?: number;
    bgColor?: string;
}

export interface Template {
    id: number;
    name: string;
    description: string;
    users_id: number | string;
    shared_to?: SharedToData[]; // If shared_to is undefined in props template, shared_to data gets fetched when opening dialog.
}

export interface TemplateSaveData {
    name: string;
    description: string;
    is_public: number;
    data_json?: string; 
    report_type?: number;
}

interface Props {
    onClose: () => void;
    onSave: (template: TemplateData, onlyEdit?: boolean, isNew?: boolean) => void; 
    onCancel: (data?: Template) => void;
    filterState?: FilterDataJson;
    selectedTab: string,
    enqueueSnackbar: Function;
    template?: Template | null;
    autoCompleteData?: {
        groups?: any[];
        companies?: any[];
        users?: any[];
    },
    noFilterStateSave?: boolean;
    onlyEdit?: boolean;
    title?: string;
    subtitle?: string;
    saveButtonText?: string;
    dateSpan?: DateStringSpan;
    relativeDateSpan?: string | null;
    materialDateSpan?: DateStringSpan | null;
    materialRelativeDateSpan?: string | null;
    balanceStartDate?: Date | null;
    balanceEndDate?: Date | null;
    hideNotifications?: boolean;
    showReportDateRangePicker?: boolean;
    showMaterialDateRangePicker?: boolean;
    reportDateRangeTooltipContent?: string | null;
    showClearButtonForReportDateRange?: boolean;
    noDateSpanSelectionPlaceholder?: string;
}

interface State {
    name: string;
    description: string;
    creating: boolean;
    saving: boolean;
    errors: errorData[];
    warnings: errorData[];
    isOwn: boolean;
    report_type: number;
    shared_to: SharedToData[];
    dateSpan?: DateRangePickerValue;
    materialDateSpan?: DateRangePickerValue;
    relativeDateSpan?: string | null;
    materialRelativeDateSpan?: string | null;
    relativeBalanceDateRange?: string | null;
    balanceStartDate?: Date | null;
    balanceEndDate?: Date | null;
}

interface SaveResponse {
    is_public_saved: boolean;
    error: string;
    share_error: string;
}

interface CreateResponse {
    id: number|null;
    error: string;
    share_error: string;
    is_public_saved: boolean;
}

interface DateRangePickerValue {
    startDate: Date | undefined;
    endDate: Date | undefined;
    key: string;
}

export default class SaveTemplateDialog extends TaimerComponent<Props, State> {
    userColor: string;
    groupColor: string;
    originalSharedToData: SharedToData[] | [];

    constructor(props, context) {
        super(props, context, "new_reports/SaveTemplateDialog");

        this.userColor = "#2196F3";
        this.groupColor = "#003A78";
        this.originalSharedToData = [];

        this.state = {
            name: props.template?.name || "",
            description: props.template?.description || "",
            creating: false,
            saving: false,
            errors: [],
            warnings: [],
            isOwn: props.template?.users_id == context.userObject.usersId,
            report_type: reportNameToReportType(props.selectedTab),
            shared_to: [],
            dateSpan:  {
                startDate: !this.props.relativeDateSpan 
                    ? props?.dateSpan?.start
                    : null,
                endDate: !this.props.relativeDateSpan 
                    ? props?.dateSpan?.end
                    : null,
                key: "selection"
            },
            relativeDateSpan: this.props.relativeDateSpan,
            materialDateSpan:  {
                startDate: !this.props.materialRelativeDateSpan 
                    ? props?.materialDateSpan?.start
                    : null,
                endDate: !this.props.materialRelativeDateSpan 
                    ? props?.materialDateSpan?.end
                    : null,
                key: "selection"
            },
            materialRelativeDateSpan: this.props.materialRelativeDateSpan,
            balanceStartDate: props.balanceStartDate,
            balanceEndDate: props.balanceEndDate,
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.getTemplateSharedToData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.autoCompleteData?.users?.length != this.props.autoCompleteData?.users?.length) {
            this.getTemplateSharedToData();
        }
    }

    getTemplateSharedToData = async () => {
        const { autoCompleteData, template } = this.props;
        const users = autoCompleteData?.users || [];

        if (!this.state.isOwn) {
            return;
        }

        let sharedToData = template?.shared_to;
        if (sharedToData === undefined && template?.id) {
            const templateData = await getTemplateData(template.id);
            sharedToData = templateData?.shared_to;
        }

        const shared_to: SharedToData[] = (sharedToData || []).map<SharedToData>(s => {
            if (s.relation_type == -1) {
                s.name = this.tr("Everyone");
                s.id = "-1";
            }
            else {
                s.id = s.relation_type + "_" + s.entity_id; // Add unique id.
            }

            if (s.relation_type == 0) {
                const userData = users.find(u => u.id == s.entity_id) || {};            
                s.permission = userData.permission;
                s.additionalText = this.getPermissionText(s.permission);
                s.bgColor = this.userColor;
            }
            else {
                s.bgColor = this.groupColor;
            }

            s.value = s.id;
            s.label = s.name;

            return s;
        })

        this.originalSharedToData = shared_to;

        this.setState({ shared_to }, () => this.setPermissionWarnings());
    }

    saveRelations = (templateId: number, is_public_saved: boolean, share_error: string) => {
        const { enqueueSnackbar } = this.props;
        const { shared_to } = this.state;

        // Filter out Everyone share (value = -1). It gets saved as is_public in template save.
        const data = (shared_to || []).filter(s => s.value != -1).map(s => { 
            return {
                relation_type: s.relation_type,
                entity_id: s.value?.toString().slice(2) // value has relation_type followed by underscore to make them unique. Take entity_id after those.
            }
        });

        DataHandler.post({ url: `new_reports/templates/${templateId}/relations/save` }, { relations: data, is_public_saved }).done((resp: SaveResponse) => {
            if (share_error || resp.error) {
                const message = this.tr("No permission");
                enqueueSnackbar && enqueueSnackbar(this.tr('Error in sharing report: ${message}.', { message }), {
                    variant: 'error'
                });
            }
        });
    }

    isTemplatePublic = () => {
        return this.state.shared_to?.find(s => s.value == -1);
    }

    createTemplate = () => {
        const { filterState, enqueueSnackbar, onClose, hideNotifications } = this.props;
        const { name, description, report_type } = this.state;

        const templateData = {
            ...filterState,
            dateSpan: {
                start: this.state.dateSpan?.startDate,
                end: this.state.dateSpan?.endDate,
            },
            materialDateSpan: {
                start: this.state.materialDateSpan?.startDate,
                end: this.state.materialDateSpan?.endDate,
            },
            relativeRange: this.state.relativeDateSpan,
            materialRelativeRange: this.state.materialRelativeDateSpan,
            relativeBalanceDateRange: this.state.relativeBalanceDateRange,
            balanceStartDate: this.state.balanceStartDate,
            balanceEndDate: this.state.balanceEndDate,
        };

        const data: TemplateSaveData = {
            name,
            description,
            data_json: JSON.stringify(templateData),
            report_type,
            is_public: this.isTemplatePublic() ? 1 : 0
        }

        this.setState({ creating: true, errors: [] });

        DataHandler.post({ url: "new_reports/templates" }, data).done((resp: CreateResponse) => {
            enqueueSnackbar && !hideNotifications && enqueueSnackbar(this.tr('Template created successfully!'), {
                variant: 'success'
            });

            onClose && onClose();
            resp?.id && this.onSave(resp.id, true);
            resp?.id && this.saveRelations(resp.id, resp.is_public_saved, resp.share_error);
            this.setState({ creating: false });

            window.dispatchEvent(new CustomEvent('reportsTemplateCreated'));
        })
        .fail((err) => {
            this.setState({ creating: false });
            this.setError(err?.responseJSON?.error);
        });
    }

    saveTemplate = () => {
        const { filterState, enqueueSnackbar, onClose, template, noFilterStateSave, hideNotifications } = this.props;
        const { name, description } = this.state;

        const data: TemplateSaveData = {
            name,
            description,
            is_public: this.isTemplatePublic() ? 1 : 0
        }

        if (!noFilterStateSave) {
            data.data_json = JSON.stringify(filterState)
        }

        this.setState({ saving: true, errors: [] });
        template && clearTemplateFromCache(template.id);

        DataHandler.patch({ url: "new_reports/templates/" + template?.id }, data).done((resp: SaveResponse) => {
            enqueueSnackbar && !hideNotifications && enqueueSnackbar(this.tr('Template saved successfully!'), {
                variant: 'success'
            });

            onClose && onClose();
            template?.id && this.onSave(template.id);
            template?.id && this.saveRelations(template.id, resp.is_public_saved, resp.share_error);
            this.setState({ saving: false });

            window.dispatchEvent(new CustomEvent('reportsTemplateCreated'));
        })
        .fail((err) => {
            this.setState({ saving: false });
            this.setError(err?.responseJSON?.error);
        });
    }

    formTemplateData = (id = -1, isNew = false): TemplateData => {
        const { template } = this.props;
        const { name, description, report_type } = this.state;

        const templateData: TemplateData = {
            ...template,
            name,
            description,
            shared_to: undefined, // Put shared_to undefined, so shared_to data gets fetched when dialog is opened again before refreshing page.
            id: id,
            users_id: this.context.userObject.usersId,
            report_type,
            date_span: {
                start: this.state.dateSpan?.startDate,
                end: this.state.dateSpan?.endDate,
            },
            relative_range: this.state.relativeDateSpan,
            material_date_span: {
                start: this.state.materialDateSpan?.startDate,
                end: this.state.materialDateSpan?.endDate,
            },
            material_relative_range: this.state.materialRelativeDateSpan,
            isNew
        }
    
        return templateData;
    }

    onSave = (id: number, isNew = false) => {
        const { onSave, filterState, noFilterStateSave, onlyEdit } = this.props;

        const savedData: TemplateData = this.formTemplateData(id, isNew);

        if (!noFilterStateSave) {
            savedData.data_json =  filterState ?? {
                filterState: defaultFilterState,
            }
        }

        onSave && onSave(savedData, onlyEdit, isNew);
    }

    setError = (error) => {
        const { name } = this.state;
        let msg = this.tr("Failed to save report: Unknown error.");
        let header = "undefined";

        switch (error) {
            case 'REQUIRED_FIELDS_MISSING':
                msg = this.tr("Required fields not filled.");
                break;
            case 'NAME_ALREADY_EXISTS':
                header = "name";
                msg = this.tr("A report called “${report_name}” already exists. Please rename the report template.", { report_name: name });
                break;
        }

        const errors = [{ message: msg, header: header }];
        this.setState({ errors });
    }

    setPermissionWarnings = () => {
        const { shared_to } = this.state;
        const warnings: errorData[] = [];
        (shared_to || []).forEach(s => {
            switch (s.permission) {
                case 1:
                    warnings.unshift({
                        message: this.tr("${name} does not have the necessary permission to view this report. Please contact the admin user.", {name: s.name})
                    });
                    break;
                case 2:
                    warnings.unshift({
                        message: this.tr("${name} has limited permission to view this report.", {name: s.name})
                    });
                    break;
            }
        });
        this.setState({ warnings });
    }

    getSharedToOptions = () => {
        const {
            taimerAccount: { isMulticompany },
        } = this.context;
        const { autoCompleteData } = this.props;

        const allCompanies = autoCompleteData?.companies || [];
        const allowedCompanies: any[] = [];

        const shareRightCompanies = this.context.privileges?.new_reports?.share_templates_write || [];

        allCompanies.forEach(c => {
            if (shareRightCompanies.find(s => s == c.id)) {
                allowedCompanies.push(c);
            }
        })

        const hasUserRight = (u) => {
            // Show freelancer if share right to atleast one company.
            if (u.companyId == 0) { 
                return allowedCompanies?.length > 0;
            }
            return allowedCompanies.find(a => a.id == u.companyId);
        }

        // Get users and teams only from companies where auth user has users_read and share_template privileges to.
        const allowedUsers = (autoCompleteData?.users || []).filter(u => hasUserRight(u));
        const allowedTeams = (autoCompleteData?.groups || []).filter(g => allowedCompanies.find(a => a.id == g.companies_id));

        const users = allowedUsers.map<Dropitem>(u => {
            const id = u.id;
            return {
                id: "0_" + id,
                value: "0_" + id,
                label: u.name,
                name: u.name,
                relation_type: "0",
                headerName: "user",
                type: "user",
                additionalText: this.getPermissionText(u.permission),
                permission: u.permission,
                bgColor: this.userColor
            };
        });

        const groups = allowedTeams.map<Dropitem>(g => {
            const id = g.id;
            return {
                id: "1_" + id,
                value: "1_" + id,
                label: g.name,
                name: g.name,
                relation_type: "1",
                headerName: "team",
                type: "team",
                bgColor: this.groupColor
            };
        });

        const companies = !isMulticompany ? [] : allowedCompanies.map<Dropitem>(c => {
            const id = c.id;
            return {
                id: "2_" + id,
                value: "2_" + id,
                label: c.name,
                name: c.name,
                relation_type: "2",
                headerName: "company",
                type: "company",
                bgColor: this.groupColor
            };
        });

        const options: Dropitem[] =
            [
                { icon: Business, type: "header", label: this.tr('Companies'), name: 'company' },
                ...companies,
                { icon: TeamIcon, type: "header", label: this.tr('Teams'), name: 'team' },
                ...groups,
                { icon: UserIcon, type: "header", label: this.tr('Users'), name: 'user' },
                ...users
            ];

        //Add "Everyone" option if multicompany and permission to share for all companies.
        if ((!isMulticompany || allowedCompanies.length > 1) && allCompanies.length == allowedCompanies.length) {
            options.unshift(
                {
                    id: "-1",
                    value: "-1",
                    label: this.tr("Everyone"),
                    name: this.tr("Everyone"),
                    relation_type: "-1",
                    bgColor: this.groupColor
                }
            );
        }

        return options;
    }

    /**
     * Gets text to be added after user name indicating if user doesn't have necessary permissions for report.
     * @param number permission 0 = Permission to all, 1 = No permission, 2 = Limited permission.
     * @returns string text to be added after user name.
     */
    getPermissionText = (permission) => {
        let text = "";
        switch (permission) {
            case 1:
                text = " (" + this.tr("No permission") + ")";
                break;
            case 2:
                text = " (" + this.tr("Limited permission") + ")";
                break;
        }
        return text;
    }

    // TODO: remove the duplication.
    
    handleTimeSpanChange = (e) => {
        const { 
            startDate, 
            endDate 
        } = e.selection;

        this.setState({
            dateSpan: {
                startDate: startDate,
                endDate: endDate,
                key: "selection"
            },
            relativeDateSpan: null
        });
    }

    handleRelativeRangeChange = (relativeRange: string) => {
        this.setState({
            dateSpan: {
                startDate: undefined,
                endDate: undefined,
                key: "selection"
            },
            relativeDateSpan: relativeRange
        });
    }

    handleDatePickerInputChange = (dateType: string, date: Date) => {
        const dateSpan: DateRangePickerValue | undefined = cloneDeep(this.state.dateSpan);

        if(!dateSpan) {
            return;
        }

        dateSpan[dateType === "start" ? "startDate" : "endDate"] = date;

        this.setState({ 
            dateSpan,
            relativeDateSpan: null
        });
    }

    handleMaterialTimeSpanChange = (e) => {
        const { 
            startDate, 
            endDate 
        } = e.selection;

        this.setState({
            materialDateSpan: {
                startDate: startDate,
                endDate: endDate,
                key: "selection"
            },
            materialRelativeDateSpan: null
        });
    }

    handleMaterialRelativeRangeChange = (relativeRange: string) => {
        this.setState({
            materialDateSpan: {
                startDate: undefined,
                endDate: undefined,
                key: "selection"
            },
            materialRelativeDateSpan: relativeRange
        });
    }

    handleMaterialDatePickerInputChange = (dateType: string, date: Date) => {
        const dateSpan: DateRangePickerValue | undefined = cloneDeep(this.state.materialDateSpan);

        if(!dateSpan) {
            return;
        }

        dateSpan[dateType === "start" ? "startDate" : "endDate"] = date;

        this.setState({ 
            materialDateSpan: dateSpan,
            materialRelativeDateSpan: null
        });
    }

    renderContent = () => {
        const { 
            name, 
            description, 
            errors, 
            warnings, 
            shared_to 
        } = this.state;

        const {
            showReportDateRangePicker,
            showMaterialDateRangePicker
        } = this.props;

        const sharedToOptions = this.getSharedToOptions();
        const nonHeaderOptions = sharedToOptions.filter(s => s.type != "header");

        // Show sharedTo-field also if no permission if report has been shared. So shares can be removed.
        const showSharedToField = nonHeaderOptions?.length > 0 || (shared_to || []).length > 0;

        return <div>
             {warnings.length > 0 && <ErrorsList
                type={"warning"}
                data={warnings}
                hideHeader={true}
                shownRowsAmount={1}
            />}
            {errors.length > 0 && warnings.length > 0 && <div className={styles.errorsListSpace}></div>}
            {errors.length > 0 && <ErrorsList
                type={"error"}
                data={errors}
                hideHeader={true}
            />}
            {showReportDateRangePicker && <DateRangePicker 
                label={this.tr("Report timespan") + "*"}
                className={styles.dateRangePicker}
                dateSeparatorClassName={styles.dateSeparator}
                dateRangePickerPopperCalendarViewClassName={styles.dateRangePickerPopperCalendarView}
                inputWrapperClassName={styles.dateRangePickerInputWrapper}
                datePickerInputClassName={styles.datePickerInput}
                dateFormat={this.context.userObject.dateFormat}
                onChange={this.handleTimeSpanChange}
                onInputChange={this.handleDatePickerInputChange}
                onRelativeRangeSelect={this.handleRelativeRangeChange}
                ranges={[this.state.dateSpan]}
                relativeRange={this.state.relativeDateSpan}
                showRelative={true}
                infoTooltipContent={this.props?.reportDateRangeTooltipContent ?? null}
                showClearButton={this.props.showClearButtonForReportDateRange}
                noSelectionPlaceholder={this.props.noDateSpanSelectionPlaceholder ?? ""}
            />}
            {showMaterialDateRangePicker && <DateRangePicker 
                label={this.tr("Material timespan") + "*"}
                className={styles.dateRangePicker}
                dateSeparatorClassName={styles.dateSeparator}
                dateRangePickerPopperCalendarViewClassName={styles.dateRangePickerPopperCalendarView}
                inputWrapperClassName={styles.dateRangePickerInputWrapper}
                datePickerInputClassName={styles.datePickerInput}
                dateFormat={this.context.userObject.dateFormat}
                onChange={this.handleMaterialTimeSpanChange}
                onInputChange={this.handleMaterialDatePickerInputChange}
                onRelativeRangeSelect={this.handleMaterialRelativeRangeChange}
                ranges={[this.state.materialDateSpan]}
                relativeRange={this.state.materialRelativeDateSpan}
                showRelative={true}
            />}
            <OutlinedField
                autoFocus={true}
                label={this.tr("Report name") + " *"}
                onKeyUp={(e) => {
                    const val = e.target?.value?.trim();
                    if (val == name) {
                        return;
                    }
                    this.setState({ name: val, errors: [] })
                }}
                name="report_name"
                value={this.state.name}
                fullWidth={true}
            />
            <OutlinedField
                label={this.tr("Report description") + " *"}
                onKeyUp={(e) => {
                    const val = e.target?.value?.trim();
                    if (val == description) {
                        return;
                    }
                    this.setState({ description: val, errors: errors.filter(e => e.header != "undefined") })
                }}
                name="report_description"
                value={this.state.description}
                fullWidth={true}
            />
            {showSharedToField && <DataList
                menuWidth="100%"
                key={"shared_to"}
                name={"shared_to"}
                label={this.tr("Shared to")}
                hasHeaders={true}
                options={this.getSharedToOptions()}
                value={shared_to}
                onChange={(e) => this.setState({ shared_to: e }, () => this.setPermissionWarnings())}
                isMulti={true}
                virtualized={true}
                showOptionTitle={true}
            />}
        </div>
    }

    renderSaveButton = () => {
        const { template, onlyEdit } = this.props;
        const { isOwn, name, description, saving } = this.state;

        const buttonText = onlyEdit
            ? this.tr("Save")
            : this.tr("Save report");

        if (template?.id && isOwn) {
            return <LoaderButton
                data-testid="button-save"
                notAllowed={!name || !description}
                loading={saving}
                onClick={(e) => {
                    this.saveTemplate();
                    e.stopPropagation();
                }}
                variant={"outlined"}
                className={"blue"}
                text={buttonText}
            />
        }
        return undefined;
    }

    render() {
        const { onClose, onlyEdit, template, onCancel } = this.props;
        const { creating, name, description, isOwn } = this.state;

        const title = this.props?.title ?? (onlyEdit 
            ? this.tr('Edit report details')
            : this.tr('Save report'));

        let subtitle = this.props?.subtitle ?? (isOwn 
            ? this.tr('You can choose to either save the edits to the current report or to save them as a new report.')
            : this.tr('Before creating the report, you need to give it a name. After creating, you can access it later from the “Report gallery” view.'));

        if (onlyEdit && !this.props.subtitle) {
            subtitle = this.tr('Here you can edit the basic details of your report.');
        }

        return (
            <SubtitleDialog
                onDialogClose={() => {
                    onClose && onClose();
                    onCancel && onCancel(
                        template ? { // Return original props template when closed. Add also shared to data (might not exist in props template if has been fetched in this dialog).
                            ...template,
                             shared_to: this.originalSharedToData 
                        } 
                        : undefined
                    )
                }}
                onConfirm={() => {
                    this.createTemplate();
                }}
                title={title}
                subtitle={subtitle}
                confirmHidden={onlyEdit}
                confirmButtonText={this.props?.saveButtonText ?? this.tr("Save as new")}
                confirmButtonLoading={creating}
                confirmDisabled={!name || !description}
                additionalButton={this.renderSaveButton()}
            >
                {this.renderContent()}
            </SubtitleDialog>
        );
    }
}

export {
    type Props
}
