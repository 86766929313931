import React from 'react';
import TaimerComponent from '../../../TaimerComponent';
import cn from 'classnames';
import styles from './GridView.module.scss';
import { ViewSettings, ResourcingSubviewProps, ExpandData } from '../../ResourcingView';
import { formatDataForGrid, GridRow, GridRowTask } from './helpers';
import Grid from './components/Grid';
import _ from 'lodash';
import { GridViewColumn } from './columns';
import { Resource } from '../../resourcing';

import { ReactComponent as AddProjectIcon } from "../ResourcingGrid/components/img/AddProject.svg";
import { MenuItem } from "../ResourcingGrid/components/GridControls";
import AddUserToProjectPopup from '../ResourcingGrid/components/AddUserToProjectPopup';
import AddTeamMembersPopUp from "../ResourcingGrid/components/AddTeamMembersPopUp";
import ConfirmationPopUp from "../ResourcingGrid/components/ConfirmationPopUp";


import {
	RemoveRedEye,
	CheckBox,
	MoreHoriz,
	LibraryAdd,
	PersonAdd,
	Chat,
	AccessTime,
	Delete,
} from "@mui/icons-material";
import { Popover } from '@mui/material';
import DataHandler from '../../../general/DataHandler';
import tzmoment from 'moment-timezone';
import { format, parse } from 'date-fns';

interface GridViewProps extends ResourcingSubviewProps {
    settings: ViewSettings;
    columns: GridViewColumn[];
    startdate: Date;
    enddate: Date;
    expandedRows: Dictionary<boolean>;
    setExpandedRows: (rows: Dictionary<boolean>) => void;
}

interface GridViewState {
    data: GridRow[];
    popUpComponent?: any;
    hoverMenuAnchor?: any;
    anchorEl?: any;
    hoverMenuOpen: boolean;
}

class GridView extends TaimerComponent<GridViewProps, GridViewState> {
    grid: React.RefObject<Grid>;

    constructor(props: GridViewProps, context) {
        super(props, context, "resourcing/views/ResourcingGrid/ResourcingGrid");

        this.state = {
            data: this._getFormattedRows(),
            hoverMenuOpen: false,
        };

        this.grid = React.createRef();
    }

    componentDidMount(): void {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: GridViewProps, prevState: GridViewState) {
        const { settings } = this.props;
        let taskUpdated = false;

        const fields = [
            'projects',
			'viewOptions',
			'availability',
        ];

        const updateFields = {
            totalData: true,
            resources: true,
            projects: true,
			viewOptions: true,
			availability: true,
        };

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            if (!_.isEqual(prevProps[field], this.props[field])) {
                if (updateFields[field]) {
                    taskUpdated = true;
                    break;
                }
            }
        }

		// We might have changed from original data
		if (prevProps.resources !== this.props.resources) {
            taskUpdated = true;
		}

        if (prevProps.settings.grouping !== settings.grouping) {
            taskUpdated = true;
        }

        if (taskUpdated) {
            this.setState({
                data: this._getFormattedRows(),
            });
        }
    }

    _getFormattedRows = () => {
		const { taimerAccount: { allow_resourcing_to_project } } = this.context;
        const { 
			filters, resources, projects, availability, 
			settings: { grouping }, autocomplete: { employees }, 
			usersAllowedToShow, viewOptions,
		} = this.props;

        const formattedRows = formatDataForGrid({
			filters,
            unassignedLabel: this.tr('Unassigned'),
            employees: _.keyBy(employees, x => x.id),
            grouping,
            projects,
            resources,
			viewOptions,
			availability,
			useProjectTasks: allow_resourcing_to_project,
			usersAllowedToShow,
        });

        return formattedRows;
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
    }

    openTaskDialog = (task: Partial<Resource>) => {
        this.context.functions.addResource(task, {
			onProjectRangeExtended: this.props.onProjectRangeExtended,
		});
    }

    _createTask = row => {
		const { newTaskExtra } = this.props;
		this.openTaskDialog({
			companies_id: row.project && row.project.companies_id,
			projects_id: row.project && row.project.projects_id,
			...newTaskExtra,
			//@ts-ignore
			users_id: row.user && row.user.id,
			users_hours: row.user && row.user.id ? [
				{ id: -1, users_id: row.user.id }
			] : [],
		});
	};

    save = async (rows, options) => {
		const dateFormat = this.context.userObject.dateFormat;

		const { enqueueSnackbar, autocomplete: { company } } = this.props;

		try {
			const data = await DataHandler.post(
				{ url: `resourcing/grid/setHours`, company: company },
				{
					timezone: tzmoment.tz.guess(),
					values: rows,
					options,
				}
			);

			if (data.status === 'OK') {
				enqueueSnackbar(this.tr(`Saved successfully!`), {
					variant: "success"
				});

				if (data.info) {
					const infoLines: React.ReactNode[] = [];

					for (const item of data.info) {
						if (item.type === 'PROJECT_EXTEND') {
							infoLines.length && infoLines.push(<br />);
							infoLines.push(this.tr('Project "${project}" was extended to match timing of task.', {
								project: item.project,
							}));
						}
					}

					if (infoLines.length)
						enqueueSnackbar(infoLines, {
							variant: "info"
						});
				}
			} else if (data.status === 'WARNING') {
				const warningLines: React.ReactNode[] = [];

				for (const item of data.warnings) {
					const range = item.start && item.end ? (item.start == item.end ?
						format(parse(item.start, 'YYYY-MM-DD', new Date(0)), dateFormat) :
						`${format(parse(item.start, 'YYYY-MM-DD', new Date(0)), dateFormat)} - ${format(parse(item.end, 'YYYY-MM-DD', new Date(0)), dateFormat)}`) : '';

					if (item.type === 'NO_WORKHOURS') {
						warningLines.push(<br />);
						warningLines.push(this.tr('${user} on ${range} is not working day', {
							user: item.user,
							range,
						}));
					} else if (item.type === 'PROJECT_NO_WRITE') {
						warningLines.push(<br />);
						warningLines.push(this.tr('No permission to edit project "${project}". Can\'t add task outside projects current range.', {
							project: item.project,
						}));
					} else if (item.type === 'locked') {
						warningLines.push(<br />);
						warningLines.push(this.tr('${user} on date ${range} is marked as done and can\'t be edited', {
							user: item.user,
							range,
						}));
					} else if (item.type === 'workhours') {
						warningLines.push(<br />);
						warningLines.push(this.tr('User ${user} has workhours logged can \'t be removed!', {
							user: item.user,
						}));
					}
				}

				enqueueSnackbar([this.tr(`Some changes were not saved: `), ...warningLines], {
					variant: "warning"
				});
			} else if (data.status === 'ERROR' && data.error === 'workhours') {
				enqueueSnackbar(this.tr(`Tasks with workhours logged can't be removed!`), {
					variant: "error"
				});
			} else if (data.status === 'ERROR' && data.error === 'LOCKED_SUBTASK') {
				enqueueSnackbar(this.tr(`This task can't be changed from grid`), {
					variant: "error"
				});
			} else {
				enqueueSnackbar(this.tr(`Saving failed!`), {
					variant: "error"
				});
			}
		} catch (error) {
			enqueueSnackbar(this.tr(`Saving failed!`), {
				variant: "error"
			});
		}

        this.props.updateResourcingData();

		return;
	};

    _showAddProject = (row: GridRow) => {
		const { autocomplete: { company }, startdate, enddate } = this.props;

		const popUpComponent = (
			<AddUserToProjectPopup
				header={this.tr("Add users to project team")}
				subtitle={row.user?.name}
				onSave={project => {
					this._closePopup();
					this._saveProjectTeam(project.id, [row.user?.id]);
				}}
				onCancel={this._closePopup}
				user={row}
				company={company}
				start={startdate}
				end={enddate}
			/>
		);
		this.setState({
			popUpComponent,
			hoverMenuOpen: false,
			hoverMenuAnchor: null
		});
	};

    _showAddTeamMemberPopUp = row => {
		const { autocomplete: { employees } } = this.props;
		const usersToShow = employees.filter(user => !row.project.users[user.id])
		const popUpComponent = (
			<AddTeamMembersPopUp
				header={this.tr("Add users to project team")}
				subtitle={row.project.projects_name}
				onSave={users => {
					this._closePopup();
					this._saveProjectTeam(row.project.projects_id, users);
				}}
				onCancel={this._closePopup}
				users={usersToShow}
			/>
		);
		this.setState({
			popUpComponent,
			hoverMenuOpen: false,
			hoverMenuAnchor: null
		});
	};
	
	_saveProjectTeam = (projectId, team) => {
		const { enqueueSnackbar } = this.props;
		DataHandler.put(
			{ url: `projects/${projectId}/team` },
			{
				team_members: team,
				add: 1
			}
		)
			.done(() => {
				this.props.updateResourcingData();
				// this.props.onTeamChange && this.props.onTeamChange();
				enqueueSnackbar(this.tr(`Project team saved successfully!`), {
					variant: "success"
				});
			})
			.catch(() => {
				enqueueSnackbar(this.tr(`Saving project team failed!`), {
					variant: "error"
				});
			});
	};

    _assignTask = (row: GridRowTask) => {
		const { autocomplete: { employees } } = this.props;

		const usersToShow = [...employees];
		const popUpComponent = (
			<AddTeamMembersPopUp
				header={this.tr("Assign Task")}
				subtitle={row.task?.description}
				single
				onSave={async users => {
					this._closePopup();
					
					await DataHandler.post({ url: `resourcing/assign_task/${row.task?.task_id}` }, {
						user: users[0],
					});

					this.props.updateResourcingData();
				}}
				onCancel={this._closePopup}
				users={usersToShow}
			/>
		);
		this.setState({
			popUpComponent,
			hoverMenuOpen: false,
			hoverMenuAnchor: null
		});
	}

    _closePopup = () => {
		this.setState({ popUpComponent: null });
	};

    // Hovers
	_renderUserRowHover = (row: GridRow, extra: JSX.Element|undefined) => {
		const { allowCreate } = this.props;
		return (
			<div className={styles["hover-button-container"]}>
				{allowCreate && <span title={this.tr("Add Task")}><LibraryAdd
					onClick={(e) => { e.stopPropagation(); this._createTask(row) }}
					className={styles["hover-button"]}
				/></span>}
				{(row.user?.id ?? 0) > 0 && <span title={this.tr("Add Project")} onClick={(e) => { e.stopPropagation(); this._showAddProject(row) }}>
					<AddProjectIcon className={cn(styles["hover-button"], styles['plus'])} />
				</span>}
			</div>
		);
	}

    _openTeamchat = row => {
		const { functions: { openTeamChat } } = this.context;

		openTeamChat({
			module: "projects",
			action: "view",
			id: row.project.projects_id,
		});
	};

    _goToProject = (row, new_tab) => {
		this.context.functions.updateView(
			{ module: "projects", action: "view", id: row.project.projects_id },
			new_tab
		);
	};

    _renderProjectRowHover = (row: GridRow, extra: JSX.Element|undefined) => {
        const { allowCreate } = this.props;
		const {
			userObject: { usersId }
		} = this.context;

		return (
			<div className={cn(styles["hover-button-container"], Number(usersId) === row.user?.id && styles["hover-shifted"])}>
				{!row.task && allowCreate && <span title={this.tr("Add Task")}><LibraryAdd
					onClick={(e) => { e.stopPropagation(); this._createTask(row) }}
					className={styles["hover-button"]}
				/></span>}
				{!row.task && <span title={this.tr("Add users to project team")}><PersonAdd
					onClick={(e) => { e.stopPropagation(); this._showAddTeamMemberPopUp(row) }}
					className={styles["hover-button"]}
				/></span>}
				{!row.task && row.user?.id === Number(usersId) && (
					<span title={this.tr("Open Team Chat")}><Chat
						onClick={(e) => { e.stopPropagation(); this._openTeamchat(row) }}
						className={styles["hover-button"]}
					/></span>
				)}
				<span title={this.tr("More")}><MoreHoriz
					onClick={e => {
						e.stopPropagation();
						this.setState({
							hoverMenuOpen: true,
							hoverMenuAnchor: e.currentTarget
						});
					}}
					className={styles["hover-button"]}
				/></span>
				<Popover
					onClose={this._closeRowPopupMenu}
					open={this.state.hoverMenuOpen}
					anchorEl={this.state.hoverMenuAnchor}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left"
					}}
				>
					<div className="grid-control-button-menu">
						<MenuItem
							item={{
								Icon: RemoveRedEye,
								text: this.tr("View project"),
								onMouseUp: evt => this._goToProject(row, evt.button === 1)
							}}
						/>
					</div>
				</Popover>
			</div>
		);
	};

    _markOwnHoursAsDone = row => {
		this._closeRowPopupMenu();
		const { enqueueSnackbar } = this.props;
		const { userObject } = this.context;
		const data = {
			users_id: userObject.usersId
		};
		DataHandler.post({ url: `resourcing/mark_hours_done/${row.task.task_id}` }, data)
			.done(() => {
				this.props.updateCompomentData();
				enqueueSnackbar(this.tr(`Own hours marked as done!`), {
					variant: "success"
				});
			})
			.catch(() => {
				enqueueSnackbar(this.tr(`Saving task failed!`), {
					variant: "error"
				});
			});
	};

	_markOwnHoursAsUndone = row => {
		this._closeRowPopupMenu();
		const { enqueueSnackbar } = this.props;
		const { userObject } = this.context;
		const data = {
			users_id: userObject.usersId
		};
		DataHandler.post({ url: `resourcing/mark_hours_undone/${row.task.task_id}` }, data)
			.done(() => {
				this.props.updateCompomentData();
				enqueueSnackbar(this.tr(`Own hours marked as undone!`), {
					variant: "success"
				});
			})
			.catch(() => {
				enqueueSnackbar(this.tr(`Saving task failed!`), {
					variant: "error"
				});
			});
	};

	_markTaskAsDone = (row: GridRowTask) => {
		this._closeRowPopupMenu();
		const { enqueueSnackbar } = this.props;
		DataHandler.post({ url: `resourcing/mark_task_done/${row.task?.id}` })
			.done(() => {
				this.props.updateCompomentData();
				enqueueSnackbar(this.tr(`Task marked as done!`), {
					variant: "success"
				});
			})
			.catch(() => {
				enqueueSnackbar(this.tr(`Saving task failed!`), {
					variant: "error"
				});
			});
	};

	_markTaskAsUndone = (row: GridRowTask) => {
		this._closeRowPopupMenu();
		const { enqueueSnackbar } = this.props;

		DataHandler.post({ url: `resourcing/unlock_task/${row.task?.id}` })
			.done(() => {
				this.props.updateCompomentData();
				enqueueSnackbar(this.tr(`Task marked as undone!`), {
					variant: "success"
				});
			})
			.catch(() => {
				enqueueSnackbar(this.tr(`Saving task failed!`), {
					variant: "error"
				});
			});
	};

    _showDeleteConfirmation = (row: GridRowTask) => {
		const popUpComponent = (
			<ConfirmationPopUp
				header={this.tr("Are you sure?")}
				desc={this.tr(
					"Are you sure you want to remove this task? Removing this deletes data for this task across all your accounts. This change cannot be undone."
				)}
				leftButtons={[
					{
						label: this.tr("Cancel"),
						style: { backgroundColor: "transparent", color: "#34495e" },
						onClick: this._closePopup
					}
				]}
				rightButtons={[
					{
						label: this.tr("Delete"),
						style: { backgroundColor: "#dc4054", color: "white" },
						onClick: () => {
							if ((row.task?.recurrence_parent_id ?? 0) > 0) {
								this._showDeleteSelections(row);
							} else {
								this._closePopup();
								this._deleteTask(row);
							}
						}
					}
				]}
			/>
		);
		this.setState({
			popUpComponent,
			hoverMenuOpen: false,
			hoverMenuAnchor: null
		});
	};

    _deleteTask = (row: GridRowTask, deleteInstances = "this") => {
		const { enqueueSnackbar } = this.props;
		DataHandler.delete({
			url: `resourcing/delete_task/${row.task?.task_id}`,
			delete_others: deleteInstances,
		})
			.done((response) => {
				this.props.updateCompomentData();
				if (response.success) {
					enqueueSnackbar(this.tr(`Task deleted successfully!`), {
						variant: "success",
					});
				} else if (response.error === 'workhours') {
					enqueueSnackbar(this.tr(`Tasks with workhours logged can't be removed!`), {
						variant: "error",
					});
				} else {
					enqueueSnackbar(this.tr(`Error while deleting.`), {
						variant: "error",
					});
				}
			})
			.catch(() => {
				enqueueSnackbar(this.tr(`Deleting task failed!`), {
					variant: "error"
				});
			});
	};

    _showDeleteSelections = (row: GridRowTask) => {
		const popUpComponent = (
			<ConfirmationPopUp
				header={this.tr(
					"Which instances of this task would you like to delete?"
				)}
				desc={null}
				leftButtons={[
					{
						label: this.tr("Cancel"),
						style: { backgroundColor: "transparent", color: "#34495e" },
						onClick: this._closePopup
					}
				]}
				rightButtons={[
					{
						label: this.tr("All"),
						style: { backgroundColor: "#2d9ff7", color: "white" },
						onClick: () => {
							this._closePopup();
							this._deleteTask(row, "all");
						}
					},
					{
						label: this.tr("Future"),
						style: { backgroundColor: "#2d9ff7", color: "white" },
						onClick: () => {
							this._closePopup();
							this._deleteTask(row, "future");
						}
					},
					{
						label: this.tr("Only this"),
						style: { backgroundColor: "#2d9ff7", color: "white" },
						onClick: () => {
							this._closePopup();
							this._deleteTask(row);
						}
					}
				]}
			/>
		);
		this.setState({
			popUpComponent
		});
	};

    _renderTaskRowHover = (row: GridRowTask, extra: JSX.Element|undefined) => {
		const {
			userObject: { usersId }
		} = this.context;

		const isOwn = row.user?.id === Number(usersId);

		return (
			<div className={cn(styles["hover-button-container"], isOwn && styles["hover-shifted"])}>
				{extra}
				<span title={this.tr("More")}><MoreHoriz
					onClick={e => {
						e.stopPropagation();
						this.setState({
							hoverMenuOpen: true,
							hoverMenuAnchor: e.currentTarget
						});
					}}
					className={styles["hover-button"]}
				/></span>
				<Popover
					onClose={this._closeRowPopupMenu}
					open={this.state.hoverMenuOpen}
					anchorEl={this.state.hoverMenuAnchor}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left"
					}}
				>
					<div className="grid-control-button-menu">
						{!row.userHour && row.task?.editable && row.user?.id === 0 && <MenuItem
							item={{
								Icon: AccessTime,
								onClick: () => this._assignTask(row),
								text: this.tr("Assign Task")
							}}
						/>}
						{row.userHour && row.userHour.id > 0 && row.task?.editable && isOwn && !row.task.done && <MenuItem
							item={{
								Icon: AccessTime,
								onClick: () => this._markOwnHoursAsDone(row),
								text: this.tr("Mark own hours as done")
							}}
						/>}
						{row.userHour && row.userHour.id > 0 && row.task?.editable && isOwn && row.task.done && <MenuItem
							item={{
								Icon: AccessTime,
								onClick: () => this._markOwnHoursAsUndone(row),
								text: this.tr("Mark own hours as undone")
							}}
						/>}
						{row.userHour && row.userHour.id > 0 && row.task?.editable && !row.task.done && <MenuItem
							item={{
								Icon: CheckBox,
								onClick: () => this._markTaskAsDone(row),
								text: this.tr("Mark task as done")
							}}
						/>}
						{row.task?.editable && row.task.done && <MenuItem
							item={{
								Icon: CheckBox,
								onClick: () => this._markTaskAsUndone(row),
								text: this.tr("Mark task as undone")
							}}
						/>}
						{row.task?.editable && <MenuItem
							item={{
								Icon: Delete,
								color: "red",
								onClick: () => this._showDeleteConfirmation(row),
								text: this.tr("Delete")
							}}
						/>}
					</div>
				</Popover>
			</div>
		);
	};

    _closeRowPopupMenu = () => {
		this.setState({
			anchorEl: null,
			hoverMenuOpen: false
		});
	};

    renderHover = (row: GridRow, extra: JSX.Element|undefined) => {
		if (row.display_type === "user") {
			return this._renderUserRowHover(row, extra)
		} else if (row.display_type === "project") {
			return this._renderProjectRowHover(row, extra);
		} else if (row.type === 'task' && row.display_type === "task" && row.task) {
			return this._renderTaskRowHover(row, extra);
		}
	};

	closeHover = () => {
		this.setState({ hoverMenuOpen: false });
	}

    _renderPopup = () => {
		if (!this.state.popUpComponent) return null;
		return (
			<div
				onClick={e =>
                    //@ts-ignore
					e.target.className === "popup-container" && this._closePopup()
				}
				className="popup-container"
			>
				{this.state.popUpComponent}
			</div>
		);
	};

	expand = (option: keyof ExpandData) => {
        this.grid.current?.expand(option);
    }

    collapse = (option: keyof ExpandData) => {
        this.grid.current?.collapse(option);
    }

    render() {
        const { 
			viewOptions, settings, columns, startdate, enddate, autocomplete, autocompleteGlobal, getWorkdayLength,
			expand, expandedRows, setExpandedRows, openDialog, totalDataAll, totalDataUser, totalDataView,
			onProjectRangeExtended,
		} = this.props;
        const { data } = this.state;

		const totalRows: string[] = [];

		if (viewOptions.showTotalVisible) {
			totalRows.push('totalVisible');
		}
		if (viewOptions.showTotalOwn) {
			totalRows.push('totalOwn');
		}
		if (viewOptions.showTotalRow) {
			totalRows.push('total');
		}

        return (
            <div className={styles.root}>
                {this._renderPopup()}
                <Grid
                    ref={this.grid}
                    viewOptions={viewOptions}
                    grouping={settings.grouping}
                    zoom={settings.zoom}
                    viewMode={settings.viewMode || ""}
                    range={{ start: startdate, end: enddate }}
                    holidays={autocompleteGlobal.holidays[autocomplete.company] || {}}
                    save={this.save}
                    renderHover={this.renderHover}
                    closeHover={this.closeHover}
                    data={data}
                    tableColumns={columns.filter(x => !x.hide)}
                    totalRows={totalRows}
                    viewTotal={totalDataView}
                    allTotal={totalDataAll}
                    userTotal={totalDataUser}
                    expand={expand}
                    openDialog={openDialog}
                    expandedRows={expandedRows}
                    setExpandedRows={setExpandedRows}
					getWorkdayLength={getWorkdayLength}
					onProjectRangeExtended={onProjectRangeExtended}
                />
            </div>
        );
    }
}

export default GridView;